import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React, { StrictMode } from 'react';
import { hydrate, render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'state/store';
import { RootTheme as theme } from 'themes';
import App from './App';
import createEmotionCache from './createEmotionCache';
require('utils/polyfill');
require('utils/Sentry');
const root = document.getElementById('root');
const cache = createEmotionCache();

function Main() {
  return (
    <StrictMode>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
              <HelmetProvider>
                <SnackbarProvider>
                  <App />
                </SnackbarProvider>
              </HelmetProvider>
            </ThemeProvider>
          </CacheProvider>
        </BrowserRouter>
      </ReduxProvider>
    </StrictMode>
  );
}

if (module.hot) {
  module.hot.accept('./App', () => hydrate(<Main />, root));
}

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  hydrate(<Main />, root);
} else {
  // If we're not running on the server, just render like normal
  render(<Main />, root);
}
