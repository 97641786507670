import { Facility, Instructor } from 'api/Serializers/Appointments';
import { AxiosResponse } from 'axios';
import { Activity } from '../Activities';

export enum AppointmentProductStatus {
  Pending = -4,
  Unavailable = -3,
  Unlisted = -2,
  Inactive = -1,
  Active = 1,
}

export interface AppointmentProduct {
  id: string;
  created: string;
  activity: Activity;
  facility: Facility;
  instructor: Instructor;
  timezone: string;
  appUrl: string;
  numAvailable: number;
  lastAvailable: string;
  taxRate: number;
  admissionFee: number;
  price: number;
  extraParticipantPrice: number;
  nextPrice: number;
  nextPriceStartDate: string;
  nextAdmissionFee: number;
  nextAdmissionFeeStartDate: string;
  bookingRate: number;
  status: AppointmentProductStatus;
  distance?: number;
  lastBookedAt?: string;
}

export interface Api {
  list: (params?: {
    username: string;
  }) => Promise<AxiosResponse<AppointmentProduct[]>>;
  search: (URLSearchParams) => Promise<AxiosResponse<AppointmentProduct[]>>;
  retrieve: (id: string) => Promise<AxiosResponse<AppointmentProduct>>;
  delete: (id: string) => Promise<AxiosResponse<{ message: string }>>;
  create: (data: {
    activityId: number;
    facilityId: number;
    instructorId: number;
  }) => Promise<AxiosResponse<AppointmentProduct>>;
}
