import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';

interface Props extends CheckboxProps {
  label?: string;
  disabled?: boolean;
  disableTypography?: boolean;
  onChange: (event: { target: { checked: boolean } }, checked: boolean) => void;
}

const Checkbox = ({ label, disableTypography = false, ...rest }: Props) => (
  <FormControlLabel
    className="!mb-0"
    control={<MuiCheckbox {...rest} />}
    label={label}
    disableTypography={disableTypography}
  />
);

export default Checkbox;
