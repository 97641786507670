import Loading from 'components/loading';
import { IS_SERVER, QueryParams } from 'config';
import { AccountRouteParams } from 'models/route';
import AboutUs from 'pages/about-us';
import Account from 'pages/account';
import Activate from 'pages/account/activate';
import { CLIENT_ROUTES } from 'pages/account/client/utils';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import Book from 'pages/book';
import Error404 from 'pages/error-404';
import AppointmentFeedback from 'pages/feedback/appointment';
import AvailabilityFeedback from 'pages/feedback/availability';
import { GiftCards } from 'pages/gifts-cards';
import Home from 'pages/home';
import LearnMore from 'pages/learn-more';
import Locations from 'pages/locations';
import SignIn from 'pages/login';
import Logout from 'pages/logout';
import NewAccountSetup from 'pages/new-account-setup';
import Opportunities from 'pages/opportunities';
import PasswordResetConfirm from 'pages/password-reset-confirm';
import PasswordResetRequest from 'pages/password-reset-request';
import Privacy from 'pages/privacy';
import ShortcodeRedirect from 'pages/shortcode';
import SignUp from 'pages/sign-up';
import TermsOfService from 'pages/terms-of-service';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { getIsAuthenticated, getIsUserDataLoaded } from 'state/selectors';
import {
  APP_ROUTES,
  DEPRECATED_ROUTES,
  EXTERNAL_ROUTES,
  getUrlParam,
  SHARED_ROUTES,
} from 'utils/routing';

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path={APP_ROUTES.HOME}>
        <Home />
      </Route>
      <Route exact path={APP_ROUTES.BOOK.PATH}>
        <Book />
      </Route>
      <Route exact path={APP_ROUTES.PRIVACY}>
        <Privacy />
      </Route>
      <Route exact path={APP_ROUTES.INSTRUCTOR_DETAIL}>
        <Locations />
      </Route>
      <Route exact path={'/locations/:regionSlug?/:facilitySlug?'}>
        <Locations />
      </Route>
      <Route exact path={APP_ROUTES.TERMS_OF_SERVICE}>
        <TermsOfService />
      </Route>
      <Route exact path={APP_ROUTES.OPPORTUNITIES.PATH}>
        <Opportunities />
      </Route>
      <Route path={APP_ROUTES.LEARN_MORE.PATH}>
        <LearnMore />
      </Route>
      <Route exact path={APP_ROUTES.PRICING}>
        <LearnMore />
      </Route>
      <Route exact path={APP_ROUTES.ABOUT_US}>
        <AboutUs />
      </Route>
      <Route exact path={APP_ROUTES.FEEDBACK.AVAILABILITY}>
        <AvailabilityFeedback />
      </Route>
      <Route path={DEPRECATED_ROUTES.INSTRUCTOR_REVIEW}>
        <Redirect to={CLIENT_ROUTES.INSTRUCTORS.ROOT} />
      </Route>
      <Route path={DEPRECATED_ROUTES.OLD_SIGNUPS}>
        <Redirect to={APP_ROUTES.SIGN_UP} />
      </Route>
      <Route path={APP_ROUTES.GIFT_CARDS.ROOT}>
        <GiftCards />
      </Route>
      {/**
       * Authenticated only endpoints
       */}
      <Route exact path={SHARED_ROUTES.ACTIVATE.PATH}>
        <RequireAuth>
          <Activate />
        </RequireAuth>
      </Route>
      <Route exact path={APP_ROUTES.FEEDBACK.APPOINTMENT}>
        <RequireAuth>
          <AppointmentFeedback />
        </RequireAuth>
      </Route>
      <Route exact path={APP_ROUTES.ACCOUNT_PATH}>
        <RequireAuth>
          <Account />
        </RequireAuth>
      </Route>
      <Route exact path={APP_ROUTES.LOGOUT}>
        <RequireAuth>
          <Logout />
        </RequireAuth>
      </Route>
      {/**
       * Annonymous only endpoints
       */}
      <Route exact path={APP_ROUTES.LOGIN}>
        <RequireUnauth>
          <SignIn />
        </RequireUnauth>
      </Route>
      <Route exact={true} path={APP_ROUTES.SIGN_UP}>
        <RequireUnauth>
          <SignUp />
        </RequireUnauth>
      </Route>
      <Route exact={true} path={APP_ROUTES.SET_NEW_PASSWORD}>
        <RequireUnauth>
          <PasswordResetConfirm />
        </RequireUnauth>
      </Route>
      <Route exact={true} path={APP_ROUTES.SETUP_NEW_ACCOUNT}>
        <RequireUnauth>
          <NewAccountSetup />
        </RequireUnauth>
      </Route>
      <Route path={APP_ROUTES.REQUEST_NEW_PASSWORD}>
        <RequireUnauth>
          <PasswordResetRequest />
        </RequireUnauth>
      </Route>
      {/**
       * Deprecated routes
       */}
      <Route path={DEPRECATED_ROUTES.NEW_INSTRUCTOR}>
        <RequireAuth>
          <AccountOnboardingRedirect />
        </RequireAuth>
      </Route>
      {/**
       * External Redirects
       */}
      <Route path={APP_ROUTES.HELP}>
        <NavigateExternal to={EXTERNAL_ROUTES.HELP.ROOT} />
      </Route>
      <Route path={APP_ROUTES.BLOG}>
        <NavigateExternal to={EXTERNAL_ROUTES.BLOG.ROOT} />
      </Route>
      <Route path={APP_ROUTES.NOT_FOUND}>
        <Error404 />
      </Route>
      <Route>
        <ShortcodeRedirect />
      </Route>
    </Switch>
  );
};

function NavigateExternal({ to }) {
  if (!IS_SERVER) {
    window.location = to;
  }
  return null;
}

const AccountOnboardingRedirect = () => {
  const { params } = useRouteMatch<AccountRouteParams>();
  return (
    <Redirect
      to={`${INSTRUCTOR_ROUTES.ONBOARDING.ROOT}${
        params.mode ? `/${params.mode}` : ''
      }`}
    />
  );
};

export function RequireAuth({ children }) {
  const isUserDataLoaded = useSelector(getIsUserDataLoaded);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const location = useLocation();
  if (!isAuthenticated) {
    const pathname = APP_ROUTES.LOGIN;
    const search =
      location.pathname !== APP_ROUTES.LOGOUT
        ? `next=${encodeURIComponent(`${location.pathname}${location.search}`)}`
        : undefined;
    return <Redirect to={{ pathname, search }} />;
  } else if (!isUserDataLoaded) {
    return <Loading message="Loading account data..." />;
  }
  return children;
}

export function RequireUnauth({ children }) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  if (isAuthenticated) {
    let pathname = APP_ROUTES.ACCOUNT;
    const nextParam = getUrlParam(QueryParams.Next);
    let search = '';
    if (nextParam) {
      const decodedParams = nextParam.split('?');
      pathname = decodedParams[0];
      search = decodedParams.length > 1 ? decodedParams[1] : '';
    }
    return <Redirect to={{ pathname, search }} />;
  }
  return children;
}

export default AppRoutes;
