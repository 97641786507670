import CircularProgress from '@mui/material/CircularProgress';
import Progress from 'components/progress';
import { IS_SERVER } from 'config';
import React from 'react';
import style from './style.module.scss';

interface Props {
  className?: string;
  percent?: number;
  message?: string;
  position?: 'fixed' | 'absolute' | 'inline-contained' | 'inline';
  size?: number;
}

const Loading = ({ className, percent, position, message, size }: Props) => {
  const nonInlineClasses = `${position} box-content bg-white border border-gray-300 rounded-full shadow-lg 1 center-xy`;
  return IS_SERVER ? null : percent ? (
    <div
      className={`box-content ${
        position === 'absolute' ? position : 'fixed'
      } flex items-center p-2 bg-white border border-gray-300 rounded-full shadow-lg 1 transition-opacity duration-1000 center-xy ${
        percent === 100 ? 'opacity-0' : 'opacity-100'
      }${cls(className)}`}
      style={{ zIndex: 1500 }}
    >
      <div className="flex flex-col w-64 m-2 space-y-2">
        <span className="text-sm font-semibold text-gray-600">Loading... </span>
        <span className="overflow-hidden rounded">
          <Progress percent={percent} color="primary" />
        </span>
      </div>
    </div>
  ) : position !== 'inline' ? (
    <div
      className={`${message ? 'p-4' : 'p-2'}${cls(
        position !== 'inline-contained' ? nonInlineClasses : null
      )}${cls(className)}`}
      style={{ zIndex: 1500 }}
    >
      <div className="flex items-center gap-4">
        {message && (
          <span className="text-sm font-semibold text-gray-600 whitespace-nowrap">
            {message}
          </span>
        )}
        <CircularProgress
          size={size}
          className="text-blue-400 shrink-0"
          color="inherit"
        />
      </div>
    </div>
  ) : position === 'inline' ? (
    <div className={`${style.loader} ${style.inline}`}>
      {message && <h5 className={style.message}>{message}</h5>}
      <CircularProgress size={size} className={`${style.blue}`} />
    </div>
  ) : null;
};

Loading.defaultProps = {
  position: 'fixed',
  size: 40,
};

export default Loading;
