import InstructorBaseTimeSlot from 'features/schedule/as-instructor/base-time-slot';
import { TimeSlotProps } from 'features/schedule/date-time-list/time-slot';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAppointmentProduct, getProposalClient } from 'state/selectors';

const BookedTimeSlot = ({
  timeSlot,
  tense,
  aptProdId,
  description,
}: TimeSlotProps) => {
  const timezone = useSelector(getAppointmentProduct(aptProdId))?.timezone;
  const client = useSelector(getProposalClient);
  const classes =
    (timeSlot.otherData?.clientId &&
      timeSlot.otherData?.clientId === client?.id) ||
    // LEGACY
    timeSlot.clientId === client?.id
      ? 'font-semibold text-white bg-green-700'
      : 'cursor-default text-gray-700 bg-gray-50';
  return (
    <InstructorBaseTimeSlot
      datetime={timeSlot.datetime}
      timezone={timezone}
      description={description}
      classes={classes}
    />
  );
};

export default BookedTimeSlot;
