import {
  FacilitySchedulableSerializer,
  FacilityStatus,
} from 'api/Serializers/Facilities';
import Select, { SelectOption as SelectOptionState } from 'components/select';
import SelectOption from 'components/select-option';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFacilitiesSchedulable, getFacilityDetail } from 'state/selectors';
import { setScheduleSelectedFacility } from 'state/slice/facility';

interface Props {
  autoFocus?: boolean;
  allowAll?: boolean;
  allowSelect?: boolean;
  slug?: string;
  placeholder?: string;
  onChange?(props: SelectOptionState<number>): void;
}

const getLabel = (f: FacilitySchedulableSerializer) => {
  if (
    f.status === FacilityStatus.Active ||
    f.status === FacilityStatus.Seeding
  ) {
    return f.displayName;
  } else {
    return `${f.displayName} <CLOSED>`;
  }
};

const FacilitySelect: React.FC<Props> = ({
  autoFocus = false,
  allowAll = false,
  allowSelect = true,
  placeholder = 'Select facility',
  onChange = undefined,
}) => {
  const facilities = useSelector(getFacilitiesSchedulable);
  const dispatch = useAppDispatch();
  const facility = useSelector(getFacilityDetail);
  const [slice, setSlice] = useState([]);
  const handleSelect = (opt: SelectOptionState<number>) => {
    if (onChange) {
      onChange(opt);
    } else if (opt) {
      const selected = facilities.find((f) => f.slug === opt.slug);
      dispatch(setScheduleSelectedFacility(selected));
    } else {
      dispatch(setScheduleSelectedFacility(undefined));
    }
  };

  const notActiveButSelected = (f: FacilitySchedulableSerializer): boolean =>
    f.status !== FacilityStatus.Active &&
    f.status !== FacilityStatus.Seeding &&
    f.slug === facility?.slug;
  const isActive = (f: FacilitySchedulableSerializer): boolean =>
    f.status === FacilityStatus.Active || f.status === FacilityStatus.Seeding;
  const createSelectOptions = (
    f: FacilitySchedulableSerializer,
    i: number
  ): SelectOptionState<number> => ({
    objectId: f.id,
    slug: f.slug,
    label: getLabel(f),
    value: i,
    avatar: f.avatar,
  });

  useEffect(() => {
    const _filteredFacilities = facilities
      ? [...facilities]
          .filter((f) => notActiveButSelected(f) || isActive(f))
          // .groupBy((f, k) => ({
          //   label
          // }))
          .map(createSelectOptions)
      : [];
    setSlice(_filteredFacilities);
    if (allowAll) {
      _filteredFacilities.unshift({
        label: 'All',
        value: undefined,
      });
    }
  }, [facilities]);

  if (slice.length === 0) {
    return (
      <Select
        type="text"
        name="facility"
        placeholder="Loading..."
        options={[]}
      />
    );
  }

  const resultIndex = slice.findIndex((f) => f.slug === facility?.slug);
  if (slice.length === 1 || !allowSelect) {
    const single = slice[resultIndex === -1 ? 0 : resultIndex];
    return <SelectOption {...single} />;
  }
  return (
    <Select
      type="text"
      name="facility"
      placeholder={placeholder}
      onChange={handleSelect}
      options={slice}
      value={slice[resultIndex]}
      autoFocus={autoFocus}
      openMenuOnFocus={true}
      isClearable={true}
    />
  );
};

export default FacilitySelect;
