import CheckIcon from '@mui/icons-material/Done';
import ParkingIcon from '@mui/icons-material/LocalParking';
import { FacilitySchedulableSerializer } from 'api/Serializers/Facilities';
import React from 'react';
import { Element } from 'react-scroll';

const FacilityCard = ({
  facility,
}: {
  facility: FacilitySchedulableSerializer;
}) => {
  return (
    <div
      className="w-full overflow-hidden transition-shadow duration-300 bg-white border-t border-gray-200 rounded-lg shadow cursor-pointer cubic hover:shadow-md"
      style={{ minHeight: 150 }}
    >
      {facility && (
        <div className="flex flex-col">
          <Element name={facility.slug} />
          <div className="flex">
            <div>
              <div className="h-full" style={{ width: 150, minHeight: 150 }}>
                <img className="object-cover min-h-full" src={facility.hero} />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col justify-between w-full h-full px-4 pt-4 pb-2">
                <div>
                  <div className="flex mb-1 font-bold leading-none text-gray-800 text-md">
                    {facility.displayName}
                  </div>
                  <div className="flex mb-2 text-sm font-semibold leading-none text-gray-700">
                    {facility.attributes.subtype}
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1 text-base text-gray-700">
                    <span className="flex items-center text-xl">
                      <ParkingIcon
                        fontSize="inherit"
                        className="mr-1 text-gray-600"
                      />
                    </span>{' '}
                    {facility.hasFreeParking ? 'Free' : 'Paid'} parking
                  </div>
                  <div className="flex items-center mb-1 text-base text-gray-700">
                    <span className="flex items-center text-xl">
                      <CheckIcon
                        fontSize="inherit"
                        className="mr-1 text-gray-600"
                      />
                    </span>{' '}
                    ${facility.admissionFee} Admission
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

FacilityCard.defaultProps = {
  isDirectionsShown: true,
};

export default FacilityCard;
