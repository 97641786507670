import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Tense } from 'api/Serializers/Schedules';
import Calendar from 'components/calendar';
import CalendarControls from 'components/calendar-controls';
import Label from 'components/input-label';
import { DATE_FMT } from 'config';
import moment from 'moment-timezone';
import React, { useState } from 'react';

interface Props {
  fwdRef?: any;
  default?: string;
  disabled?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  minDate: string;
  maxDate: string;
  name: string;
  title?: string;
  onSelect(date: string): void;
  onFocus?(): void;
  autoFocus?: boolean;
}

const CalendarDateComponent = ({
  date,
  isValid,
  isSelected,
  onClick,
  ...rest
}) => {
  const handleClick = (event) => {
    onClick(date);
  };
  if (!date) {
    return null;
  }
  return (
    <button
      className={`h-10 w-10 sm:h-12 sm:w-12 m-auto flex rounded-full items-center justify-center ${
        isValid
          ? `hover:bg-gray-300 cursor-pointer ${
              isSelected
                ? 'text-white bg-blue-400 hover:bg-blue-400'
                : 'hover:text-blue-700 text-gray-700'
            }`
          : 'opacity-50 cursor-default'
      }`}
      onClick={handleClick}
    >
      <div className={`text-center flex-1 font-medium`}>
        {moment(date).format(DATE_FMT.D)}
      </div>
    </button>
  );
};

const InputCalendar = (props: Props) => {
  const [showCal, setShowCal] = useState(false);
  const [value, setValue] = useState<string>(props.default);
  const [date, setRenderDate] = useState<string>(
    props.default ? props.default : moment().format(DATE_FMT.DATE_KEY)
  );

  const handleDateClick = (date: string, tense: Tense) => {
    if (date < props.minDate) {
      return;
    }
    setValue(date);
    props.onSelect(date);
    handleClickAway();
  };

  const clear = () => {
    setValue(undefined);
    props.onSelect(undefined);
    handleClickAway();
  };

  const handleClickAway = () => {
    setShowCal(false);
  };

  const handleClick = (evt) => {
    evt.stopPropagation();
  };
  const handleFocus = (evt) => {
    evt.stopPropagation();
    if (date < props.minDate) {
      setRenderDate(props.minDate);
    }
    setShowCal(true);
    if (props.onFocus) {
      props.onFocus();
    }
  };
  return (
    <div className="relative inline-block w-full">
      {props.title && <Label htmlFor={props.name}>{props.title}</Label>}
      <div className="relative">
        <input
          ref={props.fwdRef}
          name={props.name}
          placeholder={props.placeholder ? props.placeholder : 'Select a date'}
          className="pl-10 text-sm font-medium text-gray-700"
          value={
            value
              ? moment(value, DATE_FMT.DATE_KEY).format(DATE_FMT.DOW_MON_D)
              : ''
          }
          onFocus={handleFocus}
          onClick={handleClick}
          readOnly={true}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
        />
        <DateRangeIcon className="absolute left-0 ml-2 text-gray-700 center-y" />
        {value && props.isClearable && (
          <button
            onClick={clear}
            className="absolute right-0 mr-2 text-gray-400 transition-colors duration-300 center-y cubic hover:text-red-600"
          >
            <CloseIcon color="inherit" />
          </button>
        )}
      </div>
      <div className="relative" style={{ zIndex: 500 }}>
        {showCal && !props.disabled && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="absolute top-0 left-0 p-2 bg-white rounded shadow-lg">
              <CalendarControls
                minDate={props.minDate}
                maxDate={props.maxDate}
              />
              <Calendar
                DayComponent={(dateProps) => (
                  <CalendarDateComponent
                    {...dateProps}
                    onClick={handleDateClick}
                    isValid={moment(dateProps.date).isSameOrAfter(
                      props.minDate,
                      'day'
                    )}
                    isSelected={dateProps.date === value}
                  />
                )}
              />
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

export default InputCalendar;
