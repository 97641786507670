import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { InstructorListItemAsClientSerializer } from 'api/Serializers/Instructors';
import Dashboard from 'components/account/dashboard';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Callout from 'components/callout';
import Card from 'components/card';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { DATE_FMT, FETCH_STATE } from 'config';
import ReviewForm from 'features/reviews/create';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ReviewIcon } from 'icons';
import {
  ClientReviewSubmitSuccess,
  GenericServerError,
} from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import {
  getInstructorListFetchState,
  getInstructorsSchedulable,
  getReviewsList,
} from 'state/selectors';
import { fetchReviews } from 'state/slice/reviews';
import { CLIENT_ROUTES } from '../utils';

interface ListItemProps {
  instructor: InstructorListItemAsClientSerializer;
}

enum Mode {
  Schedule = 'schedule',
  Review = 'review',
}

const InstructorListItem = ({ instructor }: ListItemProps) => {
  const reviews = useSelector(getReviewsList);
  const review = reviews.find(
    (review) => review.instructorId === instructor.id
  );
  const lastInteractionDate = moment(instructor.lastInteractionDate);
  return (
    <Card maxWidth="full">
      <div>
        <div className="flex mb-6">
          <div className="">
            <Avatar
              src={instructor.avatar}
              diameter={32}
              variant="rounded"
              border={true}
            />
          </div>
          <div className="flex-1 m-4">
            <h3 className="my-0 font-medium text-gray-900 text-md">
              {instructor.displayName}
            </h3>
            <h4 className="mb-2 text-xs font-medium leading-none text-gray-700">
              Learn to Swim & Water Safety Instructor
            </h4>
            <p className="text-sm text-gray-800">
              You've booked a total of{' '}
              {`${instructor.numAppointments} ${'lesson'.pluralize(
                instructor.numAppointments
              )}`}{' '}
              with {instructor.firstName}, and your last lesson{' '}
              {lastInteractionDate.isAfter(moment()) ? 'is' : 'was'} on{' '}
              {lastInteractionDate.format(DATE_FMT.MONTH_D_YEAR)}
            </p>
          </div>
        </div>
        <div className="space-y-2">
          <div>
            <h5 className="mb-4 text-gray-800">My testimonial</h5>
            {review ? (
              <Callout
                type={review.recommended ? 'success' : 'error'}
                icon={
                  review.recommended ? (
                    <ThumbUpIcon fontSize="inherit" color="inherit" />
                  ) : (
                    <ThumbDownIcon fontSize="inherit" color="inherit" />
                  )
                }
              >
                <h5 className="text-gray-800">{review.title}</h5>
                <span className="text-xs font-medium leading-tight text-gray-600 ">
                  {moment(review.created).format(DATE_FMT.MONTH_D)}
                </span>
                <div className="m-0 font-normal text-gray-800">
                  <p style={{ whiteSpace: 'pre-wrap' }}>{review.review}</p>
                </div>
              </Callout>
            ) : (
              <Callout type="question">
                How have you enjoyed your lessons with {instructor.firstName} so
                far? Help others decide by giving them a testimonial!
              </Callout>
            )}
            <Controls>
              <Button
                to={CLIENT_ROUTES.INSTRUCTORS.nav(instructor.slug, Mode.Review)}
                variant="flat"
              >
                {review ? 'Modify testimonial' : 'Add testimonial'}
              </Button>
              <Button to={`/${instructor.slug}`} color="primary" variant="flat">
                Book more lessons
              </Button>
            </Controls>
          </div>
        </div>
      </div>
    </Card>
  );
};

const InstructorListItemWip = ({ instructor }: ListItemProps) => {
  const { avatar, displayName, ...rest } = instructor;

  const reviews = useSelector(getReviewsList);
  const hasReviewed =
    reviews && reviews.some((review) => review.instructorId === instructor.id);

  const lastInteractionDate = moment(instructor.lastInteractionDate);
  return (
    <div className="w-full overflow-hidden transition-shadow duration-200 bg-white border border-gray-400 rounded-lg shadow hover:shadow-md">
      <div className="space-y-3">
        <div className="flex gap-2">
          <div className="">
            <Avatar src={avatar} diameter={32} variant="rounded" />
          </div>
          <div className="flex flex-col justify-between">
            <div className="">
              <h3 className="my-0 font-medium text-gray-900 text-md">
                {displayName}
              </h3>
              <h4 className="my-0 text-xs font-medium leading-none text-gray-700">
                Learn to Swim & Water Safety Instructor
              </h4>
            </div>
            <div className="text-sm font-normal text-gray-800">
              You've booked a total of{' '}
              {`${instructor.numAppointments} ${'lesson'.pluralize(
                instructor.numAppointments
              )}`}{' '}
              with {instructor.firstName}, and your last lesson{' '}
              {lastInteractionDate.isAfter(moment()) ? 'is' : 'was'} on{' '}
              {lastInteractionDate.format(DATE_FMT.MONTH_D_YEAR)}
            </div>
            <div className="">
              <div className="grid grid-cols-3">
                <div className="flex flex-col items-center justify-center text-xs text-gray-700">
                  {hasReviewed ? (
                    <>
                      <span className="flex items-center justify-end flex-1 text-gray-900">
                        <ReviewIcon width={24} />
                      </span>
                      <span>Review Submitted</span>
                    </>
                  ) : (
                    <>
                      <span className="flex items-center justify-end flex-1 text-gray-900">
                        <ReviewIcon width={24} />
                      </span>
                      <span>Submit review</span>
                    </>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center text-xs text-gray-700 border-gray-300 border-x">
                  <span className="flex items-center justify-end flex-1 font-semibold text-gray-900 text-md">
                    {instructor.numAppointments}
                  </span>
                  <span>{'Lesson'.pluralize(instructor.numAppointments)}</span>
                </div>
                <div className="flex flex-col items-center justify-center text-xs text-gray-700">
                  <span className="flex items-center justify-end flex-1 font-semibold text-gray-900 text-md">
                    $90
                  </span>
                  <span>1-hour lesson</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="space-x-1">{chips}</div> */}
        <Controls>
          <Button
            to={CLIENT_ROUTES.INSTRUCTORS.nav(instructor.slug, Mode.Review)}
            variant="flat"
            size="small"
          >
            {hasReviewed ? 'Modify review' : 'Add review'}
          </Button>
          <Button
            to={`/${instructor.slug}`}
            color="primary"
            variant="flat"
            size="small"
          >
            Book lessons
          </Button>
        </Controls>
      </div>
    </div>
  );
};

const Instructors = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { params } = useRouteMatch<{ username: string; mode: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const fetchState = useSelector(getInstructorListFetchState);

  const instructors: InstructorListItemAsClientSerializer[] = useSelector(
    getInstructorsSchedulable
  ) as InstructorListItemAsClientSerializer[];

  useEffect(() => {
    dispatch(fetchReviews);
  }, []);

  const handleClose = () => {
    history.push(CLIENT_ROUTES.INSTRUCTORS.ROOT);
  };

  const handleReviewUpsertSuccess = () => {
    enqueueSnackbar(ClientReviewSubmitSuccess);
    handleClose();
  };

  const handleReviewUpsertError = () => {
    enqueueSnackbar(GenericServerError);
  };

  return (
    <Dashboard width="2xl" title="Instructors">
      <div className="space-y-12">
        {fetchState === FETCH_STATE.GET ? (
          <Loading
            position="inline-contained"
            message="Loading instructors..."
          />
        ) : (
          instructors?.map((instructor, i) => {
            return (
              <InstructorListItem
                key={`instructor-${i}`}
                instructor={instructor}
              />
            );
          })
        )}
      </div>
      <Modal
        name="Client — Instructor review form"
        open={params.mode === Mode.Review}
        onClose={handleClose}
        maxWidth="sm"
      >
        <ReviewForm
          instructorSlug={params.username}
          onCancel={handleClose}
          onError={handleReviewUpsertError}
          onSuccess={handleReviewUpsertSuccess}
        />
      </Modal>
    </Dashboard>
  );
};

export default Instructors;
