import { AxiosResponse } from 'axios';

export enum FeedbackCategory {
  Appointment = 'APPOINTMENT',
  Instructor = 'INSTRUCTOR',
  Facility = 'FACILITY',
  Propel = 'PROPEL',
}

interface BaseFeedbackSerializer {
  category: FeedbackCategory;
  positive: boolean;
}

interface AppointmentRatingSerializer extends BaseFeedbackSerializer {
  comments?: string;
}

interface AppointmentTagSerializer {
  subject: string;
}

export interface AppointmentFeedbackSerializer {
  event: string;
  ratings: AppointmentRatingSerializer[];
  tags: AppointmentTagSerializer[];
}

export interface Api {
  create: (data: AppointmentFeedbackSerializer) => Promise<AxiosResponse<any>>;
}
