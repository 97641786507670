import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { UserType } from 'config';
import TextArea from 'features/assistance/components/textArea';
import { AssistanceWizardProps } from 'models/Assistance';
import React from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const dataKey = 'facilityIssueDescription';
const FacilityCancelView = (props: AssistanceWizardProps) => {
  const { appointment, state, getReportData } = props;
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>Note</h4>
      <Callout
        className="my-8"
        type="info"
        title="Would you like to add a note for the cancellation email? Your note will be sent to the client."
      />
      <div className="my-8 space-y-6">
        <TextArea
          id={dataKey}
          title="Cancellation note:"
          label="Cancellation note:"
          placeholder="The lesson cannot proceed due to..."
          required={false}
          recipientUserType={UserType.Client}
        />
      </div>
      <Controls variant="block">
        <BackButton />
        <Button
          variant="contained"
          color="primary"
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
        >
          Continue
        </Button>
      </Controls>
    </>
  );
};

export default FacilityCancelView;
