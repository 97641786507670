import CancelIcon from '@mui/icons-material/Cancel';
import BookingIcon from '@mui/icons-material/MonetizationOn';
import api from 'api';
import { AccountOverviewSerializer } from 'api/Serializers/Accounts';
import { EventList } from 'api/Serializers/Appointments';
import Card from 'components/card';
import Link from 'components/link';
import Loading from 'components/loading';
import { DATE_FMT, DEBUG, UserType } from 'config';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { getUsername } from 'state/selectors';
import { SHARED_ROUTES } from 'utils/routing';

interface Props {
  username: string;
  userType: UserType;
}

const activityBoldClass = 'font-medium';
const activityClass = 'font-light text-gray-700';

const getTimeSinceText = (start: string) => {
  const test = DEBUG && USE_TEST_DATE ? moment(testDateStr) : moment();
  const testMoment = moment()
    .year(test.year())
    .month(test.month())
    .date(test.date());
  const mins = testMoment.diff(moment(start), 'minutes');
  return mins > 60 * 24
    ? `${Math.floor(mins / 60 / 24)}d`
    : mins > 60
    ? `${Math.floor(mins / 60)}h`
    : mins > 0
    ? `${mins}m`
    : 'Recently';
};

const blockClasses =
  'py-2 items-center flex flex-col justify-between mx-2 my-4 sm:mx-0 sm:my-0 ';

const getEventTitle = (event: EventList) =>
  `${event.activity.appointmentNoun} taught by ${event.instructor.displayName}`;

const getEventSubtitle = (event: EventList) =>
  `For ${event.client.displayName}, ${
    event.numParticipants
  } ${'swimmer'.pluralize(event.numParticipants)}`;

const EventComp = ({ event }: { event: EventList }) => {
  const title = getEventTitle(event);
  const subtitle = getEventSubtitle(event);
  return (
    <div className="block px-6 -mx-6 hover:bg-gray-100">
      <Link to={SHARED_ROUTES.SCHEDULE.appointment(event.id)}>
        <div className={blockClasses}>
          <div className="flex w-full">
            <span className="w-1/4 text-base font-medium leading-none text-gray-700 sm:text-md">
              {moment(event.start).tz(event.timezone).format(DATE_FMT.TIME_A)}
            </span>
          </div>
          <div className="flex w-full pt-2">
            <span
              className={`pl-1 text-sm font-normal text-green-700 border-l-4 border-green-400 border-solid`}
            >
              {title}
            </span>
          </div>
          <div className="flex w-full pb-2">
            <span
              className={`pl-1 text-sm font-medium text-gray-700 border-l-4 border-green-400 border-solid`}
            >
              {subtitle}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

const RecentCancellation = ({ event }: { event: EventList }) => {
  return (
    <div className="flex border-b ">
      <span className="flex flex-col flex-1 p-2 text-sm text-gray-700 hover:bg-gray-200">
        <span className={activityClass}>
          <span className={activityBoldClass}>{event.client.displayName}</span>{' '}
          cancelled an appointment on{' '}
          <span className={activityBoldClass}>
            {moment(event.start).tz(event.timezone).format(DATE_FMT.TIME_A)}
            {', '}
            {moment(event.start).tz(event.timezone).format(DATE_FMT.MONTH_D)}
          </span>
        </span>
        <span className="flex items-center mt-1 text-sm text-gray-600">
          <CancelIcon className="mr-1 text-red-600" fontSize="small" />{' '}
          {getTimeSinceText(event.created)}
        </span>
      </span>
    </div>
  );
};

const RecentBooking = ({ event }: { event: EventList }) => {
  return (
    <div className="flex border-b ">
      <span className="flex flex-col flex-1 p-2 text-sm text-gray-700 hover:bg-gray-200">
        <span className={activityClass}>
          <span className={activityBoldClass}>{event.client.displayName}</span>{' '}
          booked an appointment for{' '}
          <span className={activityBoldClass}>
            {moment(event.start).tz(event.timezone).format(DATE_FMT.TIME_A)}
            {', '}
            {moment(event.start).tz(event.timezone).format(DATE_FMT.MONTH_D)}
          </span>
          <span>
            {' '}
            with{' '}
            <span className={activityBoldClass}>
              {event.instructor.displayName}
            </span>
          </span>
        </span>
        <span className="flex items-center mt-1 text-sm text-gray-600">
          <BookingIcon className="mr-1 text-green-600" fontSize="small" />{' '}
          {getTimeSinceText(event.created)}
        </span>
      </span>
    </div>
  );
};

const USE_TEST_DATE = false;
const testDateStr = '2019-10-30';
const now = DEBUG && USE_TEST_DATE ? moment(testDateStr) : moment();
const endOfUpcoming = moment(now).add(48, 'hours').endOf('day');
const day0 = moment(now).endOf('day');
const day1 = moment(now).add(1, 'day').endOf('day');
const day2 = moment(now).add(2, 'day').endOf('day');

const Overview = () => {
  const username = useSelector(getUsername);
  const [data, setData] = useState<AccountOverviewSerializer>();
  const [day0Appointments, setDay0Appointments] = useState<EventList[]>();
  const [day1Appointments, setDay1Appointments] = useState<EventList[]>();
  const [day2Appointments, setDay2Appointments] = useState<EventList[]>();
  const [activityDetail, setActivityDetail] = useState<EventList>();
  const fetchOverviewData = async () => {
    const response = await api.account.overview(username);
    const dataFiltered = {
      ...response.data,
      events: [...response.data.upcoming].filter((event) =>
        moment(event.start).isBefore(endOfUpcoming)
      ),
    };
    setDay0Appointments(
      [...response.data.upcoming].filter((event) =>
        moment(event.start).isSame(day0, 'day')
      )
    );
    setDay1Appointments(
      [...response.data.upcoming].filter((event) =>
        moment(event.start).isSame(day1, 'day')
      )
    );
    setDay2Appointments(
      [...response.data.upcoming].filter((event) =>
        moment(event.start).isSame(day2, 'day')
      )
    );
    setData(dataFiltered);
  };
  useEffect(() => {
    fetchOverviewData();
    const id = setInterval(fetchOverviewData, 10 * 60 * 1000);
    return () => clearInterval(id);
  }, []);
  // const onActivityClick = (activity: Event) => () => {
  //   if (activity.type === 'reservation') {
  //     setActivityDetail(activity);
  //   }
  // };
  if (!data) {
    return <Loading />;
  }
  return (
    <div className="flex flex-wrap justify-center sm:-mx-3">
      <div className="flex-1 p-3">
        <Card maxWidth="full" title="Upcoming Reservations">
          {data.upcoming.length === 0 ? (
            <div className="p-4 my-4 text-gray-500 bg-gray-200 rounded-lg">
              Nothing scheduled in the next 48 hours
            </div>
          ) : (
            <div className="">
              <h5 className="mb-0 text-sm font-light text-gray-600 uppercase font-body">
                Today
              </h5>
              {day0Appointments.length > 0 ? (
                day0Appointments.map((event, k) => (
                  <EventComp
                    key={`upcoming-${k}`}
                    event={event}
                    // onClick={onActivityClick(event)}
                  />
                ))
              ) : (
                <div className="p-4 my-4 text-gray-500 bg-gray-200 rounded-lg">
                  Nothing scheduled today
                </div>
              )}
              <hr />
              <h5 className="mb-0 text-sm font-light text-gray-600 uppercase font-body">
                Tomorrow
              </h5>
              {day1Appointments.length > 0 ? (
                day1Appointments.map((event, k) => (
                  <EventComp
                    key={`24-${k}`}
                    event={event}
                    // onClick={onActivityClick(event)}
                  />
                ))
              ) : (
                <div className="p-4 my-4 text-gray-500 bg-gray-200 rounded-lg">
                  Nothing scheduled tomorrow
                </div>
              )}
              <hr />
              <h5 className="mb-0 text-sm font-light text-gray-600 uppercase font-body">
                Next Day
              </h5>
              {day2Appointments.length > 0 ? (
                day2Appointments.map((event, k) => (
                  <EventComp
                    key={`48-${k}`}
                    event={event}
                    // onClick={onActivityClick(event)}
                  />
                ))
              ) : (
                <div className="p-4 my-4 text-gray-500 bg-gray-200 rounded-lg">
                  Nothing scheduled next day
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
      <div className="flex-1 p-3">
        <Card maxWidth="full" title="Recent Activity">
          {data.recent.length > 0 ? (
            <Scrollbars
              autoHide={true}
              autoHideTimeout={2500}
              style={{
                height: data.recent.length * 80,
                minHeight: 2 * 80,
                maxHeight: 5 * 80,
              }}
              universal={true}
            >
              {data.recent.map((event, k) => {
                return !event.cancelled ? (
                  <RecentBooking key={`event-${k}`} event={event} />
                ) : event.cancelled ? (
                  <RecentCancellation key={`event-${k}`} event={event} />
                ) : null;
              })}
            </Scrollbars>
          ) : (
            <div className="p-4 my-4 text-gray-500 bg-gray-200 rounded-lg">
              No recent activity
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Overview;
