import Card from 'components/card';
import { DoneIcon, InfoIcon } from 'icons';
import React from 'react';

const PasswordRequirementHint = ({
  isVisible,
  password,
}: {
  isVisible: boolean;
  password: string;
}) => (
  <div
    className={`z-50 absolute -translate-x-1/2 -translate-y-full left-1/2 ${
      isVisible ? 'opacity-100' : 'opacity-0'
    } transition-opacity duration-300 pointer-events-none`}
  >
    <Card
      height={3}
      maxWidth="md"
      className="w-full pt-6 text-base text-blue-800 bg-blue-100 border border-blue-700"
    >
      <h5 className="mb-2 font-medium">Passwords should have:</h5>
      <ul className="space-y-1">
        <li>
          {password.length >= 8 ? (
            <DoneIcon width={24} className="text-green-600" color="inherit" />
          ) : (
            <InfoIcon width={24} />
          )}{' '}
          At least 8 characters
        </li>
        <li>
          {password.search(/[^A-Za-z]/) !== -1 ? (
            <DoneIcon width={24} className="text-green-600" color="inherit" />
          ) : (
            <InfoIcon width={24} />
          )}{' '}
          A number or special character
        </li>
      </ul>
    </Card>
  </div>
);

export default PasswordRequirementHint;
