import BackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/DoneOutlined';
import { AppointmentProductStatus } from 'api/Serializers/AppointmentProducts';
import {
  FacilitySchedulableSerializer,
  FacilityScheduleSerializer,
  getTemperatureLabel,
} from 'api/Serializers/Facilities';
import HelpButton from 'components/button-help';
import Callout from 'components/callout';
import DetailSection from 'components/detail-section';
import WeeklySchedule from 'components/weekly-schedule';
import { DATE_FMT } from 'config';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getScheduleAppointmentProducts } from 'state/selectors';
import { fetchFacilityScheduleForDate } from 'utils/facility';
import { FacilityDirections } from '.';
import DirectionsInfo from './directions-info';

interface Props {
  facility: FacilitySchedulableSerializer;
  directions: FacilityDirections;
  setTravelMode(mode: google.maps.TravelMode): void;
  onHelpClick(): void;
  onClose(): void;
}

const FacilityDetail = ({
  facility,
  directions,
  setTravelMode,
  onHelpClick,
  onClose,
}: Props) => {
  const [schedule, setSchedule] = useState<FacilityScheduleSerializer>();
  const appointmentProducts = useSelector(getScheduleAppointmentProducts);

  useEffect(() => {
    setSchedule(undefined);
    fetchFacilityScheduleForDate(
      facility.slug,
      moment().format(DATE_FMT.DATE_KEY)
    ).then((data) => setSchedule(data));
  }, [facility]);

  const temperatureLabel: string = getTemperatureLabel(
    facility?.attributes.temperature
  );

  const worksHereAlready = appointmentProducts.some(
    (aptProd) =>
      aptProd.facility.id === facility.id &&
      aptProd.status !== AppointmentProductStatus.Unlisted
  );

  return (
    <div className="w-full mt-4 overflow-hidden bg-background md:bg-white">
      <div className="absolute z-50" style={{ left: 10, top: 10 }}>
        <button
          className="bg-gray-100 shadow btn-icon hover:bg-blue-200 hover:text-blue-700"
          onClick={onClose}
        >
          <BackIcon />
        </button>
      </div>
      <div className="flex flex-col">
        <div className="">
          <div className="flex justify-center">
            <span className="overflow-hidden rounded-md">
              <img src={facility.hero} />
            </span>
          </div>
          <DetailSection>
            <h1>{facility.displayName}</h1>
            <h4 className="font-semibold text-gray-700 text-md">
              {facility.category} {facility.attributes.subtype}{' '}
              {facility.assetType}
            </h4>
            <div className="text-sm">
              <span>{`${facility.attributes.temperature}°C (${temperatureLabel})`}</span>
              <span>{' · '}</span>
              <span>{`${facility.attributes.maxLength} long`}</span>
              <span>{' · '}</span>
              <span>{`${facility.attributes.minDepth} to ${facility.attributes.maxDepth} deep`}</span>
            </div>
          </DetailSection>
          {worksHereAlready && (
            <DetailSection>
              <Callout title="Already listed here" type="success">
                You are already listed at this location!{' '}
                <span onClick={onClose} className="link">
                  Go back
                </span>
              </Callout>
            </DetailSection>
          )}
          <DetailSection title="About">
            <div>{facility.shortDescription}</div>
          </DetailSection>
          <DetailSection title="Directions">
            <DirectionsInfo
              directions={directions}
              onClick={() =>
                setTravelMode(
                  directions.travelMode === google.maps.TravelMode.DRIVING
                    ? google.maps.TravelMode.TRANSIT
                    : google.maps.TravelMode.DRIVING
                )
              }
            />
          </DetailSection>
          <DetailSection title="Hours">
            <WeeklySchedule schedule={schedule} />
          </DetailSection>
          <DetailSection title="Teaching here">
            <div className="flex items-center my-2">
              <DoneIcon className="w-4 mr-3" style={{ fontSize: 16 }} />$
              {facility.admissionFee} Admission per lesson
            </div>
          </DetailSection>
          <DetailSection title="Perks & Amenities">
            {[...facility.amenities]
              .sort((a, b) => b.value - a.value)
              .map((amenity) => (
                <div key={amenity.id} className="flex my-2">
                  <img className="w-4 mr-3 opacity-75" src={amenity.iconUrl} />
                  {amenity.title}
                </div>
              ))}
          </DetailSection>
          <div className="my-2 text-center">
            <HelpButton onClick={onHelpClick}>
              Things to consider when choosing
            </HelpButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityDetail;
