import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import InstructorAccount, {
  InstructorOnboardingSerializer,
} from 'api/Serializers/Accounts/Instructor';
import { InstructorListSerializer } from 'api/Serializers/Instructors';
import { AxiosError } from 'axios';
import { FETCH_STATE } from 'config';
import { GenericServerError } from 'lang/en/Snackbars';
import { enqueueSnackbar } from 'notistack';
import {
  getInstructorFetchState,
  getInstructorListFetchState,
  getUsername,
} from 'state/selectors';
import { AppDispatch } from 'state/store';

interface Reducer {
  schedulable: InstructorListSerializer[];
  details: InstructorAccount;
  onboarding: InstructorOnboardingSerializer;
  listFetchState: FETCH_STATE;
  fetchState: FETCH_STATE;
}

const initialState: Reducer = {
  schedulable: [],
  details: undefined,
  onboarding: undefined,
  listFetchState: FETCH_STATE.PRISTINE,
  fetchState: FETCH_STATE.PRISTINE,
};

const name: 'instructor' = 'instructor';
const Slice = createSlice({
  name,
  initialState,
  reducers: {
    fetchInstructorListStart(state) {
      state.listFetchState = FETCH_STATE.GET;
    },
    fetchInstructorListFailed(state) {
      state.listFetchState = FETCH_STATE.FAILED;
    },
    fetchInstructorListSuccess(
      state,
      action: PayloadAction<InstructorListSerializer[]>
    ) {
      state.schedulable = action.payload;
      state.listFetchState = FETCH_STATE.FULFILLED;
    },
    invalidateScheduleInstructors(state) {
      state.listFetchState = FETCH_STATE.PRISTINE;
    },
    fetchInstructorDetailStart(state) {
      state.fetchState = FETCH_STATE.GET;
    },
    fetchInstructorDetailFailed(state) {
      state.fetchState = FETCH_STATE.FAILED;
    },
    fetchInstructorDetailSuccess(
      state,
      action: PayloadAction<InstructorAccount>
    ) {
      state.details = action.payload;
      state.fetchState = FETCH_STATE.FULFILLED;
    },
    fetchInstructorOnboardingSuccess(
      state,
      action: PayloadAction<InstructorOnboardingSerializer>
    ) {
      state.onboarding = action.payload;
    },
    patchOnboarding(
      state,
      action: PayloadAction<Partial<InstructorOnboardingSerializer>>
    ) {
      const onboarding = state.onboarding;
      Object.entries(action.payload).map(([key, value]) => {
        onboarding[key] = value;
      });
      state.onboarding = onboarding;
    },
    updateListInstructor(
      state,
      action: PayloadAction<
        Pick<InstructorListSerializer, 'id'> & Partial<InstructorListSerializer>
      >
    ) {
      state.schedulable = state.schedulable.map((instructor) =>
        instructor.id === action.payload.id
          ? { ...instructor, ...action.payload }
          : instructor
      );
    },
    clearInstructorDetail(state) {
      state.details = undefined;
      state.onboarding = undefined;
    },
  },
});

const {
  patchOnboarding,
  fetchInstructorListStart,
  fetchInstructorListFailed,
  fetchInstructorListSuccess,
  fetchInstructorDetailStart,
  fetchInstructorDetailFailed,
  fetchInstructorDetailSuccess,
} = Slice.actions;
export const {
  clearInstructorDetail,
  invalidateScheduleInstructors,
  fetchInstructorOnboardingSuccess,
} = Slice.actions;

export const fetchScheduleInstructors =
  () => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const username = getUsername(state);
    const listFetchState = getInstructorListFetchState(state);
    if (
      listFetchState === FETCH_STATE.GET ||
      listFetchState === FETCH_STATE.FULFILLED
    ) {
      return null;
    }
    dispatch(fetchInstructorListStart());
    try {
      // const response = await api.instructors.list();
      const response = await api.account.instructors(username);
      dispatch(fetchInstructorListSuccess(response.data));
      return response.data;
    } catch (error: any) {
      dispatch(fetchInstructorListFailed());
      return [];
    }
  };

export const fetchInstructorOnboarding =
  (username: string) => async (dispatch, getState) => {
    try {
      const response = await api.account.onboarding(username);
      dispatch(fetchInstructorOnboardingSuccess(response.data));
      return response.data;
    } catch (err) {
      logger.error(err);
      enqueueSnackbar(GenericServerError);
      return undefined;
    }
  };

export const modifyOnboarding =
  (username: string, data: Partial<InstructorOnboardingSerializer>) =>
  async (dispatch, getState) => {
    try {
      dispatch(patchOnboarding(data));
      const response = await api.account.onboarding(username, data);
      return response.data;
    } catch (err) {
      logger.error(err);
      enqueueSnackbar(GenericServerError);
      return undefined;
    }
  };

export const fetchInstructorDetail =
  (username: string) => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const fetchState = getInstructorFetchState(state);
    if (fetchState === FETCH_STATE.GET) {
      return null;
    }
    dispatch(fetchInstructorDetailStart());
    return Promise.all([
      api.account.retrieve(username),
      api.account.onboarding(username),
    ])
      .then((responses) => {
        dispatch(fetchInstructorOnboardingSuccess(responses[1].data));
        // Last so fetchState is updated at the correct time
        dispatch(
          fetchInstructorDetailSuccess(responses[0].data as InstructorAccount)
        );
        return responses[0].data as InstructorAccount;
      })
      .catch((error) => {
        dispatch(fetchInstructorDetailFailed());
        return error as AxiosError;
      });
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
