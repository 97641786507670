import { UserSerializer } from 'api/Serializers/Users';
import { MenuLink, NewsMenuItem } from 'components/account/menu';
import { HeaderTheme, HeaderVariant } from 'components/app-header';
import Avatar from 'components/avatar';
import Link from 'components/link';
import Logo from 'components/logo';
import { UserType } from 'config';
import { AccountIcon, HelpIcon, LogoutIcon } from 'icons';
import { AdminMenuItems } from 'pages/account/admin/utils';
import { ClientMenuItems, CLIENT_ROUTES } from 'pages/account/client/utils';
import { HostMenuItems } from 'pages/account/host/utils';
import {
  InstructorMenuItems,
  INSTRUCTOR_ROUTES,
} from 'pages/account/instructor/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { getProxy } from 'state/selectors';
import { APP_ROUTES, SHOW_HELP } from 'utils/routing';

interface Props {
  user: UserSerializer;
  pathname: string;
  theme: HeaderTheme;
  onLogoutClick(): void;
}

const getHelpLink = (userType: UserType) =>
  userType === UserType.Instructor
    ? SHOW_HELP.TEACHING.ROOT
    : userType === UserType.Client
    ? SHOW_HELP.LEARNING.ROOT
    : userType === UserType.Host
    ? SHOW_HELP.HOSTING.ROOT
    : userType === UserType.Admin
    ? SHOW_HELP.ROOT
    : SHOW_HELP.LEARNING.ROOT;

const MobileMenu = ({ user, pathname, theme, onLogoutClick }: Props) => {
  const proxy = useSelector(getProxy);
  const MenuItems =
    user.type === UserType.Admin
      ? AdminMenuItems
      : user.type === UserType.Instructor
      ? InstructorMenuItems
      : user.type === UserType.Host
      ? HostMenuItems
      : user.type === UserType.Client
      ? ClientMenuItems
      : [];
  return (
    <div className="my-6 w-72">
      <ul className="p-0 py-6 m-0 list-none">
        <li className="relative flex flex-wrap my-1">
          <div className="flex items-center p-2">
            {user.avatar !== '' ? (
              <Avatar src={user.avatar} diameter={20} variant="rounded" />
            ) : (
              <span className="text-blue-500 text-icon-lg leading-0">
                <AccountIcon fontSize="inherit" />
              </span>
            )}
            <div className="flex-1 ml-3">
              <div className="text-lg font-semibold text-gray-600">
                {user.displayName}
              </div>
              <div className="text-xs font-bold text-gray-500">
                {user.type === UserType.Instructor
                  ? 'Swim Instructor'
                  : user.type === UserType.Client
                  ? 'Learning Swimmer'
                  : user.type === UserType.Host
                  ? 'Pool Owner'
                  : user.type === UserType.Admin
                  ? 'Propel Admin'
                  : 'Surfer'}
              </div>
              {proxy && (
                <div className="text-white bg-blue-400 border-blue-300 badge">
                  Proxy
                </div>
              )}
            </div>
          </div>
        </li>
        <hr className="-mx-4" />
        <li className="relative flex flex-wrap my-1">
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-home"
              to={APP_ROUTES.HOME}
              Icon={(props) => <Logo {...props} />}
              text="Home"
              isActive={pathname === APP_ROUTES.HOME}
            />
          </div>
        </li>
        {MenuItems.filter(
          (item) =>
            item.Icon && item.text && (!item.isHidden || !item.isHidden(user))
        ).map(
          (item, i) =>
            item.text && (
              <li key={`link-${i}`} className="relative flex flex-wrap my-1">
                <div className="flex flex-1 flex-nowrap">
                  <MenuLink
                    id={item.id}
                    to={item.route.ROOT}
                    beta={item.beta}
                    Icon={item.Icon}
                    text={item.text}
                    isActive={item.isActive(pathname)}
                    listSelector={item.listSelector}
                  />
                </div>
              </li>
            )
        )}
        <li className="relative flex flex-wrap mt-6 mb-1">
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-help"
              onClick={getHelpLink(user.type)}
              Icon={HelpIcon}
              text="Help"
              isActive={false}
            />
          </div>
        </li>
        <NewsMenuItem />
        <li className="relative flex flex-wrap my-1">
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-logout"
              onClick={onLogoutClick}
              Icon={LogoutIcon}
              text="Logout"
              isActive={false}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

const activeBorderTrans = 'border-white';
const inactiveHoverTrans = 'hover:border-white';
const activeBorderWhite = 'border-gray-600';
const inactiveHoverWhite = 'hover:border-gray-700';
const DesktopMenu = ({ user, pathname, theme, onLogoutClick }: Props) => {
  const proxy = useSelector(getProxy);
  const isDashboardActive =
    pathname.indexOf(INSTRUCTOR_ROUTES.DASHBOARD.ROOT) > -1;
  const liClasses = `p-0 mx-3 border-b-2 border-solid border-transparent relative `;
  const linkClasses = `flex items-center py-2 text-sm font-medium ${
    theme === HeaderTheme.Transparent
      ? 'text-white hover:text-gray-100'
      : 'text-gray-600 hover:text-gray-700'
  }`;
  return (
    <div className="flex items-center justify-center py-2">
      <ul className="flex p-0">
        <li
          className={`${liClasses} ${
            theme === HeaderTheme.Transparent
              ? isDashboardActive
                ? activeBorderTrans
                : inactiveHoverTrans
              : isDashboardActive
              ? activeBorderWhite
              : inactiveHoverWhite
          }`}
        >
          <Link
            id="nav-dashboard"
            className={linkClasses}
            to={APP_ROUTES.ACCOUNT}
          >
            <span className="">{user.displayName}</span>
          </Link>
          {proxy && (
            <div
              className="absolute left-0 px-2 top-1/2"
              style={{ transform: 'translate(-100%, -50%)' }}
            >
              <span className="text-white bg-blue-400 border-blue-300 badge">
                Proxy
              </span>
            </div>
          )}
        </li>
        <li
          className={`${liClasses} ${
            theme === HeaderTheme.Transparent
              ? pathname === ''
                ? activeBorderTrans
                : inactiveHoverTrans
              : pathname === ''
              ? activeBorderWhite
              : inactiveHoverWhite
          }`}
        >
          <button className={linkClasses} onClick={getHelpLink(user.type)}>
            <span className="">Help</span>
          </button>
        </li>
        {user.type === UserType.Client ? (
          <li
            className={`rounded-full mx-3 px-3 font-semibold btn-cta ${
              pathname.indexOf(APP_ROUTES.BOOK.ROOT) === 0
                ? 'hidden'
                : theme === HeaderTheme.Transparent
                ? 'white'
                : 'blue'
            }`}
          >
            <Link
              className={`flex items-center py-2 text-inherit text-sm font-semibold`}
              to={CLIENT_ROUTES.SEARCH.ROOT}
            >
              <span className="">Book Now</span>
            </Link>
          </li>
        ) : (
          <li
            className={`${liClasses} ${
              theme === HeaderTheme.Transparent
                ? pathname === ''
                  ? activeBorderTrans
                  : inactiveHoverTrans
                : pathname === ''
                ? activeBorderWhite
                : inactiveHoverWhite
            }`}
          >
            <button className={linkClasses} onClick={onLogoutClick}>
              <span className="">Logout</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

const LoggedInMenu = ({
  user,
  pathname,
  variant,
  theme,
  onLogoutClick,
}: Props & { variant: HeaderVariant }) =>
  variant === HeaderVariant.Desktop ? (
    <DesktopMenu
      user={user}
      pathname={pathname}
      theme={theme}
      onLogoutClick={onLogoutClick}
    />
  ) : (
    <MobileMenu
      user={user}
      pathname={pathname}
      theme={theme}
      onLogoutClick={onLogoutClick}
    />
  );

export default LoggedInMenu;
