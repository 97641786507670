import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES, SHARED_ROUTES } from 'utils/routing';

const DoNotScrollUrls = [
  '/account/instructors',
  SHARED_ROUTES.FACILITIES.ROOT,
  INSTRUCTOR_ROUTES.ONBOARDING.SET_FACILITY,
  APP_ROUTES.BOOK.ROOT,
];

const doNotScroll = (pathname: string) =>
  !pathname
    ? false
    : DoNotScrollUrls.findIndex((url) => pathname.indexOf(url) > -1) > -1;

const ScrollToTop = (props) => {
  const [lastPath, setLastPath] = useState(undefined);
  const { pathname } = useLocation();
  useEffect(() => {
    if (doNotScroll(pathname) && doNotScroll(lastPath)) {
      setLastPath(pathname);
      return;
    }
    window.scrollTo(0, 0);
    setLastPath(pathname);
  }, [pathname]);
  return props.children;
};

export default ScrollToTop;
