import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { ClientSerializer } from 'api/Serializers/Clients';
import { FETCH_STATE } from 'config';
import { GenericServerError } from 'lang/en/Snackbars';
import { enqueueSnackbar } from 'notistack';
import { getClientListFetchState } from 'state/selectors';
import { AppDispatch } from 'state/store';

interface ClientsReducer {
  list: ClientSerializer[];
  detail: ClientSerializer;
  listFetchState: FETCH_STATE;
}

const initialState: ClientsReducer = {
  list: [],
  detail: undefined,
  listFetchState: FETCH_STATE.IDLE,
};

const name: 'clients' = 'clients';
const Slice = createSlice({
  name,
  initialState,
  reducers: {
    fetchListStart(state) {
      state.listFetchState = FETCH_STATE.GET;
    },
    fetchListFailed(state) {
      state.listFetchState = FETCH_STATE.FAILED;
    },
    fetchListSuccess(state, action: PayloadAction<ClientSerializer[]>) {
      state.list = action.payload;
      state.listFetchState = FETCH_STATE.FULFILLED;
    },
    setClientDetail(state, action: PayloadAction<ClientSerializer>) {
      state.detail = action.payload;
    },
    clearClientDetail(state) {
      state.detail = undefined;
    },
    clear(state) {
      state.list = undefined;
      state.detail = undefined;
      state.listFetchState = FETCH_STATE.IDLE;
    },
  },
});

export const { clearClientDetail, setClientDetail } = Slice.actions;
const { fetchListSuccess, clear, fetchListStart, fetchListFailed } =
  Slice.actions;

export const clearClients = () => (dispatch: AppDispatch) => {
  return dispatch(clear());
};

export const fetchClients = () => async (dispatch: AppDispatch, getState) => {
  const state = getState();
  const fetchState = getClientListFetchState(state);
  if (fetchState === FETCH_STATE.GET || fetchState === FETCH_STATE.FULFILLED) {
    return null;
  }
  dispatch(fetchListStart());
  try {
    const response = await api.clients.list();
    dispatch(fetchListSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(fetchListFailed());
    enqueueSnackbar(GenericServerError);
    return [];
  }
};

export const fetchClient =
  (username: string) => async (dispatch: AppDispatch, getState) => {
    try {
      const response = await api.clients.retrieve(username);
      dispatch(setClientDetail(response.data));
      return response.data;
    } catch (error) {
      enqueueSnackbar(GenericServerError);
      return undefined;
    }
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
