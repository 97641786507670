import { CityListItem } from 'api/Serializers/Activities';
import Link from 'components/link';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSearchCities } from 'state/selectors';
import {
  APP_ROUTES,
  EXTERNAL_ROUTES,
  SHARED_ROUTES,
  SHOW_HELP,
} from 'utils/routing';

const Instagram = require('assets/images/icons/instagram.png');
const Twitter = require('assets/images/icons/twitter.png');
const Facebook = require('assets/images/icons/facebook.png');
const Linkedin = require('assets/images/icons/linkedin.png');
const Logo =
  'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xvZ29zL3doaXRlLnBuZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MzkwLCJoZWlnaHQiOjk2fX0sImJ1Y2tldCI6Im9sZWtzaWFrIn0=';

type Variant = 'default' | 'minimal' | 'hidden';

const HIDDEN_ROUTES = [
  APP_ROUTES.NEW_FACILITY,
  SHARED_ROUTES.SCHEDULE.ROOT,
  SHARED_ROUTES.MESSAGES.ROOT,
  '/instructor-review/',
  '/account',
];

const MINIMAL_ROUTES = [APP_ROUTES.BOOK.ROOT];

const Footer = () => {
  const { pathname } = useLocation();
  const cities = useSelector(getSearchCities);
  const [variant, setVariant] = useState<Variant>('default');

  useEffect(() => {
    let v: Variant = 'default';
    if (HIDDEN_ROUTES.findIndex((r) => pathname.indexOf(r) === 0) > -1) {
      v = 'hidden';
    } else if (
      MINIMAL_ROUTES.findIndex((r) => pathname.indexOf(r) === 0) > -1
    ) {
      v = 'minimal';
    }
    setVariant(v);
  }, [pathname]);

  if (variant === 'hidden') {
    return null;
  } else if (variant === 'minimal') {
    return (
      <footer className="text-white bg-gray-800 ">
        <div className="max-w-screen-xl px-8 mx-auto md:px-20">
          <div className="py-6">
            <div>
              <span className="text-sm">
                &copy; {new Date().getFullYear()} Propel HQ Inc.
              </span>
              <ul className="inline-block">
                <li className="inline-block">
                  <span className="inline-block w-5 h-5 text-center">·</span>
                  <Link className="text-sm text-white" to={APP_ROUTES.PRIVACY}>
                    Privacy
                  </Link>
                </li>
                <li className="inline-block">
                  <span className="inline-block w-5 h-5 text-center">·</span>
                  <Link
                    className="text-sm text-white"
                    to={APP_ROUTES.TERMS_OF_SERVICE}
                  >
                    Terms
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <div className="text-white bg-gray-800 section !py-16">
      <div className="max-w-6xl mx-auto">
        <div className="space-y-6">
          <div className="grid grid-cols-3 gap-6 sm:grid-cols-5">
            <div className="">
              <h4 className="mb-6 text-xl font-black text-white uppercase">
                Learn
              </h4>
              <ul className="space-y-1.5">
                <li>
                  <Link className="white" to={APP_ROUTES.LEARN_MORE.SWIMMERS}>
                    Learn more
                  </Link>
                </li>
                <li>
                  <Link className="white" to={APP_ROUTES.BROWSE_CITIES}>
                    Book now
                  </Link>
                </li>
                <li>
                  <button
                    className="font-semibold text-left hover:underline underline-offset-4"
                    onClick={SHOW_HELP.PRICING}
                  >
                    Pricing
                  </button>
                </li>
                <li>
                  <Link className="white" to={APP_ROUTES.GIFT_CARDS.ROOT}>
                    Gift cards
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <h4 className="mb-6 text-xl font-black text-white uppercase">
                Teach
              </h4>
              <ul className="space-y-1.5">
                <li>
                  <Link
                    className="white"
                    to={APP_ROUTES.LEARN_MORE.INSTRUCTORS}
                  >
                    Learn more
                  </Link>
                </li>
                <li>
                  <Link className="white" to={APP_ROUTES.SIGN_UP}>
                    Sign up
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <h4 className="mb-6 text-xl font-black text-white uppercase">
                Host
              </h4>
              <ul className="space-y-1.5">
                <li>
                  <Link
                    className="white"
                    to={EXTERNAL_ROUTES.HOST_LANDING_PAGES.COMMERCIAL}
                  >
                    Hotels & Gyms
                  </Link>
                </li>
                <li className="">
                  <Link
                    className="white"
                    underline={false}
                    to={EXTERNAL_ROUTES.HOST_LANDING_PAGES.RESIDENTIAL}
                  >
                    Home pools
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <h4 className="mb-6 text-xl font-black text-white uppercase">
                About
              </h4>
              <ul className="space-y-1.5">
                <li>
                  <Link className="white" to={APP_ROUTES.ABOUT_US}>
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    className="white"
                    underline={false}
                    target="_blank"
                    to={EXTERNAL_ROUTES.BLOG.ROOT}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className="white" to={APP_ROUTES.TERMS_OF_SERVICE}>
                    Terms of service
                  </Link>
                </li>
                <li>
                  <Link className="white" to={APP_ROUTES.PRIVACY}>
                    Privacy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <h4 className="mb-6 text-xl font-black text-white uppercase">
                Help
              </h4>
              <ul className="space-y-1.5">
                <li>
                  <button
                    className="font-semibold text-left hover:underline underline-offset-4"
                    onClick={SHOW_HELP.LEARNING.ROOT}
                  >
                    For swimmers
                  </button>
                </li>
                <li>
                  <button
                    className="font-semibold text-left hover:underline underline-offset-4"
                    onClick={SHOW_HELP.TEACHING.ROOT}
                  >
                    For instructors
                  </button>
                </li>
                <li>
                  <button
                    className="font-semibold text-left hover:underline underline-offset-4"
                    onClick={SHOW_HELP.HOSTING.ROOT}
                  >
                    For hosts
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-3 gap-4 sm:grid-cols-5 ">
            {cities &&
              Object.entries<CityListItem[]>(
                [...cities]
                  .sort((a, b) => a.regionOrder - b.regionOrder)
                  .groupBy((city) => city.regionCode)
              ).map(([regionCode, cities], k) => (
                <div key={`prov-${k}`}>
                  <h4 className="mb-6 text-xl font-black text-white uppercase">
                    {regionCode}
                  </h4>
                  <ul className="space-y-1.5">
                    {cities.map((city, key) => (
                      <li key={`city-${key}`}>
                        <Link
                          className="white"
                          to={`${APP_ROUTES.BROWSE_CITIES}/${city.slug}?ref=footer`}
                        >
                          {city.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col items-center justify-between w-full mt-16">
          <div className="flex flex-col items-center md:flex-row">
            <div>
              <Link to="/">
                <img style={{ width: 220, marginLeft: -5 }} src={Logo} />
              </Link>
            </div>
            <div className="hidden h-6 ml-5 border-l border-white md:block" />
            <div className="w-full h-px mt-4 border-t border-white md:hidden" />
            <div className="flex gap-4 mt-6 md:mt-0 md:ml-6">
              <a
                target="_blank"
                className="mx-auto"
                rel="noopener"
                href="https://www.instagram.com/propelhq/"
              >
                <img src={Instagram} />
              </a>
              <a
                target="_blank"
                className="mx-auto"
                rel="noopener"
                href="https://twitter.com/propel"
              >
                <img src={Twitter} />
              </a>
              <a
                target="_blank"
                className="mx-auto"
                rel="noopener"
                href="https://www.facebook.com/propelhq/"
              >
                <img src={Facebook} />
              </a>
              <a
                target="_blank"
                className="mx-auto"
                rel="noopener"
                href="https://www.linkedin.com/company/3003681/"
              >
                <img src={Linkedin} />
              </a>
            </div>
          </div>
          <div className="mt-6">
            <div>&copy; {new Date().getFullYear()} Propel HQ Inc.</div>
            <div className="mt-2">Made with ❤️ in Canada</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
