import { DialogContent, DialogTitle } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CloseIcon } from 'icons';
import React, { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  /**
   * A name to use when tracking this modal's interaction
   */
  name: string;
  /**
   * Function called to handle closing the modal. If not provided
   * the modal will not be closable
   */
  onClose?: (e?) => void;
  variant?: 'default' | 'borderless';
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  fullScreen?: boolean;
  title?: string;
  disableBackdropClick?: boolean;
  /** Set true to allow things like Select components to overflow */
  allowOverflow?: boolean;
  className?: string;
  children?: any;
  disableEnforceFocus?: boolean;
}

const Modal = ({
  open,
  onClose,
  className,
  children,
  name,
  disableBackdropClick = false, // BROKEN
  allowOverflow = false,
  title,
  variant = 'default',
  maxWidth = false,
  fullWidth,
  fullScreen,
  disableEnforceFocus,
}: Props) => {
  const theme = useTheme();

  const isFullscreen =
    fullScreen ?? useMediaQuery(theme.breakpoints.down('sm'));

  const variantStyle =
    variant === 'default' ? 'p-6 sm:py-6 sm:px-8' : 'p-0 sm:pt-0';

  const handleClose = (event, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!onClose) {
      return;
    } else if (disableBackdropClick && !!reason) {
      return;
    } else {
      rudderanalytics.track('Modal closed', {
        modalName: name,
      });
      onClose();
    }
  };

  useEffect(() => {
    if (name && open) {
      rudderanalytics.track(`Modal opened`, { name });
    }
  }, [name, open]);

  return (
    <MuiDialog
      maxWidth={maxWidth}
      fullScreen={isFullscreen}
      fullWidth={fullWidth}
      open={open}
      scroll="body"
      TransitionComponent={Transition}
      onClose={handleClose}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      disableEnforceFocus={disableEnforceFocus}
      sx={
        allowOverflow
          ? {
              '& .MuiPaper-root': { overflowY: 'visible' },
              '& .MuiDialog-paper': { overflowY: 'visible' },
            }
          : null
      }
      aria-labelledby="dialog-title"
    >
      {!!title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
      {!!onClose && (
        <div className={`z-50 flex items-center absolute top-3 right-3`}>
          <button className="btn-icon gray" onClick={onClose}>
            <CloseIcon width={24} />
          </button>
        </div>
      )}
      <DialogContent className="!p-0 !overflow-y-visible" dividers={!!title}>
        <div className={`${variantStyle}${cls(className)} text-gray-800`}>
          {children}
        </div>
      </DialogContent>
    </MuiDialog>
  );
};

export default Modal;
