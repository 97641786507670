import AppointmentCard from 'components/appointment-card';
import Loading from 'components/loading';
import { FETCH_STATE } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useTimeoutRefresh from 'hooks/useTimeoutRefresh';
import {
  AssistanceWizardProps,
  Report,
  ReportAction,
  ReportCategory,
  ReportResolution,
  ReportResult,
  ReportType,
} from 'models/Assistance';
import { RouteParams } from 'models/route';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getAppointment,
  getAppointmentFetchState,
  getAssistanceReport,
} from 'state/selectors';
import { clearAppointment, fetchAppointment } from 'state/slice/appointments';
import {
  deleteReportData,
  processReportAction,
  setReportCategory,
  setReportResolution,
  setReportResult,
  setReportType,
  upsertReportAction,
} from 'state/slice/assistance';
import { SHARED_ROUTES } from 'utils/routing';

const AssistancePage = ({
  ReportWizard,
}: {
  ReportWizard(props: AssistanceWizardProps): JSX.Element;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const refreshInt = useTimeoutRefresh();
  const match = useRouteMatch<RouteParams>();
  const id = match.params.appointmentId;
  const appointment = useSelector(getAppointment);
  const fetchState = useSelector(getAppointmentFetchState);
  const state = useSelector(getAssistanceReport) as Report;
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !state.event ||
      state.event !== match.params.appointmentId ||
      state.state !== FETCH_STATE.IDLE
    ) {
      trackAction({
        type: 'START',
        key: 'event',
        value: match.params.appointmentId,
      });
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchAppointment(id));
    } else {
      dispatch(clearAppointment());
      history.push(SHARED_ROUTES.SCHEDULE.ROOT);
    }
  }, [refreshInt]);

  useEffect(() => {
    if (fetchState === FETCH_STATE.FAILED) {
      enqueueSnackbar({
        message:
          'Error encountered when getting appointment information, please try again',
        variant: 'error',
      });
      history.push(SHARED_ROUTES.SCHEDULE.ROOT);
    }
  }, [fetchState]);

  const trackAction = (d: ReportAction) => {
    return dispatch(processReportAction(d));
  };
  const getReportData = (key: string, def = undefined) => {
    const data = state.actions?.find((data: ReportAction) => data.key === key);
    return data !== undefined ? data.value : def;
  };
  const addReportData = (d: ReportAction) => {
    return dispatch(upsertReportAction(d));
  };
  const removeReportData = (key: string) => {
    return dispatch(deleteReportData({ key }));
  };

  const setCategory = (data: ReportCategory) => {
    return dispatch(setReportCategory(data));
  };
  const setResolution = (data: ReportResolution) => {
    return dispatch(setReportResolution(data));
  };
  const setResult = (data: ReportResult) => {
    return dispatch(setReportResult(data));
  };
  const setType = (data: ReportType) => {
    return dispatch(setReportType(data));
  };
  if (!appointment || fetchState === FETCH_STATE.GET) {
    return <Loading />;
  } else if (fetchState === FETCH_STATE.FAILED) {
    return <Loading />;
  }

  const props: AssistanceWizardProps = {
    appointment,
    state,
    setCategory,
    setResolution,
    setResult,
    setType,
    trackAction,
    getReportData,
    addReportData,
    removeReportData,
  };
  return (
    <div className="flex justify-center space-x-8 sm:py-12">
      <div className="w-full max-w-md sm:w-112">
        <div className="w-full bg-white rounded-lg shadow-md py-card px-card">
          <AppointmentCard appointment={appointment} />
          <hr />
          <ReportWizard {...props} />
        </div>
      </div>
    </div>
  );
};
export default AssistancePage;
