import api from 'api';
import { Participant } from 'api/Serializers/Clients';
import Button from 'components/button';
import Controls from 'components/controls';
import Modal from 'components/modal';
import CreateNewParticipant from 'containers/participant-create';
import { EditIcon } from 'icons';
import { GenericServerError } from 'lang/en/Snackbars';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountParticipants, getUsername } from 'state/selectors';

const SelectParticipant = ({
  participantNoun,
  availableParticipants,
  setAvailableParticipants,
  selectedParticipant,
  selectedParticipants,
  errorIfEmpty,
  onSelect,
  username,
  disabled = false,
}: {
  participantNoun: string;
  availableParticipants: Participant[];
  setAvailableParticipants: React.Dispatch<React.SetStateAction<Participant[]>>;
  selectedParticipant: Participant;
  selectedParticipants: Participant[];
  errorIfEmpty: boolean;
  onSelect(participant: Participant): void;
  username: string;
  disabled?: boolean;
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);

  const handleSelect = (participant: Participant) => {
    onSelect(participant);
    setIsSelecting(false);
    setIsCreating(false);
    rudderanalytics.track('Participant selected', { participant });
  };

  const handleCreateClick = () => {
    setIsCreating(true);
    rudderanalytics.track('Create Participant selected');
  };

  const handleClickEdit = () => {
    if (availableParticipants.length === 0) {
      setIsCreating(true);
    } else {
      setIsSelecting(true);
    }
  };

  return (
    <>
      <div className="flex-1">
        <button
          className="flex items-center px-4 py-3 justify-between w-full border border-gray-600 hover:text-gray-900 hover:border-gray-900 rounded-lg min-h-[45px]"
          onClick={handleClickEdit}
        >
          <span
            className={`flex-1 text-left${
              selectedParticipant ? ' font-semibold' : ''
            }`}
          >
            {availableParticipants.length === 0
              ? `Create new ${participantNoun.toLowerCase()}`
              : selectedParticipant
              ? selectedParticipant.name
              : `Select ${participantNoun.toLowerCase()}`}
          </span>
          <span>
            <EditIcon width={24} />
          </span>
        </button>
      </div>
      <Modal
        name="Select participant"
        title={`Select ${participantNoun.toLowerCase()}`}
        open={isSelecting}
        maxWidth="xs"
        fullWidth
        variant="borderless"
      >
        {isSelecting && (
          <div className="p-6 pb-0">
            <ul className="my-2">
              {availableParticipants?.length > 0 &&
                availableParticipants.map((participant) => {
                  const isDisabled = selectedParticipants.some(
                    (p, i) =>
                      p.id !== selectedParticipant?.id &&
                      p.id === participant.id
                  );
                  const selected = selectedParticipant?.id === participant.id;
                  return (
                    <li className="-mx-6">
                      <button
                        disabled={isDisabled}
                        className={`w-full text-left transition-colors duration-100 py-3 px-6  ${
                          selected
                            ? 'bg-blue-500 text-white'
                            : isDisabled
                            ? 'bg-white text-gray-500'
                            : 'text-gray-800 hover:bg-blue-200 hover:text-gray-900'
                        }`}
                        onClick={() => handleSelect(participant)}
                      >
                        {participant.name}
                      </button>
                    </li>
                  );
                })}
            </ul>
            <Controls>
              <Button onClick={() => setIsSelecting(false)}>Close</Button>
              <Button variant="flat" onClick={handleCreateClick}>
                Add new {participantNoun.toLowerCase()}
              </Button>
            </Controls>
          </div>
        )}
      </Modal>
      <Modal
        name="Create New Participant"
        open={isCreating}
        title={`Create new ${participantNoun.toLowerCase()}`}
        onClose={() => setIsCreating(false)}
        maxWidth="xs"
        fullWidth
        disableEnforceFocus={true}
      >
        <CreateNewParticipant
          username={username}
          onCreated={handleSelect}
          onClose={() => setIsCreating(false)}
          setParticipantState={setAvailableParticipants}
        />
      </Modal>
    </>
  );
};

const SelectParticipants = ({
  username,
  participantNoun,
  numParticipants,
  selectedParticipants,
  errorIfEmpty,
  onSelect,
  disabled = false,
}: {
  username: string;
  participantNoun: string;
  numParticipants: number;
  selectedParticipants: Participant[];
  errorIfEmpty: boolean;
  onSelect(position: number, participant: Participant): void;
  disabled?: boolean;
}) => {
  const accountUsername = useSelector(getUsername);
  const accountParticipants = useSelector(getAccountParticipants);
  const [availableParticipants, setAvailableParticipants] = useState(
    username === accountUsername ? accountParticipants : []
  );

  useEffect(() => {
    if (username === accountUsername) {
      setAvailableParticipants(accountParticipants);
    }
  }, [username, accountParticipants]);

  useEffect(() => {
    (async () => {
      if (username !== accountUsername) {
        try {
          const response = await api.clients.participants(username);
          setAvailableParticipants(response.data as Participant[]);
        } catch (err) {
          enqueueSnackbar(GenericServerError);
        }
      }
    })();
  }, []);

  return (
    <>
      {Array(numParticipants)
        .fill(0)
        .map((_, i) => (
          <div
            key={`p-${i}`}
            className="flex items-center justify-between flex-1 space-x-2"
          >
            <SelectParticipant
              disabled={disabled}
              username={username}
              errorIfEmpty={errorIfEmpty}
              participantNoun={participantNoun}
              availableParticipants={availableParticipants}
              setAvailableParticipants={setAvailableParticipants}
              selectedParticipant={selectedParticipants[i]}
              selectedParticipants={selectedParticipants}
              onSelect={(participant: Participant) => onSelect(i, participant)}
            />
          </div>
        ))}
    </>
  );
};

export default SelectParticipants;
