import Slider from '@mui/material/Slider';
import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Modal from 'components/modal';
import {
  celsiusToFahrenheit,
  fahrenheitToCelsius,
} from 'features/assistance/as-instructor/utils';
import AttachImages from 'features/assistance/components/images';
import { AssistanceWizardProps, ReportResolution } from 'models/Assistance';
import React, { useState } from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const FacilityColdView = (props: AssistanceWizardProps) => {
  const {
    appointment,
    state,
    trackAction,
    addReportData,
    getReportData,
    removeReportData,
  } = props;
  const [error, setError] = useState('');
  const stateCelsius = getReportData('isCelsius', true) as boolean;
  const stateNoThermo = getReportData('noThermo', false) as boolean;
  const defaultC = parseInt(appointment.facility.temperature.split('-')[0]);
  const defaultF = celsiusToFahrenheit(defaultC);
  const stateTemp = getReportData(
    'temperature',
    stateCelsius ? defaultC : defaultF
  ) as number;
  const [isCelsius, setCelsius] = useState<boolean>(stateCelsius);
  const [noThermo, setNoThermo] = useState(stateNoThermo);
  const [temperature, setTemperature] = useState<number>(stateTemp);
  const hasSubmittedAlready = getReportData('temperature') !== undefined;
  const tempRange = isCelsius
    ? [defaultC - 2, defaultC + 2]
    : [defaultF - 4, defaultF + 4];
  const tooColdCutoffC = defaultC - 2;

  const handleUnitChange = () => {
    setTemperature(
      isCelsius
        ? celsiusToFahrenheit(temperature)
        : fahrenheitToCelsius(temperature)
    );
    setCelsius(!isCelsius);
  };

  const handleSliderTempChange = (_, value) => {
    if (temperature !== value) {
      setTemperature(value);
    }
  };

  const handleNoThermometer = () => {
    setNoThermo(true);
  };

  const canContinue = state.images && state.images.length > 0;

  const handleNext = () => {
    if (!canContinue) {
      setError('Please attach a photo of the thermometer before continuing');
    } else {
      const description = `The temperature was measured to be ${temperature}°${
        isCelsius ? 'C' : 'F'
      }`;
      const tempC = isCelsius ? temperature : fahrenheitToCelsius(temperature);
      removeReportData('noThermo');
      addReportData({
        type: 'SET_DATA',
        key: 'temperature',
        value: temperature,
        title: 'Temperature',
        description,
      });
      addReportData({
        type: 'SET_DATA',
        key: 'isCelsius',
        value: isCelsius,
      });
      if (tempC <= tooColdCutoffC) {
        trackAction({
          type: 'SET_RESOLUTION',
          key: 'resolution',
          value: 'FACILITY_TOO_COLD' as ReportResolution,
          title: 'Policy decision',
          description: 'The water is too cold to continue',
        });
        trackAction({
          type: 'SET_RESULT',
          key: 'result',
          value: 'FACILITY_NOT_AVAILABLE',
        });
      } else {
        trackAction({
          type: 'SET_RESOLUTION',
          key: 'resolution',
          value: 'PROPEL_REVIEW' as ReportResolution,
          title: 'Policy decision',
          description:
            "The temperature is within the pool's posted range, so the lesson will continue",
        });
        trackAction({
          type: 'SET_RESULT',
          key: 'result',
          value: 'NO_CHANGE',
        });
      }
    }
  };

  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>The pool is cold</h4>
      <div className="py-3 ">
        <div className="space-y-5">
          <Callout
            type="question"
            title={`This pool is maintained at ${appointment.facility.temperature}°C`}
          >
            Is it more than 1°C outside this range?
          </Callout>
          <div className="flex flex-col gap-4 p-6 rounded-lg bg-background">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center gap-1">
                <div className="text-2xl font-bold leading-none text-right">
                  {!temperature
                    ? '-'
                    : `${
                        temperature === tempRange[0]
                          ? '<'
                          : temperature === tempRange[1]
                          ? '>'
                          : ''
                      }` + temperature.toString()}
                </div>
                <button className="cursor-pointer" onClick={handleUnitChange}>
                  <span className="font-['courier'] text-xl font-medium text-blue-400 underline transition-all duration-200 hover:text-blue-600">
                    °{isCelsius ? 'C' : 'F'}
                  </span>
                </button>
              </div>
              <div className="flex items-center justify-center gap-2">
                <Slider
                  min={tempRange[0]}
                  max={tempRange[1]}
                  step={0.5}
                  value={temperature}
                  onChange={handleSliderTempChange}
                  disabled={hasSubmittedAlready}
                />
              </div>
            </div>
          </div>
          <AttachImages
            {...props}
            label="Include a clear photo of a thermometer and the temperature reading"
            disabled={hasSubmittedAlready}
            readonly={hasSubmittedAlready}
          />
        </div>
      </div>
      {!!error && <p className="text-red-600">{error}</p>}
      <Controls variant="block">
        <BackButton />
        <Button
          color="primary"
          variant="contained"
          to={
            canContinue
              ? SHARED_ROUTES.ASSISTANCE.nav(
                  appointment.id,
                  'facility/cold-decision'
                )
              : null
          }
          onClick={handleNext}
        >
          Continue
        </Button>
      </Controls>
      <div className="pt-5 text-sm text-center">
        <button onClick={handleNoThermometer} className="link">
          I don't have a thermometer
        </button>
      </div>
      <Modal
        open={noThermo}
        name="Thermometer is required"
        title="Temperature readings are required"
        maxWidth="xs"
        onClose={() => setNoThermo(false)}
      >
        <div className="space-y-5">
          <Callout
            type="info"
            title="A water thermometer is required equipment for teaching swim lessons."
          />
          <p>
            You should bring a thermometer to each lesson. Accurate readings
            help maintenance staff understand temperature issues better.
          </p>
          <p>If you do not have a thermometer, you must cancel the lesson.</p>
          <Controls variant="block">
            <Button
              color="primary"
              variant="contained"
              onClick={() => setNoThermo(false)}
            >
              Continue
            </Button>
          </Controls>
        </div>
      </Modal>
    </>
  );
};

export default FacilityColdView;
