import Loading from 'components/loading';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { LogoutSuccessfulMessage } from 'lang/en/Snackbars';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { logUserOut } from 'state/slice/authentication';
import style from './style.module.scss';

const Logout = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  useEffect(() => {
    document.body.classList.add(style.fullHeightBg);
    enqueueSnackbar(LogoutSuccessfulMessage);
    dispatch(logUserOut);
    return () => {
      document.body.classList.remove(style.fullHeightBg);
    };
  }, []);

  return <Loading />;
};

export default Logout;
