import React from 'react';

const DetailSection = ({
  children,
  title,
}: {
  children: any;
  title?: string;
}) => (
  <>
    <div className="px-6 py-4">
      {title && <h5 className="font-medium text-gray-900">{title}</h5>}
      <div className="mb-3">{children}</div>
    </div>
    <div className="border-b border-gray-300" />
  </>
);

export default DetailSection;
