import { IS_SERVER, LOCAL_STORAGE_KEY, SESSION_STORAGE_KEY } from 'config';
import { RootState } from 'state';

const LOCAL_STATES = [];
const SESSION_STATES = [];

export const watchSlice = (name: string, type: 'local' | 'session') => {
  if (type === 'local') {
    LOCAL_STATES.push(name);
  } else {
    SESSION_STATES.push(name);
  }
};

export const saveState = (state: RootState) => {
  if (IS_SERVER) {
    return;
  }
  const local = {};
  LOCAL_STATES.map((s) =>
    state.hasOwnProperty(s) ? (local[s] = { ...state[s] }) : false
  );
  const session = {};
  SESSION_STATES.map((s) =>
    state.hasOwnProperty(s) ? (session[s] = { ...state[s] }) : false
  );
  try {
    const serializedLocal = JSON.stringify(local);
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedLocal);
    const serializedSession = JSON.stringify(session);
    sessionStorage.setItem(SESSION_STORAGE_KEY, serializedSession);
  } catch (err) {
    // ignore, log later
  }
};
