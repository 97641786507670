import { SelfDescribedAs } from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import React, { useEffect, useState } from 'react';
import { InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  QuestionClasses,
  TitleClasses,
} from '..';

const WhatDescribesYou: React.FC<BaseStepProps> = (props) => {
  const [selected, setSelected] = useState<SelfDescribedAs>(
    props.onboarding.selfDescribedAs as SelfDescribedAs
  );
  useEffect(() => {
    if (selected && selected !== props.onboarding.selfDescribedAs) {
      props.modifyOnboarding({ selfDescribedAs: selected });
    }
  }, [selected]);
  return (
    <div>
      <h1 className={TitleClasses}>Tell us about yourself</h1>
      <p className={QuestionClasses}>What best describes you?</p>
      <div className="space-y-3">
        <ButtonLarge
          variant="radio"
          className="my-4"
          title="The Side Hustler"
          subtitle="I have other ongoing commitments and value the flexibility to work, go to school, or travel whenever I want."
          selected={selected === SelfDescribedAs.SideHustler}
          onClick={() => setSelected(SelfDescribedAs.SideHustler)}
        />
        <ButtonLarge
          variant="radio"
          className="my-4"
          title="The Veteran"
          subtitle="I have been in the industry for a long time and want to leverage my experience to earn a higher rate of pay."
          selected={selected === SelfDescribedAs.Veteran}
          onClick={() => setSelected(SelfDescribedAs.Veteran)}
        />
        <ButtonLarge
          variant="radio"
          className="my-4"
          title="The Entrepreneur"
          subtitle="I want to build a large client base and increase my price to the point where I achieve financial freedom and life balance."
          selected={selected === SelfDescribedAs.Entrepreneur}
          onClick={() => setSelected(SelfDescribedAs.Entrepreneur)}
        />
      </div>
      <div className={ControlClasses}>
        <Button
          to={InstructorOnboardingRoutes.S03}
          disabled={selected === SelfDescribedAs.Unset}
          variant="contained"
          color="primary"
        >
          Save and Continue
        </Button>
      </div>
    </div>
  );
};

export default WhatDescribesYou;
