import RotateIcon from '@mui/icons-material/RotateLeft';
import { EncodedMedia } from 'api/Serializers/Media';
import { ROTATE_BY } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import debounce from 'lodash.debounce';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail } from 'state/selectors';
import { modifyAccount } from 'state/slice/account';
import Uploader from './';
import style from './style.module.scss';

const save = debounce((dispatch, username, data, setIsLoading) => {
  setIsLoading(true);
  return dispatch(modifyAccount(username, data));
}, 1000);

const ProfileUpload = (props) => {
  const account = useSelector(getAccountDetail);
  const dispatch = useAppDispatch();
  const encodedMedia: EncodedMedia = new EncodedMedia(account.avatar);
  const [avatar, setAvatar] = useState<string>();
  const [rawUrl, setRawUrl] = useState<string>('');
  const [fakeRotation, setFakeRotation] = useState(0);
  const [realRotation, setRealRotation] = useState(encodedMedia.rotation);
  const [isLoading, setIsLoading] = useState(false);

  const onStart = () => {
    setIsLoading(true);
    setRealRotation(0);
    setFakeRotation(0);
    setAvatar(undefined);
  };

  const onFinish = (imgServerUrl: string, rawS3Url: string) => {
    setRawUrl(rawS3Url);
    setIsLoading(true);
    save(dispatch, account.username, { avatar: imgServerUrl }, setIsLoading);
    setAvatar(imgServerUrl);
  };

  const onRotate = (event: React.MouseEvent) => {
    event.stopPropagation();
    setRealRotation(realRotation + ROTATE_BY);
    setFakeRotation(fakeRotation + ROTATE_BY);
    save(
      dispatch,
      account.username,
      { avatarRotation: realRotation + ROTATE_BY },
      setIsLoading
    );
  };

  const onImageLoad = (event) => {
    setFakeRotation(0);
    setIsLoading(false);
  };

  // For future improvements, use https://github.com/mosch/react-avatar-editor?tab=readme-ov-file#readme
  const fakeStyle =
    fakeRotation !== 0
      ? {
          transform: `rotate(${fakeRotation}deg)`,
          transition: 'transform 250ms ease',
        }
      : {};
  return (
    <div>
      <div
        className={`flex items-center justify-center my-2 ${
          isLoading ? 'animate-pulse' : ''
        }`}
        style={{ width: 150, minHeight: 150 }}
      >
        {account.avatar ? (
          <div>
            <div
              className="h-full overflow-hidden rounded-lg shadow"
              style={{ width: 150, minHeight: 150 }}
            >
              <img
                className="object-cover min-h-full"
                style={fakeStyle}
                src={account.avatar}
                onLoad={onImageLoad}
              />
            </div>
            <div className="absolute bg-white rounded-full shadow right-2 top-2">
              <button
                className="p-1 text-gray-600 transition-colors duration-200 ease-out hover:text-blue-500"
                onClick={onRotate}
              >
                <RotateIcon color="inherit" className={style.icon} />
              </button>
            </div>
          </div>
        ) : (
          <div className="p-4 rounded-lg shadow">
            <span className="inline-block w-20 overflow-hidden bg-gray-300 rounded-full h-w-20">
              <svg
                className="w-full h-full text-gray-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          </div>
        )}
      </div>
      <Uploader
        title="Change"
        icon={false}
        onStart={onStart}
        onFinish={onFinish}
      />
    </div>
  );
};

export default ProfileUpload;
