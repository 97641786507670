import Article from 'components/article';
import Button from 'components/button';
import Hero from 'components/hero';
import Page from 'containers/page';
import PromoText from 'containers/promo-text';
import Row from 'layouts/row';
import { Error404 as Meta } from 'metadata';
import React from 'react';
import { APP_ROUTES, SHOW_HELP } from 'utils/routing';

const ErrorPage = () => (
  <Page {...Meta}>
    <div style={{ position: 'relative' }}>
      <Hero
        background="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hlcm9zL2RhcmstcG9vbC5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9"
        title="404: Page not found"
        subtitle="Our lessons will work wonders, but this page has been lost to the sea."
        backgroundPosition="24% 100%"
      >
        <div>
          <Button
            to={APP_ROUTES.HOME}
            variant="contained"
            shape="rounded-full"
            size="large"
            color="primary"
          >
            Return Home
          </Button>
        </div>
      </Hero>
    </div>
    <Article title="Or try these links!" theme="blue" justify={true}>
      <Row align="stretch">
        <PromoText
          title="Sign Up"
          subtitle="Learn to swim with Propel"
          description="Forget waitlists and registration headaches. Propel swimmers book today and swim tomorrow."
          buttonTitle="How Propel Works"
          icon={require('assets/images/icons/swimmers.png')}
          to={APP_ROUTES.LEARN_MORE.SWIMMERS}
        />
        <PromoText
          title="Find Pools"
          subtitle="Search for pools in your community"
          description="Propel partners with facilities in local communities to provide easy access to beautiful pools."
          buttonTitle="Book Now"
          icon={require('assets/images/icons/host.png')}
          to={APP_ROUTES.BROWSE_CITIES}
        />
        <PromoText
          title="I Need Help!"
          subtitle="Need help with something?"
          description="Read our FAQ, or get in touch with someone to answer any questions you have."
          buttonTitle="Find Help"
          icon={require('assets/images/icons/instructor.png')}
          onClick={SHOW_HELP.FOR_THIS_PAGE}
        />
      </Row>
    </Article>
  </Page>
);

export default ErrorPage;
