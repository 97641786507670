import api from 'api';
import { Event, TimeFrame } from 'api/Serializers/Appointments';
import { Participant } from 'api/Serializers/Clients';
import Button, { ListButton } from 'components/button';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Modal from 'components/modal';
import { UserType } from 'config';
import SelectParticipants from 'containers/participant-select';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { EditIcon, OneParticipantIcon, TwoParticipantIcon } from 'icons';
import { GenericServerError } from 'lang/en/Snackbars';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { fetchAppointment, fetchAppointments } from 'state/slice/appointments';
import { SectionProps, SECTION_CLASSES } from '../types';

const MAX_PARTICIPANTS = 2;

const EditParticipantsModal = ({
  open,
  onClose,
  appointment,
  onSubmit,
  participantNoun = 'swimmer',
}: {
  open: boolean;
  onClose(): void;
  appointment: Event;
  onSubmit(): Promise<void>;
  participantNoun?: string;
}) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [numParticipants, setNumParticipants] = useState(
    appointment.numParticipants
  );
  const currentParticipants: string[] | Participant[] =
    appointment.participants.length > 0
      ? appointment.participants
      : [appointment.participant1, appointment.participant2].reduce(
          (final, participant) => {
            return participant ? final.concat([participant]) : final;
          },
          []
        );
  const [participants, setParticipants] = useState(currentParticipants);

  const handleParticipantSelect = (position, participant) => {
    const newParticipants = [...participants];
    newParticipants[position] = participant;
    setParticipants(newParticipants as Participant[]);
  };

  const handleNumParticipantsChange = (num: number) => {
    if (num > MAX_PARTICIPANTS || num < 1) {
      return;
    }
    setNumParticipants(num);
    if (num === 1) {
      setParticipants([participants[0] as Participant]);
    }
    setShowError(false);
  };

  const handleSubmit = async () => {
    const isError = numParticipants !== participants.length;
    setShowError(isError);
    if (isError) return;
    if (
      // Ensure the participants have actually changed
      participants.length !== currentParticipants.length ||
      participants.some((participant, i) =>
        typeof participant === 'string'
          ? participant !== currentParticipants[i]
          : participant.name !== (currentParticipants as Participant[])[i].name
      )
    ) {
      try {
        setIsSubmitting(true);
        const data: any = {};
        if (typeof participants[0] === 'string') {
          participants.forEach((name, index) => {
            data[`participant${index + 1}`] = name;
          });
        } else {
          data.participants = participants;
        }
        await api.appointments.partialUpdate(appointment.id, data);
        await onSubmit();
        onClose();
      } catch (err) {
        enqueueSnackbar(GenericServerError);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      name="Edit Participants"
      open={open}
      onClose={onClose}
      title={`Select ${participantNoun}s`}
      maxWidth="xs"
      allowOverflow
      fullWidth
    >
      <div className="w-full">
        <div className="mb-4 space-y-3">
          <p>How many {participantNoun}s will be in the lesson?</p>
          <div className="flex gap-2 md:flex">
            <ButtonLarge
              icon={<OneParticipantIcon width={24} />}
              title={`1 ${participantNoun.capitalize()}`}
              onClick={() => handleNumParticipantsChange(1)}
              selected={numParticipants === 1}
            />
            <ButtonLarge
              icon={<TwoParticipantIcon width={24} />}
              title={`2 ${participantNoun.capitalize()}s`}
              onClick={() => handleNumParticipantsChange(2)}
              selected={numParticipants === 2}
            />
          </div>
          {appointment.participants.length !== 0 ? (
            <SelectParticipants
              username={appointment.client.username}
              errorIfEmpty={showError}
              participantNoun={participantNoun}
              numParticipants={numParticipants as 1 | 2}
              selectedParticipants={participants as Participant[]}
              onSelect={(position: number, participant: Participant) =>
                handleParticipantSelect(position, participant)
              }
            />
          ) : (
            Array.from(new Array(numParticipants), (v, i) => i).map(
              (_, position) => (
                <div
                  key={`appt-part-${position}`}
                  className="flex items-center justify-between flex-1 space-x-2"
                >
                  {
                    <input
                      type="text"
                      value={(participants as string[])[position]}
                      onChange={(e) =>
                        handleParticipantSelect(position, e.target.value)
                      }
                    />
                  }
                </div>
              )
            )
          )}
          {showError && (
            <Callout
              type="error"
              title={`Enter all ${participantNoun}s to continue`}
            />
          )}
        </div>
        <Controls className="!pb-0">
          <Button
            variant="contained"
            onClick={() => onClose()}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </Controls>
      </div>
    </Modal>
  );
};

export const EditParticipantsButton = ({
  appointment,
  userType,
}: {
  appointment: Event;
  userType: UserType;
}) => {
  const dispatch = useAppDispatch();
  const [participantsModalOpen, setParticipantsModalOpen] = useState(false);
  return (
    <>
      <ListButton
        title={`Edit ${appointment.activity.clientDescription.toLowerCase()}s`}
        onClick={() => setParticipantsModalOpen(true)}
        icon={<EditIcon width={24} />}
      />
      <EditParticipantsModal
        open={participantsModalOpen}
        onClose={() => setParticipantsModalOpen(false)}
        appointment={appointment}
        onSubmit={async () => {
          try {
            await dispatch(fetchAppointment(appointment.id));
            if (userType !== UserType.Client) {
              await dispatch(fetchAppointments());
            }
          } catch (err) {}
        }}
      />
    </>
  );
};

const ParticipantsSection = ({ appointment, userType }: SectionProps) => {
  // So we can display both current and legacy participants
  const participants: string[] | Participant[] =
    appointment.participants.length > 0
      ? appointment.participants
      : [appointment.participant1, appointment.participant2].reduce(
          (final, participant) => {
            return participant ? final.concat([participant]) : final;
          },
          []
        );

  const participantsAreEditable =
    userType === UserType.Admin ||
    (!appointment.cancelled &&
      appointment.timeFrame < TimeFrame.Locked &&
      userType === UserType.Instructor) ||
    (appointment.timeFrame < TimeFrame.HasStarted &&
      userType === UserType.Client);

  return (
    <div className={SECTION_CLASSES}>
      <h4>{appointment.activity.clientDescription}s</h4>
      <div className="space-y-2">
        {participants.map((participant: string | Participant, index) => (
          <div key={`part-${index}`}>
            <div className="labeled-icon">
              <OneParticipantIcon width={24} />
              <span className="font-semibold">
                {typeof participant !== 'string'
                  ? participant.name
                  : participant}
              </span>
            </div>
            {typeof participant !== 'string' && (
              <div className="-mt-2 ml-8">
                {participant.hasDisability && (
                  <p className="!leading-normal italic mb-0 whitespace-pre-wrap text-sm text-gray-700">
                    Has a disability
                  </p>
                )}
                <p className="!leading-normal mb-0 whitespace-pre-wrap text-sm text-gray-700">
                  {participant.notes !== ''
                    ? participant.notes
                    : 'No notes given'}
                </p>
              </div>
            )}
          </div>
        ))}
        {participantsAreEditable && (
          <EditParticipantsButton
            appointment={appointment}
            userType={userType}
          />
        )}
      </div>
    </div>
  );
};

export default ParticipantsSection;
