import ThumbUpIcon from '@mui/icons-material/ThumbUpOutlined';
import api from 'api';
import { FacilityListItemSerializer } from 'api/Serializers/Facilities';
import { InstructorDetailSerializer } from 'api/Serializers/Instructors';
import Avatar from 'components/avatar';
import FacilityCard from 'components/facility-card';
import Loading from 'components/loading';
import Redirect from 'components/redirect';
import { APP_ROOT_URL, MIN_USERNAME_LENGTH } from 'config';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'utils/routing';

const ProfileRedirect = ({
  instructor,
}: {
  instructor: InstructorDetailSerializer;
}) => {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [facilities, setFacilities] = useState<FacilityListItemSerializer[]>(
    []
  );
  async function getFacilities() {
    setIsFetching(true);
    const response = await api.instructors.facilities(instructor.slug);
    setFacilities(response.data);
    setIsFetching(false);
  }
  useEffect(() => {
    rudderanalytics.track('Instructor short link visited', { instructor });
    if (instructor.appointmentProducts.length > 1) {
      getFacilities();
    } else if (instructor.appointmentProducts.length === 1) {
      history.push(
        instructor.appointmentProducts[0].appUrl.split(APP_ROOT_URL)[1]
      );
    }
  }, []);
  if (instructor.appointmentProducts.length === 1) {
    return <Loading />;
  } else {
    const { avatar, displayName, numRecommended } = instructor;
    return (
      <div className="flex flex-wrap px-4 mx-auto my-0 lg:w-lg md:w-md sm:w-sm sm:p-0">
        <div className="block">
          <div className="flex items-center justify-center flex-1 py-8">
            <Avatar
              src={avatar}
              className="w-24 h-24 sm:w-40 sm:h-40"
              variant="rounded"
            />
            <div className="flex-shrink-0 pl-4 sm:flex-1">
              <h1 className="my-0 text-center text-gray-800 sm:text-left">
                {displayName}
              </h1>
              {numRecommended > 0 && (
                <div className="flex items-center justify-center my-2 text-gray-700 text-md sm:justify-start hover:text-blue-600">
                  <ThumbUpIcon
                    color="inherit"
                    className="w-10 h-4 mr-2"
                    fontSize="inherit"
                  />
                  <span className="text-base font-semibold">
                    {numRecommended} Recommended
                  </span>
                </div>
              )}
            </div>
          </div>
          <p className="m-0 font-semibold">
            Which location works best for you?
          </p>
        </div>
        {isFetching && <Loading />}
        <div className="my-8">
          <div className="space-y-10">
            {facilities?.map((facility) => {
              return (
                <FacilityCard
                  key={facility.id}
                  facility={facility}
                  linkGenerator={(f) =>
                    APP_ROUTES.BROWSE_INSTRUCTOR(
                      f.region.slug,
                      f.slug,
                      instructor.slug
                    )
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

const ErrorPage = () => {
  const [isInit, setIsInit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [instructor, setInstructor] = useState<InstructorDetailSerializer>();
  const { pathname } = useLocation();
  useEffect(() => {
    const urlParts = pathname.slice(1).split('?')[0].split('/');
    if (pathname.length < MIN_USERNAME_LENGTH || urlParts.length > 2) {
      // Either the possible username is too short,
      // or the URL contains more than one '/' indicating it was not meant as a user slug
      // This allows for things like www.propelhq.com/sean-h-1/ with the trailing slash
      setIsError(true);
      return;
    }
    // Since the pathname has .slice(1) applied, the start slash is removed
    // leaving the test slug in index 0
    const testSlug = urlParts[0];

    Promise.all([api.instructors.retrieve(testSlug)])
      .then((responses) => {
        setInstructor(responses[0].data);
      })
      .catch((errors) => {
        setIsError(true);
      })
      .finally(() => {
        setIsInit(true);
      });
  }, []);

  return !isInit ? (
    <Loading />
  ) : isError ? (
    <Redirect
      to={`${APP_ROUTES.NOT_FOUND}?url=${encodeURIComponent(pathname)}`}
    />
  ) : instructor ? (
    <ProfileRedirect instructor={instructor} />
  ) : (
    <Loading />
  );
};

export default ErrorPage;
