import { useState } from 'react';

interface ReturnProps<Type> {
  handleSubmit(event): void;
  handleInputChange(event): void;
  inputs: Type;
}

const useForm = <Type,>(
  initialValues: Type,
  callback: (inputs: Type) => void
): ReturnProps<Type> => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    callback(inputs);
  };
  const handleInputChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };
  return {
    handleSubmit,
    handleInputChange,
    inputs,
  };
};

export default useForm;
