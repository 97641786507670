import { AxiosResponse } from 'axios';

export type CartStatus =
  | 'EMPTY'
  | 'STARTED'
  | 'ABANDONED'
  | 'PROCESSING'
  | 'ERROR'
  | 'COMPLETE';

export enum CartError {
  NotBookable = 'not_bookable',
  MaxDailyLimit = 'max_daily_limit',
  MaxTotalLimit = 'max_total_limit',
  PaymentMethodFailed = 'payment_method_failed',
  InvalidPaymentMethod = 'invalid_payment_method',
  InvalidParticipants = 'invalid_participants',
  InvalidAvailability = 'invalid_availability',
  InvalidCartStatus = 'invalid_cart_status',
  CartIsComplete = 'cart_is_complete',
}

export type CartCheckoutMethod = 'AVAILABILITY' | 'PROPOSAL';
export type BookingSource = 'SEARCH' | 'EXPLORE' | 'PROPOSAL' | 'RECAPTURE';
export interface Cart {
  created: string;
  id: string;
  modified: string;
  availability: string;
  participants: string[];
  paymentMethod: string;
  price: string;
  status: CartStatus;
  checkoutMethod: CartCheckoutMethod;
  user: number;
}

export interface Api {
  list: () => Promise<AxiosResponse<Cart[]>>;
  create: (data) => Promise<AxiosResponse<Cart>>;
  checkout: (
    id: string,
    data: Pick<Cart, 'availability' | 'participants' | 'paymentMethod'>
  ) => Promise<AxiosResponse<Cart>>;
}
