import Loading from 'components/loading';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CLIENT_ROUTES } from '../utils';

const ClientDashboardComponent = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace(CLIENT_ROUTES.SCHEDULE.ROOT);
  }, []);
  return <Loading />;
};

export default ClientDashboardComponent;
