import React from 'react';
import { ControlClasses, HeaderClasses, TextClasses, TitleClasses } from '..';

import Button from 'components/button';
import { EXTERNAL_ROUTES } from 'utils/routing';

const ErrorTooYoung = () => {
  return (
    <div>
      <h1 className={TitleClasses}>Sorry but you're not quite old enough!</h1>
      <p className={TextClasses}>
        Instructors <strong>must</strong> be at least 18 years of age before
        they can teach with Propel.
      </p>
      <p>
        <a href={EXTERNAL_ROUTES.HELP.WHO_CAN_TEACH_WITH_PROPEL}>
          Read more here.
        </a>
      </p>
      <h2 className={HeaderClasses}>Q: Can't you make an exception?</h2>
      <p className={TextClasses}>Unfortunately, no.</p>
      <p className={TextClasses}>
        We promise every client that Propel instructors have passed a background
        check, and that is not available for people under 18 years of age.
      </p>
      <h2 className={HeaderClasses}>Q: What should I do then?</h2>
      <p className={TextClasses}>
        Come back on your 18th birthday! We'll even send you a reminder 🥳
      </p>
      <p className={TextClasses}>
        Until then, check out our resources on finding your first job in
        aquatics:
      </p>
      <div className={ControlClasses}>
        <Button
          variant="contained"
          color="primary"
          to={`${EXTERNAL_ROUTES.BLOG.BECOME_AN_INSTRUCTOR}?ref=onboarding`}
          target="_self"
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

export default ErrorTooYoung;
