import { APP_ROOT_URL } from 'config';
import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  to: string;
  underline?: boolean;
  disabled?: boolean;
}

const isExternalLink = (to: string) =>
  (to.indexOf('http') === 0 ||
    to.indexOf('mailto') === 0 ||
    to.indexOf('tel') === 0) &&
  to.indexOf(APP_ROOT_URL) !== 0;

const Link = ({
  to,
  children,
  className = '',
  underline = true,
  disabled = false,
  ...rest
}: Props) =>
  disabled || !to ? (
    <span className={`${className} text-gray-600 cursor-default no-underline`}>
      {children}
    </span>
  ) : typeof to === 'string' && isExternalLink(String(to)) ? (
    <a
      className={`${className}${
        !underline ? ' !no-underline !hover:no-underline' : ''
      }`}
      href={to}
      {...rest}
    >
      {children}
    </a>
  ) : (
    <RouterLink
      className={`${className}${
        !underline || typeof children !== 'string'
          ? ' hover:no-underline no-underline'
          : ''
      }`}
      to={to}
      {...rest}
    >
      {children}
    </RouterLink>
  );

export default Link;
