import * as React from 'react';
import style from './style.module.scss';
interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string;
}
const FixedCenter: React.FunctionComponent<Props> = ({
  children,
  className,
  ...rest
}) => (
  <div className={`${style.fixedCenter}${cls(className)}`} {...rest}>
    {children}
  </div>
);

FixedCenter.defaultProps = {
  className: '',
};

export default FixedCenter;
