import { FacilityListItemSerializer } from 'api/Serializers/Facilities';
import Avatar from 'components/avatar';
import FacilityCategoryIcon from 'components/facility-icon';
import FavouriteButton from 'components/favourite-button';
import Link from 'components/link';
import { FreeParkingIcon, PaidParkingIcon, UserGroupIcon } from 'icons';
import React from 'react';

interface Props {
  facility: FacilityListItemSerializer;
  linkGenerator?(f: FacilityListItemSerializer): string;
  onClick?(): void;
}

export const getDistanceText = (
  facility: Pick<FacilityListItemSerializer, 'region' | 'near'>
) => {
  if (facility.near !== '') {
    return `${facility.near}`;
  } else if (!facility.region.distance) {
    return facility.region.city;
  } else if (facility.region.distance > 5) {
    return `${facility.region.city}, ${Math.round(
      Number(facility.region.distance)
    )}km away`;
  } else {
    return `${facility.region.city}, less than 5km away`;
  }
};

const FacilityCard = ({ facility, linkGenerator, onClick = null }: Props) => {
  if (linkGenerator) {
    return (
      <Link to={linkGenerator(facility)} className="block">
        <Card facility={facility} onClick={onClick} />
      </Link>
    );
  }
  return <Card facility={facility} onClick={onClick} />;
};

const Card = ({ facility, onClick }: Props) => (
  <div
    onClick={onClick}
    className="overflow-hidden bg-white border border-gray-300 shadow-md cursor-pointer rounded-xl lg:flex group"
  >
    <div className="overflow-hidden lg:w-104 xl:w-120">
      <div className="h-full overflow-hidden">
        <img className="object-cover w-full h-full" src={facility.hero} />
        <div className="absolute top-2 right-2 lg:hidden">
          <FavouriteButton
            contentType={'facility'}
            contentObject={facility}
            variant="icon"
          />
        </div>
      </div>
    </div>
    <div className="flex flex-col justify-between flex-1 p-4 space-y-3 text-gray-800 lg:p-5">
      <div className="flex-1 space-y-3">
        <div className="flex items-start justify-between flex-1 gap-4">
          <div className="flex flex-1 gap-4">
            <div className="hidden sm:block">
              <Avatar diameter={16} src={facility.avatar} border />
            </div>
            <div className="flex flex-col justify-center">
              <h3 className="my-0">{facility.displayName}</h3>
              <div className="text-sm text-gray-700">
                {getDistanceText(facility)}
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <FavouriteButton
              contentType={'facility'}
              contentObject={facility}
              variant="icon"
            />
          </div>
          {facility.admissionFee && (
            <div className="text-right">
              <h4>${facility.admissionFee}</h4>
              <span className="block text-sm ">admission fee</span>
            </div>
          )}
        </div>
        <p className="font-normal">{facility.shortDescription}</p>
      </div>
      <div className="grid grid-cols-3">
        <div className="flex flex-col items-center justify-center text-xs text-gray-700">
          <span className="flex items-center justify-end flex-1 text-gray-900">
            <FacilityCategoryIcon category={facility.category} />
          </span>
          <span className="hidden text-center sm:block">
            {facility.category} {facility.subType} {facility.assetType}
          </span>
          <span className="text-center sm:hidden">{facility.category}</span>
        </div>
        <div className="flex flex-col items-center justify-center text-xs text-gray-700 border-l border-r border-gray-300">
          {facility.hasFreeParking ? (
            <>
              <span className="flex items-center justify-end flex-1 text-gray-800">
                <FreeParkingIcon width={24} />
              </span>

              <span className="text-center">Free Parking</span>
            </>
          ) : (
            <>
              <span className="flex items-center justify-end flex-1 text-gray-800">
                <PaidParkingIcon width={24} />
              </span>

              <span className="text-center">Paid Parking</span>
            </>
          )}
        </div>
        <div className="flex flex-col items-center justify-center text-xs text-gray-700">
          <span className="flex items-center justify-end flex-1 text-gray-900">
            <UserGroupIcon width={24} />
          </span>

          <span className="text-center">{`${
            facility.numInstructors
          } ${'Instructor'.pluralize(facility.numInstructors)}`}</span>
        </div>
      </div>
    </div>
  </div>
);

export default FacilityCard;
