import AccountIcon from '@mui/icons-material/AccountCircle';
import ApproveIcon from '@mui/icons-material/Check';
import DeclineIcon from '@mui/icons-material/Close';
import UndecidedIcon from '@mui/icons-material/Remove';
import { SchedulableObject } from 'api/Serializers/Schedules';
import Avatar from 'components/avatar';
import React from 'react';

const AvatarCheckmark = ({ approved }: { approved: boolean }) => (
  <span
    className={`absolute -bottom-0.5 -right-0.5 flex items-center justify-center w-5 h-5 text-xs text-white ${
      approved === true
        ? 'bg-green-500'
        : approved === false
        ? 'bg-red-500'
        : 'bg-orange-400'
    } border-2 border-white border-solid rounded-full`}
  >
    {approved === true ? (
      <ApproveIcon fontSize="inherit" color="inherit" />
    ) : approved === false ? (
      <DeclineIcon fontSize="inherit" color="inherit" />
    ) : (
      <UndecidedIcon fontSize="inherit" color="inherit" />
    )}
  </span>
);

const EventParticipants = ({
  event,
}: {
  event: SchedulableObject;
  showStatus?: boolean;
}) => (
  <div className="flex flex-col md:flex-row">
    <div className="flex items-center justify-start flex-1 py-4 text-inherit md:py-0 sm:flex-row">
      <div className="relative mr-2">
        <Avatar src={event.facility.avatar} />
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-gray-700">
          {event.facility.shortName}
        </span>
      </div>
    </div>
    <div className="flex items-center justify-start flex-1 py-4 md:py-0 sm:flex-row">
      <div className="relative mr-2">
        <Avatar src={event.instructor.avatar} />
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-gray-700">
          {event.instructor.fullName}
        </span>
      </div>
    </div>
    <div className="flex items-center justify-start flex-1 sm:flex-row">
      <div className="relative mr-2">
        <Avatar className="bg-blue-400">
          <AccountIcon />
        </Avatar>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-gray-700">
          {event.client.fullName}
        </span>
      </div>
    </div>
  </div>
);

export default EventParticipants;
