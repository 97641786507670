import { Status } from 'api/Serializers/Accounts';
import Link from 'components/link';
import { UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { HelpIcon, LogoutIcon, NewsIcon } from 'icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  getAccountDetail,
  getAppNewsFeedState,
  getUser,
} from 'state/selectors';
import { setNewsFeedLoaded } from 'state/slice/app';
import { APP_ROUTES, SHOW_HELP } from 'utils/routing';

const LinkClasses = 'block text-sm sm:hidden lg:block';
const IconClasses = 'my-2 opacity-75 lg:mr-2 mr-2 sm:mr-0';
const BetaClasses =
  'block ml-auto p-1 text-xxs bg-gray-500 text-white rounded-xl sm:hidden lg:block';
const getMenuClasses = (isActive) =>
  `relative cursor-pointer text-xs tracking-wide font-normal flex-1 mx-2 px-2 flex items-center rounded ${
    isActive
      ? 'text-blue-700 hover:text-blue-600 hover:bg-blue-100 bg-gray-200'
      : 'text-gray-700 hover:text-gray-800 hover:bg-gray-200'
  }`;

interface ApplicationRoute {
  /**
   * React-Router accepted path
   * eg /account/schedule/:mode/:action?
   */
  PATH: string;
  /**
   * Base application route for use in navigation
   * eg /account/schedule
   */
  ROOT: string;
}

export interface MenuItem {
  id: string;
  route: ApplicationRoute;
  text?: string;
  beta?: boolean;
  Icon?: React.FC;
  /**
   *  Is the child component expecting an h-screen fixed height container, and will handle scrolling?
   */
  isFixedHeightRoute?: boolean;
  isActive?(pathname: string): boolean;
  isHidden?(account: any): boolean;
  Component?: any;
  listSelector?: any;
}
interface Props {
  menuItems: MenuItem[];
}
interface MenuLinkProps {
  id: string;
  to?: string;
  onClick?(): void;
  text: string;
  beta?: boolean;
  isActive: boolean;
  listSelector?: any;
  Icon: any;
}

export const MenuLink = ({
  id,
  to,
  onClick,
  text,
  beta,
  isActive,
  Icon,
  listSelector,
}: MenuLinkProps) => {
  let Component;
  let navProps: any;
  const selectedList: any[] = listSelector
    ? useSelector(listSelector)
    : undefined;
  if (!to) {
    Component = 'button';
    navProps = { id, onClick };
  } else {
    Component = Link;
    navProps = { id, onClick, to };
  }
  return (
    <Component className={getMenuClasses(isActive)} {...navProps}>
      {Icon && <Icon className={IconClasses} width={24} />}{' '}
      <span className={LinkClasses}>{text}</span>
      {beta && <span className={BetaClasses}>beta</span>}
      {selectedList && selectedList.length > 0 && (
        <span className="text-white bg-red-600 animate-bounce notification left-6 top-1">
          {selectedList.length}
        </span>
      )}
    </Component>
  );
};

const ShowNewsUserTypes: UserType[] = [
  UserType.Instructor,
  UserType.Admin,
  UserType.Client,
];
const HideNewsStatuses: Status[] = [
  Status.Suspended,
  Status.Banned,
  Status.Unlisted,
];
export const NewsMenuItem = ({ className }: { className?: string }) => {
  const user = useSelector(getUser);
  const type = user?.type;
  if (
    ShowNewsUserTypes.indexOf(type) === -1 ||
    HideNewsStatuses.indexOf(user.status) > -1
  ) {
    return null;
  }
  const [beamerCheck, setBeamerCheck] = useState(0);
  const newsFeedState = useSelector(getAppNewsFeedState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (newsFeedState === 'loading') {
      if (window.beamer_config.is_initialized && safe(window.Beamer)) {
        window.Beamer.init();
        dispatch(setNewsFeedLoaded());
      } else {
        setTimeout(() => setBeamerCheck(beamerCheck + 1), 500);
      }
    }
  }, [beamerCheck]);

  const openNewsFeed = () => {
    if (newsFeedState === 'loading') {
      if (window.beamer_config.is_initialized && safe(window.Beamer)) {
        window.Beamer.init();
        dispatch(setNewsFeedLoaded());
      }
    } else {
      window.Beamer.show();
    }
  };

  return (
    <li className="relative flex flex-wrap">
      <div className="flex w-full flex-nowrap">
        <button
          className={className ? className : getMenuClasses(false)}
          onClick={openNewsFeed}
        >
          <NewsIcon width={24} className={IconClasses} />
          <span id="beamer-link" />
          <span className={LinkClasses}>News</span>
        </button>
      </div>
    </li>
  );
};

const liClasses = 'relative flex flex-wrap my-1';
const AccountMenu = ({ menuItems }: Props) => {
  const account = useSelector(getAccountDetail);
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div className="sticky flex flex-col justify-between top-16 w-14 lg:w-48">
      <ul className="p-0 py-6 m-0 list-none">
        {menuItems
          .filter(
            (item) =>
              item.Icon &&
              item.text &&
              (!item.isHidden || !item.isHidden(account))
          )
          .map((item, i) => (
            <li key={`link-${i}`} className={liClasses}>
              <div className="flex flex-1 flex-nowrap">
                <MenuLink
                  id={item.id}
                  to={item.route.ROOT}
                  beta={item.beta}
                  Icon={item.Icon}
                  text={item.text}
                  isActive={item.isActive(pathname)}
                  listSelector={item.listSelector}
                />
              </div>
            </li>
          ))}
      </ul>
      <ul className="p-0 py-2 m-0 list-none">
        <NewsMenuItem />
        <li className={liClasses}>
          <div className="flex w-full flex-nowrap">
            <button
              id="nav-help"
              className={getMenuClasses(false)}
              onClick={SHOW_HELP.FOR_THIS_PAGE}
            >
              <HelpIcon width={24} className={IconClasses} />
              <span className={LinkClasses}>Help</span>
            </button>
          </div>
        </li>
        <li className="relative flex flex-wrap my-1">
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-logout"
              to={APP_ROUTES.LOGOUT}
              Icon={LogoutIcon}
              text="Logout"
              isActive={false}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AccountMenu;
