import * as React from 'react';
import style from './style.module.scss';

export interface TitleProps {
  className?: string;
  align?: 'left' | 'center' | 'right';
  color?: 'default' | 'primary' | 'emphasis' | 'white';
  shade?: '700' | '600' | '500' | '400' | '300' | '200' | '100';
  height?: 'none' | 'short' | 'default' | 'tall';
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?: 'title' | 'header' | 'subheader' | 'caption' | 'thin';
  children?: any;
  fullWidth?: boolean;
}

const Title: React.FunctionComponent<TitleProps> = ({
  as,
  align,
  children,
  className,
  color,
  fullWidth,
  height,
  shade,
  variant,
}) => {
  const As = as;
  return (
    <As
      className={`${style.container} ${style[color + shade]} ${
        style[variant]
      } ${style[align]} ${style[`${height}Height`]}${cls(className)}${cls(
        fullWidth,
        style.fullWidth
      )}`}
    >
      {children}
    </As>
  );
};

Title.defaultProps = {
  as: 'h2',
  align: 'center',
  color: 'default',
  shade: '600',
  height: 'default',
  variant: 'title',
  fullWidth: false,
};

export default Title;
