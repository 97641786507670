import api from 'api';
import {
  CertificateType,
  Certification,
  CertifyingBody,
} from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import CreateCertification from 'components/certification-card/create';
import Checkbox from 'components/checkbox';
import Controls from 'components/controls';
import Link from 'components/link';
import Modal from 'components/modal';
import { DATE_FMT, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserType } from 'state/selectors';
import { certificationsUpdated, saveCertification } from 'state/slice/account';
import {
  certificateExpiryStatus,
  CertificationExpiryStatus,
} from 'utils/business-logic';
import CanadianRedCrossLogo from './canadian-red-cross-logo';
import LifesavingSocietyLogo from './lifesaving-society-logo';

const friendlyCertificateType = {
  [CertificateType.WaterSafetyInstructor]: 'Water Safety Instructor',
  [CertificateType.NationalLifeguard]: 'National Lifeguard',
  [CertificateType.SwimInstructor]: 'Swim Instructor',
  [CertificateType.Unset]: 'Certificate',
};

interface BasicAccountInfo {
  username: string;
  firstName: string;
  lastName: string;
}

const UpdateCertificate = ({
  account,
  certification,
  onSubmit,
}: {
  account: BasicAccountInfo;
  certification: Certification;
  onSubmit(): void;
}) => {
  const [newCert, setNewCert] = useState<Certification>();
  const dispatch = useAppDispatch();
  const onCertIsValid = (cert: Certification) => {
    setNewCert(cert);
  };
  const updateCertification = () => {
    onSubmit();
    dispatch(saveCertification(account.username, newCert));
  };
  return (
    <div className="sm:w-full sm:max-w-md">
      <CreateCertification
        type={
          certification.certificateType === CertificateType.NationalLifeguard
            ? 'lifeguard'
            : 'instructor'
        }
        certification={certification}
        onComplete={onCertIsValid}
      />
      <Controls>
        <Button
          disabled={!newCert}
          color="primary"
          variant="contained"
          onClick={updateCertification}
        >
          Save
        </Button>
      </Controls>
    </div>
  );
};

const InstructorToolbar = ({
  certification,
  onUpdate,
}: {
  certification: Certification;
  onUpdate(cert: Certification): void;
}) => {
  const warning = certificateExpiryStatus(certification.dateExpiry);
  return (
    <div>
      {warning === CertificationExpiryStatus.Unset ? (
        <div className="flex items-center justify-between p-4 pl-4 m-4 mx-2 -mt-6 text-red-700 bg-red-200 border border-red-600 rounded-b-lg ">
          <span>Expiry Date not set</span>
          <button
            className="px-2 py-1 text-gray-700 bg-white border border-gray-300 border-solid rounded"
            onClick={() => onUpdate(certification)}
          >
            Update
          </button>
        </div>
      ) : warning === CertificationExpiryStatus.Expired ? (
        <div className="flex items-center justify-between p-4 pl-4 m-4 mx-2 -mt-6 text-red-700 bg-red-200 border border-red-600 rounded-b-lg ">
          <span>This certificate is expired</span>
          <button
            className="px-2 py-1 text-gray-700 bg-white border border-gray-300 border-solid rounded"
            onClick={() => onUpdate(certification)}
          >
            Update
          </button>
        </div>
      ) : warning === CertificationExpiryStatus.Expiring ? (
        <div className="flex items-center justify-between p-4 pl-4 m-4 mx-2 -mt-6 text-yellow-700 bg-yellow-200 border border-yellow-600 rounded-b-lg ">
          <span>This certificate is expiring</span>
          <button
            className="px-2 py-1 text-gray-700 bg-white border border-gray-300 border-solid rounded"
            onClick={() => onUpdate(certification)}
          >
            Update
          </button>
        </div>
      ) : null}
    </div>
  );
};

const AdminToolbar = ({
  account,
  certification,
  onUpdate,
}: {
  account: BasicAccountInfo;
  certification: Certification;
  onUpdate(cert: Certification): void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isChecked, setChecked] = useState(certification.verified);
  const dispatch = useAppDispatch();
  const onVerify = async () => {
    if (!certification.verified) {
      setChecked(true);
      const data = {
        ...certification,
        verified: true,
        verifiedOn: moment().format(DATE_FMT.DATETIME_FIELD),
      };
      try {
        const response = await api.account.certifications.update(
          account.username,
          data
        );
        dispatch(certificationsUpdated(response.data));
      } catch (error) {
        enqueueSnackbar(
          'Could not update certificate. Error report submitted to engineering. Try again',
          { variant: 'error' }
        );
      }
    }
  };
  return (
    <div
      className={` flex items-center justify-between mx-2 pl-4 -mt-4 bg-gray-200 rounded-b-lg`}
    >
      <Link
        to={`https://api.propelhq.com/propel/instructors/instructorcertification/${certification.id}/change/`}
        target="_blank"
        className="font-semibold"
      >
        Edit
      </Link>
      <Checkbox
        label="Certificate Verified"
        checked={isChecked}
        onChange={onVerify}
      />
    </div>
  );
};

const Certification: React.FC<{
  certification: Certification;
  account: BasicAccountInfo;
}> = ({ account, certification }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [editCert, setEditCert] = useState<Certification>();
  const userType = useSelector(getUserType);

  const updateCertification = (cert: Certification) => {
    setIsUpdating(true);
    setEditCert(cert);
  };
  const {
    certifyingBody,
    certificateType,
    nonStandardCertificationName,
    certificateNumber,
    dateExpiry,
  } = certification;
  return (
    <>
      <div className="flex flex-col h-48 p-4 my-4 bg-white border border-gray-400 rounded-lg shadow-md w-80">
        <div className="flex items-end justify-between">
          <div className="flex-1 font-medium text-gray-700 font-body">
            {account.firstName} {account.lastName}
          </div>
          {certifyingBody === CertifyingBody.CanadianRedCross && (
            <div className="">
              <CanadianRedCrossLogo width={100} />
            </div>
          )}
          {certifyingBody === CertifyingBody.LifesavingSociety && (
            <div className="">
              <LifesavingSocietyLogo width={100} />
            </div>
          )}
        </div>
        <hr
          className={`my-1 ${
            certifyingBody === CertifyingBody.LifesavingSociety
              ? 'border-blue-500'
              : certifyingBody === CertifyingBody.CanadianRedCross
              ? 'border-red-500'
              : 'border-gray-500'
          }`}
        />
        <div>
          <div className="my-0 font-semibold text-gray-800 font-body">
            {friendlyCertificateType[certificateType]}
          </div>
          <div className="my-0 font-medium text-gray-600">
            ID#: {certificateNumber}
          </div>
          <div className="my-0 font-medium text-gray-600">
            Expiry Date: {dateExpiry ? dateExpiry : 'N/A'}
          </div>
        </div>
      </div>
      {userType === UserType.Admin ? (
        <AdminToolbar
          account={account}
          certification={certification}
          onUpdate={updateCertification}
        />
      ) : (
        <InstructorToolbar
          certification={certification}
          onUpdate={updateCertification}
        />
      )}
      <Modal
        name="Instructor — Update Certificate"
        open={isUpdating}
        onClose={() => setIsUpdating(false)}
        maxWidth="sm"
        allowOverflow
      >
        <UpdateCertificate
          account={account}
          certification={editCert}
          onSubmit={() => setIsUpdating(false)}
        />
      </Modal>
    </>
  );
};

export default Certification;
