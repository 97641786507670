import {
  ChatContainer,
  ConversationHeader,
  Message,
  MessageInput,
  MessageList,
} from '@chatscope/chat-ui-kit-react';
import { useMediaQuery, useTheme } from '@mui/material';
import { ClientSerializer } from 'api/Serializers/Clients';
import { InstructorListSerializer } from 'api/Serializers/Instructors';
import Callout from 'components/callout';
import { DATE_FMT, MESSAGING_PAGE_SIZE, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { MessageFailedError } from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getActiveConversationSid,
  getActiveConvoHasMoreMessages,
  getClientList,
  getConversations,
  getInstructorsSchedulable,
  getMessages,
  getMessagingConnectionState,
  getParticipants,
  getUser,
} from 'state/selectors';
import {
  parseAndInsertMessages,
  ReduxMessage,
  ReduxParticipant,
  sdkConversations,
} from 'state/slice/messaging';
import { SHARED_ROUTES } from 'utils/routing';
import { MessagingAvatar } from './components';
import { getMessagingDateStr } from './utils';

const MAX_RECENT_MESSAGES_LENGTH = 10;
const SPAM_MESSAGE_WINDOW = 20;

enum Direction {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

enum Position {
  Single = 'single',
  First = 'first',
  Normal = 'normal',
  Last = 'last',
}

const BeginningPrompt = ({
  otherParticipant,
}: {
  otherParticipant: ReduxParticipant;
}) => {
  const user = useSelector(getUser);
  const other =
    user.type === UserType.Client
      ? 'instructors'
      : user.type === UserType.Instructor
      ? 'clients'
      : 'users';
  return (
    <div className="py-3">
      <Callout
        type="info"
        title={user.type !== UserType.Client ? 'Say Hello!' : ''}
      >
        {user.type === UserType.Client ? (
          <>
            Say hello and use this space to discuss lesson goals, progress, and
            scheduling. Don't forget to review the pool access details and what
            to bring for your lessons!
            <br />
            <br />
            <i>
              For your safety and convenience, keep all communications
              on-platform.
            </i>
          </>
        ) : (
          <>
            This is the start of your message history with{' '}
            {otherParticipant
              ? otherParticipant.displayName.split(' ')[0]
              : `your ${other}`}
            ! To start, type your message in the text box at the bottom of your
            screen.
          </>
        )}
      </Callout>
    </div>
  );
};

const MessagesContainer = ({
  className = undefined,
}: {
  className?: string;
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const user = useSelector(getUser);
  const messages = useSelector(getMessages);
  const participants = useSelector(getParticipants);
  const conversations = useSelector(getConversations);
  const connectionState = useSelector(getMessagingConnectionState);
  const activeConvoSid = useSelector(getActiveConversationSid);
  const hasMore = useSelector(getActiveConvoHasMoreMessages);
  const instructors = useSelector(getInstructorsSchedulable);
  const clients = useSelector(getClientList);
  const isBreakMd = useMediaQuery(theme.breakpoints.up('md'));
  const [inputValue, setInputValue] = useState('');
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const messageListRef = useRef(null);
  const inputRef = useRef(null);

  let latestTimestamp = null;
  const sdkConvo = sdkConversations.get(activeConvoSid) ?? null;
  const reduxConvo = conversations.find((elt) => elt.sid === activeConvoSid);
  const recentMessages: ReduxMessage[] =
    messages[activeConvoSid]?.slice(-1 * MAX_RECENT_MESSAGES_LENGTH) ?? [];

  const otherParticipant:
    | ReduxParticipant
    | InstructorListSerializer
    | ClientSerializer = useMemo(
    () =>
      participants[activeConvoSid]?.find(
        (elt: ReduxParticipant) => elt.identity !== user.id.toString()
      ) ??
      (
        (user.type === UserType.Client ? instructors : clients) as (
          | InstructorListSerializer
          | ClientSerializer
        )[]
      ).find((profile) => profile.userId.toString() === activeConvoSid),
    [activeConvoSid, participants]
  );

  useEffect(() => {
    if (activeConvoSid) {
      setInputValue('');
      if (isBreakMd) inputRef.current.focus();
    }
  }, [activeConvoSid]);

  useEffect(() => {
    // scroll to bottom when new message sent or received on the active convo
    if (activeConvoSid && !pageIsLoading) {
      messageListRef.current.scrollToBottom();
    }
  }, [activeConvoSid, messages[activeConvoSid]?.length]);

  const getPrevPage = async () => {
    setPageIsLoading(true);
    const newPaginator = await sdkConvo.getMessages(
      MESSAGING_PAGE_SIZE,
      messages[activeConvoSid][0].index - 1
    );
    dispatch(parseAndInsertMessages(activeConvoSid, newPaginator.items, true));
    setPageIsLoading(false);
  };

  if (!activeConvoSid) return <ChatContainer className={className} />;

  return (
    <ChatContainer className={className}>
      <ConversationHeader>
        <ConversationHeader.Back
          onClick={() => history.push(SHARED_ROUTES.MESSAGES.ROOT)}
        />
        <MessagingAvatar
          as="Avatar"
          src={otherParticipant?.avatar}
          name={otherParticipant?.displayName}
        />
        <ConversationHeader.Content
          userName={otherParticipant?.displayName.capitalize()}
        />
      </ConversationHeader>
      <MessageList
        ref={messageListRef}
        loadingMore={pageIsLoading}
        onYReachStart={() => {
          if (!pageIsLoading && hasMore) {
            getPrevPage();
          }
        }}
      >
        {otherParticipant && activeConvoSid ? (
          user.type !== UserType.Client &&
          (!messages[activeConvoSid] ||
            messages[activeConvoSid].length === 0) ? (
            <MessageList.Content>
              <BeginningPrompt
                otherParticipant={otherParticipant as ReduxParticipant}
              />
            </MessageList.Content>
          ) : (
            <>
              {user.type === UserType.Client && !hasMore && (
                <MessageList.Content>
                  <div className="mb-8">
                    <BeginningPrompt
                      otherParticipant={otherParticipant as ReduxParticipant}
                    />
                  </div>
                </MessageList.Content>
              )}
              {messages[activeConvoSid].map((message: ReduxMessage, i, arr) => {
                const prevMessage = i !== 0 ? arr[i - 1] : null;
                const nextMessage = i !== arr.length - 1 ? arr[i + 1] : null;
                const direction =
                  message.participantSid ===
                  (otherParticipant as ReduxParticipant).sid
                    ? Direction.Incoming
                    : Direction.Outgoing;
                let position = [
                  prevMessage?.participantSid,
                  nextMessage?.participantSid,
                ].every((elt) => elt === message.participantSid)
                  ? Position.Normal
                  : prevMessage?.participantSid === message.participantSid
                  ? Position.Last
                  : nextMessage?.participantSid === message.participantSid
                  ? Position.First
                  : Position.Single;
                // TODO Show timestamps onClick on mobile, and as tooltips onHover on desktop
                // How to show all headers: https://github.com/chatscope/chat-ui-kit-styles/issues/2
                if (
                  [Position.Normal, Position.Last].includes(position) &&
                  moment(message.dateCreated)
                    .subtract(10, 'minutes')
                    .isAfter(moment(latestTimestamp))
                ) {
                  position =
                    position === Position.Normal
                      ? Position.First
                      : Position.Single;
                }
                if ([Position.First, Position.Single].includes(position)) {
                  latestTimestamp = message.dateCreated;
                }
                return (
                  <Message
                    className="selection:bg-blue-600"
                    key={message.sid}
                    type="text"
                    model={{
                      message: message.body,
                      sentTime: moment(message.dateCreated).format(
                        DATE_FMT.MON_D_YEAR_TIME_A
                      ),
                      direction: direction,
                      position: position,
                    }}
                    avatarSpacer={
                      ![Position.Single, Position.Last].includes(position) &&
                      direction === Direction.Incoming
                    }
                  >
                    {[Position.Single, Position.Last].includes(position) &&
                      direction === Direction.Incoming && (
                        <MessagingAvatar
                          as="Avatar"
                          participant={otherParticipant as ReduxParticipant}
                        />
                      )}
                    {[Position.Single, Position.First].includes(position) && (
                      <Message.Header
                        sentTime={getMessagingDateStr(message.dateCreated)}
                      />
                    )}
                  </Message>
                );
              })}
            </>
          )
        ) : null}
      </MessageList>
      <MessageInput
        ref={inputRef}
        value={inputValue}
        placeholder="Write a message..."
        onChange={setInputValue}
        onSend={(innerHtml: string, textContent: string, innerText: string) => {
          if (
            recentMessages.length === MAX_RECENT_MESSAGES_LENGTH &&
            !recentMessages.some((msg) => msg.author !== user.id.toString()) &&
            moment()
              .subtract(SPAM_MESSAGE_WINDOW, 'minutes')
              .isBefore(moment(recentMessages[0].dateCreated))
          ) {
            const minutesBanned =
              SPAM_MESSAGE_WINDOW -
                moment().diff(recentMessages[0].dateCreated, 'minutes') ?? 1;
            enqueueSnackbar({
              message: `You have sent too many messages in a row without a response. Try again in ${minutesBanned} minute(s).`,
              variant: 'error',
            });
          } else {
            // TODO disable send button if input field includes banned words
            if (connectionState !== 'connected') {
              enqueueSnackbar(MessageFailedError);
              return;
            }
            setInputValue('');
            sdkConvo.sendMessage(innerText).catch(() => {
              setInputValue(innerText);
              enqueueSnackbar(MessageFailedError);
            });
          }
        }}
        onFocus={() => {
          if (activeConvoSid && reduxConvo.unreadMessageCount > 0) {
            sdkConvo.setAllMessagesRead().catch(() => {});
          }
        }}
        activateAfterChange
        attachButton={false}
      />
    </ChatContainer>
  );
};

export default MessagesContainer;
