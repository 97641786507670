import api from 'api';
import { InstructorDetailSerializer } from 'api/Serializers/Instructors';
import { CreateReview, InstructorReview } from 'api/Serializers/Reviews';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import RatingsButtonGroup from 'components/ratings-button-group';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useForm from 'hooks/useForm';
import React, { useEffect, useState } from 'react';
import { upsertInstructorReview } from 'state/slice/reviews';

type ReviewFormData = Pick<CreateReview, 'title' | 'review' | 'notes'>;

const blankReview: InstructorReview = {
  id: undefined,
  notes: undefined,
  review: '',
  title: '',
  instructorId: undefined,
  instructorName: undefined,
  recommended: undefined,
  reviewer: undefined,
  created: undefined,
};

interface Props {
  review: InstructorReview;
  instructor: InstructorDetailSerializer;
  isSubmitting: boolean;
  onSubmit(data: CreateReview): void;
  onCancel(): void;
}

const CreateReviewForm = ({
  review = blankReview,
  instructor,
  isSubmitting,
  onSubmit,
  onCancel,
}: Props) => {
  const [recommended, setRecommended] = useState(
    review ? review.recommended : undefined
  );
  const handleFormCallback = (inputs: ReviewFormData) => {
    const data: CreateReview = {
      ...inputs,
      id: review?.id,
      instructorId: instructor.id,
      recommended,
      notes: recommended ? '' : inputs.notes,
    };
    onSubmit(data);
  };
  const { inputs, handleInputChange, handleSubmit } = useForm<ReviewFormData>(
    review,
    handleFormCallback
  );

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <h2 className="py-6 text-gray-800">
          How are your lessons with {instructor.firstName}?
        </h2>
        <p>
          Your review will help other swimmers decide if an instructor is right
          for them. Share your experience with the world!
        </p>
        <div className="space-y-3">
          <div className="font-bold">
            <h5 className="font-bold text-gray-800">
              Would you recommend {instructor.firstName} to others?
            </h5>
            <RatingsButtonGroup
              value={recommended}
              handleClick={setRecommended}
            />
          </div>
          {recommended === false && (
            <div>
              <h5 className="font-bold text-gray-800">
                Oh no! Can you tell us why?
              </h5>
              <p>
                Negative reviews are sent to Propel for further review. We will
                follow up with you if necessary.
              </p>
              <label htmlFor="review">What went wrong? How can we help?</label>
              <textarea
                name="notes"
                className="w-full"
                value={inputs.notes}
                onChange={handleInputChange}
                rows={3}
              />
            </div>
          )}
          <div>
            <h5 className="mb-1 font-bold text-gray-800">
              Give your review a title
            </h5>
            <label htmlFor="title">
              What's a quick 3-5 word summary of your experience?
            </label>
            <input
              name="title"
              className="w-full"
              value={inputs.title}
              onChange={handleInputChange}
              maxLength={100}
            />
            <div
              className={`text-sm text-right ${
                inputs.title.length === 100 ? 'text-gray-700' : 'text-gray-600'
              }`}
            >
              {inputs.title.length > 0
                ? `${100 - inputs.title.length} ${'character'.pluralize(
                    100 - inputs.title.length
                  )} left`
                : ''}
            </div>
          </div>
          <div>
            <h5 className="mb-1 font-bold text-gray-800">
              Write your review for {instructor.firstName}
            </h5>
            <label htmlFor="review">
              What did you learn? What did you like or dislike?
            </label>
            <textarea
              name="review"
              className="w-full"
              value={inputs.review}
              onChange={handleInputChange}
              rows={4}
            />
          </div>
        </div>
        <Controls>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={
              isSubmitting ||
              !inputs.review ||
              !inputs.title ||
              typeof recommended === 'undefined'
            }
            type="submit"
            color="primary"
            variant="contained"
          >
            {review?.created ? 'Update' : 'Submit'}
          </Button>
        </Controls>
      </div>
    </form>
  );
};

const CreateReview = ({ instructorSlug, onSuccess, onError, onCancel }) => {
  const [init, setInit] = useState(false);
  const [instructor, setInstructor] = useState<InstructorDetailSerializer>();
  const [isError, setError] = useState(false);
  const [review, setReview] = useState<InstructorReview>();
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  async function fetchInstructorData(slug: string) {
    if (!slug) {
      return;
    }
    try {
      Promise.all([api.instructors.retrieve(slug), api.reviews.list()])
        .then((responses) => {
          const instructor = responses[0].data;
          setInstructor(instructor);
          const review = responses[1].data.find(
            (r) => r.instructorId === instructor.id
          );
          setReview(review);
          setInit(true);
        })
        .catch((error) => {
          setError(true);
        });
    } catch (err) {
      setError(true);
    }
  }
  useEffect(() => {
    fetchInstructorData(instructorSlug);
  }, [instructorSlug]);

  const handleSuccess = () => {
    setSubmitting(false);
    onSuccess();
  };
  const handleError = () => {
    setSubmitting(false);
    onError();
  };
  const handleSubmit = async (data: CreateReview) => {
    setSubmitting(true);
    dispatch(upsertInstructorReview(data, handleSuccess, handleError));
  };
  const handleCancel = () => {
    onCancel();
  };

  if (!init || !instructorSlug) {
    return (
      <Loading
        position="inline-contained"
        message="Looking for any reviews..."
      />
    );
  }
  return (
    <>
      {isSubmitting && <Loading />}
      <CreateReviewForm
        review={review}
        instructor={instructor}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};

export default CreateReview;
