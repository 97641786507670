import Link from 'components/link';
import React from 'react';
import { scroller } from 'react-scroll';
import { APP_ROUTES } from 'utils/routing';

const Menu = ({ theme, pathname }): JSX.Element => {
  const scrollProps = { smooth: true, offset: -50 };
  const liClasses = `p-0 mx-2 border-b-2 border-solid border-transparent `;
  const btnClasses = `border rounded-lg mx-3 px-3 hover:bg-gray-100 hover:bg-opacity-20 `;
  const linkClasses = `flex items-center py-2 text-sm font-medium tracking-wide text-gray-600 hover:text-gray-700`;
  const inactiveHoverWhite = 'hover:border-gray-700';
  return (
    <div className="flex items-center justify-center">
      <ul className="flex py-0">
        <li
          className={`${liClasses} ${linkClasses} ${inactiveHoverWhite} cursor-pointer`}
          onClick={() => scroller.scrollTo('the-pool', scrollProps)}
        >
          <span className="text-center">The Pool</span>
        </li>
        <li
          className={`${liClasses} ${linkClasses} ${inactiveHoverWhite} cursor-pointer`}
          onClick={() => scroller.scrollTo('about-propel', scrollProps)}
        >
          <span className="text-center">About Propel</span>
        </li>
        <li
          className={`${liClasses} ${linkClasses} ${inactiveHoverWhite} cursor-pointer`}
          onClick={() => scroller.scrollTo('testimonials', scrollProps)}
        >
          <span className="text-center">Testimonials</span>
        </li>
        <li
          className={`${btnClasses} ${`border-gray-500  ${inactiveHoverWhite}`}`}
        >
          <Link className={linkClasses} to={APP_ROUTES.LEARN_MORE.INSTRUCTORS}>
            <span className="text-center">Learn More</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
