import Avatar from 'components/avatar';
import Link from 'components/link';
import { APP_ROOT_URL } from 'config';
import Page from 'containers/page';
import BookingFlow, {
  AppointmentProductPriceDetails,
} from 'features/booking-flow';
import { BackIcon, BusinessIcon, FacilityIcon } from 'icons';
import { RouteParams } from 'models/route';
import { CLIENT_ROUTES } from 'pages/account/client/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { getCartAppointmentProduct, getCartSource } from 'state/selectors';

export const AppointmentProductCard = ({
  variant,
}: {
  variant: 'full' | 'mini';
}) => {
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  if (!appointmentProduct) {
    return null;
  }
  return (
    <div className="p-3 -mx-3 space-y-6 border-gray-400 md:mx-0 md:border md:rounded-xl">
      <div className="flex space-x-3 ">
        <div className="w-32 h-28">
          <div className="absolute z-0 w-32 overflow-hidden bg-contain rounded-lg h-28">
            <div className="absolute z-10 w-full h-28">
              <img
                className="min-h-full"
                src={appointmentProduct.facility.hero}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 py-1 space-y-1 text-sm">
          <div className="labeled-icon !py-0">
            <Avatar src={appointmentProduct.instructor.avatar} diameter={6} />
            <span>{appointmentProduct.instructor.displayName}</span>
          </div>
          <div className="labeled-icon !py-0">
            <FacilityIcon width={24} />
            <span>{appointmentProduct.facility.displayName}</span>
          </div>
          <div className="labeled-icon !py-0">
            <BusinessIcon width={24} />
            <span>{appointmentProduct.activity.name}</span>
          </div>
        </div>
      </div>
      {variant === 'full' && (
        <div className="hidden md:block">
          <AppointmentProductPriceDetails />
        </div>
      )}
    </div>
  );
};

const BookPage = () => {
  const routeMatch = useRouteMatch<RouteParams>();
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const cartSource = useSelector(getCartSource);
  return (
    <Page title="Book now">
      <div className={`min-h-screen`}>
        <div className="flex justify-center md:py-12">
          <div className="w-full md:max-w-5xl">
            <div>
              <div className="flex flex-col-reverse px-4 py-6 bg-white pb-28 md:p-8 md:space-x-16 lg:space-x-20 md:flex-row ">
                <div className="flex-1">
                  <h1 className="hidden pb-8 my-0 font-semibold text-left md:block">
                    Complete your booking
                  </h1>
                  <BookingFlow
                    appointmentProductId={
                      routeMatch.params.appointmentProductId
                    }
                  />
                </div>
                <div className="w-full md:w-5/12">
                  <h1 className="pb-6 my-0 -mx-4 text-lg font-semibold text-center border-b border-gray-400 md:hidden">
                    Complete your booking
                  </h1>
                  <AppointmentProductCard variant="full" />
                  {/* <div className="bg-gray-300 h-1.5 md:hidden -mx-6 my-8" /> */}
                </div>
              </div>
              <div className="absolute top-[18px] md:top-[29px] md:-left-2">
                <Link
                  to={
                    cartSource === 'SEARCH'
                      ? CLIENT_ROUTES.SEARCH.ROOT
                      : appointmentProduct?.appUrl.split(APP_ROOT_URL)[1]
                  }
                >
                  <button className="bg-white btn-icon">
                    <BackIcon width={24} />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default BookPage;
