import Checkmark from 'components/checkmark';
import Element from 'components/form-element';
import * as React from 'react';
import style from './style.module.scss';

interface InputProps {
  id?: string;
  name: string;
  value: string;
  required?: boolean;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  options?: Array<{ [key: string]: any }>;
  className?: string;
  inputClassName?: string;
  title?: string;
  index?: number;
  type?: string;
  errors?: string[];
  showValidIcon?: boolean;
  validate?: boolean;
  valid?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  autoFocus?: boolean;
}

interface InputState {
  pristine: boolean;
}

class Input extends React.Component<InputProps, InputState> {
  static defaultProps = {
    autoFocus: false,
    options: [],
    validate: false,
    valid: true,
    showValidIcon: false,
    value: '',
    required: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      pristine: true,
    };
    this.onBlur = this.onBlur.bind(this);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.onChange(event);
    }
  };
  onBlur(event) {
    this.setState({
      pristine: false,
    });
    if (typeof this.props.onBlur !== 'undefined') {
      this.props.onBlur(event);
    }
  }
  isCheckedAndValid() {
    return !this.state.pristine && this.props.valid && this.props.value !== '';
  }

  /**
   * This checks whether the input is valid or not
   */
  isCheckedAndInvalid() {
    return (
      (!this.state.pristine && !this.props.valid) ||
      (safe(this.props.errors) && this.props.errors.length > 0)
    );
  }

  render() {
    const {
      className,
      inputClassName,
      disabled,
      errors,
      onChange,
      placeholder,
      icon,
      maxLength,
      name,
      options,
      required,
      showValidIcon,
      title,
      type,
      validate,
      value,
      id,
      autoFocus,
      onFocus,
    } = this.props;
    let maxL;
    if (!maxLength) {
      const maxLengthOption = options
        ? options.find((o) => (o ? o.name === 'maxLength' : false))
        : undefined;
      maxL = maxLengthOption ? maxLengthOption.value : undefined;
    } else {
      maxL = maxLength;
    }
    const inputClass = validate
      ? `${showValidIcon ? style.chValidate : ''} ${
          this.isCheckedAndInvalid() ? style.invalid : ''
        }`
      : '';
    return (
      <Element
        height="short"
        inline={false}
        className={`${style.container}${cls(className)}`}
        title={title}
        name={name}
        required={required}
      >
        <div>
          <input
            id={id}
            autoFocus={autoFocus}
            maxLength={maxL}
            key={name}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onBlur={this.onBlur}
            onFocus={onFocus}
            onChange={onChange}
            onKeyPress={this.handleKeyPress}
            className={`${inputClass}${cls(inputClassName)}${cls(
              icon,
              style.hasIcon
            )}${!!errors && errors.length > 0 ? ' border-red-600' : ''}`}
            disabled={disabled}
          />
          {validate && showValidIcon ? (
            icon ? (
              <span
                className={`${style.icon}${cls(
                  this.isCheckedAndValid(),
                  style.valid
                )}${cls(this.isCheckedAndInvalid(), style.invalid)}`}
              >
                {icon}
              </span>
            ) : (
              <Checkmark
                className={style.checkmark}
                valid={this.isCheckedAndValid()}
              />
            )
          ) : (
            icon && <span className={style.icon}>{icon}</span>
          )}
        </div>
        {!!errors && errors.length > 0 && (
          <div className="py-2 pl-2 text-sm font-medium text-red-600">
            {errors.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        )}
      </Element>
    );
  }
}

export default Input;
