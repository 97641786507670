import AccountMenu, { MenuItem } from 'components/account/menu';
import Button from 'components/button';
import Callout from 'components/callout';
import Controls from 'components/controls';
import SuggestPlace from 'components/input-suggest-place';
import Loading from 'components/loading';
import Modal from 'components/modal';
import Redirect from 'components/redirect';
import { IS_SERVER, UserType } from 'config';
import Page from 'containers/page';
import useAccountPatch from 'hooks/useAccountPatch';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Account as Meta } from 'metadata';
import Address from 'models/geo';
import { enqueueSnackbar } from 'notistack';
import AdminAccount from 'pages/account/admin';
import ClientAccount from 'pages/account/client';
import HostAccount from 'pages/account/host';
import InstructorAccount from 'pages/account/instructor';
import React, { useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getAccountDetail,
  getAccountIsLoaded,
  getUserType,
} from 'state/selectors';
import { retrieveAccount } from 'state/slice/account';
import { DEPRECATED_ROUTES } from 'utils/routing';
import { CLIENT_ROUTES } from './client/utils';

export const SidebarMenu = ({
  menuItems,
  hideMenu = false,
  children,
}: {
  menuItems: MenuItem[];
  hideMenu?: boolean;
  children: any;
}) => {
  const { pathname } = useLocation();
  const activeMenuItem = menuItems.find((i) =>
    i.isActive ? i.isActive(pathname) : false
  );
  return (
    <div
      className={`bg-background flex ${
        activeMenuItem?.isFixedHeightRoute ? 'h-screen' : 'min-h-screen'
      }`}
    >
      <div
        className={`bg-white border-r-2 border-gray-200 border-solid no-print ${
          hideMenu ? 'hidden' : 'hidden sm:block'
        }`}
      >
        <AccountMenu menuItems={menuItems} />
      </div>
      <div className="flex justify-center flex-1">{children}</div>
    </div>
  );
};

const AskForAddress = () => {
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail);
  const { handleAccountUpdate } = useAccountPatch();
  const [address, setAddress] = useState<Address>();
  const [addressError, setAddressError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleAddressSubmit = async () => {
    setIsSubmitting(true);
    if (address === undefined) {
      setAddressError(true);
      return;
    }
    try {
      await handleAccountUpdate({ address });
    } catch (err) {
      enqueueSnackbar({
        message:
          "We're having trouble updating your account. Please refresh and try again.",
        variant: 'error',
      });
    }
    try {
      await dispatch(retrieveAccount(account.username));
    } catch (err) {
      location.reload();
    }
  };
  return (
    <Modal
      name="Update your location"
      title="Update your location"
      open
      fullWidth
      maxWidth="xs"
      allowOverflow
    >
      <div className="space-y-4">
        <p>
          It looks like we don't have your location saved yet! We use it to show
          you relevant search results and new facilities opening nearby.
        </p>
        <div>
          <label>Postal code or address</label>
          <SuggestPlace
            id="address"
            name="address"
            placeholder="Postal code or address"
            className={`${addressError ? 'border-red-600' : 'border-blue-500'}`}
            onChange={() => {
              if (addressError) {
                setAddressError(false);
              }
              if (address !== undefined) {
                setAddress(undefined);
              }
            }}
            types={['geocode']}
            onSuggestSelect={(elt) => {
              if (!elt?.placeId) return;
              setAddress({
                line1: elt.line1 ?? '',
                city: elt.city,
                region: elt.region,
                regionCode: elt.regionCode,
                country: elt.country,
                countryCode: elt.countryCode,
                postalCode: elt.postalCode,
              });
            }}
            disabled={isSubmitting}
          />
          {addressError && (
            <div className="text-red-600">
              Select from the options presented
            </div>
          )}
        </div>
        <Controls>
          <Button
            color="primary"
            variant="flat"
            onClick={handleAddressSubmit}
            isLoading={isSubmitting}
          >
            Update my location
          </Button>
        </Controls>
      </div>
    </Modal>
  );
};

const AskForPhoneNumber = () => {
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail);
  const [phoneNumber, setPhone] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<{ code: string; message: string }>();
  const { handleAccountUpdate } = useAccountPatch();
  const handleSubmit = async () => {
    if (!isValidPhoneNumber(phoneNumber, 'CA')) {
      setError({
        code: 'invalid_phone_number',
        message: 'Must be a valid Canadian phone number',
      });
      return;
    }
    setIsSubmitting(true);
    try {
      await handleAccountUpdate({ phoneNumber });
    } catch (err) {
      enqueueSnackbar({
        message:
          "We're having trouble updating your account. Please refresh and try again.",
        variant: 'error',
      });
    }
    try {
      await dispatch(retrieveAccount(account.username));
    } catch (err) {
      location.reload();
    }
  };
  return (
    <Modal
      name="Missing data — phone number"
      title="Update your phone number"
      open
      fullWidth
      maxWidth="xs"
      allowOverflow
    >
      <p>
        It looks like we don't have your phone number on file. To ensure you
        receive SMS reminders and notices about any changes with your bookings,
        please provide your number here.
      </p>
      <p>A valid phone number is required to continue</p>
      <div>
        <label htmlFor="phoneNumber">Phone</label>
        <PhoneInput
          name="phoneNumber"
          value={phoneNumber}
          onChange={setPhone}
          country="CA"
          placeholder="604-555-0938"
          className={`${error !== undefined ? 'input-error' : ''}`}
          disabled={isSubmitting}
        />
      </div>
      {error && <Callout type="error">{error.message}</Callout>}
      <Controls>
        <Button
          color="primary"
          variant="flat"
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          Save phone number
        </Button>
      </Controls>
    </Modal>
  );
};

const AccountContainer = (props) => {
  const { pathname } = useLocation();
  const userType = useSelector(getUserType);
  const isLoaded = useSelector(getAccountIsLoaded);
  const account = useSelector(getAccountDetail);
  if (IS_SERVER || !isLoaded) {
    return <Loading message="Authenticating..." />;
  }

  if (location.pathname === DEPRECATED_ROUTES.BOOK_MORE) {
    return <Redirect to={CLIENT_ROUTES.SEARCH.ROOT} />;
  }

  if (userType !== UserType.Admin) {
    if (account.address?.latlng === undefined) {
      return <AskForAddress />;
    } else if (!account.phoneNumber) {
      return <AskForPhoneNumber />;
    }
  }

  return (
    <Page {...Meta}>
      {userType === UserType.Instructor ? (
        <InstructorAccount />
      ) : userType === UserType.Admin ? (
        <AdminAccount />
      ) : userType === UserType.Host ? (
        <HostAccount />
      ) : userType === UserType.Client ? (
        <ClientAccount />
      ) : null}
    </Page>
  );
};

export default AccountContainer;
