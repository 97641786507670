import PasswordIcon from '@mui/icons-material/LockOutlined';
import api from 'api';
import { ApiException } from 'api/errors';
import { Credentials } from 'api/Serializers/Authorization';
import { isClientError } from 'api/status';
import { AxiosError } from 'axios';
import Button from 'components/button';
import Callout from 'components/callout';
import Link from 'components/link';
import { FETCH_STATE, MAX_EMAIL_LENGTH, MAX_PASSWORD_LENGTH } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useForm from 'hooks/useForm';
import {
  GenericServerError,
  InvalidLoginCredentials,
  SnackbarData,
} from 'lang/en/Snackbars';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuthenticated } from 'state/selectors';
import { loginSuccess, resetAuthState } from 'state/slice/authentication';
import { tokenDecode } from 'utils/auth';
import { APP_ROUTES } from 'utils/routing';
import { validateEmail } from 'utils/string';
import { AuthFlow } from '.';

type AuthenticationFailed = 'invalid_credentials' | 'inactive_user';

const SignInForm = ({ flow }: { flow: AuthFlow }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<string>('');
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const getInvalidFields = () => {
    let errors = [];
    if (validateEmail(inputs.email) !== '') {
      errors.push('email');
    }
    if (inputs.password === '') {
      errors.push('password');
    }
    return errors;
  };

  const onSubmit = async (credentials: Credentials) => {
    if (fetchState === FETCH_STATE.POST) {
      return null;
    }
    setFetchState(FETCH_STATE.POST);
    const invalidFields = getInvalidFields();
    if (invalidFields.length > 0) {
      return;
    }
    try {
      const response = await api.auth.token(credentials);
      const { id, username, ...rest } = tokenDecode(response.data.token);
      dispatch(loginSuccess(response.data.token, response.data.refresh));
      rudderanalytics.track('Signed In', {
        id,
        username,
        email: inputs.email,
        flow,
      });
    } catch (error: any) {
      let snackbar: SnackbarData;
      if (isClientError(error?.response?.status)) {
        const data = (error as AxiosError<ApiException<AuthenticationFailed>>)
          .response.data;
        if (data.code === 'invalid_credentials') {
          snackbar = InvalidLoginCredentials;
          setError(data.message);
        } else if (data.code === 'inactive_user') {
          snackbar = InvalidLoginCredentials;
          setError(data.message);
        } else {
          snackbar = GenericServerError;
          setError('There was a problem logging in. Please try again later.');
        }
        enqueueSnackbar(snackbar);
      } else {
        setError('There was a problem logging in. Please try again later.');
      }
      setFetchState(FETCH_STATE.FAILED);
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    { email: '', password: '' },
    onSubmit
  );

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(resetAuthState());
    }
  }, []);

  return (
    <div className="w-full space-y-8">
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        {error !== '' && <Callout type="error">{error}</Callout>}
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="username"
              required
              onChange={handleInputChange}
              value={inputs.email}
              maxLength={MAX_EMAIL_LENGTH}
            />
          </div>
          <div className="flex flex-col-reverse col-span-6">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              onChange={handleInputChange}
              maxLength={MAX_PASSWORD_LENGTH}
              value={inputs.password}
            />
            <div className="flex items-baseline justify-between">
              <label htmlFor="password">Password</label>
              <Link
                to={APP_ROUTES.REQUEST_NEW_PASSWORD}
                className="order-last text-sm link"
                underline={false}
              >
                Forgot your password?
              </Link>
            </div>
          </div>
        </div>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            isLoading={fetchState === FETCH_STATE.POST}
            fullWidth={true}
            icon={<PasswordIcon />}
          >
            {fetchState === FETCH_STATE.POST ? 'Loading...' : 'Continue'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;
