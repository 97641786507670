import CharacterCount from 'components/character-count';
import { MIN_CANCEL_NOTE_LENGTH, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import debounce from 'lodash.debounce';
import { Report, ReportAction } from 'models/Assistance';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAssistanceReport } from 'state/selectors';
import { deleteReportData, upsertReportAction } from 'state/slice/assistance';

interface TextAreaProps {
  id: string;
  title: string;
  label: string;
  placeholder: string;
  recipientUserType: UserType;
  required?: boolean;
}

const addReportData = debounce((dispatch, data: ReportAction) => {
  dispatch(upsertReportAction(data));
}, 500);

const TextArea = ({
  id,
  title,
  label,
  placeholder,
  recipientUserType,
  required = true,
}: TextAreaProps) => {
  const dispatch = useAppDispatch();
  const state = useSelector(getAssistanceReport) as Report;
  const type = `SET_MESSAGE_${recipientUserType.toUpperCase()}`;
  const getDescription = (key: string, def = undefined) => {
    const data = state.actions?.find(
      (data: ReportAction) => data.key === key && data.type === type
    );
    return data?.value ?? def;
  };
  const description = getDescription(id) ?? '';
  const [text, setText] = useState(description);
  const handleSubmit = (event) => {
    const value = event.currentTarget.value;
    setText(value);
  };
  const htmlId = id + '-' + recipientUserType;

  const commitMessage = (value: string) => {
    if (value === '') {
      dispatch(deleteReportData({ key: id, type }));
    } else {
      const data = {
        key: id,
        type,
        value,
        title,
        description: value,
      };
      addReportData(dispatch, data);
    }
  };

  useEffect(() => {
    commitMessage(text);
  }, [text]);

  return (
    <div>
      <label htmlFor={htmlId}>{label}</label>
      <textarea
        id={htmlId}
        name={htmlId}
        placeholder={placeholder}
        defaultValue={description}
        maxLength={500}
        onChange={handleSubmit}
        rows={3}
      />
      <div className="flex">
        {required ? (
          text.length < MIN_CANCEL_NOTE_LENGTH && (
            <div className="flex-1 mb-2 text-sm text-red-400">
              Write at least {MIN_CANCEL_NOTE_LENGTH - text.length} more{' '}
              {'character'.pluralize(MIN_CANCEL_NOTE_LENGTH - text.length)}
            </div>
          )
        ) : (
          <div className="flex-1 mb-2 text-sm italic text-gray-500">
            Optional
          </div>
        )}
        <CharacterCount currentLength={text.length} maxLength={500} />
      </div>
    </div>
  );
};

export default TextArea;
