import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import { TimeFrame } from 'api/Serializers/Appointments';
import ButtonLarge from 'components/button-large';
import { UserType } from 'config';
import DialThrough from 'features/dial-through';
import { AssistanceWizardProps, ReportResolution } from 'models/Assistance';
import moment from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';

/**
 * url: account/assistance/:appointment-id/client/call
 */
const ClientCallView = (props: AssistanceWizardProps) => {
  const history = useHistory();
  const { appointment, trackAction, addReportData, getReportData } = props;
  const callHistory = getReportData('callHistory') as string;
  const numCalls = getReportData('numCalls') as number;
  const handleCallPlaced = () => {
    const placedAt = moment().format('h:mm:ssa');
    if (numCalls) {
      addReportData({
        type: 'COUNT_CALL',
        key: 'numCalls',
        value: numCalls + 1,
      });
      addReportData({
        type: `PLACE_CALL`,
        key: `callHistory`,
        value: `${callHistory}\n- ${placedAt}`,
        title: `Call history with client`,
        description: `${callHistory}\n- ${placedAt}`,
      });
    } else {
      addReportData({
        type: 'COUNT_CALL',
        key: 'numCalls',
        value: 1,
      });
      addReportData({
        type: 'PLACE_CALL',
        key: 'callHistory',
        value: `- ${placedAt}`,
        title: 'Call history with client',
        description: `- ${placedAt}`,
      });
    }
  };
  const handleCallSuccess = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'CLIENT_FOUND' as ReportResolution,
      title: 'How did that go?',
      description: 'I am with my client or they will be here soon',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'NO_CHANGE',
    });
  };
  const handleClientCancel = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'CLIENT_CANCELLATION' as ReportResolution,
      title: 'How did that go?',
      description: 'Client cannot attend and would like to cancel',
    });
  };
  const handleClientNoShow = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'CLIENT_CANCELLATION' as ReportResolution,
      title: 'How did that go?',
      description: 'My client never showed up',
    });
    addReportData({
      type: 'SET_POLICY_ENFORCEMENT',
      key: 'isPolicyWaived',
      value: false,
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'CLIENT_NO_SHOW',
    });
  };
  return (
    <>
      <h2>Client Assistance</h2>
      <h4>Call my client</h4>
      <DialThrough
        appointment={appointment}
        recipient={UserType.Client}
        onClose={history.goBack}
        onCallPlaced={handleCallPlaced}
        PostCallView={({ dialThroughState }) => (
          <div className="space-y-3">
            <ButtonLarge
              title="I am with my client or they will be here soon"
              subtitle="The lesson can start soon"
              icon={<CheckIcon className="text-gray-600" color="action" />}
              to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
              onClick={handleCallSuccess}
            />
            {!!dialThroughState.userProxyPhone && (
              <ButtonLarge
                title="No answer or busy, retry the call"
                subtitle="Try to reach your client until the lesson ends"
                icon={<PhoneIcon className="text-gray-600" color="action" />}
                onClick={handleCallPlaced}
                to={`tel:${dialThroughState.userProxyPhone}`}
              />
            )}
            {appointment.timeFrame < TimeFrame.HasEnded ? (
              <ButtonLarge
                title="Client will not attend"
                subtitle="You have spoken to your client and they have said they will not attend"
                icon={<CancelIcon className="text-gray-600" color="inherit" />}
                to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
                onClick={handleClientNoShow}
              />
            ) : (
              <ButtonLarge
                title="My client never showed up"
                subtitle="Your client did not show and you were unable to reach them"
                icon={<CancelIcon className="text-gray-600" color="inherit" />}
                to={SHARED_ROUTES.ASSISTANCE.nav(
                  appointment.id,
                  'client/cancel'
                )}
                onClick={handleClientNoShow}
              />
            )}
          </div>
        )}
      />
    </>
  );
};

export default ClientCallView;
