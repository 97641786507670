import {
  InstructorTimeSlotType,
  ScheduleTimeSerializer,
} from 'api/Serializers/Schedules';
import WeeklyMode from 'features/schedule/as-instructor/weekly';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';
import AvailabilityMode from './availability';
import ViewMode from './bookings';
import ProposalMode from './proposals';

export const byUserHasCommitment = (t: ScheduleTimeSerializer) =>
  t.instructorStatus === InstructorTimeSlotType.Available ||
  t.instructorStatus === InstructorTimeSlotType.Pending ||
  t.instructorStatus === InstructorTimeSlotType.Booked;

const ScheduleV3 = () => {
  return (
    <>
      <Switch>
        <Route path={SHARED_ROUTES.SCHEDULE.proposals}>
          <ProposalMode />
        </Route>
        <Route path={SHARED_ROUTES.SCHEDULE.availability}>
          <AvailabilityMode />
        </Route>
        <Route path={SHARED_ROUTES.SCHEDULE.weekly}>
          <WeeklyMode />
        </Route>
        <Route path={SHARED_ROUTES.SCHEDULE.ROOT}>
          <ViewMode />
        </Route>
      </Switch>
    </>
  );
};

export default ScheduleV3;
