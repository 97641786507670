import api from 'api';
import { HTTP_200_OK } from 'api/status';
import Button from 'components/button';
import Card from 'components/card';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Redirect from 'components/redirect';
import { QueryParams } from 'config';
import FixedCenter from 'layouts/fixed-center';
import React, { useEffect, useState } from 'react';
import { APP_ROUTES, getUrlParam } from 'utils/routing';

const Activate = () => {
  const [isLoading, setLoading] = useState(true);
  const [isActivated, setActivated] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [resent, setResent] = useState(false);
  const key = getUrlParam(QueryParams.ActivateKey);
  const activate = async () => {
    try {
      const response = await api.users.activate.verify(key);
      const success = response.status === HTTP_200_OK;
      setLoading(false);
      setActivated(success);
      if (success) {
        setTimeout(() => setRedirect(true), 1500);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    await api.users.activate.resend();
    setResent(true);
  };

  useEffect(() => {
    activate();
  });

  return (
    <div className="flex min-h-screen bg-background">
      {isLoading ? (
        <Loading message="Confirming Email..." />
      ) : redirect ? (
        <Redirect to={APP_ROUTES.ACCOUNT} />
      ) : isActivated ? (
        <Loading message="Email Confirmed! Loading account..." />
      ) : resent ? (
        <FixedCenter>
          <Card maxWidth="xl" title="Link Resent">
            <p>
              Click the link we just emailed you to verify your email address.
            </p>
            <p>You may close this window.</p>
          </Card>
        </FixedCenter>
      ) : (
        <FixedCenter>
          <Card maxWidth="xl" title="Unable to Verify Email">
            <p>
              Try again, or request a new verification email using the button
              below.
            </p>
            <Controls>
              <Button
                variant="contained"
                color="primary"
                onClick={handleResend}
              >
                Resend Verify Email
              </Button>
            </Controls>
          </Card>
        </FixedCenter>
      )}
    </div>
  );
};

export default Activate;
