import Button from 'components/button';
import Callout from 'components/callout';
import CommonQuestions from 'components/common-questions';
import Hero from 'components/hero';
import Link from 'components/link';
import ReadMore from 'containers/read-more';
import { CheckIcon } from 'icons';
import { Locations } from 'pages/home';
import React, { useState } from 'react';
import Select from 'react-select';
import { APP_ROUTES } from 'utils/routing';

const expOptions = [
  {
    label: '1-2 years exp',
    value: 35,
  },
  {
    label: '2-5 years exp',
    value: 50,
  },
  {
    label: '5+ years exp',
    value: 75,
  },
];
const numAppointmentOptions = [
  {
    label: '2 appointment/week',
    value: 2,
  },
  {
    label: '5 appointment/week',
    value: 5,
  },
  {
    label: '10 appointment/week',
    value: 10,
  },
  {
    label: '15 appointment/week',
    value: 15,
  },
  {
    label: '20 appointment/week',
    value: 20,
  },
];

const EarningsCalculator = () => {
  const WEEKS_PER_MONTH = 4.2;
  const [exp, setExp] = useState(expOptions[1]);
  const [numAppointment, setNumAppointment] = useState(
    numAppointmentOptions[2]
  );
  return (
    <div>
      <h2 className="title">Earn money teaching lessons</h2>
      <h4 className="heading">See what you could earn</h4>
      <div className="space-y-6">
        <div className="flex flex-col gap-2">
          <Select
            className="flex-1"
            options={expOptions}
            value={exp}
            onChange={(item) => setExp(item as any)}
          />
          <Select
            className="flex-1"
            options={numAppointmentOptions}
            value={numAppointment}
            onChange={(item) => setNumAppointment(item as any)}
          />
        </div>
        <div className="text-right">
          <span className="text-2xl font-bold text-right">
            {
              (exp.value * numAppointment.value * WEEKS_PER_MONTH)
                .toCurrency()
                .split('.')[0]
            }
          </span>
          per month
        </div>
        <ReadMore initialHeight={0} openBtnText="Assumptions">
          <div className="py-1">
            <Callout>
              <p>
                This is an estimated take-home payout based on our historical
                instructor earnings across the entire Propel platform. How much
                you actually make may vary with your chosen price, availability,
                location, season, demand, and other factors.
              </p>
              <p>Average take home pay was $49.65/hr.</p>
            </Callout>
          </div>
        </ReadMore>
        <Button
          color="primary"
          variant="contained"
          to={APP_ROUTES.SIGN_UP}
          fullWidth={true}
        >
          Get started
        </Button>
      </div>
    </div>
  );
};

const InstructorLearnMore = () => {
  const [playVideo, setPlayVideo] = React.useState(false);
  return (
    <div className="landing-page">
      <div style={{ position: 'relative' }}>
        <Hero
          background="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvaW5zdHJ1Y3RvcnMtaGVyby1sZy5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9"
          title="Switch your lane"
          subtitle="Teach your own private swimming lessons and earn more for your expertise"
          backgroundPosition="35% 0%"
          fadeOpacity={20}
        >
          <div className="flex flex-wrap my-4">
            <Button
              to={APP_ROUTES.SIGN_UP}
              variant="contained"
              shape="rounded-full"
              size="large"
              color="primary"
              className="mt-4 mr-4"
            >
              Sign up to teach
            </Button>
            <Button
              to={APP_ROUTES.LEARN_MORE.SWIMMERS}
              variant="contained"
              shape="rounded-full"
              size="large"
              className="mt-4 text-gray-100 bg-opacity-20 hover:text-gray-900"
            >
              Learning with Propel
            </Button>
          </div>
        </Hero>
      </div>

      <section>
        <div>
          <h2 className="title">Teach for yourself</h2>
          <h3 className="subtitle">
            Get exclusive access to pools near you and teach swimming lessons
            your way.
          </h3>
        </div>
        <div className="max-w-3xl mx-auto">
          {!playVideo ? (
            <img
              onClick={() => setPlayVideo(true)}
              src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvaW5zdHJ1Y3Rvci12aWRlby10aHVtYi5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9"
            />
          ) : (
            <iframe
              width="736"
              height="414"
              className="w-full"
              src="https://www.youtube.com/embed/_zbeeRpniGU?autoplay=1"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            />
          )}
        </div>
        <div className="!pt-16">
          <div className="flex flex-col justify-between max-w-sm mx-auto space-y-14 sm:space-y-0 sm:max-w-5xl sm:space-x-8 md:space-x-16 sm:flex-row">
            <div className="flex-1 text-center sm:text-left">
              <img
                style={{ width: 100 }}
                className="mx-auto mb-2"
                src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvaW5zdHJ1Y3Rvci1pY28tbGcucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ=="
              />
              <h4 className="heading">Free and simple setup</h4>
              <div>
                Create your free profile, set your price, and receive
                notifications when you've been booked.
              </div>
            </div>
            <div className="flex-1 text-center sm:text-left">
              <img
                style={{ width: 100 }}
                className="mx-auto mb-2"
                src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvY2FsZW5kYXItaWNvLWxnLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0="
              />
              <h4 className="heading">Own your schedule</h4>
              <div>
                Work when it's convenient for you and never get locked into a
                set schedule. Work as much or as little as you want.
              </div>
            </div>
            <div className="flex-1 text-center sm:text-left">
              <img
                style={{ width: 79 }}
                className="mx-auto mb-2"
                src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvY2hlY2tsaXN0LWljby1sZy5wbmciLCJidWNrZXQiOiJvbGVrc2lhayJ9"
              />
              <h4 className="heading">Go legit</h4>
              <div>
                Avoid conflict of interest and maintain integrity by teaching in
                our partnered pools with full insurance coverage provided.
              </div>
            </div>
          </div>
        </div>
      </section>
      <Hero
        background="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvaW5zdHJ1Y3RvcnMtZWFybmluZ3MtYmcuanBnIiwiYnVja2V0Ijoib2xla3NpYWsifQ=="
        title=""
        height="auto"
        className="h-[500px] hidden sm:block"
      >
        <div className="max-w-md p-6 mx-auto my-8 card">
          <EarningsCalculator />
        </div>
      </Hero>
      <div className="sm:hidden section bg-background">
        <EarningsCalculator />
      </div>
      <section>
        <div>
          <h2 className="title">Let clients come to you</h2>
          <h3 className="subtitle">
            Make the most of your time and teach multiple lessons in a row at
            amazing facilities.
          </h3>
        </div>
        <div className="image-grid">
          {Locations.map((location, key) => (
            <div key={key} className="space-y-2 text-center">
              <img src={location.srcSm} className="rounded-xl" />
              <h4>{location.region}</h4>
            </div>
          ))}
        </div>
        <div>
          <Button
            variant="contained"
            shape="rounded-full"
            size="large"
            color="primary"
            to={APP_ROUTES.SIGN_UP}
          >
            Choose a pool
          </Button>
        </div>
      </section>

      <section className="bg-background">
        <div>
          <h2 className="title">Crush your goals</h2>
          <h3 className="subtitle">
            Achieve life balance and earn financial freedom on your own terms.
          </h3>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="overflow-hidden bg-white shadow-md w-72 rounded-xl">
            <div className="overflow-hidden w-72 h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9pbWFnZXMvaW5zdHJ1Y3RvcnMvcHJpc2NpbGxhLW8ucG5nIn0=" />
            </div>
            <div className="p-6">
              <h4>Pay for school 🎓</h4>
              <p>
                “I love being able to create my own schedule around my busy
                student life!”
              </p>
              <p>—Priscilla O.</p>
            </div>
          </div>
          <div className="overflow-hidden bg-white shadow-md w-72 rounded-xl">
            <div className="overflow-hidden w-72 h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9pbWFnZXMvaW5zdHJ1Y3RvcnMvdmxhZGltaXItdS5wbmcifQ==" />
            </div>
            <div className="p-6">
              <h4>Build a legacy 🏅</h4>
              <p>
                “Propel is a great fit for me as many of my students go on to
                join my Water Polo team.”
              </p>
              <p>—Vladimir U.</p>
            </div>
          </div>
          <div className="overflow-hidden bg-white shadow-md w-72 rounded-xl">
            <div className="overflow-hidden w-72 h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9pbWFnZXMvaW5zdHJ1Y3RvcnMvdGhhZGVuZS1sLnBuZyJ9" />
            </div>
            <div className="p-6">
              <h4>See the world 🌍</h4>
              <p>
                “I love that I can work hard, make money, and travel whenever I
                want!”
              </p>
              <p>—Thadene L.</p>
            </div>
          </div>
          <div className="overflow-hidden bg-white shadow-md w-72 rounded-xl">
            <div className="overflow-hidden w-72 h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9pbWFnZXMvaW5zdHJ1Y3RvcnMvc2llcnJhLXAucG5nIn0=" />
            </div>
            <div className="p-6">
              <h4>Support a family 👶</h4>
              <p>
                “Propel has helped me run a part time business while being a
                full time mom.”
              </p>
              <p>—Sierra P.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <h2 className="title">In demand, not on-demand</h2>
          <h3 className="subtitle">
            Always know your schedule ahead of time with lessons booked at least
            48 hours in advance.
          </h3>
        </div>
        <div>
          <img
            className="mx-auto"
            style={{ width: 970 }}
            src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvaW5zdHJ1Y3RvcnMtaW4tZGVtYW5kLWxnLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0="
          />
        </div>
        <div>
          <Link to={APP_ROUTES.SIGN_UP}>
            <Button
              variant="contained"
              shape="rounded-full"
              size="large"
              color="primary"
            >
              Set your own schedule
            </Button>
          </Link>
        </div>
      </section>
      <section className="bg-background">
        <div>
          <h2 className="title">We've got your back</h2>
          <h3 className="subtitle">Focus on teaching, we'll handle the rest</h3>
        </div>
        <div className="flex flex-col justify-between max-w-sm mx-auto space-y-14 sm:space-y-0 sm:max-w-5xl sm:space-x-8 md:space-x-16 sm:flex-row">
          <div className="flex-1 text-center sm:text-left">
            <div className="w-10 h-10 p-2 mx-auto mb-2 bg-green-400 rounded-full shadow sm:mx-0">
              <CheckIcon width={24} className="text-white" />
            </div>
            <h4 className="header">Secure payments</h4>
            <p>
              Clients pay up front when they book you for a lesson, so you’ll
              never worry about collecting cash or getting paid.
            </p>
          </div>
          <div className="flex-1 text-center sm:text-left">
            <div className="w-10 h-10 p-2 mx-auto mb-2 bg-blue-400 rounded-full shadow sm:mx-0">
              <CheckIcon width={24} className="text-white" />
            </div>
            <h4 className="header">Liability insurance</h4>
            <p>
              Stay protected from liability claims up to $10M, with coverage
              included on every Propel lesson booking.
            </p>
          </div>
          <div className="flex-1 text-center sm:text-left">
            <div className="w-10 h-10 p-2 mx-auto mb-2 bg-red-400 rounded-full shadow sm:mx-0">
              <CheckIcon width={24} className="text-white" />
            </div>
            <h4 className="header">Cancellation protection</h4>
            <p>
              With a site-wide 48 hour cancellation policy you'll never be left
              high and dry if a client can't make it.
            </p>
          </div>
        </div>
      </section>
      <CommonQuestions type="instructor" />
    </div>
  );
};

export default InstructorLearnMore;
