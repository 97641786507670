import RequiredIcon from '@mui/icons-material/FiberManualRecord';
import * as React from 'react';
import style from './style.module.scss';

interface Props {
  children: string;
  htmlFor?: string;
  required?: boolean;
  className?: string;
}

const Label: React.FunctionComponent<Props> = ({
  className,
  children,
  htmlFor,
  required,
}) =>
  children !== '' && (
    <label htmlFor={htmlFor}>
      {children}
      {required && (
        <span className="text-xxs">
          <RequiredIcon fontSize="inherit" className={style.required} />
        </span>
      )}
    </label>
  );

Label.defaultProps = {
  required: false,
};

export default Label;
