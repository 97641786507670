import { AdminMenuItems } from 'pages/account/admin/utils';
import { AppointmentDebugToolbar } from 'pages/account/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getRelease } from 'state/selectors';
import { SidebarMenu } from '..';
require('helpers/styles/recharts.css');

const Account = () => {
  const release = useSelector(getRelease);
  return (
    <>
      <SidebarMenu menuItems={AdminMenuItems}>
        <Switch>
          {AdminMenuItems.map((item, key) => (
            <Route
              key={`route-${key}`}
              exact={true}
              path={item.route.PATH}
              render={(routeProps) => <item.Component {...routeProps} />}
            />
          ))}
        </Switch>
      </SidebarMenu>
      <div className="fixed text-sm text-gray-700 left-4 bottom-4">
        {release}
      </div>
      <AppointmentDebugToolbar />
    </>
  );
};

export default Account;
