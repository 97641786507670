import api from 'api';
import InstructorAccount from 'api/Serializers/Accounts/Instructor';
import { Event, TimeFrame } from 'api/Serializers/Appointments';
import Button, { ListButton } from 'components/button';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Modal from 'components/modal';
import { CANCEL_POLICY_HRS, DATE_FMT, UserType } from 'config';
import AdminCancellationView from 'features/assistance/as-admin/views/admin';
import ClientCancellationView from 'features/assistance/as-client/views/cancel';
import ClientNoShowModal from 'features/assistance/as-instructor/views/client/no-show';
import InstructorCancellationView from 'features/assistance/as-instructor/views/instructor';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  AccountIcon,
  CancelIcon,
  CautionIcon,
  FacilityIcon,
  HelpIcon,
  RefundIcon,
} from 'icons';
import { GenericServerError } from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAccountDetail } from 'state/selectors';
import { fetchAppointment } from 'state/slice/appointments';
import {
  resetReport,
  setReportCategory,
  setReportType,
} from 'state/slice/assistance';
import { SHARED_ROUTES, SHOW_HELP } from 'utils/routing';
import { SectionProps, SECTION_CLASSES } from '../types';

const getDisabledTimeString = (
  appointment: Event,
  dt: string,
  showTime: boolean = true
) =>
  moment(dt)
    .tz(appointment.timezone)
    .format(showTime ? DATE_FMT.MON_D_TIME_A : DATE_FMT.MON_D);

const CancelledCallout = () => (
  <Callout type="warning">
    <div>Cancelled lessons can't be modified.</div>
  </Callout>
);

const LockedCallout = () => (
  <Callout type="warning">
    <div>Changes are not allowed 48 hours after completion.</div>
  </Callout>
);

export const WaivePolicyButton = ({ appointment }: { appointment: Event }) => {
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const dispatch = useAppDispatch();
  const [isWaiving, setIsWaiving] = useState(false);
  const [refundModelOpen, setRefundModelOpen] = useState(false);
  const cancellation = appointment.cancellation;
  if (!cancellation) return null;
  const total = Number(appointment.receipt.price);
  const admissionFee = Number(appointment.receipt.admissionFee);
  const admissionFeeTax = Number(appointment.receipt.admissionFeeTax);
  const admissionTotal = admissionFee + admissionFeeTax;
  const amountWaived = total - admissionTotal;
  return (
    <>
      <ListButton
        title="Reduce payment owed"
        onClick={() => {
          setRefundModelOpen(true);
        }}
        icon={<RefundIcon width={24} />}
      />
      <Modal
        name="Instructor — Waive policy help"
        title="Reduce payment owed"
        fullWidth
        maxWidth="xs"
        open={refundModelOpen}
        onClose={() => setRefundModelOpen(false)}
      >
        <div className="space-y-5">
          <p>
            Clients agree to a 48 hour cancellation policy each time they book a
            lesson.
          </p>
          <p>
            If you waive your cancellation policy for this lesson, the client
            will only be charged the amount required to cover pool admission
            plus tax, instead of paying your full lesson price.
          </p>
          <div className="p-4 space-y-2 rounded-lg bg-background">
            <ul>
              <li className="flex justify-between ">
                <span>Lesson price</span>
                <span>{total.toCurrency()}</span>
              </li>
              <li className="flex justify-between ">
                <span>Payment reduction</span>
                <span>-{amountWaived.toCurrency()}</span>
              </li>
              <hr />
              <li className="flex justify-between font-semibold">
                <span>Admission cost to client</span>
                <span>{admissionTotal.toCurrency()}</span>
              </li>
              <li className="flex justify-between font-semibold">
                <span>Instructor payout</span>
                <span>{(0).toCurrency()}</span>
              </li>
            </ul>
          </div>
          <p className="font-semibold">
            By continuing, you will save your client {amountWaived.toCurrency()}
            , but receive no payout. This action is irreversible.
          </p>
          <Controls>
            <Button
              color="default"
              variant="flat"
              onClick={() => setRefundModelOpen(false)}
              disabled={isWaiving}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="flat"
              isLoading={isWaiving}
              onClick={async () => {
                setIsWaiving(true);
                let success = false;
                try {
                  await api.cancellations.waive(appointment.cancellation.id);
                  success = true;
                } catch (err) {
                  enqueueSnackbar(GenericServerError);
                  setIsWaiving(false);
                }
                if (!success) return;
                dispatch(fetchAppointment(appointment.id));
              }}
            >
              Refund
            </Button>
          </Controls>
        </div>
      </Modal>
    </>
  );
};

const ManageSection = ({ appointment, userType }: SectionProps) => {
  if (userType === UserType.Host) return null;
  return (
    <div className={SECTION_CLASSES}>
      <h4>Manage</h4>
      {userType === UserType.Client ? (
        <ClientManage appointment={appointment} />
      ) : userType === UserType.Instructor ? (
        <InstructorManage appointment={appointment} />
      ) : userType === UserType.Admin ? (
        <AdminManage appointment={appointment} />
      ) : null}
    </div>
  );
};

const ClientManage = ({ appointment }: { appointment: Event }) => {
  const dispatch = useAppDispatch();
  const [showCancel, setShowCancel] = useState(false);

  const handleModalClose = () => {
    dispatch(resetReport());
    setShowCancel(false);
  };

  const handleCancelClick = () => {
    dispatch(setReportCategory('CLIENT'));
    dispatch(setReportType('CLIENT_CANCEL_REQUEST'));
    setShowCancel(true);
  };

  if (appointment.cancelled) {
    return <CancelledCallout />;
  } else if (appointment.timeFrame === TimeFrame.Locked) {
    return <LockedCallout />;
  } else if (appointment.timeFrame < TimeFrame.HasEnded) {
    const dt = moment(appointment.start)
      .tz(appointment.timezone)
      .subtract(CANCEL_POLICY_HRS, 'hours');
    const time = dt.format(DATE_FMT.TIME_A);
    const date = dt.format(DATE_FMT.MONTH_D);
    return (
      <div className="space-y-2">
        <h5 className="text-base font-bold">
          {CANCEL_POLICY_HRS} hour cancellation policy
        </h5>
        <p>
          Free cancellation before {time} on {date}. After that, the lesson is
          non-refundable.
        </p>
        <button className="link" onClick={SHOW_HELP.CANCELLATION_POLICY}>
          Review policy
        </button>
        <ListButton
          title={`Cancel ${appointment.activity.appointmentNoun.toLowerCase()}`}
          onClick={handleCancelClick}
          icon={<CancelIcon width={24} />}
        />
        <ListButton
          title={`My ${appointment.activity.instructorDescription.toLowerCase()} was absent`}
          subtitle={
            appointment.timeFrame < TimeFrame.HasEnded
              ? `Available on ${getDisabledTimeString(
                  appointment,
                  appointment.end
                )}`
              : null
          }
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'instructor/note')}
          icon={<CautionIcon width={24} />}
          disabled={appointment.timeFrame < TimeFrame.HasEnded}
        />
        <ClientCancellationView
          appointment={appointment}
          isOpen={showCancel}
          onClose={handleModalClose}
        />
      </div>
    );
  } else {
    return (
      <div className="space-y-2">
        <ListButton
          title="I was absent from this lesson"
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'no-show')}
          icon={<CancelIcon width={24} />}
        />
        <ListButton
          title="My instructor was absent"
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'instructor/note')}
          icon={<CautionIcon width={24} />}
        />
      </div>
    );
  }
};

const InstructorManage = ({ appointment }: { appointment: Event }) => {
  const dispatch = useAppDispatch();
  const [showCancel, setShowCancel] = useState(false);
  const [showNoShow, setShowNoShow] = useState(false);

  const handleModalClose = () => {
    dispatch(resetReport());
    setShowCancel(false);
    setShowNoShow(false);
  };

  if (appointment.cancelled) {
    if (appointment.cancellation.canWaivePolicy) {
      return <WaivePolicyButton appointment={appointment} />;
    } else {
      return <CancelledCallout />;
    }
  } else if (appointment.timeFrame === TimeFrame.Locked) {
    return <LockedCallout />;
  } else {
    return (
      <div className="space-y-2">
        <ListButton
          title={
            appointment.timeFrame >= TimeFrame.HasEnded
              ? 'I did not attend'
              : `I need to cancel this ${appointment.activity.appointmentNoun.toLowerCase()}`
          }
          icon={<CancelIcon width={24} />}
          onClick={() => setShowCancel(true)}
        />
        <ListButton
          title="My client never showed up"
          subtitle={
            appointment.timeFrame < TimeFrame.HasEnded
              ? `Available on ${getDisabledTimeString(
                  appointment,
                  appointment.end
                )}`
              : null
          }
          icon={<AccountIcon width={24} />}
          onClick={() => setShowNoShow(true)}
          disabled={appointment.timeFrame < TimeFrame.HasEnded}
        />
        <ListButton
          title="Report a facility issue"
          subtitle={
            appointment.timeFrame < TimeFrame.SameDay
              ? `Available on ${getDisabledTimeString(
                  appointment,
                  appointment.start,
                  false
                )}`
              : null
          }
          icon={<FacilityIcon width={24} />}
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'facility')}
          disabled={appointment.timeFrame < TimeFrame.SameDay}
        />
        <ListButton
          title="General issues"
          subtitle={
            appointment.timeFrame < TimeFrame.SameDay
              ? `Available on ${getDisabledTimeString(
                  appointment,
                  appointment.start,
                  false
                )}`
              : null
          }
          icon={<HelpIcon width={24} />}
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'general')}
          disabled={appointment.timeFrame < TimeFrame.SameDay}
        />
        <Modal
          name="Instructor — Cancel"
          title={`Cancel ${appointment.activity.appointmentNoun.toLowerCase()}`}
          open={showCancel}
          onClose={handleModalClose}
          maxWidth="xs"
        >
          <InstructorCancellationView
            appointment={appointment}
            onClose={handleModalClose}
          />
        </Modal>

        <Modal
          name="Instructor — Client no show"
          title={`${appointment.client.firstName} did not show`}
          open={showNoShow}
          onClose={handleModalClose}
          maxWidth="xs"
        >
          <ClientNoShowModal
            appointment={appointment}
            onClose={handleModalClose}
          />
        </Modal>
      </div>
    );
  }
};

const AdminManage = ({ appointment }: { appointment: Event }) => {
  const dispatch = useAppDispatch();
  const [showCancel, setShowCancel] = useState(false);
  const [showAdminCancel, setShowAdminCancel] = useState(false);
  const history = useHistory();

  if (appointment.cancelled) {
    if (appointment.cancellation.canWaivePolicy) {
      return <WaivePolicyButton appointment={appointment} />;
    } else {
      return <CancelledCallout />;
    }
  }
  return (
    <div className="space-y-2">
      <ListButton
        title="Cancel as Admin"
        icon={<AccountIcon width={24} />}
        onClick={() => setShowAdminCancel(true)}
      />
      <ListButton
        title="Client needs to cancel"
        icon={<AccountIcon width={24} />}
        onClick={() => setShowCancel(true)}
      />
      <ListButton
        title={
          appointment.timeFrame >= TimeFrame.HasEnded
            ? 'The instructor did not attend'
            : 'The instructor cannot attend'
        }
        icon={<CautionIcon width={24} />}
        to={SHARED_ROUTES.ASSISTANCE.nav(
          appointment.id,
          'instructor-cancellation'
        )}
      />
      <ListButton
        title="Report a facility issue"
        icon={<FacilityIcon width={24} />}
        to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'facility')}
      />
      <AdminCancellationView
        appointment={appointment}
        isOpen={showAdminCancel}
        onClose={() => setShowAdminCancel(false)}
      />
      <ClientCancellationView
        appointment={appointment}
        isOpen={showCancel}
        onClose={() => setShowCancel(false)}
      />
    </div>
  );
};

export default ManageSection;
