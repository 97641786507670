import api from 'api';
import {
  CertificateType,
  Certification,
  CertifyingBody,
} from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import Controls from 'components/controls';
import Input from 'components/input';
import Label from 'components/input-label';
import Loading from 'components/loading';
import React, { useState } from 'react';
import { EXTERNAL_ROUTES, InstructorOnboardingRoutes } from 'utils/routing';
import { BaseStepProps, HeaderClasses, TextClasses, TitleClasses } from '..';

const InsufficientQualifications: React.FC<BaseStepProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [nonStandardCertificationName, setNonStandardCertificationName] =
    useState(
      props.account.certifications.filter(
        (cert) => cert.nonStandardCertificationName !== ''
      ).length > 0
        ? props.account.certifications.filter(
            (cert) => cert.nonStandardCertificationName !== ''
          )[0].nonStandardCertificationName
        : ''
    );
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data: Certification = {
      certificateType: CertificateType.Unset,
      certifyingBody: CertifyingBody.Unset,
      certifiedBy: CertifyingBody.Unset,
      nonStandardCertificationName,
      certificateNumber: 'unknown',
    };
    await api.account.certifications.create(props.account.username, data);
    location.href = EXTERNAL_ROUTES.HELP.WHO_CAN_TEACH_WITH_PROPEL;
  };
  return (
    <div>
      <h1 className={TitleClasses}>Don't have the required certs?</h1>
      <p className={TextClasses}>
        For liability purposes, instructors must hold current certifications in
        order to join Propel.
      </p>
      <h1 className={HeaderClasses}>Looking to recertify?</h1>
      <p className={TextClasses}>
        We work with many different recertification partners and some of them
        even provide Propel discounts.
      </p>
      <div className="mt-6 mb-10 text-center">
        <Button
          variant="contained"
          color="primary"
          to={`${EXTERNAL_ROUTES.BLOG.INSTRUCTOR_PERKS}?ref=instructor-signup-no-certs`}
        >
          Show me
        </Button>
      </div>
      <h1 className={HeaderClasses}>Do you hold a different certification?</h1>
      <p className={TextClasses}>
        Right now, Propel instructors must be certified by either the Canadian
        Red Cross or the Lifesaving Society.
      </p>
      <p className={TextClasses}>
        If you hold a different certification, let us know which one it is.
      </p>
      {loading && <Loading />}
      {props.account.certifications.filter(
        (cert) => cert.nonStandardCertificationName !== ''
      ).length > 0 ? (
        <div>
          <div className="p-4 my-4 bg-gray-200 rounded-lg">
            <div>Certificate type or name</div>
            <div className="font-bold text-gray-800">
              {nonStandardCertificationName}
            </div>
          </div>
          <Controls variant="block">
            <Button to={InstructorOnboardingRoutes.S07}>Go back</Button>
            <Button
              to={EXTERNAL_ROUTES.HELP.WHO_CAN_TEACH_WITH_PROPEL}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Controls>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="my-6">
            <Label htmlFor="nonStandardCertificationName">
              Certificate type or name
            </Label>
            <Input
              name="nonStandardCertificationName"
              placeholder="Certificate Name"
              value={nonStandardCertificationName}
              onChange={(evt) =>
                setNonStandardCertificationName(evt.currentTarget.value)
              }
            />
          </div>
          <Controls variant="block">
            <Button to={InstructorOnboardingRoutes.S07}>Go back</Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || nonStandardCertificationName === ''}
            >
              Submit
            </Button>
          </Controls>
        </form>
      )}
    </div>
  );
};

export default InsufficientQualifications;
