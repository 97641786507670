import ClickAwayListener from '@mui/material/ClickAwayListener';
import { MoreMenuIcon } from 'icons';
import React, { useState } from 'react';

const MenuWrap = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpen = (evt) => {
    evt.stopPropagation();
    setOpenMenu(!openMenu);
  };
  const handleClickAway = (event) => {
    setOpenMenu(false);
  };
  const handleMenuClick = (event) => {
    setOpenMenu(false);
  };
  return (
    <div className="relative">
      <button onClick={handleOpen} className="btn-icon gray">
        <MoreMenuIcon width={24} className="pointer-events-none" />
      </button>
      {openMenu && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <span className="absolute right-0 z-50 w-56 h-auto py-2 overflow-hidden bg-white border border-gray-300 rounded-lg shadow-md top-full">
            <div className="flex flex-col" onClick={handleMenuClick}>
              {children}
            </div>
          </span>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default MenuWrap;
