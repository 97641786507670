import { DATE_FMT } from 'config';
import InstructorBaseTimeSlot from 'features/schedule/as-instructor/base-time-slot';
import { TimeSlotProps } from 'features/schedule/date-time-list/time-slot';
import BaseClasses from 'features/schedule/date-time-list/time-slot/base-classes';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { getScheduleAppointmentProduct } from 'state/selectors';
import { setProposalData } from 'state/slice/proposals';

const ClosedTimeSlot = ({
  timeSlot,
  tense,
  aptProdId,
  description,
}: TimeSlotProps) => {
  const timezone = useSelector(getScheduleAppointmentProduct)?.timezone;
  const dispatch = useAppDispatch();
  let classes: string;
  let handleClick = null;
  if (timeSlot.isBookable) {
    classes = BaseClasses.proposal.open;
    // description = 'Open';
    handleClick = () => {
      const datetime = timeSlot.datetime;
      const date = moment(timeSlot.datetime)
        .tz(timezone)
        .format(DATE_FMT.DATE_KEY);
      dispatch(setProposalData({ aptProdId, datetime, date }));
    };
    classes =
      'transparent-bg font-semibold hover:bg-orange-50 hover:text-orange-900';
  } else {
    classes = 'bg-gray-100 text-gray-600';
  }
  return (
    <InstructorBaseTimeSlot
      onClick={handleClick}
      datetime={timeSlot.datetime}
      timezone={timezone}
      description={description}
      classes={classes}
    />
  );
};

export default ClosedTimeSlot;
