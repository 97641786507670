import { FacilityCategory } from 'api/Serializers/Facilities';
import {
  FacilityGymIcon,
  FacilityHotelIcon,
  FacilityResidentialIcon,
} from 'icons';
import React from 'react';

interface Props {
  category: FacilityCategory;
  width?: number;
}

const FacilityCategoryIcon = ({ category, width = 24 }: Props) =>
  category === 'Hotel' ? (
    <FacilityHotelIcon width={width} />
  ) : category === 'Residential' ? (
    <FacilityResidentialIcon width={width} />
  ) : category === 'Fitness Club' ? (
    <FacilityGymIcon width={width} />
  ) : null;

export default FacilityCategoryIcon;
