import PayoutsApi from 'api/Serializers/Payouts';
import { AxiosResponse } from 'axios';
import { Api as FacilitiesApi } from './Serializers/Facilities';
import { Api as InstructorApi } from './Serializers/Instructors';
import SchedulesApi from './Serializers/Schedules';

import { Api as AppointmentFeedbackApi } from './Serializers/AppointmentFeedback';
import { Api as AvailabilityFeedbackApi } from './Serializers/AvailabilityFeedback';
import { CancellationsApi } from './Serializers/Cancellations';
import { Api as ConversationsApi } from './Serializers/Conversations';
import { Api as FavouritesApi } from './Serializers/Favourites';
import { Api as ReviewsApi } from './Serializers/Reviews';

import { Api as AppointmentProductApi } from 'api/Serializers/AppointmentProducts';
import { Api as AvailabilityApi } from 'api/Serializers/Availability';
import { Api as GiftCardsApi } from 'api/Serializers/GiftCards';
import { Api as PaymentMethodsApi } from 'api/Serializers/PaymentMethods';
import { Api as ProposalApi } from 'api/Serializers/Proposals';
import { Api as ServicesApi } from 'api/Serializers/Services';
import { Api as UserApi } from 'api/Serializers/Users';
import { Report } from 'models/Assistance';
import {
  AccountNoteSerializer,
  Api as AccountApi,
} from './Serializers/Accounts';
import { Api as ActivitiesApi, City } from './Serializers/Activities';
import { KpiSerializer } from './Serializers/Analytics';
import { AppointmentsApi } from './Serializers/Appointments';
import AuthorizationApi from './Serializers/Authorization';
import { Api as CartApi } from './Serializers/Cart';
import { Api as ClientsApi } from './Serializers/Clients';
import { PriceApi } from './Serializers/Price';
import { PromotionsApi } from './Serializers/Promotions';
import { TermsOfServiceAPI } from './Serializers/TermsOfService';
import { Api as UtilsApi } from './Serializers/Utils';

type RootSchema = {
  root: string;
};

type NewSchema = {
  baseUrl: string;
  root: string;
  getUrl: (endpoint: string, baseUrl?: string) => string;
};

export interface Schema {
  account: AccountApi & RootSchema;
  activities: ActivitiesApi & RootSchema;
  analytics: AnalyticsApi & RootSchema;
  appointmentFeedback: AppointmentFeedbackApi & RootSchema;
  appointmentProducts: AppointmentProductApi & RootSchema;
  availability: AvailabilityApi & RootSchema;
  availabilityFeedback: AvailabilityFeedbackApi & RootSchema;
  appointments: AppointmentsApi & RootSchema;
  auth: AuthorizationApi & RootSchema;
  cancellations: CancellationsApi & RootSchema;
  cart: CartApi & RootSchema;
  clients: ClientsApi & RootSchema;
  conversations: ConversationsApi & RootSchema;
  facilities: FacilitiesApi & RootSchema;
  favourites: FavouritesApi & RootSchema;
  giftCards: GiftCardsApi & RootSchema;
  instructors: InstructorApi & RootSchema;
  media: MediaApi & RootSchema;
  paymentMethods: PaymentMethodsApi & RootSchema;
  payouts: PayoutsApi & RootSchema;
  prices: PriceApi & RootSchema;
  proposals: ProposalApi & RootSchema;
  promotions: PromotionsApi & NewSchema;
  regions: RegionsApi & RootSchema;
  reports: ReportsApi & RootSchema;
  reviews: ReviewsApi & RootSchema;
  services: ServicesApi & NewSchema;
  schedules: SchedulesApi & RootSchema;
  termsOfService: TermsOfServiceAPI & RootSchema;
  users: UserApi & RootSchema;
  utils: UtilsApi & RootSchema;
  notes: NotesApi & RootSchema;
}

interface NotesApi {
  list(username: string): Promise<AxiosResponse<AccountNoteSerializer[]>>;
  create(
    username: string,
    data: { note: string }
  ): Promise<AxiosResponse<AccountNoteSerializer>>;
  patch(
    id: number,
    data: { note: string }
  ): Promise<AxiosResponse<AccountNoteSerializer>>;
  delete(id: number): Promise<AxiosResponse<any>>;
}

interface ReportsApi {
  create: (data: Report) => Promise<AxiosResponse<any>>;
}

interface RegionsApi {
  retrieve: (slug: string) => Promise<AxiosResponse<City>>;
}

interface AnalyticsParams {
  start: string;
  end: string;
}

interface AnalyticsApi {
  kpis: (signal?: AbortSignal) => Promise<AxiosResponse<KpiSerializer>>;
  dailySales: (params: AnalyticsParams) => Promise<AxiosResponse<any>>;
}

export enum ActivityType {
  PrivateSwimLessons = 'private-swim-lessons',
  ParentTotLessons = 'parent-tot-swim-lessons',
}

interface MediaApi {
  delete: (id: string) => Promise<AxiosResponse<any>>;
}
