import { ProposalListItem, ProposalStatus } from 'api/Serializers/Proposals';
import React from 'react';

const ProposalStatusBadge = ({ proposal }: { proposal: ProposalListItem }) => {
  switch (proposal.status) {
    case ProposalStatus.AwaitingClient:
      return (
        <span className="text-gray-700 bg-gray-300 badge">
          Waiting for Client Approval
        </span>
      );

    case ProposalStatus.BookingInProgress:
      return (
        <span className="text-orange-600 bg-orange-200 badge">
          Booking In Progress
        </span>
      );

    case ProposalStatus.BookingSuccessful:
      return <span className="text-green-700 bg-green-200 badge">Booked</span>;

    case ProposalStatus.ClientCancelled:
      return (
        <span className="text-red-700 bg-red-200 badge">
          Declined by Client
        </span>
      );

    case ProposalStatus.Expired:
      return <span className="text-red-700 bg-red-200 badge">Expired</span>;

    case ProposalStatus.InstructorCancelled:
      return (
        <span className="text-red-700 bg-red-200 badge">
          Cancelled by Instructor
        </span>
      );

    default:
      return (
        <span className="text-gray-700 bg-gray-300 badge">
          Waiting for Client Approval
        </span>
      );
  }
};

export default ProposalStatusBadge;
