import Avatar from 'components/avatar';
import React from 'react';
import { ReduxParticipant } from 'state/slice/messaging';

// Provide either the participant or the name and Avatar url
// name must contain at least one space (ie/ first and last name)
export const MessagingAvatar = ({
  className,
  as,
  participant,
  src,
  name,
}: {
  className?: string;
  as?: string;
  participant?: ReduxParticipant;
  src?: string;
  name?: string;
}) => (
  <div className="flex items-center pr-3">
    <Avatar
      className="bg-blue-500"
      diameter={10}
      src={participant ? participant.avatar : src ?? null}
    >
      {(participant || name) &&
        `${(participant ? participant.displayName : name).capitalize()[0]}${
          (participant ? participant.displayName : name)
            .capitalize()
            .split(' ')[1][0]
        }`}
    </Avatar>
  </div>
);
