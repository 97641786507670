import ArrowBack from '@mui/icons-material/ArrowBackIosNewRounded';
import MeasureIcon from '@mui/icons-material/Height';
import DollarIcon from '@mui/icons-material/MonetizationOnOutlined';
import OpenDetailIcon from '@mui/icons-material/OpenInNewRounded';
import FacilityIcon from '@mui/icons-material/Storefront';
import {
  FacilityInstructorSerializer,
  FacilitySchedulableSerializer,
} from 'api/Serializers/Facilities';
import Button from 'components/button';
import DetailSection from 'components/detail-section';
import InstructorCard from 'components/instructor-list-card';
import Link from 'components/link';
import FacilityScheduleUpdates from 'containers/facility-updates';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES, SHARED_ROUTES } from 'utils/routing';

interface Props {
  facility: FacilitySchedulableSerializer;
  instructors: FacilityInstructorSerializer[];
}

const FacilityDetail = ({ facility, instructors }: Props) => {
  const location = useLocation();
  location.pathname;
  return (
    <div
      className="w-full overflow-hidden bg-white "
      style={{ minHeight: 150 }}
    >
      <div className="absolute z-50 flex justify-between left-3 top-3 right-3">
        <span>
          <Link
            className="bg-white btn-icon"
            to={SHARED_ROUTES.FACILITIES.ROOT}
          >
            <ArrowBack className="-translate-x-px" />
          </Link>
        </span>
        <span>
          <Link
            className="bg-white btn-icon"
            to={APP_ROUTES.BROWSE_FACILITY(facility.region.slug, facility.slug)}
          >
            <OpenDetailIcon className="-translate-x-px" />
          </Link>
        </span>
      </div>
      <div className="flex flex-col">
        <div className="">
          <div>
            <img src={facility.hero} />
          </div>
          <DetailSection>
            <h1 className="mb-1 text-xl font-semibold text-gray-900">
              {facility.displayName}
            </h1>
            <div className="flex justify-start py-1 my-2 text-base font-medium text-gray-900 cursor-default">
              <FacilityIcon
                color="inherit"
                className="w-10 h-4 mr-2 text-gray-900"
              />
              {`${facility.attributes.subtype} ${facility.assetType}`}
            </div>
            <div className="flex justify-start py-1 my-2 text-base font-medium text-gray-900 cursor-default">
              <MeasureIcon
                color="inherit"
                className="w-10 h-4 mr-2 text-gray-900"
              />
              {`${facility.attributes.minDepth} to ${facility.attributes.maxDepth} deep`}
            </div>
            <div className="flex justify-start py-1 my-2 text-base font-medium text-gray-900 cursor-default">
              <span
                className=""
                style={{ transform: 'rotate(90deg) translate(2px, 4px)' }}
              >
                <MeasureIcon
                  color="inherit"
                  className="w-10 h-4 mr-2 text-gray-900"
                />
              </span>
              {`${facility.attributes.maxLength} long`}
            </div>
            <div className="flex justify-start py-1 my-2 text-base font-medium text-gray-900 cursor-default">
              <DollarIcon
                color="inherit"
                className="w-10 h-4 mr-2 text-gray-900"
              />
              ${facility.admissionFee} admission fee
            </div>
          </DetailSection>
          <DetailSection title="Latest schedule">
            {location.pathname !==
            SHARED_ROUTES.FACILITIES.ROOT + `/${facility.slug}/availability` ? (
              <Button
                to={
                  SHARED_ROUTES.FACILITIES.ROOT +
                  `/${facility.slug}/availability`
                }
                color="primary"
                variant="contained"
              >
                Availability Map
              </Button>
            ) : (
              <Button
                to={SHARED_ROUTES.FACILITIES.ROOT + `/${facility.slug}`}
                color="primary"
                variant="contained"
              >
                World Map
              </Button>
            )}
            <FacilityScheduleUpdates facility={facility} />
          </DetailSection>
          <DetailSection
            title={`${instructors.length} ${'instructor'.pluralize(
              instructors.length
            )} teaching here`}
          >
            <div className="space-y-3">
              {instructors?.length > 0
                ? instructors.map((instructor) => (
                    <div className="w-full" key={instructor.id}>
                      <InstructorCard {...instructor} />
                    </div>
                  ))
                : 'Loading...'}
            </div>
            {/* <div>
              <DollarIcon className="w-4 mr-3" style={{fontSize: 16}} />
              {instructors ? `$${Math.round(instructors.reduce((r, c, i) => r + c.basePrice, 0) / instructors.length)} Avg price` : 'Loading...'}
            </div> */}
          </DetailSection>
          <DetailSection title="Perks & Amenities">
            <div className="space-y-2">
              {[...facility.amenities]
                .sort((a, b) => b.value - a.value)
                .map((amenity) => (
                  <div
                    key={`amnty${amenity.id}`}
                    className="flex space-x-3 text-base text-gray-900"
                  >
                    <img className="h-6 opacity-75" src={amenity.iconUrl} />
                    <span className="text-base text-gray-900">
                      {amenity.title}
                    </span>
                  </div>
                ))}
            </div>
          </DetailSection>
          <DetailSection title="About">
            <span className="text-base text-gray-900">
              {facility.shortDescription}
            </span>
          </DetailSection>
        </div>
      </div>
    </div>
  );
};

export default FacilityDetail;
