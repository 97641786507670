import Page from 'containers/page';
import Messages from 'features/messages';
import React from 'react';

const MessagesPage = () => {
  return (
    <Page title="Messages">
      <Messages />
    </Page>
  );
};

export default MessagesPage;
