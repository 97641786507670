import { YearsExperience } from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import React, { useEffect, useState } from 'react';
import { InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  QuestionClasses,
  TitleClasses,
} from '..';

const WorkExperience: React.FC<BaseStepProps> = (props) => {
  const [selected, setSelected] = useState<YearsExperience>(
    props.onboarding.numYearsExp
  );
  useEffect(() => {
    if (selected && selected !== props.onboarding.numYearsExp) {
      props.modifyOnboarding({ numYearsExp: selected });
    }
  }, [selected]);
  return (
    <div>
      <h1 className={TitleClasses}>Tell us about your work experience</h1>
      <p className={QuestionClasses}>
        How long have you been working as a swim instructor?
      </p>
      <div className="space-y-3">
        <ButtonLarge
          variant="radio"
          title="Less than one year experience"
          selected={selected === YearsExperience.LessThanOne}
          onClick={() => setSelected(YearsExperience.LessThanOne)}
        />
        <ButtonLarge
          variant="radio"
          title="One to five years experience"
          selected={selected === YearsExperience.OneToFive}
          onClick={() => setSelected(YearsExperience.OneToFive)}
        />
        <ButtonLarge
          variant="radio"
          title="More than five years experience"
          selected={selected === YearsExperience.MoreThanFive}
          onClick={() => setSelected(YearsExperience.MoreThanFive)}
        />
        <p className="text-xs text-left">*Excluding unpaid volunteer work</p>
      </div>
      <div className={ControlClasses}>
        <Button
          to={
            selected === YearsExperience.LessThanOne
              ? InstructorOnboardingRoutes.S06
              : InstructorOnboardingRoutes.S07
          }
          variant="contained"
          disabled={!selected}
          color="primary"
        >
          Save and Continue
        </Button>
      </div>
    </div>
  );
};

export default WorkExperience;
