import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import TodayIcon from '@mui/icons-material/TodayOutlined';
import { Proposal } from 'api/Serializers/Proposals';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Row from 'components/row';
import { DATE_FMT, UserType } from 'config';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail } from 'state/selectors';

const ProposalBook = ({
  proposal,
  goBack,
  onProposalBook,
}: {
  proposal: Proposal;
  goBack(): void;
  onProposalBook(): void;
}) => {
  const [isBooking, setBooking] = useState(false);
  const [isSuccessful, setSuccessful] = useState(false);
  const user = useSelector(getAccountDetail);
  const action = user.type === UserType.Client ? 'Decline' : 'Cancel';
  const handleProposalBook = async () => {
    if (user.type !== UserType.Admin) {
      return;
    }
    setBooking(true);
    try {
      // const response = await api.proposals.book(proposal.id);
      setBooking(false);
      setSuccessful(true);
    } catch (error) {
      setBooking(false);
      setSuccessful(false);
    }
  };
  return (
    <div>
      {isBooking && <Loading message="Attempting to book..." />}
      <span>
        Are you sure you wish to {action.toLowerCase()} this proposed{' '}
        {proposal.activity.appointmentNoun.toLowerCase()}?
      </span>
      <Row height={3} className="flex">
        <TodayIcon className="mr-2 text-blue-400" />
        <span>
          {moment(proposal.start)
            .tz(proposal.timezone)
            .format(DATE_FMT.DOW_MONTH_D_TIME_A)}
        </span>
      </Row>
      <Row height={3} className="flex">
        <AccountCircleIcon className="mr-2 text-blue-400" />
        <span>
          Proposed {proposal.activity.name.toLowerCase()} with{' '}
          {proposal.client.fullName}
        </span>
      </Row>
      <Controls>
        <Button disabled={isBooking} onClick={goBack}>
          Go back
        </Button>
        <Button
          disabled={isBooking}
          onClick={handleProposalBook}
          color="secondary"
          variant="contained"
        >
          Yes, {action.toLowerCase()} proposal
        </Button>
      </Controls>
    </div>
  );
};

export default ProposalBook;
