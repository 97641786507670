import { FacilityListItemSerializer } from 'api/Serializers/Facilities';
import FacilityCard from 'components/facility-card';
import React from 'react';

interface Props {
  list: FacilityListItemSerializer[];
  linkGenerator?(f: FacilityListItemSerializer): string;
}

const FacilityList = ({ list, linkGenerator }: Props) => {
  const handleFacilityClick = (
    facility: FacilityListItemSerializer,
    index: number
  ) => {
    rudderanalytics.track('Facility clicked', { facility, index });
  };
  return (
    <div className="space-y-10">
      {list?.map((facility, index) => {
        return (
          <FacilityCard
            key={facility.id}
            facility={facility}
            linkGenerator={linkGenerator}
            onClick={() => handleFacilityClick(facility, index)}
          />
        );
      })}
    </div>
  );
};

export default FacilityList;
