import { YearsExperienceText } from 'api/Serializers/Accounts/Instructor';
import { InstructorListSerializer } from 'api/Serializers/Instructors';
import Avatar from 'components/avatar';
import Link from 'components/link';
import { DATE_FMT } from 'config';
import {
  AccountIcon,
  BusinessIcon,
  EmailIcon,
  NewIcon,
  PhoneIcon,
} from 'icons';
import moment from 'moment-timezone';
import React from 'react';
import { ADMIN_ROUTES } from '../utils';
import {
  hasNoFirstLesson,
  isActive,
  isChurned,
  isUpcomingOrientation,
} from './utils';

interface Props {
  instructor: InstructorListSerializer;
}

const Name = ({ instructor }: Props) => (
  <h3 className="my-0 font-medium text-gray-900 text-md">
    {instructor.fullName}
  </h3>
);
const Email = ({ instructor }: Props) => (
  <div className="flex items-center space-x-1">
    <EmailIcon width={16} />
    <span> {instructor.email}</span>
  </div>
);
const YearsExperience = ({ instructor }: Props) => (
  <div className="flex items-center space-x-1">
    <BusinessIcon width={16} />
    <span>
      {YearsExperienceText[`Exp${instructor.onboarding.numYearsExp}`]}{' '}
      experience
    </span>
  </div>
);

const datetimeIsToday = (datetime: string) => {
  if (!datetime) {
    return false;
  }
  return moment(datetime).isSame(moment(), 'day');
};

const OnboardingCall = ({ instructor }: Props) => {
  instructor.onboarding.callDatetime &&
    moment(instructor.onboarding.callDatetime).isSame(moment(), 'day');
  return (
    <div className="flex items-center space-x-1">
      <PhoneIcon width={16} />
      <span>
        {instructor.onboarding.callDatetime
          ? datetimeIsToday(instructor.onboarding.callDatetime)
            ? `Today at ${moment(instructor.onboarding.callDatetime).format(
                DATE_FMT.TIME_A
              )}`
            : `${moment(instructor.onboarding.callDatetime).format(
                DATE_FMT.MONTH_D_YEAR_TIME_A
              )}`
          : 'N/A'}
      </span>
    </div>
  );
};

const LiveSince = ({ instructor }: Props) => (
  <div className="flex items-center space-x-1">
    <NewIcon width={16} />
    <span>
      Live since {moment(instructor.liveSince).format(DATE_FMT.MON_D_YEAR)}
    </span>
  </div>
);

const InstructorStats = ({ instructor }: Props) => {
  const cancellationRate = instructor.cancellationRate
    ? Math.round(instructor.cancellationRate * 100)
    : -1;
  if (isActive(instructor)) {
    return (
      <div className="">
        <div className="grid grid-cols-3">
          <div className="flex flex-col items-center justify-center text-xs text-gray-700 border-r border-gray-300">
            <span className="flex items-center justify-end flex-1 text-gray-900">
              <AccountIcon width={24} />
            </span>
            <span>{`${instructor.numClients} ${'client'.pluralize(
              instructor.numClients
            )}`}</span>
          </div>
          <div className="flex flex-col items-center justify-center text-xs text-gray-700 border-r border-gray-300">
            <span
              className={`flex items-center justify-end flex-1 ${
                cancellationRate > 25
                  ? 'text-red-600'
                  : cancellationRate > 10
                  ? 'text-orange-600'
                  : 'text-gray-900'
              }`}
            >
              {cancellationRate > -1 ? `${cancellationRate}%` : '—'}
            </span>
            <span>Cancel rate</span>
          </div>
          <div className="flex flex-col items-center justify-center text-xs text-gray-700">
            <span className="flex items-center justify-end flex-1 font-semibold text-gray-900 text-md">
              {instructor.numAppointments}
            </span>
            <span>Lessons taught</span>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const InstructorData = ({ instructor }: Props) => {
  if (isUpcomingOrientation(instructor)) {
    return (
      <div className="space-y-0.5 text-xs">
        <Name instructor={instructor} />
        <Email instructor={instructor} />
        <OnboardingCall instructor={instructor} />
        <YearsExperience instructor={instructor} />
      </div>
    );
  } else if (hasNoFirstLesson(instructor)) {
    return (
      <div className="space-y-0.5 text-xs">
        <Name instructor={instructor} />
        <Email instructor={instructor} />
        <LiveSince instructor={instructor} />
        <YearsExperience instructor={instructor} />
      </div>
    );
  } else if (isChurned(instructor)) {
    return (
      <div className="space-y-0.5 text-xs">
        <Name instructor={instructor} />
        <Email instructor={instructor} />
        <LiveSince instructor={instructor} />
        <YearsExperience instructor={instructor} />
      </div>
    );
  } else if (isActive(instructor)) {
    return (
      <div className="space-y-0.5 text-xs">
        <Name instructor={instructor} />
        <Email instructor={instructor} />
        <LiveSince instructor={instructor} />
        <YearsExperience instructor={instructor} />
      </div>
    );
  }

  return null;
};

const InstructorCard = ({
  instructor,
}: {
  instructor: InstructorListSerializer;
}) => {
  const callIsToday =
    instructor.onboarding.callDatetime &&
    moment(instructor.onboarding.callDatetime).isSame(moment(), 'day');
  return (
    <Link
      key={instructor.id}
      to={ADMIN_ROUTES.INSTRUCTORS.detail(instructor.username)}
      className={`block`}
    >
      <div
        className={`w-full p-2 overflow-hidden transition-shadow duration-200 bg-white border ${
          callIsToday ? 'border-blue-500' : 'border-gray-400'
        } shadow rounded-xl hover:shadow-md`}
      >
        <div className="space-y-3">
          <div className="flex gap-2">
            <div className="">
              <Avatar src={instructor.avatar} diameter={24} variant="rounded" />
            </div>
            <InstructorData instructor={instructor} />
          </div>
          <InstructorStats instructor={instructor} />
        </div>
      </div>
    </Link>
  );
};

export default InstructorCard;
