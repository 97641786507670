/**
 * Created on Wed July 26 2023
 * Author Sean Hudson
 * Standardized list of icons for the Propel app
 */

// https://heroicons.com/
import {
  AcademicCapIcon,
  AdjustmentsHorizontalIcon,
  ArrowLeftOnRectangleIcon,
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  ArrowsRightLeftIcon,
  ArrowTopRightOnSquareIcon,
  AtSymbolIcon,
  BarsArrowDownIcon as HeroSortIcon,
  BellAlertIcon,
  BookmarkIcon as HeroBookmark,
  BriefcaseIcon,
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  CakeIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckBadgeIcon as HeroCheckBadge,
  CheckCircleIcon,
  CheckIcon as HeroCheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon as HeroClockIcon,
  Cog6ToothIcon,
  CreditCardIcon as HeroCreditCard,
  CurrencyDollarIcon,
  DocumentIcon as HeroDocumentIcon,
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon as HeroEyeOnIcon,
  EyeSlashIcon as HeroEyeOffIcon,
  FlagIcon as HeroFlagIcon,
  GlobeAmericasIcon,
  HandThumbUpIcon,
  HeartIcon,
  HomeModernIcon,
  InformationCircleIcon,
  MapIcon as HeroMapIcon,
  MapPinIcon,
  MinusCircleIcon,
  MinusIcon as HeroMinusIcon,
  NewspaperIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  PhoneIcon as HeroPhoneIcon,
  PlusIcon,
  PowerIcon,
  QuestionMarkCircleIcon,
  QueueListIcon,
  ReceiptRefundIcon,
  SparklesIcon,
  StarIcon as HeroStarIcon,
  TrashIcon as HeroTrashIcon,
  UserCircleIcon,
  UserGroupIcon as HeroUserGroup,
  UserIcon,
  UsersIcon,
  ViewColumnsIcon,
  WindowIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

// https://heroicons.com/
import {
  BuildingOffice2Icon as BuildingOffice2FilledIcon,
  GlobeAmericasIcon as GlobeAmericasFillledIcon,
  HeartIcon as HeartFilledIcon,
  StarIcon as HeroStarFilledIcon,
  UserGroupIcon as UGFilledIcon,
} from '@heroicons/react/24/solid';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

// https://fonts.google.com/icons?icon.set=Material+Icons
import {
  CarCrashOutlined,
  DirectionsCarOutlined,
  PoolRounded,
  ThermostatOutlined,
  ThumbUp,
} from '@mui/icons-material';

export const AccountIcon = UserCircleIcon;
export const AddIcon = PlusIcon;
export const AlertIcon = BellAlertIcon;
export const ArrowForwardIcon = ChevronRightIcon;
export const BackIcon = ChevronLeftIcon;
export const BirthdayIcon = CakeIcon;
export const BookmarkIcon = HeroBookmark;
export const BusinessIcon = BriefcaseIcon;
export const CautionIcon = ExclamationCircleIcon;
export const CancelIcon = XCircleIcon;
export const CertificationIcon = AcademicCapIcon;
export const CheckIcon = HeroCheckIcon;
export const CheckBadgeIcon = HeroCheckBadge;
export const ClockIcon = HeroClockIcon;
export const CloseIcon = XMarkIcon;
export const CreditCardIcon = HeroCreditCard;
export const DocumentIcon = HeroDocumentIcon;
export const DoneIcon = CheckCircleIcon;
export const DownIcon = ChevronDownIcon;
export const LeftIcon = ChevronLeftIcon;
export const RightIcon = ChevronRightIcon;
export const EditIcon = PencilSquareIcon;
export const EmailIcon = AtSymbolIcon;
export const ErrorIcon = XCircleIcon;
export const ExternalLinkIcon = ArrowTopRightOnSquareIcon;
export const EyeOnIcon = HeroEyeOnIcon;
export const EyeOffIcon = HeroEyeOffIcon;
export const FacilityFilledIcon = BuildingOffice2FilledIcon;
export const FacilityGymIcon = BuildingStorefrontIcon;
export const FacilityHotelIcon = BuildingOffice2Icon;
export const FacilityIcon = BuildingOffice2Icon;
export const FacilityResidentialIcon = HomeModernIcon;
export const FacilitySchedulesIcon = QueueListIcon;
export const FavoriteIcon = HeartIcon;
export const FavoriteOnIcon = HeartFilledIcon;
export const FlagIcon = HeroFlagIcon;
export const FreeParkingIcon = DirectionsCarOutlined;
export const HelpIcon = QuestionMarkCircleIcon;
export const HomeIcon = HomeModernIcon;
export const InfoIcon = InformationCircleIcon;
export const KPIIcon = ChartPieIcon;
export const LoginIcon = ArrowRightOnRectangleIcon;
export const LogoutIcon = ArrowLeftOnRectangleIcon;
export const MapIcon = HeroMapIcon;
export const MessageIcon = ChatBubbleOvalLeftEllipsisIcon;
export const MessagesIcon = ChatBubbleLeftRightIcon;
export const MinusIcon = HeroMinusIcon;
export const MoneyIcon = CurrencyDollarIcon;
export const MoreMenuIcon = EllipsisHorizontalIcon;
export const NewIcon = SparklesIcon;
export const NewsIcon = NewspaperIcon;
export const OneParticipantIcon = UserIcon;
export const PaidParkingIcon = CarCrashOutlined;
export const PayoutIcon = CurrencyDollarIcon;
export const PhoneIcon = HeroPhoneIcon;
export const PlaceIcon = MapPinIcon;
export const ProxyLoginIcon = WindowIcon;
export const ProposalIcon = SparklesIcon;
export const PreferencesIcon = AdjustmentsHorizontalIcon;
export const RefreshIcon = ArrowPathIcon;
export const RefundIcon = ReceiptRefundIcon;
export const RegionFilledIcon = GlobeAmericasFillledIcon;
export const RegionIcon = GlobeAmericasIcon;
export const ReviewIcon = HandThumbUpIcon;
export const RemoveIcon = MinusCircleIcon;
export const ScheduleIcon = CalendarDaysIcon;
export const SearchIcon = MagnifyingGlassIcon;
export const SendIcon = PaperAirplaneIcon;
export const SettingsIcon = Cog6ToothIcon;
export const SortIcon = HeroSortIcon;
export const StarIcon = HeroStarIcon;
export const StarFilledIcon = HeroStarFilledIcon;
export const SuccessIcon = CheckCircleIcon;
export const SwimIcon = PoolRounded;
export const TemperatureIcon = ThermostatOutlined;
export const ThumbIcon = ThumbUp;
export const OnboardingIcon = AcademicCapIcon;
export const TOSIcon = ClipboardDocumentCheckIcon;
export const TrashIcon = HeroTrashIcon;
export const TwoParticipantIcon = UsersIcon;
export const TwoWayArrowIcon = ArrowsRightLeftIcon;
export const UpIcon = ChevronUpIcon;
export const UserGroupFilledIcon = UGFilledIcon;
export const UserGroupIcon = HeroUserGroup;
export const UserStatusIcon = PowerIcon;
export const WeekIcon = ViewColumnsIcon;
export const WarningIcon = ExclamationTriangleIcon;
