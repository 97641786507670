/**
 * One stop shop for meta data information
 */
import * as Sentry from '@sentry/react';
import { FacilityDetailSerializer as Facility } from 'api/Serializers/Facilities';
import { InstructorDetailSerializer } from 'api/Serializers/Instructors';
import { IS_SERVER } from 'config';
import {
  FacilityListReduxProps,
  InstructorListReduxProps,
} from 'models/pages/locations';
import { APP_ROUTES } from 'utils/routing';
import { getHeroMedia } from 'utils/string';

interface Tags {
  id: string;
  title?: string;
  titleSuffix?: boolean;
  description?: string;
  image?: string;
}

// https://www.propelhq.com/account/
export const Account: Tags = {
  id: 'account',
  title: 'My Account',
  description: 'Propel Account Dashboard',
};

export const AppointmentReview: Tags = {
  id: 'instructor-review',
  title: 'Tell us how it was!',
  description: 'Submit a review of your time with your Propel instructor',
};

// https://www.propelhq.com/asdf/
export const Error404: Tags = {
  id: 'error-404',
  title: "404: Page Doesn't Exist",
  description: "Oops! We weren't able to find that page for you.",
};

// https://www.propelhq.com/
export const Home: Tags = {
  id: 'home',
  title: 'Swim Lessons for Adults, Teens, Children, and Special Needs',
  description:
    'Find your favourite swimming instructor and book private lessons in a pool near you. Sign up anytime with no registration dates or waitlists.',
};

// https://www.propelhq.com/how-to-host/
export const LearnMoreHost: Tags = {
  id: 'how-to-host',
  title: 'How Propel Works For Pools',
  description:
    'Offer private swim lessons as an amenity and turn your pool into a profitable asset. Create a new revenue source for your hotel, gym, or condo.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuL2hvc3QvaGVhZGVyLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=',
};

// https://www.propelhq.com/how-to-teach/
export const LearnMoreInstructor: Tags = {
  id: 'account',
  title: 'How Propel Works For Instructors',
  description:
    'Set your own hours and pay. Access a network of exclusive pools and offer private swim lessons to anyone.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hlcm9zL2xlYXJuLW1vcmUtaW5zdHJ1Y3Rvci1oZXJvLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=',
};

// https://www.propelhq.com/how-to-learn/
export const LearnMoreClient: Tags = {
  id: 'account',
  title: 'How Propel Works For Swimmers',
  description:
    'Conveniently book private swimming lessons with your choice of instructor and location at times that work for you.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hlcm9zL2xlYXJuLW1vcmUtZ2VuZXJhbC1oZXJvLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=',
};

// https://www.propelhq.com/about-us/
export const AboutUsMeta: Tags = {
  id: 'pricing',
  title: 'Meet the Founders',
  description:
    'In 2015, we made it our mission to help the world learn how to swim by providing private lessons that are more affordable, accessible, flexible, and effective.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hlcm9zL3Byb3BlbC1waWdneS1iYW5rLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=',
};

// https://www.propelhq.com/privacy/
export const PrivacyMeta: Tags = {
  id: 'privacy',
  title: 'Privacy Policy',
  description: 'Privacy & Cookie Policy',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuL2luc3RydWN0b3ItaG93LWl0LXdvcmtzMi5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9',
};

// https://www.propelhq.com/terms-of-service/
export const TermsOfServiceMeta: Tags = {
  id: 'terms-of-service',
  title: 'Terms of Service',
  description: 'Read the Terms of Service for using the Propel platform.',
  image: '',
};

export const GiftCardsMeta: Tags = {
  id: 'gift-cards',
  title: 'Gift Cards',
  description: 'Give the gift of a Propel gift card',
  image:
    'https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/gift-cards/gift-card-meta.png',
};

// https://www.propelhq.com/feedback/availability/:form/:uid/
export const AvailabilityFeedbackMeta: Tags = {
  id: 'availability-feedback',
  title: 'Availability Feedback',
  description: "Let us know why you're stepping away from Propel",
  image: '',
};

// https://www.propelhq.com/feedback/lesson/:rating/:eventId/
export const AppointmentFeedbackMeta: Tags = {
  id: 'appointment-feedback',
  title: 'Lesson Feedback',
  description: 'Let us know how your lesson went',
  image: '',
};

// https://www.propelhq.com/locations
export const CitySelectPageMeta: (pathname) => Tags | undefined = (pathname) =>
  pathname === APP_ROUTES.BROWSE_CITIES
    ? {
        id: 'locations-list',
        title: `Find Swim Lessons In Your City`,
        description:
          'Say goodbye to waitlists and registration days. Choose the location, time, and instructor that fits your needs, and learn faster with Propel.',
      }
    : undefined;

// https://www.propelhq.com/locations/vancouver-bc
export const FacilityListMeta: (props: FacilityListReduxProps) => Tags = ({
  facilities,
  region,
}) => ({
  id: 'locations',
  title: `Learn to Swim Near ${region}`,
  description: `Find a Propel pool for your private swim lessons in ${region}. Choose a pool and browse available instructors.`,
  image: facilities?.length > 0 ? facilities[0].hero : '',
});

// https://www.propelhq.com/locations/vancouver-bc/residence-inn-vancouver
export const FacilityDetailMeta: ({
  facility,
}: {
  facility: Facility;
}) => Tags = ({ facility }) => {
  if (!facility) {
    Sentry.captureEvent({
      environment: 'express',
      message: `Some FacilityDetailMeta not loaded:${
        !facility ? ' !Facility ' : ''
      }`,
    });
    return {
      id: 'locations',
      title: `Learn to Swim at this official Propel pool`,
    };
  }
  const image = getHeroMedia(facility.media);
  return {
    id: 'locations',
    title: `Learn to Swim at the ${facility.displayName} · ${facility.region.label}`,
    description: facility.description,
    image,
  };
};

// https://www.propelhq.com/pool-opportunities/residence-inn-vancouver
export const OpportunitiesMeta = (facility: Facility): Tags => {
  const image = getHeroMedia(facility.media);
  return {
    id: 'opportunities',
    title: `Become a swim instructor at ${facility.displayName} · ${facility.region.label}`,
    description: facility.description,
    image,
  };
};

// https://www.propelhq.com/locations/vancouver-bc/residence-inn-vancouver/instructors
export const InstructorListMeta: (props: InstructorListReduxProps) => Tags = ({
  facility,
  instructors,
  ...rest
}) => {
  const id = 'locations';
  if (IS_SERVER && (!facility || !instructors)) {
    Sentry.captureEvent({
      environment: 'express',
      message: `Some InstructorListMeta not loaded:${
        !safe(instructors)
          ? ' !Instructors'
          : instructors.length === 0
          ? ' Instructors.length=0'
          : ''
      }${!facility ? ' !Facility ' : ''}`,
    });
  }
  let title = `Find an Instructor${
    facility ? ` at ${facility.displayName} · ${facility.region.label}` : ''
  }`;
  let description =
    'Find an instructor for your private swim lessons. Choose an instructor and view their profile and schedule.';
  let image = '';
  if (instructors && facility) {
    title = `${instructors.length} Swim ${'Instructor'.pluralize(
      instructors.length
    )} · ${facility.displayName}`;
    description = `Find an instructor for your private swim lessons at the ${
      facility.displayName
    }. Choose from ${instructors.length} ${'instructor'.pluralize(
      instructors.length
    )}, view their profile, and book a swimming lesson today.`;
    image = facility.media[0].smUrl;
  }
  return {
    id,
    title,
    description,
    image,
  };
};

// https://www.propelhq.com/locations/vancouver-bc/residence-inn-vancouver/kristen-n
export const InstructorDetailMeta: (
  facility: Facility,
  instructor: InstructorDetailSerializer
) => Tags = (facility, instructor) => {
  const id = 'locations';
  if (IS_SERVER && (!instructor || !facility)) {
    Sentry.captureEvent({
      environment: 'express',
      message: `Some InstructorDetailMeta not loaded:${
        !instructor ? ' !Instructor' : ''
      }${!facility ? ' !Facility ' : ''}`,
    });
    return {
      id,
      title: 'Learn to Swim with Propel Qualified Instructors',
      description:
        "Learn to Swim with Propel's swim instructors in quiet, beautiful locations around your city",
    };
  }
  const image =
    getHeroMedia(instructor.media) !== ''
      ? getHeroMedia(instructor.media)
      : getHeroMedia(facility.media);
  return {
    id,
    title: `Learn to Swim with ${instructor.displayName} · ${facility.region.label}`,
    description: `Lessons with ${instructor.displayName} at the ${
      facility.displayName
    }: ${instructor.description.substr(0, 80)}...`,
    image,
  };
};

// https://www.propelhq.com/login/
export const LoginMeta: Tags = {
  id: 'login',
  title: 'Sign in to your account',
  description:
    'Sign in to your Propel account to access your dashboard, update your schedule, and modify your settings.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuL2luc3RydWN0b3ItaG93LWl0LXdvcmtzMi5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9',
};

// https://www.propelhq.com/logout/
export const LogoutMeta: Tags = {
  id: 'logout',
  title: 'Logout of Your Account',
  description: 'Log out of your Propel account.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuL2luc3RydWN0b3ItaG93LWl0LXdvcmtzMi5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9',
};

// https://www.propelhq.com/account/onboarding/welcome
export const NewInstructorFormMeta: Tags = {
  id: 'onboarding',
  title: 'Welcome! Complete Your Profile',
  description:
    'To get your profile setup, we need some basic information about you. Your progress is saved after every step.',
};

// www.propelhq.com/password-reset/:uid/
export const PasswordResetConfirmMeta: Tags = {
  id: 'password-reset-confirm',
  title: 'Password Reset',
  description: 'Change your Propel account password.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuL2luc3RydWN0b3ItaG93LWl0LXdvcmtzMi5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9',
};

// www.propelhq.com/password-reset/
export const PasswordResetRequestMeta: Tags = {
  id: 'password-reset-request',
  title: 'Password Reset',
  description: 'Change your Propel account password.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuL2luc3RydWN0b3ItaG93LWl0LXdvcmtzMi5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9',
};

// https://www.propelhq.com/sign-up/to-host/
export const SignUpAsHostMeta: Tags = {
  id: 'sign-up',
  title: 'Sign Up to Host',
  description:
    'Propel is the private lesson platform that works for you. Create your account and list your pool today.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuL2hvc3QvaGVhZGVyLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=',
};

// https://www.propelhq.com/sign-up/to-teach/
export const SignUpAsInstructorMeta: Tags = {
  id: 'sign-up',
  title: 'Sign Up to Teach',
  description:
    'The Next Step in Aquatics. Create a profile, post your teaching availability, and get booked to teach private swimming lessons today.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL3RlYWNoLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=',
};

export const SignUpAsClientMeta: Tags = {
  id: 'sign-up',
  title: 'Sign Up to Swim',
  description:
    'Propel is the easiest way to find and register for private swim lessons. Create your account and learn to swim the easy way.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL3N3aW0uanBnIiwiYnVja2V0Ijoib2xla3NpYWsifQ==',
};

export const SignUpGeneral: Tags = {
  id: 'sign-up',
  title: 'Create Your Account',
  description:
    'Propel is the easiest way to start teaching lessons, or find and register for private swim lessons. Create your account get started with swimming, the easy way.',
  image:
    'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL3N3aW0uanBnIiwiYnVja2V0Ijoib2xla3NpYWsifQ==',
};
