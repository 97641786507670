import Page from 'containers/page';
import AdminSchedule from 'features/schedule/as-admin';
import React from 'react';

const CalendarPage = () => {
  return (
    <Page title="Schedule">
      <AdminSchedule />
    </Page>
  );
};

export default CalendarPage;
