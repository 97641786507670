import { IS_SERVER } from 'config';
import React, { useEffect, useRef, useState } from 'react';
import ReactSelect, { createFilter } from 'react-select';
export { components } from 'react-select';

export interface SelectOption<T> {
  label: string | number;
  value?: T;
  data?: any;
  options?: SelectOption<T>[];
  objectId?: number;
  slug?: string;
  username?: string;
  avatar?: string;
  initials?: string;
  approved?: boolean;
  schedule?: string;
  hideCount?: boolean;
}

interface Props<T> {
  options: SelectOption<T>[];
  autoFocus?: boolean;
  className?: string;
  defaultMenuIsOpen?: boolean;
  defaultValue?: SelectOption<T>;
  disabled?: boolean;
  error?: boolean;
  formatGroupLabel?: any;
  formatOptionLabel?: any;
  inputValue?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  loadingMessage?: (obj: { inputValue: string }) => string;
  maxMenuHeight?: number;
  menuIsOpen?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  minMenuHeight?: number;
  name?: string;
  onChange?(value: SelectOption<T>): void;
  openMenuOnFocus?: boolean;
  openOnFocus?: boolean;
  components?: {
    Option?: any;
    SingleValue?: any;
  };
  placeholder?: string;
  ref?: any;
  styles?: any;
  type?: string;
  value?: SelectOption<T>;
}

const GroupLabel = (data) => (
  <div className="flex items-center justify-between">
    <span>{data.label}</span>
    {!data.hideCount && (
      <span className="px-2 py-px text-sm leading-none text-center text-gray-600 bg-gray-200 rounded-full">
        {data.options.length}
      </span>
    )}
  </div>
);

export const CREATE_NEW_VALUE = 'create-new';

const Select = ({
  className,
  components = null,
  disabled = false,
  error = false,
  isSearchable = false,
  ...rest
}: Props<any>) => {
  const [hasMounted, setMounted] = useState(false);
  const selectEl = useRef(null);
  const classNames = `text-left ${safe(className) ? ` ${className}` : ''}`;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: state.isDisabled ? '#EBECEF' : 'white',
      color: state.isDisabled ? '#B8BCC7' : '#2D3748',
      cursor: 'pointer',
      height: '100%',
      minHeight: 45,
      borderRadius: 12,
      borderColor: error ? 'rgb(229 62 62 / 0.7)' : 'rgb(211 217 223)',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: error ? 'rgb(97 35 35 / 0.5)' : '#A9B2C1',
      fontSize: '0.875rem',
      fontFamily: '"Inter", "Helvetica Neue", Helvetica, sans-serif',
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '0.875rem',
      fontFamily: '"Inter", "Helvetica Neue", Helvetica, sans-serif',
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: '0.875rem',
      padding: '2px 12px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: '0.875rem',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    ...rest.styles,
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <ReactSelect
      {...rest}
      isSearchable={isSearchable}
      isDisabled={disabled}
      ref={selectEl}
      className={classNames}
      styles={customStyles}
      components={components ? components : null}
      formatGroupLabel={GroupLabel}
      filterOption={createFilter({ ignoreAccents: false })}
      menuPortalTarget={!IS_SERVER ? document?.body : null}
    />
  );
};

export default Select;
