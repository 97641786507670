import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import Button from 'components/button';
import { ButtonProps } from 'components/button/base';
import { useSnackbar } from 'notistack';
import React from 'react';

interface Props {
  copyText: string;
  className?: string;
  alertText?: string;
  children?: any;
}

const ButtonCopyText: React.FC<ButtonProps & Props> = ({
  copyText,
  className = '',
  onClick = undefined,
  alertText = '',
  variant = 'outlined',
  children = '',
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const onCopyClick = (event) => {
    const message =
      alertText === ''
        ? `Copied '${
            copyText.length > 30 ? `${copyText.substring(0, 30)}...` : copyText
          }' to clipboard`
        : alertText;
    copyToClipboard(copyText);
    enqueueSnackbar(message, { variant: 'success' });
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <Button
      className={className}
      icon={<CopyIcon />}
      onClick={onCopyClick}
      variant={variant}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonCopyText;
