import { ClientSerializer } from 'api/Serializers/Clients';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import Controls from 'components/controls';
import { FETCH_STATE, UserType } from 'config';
import Pagination from 'containers/paginate';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAccountDetail,
  getClientList,
  getClientListFetchState,
  getCurrentUser,
} from 'state/selectors';
import { setProposalClient } from 'state/slice/proposals';

const ClientCard = ({
  data,
  onClick,
}: {
  onClick(client: ClientSerializer): void;
  data: ClientSerializer;
}) => {
  const subtitle =
    data.numFutureAppointments === 0
      ? 'No more bookings'
      : `${data.numFutureAppointments} more booked`;
  return (
    <ButtonLarge
      variant="blank"
      title={data.fullName}
      subtitle={subtitle}
      onClick={() => onClick(data)}
    />
  );
};

const ClientList = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(getAccountDetail);
  const currentUser = useSelector(getCurrentUser);
  const clients = useSelector(getClientList);
  const isClientsLoading = useSelector(getClientListFetchState);
  const [clientSlice, setClientSlice] = useState<ClientSerializer[]>([]);
  const [search, setSearch] = useState('');
  const history = useHistory();

  useEffect(() => {
    let _clients;
    const relevantClients =
      user.type === UserType.Admin && currentUser.type === UserType.Instructor
        ? clients.filter(
            (client) => client.instructors.indexOf(currentUser.id) > -1
          )
        : clients;
    if (search !== '') {
      _clients = [...relevantClients]
        .map((client) => ({
          ...client,
          order: `${client.firstName} ${client.lastName}`
            .toLowerCase()
            .indexOf(search.toLowerCase()),
        }))
        .filter((client) => client.order > -1)
        .sort((a, b) => a.order - b.order);
    } else {
      _clients = [...relevantClients].sort((a, b) => {
        // push null recentAppointment values to bottom, then sort by recentAppointment descending
        if (!a.recentAppointment && b.recentAppointment) {
          return 1;
        } else if (!b.recentAppointment && a.recentAppointment) {
          return -1;
        }
        return a.recentAppointment === b.recentAppointment
          ? 0
          : a.recentAppointment > b.recentAppointment
          ? -1
          : 1;
      });
    }
    setClientSlice(_clients);
  }, [search, clients]);

  const handleSelectClient = (client: ClientSerializer) => {
    dispatch(setProposalClient(client));
  };

  return (
    <div>
      <input
        className="px-4 py-3 my-4 font-medium text-gray-600 bg-gray-200 border-transparent rounded-lg focus:bg-white focus:border-gray-400"
        placeholder="Search"
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        autoFocus={true}
      />
      {isClientsLoading === FETCH_STATE.GET ? (
        [1, 2, 3, 4, 5].map((i) => <ClientLoading key={`loading-${i}`} />)
      ) : (
        <Pagination
          data={clientSlice}
          render={({ key, ...client }) => (
            <ClientCard
              key={`client-${key}`}
              data={client}
              onClick={handleSelectClient}
            />
          )}
          itemsPerPage={5}
          position="bottom"
        />
      )}
      <Controls>
        <Button onClick={() => history.goBack()} variant="flat">
          Go back
        </Button>
      </Controls>
    </div>
  );
};

const ClientLoading = () => (
  <div className="flex h-20 p-2 my-2 rounded-lg w-80 sm:w-96 animate-pulse">
    <div className="w-10 h-10 mr-2 rounded-full bg-loading" />
    <div className="flex-1">
      <div className="w-24 h-5 mb-1.5 rounded-md bg-loading" />
      <div className="w-11/12 h-3 my-1 rounded bg-loading" />
      <div className="w-4/5 h-3 my-1 rounded bg-loading" />
      <div className="w-2/5 h-3 my-1 rounded bg-loading" />
    </div>
  </div>
);

export default ClientList;
