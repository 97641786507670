import {
  InstructorTimeSlotType,
  ScheduleChange,
  ScheduleChangeAction,
} from 'api/Serializers/Schedules';
import { DATE_FMT } from 'config';
import InstructorBaseTimeSlot from 'features/schedule/as-instructor/base-time-slot';
import { TimeSlotProps } from 'features/schedule/date-time-list/time-slot';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { getScheduleAppointmentProduct } from 'state/selectors';
import { addScheduleChange } from 'state/slice/schedule';

const ClosedTimeSlot = ({
  timeSlot,
  tense,
  aptProdId,
  isChanged,
  description,
}: TimeSlotProps) => {
  const timezone = useSelector(getScheduleAppointmentProduct)?.timezone;
  const dispatch = useAppDispatch();
  let handleClick = () => {
    const change: ScheduleChange = {
      aptProdId,
      id: timeSlot.id,
      datetime: timeSlot.datetime,
      date: moment(timeSlot.datetime).tz(timezone).format(DATE_FMT.DATE_KEY),
      action: ScheduleChangeAction.Create,
    };
    dispatch(addScheduleChange(change));
  };
  let newDesc = description;
  let classes = '';
  if (isChanged) {
    newDesc = '* Remove availability';
    if (timeSlot.instructorStatus === InstructorTimeSlotType.Booked) {
      newDesc += ', appointment still scheduled';
    }
    classes = 'font-semibold bg-red-50 text-red-900 hover:bg-red-100';
  } else if (timeSlot.isBookable) {
    classes =
      'transparent-bg font-semibold hover:bg-blue-50 hover:text-blue-900';
  } else {
    classes = 'bg-gray-100 text-gray-600';
  }
  return (
    <InstructorBaseTimeSlot
      onClick={handleClick}
      datetime={timeSlot.datetime}
      timezone={timezone}
      description={newDesc}
      classes={classes}
    />
  );
};

export default ClosedTimeSlot;
