import CommuteIcon from '@mui/icons-material/Commute';
import CarIcon from '@mui/icons-material/DirectionsCar';
import TransitIcon from '@mui/icons-material/DirectionsTransit';
import React from 'react';
import { FacilityDirections } from '.';

interface Props {
  directions: FacilityDirections;
  onClick?(): void;
  placeholder?: string;
}

const DirectionsInfo = ({
  directions,
  onClick,
  placeholder = 'Loading...',
}: Props) => {
  const containerClasses = `space-x-1.5 flex text-base text-gray-700 flex items-center${
    onClick ? ' cursor-pointer hover:text-blue-500' : ''
  }`;
  if (directions) {
    if (
      directions.directions.status === google.maps.DirectionsStatus.OK &&
      directions.directions.routes.length > 0
    ) {
      const length = Math.round(
        directions.directions.routes[0].legs.reduce(
          (r, c, i) => r + c.distance.value,
          0
        ) / 1000
      );
      const duration = Math.round(
        directions.directions.routes[0].legs.reduce(
          (r, c, i) => r + c.duration.value,
          0
        ) / 60
      );
      return (
        <div className={containerClasses} onClick={onClick}>
          <span
            className={`text-xl flex items-center ${
              onClick ? ' p-1 rounded-full border-gray-300 border' : ''
            }`}
          >
            {directions.travelMode === google.maps.TravelMode.DRIVING ? (
              <CarIcon fontSize="inherit" className="" />
            ) : directions.travelMode === google.maps.TravelMode.TRANSIT ? (
              <TransitIcon fontSize="inherit" className="" />
            ) : null}
          </span>
          <span className="px-2 py-1 font-medium leading-none text-green-700 bg-green-200 rounded-lg ">
            {duration} min
          </span>
          <span className="font-medium text-gray-600">{length} km</span>
        </div>
      );
    } else {
      return (
        <div className={containerClasses}>
          <span className="flex items-center text-xl">
            {directions.travelMode === google.maps.TravelMode.DRIVING ? (
              <CarIcon fontSize="inherit" className="text-gray-600 " />
            ) : directions.travelMode === google.maps.TravelMode.TRANSIT ? (
              <TransitIcon fontSize="inherit" className="text-gray-600 " />
            ) : null}
          </span>
          <span className="px-2 py-1 font-medium leading-none text-red-700 bg-red-200 rounded-lg">
            Error
          </span>
          <span className="font-medium text-gray-600">
            Cannot get directions, please try again.
          </span>
        </div>
      );
    }
  } else {
    return (
      <div className={containerClasses}>
        <span className="flex items-center text-xl">
          <CommuteIcon fontSize="inherit" className="text-gray-600 " />
        </span>
        <span className="py-1 mr-1 text-gray-600">{placeholder}</span>
      </div>
    );
  }
};

export default DirectionsInfo;
