import { ApiException } from 'api/errors';
import Select, { SelectOption } from 'components/select';
import { FIELDS, MAX_LENGTHS } from 'config';
import useForm from 'hooks/useForm';
import { Address } from 'models/geo';
import React, { useEffect, useState } from 'react';

interface Props extends Address {
  name: string;
  onChange(address: Address): void;
}

const Label = ({ htmlFor, children, error = false }) => (
  <label htmlFor={htmlFor} className={!!error ? 'text-red-800' : ''}>
    {children}
  </label>
);

const Error = ({ error }: { error: ApiException<string> }) => {
  if (!error) {
    return null;
  }
  return <div className="input-error-message">{error.message}</div>;
};

const Input = ({
  label,
  name,
  onChange,
  inputs,
  errors,
  disabled = false,
  type = 'text',
  error = null,
  onBlur = null,
  placeholder = null,
  maxLength = null,
}) => {
  return (
    <>
      <Label htmlFor={name} error={error}>
        {label}
      </Label>
      <input
        id={name}
        name={name}
        type={type}
        autoComplete={name}
        placeholder={placeholder}
        className={`${!!error ? 'input-error' : ''}`}
        onChange={onChange}
        onBlur={onBlur}
        value={inputs[name]}
        maxLength={maxLength}
        disabled={disabled}
      />
      <Error error={error} />
    </>
  );
};

const provinces = [
  {
    label: 'Alberta',
    value: 'Alberta',
  },
  {
    label: 'British Columbia',
    value: 'British Columbia',
  },
  {
    label: 'Manitoba',
    value: 'Manitoba',
  },
  {
    label: 'New Brunswick',
    value: 'New Brunswick',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'Newfoundland and Labrador',
  },
  {
    label: 'Nova Scotia',
    value: 'Nova Scotia',
  },
  {
    label: 'Ontario',
    value: 'Ontario',
  },
  {
    label: 'Prince Edward Island',
    value: 'Prince Edward Island',
  },
  {
    label: 'Quebec',
    value: 'Quebec',
  },
  {
    label: 'Saskatchewan',
    value: 'Saskatchewan',
  },
];

const InputAddress = ({ name, onChange, ...address }: Props) => {
  const [errors, setErrors] = useState<Error>(undefined);
  const onSubmit = () => {};
  const { inputs, handleInputChange } = useForm(address, onSubmit);

  const handleSelectChange =
    (name: string) => (option: SelectOption<string>) => {
      const event = {
        target: {
          name,
          value: option?.value,
        },
      };
      handleInputChange(event);
      onChange({ ...inputs, [name]: option?.value });
    };

  useEffect(() => {
    onChange(inputs);
  }, [inputs]);

  return (
    <div className="space-y-3">
      <Input
        name={FIELDS.LINE1}
        label="Street address"
        inputs={inputs}
        errors={errors}
        placeholder={'eg. 1188 18th Ave'}
        onChange={handleInputChange}
        maxLength={MAX_LENGTHS.ADDRESS_LINE1}
      />
      <Input
        name={FIELDS.LINE2}
        label="Apt, suite, unit (optional)"
        inputs={inputs}
        errors={errors}
        placeholder={'eg. #250'}
        onChange={handleInputChange}
        maxLength={MAX_LENGTHS.ADDRESS_LINE2}
      />
      <Input
        name={FIELDS.CITY}
        label="City"
        inputs={inputs}
        errors={errors}
        onChange={handleInputChange}
        maxLength={MAX_LENGTHS.ADDRESS_CITY}
      />
      <Label htmlFor={FIELDS.REGION}>Province</Label>
      <Select
        name={FIELDS.REGION}
        options={provinces}
        value={provinces.find((opt) => opt.value === address?.region)}
        onChange={handleSelectChange(FIELDS.REGION)}
      />
      <Input
        name={FIELDS.POSTAL_CODE}
        label="Postal code"
        inputs={inputs}
        errors={errors}
        onChange={handleInputChange}
        maxLength={MAX_LENGTHS.ADDRESS_POSTAL_CODE}
        placeholder={`eg. V6T`}
      />
    </div>
  );
};

export default InputAddress;
