import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import api from 'api';
import { UserSearchPreferences } from 'api/Serializers/Accounts';
import Dashboard from 'components/account/dashboard';
import { AvailabilitySelectCard } from 'components/availability-select';
import Button from 'components/button';
import Callout from 'components/callout';
import Loading from 'components/loading';
import { FETCH_STATE, SORT_OPTIONS } from 'config';
import { SearchFilters } from 'containers/instructor-list';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useFirstRender } from 'hooks/useFirstRender';
import { DownIcon, SortIcon } from 'icons';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getSearchApptProdIds,
  getSearchPreferences,
  getSearchSorting,
} from 'state/selectors';
import {
  addSearchApptProdIds,
  fetchApptProdAvailability,
  setAppointmentProductsFetchState,
  setSearchApptProdIds,
  setSearchSorting,
  upsertAppointmentProducts,
} from 'state/slice/search';

const APPT_PROD_FETCH_LIMIT = 5;

const getSearchPreferencesParams = (
  searchPreferences: UserSearchPreferences,
  constructor: { [key: string]: string } = {}
) => {
  const params = new URLSearchParams(constructor);
  if (!!searchPreferences.segment) {
    params.set('segment', searchPreferences.segment.value);
  }
  if (searchPreferences.times.length > 0) {
    params.set('startHour', searchPreferences.times[0].start.toString());
    params.set('endHour', searchPreferences.times[0].end.toString());
    searchPreferences.times.forEach((item) =>
      params.append('weekday', item.weekDay.toString())
    );
  }
  if (searchPreferences.maxPrice !== undefined) {
    params.set('maxPrice', searchPreferences.maxPrice.toString());
  }
  if (searchPreferences.location?.latlng !== undefined) {
    const location = searchPreferences.location;
    params.set(
      'point',
      location.latlng.lat.toString() + ',' + location.latlng.lng.toString()
    );
    if (location.radius !== undefined) {
      params.set('radius', location.radius.toString());
    }
  }
  return params;
};

const SearchPage = () => {
  const dispatch = useAppDispatch();
  const searchPreferences = useSelector(getSearchPreferences);
  const bookMoreApptProdIds = useSelector(getSearchApptProdIds);
  const sortParams = useSelector(getSearchSorting);
  const [apptProdPage, setApptProdPage] = useState(
    bookMoreApptProdIds.length / APPT_PROD_FETCH_LIMIT
  );
  const [hasMore, setHasMore] = useState(true);
  const [sortAnchorEl, setSortAnchorEl] = useState<HTMLButtonElement>(null);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const isFirstRender = useFirstRender();
  const isDefaultSort = sortParams === SORT_OPTIONS[0].value;

  const fetchData = async (isRefresh: boolean = true) => {
    const loadingSetter = isRefresh ? setIsRefreshing : setIsLoadingNextPage;
    loadingSetter(true);
    const fetchAvailForIds = [];
    const currentPage = isRefresh ? 0 : apptProdPage;
    const params = getSearchPreferencesParams(searchPreferences, {
      limit: (APPT_PROD_FETCH_LIMIT + 1).toString(),
      order: sortParams,
      offset: (currentPage * APPT_PROD_FETCH_LIMIT).toString(),
      ...(isDefaultSort ? { hasBookedMinAvailability: '0' } : {}),
    });
    try {
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.GET));
      const response = await api.appointmentProducts.search(params);
      const hasNextPage = response.data.length === APPT_PROD_FETCH_LIMIT + 1;
      const data = response.data.slice(0, APPT_PROD_FETCH_LIMIT);
      dispatch(upsertAppointmentProducts(data));
      const updateIdsFunc = isRefresh
        ? setSearchApptProdIds
        : addSearchApptProdIds;
      dispatch(updateIdsFunc(data.map((apptProd) => apptProd.id)));
      setApptProdPage(currentPage + 1);
      setHasMore(hasNextPage);
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.FULFILLED));
      data.forEach((apptProd) => fetchAvailForIds.push(apptProd.id));
    } catch (err) {
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.FAILED));
      enqueueSnackbar({
        message: 'Error getting instructors. Please refresh to try again.',
        variant: 'error',
      });
      return;
    } finally {
      loadingSetter(false);
    }
    Promise.all(
      fetchAvailForIds.map((id) => dispatch(fetchApptProdAvailability(id)))
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (clientHeight + scrollTop + 1000 < scrollHeight) {
        return;
      }
      fetchData(false);
    };
    if (hasMore && !isRefreshing && !isLoadingNextPage) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoadingNextPage, isRefreshing]);

  useEffect(() => {
    if (isFirstRender && bookMoreApptProdIds.length > 0) {
      setIsRefreshing(false);
    } else {
      fetchData();
    }
  }, [searchPreferences, sortParams]);

  return (
    <>
      <Dashboard width="2xl" title="Search">
        <div className="flex flex-col gap-8">
          <div className="flex justify-center">
            <div className="flex justify-center w-full max-w-lg gap-2 md:gap-4">
              <SearchFilters
                allowedFilters={['times', 'segment', 'maxPrice', 'location']}
              />
              <Button
                className={`w-full bg-white md:w-auto${
                  isDefaultSort ? '' : ' border-2'
                }`}
                variant="outlined"
                color={isDefaultSort ? 'default' : 'primary'}
                icon={<SortIcon width={24} />}
                onClick={(e) => setSortAnchorEl(e.currentTarget)}
              >
                Sort
                <DownIcon className="pl-1.5" width={24} />
              </Button>
              <Menu
                anchorEl={sortAnchorEl}
                open={!!sortAnchorEl}
                onClose={() => setSortAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: -16, horizontal: 'left' }}
              >
                {SORT_OPTIONS.map((elt) => (
                  <MenuItem
                    key={elt.label}
                    onClick={() => {
                      dispatch(setSearchSorting(elt.value));
                      setSortAnchorEl(null);
                    }}
                    selected={sortParams === elt.value}
                  >
                    {elt.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
          {isRefreshing ? (
            <Loading position="fixed" message="Loading instructors..." />
          ) : (
            <>
              {bookMoreApptProdIds.length > 0 ? (
                <>
                  <div className="flex flex-col gap-4">
                    {bookMoreApptProdIds.map((id) => (
                      <AvailabilitySelectCard
                        key={id}
                        appointmentProductId={id}
                        manageAvailabilityFetching={false}
                        noTimesCTA="messageInstructor"
                        source="SEARCH"
                      />
                    ))}
                  </div>
                  {!hasMore && (
                    <p className="mt-8 mb-12 text-center text-gray-600">
                      Showing all results
                    </p>
                  )}
                </>
              ) : (
                <div className="flex justify-center">
                  <Callout className="max-w-sm grow" title="No results found">
                    Try broadening your search
                  </Callout>
                </div>
              )}
            </>
          )}
        </div>
        {hasMore && (
          <div
            className={`flex justify-center w-full flex-0${
              isLoadingNextPage ? '' : ' invisible'
            }`}
          >
            <Loading position="inline-contained" />
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default SearchPage;
