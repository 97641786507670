import api from 'api';
import Button from 'components/button';
import Select, { SelectOption } from 'components/select';
import React, { useEffect, useState } from 'react';
import { InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  QuestionClasses,
  TextClasses,
  TitleClasses,
} from '..';

interface Props extends BaseStepProps {
  hideControls?: boolean;
}

const UniqueExperience: React.FC<Props> = ({
  account,
  modifyAccount,
  hideControls = false,
}) => {
  const [experienceData, setExperienceData] = useState<SelectOption<string>[]>(
    []
  );
  const [specialization1, setSpecialization1] = useState<SelectOption<string>>(
    account.specialization1 === ''
      ? undefined
      : { label: account.specialization1 }
  );
  const [specialization2, setSpecialization2] = useState<SelectOption<string>>(
    account.specialization2 === ''
      ? undefined
      : { label: account.specialization2 }
  );

  useEffect(() => {
    if (specialization1 && specialization1.label !== account.specialization1) {
      modifyAccount({ specialization1: specialization1.label as string });
    } else if (!specialization1 && account.specialization1 !== '') {
      modifyAccount({ specialization1: '' });
    }
  }, [specialization1]);
  useEffect(() => {
    if (specialization2 && specialization2.label !== account.specialization2) {
      modifyAccount({ specialization2: specialization2.label as string });
    } else if (!specialization2 && account.specialization2 !== '') {
      modifyAccount({ specialization2: '' });
    }
  }, [specialization2]);
  useEffect(() => {
    const fetchExperiences = async () => {
      const response = await api.instructors.experience();
      setExperienceData(response.data);
    };
    fetchExperiences();
  }, []);
  return (
    <div>
      <h1 className={TitleClasses}>Tell us about your unique expertise</h1>
      <p className={TextClasses}>
        Your skills and experiences set you apart from others.
      </p>
      <p className={QuestionClasses}>
        Do you have any specializations or additional skills you’d like to
        highlight?
      </p>
      <Select
        className="my-6"
        placeholder="Select..."
        isClearable={true}
        options={
          specialization2
            ? experienceData.filter((v) => v.label !== specialization2.label)
            : experienceData
        }
        value={specialization1}
        onChange={(value: SelectOption<string>) => setSpecialization1(value)}
      />
      <Select
        className="my-6"
        placeholder="Select..."
        isClearable={true}
        // options={specialization1 ? experienceData.filter(v => v.label !== specialization1.label) : experienceData}
        options={
          specialization1
            ? experienceData.filter((v) => v.label !== specialization1.label)
            : experienceData
        }
        value={specialization2}
        onChange={(value: SelectOption<string>) => setSpecialization2(value)}
      />
      {!hideControls && (
        <div className={ControlClasses}>
          <Button
            color="primary"
            variant="contained"
            to={InstructorOnboardingRoutes.S11}
          >
            {!specialization1 && !specialization2
              ? 'Skip'
              : 'Save and Continue'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UniqueExperience;
