import Page from 'containers/page';
import { PrivacyMeta as Meta } from 'metadata';
import React from 'react';
import style from './style.module.scss';

const PrivacyPolicy = () => {
  return (
    <Page {...Meta}>
      <div className={style.container}>
        <article
          id="146aecf9-5859-4be5-8f9a-dab1655de6b8"
          className="w-full max-w-4xl p-4 mx-auto sm:p-6"
        >
          <header>
            <h1 id="fe3fd4c8-2b49-48f1-bd25-6f9c557fe450">
              Privacy &amp; Cookies Policy
            </h1>
            <div>
              <span className="pr-2">Last Updated</span>
              <span className="font-bold">Aug 7, 2023</span>
            </div>
          </header>
          <div>
            <ul
              id="4f9457b4-496a-4865-94ab-ab9e3a259a39"
              className="pl-4 list-none"
            >
              <li>
                <details>
                  <summary className="cursor-pointer">
                    Table of Contents
                  </summary>
                  <nav
                    id="6aa8621e-c5ad-4e7e-9a84-b1d3655294a2"
                    className="text-gray-700"
                  >
                    <div className="block p-1 ml-3 text-sm leading-snug">
                      <a
                        className=""
                        href="#fe3fd4c8-2b49-48f1-bd25-6f9c557fe450"
                      >
                        Privacy &amp; Cookies Policy
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#d08471db-0724-4018-affa-8711984bf5c1"
                      >
                        Who We Are and What This Policy Covers
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#7766c8ae-7dd8-42b0-88d8-113981398879"
                      >
                        Information We Collect
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#74630cbc-701e-439a-a44d-471d42ddecfd"
                      >
                        Information You Provide to Us
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#53480c37-1407-40f5-8d3b-7185af6aa774"
                      >
                        Information We Collect Automatically
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#823643ed-a0e6-42a8-b1b1-aafc5b012c40"
                      >
                        Information We Collect from Other Sources
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#100d1fb2-1b8a-433f-9b92-e5ee55ead270"
                      >
                        Cookies
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#c783bd57-0c96-4222-86d1-b780bc596e9d"
                      >
                        What are cookies?
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#666a409c-94b2-4380-87da-2fa34ed963cf"
                      >
                        What cookies do we use?
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#56079cb3-192a-4d94-b8c4-acdb5413e460"
                      >
                        How can I disable cookies?
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#e4a267cd-5c99-43b1-867e-b2380c2673a9"
                      >
                        How and Why We Use Information
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#d6bd3a5b-06a9-48ee-8481-dfe7234a82d7"
                      >
                        Purposes for Using Information
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#a993c5eb-2ce7-4dd0-9860-39fed1165ee9"
                      >
                        Legal Bases for Collecting and Using Information
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#2a464d40-717d-4946-9b8b-98c2490a89bc"
                      >
                        Sharing Information
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#cb0a2407-8a7e-4a23-8522-257dece84e78"
                      >
                        How We Share Information
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#ab3503b4-2e06-4466-8eb4-234c6bb71fa9"
                      >
                        Information Shared Publicly
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#1f2932db-8366-4ed1-9127-3ca30bf85c70"
                      >
                        How Long We Keep Information
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#8d5eae9f-c504-443d-8ece-de4be0f338d9"
                      >
                        Where We Store Information
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#285cab66-1d87-4297-844f-6897f9b101fb"
                      >
                        Security
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#59343216-e4ad-470a-bc39-6e1ef646006f"
                      >
                        Your Rights
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#558daeae-5a89-4651-badd-9e940d688f93"
                      >
                        <em>
                          European General Data Protection Regulation (GDPR)
                        </em>
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#ca77cd0a-1f6b-44ec-b935-77728ad1c4cd"
                      >
                        <em>California Consumer Privacy Act (CCPA)</em>
                      </a>
                    </div>
                    <div className="block p-1 ml-12 text-sm leading-snug">
                      <a
                        className=""
                        href="#866fadb7-708e-459f-b554-11dced9b01fb"
                      >
                        <em>Contacting Us About These Rights</em>
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#2c2a5750-10f5-4f53-880f-cea09befc8d2"
                      >
                        Controllers
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#10b876f1-c471-4248-904b-137a2854db97"
                      >
                        How to Reach Us
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#895d5171-b2a4-4ecb-b5fc-156ccbc272de"
                      >
                        Our Policy on Children&#x27;s Privacy
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#d9c19fe2-26fd-499d-bbda-9e000d966a67"
                      >
                        Links to Other Websites
                      </a>
                    </div>
                    <div className="block p-1 ml-6 text-sm leading-snug">
                      <a
                        className=""
                        href="#20a1800a-7622-4ff2-9480-ab1fccc8e398"
                      >
                        Changes to Our Privacy &amp; Cookies Policy
                      </a>
                    </div>
                  </nav>
                </details>
              </li>
            </ul>
          </div>
          <div>
            <p id="1c505f73-2a7c-412a-93b1-56f0429a3dfa">
              Your privacy is critically important to us. At Propel HQ Inc. we
              have a few fundamental principles:
            </p>
            <ul id="ef612524-db72-48f1-bbc2-3cfdd31be8ff" className="list-disc">
              <li>
                We are thoughtful about the personal information we ask you to
                provide and the personal information that we collect about you
                through the operation of our services.
              </li>
            </ul>
            <ul id="331d0f16-5a63-4a3a-a84d-42082ad3c838" className="list-disc">
              <li>
                We store personal information for only as long as we have a
                reason to keep it.
              </li>
            </ul>
            <ul id="575a691f-133e-49fd-92b8-936591b38a10" className="list-disc">
              <li>
                We aim to make it as simple as possible for you to control what
                information on our website is shared publicly (or kept private),
                indexed by search engines, and permanently deleted.
              </li>
            </ul>
            <ul id="09e06c37-f2ca-4ad8-a7ac-78032eef0551" className="list-disc">
              <li>
                We aim for full transparency on how we gather, use, and share
                your personal information.
              </li>
            </ul>
            <p id="fca23ea7-c592-4175-b7c1-e361d633703a">
              Below is our Privacy Policy, which incorporates and clarifies
              these principles.
            </p>
            <h2 id="d08471db-0724-4018-affa-8711984bf5c1">
              Who We Are and What This Policy Covers
            </h2>
            <p id="4bc4cd3b-142b-4b06-bd2a-e3a79500e4ef">
              We are Propel HQ Inc. (&quot;Propel,&quot; &quot;we,&quot;
              &quot;us,&quot; or &quot;our&quot;), and we made it our mission to
              help the world learn how to swim by providing private lessons that
              are more affordable, accessible, flexible, and effective. Today,
              we build technology that helps swim instructors leverage their
              experience to better serve their communities as recreation
              entrepreneurs.
            </p>
            <p id="685aaff6-f968-4023-98eb-a938a5a94a4c">
              This Privacy Policy applies to information that we collect about
              you when you use our online services (the &quot;Services&quot;),
              which are made available to you through our website located at{' '}
              <a href="http://www.propelhq.com/">www.propelhq.com</a> (the
              &quot;Site&quot;), or our mobile app (the &quot;App&quot;).
            </p>
            <p id="7a87771a-9500-4c5e-97e0-fdad78542588">
              By visiting our Site and/or using our App and/or Services, you are
              agreeing to the terms of this Privacy Policy.
            </p>
            <h2 id="7766c8ae-7dd8-42b0-88d8-113981398879">
              Information We Collect
            </h2>
            <p id="a9b88818-694a-4900-bca9-1eb3c841a960">
              We only collect information about you if we have a reason to do so
              — for example, to provide our Services, to communicate with you,
              or to make our Services better.
            </p>
            <p id="b3b6840d-af0e-41b2-b2ba-79b618560ea6">
              We collect this information from three sources: if and when you
              provide information to us, automatically through operating our
              Services, and from outside sources. Let’s go over the information
              that we collect.
            </p>
            <h3 id="74630cbc-701e-439a-a44d-471d42ddecfd">
              Information You Provide to Us
            </h3>
            <p id="d7d21fc9-5a5e-4a89-9142-ca29423d6c99">
              As you might expect, we collect and store the information that you
              provide to us directly. Here are some examples:
            </p>
            <ul
              id="715e1e36-f02a-4e61-a1b8-11ba4c87a763"
              className="mb-6 list-disc"
            >
              <li>
                <strong>Basic Account Information:</strong> We ask for basic
                information from you in order to set up your account, such as
                first name, last name, email address, phone number, current city
                of residence, and password. You may provide us with more
                information — such as how you heard about our Services — but we
                don&#x27;t require that information to create a Propel account.
              </li>
              <li>
                <strong>Public profile information</strong>: If you are
                registered as an Instructor with Propel, we collect the
                information that you provide for your public profile. For
                example, your first name and initial of your last name are part
                of your public profile, along with a profile photo, description,
                specific known skills, existence and type of valid training
                certificates, lesson price, choice of location, and teaching
                availability.
              </li>
              <li>
                <strong>Payment and Contact Information:</strong> As a user of
                our Services, you may provide personal and payment information
                like your name, credit card information, and contact
                information. We also keep a record of the purchases you&#x27;ve
                made. If you offer Services as an Instructor with Propel, we
                will also collect direct deposit information for the purposes of
                paying out your earnings.
              </li>
              <li>
                <strong>Swimmer Information:</strong> As a user of our Services,
                you may provide limited information about lesson participants.
                For example, the first name of each participant may be given to
                assist the Instructor with lesson planning.{' '}
                <strong>
                  It is important you obtain the permission of the participant,
                  or the participant&#x27;s parent or legal guardian before
                  submitting this data.{' '}
                </strong>
                For more on this, please see{' '}
                <a className="" href="#895d5171-b2a4-4ecb-b5fc-156ccbc272de">
                  Our Policy on Children&#x27;s Privacy
                </a>
              </li>
              <li>
                <strong>Communications with us:</strong> You may also provide us
                with information when you respond to surveys, polls, texts,
                emails, support questions, reviews, or other communications we
                send/ask-for in normal operation of our Services. Additionally,
                when you communicate with us via form, email, phone, review, or
                otherwise, we store a copy of our communications (including any
                call recordings as permitted by applicable law).
              </li>
            </ul>
            <h3 id="53480c37-1407-40f5-8d3b-7185af6aa774">
              Information We Collect Automatically
            </h3>
            <p id="856e11bb-a5f4-4596-a9ea-02882a8aa955">
              We also collect some information automatically:
            </p>
            <ul
              id="6dd2e5b7-436a-4bd5-bff4-1e04654db9ff"
              className="mb-6 list-disc"
            >
              <li>
                <strong>Log information:</strong> Like most online service
                providers, we collect information that web browsers, mobile
                devices, and servers typically make available, including the
                browser type, IP address, unique device identifiers, language
                preference, referring site, the date and time of access,
                operating system, and mobile network information. We collect log
                information when you use our Services — for example, when you
                create or make changes to your account.
              </li>
              <li>
                <strong>Usage information:</strong> We collect information about
                your usage of our Services. For example, we collect information
                about the actions that users perform, and any preferences that
                are set. We also collect information about what happens when you
                use our Services (e.g., page views, support document searches at{' '}
                <a href="http://help.propelhq.com">help.propelhq.com</a> or in
                the help sidebar, search queries and results, and any errors
                that occur), along with information about your device (e.g.,
                screen size, name of cellular network, and mobile device
                manufacturer). We use this information to, for example, provide
                our Services to you, get insights on how people use our Services
                so we can make our Services better, and understand and make
                predictions about user retention.
              </li>
              <li>
                <strong>Location information:</strong> We may determine the
                approximate location of your device from your IP address. We
                collect and use this information to, for example, show relevant
                localized content, or calculate how many people visit our
                Services from certain geographic regions. We may also collect
                information about your precise location via our mobile apps
                (like when you post a photograph with location information) if
                you allow us to do so through your mobile device operating
                system’s permissions.
              </li>
              <li>
                <strong>Stored information:</strong> We may access information
                stored on your device. We access this stored information through
                your browser permissions and/or device operating system’s
                permissions. For example, if you give us permission to access
                the photographs on your mobile device’s camera roll, our
                Services may access the photos stored on your device when you
                upload a really amazing photograph of your teaching skills in
                action.
              </li>
              <li>
                <strong>
                  Information from cookies &amp; other technologies:
                </strong>{' '}
                A cookie is a string of information that a website stores on a
                visitor’s computer, and that the visitor’s browser provides to
                the website each time the visitor returns. Pixel tags (also
                called web beacons) are small blocks of code placed on websites
                and emails. Propel uses cookies and other technologies like
                pixel tags to help us identify and track visitors, usage,
                engagement, and access preferences for our Services, as well as
                track and understand email campaign effectiveness and to deliver
                targeted ads on external website (e.g., Facebook or Google ads).
                For more information about our use of cookies and other
                technologies for tracking, including how you can control the use
                of cookies, please see the section on{' '}
                <a className="" href="#100d1fb2-1b8a-433f-9b92-e5ee55ead270">
                  Cookies
                </a>{' '}
                below.
              </li>
            </ul>
            <h3 id="823643ed-a0e6-42a8-b1b1-aafc5b012c40">
              Information We Collect from Other Sources
            </h3>
            <p id="d93b2f5b-5cdf-4a31-be53-64d644a8fc6f">
              We may also get information about you from other sources. For
              example, if you create or log in to your Propel account through
              another service (like Google) or if you connect your website or
              account to a social media service (like Twitter), we&#x27;ll
              receive information from that service (e.g., your username, basic
              profile information) via the authorization procedures for that
              service. The information we receive depends on which services you
              authorize and what options are available.
            </p>
            <p id="f66ada35-8add-4156-a5dc-a2f7d7e7f84d">
              As a client using Propel&#x27;s Services, we may collect
              information about lesson participants&#x27; goals, progress, and
              lesson plans, mainly to improve the Service.
            </p>
            <p id="49e887ba-9fca-4644-8d29-59e1e7eeb259">
              If you are an Instructor, we may collect extra information about
              your certifications and customer interactions. For example, if you
              are registered with a certified training body (e.g., the Red
              Cross), your information may be available to us through partner
              programs with those training bodies (e.g., Canadian Red Cross
              Training Partner program). Additionally, we may collect reviews
              from your clients to post on your profile.
            </p>
            <p id="8092d03a-ba19-43f8-a3e4-2f4ce4d75812">
              We may ask users of our Services to provide emergency contact
              information should anything happen where we need it (and we hope
              we never do!). It is possible that Propel collected your name and
              phone number from someone using the Services, however we do not
              use emergency contact information for anything other than
              emergencies.
            </p>
            <h2 id="100d1fb2-1b8a-433f-9b92-e5ee55ead270">Cookies</h2>
            <h3 id="c783bd57-0c96-4222-86d1-b780bc596e9d">What are cookies?</h3>
            <p id="2fad4215-0762-4be0-95da-7e3518d36bde">
              When you visit the Services, we may send one or more “cookies“ –
              small data files – to your computer to uniquely identify your
              browser and let us help you log in faster and enhance your
              navigation through the Site. A cookie may convey anonymous
              information about how you browse the Services to us so we can
              provide you with a more personalized experience, but does not
              collect personal information about you. A persistent cookie
              remains on your computer after you close your browser so that it
              can be used by your browser on subsequent visits to the Service.
              Persistent cookies can be removed by following your web browser’s
              directions. A session cookie is temporary and disappears after you
              close your browser.
            </p>
            <h3 id="666a409c-94b2-4380-87da-2fa34ed963cf">
              What cookies do we use?
            </h3>
            <p id="d6af8fe2-f9cb-4013-858a-c07625fc73aa">
              Our Site uses the following types of cookies for the purposes set
              out below:
            </p>
            <div id="5527237b-8990-40ff-ae28-a0713574b5b0" className="text-sm">
              <table className="mb-6 text-sm">
                <thead>
                  <tr>
                    <th>Category of cookies</th>
                    <th>Why we use these cookies</th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="549e0f14-de65-4231-a72d-27229afe760f">
                    <td>Strictly Necessary</td>
                    <td>
                      These cookies are essential for websites on our services
                      to perform their basic functions. These include those
                      required to allow registered users to authenticate and
                      perform account related functions, as well as to save the
                      contents of virtual “carts” on the Service.
                    </td>
                  </tr>
                  <tr id="0059a464-1190-4d98-80e6-959bd9559525">
                    <td>Functionality</td>
                    <td>
                      These cookies are used to store preferences set by users
                      such as account name, language, and location.
                    </td>
                  </tr>
                  <tr id="f4d43467-5d9b-48bd-b638-3cf2e8854c93">
                    <td>Security</td>
                    <td>
                      We use these cookies to help identify and prevent
                      potential security risks.
                    </td>
                  </tr>
                  <tr id="9a198bba-8ad3-44f4-b181-90920228dcbe">
                    <td>Analytics and Performance</td>
                    <td>
                      Performance cookies collect information on how users
                      interact with our websites, including what pages are
                      visited most, as well as other analytical data. We use
                      these details to improve how our websites function and to
                      understand how users interact with them.
                    </td>
                  </tr>
                  <tr id="276a2e70-2012-4077-a879-41e45ae2dc22">
                    <td>Customer Service</td>
                    <td>
                      These cookies are used for services such as chat windows,
                      Service usage, interaction, and monitoring, and help
                      content delivery
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="93fb533f-a222-4087-9d74-9e29b46b93c6" className="text-sm">
              <h4 className="inline-block mr-4">Propel&#x27;s Cookies</h4>
              <table className="mb-6 text-sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Function</th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="a76229fd-ecd0-46ba-ae21-76dba65b95eb">
                    <td>_prpl_device_id_</td>
                    <td className="cell-1xQr">Propel analytics.</td>
                  </tr>
                  <tr id="e14d422e-450b-406c-a977-279833e84aa9">
                    <td>_prpl_session_id</td>
                    <td className="cell-1xQr">Propel analytics.</td>
                  </tr>
                  <tr id="2c8ef2f2-25cc-4846-a1d0-3e869b0c2d15">
                    <td>token</td>
                    <td className="cell-1xQr">Propel authentication</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="f1944a5b-3ce8-4577-acfa-accf8758fefe" className="text-sm">
              <h4 className="inline-block mr-4">Third Party Applications</h4>
              <table className="mb-6 text-sm">
                <thead>
                  <tr>
                    <th>Third Party & Privacy Policy Link</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="81e81b39-286b-475e-8d89-5aa88b7dea86">
                    <td>
                      <a href="https://www.getbeamer.com/privacy-policy">
                        Beamer
                      </a>
                    </td>
                    <td>
                      We use Beamer to post feature updates and news
                      announcements to our instructors.
                    </td>
                  </tr>
                  <tr id="81e81b39-286b-475e-8d89-5aa88b7dea86">
                    <td>
                      <a href="https://haveibeenpwned.com/Privacy">
                        Have I Been Pwned
                      </a>
                    </td>
                    <td>
                      We use Have I Been Pwned to determine the vulnerability of
                      chosen passwords at the time of signup. Passwords are
                      never sent in plain text to this service, nor is your
                      password stored in plain text in our database. Admins do
                      not have the ability to see passwords.
                    </td>
                  </tr>
                  <tr id="9457d952-1847-42d6-95ed-57916c1c899f">
                    <td>
                      <a href="https://www.supporthero.io/privacy-policy/">
                        Support Hero
                      </a>
                    </td>
                    <td>
                      We use Support Hero to deliver help content to users.
                    </td>
                  </tr>
                  <tr id="16e0daf2-4e9a-4f7e-bf9a-0b595f0580a1">
                    <td>
                      <a href="https://posthog.com/privacy">PostHog</a>
                    </td>
                    <td>
                      We use PostHog to help measure how users interact with
                      Propel
                    </td>
                  </tr>
                  <tr id="433e83dd-959f-4f11-b463-3a4aee7ffb5b">
                    <td>
                      <a href="https://sentry.io/legal/privacy/2.1.0/">
                        Sentry
                      </a>
                    </td>
                    <td>
                      We use Sentry to track and fix Site and App issues
                      automatically.
                    </td>
                  </tr>
                  <tr id="198800a4-8f1f-47bf-9d0b-f097dd5d796e">
                    <td>
                      <a href="https://stripe.com/en-ca/privacy">Stripe</a>
                    </td>
                    <td>
                      We use Stripe for payment processing and fraud detection
                    </td>
                  </tr>
                  <tr id="198800a4-8f1f-47bf-9d0b-f097dd5d796e">
                    <td>
                      <a href="https://stripe.com/en-ca/privacy">Customer.io</a>
                    </td>
                    <td>
                      We use Customer.io for automated reminders, marketing
                      purposes, and product customization. We feel this creates
                      a better experience for our users overall.
                    </td>
                  </tr>
                  <tr id="198800a4-8f1f-47bf-9d0b-f097dd5d796e">
                    <td>
                      <a href="https://www.twilio.com/legal/privacy">
                        Twilio & Segment
                      </a>
                    </td>
                    <td>
                      Twilio and Segment are one company, but two products. We
                      use Twilio to send text message alerts, and Segment to
                      pass data from our site to the services listed here.
                    </td>
                  </tr>
                  <tr id="7b449ef1-8b58-4724-8b2a-04fabf868b61">
                    <td>
                      <a href="https://policies.google.com/privacy">
                        Google Analytics
                      </a>
                    </td>
                    <td>
                      We use Google Analytics to see where our users are
                      visiting from and how they&#x27;re using the Service
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 id="56079cb3-192a-4d94-b8c4-acdb5413e460">
              How can I disable cookies?
            </h3>
            <p id="8f1dbd35-56a7-4be1-abe9-a842f5161151">
              You can typically reset your web browser to refuse all cookies or
              to notify you when a cookie is being sent. In order to do this,
              follow the instructions provided by your browser (usually located
              within the “settings”, “help” “tools” or “edit” facility). Many
              browsers are set to accept cookies until you change your settings.
            </p>
            <p id="fd04498e-bdae-46df-9515-514ea2f234e3">
              If you do not accept our cookies, you may experience some
              inconvenience in your use of our Services and some features of the
              Service may not function properly. For example, we may not be able
              to recognize your computer or mobile device and you may need to
              log in every time you visit our Services.
            </p>
            <p id="5415a67a-3620-4dd4-a99f-ceb07efaa9ec">
              Further information about cookies, including how to see what
              cookies have been set on your computer or mobile device and how to
              manage and delete them, visit{' '}
              <a href="https://www.allaboutcookies.org/">
                www.allaboutcookies.org
              </a>{' '}
              and{' '}
              <a href="https://www.youronlinechoices.com/">
                www.youronlinechoices.com
              </a>
              .
            </p>
            <h2 id="e4a267cd-5c99-43b1-867e-b2380c2673a9">
              How and Why We Use Information
            </h2>
            <h3 id="d6bd3a5b-06a9-48ee-8481-dfe7234a82d7">
              Purposes for Using Information
            </h3>
            <p id="7653ff99-d986-4e78-b593-f0b6539c6546">
              We use information about you for the purposes listed below:
            </p>
            <ul id="b4b3ae14-9805-4191-8f69-c96ab9d9aaf9" className="list-disc">
              <li>
                <strong>To provide our Services.</strong> For example, to set up
                and maintain your account, host your website, backup and restore
                your website, provide customer service, process payments and
                orders, and verify user information.
              </li>
            </ul>
            <ul id="d9976231-957d-43ea-9e22-87c8d45e9588" className="list-disc">
              <li>
                <strong>
                  To ensure quality, maintain safety, and improve our Services.
                </strong>{' '}
                For example, by providing automatic upgrades and new versions of
                our Services. Or, for example, by monitoring and analyzing how
                users interact with our Services so we can create new features
                that we think our users will enjoy and that will help them
                create and manage websites more efficiently or make our Services
                easier to use.
              </li>
            </ul>
            <ul id="37b12485-90c9-4a8a-9b39-7075d6cc78d3" className="list-disc">
              <li>
                <strong>
                  To market our Services and measure, gauge, and improve the
                  effectiveness of our marketing.
                </strong>{' '}
                For example, by targeting our marketing messages to groups of
                our users (like those who have a particular plan with us or have
                been users for a certain length of time), advertising our
                Services, analyzing the results of our marketing campaigns (like
                how many people purchased a lesson after receiving a marketing
                message), and understanding and forecasting user retention.
              </li>
            </ul>
            <ul id="b2d7b7ac-2371-415f-9dfe-e67386ddd7b3" className="list-disc">
              <li>
                <strong>
                  To protect our Services, our users, and the public.
                </strong>{' '}
                For example, by detecting security incidents; detecting and
                protecting against malicious, deceptive, fraudulent, or illegal
                activity; fighting spam; complying with our legal obligations;
                and protecting the rights and property of Propel and others,
                which may result in us, for example, declining a transaction or
                terminating Services.
              </li>
            </ul>
            <ul id="39b670fb-d668-4a7b-8548-8abc66a24cf8" className="list-disc">
              <li>
                <strong>To fix problems with our Services.</strong> For example,
                by monitoring, debugging, repairing, and preventing issues.
              </li>
            </ul>
            <ul id="3d93c4f8-276d-45b2-bc1f-261b82c04f7e" className="list-disc">
              <li>
                <strong>To customize the user experience.</strong> For example,
                to personalize your experience by serving you relevant
                notifications and recommending specific actions to enhance your
                learning experience, or improve your teaching ability, depending
                on how you&#x27;re using Propel.
              </li>
            </ul>
            <ul id="83b1f92f-883a-47d8-9d9b-bf7e661d3d1b" className="list-disc">
              <li>
                <strong>To communicate with you.</strong> For example, by
                emailing you to ask for your feedback, share tips for getting
                the most out of our products, or keep you up to date on Propel;
                texting you to verify your payment or to notify you of new
                lesson bookings; or calling you for customer service issues. If
                you don’t want to hear from us, you can opt out of marketing
                communications at any time. (If you opt out, we’ll still send
                you important updates relating to your account.)
              </li>
            </ul>
            <h3 id="a993c5eb-2ce7-4dd0-9860-39fed1165ee9">
              Legal Bases for Collecting and Using Information
            </h3>
            <p id="5446bf86-d362-4976-a0ab-ffbfea8bb322">
              A note here for those in the European Union about our legal
              grounds for processing information about you under EU data
              protection laws, which is that our use of your information is
              based on the grounds that:
            </p>
            <p id="2a4f1fd3-e90d-41a6-b612-f7d0e88e52dc">
              (1) The use is necessary in order to fulfill our commitments to
              you under the applicable terms of service or other agreements with
              you or is necessary to administer your account — for example, in
              order to enable access to our website on your device or charge you
              for a paid plan; or
            </p>
            <p id="c1cb9edd-5410-4e61-96d0-5315cc5f459e">
              (2) The use is necessary for compliance with a legal obligation;
              or
            </p>
            <p id="e67664b7-f4d5-44fe-ba90-6c28e4998032">
              (3) The use is necessary in order to protect your vital interests
              or those of another person; or
            </p>
            <p id="1d539f83-111c-4117-bd22-77b3d062fe9d">
              (4) We have a legitimate interest in using your information — for
              example, to provide and update our Services; to improve our
              Services so that we can offer you an even better user experience;
              to safeguard our Services; to communicate with you; to measure,
              gauge, and improve the effectiveness of our advertising; and to
              understand our user retention and attrition; to monitor and
              prevent any problems with our Services; and to personalize your
              experience; or
            </p>
            <p id="676d7508-8aac-47d6-a772-6622fbbef0fc">
              (5) You have given us your consent — for example before we place
              certain cookies on your device and access and analyze them later
              on
            </p>
            <h2 id="2a464d40-717d-4946-9b8b-98c2490a89bc">
              Sharing Information
            </h2>
            <h3 id="cb0a2407-8a7e-4a23-8522-257dece84e78">
              How We Share Information
            </h3>
            <p id="72181c85-2db9-4682-96d8-923a4c6d54ca">
              We only share information that is absolutely necessary to complete
              lessons and provide our Service, with appropriate safeguards on
              your privacy.
            </p>
            <ul id="e52e3ab3-6e28-48cf-b4ec-5a38d2d2d6fa" className="list-disc">
              <li>
                <strong>Partner Facilities and Instructors:</strong> We may
                disclose information about you to our partner facilities and
                instructors who need the information to provide Services to you.
                For example, partner facilities and instructors are provided
                with first name and last name initials so they know who is
                allowed access on a particular day. Instructors have
                time-limited access to provided phone numbers in the event they
                need to get in touch (e.g., first time meetings, running late,
                or cancellations due to emergency). We require these partners to
                follow this Privacy Policy for any personal information that we
                share with them.
              </li>
            </ul>
            <ul id="5575bd8e-c179-44e0-ae0c-31a123bf6fb0" className="list-disc">
              <li>
                <strong>Third-party vendors:</strong> We may share information
                about you with third-party vendors who need the information in
                order to provide their services to us, or to provide their
                services to you or your site. This includes vendors that help us
                provide our Services to you (like payment providers that process
                your credit and debit card information, fraud prevention
                services that allow us to analyze fraudulent payment
                transactions, cloud storage services, postal and email delivery
                services that help us stay in touch with you, customer chat and
                email support services that help us communicate with you); those
                that assist us with our marketing efforts (e.g., by providing
                tools for identifying a specific marketing target group or
                improving our marketing campaigns, and by placing ads to market
                our services); those that help us understand and enhance our
                Services (like analytics providers); those that make tools to
                help us run our operations (like programs that help us with task
                management, scheduling, word processing, email and other
                communications, and collaboration among our teams); and other
                third-party tools that help us manage operations.
              </li>
            </ul>
            <ul id="459b34b1-f2d6-4a1b-bc7e-ba70f516c140" className="list-disc">
              <li>
                <strong>To protect rights, property, and others:</strong> We may
                disclose information about you when we believe in good faith
                that disclosure is reasonably necessary to protect the property
                or rights of Propel, partner facilities, third parties, or the
                public at large. For example, if we have a good faith belief
                that there is an imminent danger of death or serious physical
                injury, we may disclose information related to the emergency
                without delay.
              </li>
            </ul>
            <ul id="c402587a-2f2a-4b3d-9880-ea5410ee34b9" className="list-disc">
              <li>
                <strong>Aggregated or de-identified information:</strong> We may
                share information that has been aggregated or de-identified, so
                that it can no longer reasonably be used to identify you. For
                instance, we may publish aggregate statistics about the use of
                our Services, or share a hashed version of your email address to
                facilitate customized ad campaigns on other platforms.
              </li>
            </ul>
            <p id="ab08e6d1-439d-487f-8189-0706e787c057">
              We have a long-standing policy that we do not sell our users’
              data. We aren’t a data broker, we don’t sell your personal
              information to data brokers, and we don’t sell your information to
              other companies that want to spam you with marketing emails.
            </p>
            <h3 id="ab3503b4-2e06-4466-8eb4-234c6bb71fa9">
              Information Shared Publicly
            </h3>
            <p id="f577d2c0-21aa-4cd8-84a7-9de2ac228396">
              Information that you choose to make public will be disclosed
              publicly. That means information like your public profile,
              availability, reviews for instructors or facilities, and profile
              photos are all available to others. For example, if you post a
              review about an instructor or about a facility,{' '}
              <strong>
                be aware that any content you write in the review will be made
                public{' '}
              </strong>
              (e.g., swimmer names, dates, situations).
            </p>
            <h2 id="1f2932db-8366-4ed1-9127-3ca30bf85c70">
              How Long We Keep Information
            </h2>
            <p id="9dc6e9b6-9f9c-47c1-93a0-1e63de9a919d">
              We will retain your information for as long as your account is
              active or it is reasonably needed for the purposes set out in How
              and Why We Use Information unless you request that we remove your
              personal data as described below in Your Rights. We will only
              retain your personal data for so long as we reasonably need to use
              it for these purposes unless a longer retention period is required
              by law (for example for regulatory purposes). This may include
              keeping your personal data after you have deactivated your account
              for the period of time needed for us to pursue legitimate business
              interests, conduct audits, comply with (and demonstrate compliance
              with) legal obligations, resolve disputes and enforce our
              agreements.
            </p>
            <h2 id="8d5eae9f-c504-443d-8ece-de4be0f338d9">
              Where We Store Information
            </h2>
            <p id="8bf2442f-bf68-478a-b66c-78bf19a59f69">
              When you use our services, your data may be sent to the United
              States and possibly other countries.
            </p>
            <p id="4b448989-77d0-4c65-871d-b51642f50720">
              We and/or Third Parties we share your information with may
              transfer, store, use and process your information, including any
              Personal Information, to countries including the United States.
              Please note that laws vary from jurisdiction to jurisdiction, and
              so the privacy laws applicable to the places where your
              information is transferred to or stored, used or processed in, may
              be different from the privacy laws applicable to the place where
              you are resident.
            </p>
            <h2 id="285cab66-1d87-4297-844f-6897f9b101fb">Security</h2>
            <p id="c1e8c656-a597-40b0-9114-1c2b7b312a3c">
              While no online service is 100% secure, we work very hard to
              protect information about you against unauthorized access, use,
              alteration, or destruction, and take reasonable measures to do so.
              We cannot guarantee the security of our databases, nor can we
              guarantee that the information you supply will not be intercepted
              while being transmitted to and from us over the Internet.
            </p>
            <h2 id="59343216-e4ad-470a-bc39-6e1ef646006f">Your Rights</h2>
            <p id="c0fea27c-9477-4a35-bae2-9a849249cae2">
              If you are located in certain parts of the world, including
              California and countries that fall under the scope of the European
              General Data Protection Regulation (aka the “GDPR”), you may have
              certain rights regarding your personal information, like the right
              to request access to or deletion of your data.
            </p>
            <h3 id="558daeae-5a89-4651-badd-9e940d688f93">
              <em>European General Data Protection Regulation (GDPR)</em>
            </h3>
            <p id="86877882-5c37-4fe7-8158-24f26835fd43">
              If you are located in a country that falls under the scope of the
              GDPR, data protection laws give you certain rights with respect to
              your personal data, subject to any exemptions provided by the law,
              including the rights to:
            </p>
            <ul id="9ce5307d-f51c-46a9-8ff8-0a12b3e8c055" className="list-disc">
              <li>Request access to your personal data;</li>
            </ul>
            <ul id="01349f2a-f3b3-4cd5-9644-0127c2dcedc2" className="list-disc">
              <li>Request correction or deletion of your personal data;</li>
            </ul>
            <ul id="f3cfe517-695c-4add-a7f8-447f1878b8c5" className="list-disc">
              <li>Object to our use and processing of your personal data;</li>
            </ul>
            <ul id="43efc003-e2d5-4304-b702-73f5f60670ff" className="list-disc">
              <li>
                Request that we limit our use and processing of your personal
                data; and
              </li>
            </ul>
            <ul id="5f097d0c-15ed-4f3c-94a1-63c950bdc798" className="list-disc">
              <li>Request portability of your personal data.</li>
            </ul>
            <p id="74a7ccd1-77d5-4fa6-9749-8b528ed4eeb1">
              You also have the right to make a complaint to a government
              supervisory authority.
            </p>
            <h3 id="ca77cd0a-1f6b-44ec-b935-77728ad1c4cd">
              <em>California Consumer Privacy Act (CCPA)</em>
            </h3>
            <p id="f31a7993-e3cb-4acd-b8a0-ad1ed0bd9e07">
              The California Consumer Privacy Act (“CCPA”) requires us to
              provide California residents with some additional information
              about the categories of personal information we collect and share,
              where we get that personal information, and how and why we use it.
            </p>
            <p id="f0b93367-4f6c-4440-bd01-409887f94a6a">
              The CCPA also requires us to provide a list of the “categories” of
              personal information we collect, as that term is defined in the
              law, so, here it is. In the last 12 months, we collected the
              following categories of personal information from California
              residents, depending on the Services used:
            </p>
            <ul id="f068932d-e4da-4a8b-b08f-1b6cbb600083" className="list-disc">
              <li>
                Identifiers (like your name, contact information, and device and
                online identifiers);
              </li>
            </ul>
            <ul id="75de7339-4975-4374-a913-8eb81896a984" className="list-disc">
              <li>
                Commercial information (your billing information and purchase
                history, for example);
              </li>
            </ul>
            <ul id="fe1762e5-f2ed-4233-b035-cf75a3e91dde" className="list-disc">
              <li>
                Characteristics protected by law (for example, you might provide
                your gender as part of a research survey for us);
              </li>
            </ul>
            <ul id="fb598f61-d4c0-4c02-a601-9eaf2cbf3771" className="list-disc">
              <li>
                Internet or other electronic network activity information (such
                as your usage of our Services, like the actions you take as an
                administrator of a WordPress.com site);
              </li>
            </ul>
            <ul id="ab73901d-07b9-4f36-9e7f-9121ca10297a" className="list-disc">
              <li>
                Geolocation data (such as your location based on your IP
                address);
              </li>
            </ul>
            <ul id="b44b644c-7f47-49cb-abb7-64f0ac6c5b2e" className="list-disc">
              <li>
                Audio, electronic, visual or similar information (such as your
                profile picture, if you uploaded one);
              </li>
            </ul>
            <ul id="66cfe181-d0f6-4c0a-8f5f-83b0736d2e58" className="list-disc">
              <li>
                Professional or employment-related information (for example,
                your company and team information if you are a Happy Tools user,
                or information you provide in a job application); and
              </li>
            </ul>
            <ul id="28dd579b-7523-4e58-b00b-392d650cdcea" className="list-disc">
              <li>
                Inferences we make (such as likelihood of retention or
                attrition).
              </li>
            </ul>
            <p id="b9feacfc-252f-406d-99d1-3c638d18956f">
              You can find more information about what we collect in
              the Information We Collect section above.
            </p>
            <p id="829278cf-52d0-4de2-ba29-a893b4c79e37">
              We collect personal information for the business and commercial
              purposes described in the How and Why We Use Information section.
              And we share this information with the categories of third parties
              described in the Sharing Information section.
            </p>
            <p id="37e8aa73-05e3-4903-88c1-f94e9ebe3ab6">
              If you are a California resident, you have additional rights under
              the CCPA, subject to any exemptions provided by the law, including
              the right to:
            </p>
            <ul id="3cf334da-9396-4fc0-ad34-b6fd268cc810" className="list-disc">
              <li>
                Request to know the categories of personal information we
                collect, the categories of business or commercial purpose for
                collecting and using it, the categories of sources from which
                the information came, the categories of third parties we share
                it with, and the specific pieces of information we collect about
                you;
              </li>
            </ul>
            <ul id="0a130060-75b9-4735-87de-1c939aff6cab" className="list-disc">
              <li>
                Request deletion of personal information we collect or maintain;
              </li>
            </ul>
            <ul id="9ffb1a46-2d42-4b1f-ab83-57a3dca47e86" className="list-disc">
              <li>Opt out of any sale of personal information; and</li>
            </ul>
            <ul id="c4845c29-cbd1-4963-9afe-ec7dcd97e93f" className="list-disc">
              <li>
                Not receive discriminatory treatment for exercising your rights
                under the CCPA.
              </li>
            </ul>
            <h3 id="866fadb7-708e-459f-b554-11dced9b01fb">
              <em>Contacting Us About These Rights</em>
            </h3>
            <p id="c924fc2b-6f84-4192-802a-f810159c8dd5">
              You can usually access, correct, or delete your personal data
              using your account settings and tools that we offer, but if you
              aren’t able to or you’d like to contact us about one of the other
              rights, scroll down to &quot;How to Reach Us&quot; to, well, find
              out how to reach us.
            </p>
            <p id="4289c877-2299-45e9-a7ba-c9339026d670">
              When you contact us about one of your rights under this section,
              we’ll need to verify that you are the right person before we
              disclose or delete anything. For example, if you are a user, we
              will need you to contact us from the email address associated with
              your account. You can also designate an authorized agent to make a
              request on your behalf by giving us written authorization. We may
              still require you to verify your identity with us.
            </p>
            <p id="8501faba-3c25-4af1-8e19-33168c4a2d7f">
              Typically, you will not have to pay a fee to access your personal
              data (or to exercise any of the other rights). However, except in
              relation to withdrawing your consent, we may charge a reasonable
              fee if your request is clearly unfounded, repetitive or excessive,
              or, we may refuse to comply with your request in these
              circumstances.
            </p>
            <p id="aa06cbdf-3250-4df5-ba24-441e3b0ba478">
              We try to respond to all legitimate requests within one month.
              Occasionally it may take us longer than a month if your request is
              particularly complex or you have made a number of requests. In
              this case, we will notify you and keep you updated.
            </p>
            <h2 id="2c2a5750-10f5-4f53-880f-cea09befc8d2">Controllers</h2>
            <p id="0002b52d-983e-42f0-881f-f53acd897e93">
              Propel&#x27;s Services are provided in Canada only. Propel is the
              company responsible for processing your personal data and thus is
              the controller for processing your personal information. Our
              address is:
            </p>
            <blockquote
              id="614befac-d5d7-4dcc-8dcf-22fd9a3aef70"
              className="ml-2 font-medium"
            >
              Propel HQ Inc.
              <br />
              Care of Propel c/o DMZ
              <br />
              10 Dundas Street East
              <br />
              Suite 600
              <br />
              Toronto ON M5B2G9
            </blockquote>
            <h2 id="10b876f1-c471-4248-904b-137a2854db97">How to Reach Us</h2>
            <p id="4025503f-b8ad-49f2-b2b5-231ccf01d79e">
              If you have any questions about our practices or this Privacy
              &amp; Cookies Policy, please contact us at{' '}
              <a href="mailto:support@propelhq.com">support@propelhq.com</a>{' '}
              with &quot;PRIVACY POLICY&quot; in the subject line.
            </p>
            <h2 id="895d5171-b2a4-4ecb-b5fc-156ccbc272de">
              Our Policy on Children&#x27;s Privacy
            </h2>
            <p id="16e0b9fa-e9db-4e7c-9efd-8e724aa20268">
              Protecting the privacy of young children is especially important.
              Though lessons are suitable for most ages, the Services are not
              intended for children below 16. If you are under the age of 16,
              please do not submit any personal information through the Site.
            </p>
            <p id="cac099a9-d3f9-42ea-aca1-54a8ff8aac3f">
              We understand that participants in lessons may be under the age of
              16. To assist your instructor with lesson planning, you may
              optionally provide a participant&#x27;s first name{' '}
              <strong>
                only after receiving the permission of the participant&#x27;s
                parent or legal guardian.{' '}
              </strong>
              The instructor may also collect information about progress or
              lesson plans. This data is only known to authorized Propel admins
              and the Instructor for the explicit purpose of enhancing a lesson
              and providing feedback, and it not used for promotional or
              marketing purposes.
            </p>
            <p id="a0793d81-f40e-4e27-926d-6b4598aba0c6">
              If you do not wish a participants name to be stored, or permission
              was not granted, simply enter &quot;n/a&quot;,
              &quot;undisclosed&quot;, or similar, or leave the field blank
              where possible.
            </p>
            <h2 id="d9c19fe2-26fd-499d-bbda-9e000d966a67">
              Links to Other Websites
            </h2>
            <p id="3b7b2703-c56c-4fe9-b17e-2684a6a9a2ed">
              This Privacy &amp; Cookies Policy applies only to the Services.
              The Services may contain links to other websites not operated or
              controlled by Propel and as such we are not responsible for the
              content, accuracy, or opinions expressed in such websites, and
              such websites are not investigated, monitored or checked for
              accuracy or completeness by us. Your browsing and interaction on
              any other website, including those that have a link on our Site,
              is subject to that website’s own rules and policies. Such third
              parties may use their own cookies or other methods to collect
              information about you.
            </p>
            <h2 id="20a1800a-7622-4ff2-9480-ab1fccc8e398">
              Changes to Our Privacy &amp; Cookies Policy
            </h2>
            <p id="52b01f5c-6eac-4149-840c-68f647afc1d9">
              We may change how we collect and then use Personal Information at
              any time and without prior notice, at our sole discretion. We may
              change this Privacy Policy at any time. If we make material
              changes to the Privacy Policy, we will notify you either by
              posting the changed Privacy Policy on the Platform or by sending
              an email to you. We will also update the “Last Updated” date at
              the top of this Privacy Policy. If we let you know of changes
              through an email communication, then the date on which we send the
              email will be deemed to be the date of your receipt of that email.
              Please refer back to this Privacy Policy on a regular basis.
            </p>
          </div>
        </article>
      </div>
    </Page>
  );
};

export default PrivacyPolicy;
