import InstructorBaseTimeSlot from 'features/schedule/as-instructor/base-time-slot';
import { TimeSlotProps } from 'features/schedule/date-time-list/time-slot';
import React from 'react';
import { useSelector } from 'react-redux';
import { getScheduleAppointmentProduct } from 'state/selectors';

const BookedTimeSlot = ({
  timeSlot,
  tense,
  aptProdId,
  description,
}: TimeSlotProps) => {
  const timezone = useSelector(getScheduleAppointmentProduct).timezone;
  let classes = 'font-semibold bg-gray-100 text-gray-700';
  return (
    <InstructorBaseTimeSlot
      datetime={timeSlot.datetime}
      timezone={timezone}
      description={description}
      classes={classes}
    />
  );
};

export default BookedTimeSlot;
