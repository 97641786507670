import PasswordIcon from '@mui/icons-material/LockOutlined';
import api from 'api';
import { Credentials } from 'api/Serializers/Authorization';
import { isClientError } from 'api/status';
import Button from 'components/button';
import { FETCH_STATE, MAX_EMAIL_LENGTH, MAX_PASSWORD_LENGTH } from 'config';
import TaskCard from 'containers/task-card';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useForm from 'hooks/useForm';
import {
  GenericServerError,
  InvalidLoginCredentials,
  SnackbarData,
} from 'lang/en/Snackbars';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAccountDetail } from 'state/selectors';
import { loginSuccess, logUserOut } from 'state/slice/authentication';
import { validateEmail } from 'utils/string';

const CODE_INVALID = 'invalid_attrs';

const ProxyLoginForm = ({}: {}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail);

  const getInvalidFields = () => {
    let errors = [];
    if (validateEmail(inputs.email) !== '') {
      errors.push('email');
    }
    if (inputs.password === '') {
      errors.push('password');
    }
    return errors;
  };

  const onSubmit = async (credentials: Credentials) => {
    if (fetchState !== FETCH_STATE.IDLE) {
      return null;
    }
    setFetchState(FETCH_STATE.POST);
    const invalidFields = getInvalidFields();
    if (invalidFields.length > 0) {
      return;
    }
    try {
      const response = await api.auth.proxyToken({
        proxyEmail: account.email,
        ...credentials,
      });
      dispatch(logUserOut);
      dispatch(loginSuccess(response.data.token, response.data.refresh, true));
      history.push('/account');
    } catch (error: any) {
      let snackbar: SnackbarData;
      if (isClientError(error.response?.status)) {
        const errors = error.response.data?.nonFieldErrors;
        if (errors?.length > 0) {
          const code = error.response.data.nonFieldErrors[0];
          if (code === CODE_INVALID) {
            snackbar = InvalidLoginCredentials;
          } else {
            snackbar = GenericServerError;
          }
        } else {
          snackbar = GenericServerError;
        }
      } else {
        snackbar = GenericServerError;
      }
      enqueueSnackbar(snackbar);
      setFetchState(FETCH_STATE.IDLE);
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    { email: '', password: '' },
    onSubmit
  );

  return (
    <TaskCard>
      <div>
        <h2 className="text-xl font-bold text-left text-gray-800">
          Sign in to another account
        </h2>
      </div>
      <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <label htmlFor="email">Account Email</label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="username"
              required
              onChange={handleInputChange}
              value={inputs.email}
              maxLength={MAX_EMAIL_LENGTH}
            />
          </div>
          <div className="flex flex-col-reverse col-span-6">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              onChange={handleInputChange}
              maxLength={MAX_PASSWORD_LENGTH}
              value={inputs.password}
            />
            <div className="flex items-baseline justify-between">
              <label htmlFor="password">Admin Password</label>
            </div>
          </div>
        </div>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            isLoading={fetchState === FETCH_STATE.POST}
            fullWidth={true}
            icon={<PasswordIcon />}
          >
            {fetchState === FETCH_STATE.POST ? 'Loading...' : 'Continue'}
          </Button>
        </div>
      </form>
    </TaskCard>
  );
};

export default ProxyLoginForm;
