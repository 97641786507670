import jwt_decode from 'jwt-decode';
import { CookieStore } from 'state/storage';
import { CookieKey } from 'state/storage/cookies';

interface TokenData {
  id: number;
  username: string;
  exp: number;
  jti: string;
  user_id: number;
}

export const tokenDecode = (jwt: string): TokenData => {
  if (typeof jwt === 'undefined') {
    return undefined;
  }
  try {
    const data = jwt_decode(jwt);
    return data;
  } catch (error: any) {
    return undefined;
  }
};

export const inProxySession = () =>
  CookieStore.get(CookieKey.IsProxySession) !== undefined;
