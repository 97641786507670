import LeftIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import RightIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import React from 'react';
import style from './style.module.scss';
/// https://react-slick.neostack.com/docs/get-started ///
const ReactSlider = require('react-slick').default;

interface ReactSlickResponsiveSetting {
  breakpoint: number;
  settings: Partial<ReactSlickSettings>;
}

export interface ReactSlickSettings {
  accessibility: boolean;
  adaptiveHeight: boolean;
  afterChange(): void;
  appendDots(): void;
  autoplaySpeed: number;
  autoplay: boolean;
  beforeChange(): void;
  centerMode: boolean;
  centerPadding: string;
  className: string;
  customPaging(): void;
  dotsClass: string;
  dots: boolean;
  draggable: boolean;
  easing: string;
  fade: boolean;
  focusOnSelect: boolean;
  infinite: boolean;
  initialSlide: number;
  nextArrow: JSX.Element;
  prevArrow: JSX.Element;
  onEdge(): void;
  onInit(): void;
  onLazyLoad(): void;
  onReInit(): void;
  onSwipe(): void;
  pauseOnDotsHover: boolean;
  pauseOnFocus: boolean;
  pauseOnHover: boolean;
  responsive: ReactSlickResponsiveSetting[];
  rows: number;
  rtl: boolean;
  slide: string;
  slidesPerRow: number;
  slidesToScroll: number;
  slidesToShow: number;
  speed: number;
  swipeToSlide: boolean;
  swipe: boolean;
  touchMove: boolean;
  touchThreshold: number;
  useCSS: boolean;
  useTransform: boolean;
  variableWidth: boolean;
  vertical: boolean;
}

interface ArrowProps {
  onClick?(): void;
  color: 'white' | 'gray';
  size?: 'xs' | 'sm' | 'base';
  arrowShow: boolean | 'sm' | 'md';
}

const getTransform = (size: 'xs' | 'sm' | 'base', position: 'right' | 'left') =>
  size === 'xs'
    ? position === 'right'
      ? 'translate(1px, 0px)'
      : 'translate(-1px, 0px)'
    : size === 'sm'
    ? position === 'right'
      ? 'translate(2px, 0px)'
      : 'translate(-2px, 0px)'
    : position === 'right'
    ? 'translate(3px, 1px)'
    : 'translate(-3px, 1px)';
const getPosition = (size: 'xs' | 'sm' | 'base', position: 'right' | 'left') =>
  size === 'xs'
    ? position === 'right'
      ? 'right-1'
      : 'left-1'
    : size === 'sm'
    ? position === 'right'
      ? 'right-2 sm:right-4'
      : 'left-2 sm:left-4'
    : position === 'right'
    ? 'right-3 sm:right-6'
    : 'left-3 sm:left-6';
const getColors = (color: 'white' | 'gray') =>
  color === 'white'
    ? 'border-white hover:bg-white text-white'
    : 'border-gray-600 text-gray-600 hover:bg-gray-300';
const getSize = (size: 'xs' | 'sm' | 'base') =>
  size === 'xs'
    ? `text-icon-sm sm:text-icon-sm`
    : size === 'sm'
    ? `text-icon-md sm:text-icon-lg`
    : `text-icon-lg sm:text-icon-xl`;
const baseStyle = (arrowShow: boolean | 'sm' | 'md') =>
  !arrowShow
    ? 'hidden'
    : `hidden ${
        arrowShow === 'sm'
          ? 'sm:block'
          : arrowShow === 'md'
          ? 'md:block'
          : '!block'
      } absolute z-10 transform -translate-y-1/2 border-2 rounded-full cursor-pointer leading-0 hover:bg-opacity-60 bg-none top-1/2 block transition-colors`;
function NextArrow(props: ArrowProps) {
  const { onClick, arrowShow } = props;
  const click = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!!onClick) {
      onClick();
    }
  };
  return (
    <button
      className={`${baseStyle(arrowShow)} ${getSize(props.size)} ${getPosition(
        props.size,
        'right'
      )} ${getColors(props.color)}`}
      onClick={click}
    >
      <RightIcon
        fontSize="inherit"
        color="inherit"
        style={{ transform: getTransform(props.size, 'right') }}
      />
    </button>
  );
}

function PrevArrow(props: ArrowProps) {
  const { onClick, arrowShow } = props;
  const click = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!!onClick) {
      onClick();
    }
  };
  return (
    <button
      className={`${baseStyle(arrowShow)} ${getSize(props.size)} ${getPosition(
        props.size,
        'left'
      )} ${getColors(props.color)}`}
      onClick={click}
    >
      <LeftIcon
        fontSize="inherit"
        color="inherit"
        style={{ transform: getTransform(props.size, 'left') }}
      />
    </button>
  );
}

interface Props {
  children?: any;
  settings?: Partial<ReactSlickSettings>;
  arrowShow?: boolean | 'sm' | 'md';
  arrowSize?: 'xs' | 'sm' | 'base';
  arrowColor?: 'white' | 'gray';
}

const Slider: React.FunctionComponent<Props> = (props) => {
  const { arrowSize, arrowColor, arrowShow, settings } = props;
  const sliderConfig: Partial<ReactSlickSettings> = {
    autoplay: false,
    draggable: true,
    infinite: true,
    nextArrow: (
      <NextArrow size={arrowSize} color={arrowColor} arrowShow={arrowShow} />
    ),
    prevArrow: (
      <PrevArrow size={arrowSize} color={arrowColor} arrowShow={arrowShow} />
    ),
    ...settings,
  };
  const arrowStyle =
    arrowShow === false
      ? style.hideArrows
      : arrowShow === true
      ? style.showArrows
      : style[`showArrows-${arrowShow}`];
  return (
    <div className={`${style.container} ${arrowStyle}`}>
      <ReactSlider {...sliderConfig}>{props.children}</ReactSlider>
    </div>
  );
};

Slider.defaultProps = {
  arrowShow: true,
  arrowSize: 'base',
  arrowColor: 'white',
};

export default Slider;
