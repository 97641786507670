import {
  AccountUpdateSerializer,
  InstructorOnboardingSerializer,
} from 'api/Serializers/Accounts/Instructor';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getUsername } from 'state/selectors';
import { modifyAccount } from 'state/slice/account';
import { modifyOnboarding } from 'state/slice/instructor';

const useAccountPatch = () => {
  const dispatch = useAppDispatch();
  const username = useSelector(getUsername);

  const handleAccountUpdate = async (
    data: Partial<AccountUpdateSerializer>
  ) => {
    return await dispatch(modifyAccount(username, data));
  };

  const handleOnboardingUpdate = async (
    data: Partial<InstructorOnboardingSerializer>
  ) => {
    return await dispatch(modifyOnboarding(username, data));
  };

  return { handleAccountUpdate, handleOnboardingUpdate };
};

export default useAccountPatch;
