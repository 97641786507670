import Button from 'components/button';
import { DATE_FMT } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ArrowForwardIcon, BackIcon } from 'icons';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { getScheduleRenderDate } from 'state/selectors';
import { setScheduleRenderDate } from 'state/slice/schedule';

interface Props {
  minDate: string;
  maxDate: string;
}

const CalendarControls = ({ minDate, maxDate }: Props) => {
  const dispatch = useAppDispatch();
  const renderDate = useSelector(getScheduleRenderDate);

  const handleMonthForward = (e) => {
    dispatch(
      setScheduleRenderDate(
        moment(renderDate)
          .add(1, 'month')
          .startOf('month')
          .format(DATE_FMT.DATE_KEY)
      )
    );
  };
  const handleMonthBack = (e) => {
    dispatch(
      setScheduleRenderDate(
        moment(renderDate)
          .subtract(1, 'month')
          .startOf('month')
          .format(DATE_FMT.DATE_KEY)
      )
    );
  };
  const handleMonthCurrent = (e) => {
    dispatch(
      setScheduleRenderDate(
        moment().startOf('month').startOf('month').format(DATE_FMT.DATE_KEY)
      )
    );
  };
  return (
    <div className="relative flex justify-between capitalize cursor-default">
      <Button onClick={handleMonthCurrent} className="whitespace-nowrap">
        {moment(renderDate, DATE_FMT.DATE_KEY).format(DATE_FMT.MONTH_YEAR)}
      </Button>
      <div className="flex space-x-2">
        <button
          className="btn-icon gray"
          onClick={handleMonthBack}
          disabled={moment(renderDate).isSameOrBefore(minDate, 'month')}
        >
          <BackIcon width={20} />
        </button>
        <button
          className="btn-icon gray"
          onClick={handleMonthForward}
          disabled={moment(renderDate).isSameOrAfter(maxDate, 'month')}
        >
          <ArrowForwardIcon width={20} />
        </button>
      </div>
    </div>
  );
};

export default CalendarControls;
