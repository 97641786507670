import React from 'react';
import style from './style.module.scss';

const Background = ({
  imgId,
  background,
  backgroundPosition,
  className,
  fadeOpacity = 40,
  height = 'auto',
}: {
  imgId: string;
  background: string;
  backgroundPosition: string;
  className?: string;
  fadeOpacity?: 0 | 10 | 20 | 30 | 40 | 50 | 60;
  height: 'screen' | 'full' | 'auto';
}) => (
  <div
    className={`${style.background} ${style[height]}Height${cls(className)}`}
  >
    <div
      className={`absolute z-10 top-0 bottom-0 left-0 right-0 bg-black bg-opacity-${fadeOpacity}`}
    />
    <div className={style.item}>
      <img
        id={imgId}
        src={background}
        className={style.image}
        style={{ objectPosition: backgroundPosition }}
      />
    </div>
  </div>
);

export default Background;
