import CookieHandler, { CookieKey } from './cookies';
import LocalStorageHandler from './local';

export interface StorageHandler<K, O> {
  isEnabled: () => boolean;
  set: (key: K, value: any, options?: O) => void;
  get: (key: K) => string;
  getJSON: (key: K) => any;
  remove: (key: K) => void;
}

export function clearStorage() {
  LocalStore.remove('assistanceClosed');
  LocalStore.remove('PropelRegionData');
  CookieStore.remove(CookieKey.AuthToken);
  CookieStore.remove(CookieKey.RefreshToken);
  CookieStore.remove(CookieKey.MessagingToken);
  CookieStore.remove(CookieKey.SessionId);
  CookieStore.remove(CookieKey.IsProxySession);
}

export const CookieStore = new CookieHandler();
export const LocalStore = new LocalStorageHandler();
