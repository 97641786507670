import { ClientAccount } from 'api/Serializers/Clients';
import { ProposalStatus } from 'api/Serializers/Proposals';
import { MenuItem } from 'components/account/menu';
import {
  FavoriteIcon,
  MessagesIcon,
  ProposalIcon,
  ScheduleIcon,
  SearchIcon,
  SettingsIcon,
  UserGroupIcon,
} from 'icons';
import { RootState } from 'state';
import { getProposals } from 'state/selectors';
import { SHARED_ROUTES } from 'utils/routing';
import { isPathnameInRouteRoot, unreadConversations } from '../utils';
import Assistance from './assistance';
import Dashboard from './dashboard';
import Favourites from './favourites';
import Instructors from './instructors';
import MessagesPage from './messages';
import Proposals from './proposals';
import Schedule from './schedule';
import SearchPage from './search';
import Settings from './settings';

export const CLIENT_ROUTES = {
  ...SHARED_ROUTES,
  INSTRUCTORS: {
    PATH: '/account/instructors/:username?/:mode?',
    ROOT: '/account/instructors',
    nav: (username: string, mode?: string) =>
      `/account/instructors${
        username ? `/${username}${mode ? `/${mode}` : ''}` : ''
      }`,
  },
  FAVOURITES: {
    PATH: '/account/favourites',
    ROOT: '/account/favourites',
  },
  SEARCH: {
    PATH: '/account/search',
    ROOT: '/account/search',
  },
};

export const ClientMenuItems: MenuItem[] = [
  {
    id: 'assistance',
    route: CLIENT_ROUTES.ASSISTANCE,
    Component: Assistance,
  },
  {
    id: 'dashboard',
    route: CLIENT_ROUTES.DASHBOARD,
    Component: Dashboard,
    isActive: (pathname) => pathname === SHARED_ROUTES.DASHBOARD.ROOT,
  },
  {
    id: 'search',
    route: CLIENT_ROUTES.SEARCH,
    text: 'Search',
    Icon: SearchIcon,
    Component: SearchPage,
    isActive: isPathnameInRouteRoot,
  },
  {
    id: 'schedule',
    route: CLIENT_ROUTES.SCHEDULE,
    text: 'Bookings',
    Icon: ScheduleIcon,
    Component: Schedule,
    isActive: isPathnameInRouteRoot,
  },
  {
    id: 'messages',
    route: CLIENT_ROUTES.MESSAGES,
    text: 'Messages',
    Icon: MessagesIcon,
    Component: MessagesPage,
    isActive: isPathnameInRouteRoot,
    isHidden: (account: ClientAccount) => account.messagingDisabled,
    listSelector: unreadConversations,
    isFixedHeightRoute: true,
  },
  {
    id: 'proposals',
    route: CLIENT_ROUTES.PROPOSALS,
    text: 'Proposals',
    Icon: ProposalIcon,
    Component: Proposals,
    isActive: isPathnameInRouteRoot,
    listSelector: (state: RootState) =>
      getProposals(state)?.filter(
        (p) => p.status === ProposalStatus.AwaitingClient
      ) || [],
  },
  {
    id: 'instructors',
    route: CLIENT_ROUTES.INSTRUCTORS,
    text: 'Instructors',
    Icon: UserGroupIcon,
    Component: Instructors,
    isActive: isPathnameInRouteRoot,
  },
  {
    id: 'favourites',
    route: CLIENT_ROUTES.FAVOURITES,
    text: 'Favourites',
    Icon: FavoriteIcon,
    Component: Favourites,
    isActive: isPathnameInRouteRoot,
  },
  {
    id: 'settings',
    route: CLIENT_ROUTES.SETTINGS,
    text: 'Settings',
    Icon: SettingsIcon,
    Component: Settings,
    isActive: isPathnameInRouteRoot,
  },
];
