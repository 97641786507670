import { MainContainer, Sidebar } from '@chatscope/chat-ui-kit-react';
import { useMediaQuery, useTheme } from '@mui/material';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import { UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAccountDetail,
  getActiveConversationSid,
  getMessagingConnectionState,
  getMessagingInit,
  getUser,
} from 'state/selectors';
import { fetchClients } from 'state/slice/clients';
import { fetchScheduleInstructors } from 'state/slice/instructor';
import { setActiveConversationSid } from 'state/slice/messaging';
import './chatscope-overrides.scss';
import ConversationsContainer from './conversations-container';
import MessagesContainer from './messages-container';

const Messages = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isUsersFetched, setUsersFetched] = useState(false);
  const user = useSelector(getUser);
  const account = useSelector(getAccountDetail);
  const activeConvoSid = useSelector(getActiveConversationSid);
  const connectionState = useSelector(getMessagingConnectionState);
  const messagingInit = useSelector(getMessagingInit);
  const theme = useTheme();
  const isBreakSm = useMediaQuery(theme.breakpoints.up('sm'));
  const hasNotLoaded =
    messagingInit === undefined || connectionState === undefined;
  const hasFailedToLoad =
    messagingInit === false ||
    !['connected', 'connecting'].includes(connectionState);

  const fetchData = async () => {
    if (user.type === UserType.Client) {
      await dispatch(fetchScheduleInstructors());
    } else if (user.type === UserType.Instructor) {
      await dispatch(fetchClients());
    }
    setUsersFetched(true);
  };

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(setActiveConversationSid(null));
    };
  }, []);

  useEffect(() => {
    const { email } = account;
    if (hasNotLoaded) {
      return;
    } else if (hasFailedToLoad) {
      rudderanalytics.track('Messages Init Failure', {
        email,
        connectionState,
        init:
          typeof messagingInit !== 'undefined' ? messagingInit : 'undefined',
      });
    } else if (connectionState === 'connected') {
      rudderanalytics.track('Messages Init Success', {
        email,
        connectionState: connectionState,
      });
    }
  }, [messagingInit, connectionState]);

  if (account?.messagingDisabled) {
    return null;
  }

  if (hasNotLoaded || !isUsersFetched) {
    return <Loading position="absolute" message="Loading messages..." />;
  }

  return (
    <>
      <div className="w-full">
        {messagingInit === true &&
        ['connected', 'connecting'].includes(connectionState) ? (
          <MainContainer className="border-none shadow-none" responsive>
            <Sidebar
              className={`!border-r-2 !border-gray-200${
                !isBreakSm && !activeConvoSid
                  ? ' !flex !basis-auto !w-full !max-w-full'
                  : ''
              }`}
              position="left"
              scrollable={false}
            >
              <ConversationsContainer />
            </Sidebar>
            <MessagesContainer
              className={!isBreakSm && !activeConvoSid ? 'hidden' : ''}
            />
          </MainContainer>
        ) : (
          <div />
        )}
      </div>
      {hasFailedToLoad && (
        <div
          className={`absolute m-4 p-8 max-w-sm rounded-lg drop-shadow-lg bg-white top-1/4 left 1/2`}
        >
          <div>
            <p>
              Error connecting to the messaging service. Please refresh to try
              again. If the problem persists, try logging out and in again.
            </p>
            <Controls className="!mb-0">
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
            </Controls>
          </div>
        </div>
      )}
    </>
  );
};

export default Messages;
