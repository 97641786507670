import api from 'api';
import { Availability } from 'api/Serializers/Availability';
import {
  DaySerializer,
  FacilityLimiterSerializer,
  FacilitySchedulableSerializer,
  FacilityScheduleSerializer,
} from 'api/Serializers/Facilities';
import Calendar from 'components/calendar';
import CalendarControls from 'components/calendar-controls';
import Callout from 'components/callout';
import Loading from 'components/loading';
import Select, { SelectOption } from 'components/select';
import { DATE_FMT, DAY, DAYS_OF_WEEK_JS } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getScheduleRenderDate } from 'state/selectors';
import { getFacilityScheduleForDate } from 'utils/facility';
const TIME_FMT = DATE_FMT.TIME_FIELD;

interface OverallBlock {
  available: number;
  booked: number;
  limits: number;
  opportunity: 'none' | 'low' | 'medium' | 'high';
}

interface AvailabilityMapDay {
  schedule: FacilityScheduleSerializer;
  date: string;
  data: Availability[];
  limiters: FacilityLimiterSerializer[];
}

const availabilityColorStyle =
  'bg-gradient-to-b from-sky-300 to-sky-400 border-sky-400 opacity-80';
const appointmentColorStyle =
  'bg-gradient-to-b from-green-300 to-green-400 border-green-400';

const emptyBlock: OverallBlock = {
  available: 0,
  booked: 0,
  limits: 0,
  opportunity: 'low',
};

const getDOWSchedule = (
  date: string,
  schedule: FacilityScheduleSerializer
): DaySerializer => {
  if (!date || !schedule) {
    return null;
  }
  return schedule[DAYS_OF_WEEK_JS[moment(date).weekday()].toLowerCase()];
};

const DayByInstructor = ({
  schedule,
  date,
  data,
  limiters,
}: AvailabilityMapDay) => {
  if (!date) {
    return <td />;
  }
  const daySchedule = getDOWSchedule(date, schedule);
  const [hours, setHours] = useState([]);
  const [instructors, setInstructors] = useState<[string, Availability[]][]>(
    []
  );
  const width = 188; // 192px - padding
  useEffect(() => {
    if (daySchedule) {
      const _start = moment(daySchedule.open, TIME_FMT).hour();
      const _end = moment(daySchedule.close, TIME_FMT).hour() + 1;
      setHours(
        Array.from(new Array(_end - _start + 1), (val, index) => _start + index)
      );
      setInstructors(
        Object.entries<Availability[]>(
          data.groupBy((d) => d.instructor.id)
        ).sort((a, b) => (a[0] > b[0] ? -1 : 1))
      );
    }
  }, [schedule]);

  const getOffset = () =>
    moment(daySchedule.open, TIME_FMT).minutes() === 30
      ? width / hours.length / 2
      : 0;

  const getLeft = (time: string) =>
    (moment(time, TIME_FMT).diff(moment(daySchedule.open, TIME_FMT), 'hours') /
      hours.length) *
      width +
    getOffset();
  return (
    <div className={`border border-gray-300`}>
      <DayHeader date={date} daySchedule={daySchedule} />
      <div className="w-full px-0.5 min-h-[8rem] ml-5 py-2">
        {daySchedule && (
          <>
            <div className="flex">
              {hours.map((hour) => (
                <span
                  key={hour}
                  className="text-gray-500 text-xxs"
                  style={{ width: width / hours.length }}
                >
                  {hour}
                </span>
              ))}
            </div>
            <div className="flex flex-col-reverse ">
              {instructors.map(([instructor, availability], i) => {
                // const starts = getLeft(availability[0].time);
                return (
                  <div
                    key={instructor}
                    className="relative flex h-4 my-px text-xxs last-of-type:border-t odd:bg-background group"
                  >
                    <div className="absolute top-0 z-10 -left-5">
                      <span className="flex items-center w-4 h-4 px-1 overflow-hidden font-light text-white transition-all duration-150 bg-blue-700 rounded-full cursor-pointer whitespace-nowrap group-hover:w-auto">
                        {instructor[0]}
                        <span className="hidden group-hover:inline">
                          {instructor.substring(1)}
                        </span>
                      </span>
                    </div>
                    <div>
                      {availability.map((avail, i) => {
                        const left = getLeft(avail.time);
                        const color = avail.isBooked
                          ? appointmentColorStyle
                          : availabilityColorStyle;
                        return (
                          <div
                            key={`avail-${i}`}
                            style={{
                              left: left,
                              width:
                                Math.ceil((width / hours.length) * 10) / 10,
                            }}
                            className={`absolute border-t border-b h-4 first-of-type:border-l-2 first-of-type:rounded-l-sm last-of-type:border-r-2 last-of-type:rounded-r-sm ${color}`}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const DayOverview = ({
  schedule,
  date,
  data,
  limiters = [],
}: AvailabilityMapDay) => {
  if (!date) {
    return <td />;
  }
  const daySchedule = getDOWSchedule(date, schedule);
  const [hourly, setHourly] = useState<[string, OverallBlock][]>([]);
  useEffect(() => {
    if (daySchedule) {
      const _start = moment(daySchedule.open, TIME_FMT).hour();
      const _end = moment(daySchedule.close, TIME_FMT).hour() + 1;
      const _hours = Array.from(
        new Array(_end - _start + 1),
        (val, index) => _start + index
      );
      const incrementalSchedule = _hours.reduce((agg, hour, i) => {
        const zero = moment(date).hour(hour).format(TIME_FMT);
        const thirty = moment(date).hour(hour).minute(30).format(TIME_FMT);
        return {
          ...agg,
          [zero]: { ...emptyBlock },
          [thirty]: { ...emptyBlock },
        };
      }, {});
      data.map((avail) => {
        const start = avail.time;
        const mid = moment(`${avail.date} ${avail.time}`)
          .add(30, 'minutes')
          .format(TIME_FMT);
        if (avail.isBooked) {
          incrementalSchedule[start]['booked'] += 1;
          incrementalSchedule[mid]['booked'] += 1;
        }
        incrementalSchedule[start]['available'] += 1;
        incrementalSchedule[mid]['available'] += 1;
      });
      limiters.map((limit) => {
        if (!incrementalSchedule[limit.time]) {
          incrementalSchedule[limit.time] = { ...emptyBlock };
        }
        incrementalSchedule[limit.time]['limits'] += 1;
      });
      setHourly(Object.entries(incrementalSchedule));
    }
  }, [schedule]);

  return (
    <div className={`border border-gray-300`}>
      <DayHeader date={date} daySchedule={daySchedule} />
      <div className="w-full px-0.5 min-h-[8rem]">
        {daySchedule && (
          <>
            <div className="flex">
              {hourly.map(([time, obj]) => {
                const hour = moment(time, TIME_FMT).format(DATE_FMT.TIME_A);
                const available = (obj.available / SCALE) * 100;
                const bookings = (obj.booked / SCALE) * 100;
                const limits = (obj.limits / SCALE) * 100;
                // const capacity =
                //   ((obj.spots + obj.booked) / SCALE) * 100;
                // const hourData = availabilityData.filter(
                //   (d) => moment(d.datetime).tz(timezone).hour() === hour
                // );
                return (
                  <div
                    key={time}
                    className="flex flex-col flex-1 h-20 text-gray-500 text-xxs group"
                  >
                    <span className="absolute top-0 z-20 hidden px-2 py-1 text-xs text-blue-600 -translate-x-1/2 bg-white border border-blue-500 rounded-full left-full group-hover:block">
                      {hour}
                    </span>
                    <div className="flex flex-1 group-hover:bg-blue-200">
                      <div className="flex-1 h-full">
                        <Bar
                          height={available}
                          colors={availabilityColorStyle}
                        />
                        <Bar height={bookings} colors={appointmentColorStyle} />
                        <Bar
                          height={limits}
                          colors="bg-gradient-to-b from-gray-600 to-gray-700 border-gray-700"
                        />
                        {/* <Bar
                          height={capacity}
                          colors="bg-transparent border-red-300"
                        /> */}
                        <span className="z-0 block h-full" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const DayHeader = ({
  date,
  daySchedule,
}: {
  date: string;
  daySchedule: DaySerializer;
}) => (
  <div className="flex text-gray-600">
    <span>{moment(date).format(DATE_FMT.D)}</span>
    <span className="items-center flex-1 text-sm font-light text-center">
      {daySchedule
        ? `${moment(daySchedule.open, TIME_FMT).format(DATE_FMT.TIME)}-
          ${moment(daySchedule.close, TIME_FMT).format(DATE_FMT.TIME)}`
        : 'Closed'}
    </span>
  </div>
);

const getOpportunityLevel = (
  time: string,
  date: string,
  data: OverallBlock
) => {
  const md = moment(`${date} ${time}`);
  const day = md.day();
  let opportunity = 'low';
  let start;
  let end;
  if (day === DAY.SU) {
    start = moment(date).hour(9);
    end = moment(date).hour(14);
  } else if (
    day === DAY.MO ||
    day === DAY.TU ||
    day === DAY.WE ||
    day === DAY.TH
  ) {
    start = moment(date).hour(15);
    end = moment(date).hour(20);
  } else if (day === DAY.FR) {
    start = moment(date).hour(14);
    end = moment(date).hour(19);
  } else if (day === DAY.SU) {
    start = moment(date).hour(10);
    end = moment(date).hour(15);
  }
  if (md.isBetween(start, end, 'hour', '[)')) {
    opportunity = 'high';
  }
};

const DayByOpportunity = ({
  schedule,
  date,
  data,
}: // limiters,
AvailabilityMapDay) => {
  if (!date) {
    return <td />;
  }
  const daySchedule = getDOWSchedule(date, schedule);
  const [hourly, setHourly] = useState<Array<[string, OverallBlock]>>([]);
  useEffect(() => {
    if (daySchedule) {
      const _start = moment(daySchedule.open, TIME_FMT).hour();
      const _end = moment(daySchedule.close, TIME_FMT).hour() + 1;
      const _hours = Array.from(
        new Array(_end - _start + 1),
        (val, index) => _start + index
      );
      const incrementalSchedule = _hours.reduce((agg, hour, i) => {
        const zero = moment(date).hour(hour).format(TIME_FMT);
        const thirty = moment(date).hour(hour).minute(30).format(TIME_FMT);
        return {
          ...agg,
          [zero]: { ...emptyBlock },
          [thirty]: { ...emptyBlock },
        };
      }, {});
      data.map((avail) => {
        const start = avail.time;
        const mid = moment(`${avail.date} ${avail.time}`)
          .add(30, 'minutes')
          .format(TIME_FMT);
        if (avail.isBooked) {
          incrementalSchedule[start]['booked'] += 1;
          incrementalSchedule[mid]['booked'] += 1;
        }
        incrementalSchedule[start]['available'] += 1;
        incrementalSchedule[mid]['available'] += 1;
      });
      const temp: Array<[string, OverallBlock]> =
        Object.entries(incrementalSchedule);
      const opportunitySchedule = temp.map(([time, data]) =>
        getOpportunityLevel(time, date, data)
      );
      // setHourly(Object.entries(opportunitySchedule));
    }
  }, [schedule]);

  return (
    <div className={`border border-gray-300`}>
      <DayHeader date={date} daySchedule={daySchedule} />
      <div className="w-full px-0.5 min-h-[4rem] ml-5">
        {daySchedule && (
          <>
            <div className="flex">
              {hourly.map(([time, obj]) => {
                const hour = moment(time, TIME_FMT).hour();
                const minute = moment(time, TIME_FMT).minute();
                const available = (obj.available / SCALE) * 100;
                const bookings = (obj.booked / SCALE) * 100;
                // const capacity =
                //   ((obj.spots + obj.booked) / SCALE) * 100;
                // const hourData = availabilityData.filter(
                //   (d) => moment(d.datetime).tz(timezone).hour() === hour
                // );
                return (
                  <div
                    key={time}
                    className="flex flex-col flex-1 w-3 h-20 text-gray-500 text-xxs"
                  >
                    {minute === 0 && (
                      <span className="absolute top-0 left-0">{hour}</span>
                    )}
                    <div className="flex flex-1">
                      <div className="flex-1 h-full">
                        <Bar
                          height={available}
                          colors="bg-blue-400 border-blue-500 opacity-80"
                        />
                        <Bar
                          height={bookings}
                          colors="bg-green-400 border-green-500"
                        />
                        <span className="z-0 block h-full" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const SCALE = 5;
const Bar = ({ height, colors }) => (
  <span
    className="absolute bottom-0 z-20 w-full"
    style={{
      height: Math.max(Math.min(height, 100), 1) + '%',
    }}
  >
    <div className={`w-full h-full border-t ${colors}`} />
  </span>
);

const ViewType = ({ viewType, setViewType }) => {
  return (
    <div className="flex max-w-lg gap-4">
      <div className="flex-1">
        <div className="text-xs font-light uppercase">View Type</div>
        <Select
          options={[
            { label: 'Overview', value: 'overview' },
            { label: 'Instructor', value: 'instructor' },
            // { label: 'Opportunity', value: 'opportunity' },
          ]}
          onChange={(val) => setViewType(val as SelectOption<string>)}
          value={viewType}
        />
      </div>
    </div>
  );
};

const AvailabilityMapV2 = ({
  facility,
}: {
  facility: FacilitySchedulableSerializer;
}) => {
  const dispatch = useAppDispatch();
  const [schedules, setSchedules] = useState<FacilityScheduleSerializer[]>([]);
  const [limiters, setLimiters] = useState([]);
  const [isFetchingSchedule, setFetchingSchedule] = useState(false);
  const [isFetchingLimiters, setFetchingLimiters] = useState(false);
  const [isFetchingAvailability, setFetchingAvailability] = useState(false);
  // const facility = useSelector(getFacilityDetail);

  const [viewType, setViewType] = useState<SelectOption<string>>({
    label: 'Overview',
    value: 'overview',
  });
  const [availability, setAvailability] = useState<Availability[]>([]);

  const renderDate = useSelector(getScheduleRenderDate);
  const start = moment(renderDate, DATE_FMT.DATE_KEY).date(1);
  const end = moment(renderDate, DATE_FMT.DATE_KEY).endOf('month');

  const isClosed =
    facility &&
    schedules.length === 1 &&
    !schedules[0].friday &&
    !schedules[0].monday &&
    !schedules[0].saturday &&
    !schedules[0].sunday &&
    !schedules[0].tuesday &&
    !schedules[0].wednesday &&
    !schedules[0].thursday;

  const fetchLimiters = async () => {
    setFetchingLimiters(true);
    const response = await api.facilities.limiters(facility.slug, {
      start: start.format(DATE_FMT.DATE_KEY),
      end: end.format(DATE_FMT.DATE_KEY),
    });
    setLimiters(response.data);
    setFetchingLimiters(false);
  };

  const fetchSchedule = async () => {
    setFetchingSchedule(true);
    const response = await api.facilities.schedules(facility.slug, {
      start: start.format(DATE_FMT.DATE_KEY),
      end: end.format(DATE_FMT.DATE_KEY),
    });
    setSchedules(response.data);
    setFetchingSchedule(false);
  };

  const fetchAvailability = async () => {
    setFetchingAvailability(true);
    const response = await api.availability.list({
      facility: facility.slug,
      start: start.format(DATE_FMT.DATE_KEY),
      end: end.format(DATE_FMT.DATE_KEY),
      all: true,
    });
    setAvailability(response.data);
    setFetchingAvailability(false);
  };

  useEffect(() => {
    if (!facility) {
      return;
    }
    setSchedules([]);
    setLimiters([]);
    setAvailability([]);
    fetchSchedule();
    fetchLimiters();
    fetchAvailability();
  }, [renderDate, facility]);

  const DayComponent =
    viewType.value === 'overview'
      ? DayOverview
      : viewType.value === 'instructor'
      ? DayByInstructor
      : viewType.value === 'opportunity'
      ? DayByOpportunity
      : null;

  const isLoading =
    isFetchingAvailability || isFetchingSchedule || isFetchingLimiters;

  return (
    <div className="p-6 m-2 bg-white rounded-lg shadow-md">
      {isLoading && <Loading />}
      {/* <ViewType viewType={viewType} setViewType={setViewType} /> */}
      {facility && (
        <div className={isLoading ? 'animate-pulse' : ''}>
          <div className="flex justify-center ">
            <div className="max-w-lg">
              <CalendarControls
                minDate={moment()
                  .subtract(18, 'months')
                  .format(DATE_FMT.DATE_KEY)}
                maxDate={moment().add(3, 'months').format(DATE_FMT.DATE_KEY)}
              />
            </div>
          </div>
          <Calendar
            DayComponent={(dateProps) => {
              const sched =
                !isLoading && dateProps.date
                  ? getFacilityScheduleForDate(dateProps.date, schedules)
                  : null;
              const data =
                !isLoading && dateProps.date
                  ? availability.filter((a) => a.date === dateProps.date)
                  : null;
              const limits =
                !isLoading && dateProps.date
                  ? limiters.filter((l) => l.date === dateProps.date)
                  : null;
              return (
                <DayComponent
                  date={dateProps.date}
                  schedule={sched}
                  limiters={limits}
                  data={data}
                  {...dateProps}
                />
              );
            }}
          />
          {facility && !isLoading && isClosed && (
            <div className="absolute -translate-x-1/2 -translate-y-1/2 shadow-lg top-1/2 left-1/2">
              <Callout type="error">
                This location has no schedule for this month. Try another
                location, or change the month.
              </Callout>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AvailabilityMapV2;
