import Add from '@mui/icons-material/Add';
import api from 'api';
import { TimeFrame } from 'api/Serializers/Appointments';
import { ClientAccount } from 'api/Serializers/Clients';
import { AxiosError } from 'axios';
import Button from 'components/button';
import BackButton from 'components/button-back';
import Controls from 'components/controls';
import CreditCardDetails from 'components/credit-card-details';
import CreditCardForm from 'components/credit-card-form';
import Modal from 'components/modal';
import Redirect from 'components/redirect';
import Select from 'components/select';
import { FETCH_STATE } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AssistanceWizardProps } from 'models/Assistance';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { getAccountDetail } from 'state/selectors';
import {
  fetchAppointment,
  fetchClientAppointments,
} from 'state/slice/appointments';
import { SHARED_ROUTES } from 'utils/routing';

const PaymentMethod = (props: AssistanceWizardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail) as ClientAccount;
  const [paymentMethodId, setPaymentMethodId] = useState(
    props.appointment.paymentMethod?.id ?? ''
  );
  const [submitState, setSubmitState] = useState(FETCH_STATE.IDLE);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const options =
    account.paymentMethods?.map((pm) => {
      return {
        label: pm.id,
        value: pm.id,
        data: pm,
      };
    }) ?? [];

  const getPaymentMethodFromId = (id: string) =>
    account.paymentMethods?.find((pm) => pm.id === id);
  // TODO add link to help article about deferred payment process
  const handleSave = async () => {
    try {
      setSubmitState(FETCH_STATE.PUT);
      await api.appointments.paymentMethod(props.appointment.id, {
        id: paymentMethodId,
      });
      enqueueSnackbar({
        message: "Appointment's payment method successfully updated",
        variant: 'success',
      });
      dispatch(fetchAppointment(props.appointment.id));
      dispatch(
        fetchClientAppointments({
          start: props.appointment.date,
          end: props.appointment.date,
        })
      );
      setSubmitState(FETCH_STATE.FULFILLED);
    } catch (err) {
      setSubmitState(FETCH_STATE.FAILED);
      const message =
        err instanceof AxiosError &&
        err.response.data.error === 'payment_method_failed'
          ? err.response.data.message
          : "Error changing appointment's payment method";
      enqueueSnackbar({
        message: message,
        variant: 'error',
      });
    }
  };
  const handleCloseAddingCard = () => setIsAddingCard(false);

  if (
    props.appointment.cancelled ||
    props.appointment.timeFrame !== TimeFrame.Outside48 ||
    !props.appointment.paymentMethod
  ) {
    enqueueSnackbar({
      message: "Appointment's payment method can't be changed",
      variant: 'error',
    });
    return (
      <Redirect to={SHARED_ROUTES.SCHEDULE.appointment(props.appointment.id)} />
    );
  }

  if (submitState === FETCH_STATE.FULFILLED) {
    return (
      <Redirect to={SHARED_ROUTES.SCHEDULE.appointment(props.appointment.id)} />
    );
  }

  return (
    <>
      <h2>Change payment method</h2>
      {/* TODO: Put below notice in Callout */}
      <p>
        Any pre-authorized payments on your previous card will be cancelled and
        charged to the new card.
      </p>
      <div className="my-8">
        <Select
          styles={{
            valueContainer: (provided) => ({
              ...provided,
              minHeight: 80,
              fontSize: '0.875rem',
            }),
          }}
          options={options}
          value={options.find((opt) => opt.value === paymentMethodId)}
          onChange={(option) => setPaymentMethodId(option.value)}
          formatOptionLabel={(data) => (
            <CreditCardDetails
              paymentMethod={getPaymentMethodFromId(data.value)}
              showDefaultBadge={false}
            />
          )}
          components={{
            Option: CustomCardSelectOption,
          }}
          isSearchable={false}
        />
      </div>
      <Controls variant="block">
        <BackButton />
        <Button
          variant="flat"
          icon={<Add />}
          onClick={() => setIsAddingCard(true)}
        >
          Add a payment method
        </Button>
        <Button
          color="primary"
          variant="flat"
          isLoading={submitState === FETCH_STATE.PUT}
          onClick={handleSave}
          disabled={
            paymentMethodId === (props.appointment.paymentMethod?.id ?? '')
          }
        >
          Save
        </Button>
      </Controls>
      <Modal
        name="Add payment method"
        open={isAddingCard}
        onClose={handleCloseAddingCard}
        title="Add payment method"
      >
        <CreditCardForm
          onCreated={handleCloseAddingCard}
          onClose={handleCloseAddingCard}
          showDefaultToggle={false}
        />
      </Modal>
    </>
  );
};

const CustomCardSelectOption = (props) => {
  if (!props.innerProps) {
    return null;
  }
  const { data, label } = props.data;
  return (
    <components.Option {...props} className="flex items-center px-3 py-2">
      <CreditCardDetails paymentMethod={data} showDefaultBadge={false} />
    </components.Option>
  );
};

export default PaymentMethod;
