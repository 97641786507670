import { ClientAccount } from 'api/Serializers/Clients';
import { PaymentMethodSerializer } from 'api/Serializers/PaymentMethods';
import Button from 'components/button';
import Controls from 'components/controls';
import CreditCardBrand from 'components/credit-card-brand';
import CreditCardForm from 'components/credit-card-form';
import Modal from 'components/modal';
import { EditIcon } from 'icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail, getCartPaymentMethod } from 'state/selectors';

const PaymentMethodSelect = ({
  stripeConfigReturnUrl,
  onSelect,
}: {
  stripeConfigReturnUrl: string;
  onSelect(paymentMethod: PaymentMethodSerializer): void;
}) => {
  const account = useSelector(getAccountDetail) as ClientAccount;
  const selectedPaymentMethod = useSelector(getCartPaymentMethod);
  const paymentMethods = account?.paymentMethods || [];
  const [isCreating, setIsCreating] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);

  const handleSelect = (paymentMethod: PaymentMethodSerializer) => {
    setIsSelecting(false);
    setIsCreating(false);
    onSelect(paymentMethod);
  };

  const handleCreateClick = () => {
    setIsCreating(true);
  };

  const handleClickEdit = () => {
    if (paymentMethods.length === 0) {
      setIsCreating(true);
    } else {
      setIsSelecting(true);
    }
  };

  return (
    <>
      <div className="flex-1">
        <button
          className="flex items-center px-4 py-3 justify-between w-full border border-gray-600 hover:text-gray-900 hover:border-gray-900 rounded-lg min-h-[45px]"
          onClick={handleClickEdit}
        >
          {selectedPaymentMethod ? (
            <div className="flex items-center space-x-2">
              <CreditCardBrand
                brand={selectedPaymentMethod.brand}
                variant="card"
              />
              <span>{`•••• ${selectedPaymentMethod.last4}`}</span>
            </div>
          ) : (
            <span className="text-left">Add payment method</span>
          )}

          <span>
            <EditIcon width={24} />
          </span>
        </button>
      </div>
      <Modal
        name="Select payment method"
        title="Select a payment method"
        open={isSelecting && !isCreating}
        maxWidth="xs"
        fullWidth
        variant="borderless"
      >
        {isSelecting && (
          <div className="p-6 pb-0">
            <ul className="my-2">
              {paymentMethods?.length > 0 &&
                paymentMethods.map((paymentMethod) => {
                  const selected =
                    paymentMethod?.id === selectedPaymentMethod.id;
                  return (
                    <li key={paymentMethod?.id} className="-mx-6">
                      <button
                        className={`w-full text-left transition-colors duration-100 py-4 px-6  ${
                          selected
                            ? 'bg-blue-500 text-white'
                            : 'text-gray-800 hover:bg-blue-200 hover:text-gray-900'
                        }`}
                        onClick={() => handleSelect(paymentMethod)}
                      >
                        <div className="flex items-center space-x-2">
                          <CreditCardBrand
                            brand={paymentMethod.brand}
                            variant="card"
                          />
                          <span>{`•••• ${paymentMethod.last4}`}</span>
                          {paymentMethod.isDefault && (
                            <span className="px-2 py-0 text-xs text-gray-700 bg-gray-300 rounded-sm">
                              Default
                            </span>
                          )}
                        </div>
                      </button>
                    </li>
                  );
                })}
            </ul>
            <Controls>
              <Button onClick={() => setIsSelecting(false)}>Close</Button>
              <Button variant="flat" onClick={handleCreateClick}>
                Add new payment method
              </Button>
            </Controls>
          </div>
        )}
      </Modal>
      <Modal
        name="Checkout — Add payment method"
        title="Add new payment method"
        open={isCreating}
        onClose={() => setIsCreating(false)}
        maxWidth="xs"
        fullWidth
        disableEnforceFocus={true}
      >
        <CreditCardForm
          stripeConfigReturnUrl={stripeConfigReturnUrl}
          onCreated={handleSelect}
          onClose={() => setIsCreating(false)}
        />
      </Modal>
    </>
  );
};

export default PaymentMethodSelect;
