import { Facility } from 'api/Serializers/Appointments';
import Avatar from 'components/avatar';
import Callout from 'components/callout';
import Loading from 'components/loading';
import WeeklySchedule from 'components/weekly-schedule';
import ReadMore from 'containers/read-more';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getScheduleFacilityWeeklySchedules } from 'state/selectors';
import { fetchFacilityWeeklySchedules } from 'state/slice/schedule';

const FacilityScheduleUpdates = ({
  facility,
  truncate = true,
}: {
  facility: Pick<
    Facility,
    'slug' | 'avatar' | 'displayName' | 'shortName' | 'instructorNotes'
  >;
  truncate?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const allSchedules = useSelector(getScheduleFacilityWeeklySchedules);
  const facilitySchedules = allSchedules[facility.slug];
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (facilitySchedules === undefined) {
        await dispatch(fetchFacilityWeeklySchedules(facility.slug));
      }
      setIsLoading(false);
    })();
  }, [facility]);

  if (isLoading) {
    return (
      <div className="flex justify-center w-full">
        <Loading
          position="inline-contained"
          message={`Loading ${facility.shortName} schedule...`}
        />
      </div>
    );
  }

  if (facilitySchedules === undefined) {
    return (
      <div className="flex justify-center w-full">
        <Callout className="w-full" type="error">
          Error fetching schedules for {facility.displayName}
        </Callout>
      </div>
    );
  }

  return (
    <div className="w-full p-4 mb-4 rounded-lg bg-background">
      <div className="mb-3">
        <div className="flex items-center">
          <Avatar
            src={facility.avatar}
            className="mr-2"
            diameter={12}
            border={true}
          />
          <div>
            <div className="font-semibold text-gray-700 text-md">
              {facility.displayName}
            </div>
          </div>
        </div>
        {facility.instructorNotes && (
          <div className="mt-1 text-base italic text-gray-600">
            {facility.instructorNotes}
          </div>
        )}
      </div>
      <div className="text-gray-800">
        {facilitySchedules.length > 0 ? (
          <>
            <WeeklySchedule
              key={facilitySchedules[0].startDate}
              schedule={facilitySchedules[0]}
              detailed
            />
            {facilitySchedules.length === 1 ? null : truncate ? (
              <ReadMore initialHeight={0} openBtnText="Show All Schedules">
                <div>
                  {facilitySchedules.slice(1).map((schedule) => (
                    <WeeklySchedule
                      key={schedule.startDate}
                      schedule={schedule}
                      detailed
                    />
                  ))}
                </div>
              </ReadMore>
            ) : (
              <div>
                {facilitySchedules.slice(1).map((schedule) => (
                  <WeeklySchedule
                    key={schedule.startDate}
                    schedule={schedule}
                    detailed
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="text-base font-semibold">
            No current schedule for facility
          </div>
        )}
        <div className="text-base font-semibold">Next schedule TBA</div>
      </div>
    </div>
  );
};

export default FacilityScheduleUpdates;
