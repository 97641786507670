import UmbrellaIcon from '@mui/icons-material/BeachAccess';
import SunIcon from '@mui/icons-material/LightMode';
import Card from 'components/card';
import MenuWrap from 'components/menu-wrapper';
import { CAL_LIST_ITEM_NAME_PREFIX, DATE_FMT } from 'config';
import moment from 'moment-timezone';
import React from 'react';

export const TodayEmpty = ({ date }) => {
  const moDate = moment();
  return (
    <Card
      maxWidth="xl"
      name={`${CAL_LIST_ITEM_NAME_PREFIX}-${date}`}
      className="border border-blue-400"
    >
      <div className="flex flex-col justify-between sm:flex-row">
        <div className="relative flex-1">
          <div
            style={{ letterSpacing: '1.12px', top: -12 }}
            className="absolute font-medium text-gray-500 uppercase text-xxs"
          >
            {moDate.format(DATE_FMT.DOW_FULL)}
          </div>
          <div className="flex">
            <div className="mr-4 text-lg font-semibold text-gray-600 sm:text-xl">
              {moDate.format(DATE_FMT.D)}
            </div>
            <div className="flex-1 text-lg text-center text-gray-700 sm:text-xl font-">
              Today
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center my-8">
        <span className="flex text-blue-400">
          <UmbrellaIcon color="inherit" fontSize="large" />
        </span>
        <span className="flex mx-3 text-lg font-bold text-gray-600">
          Nothing booked today!
        </span>
        <span className="flex text-yellow-400">
          <SunIcon color="inherit" fontSize="large" />
        </span>
      </div>
    </Card>
  );
};

export const DateCardTitle = ({
  date,
  title,
  menuItems,
}: {
  date?: string;
  title: string;
  menuItems?: any;
}) => {
  const moDate = date ? moment(date, DATE_FMT.DATE_KEY) : undefined;
  return (
    <div className="flex flex-col justify-between mb-3 sm:flex-row">
      <div className="relative flex-1">
        {moDate && (
          <div
            style={{ letterSpacing: '1.12px', top: -12 }}
            className="absolute font-medium text-gray-500 uppercase text-xxs"
          >
            {moDate.format(DATE_FMT.DOW_FULL)}
          </div>
        )}
        <div className="relative flex">
          {moDate && (
            <div className="absolute text-lg font-semibold text-gray-600 sm:text-xl">
              {moDate.format(DATE_FMT.D)}
            </div>
          )}
          <div className="flex-1 px-8 text-lg text-center text-gray-700 sm:text-xl">
            {title}
          </div>
          {menuItems && (
            <div className="absolute right-0 font-medium text-gray-500 text-xxs">
              <MenuWrap>{menuItems}</MenuWrap>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const DateTimeLoading = () => (
  <Card maxWidth="xl">
    <div className="animate-pulse">
      <div className="flex flex-col justify-between sm:flex-row">
        <div className="relative flex-1">
          <div className="flex">
            <div className="bg-blue-300 rounded-full w-7 h-7" />
            <div className="flex justify-center flex-1">
              <span className="w-1/3 bg-blue-300 rounded-md h-7 min-w-20" />
            </div>
          </div>
        </div>
      </div>
      {[0, 1, 2].map((i, k) => (
        <div
          key={`load-${k}`}
          className={`flex items-center justify-start my-2`}
        >
          <div className="w-12 px-3 py-2 mr-1 bg-blue-300 rounded-md" />
          <div className="flex-1 w-12 h-6 px-3 py-2 bg-blue-300 rounded-md" />
        </div>
      ))}
    </div>
  </Card>
);
