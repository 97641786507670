import CheckIcon from '@mui/icons-material/Done';
import React from 'react';
import style from './style.module.scss';

const Progress: React.FC<{ current: string }> = ({ current }) => {
  const index = current === 'background' ? 1 : current === 'profile' ? 2 : 3;
  return (
    <div className={`flex justify-center items-center py-4 ${style.container}`}>
      <div className="flex flex-col items-center w-24 text-xs text-gray-600">
        <span
          className={`p-2 border rounded-full w-6 h-6 leading-none text-xs flex items-center justify-center text-white ${
            index >= 1
              ? 'bg-blue-400 border-blue-400'
              : 'bg-gray-400 border-gray-400'
          }`}
        >
          {index > 1 ? <CheckIcon fontSize="small" /> : '1'}
        </span>
        <span className="my-2">Background</span>
      </div>
      <div className="flex flex-col items-center w-24 text-xs text-gray-600">
        <span
          className={`p-2 border rounded-full w-6 h-6 leading-none text-xs flex items-center justify-center text-white ${
            index >= 2
              ? 'bg-blue-400 border-blue-400'
              : 'bg-gray-400 border-gray-400'
          }`}
        >
          {index > 2 ? <CheckIcon fontSize="small" /> : '2'}
        </span>
        <span className="my-2">Profile</span>
      </div>
      <div className="flex flex-col items-center w-24 text-xs text-gray-600">
        <span
          className={`p-2 border rounded-full w-6 h-6 leading-none text-xs flex items-center justify-center text-white ${
            index >= 3
              ? 'bg-blue-400 border-blue-400'
              : 'bg-gray-400 border-gray-400'
          }`}
        >
          {index > 3 ? <CheckIcon fontSize="small" /> : '3'}
        </span>
        <span className="my-2">Lessons</span>
      </div>
    </div>
  );
};

export default Progress;
