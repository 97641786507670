import Address, { Locality } from 'models/geo';
import { Suggest } from 'react-geosuggest';
import { slugify } from 'utils/string';

type AddressComponentType =
  | 'street_number'
  | 'street_address'
  | 'route'
  | 'intersection'
  | 'political'
  | 'country'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'administrative_area_level_6'
  | 'administrative_area_level_7'
  | 'colloquial_area'
  | 'locality'
  | 'sublocality'
  | 'neighborhood'
  | 'premise'
  | 'subpremise'
  | 'plus_code'
  | 'postal_code'
  | 'natural_feature'
  | 'airport'
  | 'park'
  | 'point_of_interest';

// https://developers.google.com/maps/documentation/javascript/geocoding?hl=en#GeocodingAddressTypes
const COMPONENT: { [key: string]: AddressComponentType } = {
  NUMBER: 'street_number',
  ROUTE: 'route',
  LOCALITY: 'locality',
  REGION: 'administrative_area_level_1',
  COUNTRY: 'country',
  POSTAL_CODE: 'postal_code',
};
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const getAddressComponent = (
  acs: AddressComponent[],
  type: AddressComponentType
): AddressComponent => {
  const test = acs.find((a) => a.types.findIndex((t) => t === type) !== -1);
  return safe(test) ? test : { short_name: '', long_name: '', types: [] };
};

export const bestGuessCity = (components: AddressComponent[]): string => {
  let bestGuess = '';
  const componentHierarchy: Array<AddressComponentType> = [
    'locality',
    'administrative_area_level_3',
    'administrative_area_level_2',
  ];
  for (const component of componentHierarchy) {
    const data = getAddressComponent(components, component);
    if (data.long_name !== '') {
      bestGuess = data.long_name;
      break;
    }
  }
  return bestGuess;
};

export const geoSuggestToLocality = (gs: Suggest): Locality | undefined => {
  if (!gs || !gs.gmaps || !gs.gmaps.address_components) {
    return undefined;
  }
  const ac = gs.gmaps.address_components;
  // tslint:disable-next-line:variable-name
  const line1 = `${getAddressComponent(ac, COMPONENT.NUMBER).long_name} ${
    getAddressComponent(ac, COMPONENT.ROUTE).long_name
  }`;
  const postalCode = getAddressComponent(ac, COMPONENT.POSTAL_CODE);
  const locality = getAddressComponent(ac, COMPONENT.LOCALITY);
  const region = getAddressComponent(ac, COMPONENT.REGION);
  const country = getAddressComponent(ac, COMPONENT.COUNTRY);
  const lat = typeof gs.location !== 'undefined' ? Number(gs.location.lat) : 0;
  const lng = typeof gs.location !== 'undefined' ? Number(gs.location.lng) : 0;
  const label = `${locality.long_name !== '' ? `${locality.long_name}, ` : ''}${
    region.short_name
  }`;
  const localityObj: Locality = {
    label,
    name: label,
    line1,
    slug: slugify(`${locality.long_name} ${region.short_name}`),
    city: bestGuessCity(ac),
    country: country.long_name,
    countryCode: country.short_name,
    region: region.long_name,
    regionCode: region.short_name,
    latlng: {
      lat,
      lng,
    },
    postalCode: postalCode.long_name,
    description: gs.description,
    placeId: gs.placeId,
  };
  return localityObj;
};

// Mirror SuggestPlace formatting as closely as possible
export const getAddressString = (address: Address) =>
  [
    address.line1,
    address.city,
    address.regionCode,
    address.country,
    address.postalCode,
  ]
    .reduce(
      (agg, val, i) => (!val || val.trim() === '' ? agg : `${agg}, ${val}`),
      ''
    )
    .substring(2);
