import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from '@mui/x-data-grid-pro';
import api from 'api';
import {
  ConversationSerializer,
  MessageSerializer,
} from 'api/Serializers/Conversations';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import Loading from 'components/loading';
import { DATE_FMT } from 'config';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ADMIN_ROUTES } from '../utils';
import style from './style.module.scss';

const parseConversationRows = (data: ConversationSerializer[]): GridRowsProp =>
  data.map((elt: ConversationSerializer) => {
    return {
      id: elt.sid,
      userOneId: elt.participants[0].user.id,
      userOneName: elt.participants[0].user.fullName,
      userOneType: elt.participants[0].user.type,
      userTwoId: elt.participants[1].user.id,
      userTwoName: elt.participants[1].user.fullName,
      userTwoType: elt.participants[1].user.type,
      messageCount: elt.messageCount,
      lastMessageAdded: moment(elt.lastMessageAdded).format(
        DATE_FMT.DATETIME_TZ
      ),
    };
  });

const parseMessageRows = (data: MessageSerializer[]): GridRowsProp =>
  data.map((elt: MessageSerializer) => {
    return {
      id: elt.sid,
      userId: elt.userId,
      user: elt.author,
      userType: elt.userType,
      body: elt.body,
      index: elt.index,
      dateCreated: moment(elt.created).format(DATE_FMT.DATETIME_TZ),
    };
  });

const MessageLogs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { conversationId } = useParams<any>();
  const [conversationRows, setConversationRows] = useState<GridRowsProp>();
  const [messageRows, setMessageRows] = useState<GridRowsProp>();

  useEffect(() => {
    const fetchData = async () => {
      if (!conversationRows) {
        await fetchConversations();
      }
      if (conversationId) {
        await fetchMessages();
      }
    };
    fetchData();
  }, [conversationId]);

  const fetchConversations = async () => {
    try {
      const response = await api.conversations.list();
      setConversationRows(parseConversationRows(response.data));
    } catch (err) {
      enqueueSnackbar({
        message: 'Error fetching conversations',
        variant: 'error',
      });
      setConversationRows([]);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await api.conversations.messages(conversationId);
      setMessageRows(parseMessageRows(response.data));
    } catch (err) {
      enqueueSnackbar({
        message: 'Error fetching messages for conversation',
        variant: 'error',
      });
      history.push(ADMIN_ROUTES.MESSAGE_LOGS.ROOT);
    }
  };

  const ConversationGridCols: GridColDef[] = [
    { field: 'id', headerName: 'SID', flex: 1 },
    { field: 'userOneId', headerName: 'ID 1', width: 150 },
    { field: 'userOneName', headerName: 'User 1', flex: 1 },
    { field: 'userOneType', headerName: 'User 1 Type', flex: 1 },
    { field: 'userTwoId', headerName: 'ID 2', width: 150 },
    { field: 'userTwoName', headerName: 'User 2', flex: 1 },
    { field: 'userTwoType', headerName: 'User 2 Type', flex: 1 },
    { field: 'messageCount', headerName: 'Messages', width: 150 },
    { field: 'lastMessageAdded', headerName: 'Last Message', width: 250 },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <a
            className="cursor-pointer"
            onClick={async (e) => {
              e.stopPropagation();
              history.push(ADMIN_ROUTES.MESSAGE_LOGS.nav(params.id.toString()));
            }}
          >
            View Messages
          </a>
        );
      },
    },
  ];

  const MessageGridCols = [
    { field: 'id', headerName: 'SID', hide: true, flex: 1 },
    { field: 'userId', headerName: 'User ID', hide: true, width: 150 },
    { field: 'user', headerName: 'User', width: 200 },
    { field: 'userType', headerName: 'User Type', width: 200 },
    {
      field: 'body',
      headerName: 'Body',
      flex: 1,
      width: 500,
      // renderCell: (params: GridRenderCellParams) => <TooltipCell {...params} />,
    },
    { field: 'index', headerName: 'Index', width: 150 },
    { field: 'dateCreated', headerName: 'Date Created', width: 250 },
  ];

  if (!conversationRows || (conversationId && !messageRows)) {
    return (
      <div>
        <Loading position="absolute" message="Loading logs..." />
      </div>
    );
  }

  return (
    <Dashboard title="Message Logs" width="full">
      <div className={`${style.container} h-[70vh]`}>
        <DataGridPro
          className="bg-white"
          density="compact"
          pagination
          getRowHeight={() => 'auto'}
          columns={!conversationId ? ConversationGridCols : MessageGridCols}
          rows={!conversationId ? conversationRows : messageRows}
        />
      </div>
      <div className="mt-4">
        {conversationId && (
          <Button
            variant="contained"
            color="primary"
            icon={<ArrowBack />}
            onClick={() => {
              setMessageRows(undefined);
              history.push(ADMIN_ROUTES.MESSAGE_LOGS.ROOT);
            }}
          >
            Back to conversations
          </Button>
        )}
      </div>
    </Dashboard>
  );
};

// const TooltipCell = (props: GridRenderCellParams) => {
//   // Simplified version of https://github.com/mui/mui-x/issues/1040#issuecomment-1361092416
//   const [isOverflowing, setIsOverflowing] = useState(false);
//   const textElementRef = useRef(null);

//   useEffect(() => {
//     const hiddenWidth = textElementRef.current.getBoundingClientRect().width;
//     textElementRef.current.style.overflow = 'visible';
//     const fullWidth = textElementRef.current.getBoundingClientRect().width;
//     textElementRef.current.style.overflow = 'hidden';
//     setIsOverflowing(fullWidth > hiddenWidth);
//   }, []);

//   return (
//     <Tooltip title={props.value} disableHoverListener={!isOverflowing}>
//       <span
//         className="overflow-hidden text-ellipsis whitespace-nowrap"
//         ref={textElementRef}
//       >
//         {props.value}
//       </span>
//     </Tooltip>
//   );
// };

export default MessageLogs;
