import { Delete as DeleteIcon } from '@mui/icons-material';
import ColdIcon from '@mui/icons-material/AcUnit';
import CrowdedIcon from '@mui/icons-material/Groups';
import InaccessibleIcon from '@mui/icons-material/Lock';
import OtherIcon from '@mui/icons-material/ReportProblem';
import WaterIssueIcon from '@mui/icons-material/Science';
import { TimeFrame } from 'api/Serializers/Appointments';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { getReportTypeDescription } from 'features/assistance/as-instructor/utils';
import { AssistanceWizardProps } from 'models/Assistance';
import React, { useEffect } from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const QUESTION = 'What seems to be the problem?';

const LessonBeforeSameDay = (props: AssistanceWizardProps) => {
  const { appointment, trackAction } = props;
  const { id, timeFrame } = appointment;
  const handleFacilityClosure = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_INACCESSIBLE',
      title: QUESTION,
      description: 'Pool will be closed or inaccessible',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'HOST_CANCELLATION',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value:
        timeFrame === TimeFrame.Outside48
          ? 'CANCELLATION'
          : 'FACILITY_NOT_AVAILABLE',
    });
  };
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>Report issues with the pool or facility</h4>
      <Callout className="my-8" type="question" title={QUESTION} />
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="Pool will be closed or inaccessible"
          subtitle="Cancel the lesson due to pool closure"
          icon={<InaccessibleIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleFacilityClosure}
        />
      </div>
      <Controls variant="block">
        <BackButton />
      </Controls>
    </>
  );
};

const LessonHasEnded = (props: AssistanceWizardProps) => {
  const { appointment, trackAction } = props;
  const { id } = appointment;
  const handleFacilityClosure = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_INACCESSIBLE',
      title: QUESTION,
      description: 'Pool was closed or inaccessible',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_WILL_NOT_OPEN',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  const handleFacilityMaintenance = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_URGENT_MAINTENANCE',
      title: QUESTION,
      description: 'Cancel the lesson due to chemistry or maintenance problems',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_WILL_NOT_OPEN',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  const handleSomethingElse = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_NEEDS_ATTENTION',
      title: QUESTION,
      description: getReportTypeDescription('FACILITY_NEEDS_ATTENTION'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'PROPEL_REVIEW',
      title: 'Next steps',
      description:
        'Propel will handle any further action needed by this submission',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'NO_CHANGE',
    });
  };
  const handleOvercrowding = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_CROWDED',
      title: QUESTION,
      description: getReportTypeDescription('FACILITY_CROWDED'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_TOO_CROWDED',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  const handleFouling = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_FOULING',
      title: QUESTION,
      description: getReportTypeDescription('FACILITY_FOULING'),
    });
  };
  const handleTooCold = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_COLD',
      title: QUESTION,
      description: getReportTypeDescription('FACILITY_COLD'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_TOO_COLD',
      title: 'Decision',
      description: 'The water is too cold to continue',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>Report issues with the pool or facility</h4>
      <Callout className="my-8" type="question">
        {QUESTION}
      </Callout>
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="A closure prevented lessons"
          subtitle="Cancel due to pool closure"
          icon={<InaccessibleIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleFacilityClosure}
        />
        <ButtonLarge
          title="There was a pool fouling"
          subtitle="Cancel due to a pool fouling"
          icon={<DeleteIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/fouling')}
          onClick={handleFouling}
        />
        <ButtonLarge
          title="Maintenance prevented lessons"
          subtitle="Cancel the lesson due to chemistry or maintenance problems"
          icon={<WaterIssueIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleFacilityMaintenance}
        />
        <ButtonLarge
          title="Cold water prevented lessons"
          subtitle="Cancel due to temperature problems"
          icon={<ColdIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleTooCold}
        />
        <ButtonLarge
          title="Crowds prevented lessons"
          subtitle="Cancel due to overcrowding"
          icon={<CrowdedIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleOvercrowding}
        />
        <ButtonLarge
          title="Facility needs attention"
          subtitle="Non-urgent issues such as cleanliness, lighting, or bathrooms needing attention"
          icon={<OtherIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/general')}
          onClick={handleSomethingElse}
        />
      </div>
      <Controls variant="block">
        <BackButton />
      </Controls>
    </>
  );
};

const LessonIsToday = (props: AssistanceWizardProps) => {
  const { appointment, trackAction } = props;
  const { id } = appointment;
  const handleFacilityClosure = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_INACCESSIBLE',
      title: QUESTION,
      description: 'Pool is closed or inaccessible',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_WILL_NOT_OPEN',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  const handleFacilityMaintenance = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_URGENT_MAINTENANCE',
      title: QUESTION,
      description: 'Cancel the lesson due to chemistry or maintenance problems',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_WILL_NOT_OPEN',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  const handleSomethingElse = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_NEEDS_ATTENTION',
      title: QUESTION,
      description: getReportTypeDescription('FACILITY_NEEDS_ATTENTION'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'PROPEL_REVIEW',
      title: 'Next steps',
      description:
        'Propel will handle any further action needed by this submission',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'NO_CHANGE',
    });
  };
  const handleOvercrowding = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_CROWDED',
      title: QUESTION,
      description: getReportTypeDescription('FACILITY_CROWDED'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_TOO_CROWDED',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  const handleFouling = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_FOULING',
      title: QUESTION,
      description: getReportTypeDescription('FACILITY_FOULING'),
    });
  };
  const handleTooCold = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'FACILITY_COLD',
      title: QUESTION,
      description: getReportTypeDescription('FACILITY_COLD'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_TOO_COLD',
      title: 'Decision',
      description: 'The water is too cold to continue',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>Report issues with the pool or facility</h4>
      <Callout className="my-8" type="question">
        {QUESTION}
      </Callout>
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="Pool is closed or inaccessible"
          subtitle="Cancel the lesson due to pool closure"
          icon={<InaccessibleIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleFacilityClosure}
        />
        <ButtonLarge
          title="There was a pool fouling"
          subtitle="Cancel due to a pool fouling"
          icon={<DeleteIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/fouling')}
          onClick={handleFouling}
        />
        <ButtonLarge
          title="Urgent maintenance is needed"
          subtitle="Cancel the lesson due to chemistry or maintenance problems"
          icon={<WaterIssueIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleFacilityMaintenance}
        />
        <ButtonLarge
          title="The pool is cold"
          subtitle="Cancel due to cold water"
          icon={<ColdIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleTooCold}
        />
        <ButtonLarge
          title="Crowds are preventing lessons"
          subtitle="Cancel if crowds are preventing lessons"
          icon={<CrowdedIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
          onClick={handleOvercrowding}
        />
        <ButtonLarge
          title="Facility needs attention"
          subtitle="Non-urgent issues such as cleanliness, lighting, or bathrooms needing attention"
          icon={<OtherIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/general')}
          onClick={handleSomethingElse}
        />
      </div>
      <Controls variant="block">
        <BackButton />
      </Controls>
    </>
  );
};

const SelectFacilityProblem = (props: AssistanceWizardProps) => {
  useEffect(() => {
    props.trackAction({
      type: 'SET_CATEGORY',
      key: 'category',
      value: 'FACILITY',
      title: 'What do you need help with?',
      description: 'Facility issues',
    });
  }, []);

  const { appointment } = props;
  if (appointment.timeFrame < TimeFrame.SameDay) {
    return <LessonBeforeSameDay {...props} />;
  } else if (appointment.timeFrame >= TimeFrame.HasEnded) {
    return <LessonHasEnded {...props} />;
  } else {
    return <LessonIsToday {...props} />;
  }
};

export default SelectFacilityProblem;
