import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import {
  AppointmentListSerializer,
  CancelSerializer,
  Event,
  EventListParams,
} from 'api/Serializers/Appointments';
import axios from 'axios';
import { DATE_FMT, FETCH_STATE } from 'config';
import {
  GenericServerError,
  UserAppointmentCancelSuccess,
} from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { APIData } from 'state';
import { getScheduleRenderDate } from 'state/selectors';
import { AppDispatch, RootState } from 'state/store';
import { getMonthStartEndParams } from 'utils/date';
import { BLANK_API_DATA } from '../utils';

interface Reducer {
  detail: APIData<Event>;
  list: APIData<AppointmentListSerializer[]>;
  clientListHasMore: boolean;
  upcoming: AppointmentListSerializer[];
}

const initialState: Reducer = {
  detail: BLANK_API_DATA(),
  list: BLANK_API_DATA([]),
  clientListHasMore: true,
  upcoming: [],
};

const name: 'appointments' = 'appointments';
const Slice = createSlice({
  name,
  initialState,
  reducers: {
    setAppointmentFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.detail.fetchState = action.payload;
    },
    setAppointmentData(state, action: PayloadAction<Event>) {
      state.detail.data = action.payload;
    },
    // Currently for instructors and hosts
    setAppointments(state, action: PayloadAction<AppointmentListSerializer[]>) {
      state.list.data = action.payload;
      state.list.fetchState = FETCH_STATE.IDLE;
    },
    // Currently for clients only
    // TODO: Move instructor calendar to the upsert logic
    upsertAppointments(
      state,
      action: PayloadAction<AppointmentListSerializer[]>
    ) {
      state.list.data = [...state.list.data]
        .filter(
          (elt) =>
            action.payload.findIndex((inner) => elt.start === inner.start) ===
            -1
        )
        .concat(action.payload)
        .sort((a, b) => (a.start < b.start ? -1 : 1));
      state.list.fetchState = FETCH_STATE.IDLE;
    },
    updateListAppointment(state, action: PayloadAction<Event>) {
      state.list.data = state.list.data
        .filter((a) => a.id !== action.payload.id)
        .concat([action.payload]);
    },
    setListFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.list.fetchState = action.payload;
    },
    setListFetchedAt(state, action: PayloadAction<string>) {
      state.list.fetchedAt = action.payload;
    },
    setClientListHasMore(state, action: PayloadAction<boolean>) {
      state.clientListHasMore = action.payload;
    },
    setUpcoming(state, action: PayloadAction<AppointmentListSerializer[]>) {
      state.upcoming = action.payload;
    },
    clearAppointment(state) {
      state.detail.data = undefined;
      state.detail.fetchState = FETCH_STATE.IDLE;
    },
    clearAppointmentFetchStates: (state) => {
      state.list.data = [];
      state.list.fetchState = FETCH_STATE.PRISTINE;
      state.list.fetchedAt = undefined;
    },
  },
});

const {
  upsertAppointments,
  updateListAppointment,
  setAppointments,
  setAppointmentFetchState,
  setListFetchState,
  setListFetchedAt,
  setClientListHasMore,
  setUpcoming,
} = Slice.actions;

export const {
  setAppointmentData,
  clearAppointment,
  clearAppointmentFetchStates,
} = Slice.actions;

export const fetchAppointment =
  (appointmentId: string) => async (dispatch, getState: () => RootState) => {
    if (!appointmentId) {
      return null;
    }
    try {
      dispatch(setAppointmentData(undefined));
      dispatch(setAppointmentFetchState(FETCH_STATE.GET));
      const response = await api.appointments.retrieve(appointmentId);
      dispatch(setAppointmentFetchState(FETCH_STATE.IDLE));
      return dispatch(setAppointmentData(response.data));
    } catch (error: any) {
      logger.error(error);
      enqueueSnackbar({
        message:
          error?.response?.data?.details ||
          'There was a problem getting that appointment',
        variant: 'error',
      });
      dispatch(setAppointmentFetchState(FETCH_STATE.FAILED));
    }
  };

let abortController: AbortController;
export const fetchAppointments =
  (params: EventListParams = undefined) =>
  async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const renderDate = getScheduleRenderDate(state);
    if (abortController) {
      abortController.abort();
    }
    let requestParams = params;
    if (!requestParams) {
      requestParams = getMonthStartEndParams(renderDate);
    }
    dispatch(setListFetchState(FETCH_STATE.GET));
    try {
      abortController = new AbortController();
      dispatch(setListFetchedAt(moment().format(DATE_FMT.DATETIME_FIELD)));
      const response = await api.appointments.list(
        requestParams,
        abortController
      );
      const stillValid = getScheduleRenderDate(getState()) === renderDate;
      if (!stillValid) {
        return null;
      }
      dispatch(setAppointments(response.data));
      return response;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return undefined;
      }
      dispatch(setListFetchedAt(undefined));
      dispatch(setListFetchState(FETCH_STATE.FAILED));
      logger.captureAxiosError('Error fetching appointments', error);
      return undefined;
    }
  };

/**
 * Fetches next page of appointments if no params provided
 */
export const fetchClientAppointments =
  (customParams: EventListParams = undefined) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (
      (customParams === undefined &&
        !getState().appointments.clientListHasMore) ||
      getState().appointments.fetchState === FETCH_STATE.GET
    ) {
      return undefined;
    }
    const DEFAULT_LIMIT = 20;
    const currentAppts = getState().appointments.list.data;
    let paramsList: EventListParams[] = [];
    if (customParams !== undefined) {
      paramsList.push(customParams);
    } else if (currentAppts.length === 0) {
      const today = moment().format(DATE_FMT.DATE_KEY);
      // All upcoming
      paramsList.push({ start: today });
      // Up to 20 in the past (incl 1 for next page check)
      paramsList.push({
        end: moment().subtract(1, 'day').format(DATE_FMT.DATE_KEY),
        limit: DEFAULT_LIMIT + 1,
        order: 'desc',
      });
    } else {
      // Next page of 20 (incl 1 for next page check)
      paramsList.push({
        end: moment(currentAppts[0].date)
          .subtract(1, 'day')
          .format(DATE_FMT.DATE_KEY),
        limit: DEFAULT_LIMIT + 1,
        order: 'desc',
      });
    }
    dispatch(setListFetchState(FETCH_STATE.GET));
    try {
      dispatch(setListFetchedAt(moment().format(DATE_FMT.DATETIME_FIELD)));
      const responses = await Promise.all(
        paramsList.map((params) => api.appointments.list(params))
      );
      const allData = responses[0].data.concat(
        ...responses.slice(1).map((res) => res.data)
      );
      const lastResponse = responses[responses.length - 1];
      dispatch(upsertAppointments(allData));
      // Don't update the pagination state if params were set manually
      if (customParams === undefined) {
        dispatch(
          setClientListHasMore(lastResponse.data.length > DEFAULT_LIMIT)
        );
      }
      dispatch(setListFetchState(FETCH_STATE.FULFILLED));
      return lastResponse;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return undefined;
      }
      dispatch(setListFetchedAt(undefined));
      dispatch(setClientListHasMore(false));
      dispatch(setListFetchState(FETCH_STATE.FAILED));
      logger.captureAxiosError('Error fetching appointments', error);
      return undefined;
    }
  };

export const fetchNextAppointments =
  () => async (dispatch: AppDispatch, getState) => {
    const params = {
      start: moment().format(DATE_FMT.DATE_KEY),
      end: moment().add(90, 'days').format(DATE_FMT.DATE_KEY),
    };
    try {
      const response = await api.appointments.list(params);
      dispatch(setUpcoming(response.data));
      return response;
    } catch (error: any) {
      logger.captureAxiosError('Error fetching appointments', error);
      return undefined;
    }
  };

export const cancelAppointment =
  (appointment: Event, data: CancelSerializer) =>
  async (dispatch, getState): Promise<boolean> => {
    dispatch(setListFetchState(FETCH_STATE.GET));
    try {
      const response = await api.appointments.cancel(appointment.id, data);
      dispatch(setAppointmentData(response.data));
      dispatch(updateListAppointment(response.data));
      enqueueSnackbar(UserAppointmentCancelSuccess);
      dispatch(setListFetchState(FETCH_STATE.IDLE));
      return true;
    } catch (error) {
      dispatch(setListFetchState(FETCH_STATE.IDLE));
      enqueueSnackbar(GenericServerError);
      return false;
    }
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
