import api from 'api';
import { FacilityScheduleSerializer } from 'api/Serializers/Facilities';

export const fetchFacilityScheduleForDate = async (
  facilitySlug: string,
  date: string
): Promise<FacilityScheduleSerializer> => {
  const response = await api.facilities.schedules(facilitySlug, {
    start: date,
    end: date,
  });
  return getFacilityScheduleForDate(date, response.data);
};

export const getFacilityScheduleForDate = (
  date: string,
  schedules: FacilityScheduleSerializer[]
) =>
  schedules.length === 0
    ? ({} as FacilityScheduleSerializer)
    : schedules.find(
        (s) => s.startDate <= date && (!s.endDate || s.endDate >= date)
      );
