import { YearsExperience } from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { EXTERNAL_ROUTES, InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  HeaderClasses,
  TextClasses,
  TitleClasses,
} from '..';

const InsufficientWorkExperience: React.FC<BaseStepProps> = (props) => {
  if (props.onboarding.numYearsExp === YearsExperience.Unset) {
    return <Redirect to={InstructorOnboardingRoutes.S05} />;
  } else if (props.onboarding.numYearsExp !== YearsExperience.LessThanOne) {
    return <Redirect to={InstructorOnboardingRoutes.S07} />;
  }

  return (
    <div>
      <h1 className={TitleClasses}>Sorry but you're not quite ready!</h1>
      <p className={TextClasses}>
        Instructors must have at least one year of paid teaching experience to
        join Propel
      </p>
      <h2 className={HeaderClasses}>Almost there?</h2>
      <p className="text-gray-700">
        If you are already working, we'll check back in with you when you are
        ready. In the meantime, you can start building your client base.
      </p>
      <div className={ControlClasses}>
        <Button
          variant="contained"
          color="primary"
          to={`${EXTERNAL_ROUTES.BLOG.CLIENT_BASE_BUILDING}?ref=onboarding`}
          target="_self"
        >
          Show me how
        </Button>
      </div>
      <h2 className={HeaderClasses}>Have no experience at all?</h2>
      <p className="text-gray-700">
        If you are looking to get your first job in aquatics, we've put together
        some resources to help you out.
      </p>
      <div className={ControlClasses}>
        <Button
          variant="contained"
          color="primary"
          to={`${EXTERNAL_ROUTES.BLOG.BECOME_AN_INSTRUCTOR}?ref=onboarding`}
          target="_self"
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

export default InsufficientWorkExperience;
