import { IS_SERVER } from 'config';
import React, { useEffect, useState } from 'react';

const Help = () => {
  const [check, setCheck] = useState(0);
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    if (IS_SERVER) {
      return;
    }
    if (typeof window.supportHeroWidget === 'undefined') {
      setTimeout(() => setCheck(check + 1), 500);
      return;
    }
    window.HelpWidget = window.supportHeroWidget;
    window.HelpWidget.onShow(function () {
      setOpen(true);
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '17px';
    });
    window.HelpWidget.onHide(function () {
      setOpen(false);
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    });
  }, [check]);

  const handleClick = (evt) => {
    if (window.HelpWidget !== undefined) {
      window.HelpWidget.hide();
    }
  };

  return (
    <div
      className={
        isOpen ? 'bg-black opacity-50 fixed top-0 bottom-0 left-0 right-0' : ''
      }
      style={{ zIndex: 29999 }}
      onClick={handleClick}
    />
  );
};

export default Help;
