import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import api from 'api';
import { YearlySummary } from 'api/Serializers/Payouts';
import YearSummary from 'components/dashboards/earnings/year-summary';
import Link from 'components/link';
import Loading from 'components/loading';
import { Entity } from 'models/route';
import moment from 'moment-timezone';
import { ADMIN_ROUTES } from 'pages/account/admin/utils';
import React, { useEffect, useState } from 'react';

const AdminInstructorPayoutSummary = ({ match }) => {
  const numYearsToShow = 3;
  const [yearlySummaries, setYearlySummaries] = useState<YearlySummary[]>([]);
  // tslint:disable-next-line: no-bitwise
  const year: string = match.params.payoutId || moment().year().toString();
  const [selectedSummary, setSelectedSummary] = useState<number>();
  useEffect(() => {
    const fetchData = async () => {
      setYearlySummaries([]);
      const response = await api.payouts.byYear(year);
      setYearlySummaries(response.data);
    };
    fetchData();
  }, [year]);

  return (
    <div>
      <div className="card button-group">
        {[...Array(numYearsToShow).keys()].map((i) => {
          const linkYear = (
            moment().year() -
            numYearsToShow +
            1 +
            i
          ).toString();
          return (
            <Link
              key={linkYear}
              to={`${ADMIN_ROUTES.PAYOUTS.nav(Entity.Instructors)}/${linkYear}`}
              className={`!no-underline px-4 ${
                year === linkYear ? 'active' : ''
              }`}
            >
              {linkYear}
            </Link>
          );
        })}
      </div>
      <div className="w-full max-w-4xl mx-auto bg-white rounded-lg">
        {yearlySummaries.length === 0 && <Loading />}
        <div className="flex w-full p-6 pr-16 no-print">
          <span className="flex items-center flex-1 font-medium">
            Instructor
          </span>
          <span className="flex items-center justify-center flex-1">
            Num. Appointments
          </span>
          <span className="flex items-center justify-end flex-1 text-right">
            GMV
          </span>
          <span className="flex items-center justify-end flex-1 text-right">
            Earnings
          </span>
        </div>
        {yearlySummaries.map((summary, i) => {
          return (
            <Accordion
              key={summary.id}
              elevation={0}
              className={selectedSummary !== summary.id ? 'no-print' : ''}
              onChange={() =>
                setSelectedSummary(
                  selectedSummary === summary.id ? undefined : summary.id
                )
              }
              expanded={selectedSummary === summary.id}
            >
              <AccordionSummary
                className="flex items-stretch border-gray-200 border-t-px no-print"
                expandIcon={<ExpandMore className="no-print" />}
              >
                <div className="flex w-full no-print">
                  <span className="flex items-center flex-1 font-medium">
                    <Avatar src={summary.avatar} className="mr-2" />
                    {summary.fullName}
                  </span>
                  <span className="flex items-center justify-center flex-1">
                    <div>{summary.numComplete}</div>
                  </span>
                  <span className="flex items-center justify-end flex-1 text-right">
                    {summary.revenue.toCurrency()}
                  </span>
                  <span className="flex items-center justify-end flex-1 text-right">
                    {summary.netIncome.toCurrency()}
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails className="bg-white">
                <YearSummary details={summary} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default AdminInstructorPayoutSummary;
