import Button from 'components/button';
import { CheckIcon } from 'icons';
import React from 'react';
import { InstructorOnboardingRoutes } from 'utils/routing';
import { BaseStepProps, ControlClasses, TextClasses, TitleClasses } from '..';

const LearnAboutFees: React.FC<BaseStepProps> = (props) => {
  return (
    <div>
      <h1 className={TitleClasses}>Never pay out of pocket</h1>
      <p className={TextClasses}>
        When a client books with you, pool admission and a 16% booking fee are
        automatically deducted from the payment. You keep the rest.
      </p>
      <p>There are no monthly fees and you'll never need your credit card.</p>

      <div>
        <h5 className="mb-2">Admission & booking fees go to work for you.</h5>
        <ul className="space-y-3">
          <li className="flex items-center space-x-2">
            <CheckIcon className="text-green-500" width={20} />
            <span>Unparalleled liability insurance</span>
          </li>
          <li className="flex items-center space-x-2">
            <CheckIcon className="text-green-500" width={20} />
            <span>Dedicated customer and technical support</span>
          </li>
          <li className="flex items-center space-x-2">
            <CheckIcon className="text-green-500" width={20} />
            <span>Promotion to thousands of swimmers</span>
          </li>
        </ul>
      </div>

      <div className={ControlClasses}>
        <Button
          to={InstructorOnboardingRoutes.S04}
          variant="contained"
          color="primary"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default LearnAboutFees;
