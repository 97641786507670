import React from 'react';
import style from './style.module.scss';

const Controls = ({
  align = 'right',
  variant = 'inline',
  className,
  children,
}: {
  align?: 'left' | 'center' | 'right';
  variant?: 'inline' | 'block' | 'fill';
  className?: string;
  children?: any;
}) => {
  const justify = { left: 'start', center: 'center', right: 'end' };
  const baseInnerClasses = `flex flex-wrap flex-col-reverse flex-1 gap-2.5`;
  return (
    <div className={`flex py-3 justify-${justify[align]}${cls(className)}`}>
      <div
        className={`${style.controls} ${baseInnerClasses} ${style[align]} ${style[variant]} no-print`}
      >
        {children}
      </div>
    </div>
  );
};

export default Controls;
