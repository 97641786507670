import FacilitySchedulesIcon from '@mui/icons-material/CalendarViewDayOutlined';
import CalendarIcon from '@mui/icons-material/Today';
import { Tooltip } from '@mui/material';
import { AppointmentListSerializer } from 'api/Serializers/Appointments';
import Button from 'components/button';
import ListButton from 'components/button/list';
import Calendar from 'components/calendar';
import CalendarControls from 'components/calendar-controls';
import Controls from 'components/controls';
import Link from 'components/link';
import Modal from 'components/modal';
import { CAL_LIST_ITEM_NAME_PREFIX, DATE_FMT, QueryParams } from 'config';
import FacilityScheduleUpdates from 'containers/facility-updates';
import AppointmentDetail from 'features/appointment-detail';
import { ScrollOptions } from 'features/schedule';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import useSchedulableFacilities from 'hooks/useSchedulableFacilities';
import useTimeoutRefresh from 'hooks/useTimeoutRefresh';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { animateScroll, scroller } from 'react-scroll';
import { getAppointments, getScheduleRenderDate } from 'state/selectors';
import { fetchAppointments } from 'state/slice/appointments';
import { ScheduleMode } from 'state/slice/schedule';
import { AppDispatch } from 'state/store';
import { getUrlParam } from 'utils/routing';
import CalendarDay from './calendar-day';
import CalendarEventList from './event-list';

const callApi = debounce((dispatch: AppDispatch) => {
  return dispatch(fetchAppointments());
}, 350);

const ScheduleV1 = () => {
  useTimeoutRefresh();
  const { facilities, isFacilitiesLoading } = useSchedulableFacilities();
  const appointments = useSelector(getAppointments);
  const dispatch = useAppDispatch();
  const queryParams = useQuery();
  const history = useHistory();
  const match = useRouteMatch();
  const renderDate = useSelector(getScheduleRenderDate);

  const onDateClick = (
    events: AppointmentListSerializer[],
    date: string,
    performAction: boolean
  ) => {
    if (events.length > 0) {
      scroller.scrollTo(`${CAL_LIST_ITEM_NAME_PREFIX}-${date}`, {
        duration: 350,
        smooth: true,
        offset: -50,
      });
    }
  };

  useEffect(() => {
    callApi(dispatch);
  }, [renderDate]);

  const onCloseModal = () => history.push(location.pathname);
  const actionParam = queryParams.get(QueryParams.Action);
  const onAppointmentClose = () => {
    queryParams.delete(QueryParams.AppointmentId);
    const params =
      queryParams.toString() !== '' ? `?${queryParams.toString()}` : '';
    const url = `${match.url}${params}`;
    history.push(url);
  };

  return (
    <div className="flex flex-col flex-1 md:flex-row">
      <div className="flex-shrink px-1 md:bg-white md:w-96 xl:w-120">
        <div className="sticky max-w-lg px-2 pt-4 pb-6 mx-auto mt-4 bg-white rounded-lg shadow-lg md:px-0 md:mt-0 md:shadow-none top-16">
          <CalendarControls
            minDate={moment().subtract(6, 'months').format(DATE_FMT.DATE_KEY)}
            maxDate={moment().add(6, 'months').format(DATE_FMT.DATE_KEY)}
          />
          <Calendar
            DayComponent={(props) => (
              <CalendarDay
                {...props}
                events={[...appointments].filter(
                  (event) => event.date === props.date
                )}
                onClick={onDateClick}
              />
            )}
          />
          <div className="flex-col hidden mt-2 md:flex">
            <ListButton
              to={`${location.pathname}?${QueryParams.Action}=${ScheduleMode.FacilitySchedules}`}
              icon={<FacilitySchedulesIcon />}
              title="View my schedule hours"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between flex-1 md:flex-col-reverse">
        <div className="fixed bottom-0 left-0 right-0 z-50 flex justify-center md:sticky">
          <div className="flex flex-1 mx-2 mb-6 space-x-2 bg-white border-t border-gray-200 rounded-full shadow-lg md:mx-3">
            <div className="flex justify-end flex-1 px-3 py-2 space-x-3">
              <Tooltip title="Jump to calendar" placement="top">
                <button
                  className="btn-icon blue sm:hidden"
                  onClick={() => animateScroll.scrollToTop(ScrollOptions)}
                >
                  <CalendarIcon color="inherit" fontSize="inherit" />
                </button>
              </Tooltip>
              <Tooltip title="View pool schedules" placement="top">
                <div className="flex">
                  <Link
                    className="btn-icon blue"
                    to={`${location.pathname}?${QueryParams.Action}=${ScheduleMode.FacilitySchedules}`}
                  >
                    <FacilitySchedulesIcon color="inherit" fontSize="inherit" />
                  </Link>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <CalendarEventList events={appointments} />
        <AppointmentDetail
          id={getUrlParam(QueryParams.AppointmentId)}
          onClose={onAppointmentClose}
        />
        <Modal
          name="Host — View facility schedules"
          open={actionParam === ScheduleMode.FacilitySchedules}
          onClose={onCloseModal}
        >
          {facilities.map((facility) => (
            <FacilityScheduleUpdates key={facility.slug} facility={facility} />
          ))}
          <Controls>
            <Button onClick={onCloseModal} color="primary" variant="contained">
              Close
            </Button>
          </Controls>
        </Modal>
      </div>
    </div>
  );
};

export default ScheduleV1;
