import RefreshIcon from '@mui/icons-material/Refresh';
import * as Sentry from '@sentry/react';
import Article from 'components/article';
import Button from 'components/button';
import Card from 'components/card';
import Controls from 'components/controls';
import Footer from 'components/footer';
import Hero from 'components/hero';
import React from 'react';

const Error = ({ error, componentStack, resetError }) => {
  return (
    <>
      {error?.name === 'ChunkLoadError' ? (
        <div>
          <Hero
            title="New Version Available"
            subtitle="We've recently made some changes and would love for you to get the latest! Please refresh this page to proceed."
            background="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogImFzc2V0cy9pbWFnZXMvaGVyb3MvaW5kb29yLXBvb2wuanBnIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogMTQ0MCwgImhlaWdodCI6IDgwMH19fQ=="
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => window.location.reload()}
              icon={<RefreshIcon className="text-white" />}
            >
              Refresh!
            </Button>
          </Hero>
          <Footer />
        </div>
      ) : (
        <div>
          <Hero
            title="Well that didn't go swimmingly..."
            subtitle="Our app hit an error and couldn't recover — the team has been alerted and will try to fix it asap! Try your request once more."
            background="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hlcm9zL2RhcmstcG9vbC5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9"
            backgroundPosition="50% 30%"
          >
            <div className="mt-8">
              <Controls align="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => window.location.reload()}
                >
                  Refresh and try again
                </Button>
              </Controls>
            </div>
          </Hero>
          <Article theme="blue" title="">
            <Card maxWidth="lg" title="We've been notified of the problem.">
              <div className="text-left text-gray-700">
                <p>
                  When an error like this happens, an email is sent to one of
                  our team members for further investigation.
                </p>
                <p>
                  If you're a logged-in user, your username is sent along with
                  the report and someone may be in touch with updates or
                  questions.
                </p>
                <h2 className="flex items-center pb-4 m-0 text-lg font-bold text-gray-700 font-body">
                  Ready to try again? Refresh this page!
                </h2>
                <p>
                  This error is unrecoverable, so you'll have to refresh the
                  page once you're ready.
                </p>
                <div className="text-center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </Card>
          </Article>
          <Footer />
        </div>
      )}
    </>
  );
};

const ErrorWrapper = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={(props) => <Error {...props} />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorWrapper;
