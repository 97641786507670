import Button from 'components/button';
import React from 'react';

const EarningsHeader = () => {
  return (
    <div className="w-full flex flex-col sm:flex-row sm:justify-between">
      <div className="text-left">
        <div className="my-1 font-semibold font-body uppercase text-gray-700 leading-none">
          Propel HQ Inc.
        </div>
        <div className="my-1 font-light font-body text-gray-600 leading-none">
          10 Dundas Street East
        </div>
        <div className="my-1 font-light font-body text-gray-600 leading-none">
          Suite 600
        </div>
        <div className="my-1 font-light font-body text-gray-600 leading-none">
          Toronto, ON M6B 2G9
        </div>
      </div>
      <div className="flex justify-center my-4 items-center no-print">
        <Button variant="contained" onClick={() => window.print()}>
          Print/Save as PDF
        </Button>
      </div>
    </div>
  );
};

export default EarningsHeader;
