import { Tense } from 'api/Serializers/Schedules';
import { DATE_FMT } from 'config';
import moment from 'moment-timezone';
import React from 'react';
import { getDateTense } from 'utils/date';

const DateBox = ({ date, events, onClick, highlight = false }) => {
  const mdt = moment(date, DATE_FMT.DATE_KEY);
  const tense = getDateTense(date);
  const schedule = events;
  const first = schedule[0];
  const last = schedule[schedule.length - 1];
  const numBooked = events.filter((e) => e.cancelled === false).length;
  const tenseClasses = highlight
    ? 'border-blue-400 border-2 shadow'
    : tense === Tense.Today
    ? 'border-blue-400'
    : tense === Tense.Past
    ? 'opacity-50'
    : '';
  const loading = false;
  const isLoadingDates = false;

  const handleClick = (evt) => {
    if (onClick) {
      onClick(schedule, date);
    }
  };
  return (
    <div
      className={`group flex flex-col items-center justify-start h-12 p-0 m-px leading-none cursor-pointer hover:bg-gray-200 bg-gray-50 border border-transparent rounded ${tenseClasses}`}
      onClick={handleClick}
    >
      <div className="text-sm font-semibold text-gray-600 group-hover:text-gray-700">
        {mdt.format(DATE_FMT.D)}
      </div>
      {loading ? (
        <div className="flex justify-center w-full">
          <div className="w-8 h-2 bg-blue-300 rounded-full" />
        </div>
      ) : isLoadingDates ? (
        <span />
      ) : schedule.length > 0 ? (
        <React.Fragment>
          <div
            className="flex px-0.5 py-px font-light leading-none rounded-lg item-center text-xxs"
            style={{ color: '#5992B1', backgroundColor: '#ECF4F8' }}
          >
            {schedule.length > 1
              ? `${moment(first.start).tz(first.timezone).format('h')}-`
              : ''}
            {moment(last.start).tz(last.timezone).format(DATE_FMT.HOUR_A)}
          </div>
          {numBooked > 0 && (
            <div className="flex px-2 py-px font-bold leading-none text-green-600 bg-green-200 rounded-lg item-center text-xxs">
              {numBooked}
            </div>
          )}
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default DateBox;
