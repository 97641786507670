import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import TodayIcon from '@mui/icons-material/TodayOutlined';
import { Proposal } from 'api/Serializers/Proposals';
import Button from 'components/button';
import Controls from 'components/controls';
import Row from 'components/row';
import { DATE_FMT, UserType } from 'config';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail } from 'state/selectors';

const ProposalCancel = ({
  proposal,
  goBack,
  onProposalCancel,
}: {
  proposal: Proposal;
  goBack(): void;
  onProposalCancel(): void;
}) => {
  const user = useSelector(getAccountDetail);
  const action = user.type === UserType.Client ? 'Decline' : 'Cancel';
  return (
    <div>
      <span>
        Are you sure you wish to {action.toLowerCase()} this proposed{' '}
        {proposal.activity.appointmentNoun.toLowerCase()}?
      </span>
      <Row height={3} className="flex">
        <TodayIcon className="mr-2 text-blue-400" />
        <span>
          {moment(proposal.start)
            .tz(proposal.timezone)
            .format(DATE_FMT.DOW_MONTH_D_TIME_A)}
        </span>
      </Row>
      <Row height={3} className="flex">
        <AccountCircleIcon className="mr-2 text-blue-400" />
        <span>
          Proposed {proposal.activity.name} with {proposal.client.fullName}
        </span>
      </Row>
      <Controls>
        <Button onClick={goBack}>Go back</Button>
        <Button
          onClick={onProposalCancel}
          color="secondary"
          variant="contained"
        >
          Yes, {action.toLowerCase()} proposal
        </Button>
      </Controls>
    </div>
  );
};

export default ProposalCancel;
