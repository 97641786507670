import Modal from 'components/modal';
import { UserType } from 'config';
import { LoginSuccessfulMessage } from 'lang/en/Snackbars';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getIsIdentified } from 'state/selectors';
import LoginForm from './login';
import SignUpForm from './sign-up';

type Form = 'login' | 'sign-up';

/**
 * Tracks where the authentication took place for analytics purposes
 */
export type AuthFlow =
  | 'BookingFlow'
  | 'HeaderFlow'
  | 'FavouritesFlow'
  | 'SignInPage'
  | 'SignUpPage';

const Authentication = ({
  initialForm,
  isOpen,
  onClose,
  flow,
  message = undefined,
  defaultUserType = undefined,
}: {
  isOpen: boolean;
  onClose(isAuthenticated: boolean): void;
  flow: AuthFlow;
  initialForm?: Form;
  message?: string;
  defaultUserType?: UserType;
}) => {
  const location = useLocation();
  const [form, setForm] = useState<Form>(initialForm ?? 'sign-up');
  const isIdentified = useSelector(getIsIdentified);

  const handleClose = (isAuthenticated: boolean): void => {
    onClose(isAuthenticated);
    if (form !== initialForm) {
      setForm(initialForm ?? 'sign-up');
    }
  };

  useEffect(() => {
    if (isIdentified && isOpen) {
      enqueueSnackbar(LoginSuccessfulMessage);
      handleClose(isIdentified);
    }
  }, [isIdentified]);

  // Close modal on navigation to "forgot password" page
  useEffect(() => {
    if (isOpen) {
      handleClose(false);
    }
  }, [location]);

  return (
    <Modal
      name="Authenticate"
      title={
        form === 'login'
          ? 'Sign in to your account'
          : 'Create your Propel account'
      }
      open={isOpen}
      onClose={() => handleClose(false)}
      maxWidth="xs"
      fullWidth
      disableEnforceFocus
    >
      <div className="w-full max-w-md mx-auto">
        {message}
        {form === 'login' ? (
          <>
            <LoginForm flow={flow} />
            <p className="mt-8 text-sm text-center text-gray-600">
              Not registered yet?{' '}
              <button className="link" onClick={() => setForm('sign-up')}>
                Create an account
              </button>
            </p>
          </>
        ) : form === 'sign-up' ? (
          <>
            <SignUpForm flow={flow} defaultUserType={defaultUserType} />
            <p className="mt-8 text-sm text-center text-gray-600">
              Already have an account?{' '}
              <button className="link" onClick={() => setForm('login')}>
                Sign in now
              </button>
            </p>
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export default Authentication;
