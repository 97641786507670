import ReactGeoSuggest, { Location } from '@ubilabs/react-geosuggest';
import { PlaceIcon } from 'icons';
import { Locality } from 'models/geo';
import React, { forwardRef } from 'react';
import { geoSuggestToLocality } from 'utils/geo';

type QueryType =
  | 'geocode'
  | 'address'
  | 'establishment'
  | '(regions)'
  | '(cities)';

interface Props {
  ref?: any;
  id?: string;
  types: QueryType[];
  name?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  initialValue?: string;
  error?: boolean;
  onBlur?(value: string): void;
  onChange?(value): void;
  onSuggestSelect?(selected: Locality): void;
  disabled?: boolean;
  locationBias?: google.maps.places.LocationBias;
  location?: google.maps.LatLng;
}

const InputSuggestItem = (props) => {
  return (
    <span className="flex items-center">
      <span className="flex items-center mr-2 opacity-75 md:mr-2 sm:mr-0">
        <PlaceIcon width={24} />
      </span>
      <span className="w-full text-sm font-normal tracking-wide text-left text-inherit min-w-20">
        {props.label}
      </span>
    </span>
  );
};

const InputSuggestPlace = forwardRef(function InputSuggestPlace(
  { className, onSuggestSelect, ...props }: Props,
  ref
) {
  const handleSuggestSelect = (suggest: Location) => {
    if (!suggest) {
      onSuggestSelect(undefined);
    } else if (suggest.isFixture) {
      // Poor workaround that prevents the first suggest item
      // from being selected on an onBlur action. For a demo,
      // add console.log(suggest) here, type some text, then
      // click outside of the input field — the first suggestion
      // is auto fired here, which is not a good outcome.
      // This does effectively kill having fixtures though.
    } else {
      onSuggestSelect(geoSuggestToLocality(suggest));
    }
  };

  return (
    <>
      <ReactGeoSuggest
        ref={ref}
        country={'ca'}
        className={`w-full ${className ?? ''}`}
        renderSuggestItem={InputSuggestItem}
        suggestsClassName="bg-white shadow-lg m-0 p-0 absolute w-full z-10"
        suggestItemClassName="font-base p-3 cursor-pointer text-gray-800 hover:bg-background hover:text-gray-800"
        suggestsHiddenClassName="hidden"
        suggestItemActiveClassName="bg-blue-200 text-gray-800"
        onSuggestSelect={handleSuggestSelect}
        {...props}
      />
    </>
  );
});

export default InputSuggestPlace;
