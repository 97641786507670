import { LocationFilter, WeekDayTimeFilter } from 'api/Serializers/Accounts';
import { ActivitySegment } from 'api/Serializers/Activities';
import InputSuggestPlace from 'components/input-suggest-place';
import Select, { components, SelectOption } from 'components/select';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSearchSegments } from 'state/selectors';

const timeOptions: SelectOption<number>[] = [
  {
    label: '6am',
    value: 6,
  },
  {
    label: '7am',
    value: 7,
  },
  {
    label: '8am',
    value: 8,
  },
  {
    label: '9am',
    value: 9,
  },
  {
    label: '10am',
    value: 10,
  },
  {
    label: '11am',
    value: 11,
  },
  {
    label: '12pm',
    value: 12,
  },
  {
    label: '1pm',
    value: 13,
  },
  {
    label: '2pm',
    value: 14,
  },
  {
    label: '3pm',
    value: 15,
  },
  {
    label: '4pm',
    value: 16,
  },
  {
    label: '5pm',
    value: 17,
  },
  {
    label: '6pm',
    value: 18,
  },
  {
    label: '7pm',
    value: 19,
  },
  {
    label: '8pm',
    value: 20,
  },
  {
    label: '9pm',
    value: 21,
  },
  {
    label: '10pm',
    value: 22,
  },
];

const RADIUS_OPTIONS = [
  { label: '10km', value: 10 },
  { label: '25km', value: 25 },
  { label: '50km', value: 50 },
];

const MAX_PRICE_OPTIONS = [
  { label: '$60', value: 60 },
  { label: '$70', value: 70 },
  { label: '$80', value: 80 },
  { label: '$90', value: 90 },
  { label: '$100', value: 100 },
  { label: '$110', value: 110 },
  { label: '$120', value: 120 },
  { label: '$130', value: 130 },
  { label: '$140', value: 140 },
  { label: '$150', value: 150 },
];

export const defaultTimeFilters: WeekDayTimeFilter[] = Array.from(
  new Array(7),
  (k, i) => ({
    weekDay: i + 1,
    start: timeOptions[0].value,
    end: timeOptions[timeOptions.length - 1].value,
  })
);

export const SegmentFilter = ({
  segment,
  onChange,
}: {
  segment: ActivitySegment;
  onChange(segment: ActivitySegment): void;
}) => {
  const segments = useSelector(getSearchSegments);
  return (
    <div className="w-full space-y-3">
      <div className="flex items-baseline justify-between">
        <h4>Swimmer type</h4>
      </div>
      <Select
        options={segments}
        name="segment"
        value={segment ?? null}
        onChange={(opt: ActivitySegment) => onChange(opt)}
        components={{
          Option: (props) =>
            !props.innerProps ? null : (
              <components.Option {...props} className="p-3">
                <div>{props.label}</div>
                <span
                  className={`"text-sm ${
                    props.isSelected ? 'text-gray-50' : 'text-gray-600'
                  }`}
                >
                  {props.data?.description}
                </span>
              </components.Option>
            ),
        }}
        isClearable={true}
        placeholder="Any swimmer type..."
      />
    </div>
  );
};

export const TimeAndWeekDayFilter = ({
  times,
  onChange,
}: {
  times: WeekDayTimeFilter[];
  onChange(newFilter: WeekDayTimeFilter[]): void;
}) => {
  const timesState = times ?? [];
  const firstSetValue = timesState.find(
    (filter) => filter && filter.start !== undefined && filter.end !== undefined
  );
  const isCustomized = timesState.length > 0;
  const start = isCustomized
    ? timeOptions.find((opt) => opt.value === firstSetValue.start)
    : timeOptions[0];
  const end = isCustomized
    ? timeOptions[
        timeOptions.findIndex((opt) => opt.value === firstSetValue.end)
      ]
    : timeOptions[timeOptions.length - 1];

  const startOptions = timeOptions.slice(0, -1);
  const endOptions = timeOptions.slice(
    timeOptions.findIndex((val) => val.value === start.value)
  );

  const getIsWeekdayActive = (
    weekDay: number,
    includeEmpty: boolean = true
  ) => {
    const filter = timesState.find((p) => p.weekDay === weekDay);
    return (
      (includeEmpty && timesState.length === 0) ||
      (filter && filter.start !== undefined && filter.end !== undefined)
    );
  };

  const toggleWeekDay = (weekDay: number) => {
    const isActive = getIsWeekdayActive(weekDay);
    let newFilters;
    if (isActive) {
      let currentFilters;
      if (timesState.length === 0) {
        currentFilters = defaultTimeFilters;
      } else if (timesState.length === 1) {
        currentFilters = defaultTimeFilters.map((elt) => ({
          weekDay: elt.weekDay,
          start: start.value,
          end: end.value,
        }));
      } else {
        currentFilters = timesState;
      }
      newFilters = currentFilters.filter(
        (filter) => filter.weekDay !== weekDay
      );
    } else {
      newFilters = [
        ...timesState,
        {
          weekDay,
          start: start.value,
          end: end.value,
        },
      ];
    }
    newFilters.sort((a, b) => a.weekDay - b.weekDay);
    onChange(newFilters);
  };

  return (
    <div className="w-full space-y-3">
      <div className="flex items-baseline justify-between">
        <h4>Day and time</h4>
      </div>
      <div className="flex items-center space-x-4 py-0.5">
        <Select
          name="start"
          value={start}
          options={startOptions}
          onChange={(opt) => {
            const newFilter: WeekDayTimeFilter[] = (
              timesState.length > 0 ? timesState : defaultTimeFilters
            ).map((filter) => ({
              ...filter,
              start: opt.value,
              end: opt.value > end.value ? opt.value : end.value,
            }));
            onChange(newFilter);
          }}
          className="flex-1"
        />
        <span>to</span>
        <Select
          name="end"
          value={end}
          options={endOptions}
          onChange={(opt) => {
            const newFilter: WeekDayTimeFilter[] = (
              timesState.length > 0 ? timesState : defaultTimeFilters
            ).map((filter) => ({ ...filter, end: opt.value }));
            onChange(newFilter);
          }}
          className="flex-1"
        />
      </div>
      <div className="w-full button-group">
        <button
          onClick={() => toggleWeekDay(1)}
          className={`!px-1.5 ${getIsWeekdayActive(1) ? 'active' : ''}`}
        >
          Sun
        </button>
        <button
          onClick={() => toggleWeekDay(2)}
          className={`!px-1.5 ${getIsWeekdayActive(2) ? 'active' : ''}`}
        >
          Mon
        </button>
        <button
          onClick={() => toggleWeekDay(3)}
          className={`!px-1.5 ${getIsWeekdayActive(3) ? 'active' : ''}`}
        >
          Tue
        </button>
        <button
          onClick={() => toggleWeekDay(4)}
          className={`!px-1.5 ${getIsWeekdayActive(4) ? 'active' : ''}`}
        >
          Wed
        </button>
        <button
          onClick={() => toggleWeekDay(5)}
          className={`!px-1.5 ${getIsWeekdayActive(5) ? 'active' : ''}`}
        >
          Thu
        </button>
        <button
          onClick={() => toggleWeekDay(6)}
          className={`!px-1.5 ${getIsWeekdayActive(6) ? 'active' : ''}`}
        >
          Fri
        </button>
        <button
          onClick={() => toggleWeekDay(7)}
          className={`!px-1.5 ${getIsWeekdayActive(7) ? 'active' : ''}`}
        >
          Sat
        </button>
      </div>
    </div>
  );
};

export const MaxPriceFilter = ({
  maxPrice,
  onChange,
}: {
  maxPrice: number;
  onChange(maxPrice: number): void;
}) => {
  return (
    <div className="w-full space-y-3">
      <div className="flex items-baseline justify-between">
        <h4>Max price</h4>
      </div>
      <Select
        className="w-full"
        name="maxPrice"
        options={MAX_PRICE_OPTIONS}
        value={MAX_PRICE_OPTIONS.find((opt) => opt.value === maxPrice) ?? null}
        onChange={(opt) => onChange(opt?.value)}
        isClearable
        menuPlacement="top"
      />
    </div>
  );
};

export const RadiusFilter = ({
  location,
  onChange,
}: {
  location: LocationFilter;
  onChange(location: LocationFilter): void;
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setIsInvalid(location.latlng === undefined);
  }, [location.latlng]);

  return (
    <div className="w-full space-y-3">
      <div className="flex items-baseline justify-between">
        <h4>Location</h4>
      </div>
      <div className="flex items-center gap-4">
        <Select
          className="w-full"
          name="radius"
          options={RADIUS_OPTIONS}
          value={
            RADIUS_OPTIONS.find((opt) => opt.value === location.radius) ?? null
          }
          onChange={(opt) =>
            onChange({
              ...location,
              radius: opt.value,
            })
          }
        />
        <div className="pr-4">from</div>
      </div>
      <div>
        <InputSuggestPlace
          inputClassName={isInvalid ? 'input-error' : ''}
          initialValue={location.address}
          types={['geocode']}
          onBlur={(value) => {
            // Do this on a timeout because onBlur fires before onSuggestSelect
            timeoutRef.current = setTimeout(
              () =>
                onChange({ ...location, latlng: undefined, address: value }),
              250
            );
          }}
          onSuggestSelect={(elt) => {
            if (elt?.latlng === undefined) {
              return;
            }
            if (timeoutRef.current !== null) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = null;
            }
            onChange({
              latlng: elt.latlng,
              radius: location.radius,
              address: elt.description,
            });
          }}
        />
        {isInvalid && (
          <div className="input-error-message">
            Please select an address from the options presented
          </div>
        )}
      </div>
    </div>
  );
};
