import React from 'react';
import { Redirect as RouterRedirect } from 'react-router';
import { trailingSlash } from 'utils/string';

interface RedirectProps {
  to: string | ToProps;
  from?: string;
  target?: string;
  push?: boolean;
}

interface ToProps {
  pathname: string;
  search?: string;
  hash?: string;
  state?: any;
}

const Redirect: React.FunctionComponent<RedirectProps> = ({ to, ...rest }) => (
  <RouterRedirect to={trailingSlash(to)} {...rest} />
);
export default Redirect;
