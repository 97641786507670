import {
  AccountIcon,
  CertificationIcon,
  HomeIcon,
  MessagesIcon,
  OnboardingIcon,
  PayoutIcon,
  ReviewIcon,
  ScheduleIcon,
  SettingsIcon,
  TOSIcon,
  WarningIcon,
} from 'icons';

import { Status } from 'api/Serializers/Accounts';
import InstructorAccount from 'api/Serializers/Accounts/Instructor';
import { MenuItem } from 'components/account/menu';
import { LocationPriceAction, SHARED_ROUTES } from 'utils/routing';
import { isPathnameInRouteRoot, unreadConversations } from '../utils';
import Assistance from './assistance';
import Certifications from './certifications';
import Dashboard from './dashboard';
import PendingDashboard from './dashboard/pending';
import Earnings from './earnings';
import Messages from './messages';
import InstructorOnboardingContainer from './onboarding';
import OrientationCall from './orientation-call';
import Profile from './profile';
import Reviews from './reviews';
import Schedule from './schedule';
import Settings from './settings';

export const INSTRUCTOR_ROUTES = {
  ...SHARED_ROUTES,
  CERTIFICATES: {
    PATH: '/account/certificates',
    ROOT: '/account/certificates',
  },
  SELECT_LOCATION: {
    PATH: '/account/select-location/:mode?/:action?',
    ROOT: '/account/select-location',
    nav: (mode?: string, action?: LocationPriceAction) =>
      `/account/select-location${
        !!mode ? (!!action ? `/${mode}/${action}` : `/${mode}`) : ''
      }`,
  },
  ORIENTATION_CALL: {
    PATH: '/account/orientation-call',
    ROOT: '/account/orientation-call',
  },
  CLIENTS: {
    PATH: '/account/clients',
    ROOT: '/account/clients',
  },
  ADD_LOCATION: {
    PATH: '/account/settings',
    /** /account/settings */
    ROOT: '/account/settings',
  },
  /** /account/profile */
  PROFILE: {
    PATH: '/account/profile',
    ROOT: '/account/profile',
  },
  /** /account/earnings */
  EARNINGS: {
    PATH: '/account/earnings/:mode?',
    ROOT: '/account/earnings',
    DETAIL: (id: string | number) => `/account/earnings/${id}`,
  },
  ONBOARDING: {
    PATH: '/account/onboarding/:mode?',
    ROOT: '/account/onboarding',
    /** Background stage */
    CONTINUE: '/account/onboarding/continue',
    WELCOME: '/account/onboarding/welcome',
    EVERYTHING_YOU_NEED: '/account/onboarding/everything-you-need',
    WHAT_DESCRIBES_YOU: '/account/onboarding/what-describes-you',
    HOW_FEES_WORK: '/account/onboarding/how-fees-work',
    /** Profile stage */
    BASIC_INFO: '/account/onboarding/basic-info',
    TEACHING_EXPERIENCE: '/account/onboarding/teaching-experience',
    QUALIFICATIONS: '/account/onboarding/qualifications',
    CLIENT_EXPERIENCE: '/account/onboarding/client-experience',
    UNIQUE_EXPERIENCE: '/account/onboarding/unique-experience',
    BASIC_PROFILE: '/account/onboarding/basic-profile',
    /** Appointments stage */
    SET_LOCATION_AND_PRICE: '/account/onboarding/set-teaching-preferences',
    SET_FACILITY: '/account/onboarding/browse-facilities',
    SET_PRICE: '/account/onboarding/prices',
    /** Edge cases */
    TOO_YOUNG: '/account/onboarding/too-young',
    NO_QUALIFICATIONS: '/account/onboarding/no-qualifications',
    INEXPERIENCED: '/account/onboarding/learn-about-experience',
  },
};

export const InstructorTermsOfServiceMenuItems: MenuItem[] = [
  {
    id: 'terms-of-service',
    route: INSTRUCTOR_ROUTES.TERMS_OF_SERVICE,
    text: 'Terms of Service',
    Icon: TOSIcon,
    isActive: () => true,
  },
];

const goodStatus = [
  Status.Active,
  Status.Inactive,
  Status.Done,
  Status.Hiatus,
  Status.Stale,
  Status.Unlisted,
];
const pendingStatues = [Status.Pending, Status.Unqualified];
const poorStatuses = [
  Status.Banned,
  Status.Pending,
  Status.Rejected,
  Status.Suspended,
  Status.Unqualified,
];
const accountIsPendingOrBanned = (account: InstructorAccount) =>
  poorStatuses.indexOf(account.status) > -1;
const accountIsPending = (account: InstructorAccount) =>
  pendingStatues.indexOf(account.status) > -1;
const accountIsActive = (account: InstructorAccount) =>
  goodStatus.indexOf(account.status) > -1;

export const InstructorOrientationMenuItems: MenuItem[] = [
  {
    id: 'dashboard',
    text: 'Dashboard',
    Icon: HomeIcon,
    route: INSTRUCTOR_ROUTES.DASHBOARD,
    Component: PendingDashboard,
    isActive: (pathname) => pathname === SHARED_ROUTES.DASHBOARD.ROOT,
  },
  {
    id: 'onboarding',
    text: 'Onboarding',
    Icon: OnboardingIcon,
    route: INSTRUCTOR_ROUTES.ONBOARDING,
    Component: InstructorOnboardingContainer,
    isActive: isPathnameInRouteRoot,
  },
  {
    id: 'schedule-a-call',
    route: INSTRUCTOR_ROUTES.ORIENTATION_CALL,
    Component: OrientationCall,
    isActive: isPathnameInRouteRoot,
  },
  {
    id: 'schedule',
    route: INSTRUCTOR_ROUTES.SCHEDULE,
    text: 'Schedule',
    Icon: ScheduleIcon,
    Component: Schedule,
    isActive: isPathnameInRouteRoot,
    isHidden: accountIsPendingOrBanned,
    isFixedHeightRoute: true,
  },
  {
    id: 'settings',
    route: INSTRUCTOR_ROUTES.SETTINGS,
    text: 'Settings',
    Icon: SettingsIcon,
    Component: Settings,
    isActive: isPathnameInRouteRoot,
    isHidden: accountIsPendingOrBanned,
  },
];

export const InstructorBannedMenuItems: MenuItem[] = [
  {
    id: 'notice',
    route: INSTRUCTOR_ROUTES.DASHBOARD,
    text: 'Notice',
    Icon: WarningIcon,
    isActive: () => true,
    listSelector: unreadConversations,
  },
];

export const InstructorMenuItems: MenuItem[] = [
  {
    id: 'dashboard',
    route: INSTRUCTOR_ROUTES.DASHBOARD,
    text: 'Dashboard',
    Icon: HomeIcon,
    Component: Dashboard,
    isActive: (pathname) => pathname === SHARED_ROUTES.DASHBOARD.ROOT,
    isHidden: accountIsPendingOrBanned,
  },
  {
    id: 'onboarding',
    route: INSTRUCTOR_ROUTES.DASHBOARD,
    text: 'Onboarding',
    Icon: HomeIcon,
    Component: Dashboard,
    isHidden: (account: InstructorAccount) =>
      accountIsPending(account) === false,
    isActive: (pathname: string) =>
      pathname.indexOf(INSTRUCTOR_ROUTES.SCHEDULE.ROOT) === 0,
  },
  {
    id: 'assistance',
    route: INSTRUCTOR_ROUTES.ASSISTANCE,
    Component: Assistance,
  },
  {
    id: 'schedule-a-call',
    route: INSTRUCTOR_ROUTES.ORIENTATION_CALL,
    Component: OrientationCall,
    isActive: isPathnameInRouteRoot,
  },
  {
    id: 'schedule',
    route: INSTRUCTOR_ROUTES.SCHEDULE,
    text: 'Schedule',
    Icon: ScheduleIcon,
    Component: Schedule,
    isActive: isPathnameInRouteRoot,
    isHidden: accountIsPendingOrBanned,
    isFixedHeightRoute: true,
  },
  {
    id: 'messages',
    route: INSTRUCTOR_ROUTES.MESSAGES,
    text: 'Messages',
    Icon: MessagesIcon,
    Component: Messages,
    isActive: isPathnameInRouteRoot,
    listSelector: unreadConversations,
    isHidden: (account: InstructorAccount) =>
      account.messagingDisabled || accountIsPendingOrBanned(account),
    isFixedHeightRoute: true,
  },
  {
    id: 'profile',
    route: INSTRUCTOR_ROUTES.PROFILE,
    text: 'Profile',
    Icon: AccountIcon,
    Component: Profile,
    isActive: isPathnameInRouteRoot,
    isHidden: accountIsPendingOrBanned,
  },
  {
    id: 'reviews',
    route: INSTRUCTOR_ROUTES.REVIEWS,
    text: 'Reviews',
    Icon: ReviewIcon,
    Component: Reviews,
    isActive: isPathnameInRouteRoot,
    isHidden: accountIsPendingOrBanned,
  },
  {
    id: 'certifications',
    route: INSTRUCTOR_ROUTES.CERTIFICATES,
    text: 'Certification',
    Icon: CertificationIcon,
    Component: Certifications,
    isActive: isPathnameInRouteRoot,
    isHidden: accountIsPendingOrBanned,
  },
  {
    id: 'earnings',
    route: INSTRUCTOR_ROUTES.EARNINGS,
    text: 'Earnings',
    Icon: PayoutIcon,
    Component: Earnings,
    isActive: isPathnameInRouteRoot,
    isHidden: (account: InstructorAccount) => account.status === Status.Pending,
  },
  {
    id: 'settings',
    route: INSTRUCTOR_ROUTES.SETTINGS,
    text: 'Settings',
    Icon: SettingsIcon,
    Component: Settings,
    isActive: isPathnameInRouteRoot,
    isHidden: accountIsPendingOrBanned,
  },
];
