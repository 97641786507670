import { NewProposal } from 'api/Serializers/Proposals';
import { ScheduleTimeSerializer } from 'api/Serializers/Schedules';
import InstructorBaseTimeSlot from 'features/schedule/as-instructor/base-time-slot';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getProposalClient,
  getScheduleAppointmentProduct,
} from 'state/selectors';
import { removeProposal } from 'state/slice/proposals';

const NewProposedTimeSlot = ({
  timeSlot,
  ...proposal
}: NewProposal & { timeSlot: ScheduleTimeSerializer }) => {
  const dispatch = useAppDispatch();
  const aptProduct = useSelector(getScheduleAppointmentProduct);
  const client = useSelector(getProposalClient);
  const title = `* ${
    client.fullName
  } — Proposed ${aptProduct.activity.appointmentNoun.toLowerCase()}`;
  const classes =
    'font-semibold bg-orange-50 text-orange-900 hover:bg-orange-100 hover:text-orange-800';

  const handleClick = () => {
    dispatch(removeProposal({ datetime: timeSlot.datetime }));
  };
  return (
    <InstructorBaseTimeSlot
      onClick={handleClick}
      datetime={timeSlot.datetime}
      timezone={aptProduct.timezone}
      description={title}
      classes={classes}
    />
  );
};

export default NewProposedTimeSlot;
