import api from 'api';
import InstructorAccount, {
  Certification,
} from 'api/Serializers/Accounts/Instructor';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import CertificationCard from 'components/certification-card';
import CreateCertification from 'components/certification-card/create';
import Controls from 'components/controls';
import Link from 'components/link';
import Modal from 'components/modal';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'state/selectors';
import { certificationsUpdated } from 'state/slice/account';
import { EXTERNAL_ROUTES } from 'utils/routing';
import { BaseStepProps } from '../onboarding';

const AccountCertificationsComponent: React.FC<BaseStepProps> = () => {
  const account = useSelector(getCurrentUser) as InstructorAccount;
  const askForLifeguarding = account.address.regionCode === 'ON';
  const { certifications } = account;
  const [addingCert, setAddingCert] = useState(false);
  const [addCertType, setCertType] = useState<
    'instructor' | 'lifeguard' | undefined
  >(askForLifeguarding ? undefined : 'instructor');
  const [newCert, setNewCert] = useState<Certification>();
  const dispatch = useAppDispatch();
  const onInstructorCertComplete = (cert: Certification) => {
    setNewCert(cert);
  };

  const onAddCertSubmit = async (evt) => {
    const data = {
      ...newCert,
    };
    const response = await api.account.certifications.create(
      account.username,
      data
    );
    dispatch(certificationsUpdated(response.data));
    setNewCert(undefined);
    setAddingCert(false);
    setCertType(askForLifeguarding ? undefined : 'instructor');
  };

  const closeModal = () => {
    setNewCert(undefined);
    setAddingCert(false);
    setCertType(askForLifeguarding ? undefined : 'instructor');
  };

  return (
    <Dashboard title="Certifications">
      <p>
        Check out our{' '}
        <Link to={EXTERNAL_ROUTES.BLOG.INSTRUCTOR_PERKS}>Perks Page</Link> for
        discounts from local certification programs.
      </p>
      <div className="flex flex-wrap justify-evenly sm:-mx-4">
        {certifications.length > 0 ? (
          certifications.map(
            (certification, i) =>
              certification && (
                <div key={i} className="p-4">
                  <CertificationCard
                    certification={certification}
                    account={account}
                  />
                </div>
              )
          )
        ) : (
          <div className="z-10 flex flex-col h-48 p-4 my-4 bg-white border border-gray-400 rounded-lg shadow-md w-80">
            <div className="my-0 font-semibold text-gray-800 font-body">
              No Certs on file
            </div>
            <div className="my-0 font-medium text-gray-600">
              Add your certification details to stay legitimate
            </div>
          </div>
        )}
      </div>
      <hr className="border-t-0 border-gray-400" />
      <Controls>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddingCert(true)}
        >
          Add New
        </Button>
      </Controls>
      <Modal
        name="Instructor — Add new certificate"
        maxWidth="sm"
        title={`Add New${
          addCertType ? ` ${addCertType.capitalize()}` : ''
        } Certificate`}
        open={addingCert}
        onClose={closeModal}
        allowOverflow
      >
        {askForLifeguarding && !addCertType ? (
          <div>
            <p className="mb-8 font-bold text-center text-gray-700 text-md">
              Is this your teaching or lifeguarding certificate?
            </p>
            <div className="flex justify-evenly">
              <Button
                variant="contained"
                onClick={() => setCertType('instructor')}
              >
                Teaching
              </Button>
              <Button
                variant="contained"
                onClick={() => setCertType('lifeguard')}
              >
                Lifeguarding
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <CreateCertification
              type={addCertType}
              onComplete={onInstructorCertComplete}
            />
            <Controls>
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                disabled={!newCert}
                variant="contained"
                color="primary"
                onClick={onAddCertSubmit}
              >
                Submit
              </Button>
            </Controls>
          </div>
        )}
      </Modal>
    </Dashboard>
  );
};

export default AccountCertificationsComponent;
