import Button from 'components/button';
import { ButtonProps } from 'components/button/base';
import { BackIcon } from 'icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton: React.FC<ButtonProps> = ({
  onClick,
  children = undefined,
  ...rest
}) => {
  const history = useHistory();
  return (
    <Button
      variant="flat"
      icon={<BackIcon width={20} />}
      onClick={rest.to ? null : onClick ? onClick : history.goBack}
      {...rest}
    >
      {children ? children : 'Go back'}
    </Button>
  );
};

export default BackButton;
