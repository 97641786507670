import { Media } from 'api/Serializers/Media';
import { MAX_EMAIL_LENGTH } from 'config';
import React from 'react';

export const MailtoPropel = () => (
  <a target="_blank" href="mailto:support@propelhq.com">
    support@propelhq.com
  </a>
);
export const phonePropel = () => (
  <a target="_blank" href="tel:8339776735">
    +1-833-977-6735
  </a>
);

export const trailingSlash = (url) => {
  if (
    typeof url === 'string' &&
    url.length > 1 &&
    (url.charAt(url.length - 1) === '/' || url.indexOf('/?') !== -1)
  ) {
    logger.warn('URL FOUND WITH TRAILING SLASH', url);
  }
  return removeTrailingSlash(url);
};

const removeTrailingSlash = (url) =>
  // is a string, check and append a slash
  typeof url === 'undefined' || url === null
    ? url
    : typeof url === 'string'
    ? url !== '/' && url.indexOf('/?') !== 0
      ? url.charAt(url.length - 1) === '/'
        ? url.substr(0, url.length - 1)
        : url.indexOf('/?') !== -1
        ? url.replace('/?', '?')
        : url
      : url
    : typeof url === 'object'
    ? typeof url.pathname === 'string'
      ? { ...url, pathname: trailingSlash(url.pathname) }
      : url
    : url;

String.prototype.capitalize =
  String.prototype.capitalize ||
  function () {
    /**
     * Returns string with every word capitalized
     */
    const arr = this.split(' ').map(
      (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    );
    return arr.join(' ');
  };
// tslint:disable-next-line:only-arrow-functions
String.prototype.pluralize =
  String.prototype.pluralize ||
  function (num: number | boolean) {
    return num === false || num === 1 ? this : this + 's';
  };

String.prototype.toCurrency =
  String.prototype.toCurrency ||
  function (precise: boolean = true) {
    return Number(this).toCurrency(precise);
  };

Array.prototype.flat =
  Array.prototype.flat ||
  function () {
    return [].concat(...this);
  };

Array.prototype.groupBy =
  Array.prototype.groupBy ||
  function (grouper) {
    return this.reduce((agg, val) => {
      (agg[grouper(val)] = agg[grouper(val)] || []).push(val);
      return agg;
    }, {});
  };

Number.prototype.toCurrency =
  Number.prototype.toCurrency ||
  function (precise: boolean = true) {
    const options = {
      maximumFractionDigits: precise ? undefined : 0,
      style: 'currency',
      currency: 'CAD',
    };
    return this.toLocaleString('en-CA', options);
  };

Number.prototype.toOrdinal =
  Number.prototype.toOrdinal ||
  function () {
    if (this === 11 || this === 12 || this === 13) {
      return `${this}th`;
    }
    switch (this % 10) {
      case 1:
        return `${this}st`;
      case 2:
        return `${this}nd`;
      case 3:
        return `${this}rd`;
      default:
        return `${this}th`;
    }
  };

export const slugify = (text) =>
  typeof text !== 'undefined'
    ? text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
    : undefined;

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (email: string): string => {
  if (!email || email.length === 0) {
    return 'Cannot be blank';
  } else if (email.length > MAX_EMAIL_LENGTH) {
    return 'Cannot be longer than ' + MAX_EMAIL_LENGTH;
  } else if (!emailRegex.test(email)) {
    return 'Invalid email address';
  }
  return '';
};

export const getHeroMedia = (media: Media[]) => {
  try {
    return media.length > 0
      ? [...media].sort((a, b) => a.order - b.order)[0].url
      : '';
  } catch (err) {
    return '';
  }
};
