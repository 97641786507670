import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { FavouriteSerializer } from 'api/Serializers/Favourites';
import { FETCH_STATE, UserType } from 'config';
import {
  FavouritesFetchError,
  FavouritesUpdated,
  FavouriteUpdateError,
} from 'lang/en/Snackbars';
import { enqueueSnackbar } from 'notistack';
import { getAccountDetail, getIsAuthenticated } from 'state/selectors';
import { AppDispatch } from 'state/store';

interface FavouritesReducer {
  fetchState: string;
  isLoaded: boolean;
  list: FavouriteSerializer[];
  error: string | string[];
}

const initialState: FavouritesReducer = {
  fetchState: FETCH_STATE.IDLE,
  isLoaded: false,
  list: [],
  error: undefined,
};

const findFav = (toFind: FavouriteSerializer) => (fav: FavouriteSerializer) =>
  fav.contentObject.id === toFind.contentObject.id &&
  fav.contentType === toFind.contentType;

const name: 'favourites' = 'favourites';
const Slice = createSlice({
  name,
  initialState,
  reducers: {
    setFetchState(state, action: PayloadAction<string>) {
      state.fetchState = action.payload;
    },
    setIsLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },
    setList(state, action: PayloadAction<FavouriteSerializer[]>) {
      state.list = action.payload;
    },
    removeFavourite(state, action: PayloadAction<FavouriteSerializer>) {
      state.list = state.list.map((fav) =>
        fav.contentObject.id === action.payload.contentObject.id &&
        fav.contentType === action.payload.contentType
          ? { ...fav, isRemoving: true }
          : { ...fav }
      );
    },
    addFavourite(state, action: PayloadAction<FavouriteSerializer>) {
      if (state.list.find(findFav(action.payload))) {
        state.list = state.list.map((fav) =>
          fav.contentObject.id === action.payload.contentObject.id &&
          fav.contentType === action.payload.contentType
            ? { ...fav, isActive: true }
            : { ...fav }
        );
      } else {
        state.list.push(action.payload);
      }
    },
    clear(state) {
      state.fetchState = initialState.fetchState;
      state.isLoaded = initialState.isLoaded;
      state.list = initialState.list;
      state.error = initialState.error;
    },
    flush(state, action: PayloadAction<FavouriteSerializer>) {},
  },
});

const { setIsLoaded, setList, clear } = Slice.actions;

export const clearFavourites = () => (dispatch: AppDispatch) => {
  return dispatch(clear());
};

export const removeFavourite =
  ({ contentType, contentObject }: FavouriteSerializer) =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(Slice.actions.removeFavourite({ contentType, contentObject }));
    try {
      await api.favourites.delete({
        contentType: contentType,
        objectId: contentObject.id,
      });
    } catch {
      // on error, reverse the optimistic local state change
      dispatch(Slice.actions.addFavourite({ contentType, contentObject }));
      enqueueSnackbar(FavouriteUpdateError);
    }
  };

export const addFavourite =
  ({ contentType, contentObject }: FavouriteSerializer) =>
  async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const account = getAccountDetail(state);
    if (!account || account.type !== UserType.Client) {
      return null;
    }
    dispatch(Slice.actions.addFavourite({ contentType, contentObject }));
    try {
      await api.favourites.create({
        contentType: contentType,
        objectId: contentObject.id,
      });
      enqueueSnackbar(
        FavouritesUpdated(
          contentObject.displayName,
          account.favouriteNotificationFrequency
        )
      );
    } catch (err) {
      // on error, reverse the optimistic local state change
      dispatch(Slice.actions.removeFavourite({ contentType, contentObject }));
      enqueueSnackbar(FavouriteUpdateError);
    }
  };

export const fetchFavourites =
  () => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    if (!getIsAuthenticated(state)) {
      return null;
    }
    dispatch(Slice.actions.setFetchState(FETCH_STATE.GET));
    try {
      const response = await api.favourites.list();
      dispatch(Slice.actions.setFetchState(FETCH_STATE.IDLE));
      dispatch(setList(response.data));
      dispatch(setIsLoaded(true));
    } catch (error) {
      dispatch(Slice.actions.setFetchState(FETCH_STATE.FAILED));
      enqueueSnackbar(FavouritesFetchError);
      dispatch(setIsLoaded(false));
    }
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
