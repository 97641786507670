import FormControlLabel from '@mui/material/FormControlLabel';
import UISwitch from '@mui/material/Switch';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  PaymentIntentResult,
  SetupIntentResult,
} from '@stripe/stripe-js';
import api from 'api';
import { AxiosResponse } from 'axios';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import { APP_ROOT_URL, STRIPE_PUBLIC_KEY } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUsername } from 'state/selectors';
import { retrieveAccount } from 'state/slice/account';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

type Props = {
  stripeConfigReturnUrl?: string;
  isSetupIntent?: boolean;
  showDefaultToggle?: boolean;
  onClose(...params: any[]): void;
  onCreated(successData?: any): void;
  onError?(errorData?: any): void;
  fetchClientSecret?(): Promise<AxiosResponse<{ clientSecret: string }>>;
};

/**
 * Either save a credit card for future use or capture an immediate payment.
 */
const CreditCardForm = ({
  onError,
  isSetupIntent = true,
  showDefaultToggle = true,
  stripeConfigReturnUrl = `${APP_ROOT_URL}${location.pathname}`,
  ...props
}: Props) => {
  const [clientSecret, setClientSecret] = useState<string>();
  const fetchClientSecret = async () => {
    const fetchFunc = isSetupIntent
      ? api.paymentMethods.setup
      : props.fetchClientSecret;
    try {
      const response = await fetchFunc();
      setClientSecret(response.data.clientSecret);
    } catch (err) {
      onError({ msg: 'Failed to fetch client secret', fault: 'propel' });
    }
  };

  useEffect(() => {
    if (clientSecret === undefined) {
      fetchClientSecret();
    }
  }, [clientSecret]);

  const handleError = ({
    msg,
    fault,
    ...extra
  }: {
    msg: string;
    fault: 'stripe' | 'propel';
    [extra: string]: any;
  }) => {
    if (fault === 'propel') {
      setClientSecret(undefined);
    }
    if (onError !== undefined) {
      onError({ msg, fault, ...extra });
    }
    enqueueSnackbar({ message: msg, variant: 'error' });
  };

  return clientSecret ? (
    <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
      <StripeForm
        {...props}
        isSetupIntent={isSetupIntent}
        onError={handleError}
        showDefaultToggle={isSetupIntent && showDefaultToggle}
        stripeConfigReturnUrl={stripeConfigReturnUrl}
      />
    </Elements>
  ) : (
    <div className="min-w-sm h-28">
      <Loading position="absolute" message="Securing connection..." />
    </div>
  );
};

// Docs: https://stripe.com/docs/payments/save-and-reuse?platform=web
const StripeForm = ({
  stripeConfigReturnUrl,
  isSetupIntent,
  onCreated,
  onError,
  onClose,
  showDefaultToggle,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const username = useSelector(getUsername);
  const [isDefault, setIsDefault] = useState(showDefaultToggle);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }
    const stripeMethod = isSetupIntent
      ? stripe.confirmSetup
      : stripe.confirmPayment;
    const stripeResponse = await stripeMethod({
      elements,
      confirmParams: {
        return_url: stripeConfigReturnUrl,
      },
      redirect: 'if_required',
    });
    if (stripeResponse.error) {
      setIsLoading(false);
      onError({
        msg:
          stripeResponse.error.message ??
          'Error with card: Please check the details and try again',
        fault: 'stripe',
      });
      return;
    } else if (isSetupIntent) {
      try {
        const pmResponse = await api.paymentMethods.create({
          id: (stripeResponse as SetupIntentResult).setupIntent
            .payment_method as string,
          isDefault: isDefault,
        });
        await dispatch(retrieveAccount(username));
        enqueueSnackbar({
          message: 'New payment method successfully added',
          variant: 'success',
        });
        setIsLoading(false);
        onCreated(pmResponse.data);
      } catch (err) {
        setIsLoading(false);
        onError({
          msg: 'Failed to add new payment method. Please try again.',
          fault: 'propel',
        });
      }
    } else {
      try {
        const paymentIntent = (stripeResponse as PaymentIntentResult)
          .paymentIntent;
        if (paymentIntent.status === 'succeeded') {
          onCreated(paymentIntent);
        } else {
          onError({ msg: 'Payment failed', fault: 'stripe', paymentIntent });
        }
      } catch (err) {
        onError({
          msg: 'Stripe request was successful, but an unknown error occurred',
          fault: 'propel',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {showDefaultToggle && (
        <FormControlLabel
          className="mb-3"
          control={
            <UISwitch
              checked={isDefault}
              onChange={() => setIsDefault((val) => !val)}
              color="primary"
            />
          }
          label="Make this my default card"
        />
      )}
      <PaymentElement />
      <Controls className="!mb-0 !pb-0">
        <Button
          variant="flat"
          onClick={() => {
            onClose();
          }}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="flat"
          color="primary"
          isLoading={isLoading}
        >
          {isSetupIntent ? 'Submit' : 'Checkout'}
        </Button>
      </Controls>
    </form>
  );
};

export default CreditCardForm;
