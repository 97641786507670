import api from 'api';
import { InstructorReview } from 'api/Serializers/Reviews';
import Dashboard from 'components/account/dashboard';
import Card from 'components/card';
import Loading from 'components/loading';
import { REVIEWS_PER_PAGE } from 'config';
import Reviews from 'containers/reviews';
import React, { useMemo, useState } from 'react';

const AdminReviewPage = () => {
  const reduceInstructorIds = (agg: number[], rev: InstructorReview) =>
    agg.find((i) => i === rev.instructorId)
      ? agg
      : agg.concat(rev.instructorId);
  const [isLoading, setLoading] = useState(true);
  const [allReviews, setAllReviews] = useState<InstructorReview[]>([]);
  const [instructorIds, setInstructorIds] = useState<number[]>([]);
  const fetchReviews = async () => {
    const response = await api.reviews.list();
    if (response) {
      setAllReviews(response.data);
      setInstructorIds(response.data.reduce(reduceInstructorIds, []));
    }
    setLoading(false);
  };
  useMemo(() => {
    fetchReviews();
  }, []);

  return (
    <Dashboard title="All Reviews" width="3xl">
      <div>
        {instructorIds.map((id) => {
          const reviews = allReviews.filter(
            (review) => review.instructorId === id
          );
          if (reviews.length === 0) {
            return null;
          }
          return (
            <Card
              key={`ins-${id}`}
              className="mb-12"
              maxWidth="full"
              title={reviews[0].instructorName}
            >
              <div className="max-w-full">
                <div className="pb-4 text-gray-700">
                  {reviews.length} reviews
                </div>
                <Reviews
                  name="All Propel"
                  type="reviews"
                  reviews={reviews}
                  count={reviews.length}
                  limit={REVIEWS_PER_PAGE}
                />
              </div>
            </Card>
          );
        })}
      </div>
      {isLoading && <Loading />}
    </Dashboard>
  );
};

export default AdminReviewPage;
