import { AssistanceWizardProps } from 'models/Assistance';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';
import ClientNoShowModal from './views/cancel/no-show';
import InstructorRootView from './views/instructor';
import InstructorDialThrough from './views/instructor/call';
import NoteToInstructor from './views/instructor/note';
import OtherRootView from './views/other';
import PaymentMethod from './views/payment';
import SummaryView from './views/summary';

const getPath = (route: string) =>
  SHARED_ROUTES.ASSISTANCE.nav(':appointmentId', route);

const ReportWizard = (props: AssistanceWizardProps) => {
  return (
    <div className="w-full">
      <Switch>
        <Route exact path={getPath('no-show')}>
          <ClientNoShowModal {...props} />
        </Route>
        <Route exact path={getPath('instructor')}>
          <InstructorRootView {...props} />
        </Route>
        <Route exact path={getPath('instructor/call')}>
          <InstructorDialThrough {...props} />
        </Route>
        <Route exact path={getPath('instructor/note')}>
          <NoteToInstructor {...props} />
        </Route>
        <Route exact path={getPath('payment')}>
          <PaymentMethod {...props} />
        </Route>
        <Route exact path={getPath('general')}>
          <OtherRootView {...props} />
        </Route>
        <Route exact path={getPath('summary')}>
          <SummaryView {...props} />
        </Route>
      </Switch>
    </div>
  );
};

export default ReportWizard;
