import Dashboard from 'components/account/dashboard';
import Link from 'components/link';
import { Entity, RouteProps } from 'models/route';
import { ADMIN_ROUTES } from 'pages/account/admin/utils';
import React from 'react';
import AdminInstructorPayoutSummary from './instructors';
import MarketplacePayouts from './marketplace';

const AdminPayoutPage: React.FC<RouteProps> = (props) => {
  const { entity } = props.match.params;
  return (
    <Dashboard title="Payouts" width="4xl">
      <div className="button-group no-print card">
        <Link
          className={`${entity === Entity.Marketplace ? 'active' : ''}`}
          to={ADMIN_ROUTES.PAYOUTS.nav(Entity.Marketplace)}
          underline={false}
        >
          Biweekly payouts
        </Link>
        <Link
          className={`${entity === Entity.Instructors ? 'active' : ''}`}
          to={ADMIN_ROUTES.PAYOUTS.nav(Entity.Instructors)}
          underline={false}
        >
          Yearly earnings
        </Link>
        <Link
          className={`${entity === Entity.Hosts ? 'active' : ''}`}
          to={ADMIN_ROUTES.PAYOUTS.nav(Entity.Hosts)}
          underline={false}
          disabled
        >
          Host earnings
        </Link>
      </div>
      {entity === Entity.Marketplace ? (
        <MarketplacePayouts {...props} />
      ) : entity === Entity.Instructors ? (
        <AdminInstructorPayoutSummary {...props} />
      ) : null}
    </Dashboard>
  );
};

export default AdminPayoutPage;
