import { AppointmentListSerializer } from 'api/Serializers/Appointments';
import Loading from 'components/loading';
import { CAL_LIST_ITEM_NAME_PREFIX, DATE_FMT, FETCH_STATE } from 'config';
import {
  DateTimeLoading,
  TodayEmpty,
} from 'features/schedule/date-time-list/card';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import {
  getAppointmentsFetchState,
  getCurrentUser,
  getScheduleRenderDate,
} from 'state/selectors';
import EventListDay from '../event-list-day';

const reduceUniqueDays = (agg: string[], val: AppointmentListSerializer) =>
  agg.indexOf(val.date) === -1 ? agg.concat(val.date) : agg;

const CalendarEventList = ({
  events,
}: {
  events: AppointmentListSerializer[];
}) => {
  const renderDate = useSelector(getScheduleRenderDate);
  const appointmentFetchState = useSelector(getAppointmentsFetchState);
  const user = useSelector(getCurrentUser);
  const today = moment().format(DATE_FMT.DATE_KEY);
  const [scrollTop, setScrollTop] = useState(undefined);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [dates, setDates] = useState([]);

  function scrollToDate(target) {
    scroller.scrollTo(target, {
      smooth: true,
      duration: 900,
      offset: -250,
    });
  }

  useEffect(() => {
    if (events) {
      const uniqueDays = [...events].reduce(reduceUniqueDays, []);
      if (
        moment().isSame(renderDate, 'month') &&
        uniqueDays.findIndex((d) => d === today) === -1 &&
        uniqueDays.length > 1
      ) {
        uniqueDays.push(today);
      }
      setDates(uniqueDays.sort((a, b) => (a > b ? 1 : -1)));
    }
  }, [events]);

  useEffect(() => {
    setShouldScroll(
      moment().isSame(moment(renderDate, DATE_FMT.DATE_KEY), 'month')
    );
  }, [renderDate]);

  useEffect(() => {
    if (user && scrollTop) {
      const sameMonth = moment().isSame(
        moment(renderDate, DATE_FMT.DATE_KEY),
        'month'
      );
      if (!sameMonth) {
        scrollTop.scrollToTop();
      } else {
        setShouldScroll(sameMonth);
      }
    }
  }, [user]);

  useEffect(() => {
    if (shouldScroll && scroller) {
      const target = `${CAL_LIST_ITEM_NAME_PREFIX}-${moment().format(
        DATE_FMT.DATE_KEY
      )}`;
      const canScroll = document.getElementsByName(target).length > 0;
      if (canScroll) {
        setShouldScroll(false);
        setTimeout(() => scrollToDate(target), 150);
      }
    }
  });

  return (
    <div className="flex-1 overflow-auto" id="_eventList">
      {appointmentFetchState === FETCH_STATE.GET && <Loading />}
      <div className="relative bg-background px-0.5 pt-4 pb-16 transition-opacity duration-300 ease-in-out space-y-12">
        {dates.length > 0 ? (
          dates.map((date) => {
            const dateEvents = [...events].filter(
              (event) => event.date === date
            );
            if (date === today && dateEvents.length === 0) {
              return <TodayEmpty key={`date-${date}`} date={date} />;
            }
            return (
              <EventListDay
                key={`date-${date}`}
                events={dateEvents}
                dateKey={date}
                sorting={'byFacility'}
              />
            );
          })
        ) : events.length === 0 ? (
          appointmentFetchState === FETCH_STATE.GET ? (
            <>
              <DateTimeLoading />
              <DateTimeLoading />
              <DateTimeLoading />
              <DateTimeLoading />
            </>
          ) : (
            <div className="m-2 sm:m-4">
              <div className="max-w-3xl px-6 py-4 mx-auto text-left text-gray-700 bg-white rounded-lg">
                <h3 className="my-2 text-lg font-semibold text-gray-900">
                  Zero Results
                </h3>
                <p>There were no appointments found for this month</p>
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default CalendarEventList;
