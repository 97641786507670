import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { MIN_CANCEL_NOTE_LENGTH, UserType } from 'config';
import TextArea from 'features/assistance/components/textArea';
import { AssistanceWizardProps, ReportResolution } from 'models/Assistance';
import moment from 'moment-timezone';
import React from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const minWaitMinutes = 10;
const dataKey = 'facilityIssueDescription';
const ClientSelectProblemView = (props: AssistanceWizardProps) => {
  const { appointment, state, getReportData, trackAction } = props;
  const canReport = moment().isSameOrAfter(
    moment(appointment.start).add(minWaitMinutes, 'minutes')
  );
  const description = getReportData(dataKey, '') as string;
  const canContinue = canReport && description.length >= MIN_CANCEL_NOTE_LENGTH;
  const handleOvercrowding = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_TOO_CROWDED' as ReportResolution,
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>The pool is too crowded</h4>
      <Callout className="my-8" type="info">
        Always yield to other guests
      </Callout>
      <div className="my-8">
        <p>
          Propel lessons are private, but the pool is shared with everyone.
          Other guests and Propel lessons may be present, so please be polite
          and <strong>share the space appropriately.</strong>
        </p>
        <TextArea
          id={dataKey}
          title="Description"
          label="Briefly describe the issue:"
          placeholder="The lesson cannot proceed due to..."
          recipientUserType={UserType.Admin}
        />
        {!canReport && (
          <Callout type="error">
            Wait until at least {minWaitMinutes} minutes before reporting the
            pool overcrowded, as other guests may clear out
          </Callout>
        )}
      </div>
      <Controls variant="block">
        <BackButton />
        <Button
          disabled={!canContinue}
          variant="contained"
          color="primary"
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
          onClick={handleOvercrowding}
        >
          Continue
        </Button>
      </Controls>
    </>
  );
};

export default ClientSelectProblemView;
