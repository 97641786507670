import CheckIcon from '@mui/icons-material/Done';
import Link from 'components/link';
import React from 'react';
import css from './style.module.scss';

type Button = {
  title?: string;
  subtitle?: string;
  variant?: 'blank' | 'check' | 'checkbox' | 'radio' | 'toggle';
  selected?: boolean;
  icon?: JSX.Element;
  to?: string;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const iconHeight = 18;
const iconPadding = 2;
const Check = ({ selected }: { selected: boolean }) => (
  <div className="mr-2" style={{ width: iconHeight, height: iconHeight }}>
    <div
      className={`w-full h-full border ${
        selected ? 'border-blue-400' : 'border-gray-400'
      } rounded-full`}
      style={{ padding: iconPadding }}
    >
      <CheckIcon
        className={`bg-blue-400 absolute top-1/2 left-1/2 text-white rounded-full transition-transform duration-150 ease-out transform -translate-x-1/2 -translate-y-1/2 ${
          selected ? 'scale-100' : 'scale-0'
        }`}
        style={{ fontSize: iconHeight }}
      />
    </div>
  </div>
);
const Checkbox = ({ selected }: { selected: boolean }) => (
  <div className="mr-2" style={{ width: iconHeight, height: iconHeight }}>
    <div
      className={`w-full h-full border ${
        selected ? 'border-blue-400' : 'border-gray-400'
      } rounded`}
      style={{ padding: iconPadding }}
    >
      <div
        className={`${
          css.transform
        } center-xy absolute bg-blue-400 top-0 left-0 rounded-sm ${
          selected ? css.selected : css.unselected
        }`}
      />
    </div>
  </div>
);
const Radio = ({ selected }: { selected: boolean }) => (
  <div className="mr-2" style={{ width: iconHeight, height: iconHeight }}>
    <div
      className={`w-full h-full border ${
        selected ? 'border-blue-400' : 'border-gray-400'
      } rounded-full`}
      style={{ padding: iconPadding }}
    >
      <div
        className={`transition-all duration-100 ease-out center-xy absolute bg-blue-400 top-0 left-0 rounded-full ${
          selected
            ? 'opacity-100 w-full h-full border-solid border-white border-2'
            : 'opacity-0 w-1/4 h-1/4 border-2 border-solid border-white'
        }`}
      />
    </div>
  </div>
);
const toggleW = iconHeight * 2 - 2 * iconPadding - 2;
export const Toggle = ({ selected }: { selected: boolean }) => (
  <div className="mr-2" style={{ width: toggleW, height: iconHeight }}>
    <div
      className={`relative w-full h-full border ${
        selected ? 'border-blue-400' : 'border-gray-400'
      } rounded-full`}
      style={{ padding: iconPadding }}
    >
      <div
        style={{
          width: iconHeight - 2 * iconPadding - 2,
          height: iconHeight - 2 * iconPadding - 2,
          top: '50%',
          left: selected ? toggleW - iconHeight + iconPadding : iconPadding,
          transform: 'translateY(-50%)',
        }}
        className={`${css.transform} ${
          selected ? 'bg-blue-400' : 'bg-gray-500'
        } absolute top-0 rounded-full`}
      />
    </div>
  </div>
);

const ButtonLarge: React.FC<Button> = ({
  className,
  subtitle,
  title,
  style,
  onClick,
  variant = 'toggle',
  selected = false,
  type = 'button',
  disabled = false,
  icon = undefined,
  to = null,
  ...rest
}) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };
  const Button = (
    <button
      {...rest}
      onClick={handleClick}
      type={type}
      disabled={disabled}
      className={`w-full p-4 btn-cta gray focus:outline-none flex items-center justify-between rounded-lg group ${
        !selected ? `text-gray-800 ` : `selected text-gray-900 `
      } ${
        disabled
          ? 'opacity-75 cursor-default bg-gray-200'
          : `hover:text-gray-900`
      } ${cls(className)}`}
      style={{ ...style, minHeight: '3.5rem' }}
    >
      <div className="flex items-center justify-between pointer-events-none ">
        {getIcon(variant, selected, icon)}
        <div className="flex-1">
          <div
            className={`text-base text-left ${
              disabled
                ? 'text-gray-600'
                : selected
                ? `font-semibold text-gray-900 group-hover:text-gray-800`
                : `font-semibold text-gray-900 group-hover:text-gray-800`
            }`}
          >
            {title}
          </div>
          <div
            className={`text-left font-normal text-sm ${
              disabled
                ? 'text-gray-600'
                : selected
                ? 'text-gray-800 group-hover:text-gray-900'
                : 'text-gray-700 group-hover:text-gray-900'
            }`}
          >
            {subtitle}
          </div>
        </div>
      </div>
    </button>
  );
  return to ? (
    <Link
      className="flex text-inherit hover:text-inherit"
      to={to}
      underline={false}
    >
      {Button}
    </Link>
  ) : (
    Button
  );
};

const getIcon = (variant, selected, icon) =>
  icon ? (
    <div
      className={`self-start mr-2 group-hover:text-gray-900 transition-colors duration-200 ${
        selected ? 'text-gray-900' : 'text-gray-600'
      }`}
    >
      {icon}
    </div>
  ) : variant === 'check' ? (
    <Check selected={selected} />
  ) : variant === 'checkbox' ? (
    <Checkbox selected={selected} />
  ) : variant === 'radio' ? (
    <Radio selected={selected} />
  ) : variant === 'toggle' ? (
    <Toggle selected={selected} />
  ) : null;

export default ButtonLarge;
