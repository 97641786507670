import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ANIMATE_MS } from 'config';
import * as React from 'react';
import style from './style.module.scss';

interface Props {
  /** css or px height of the closed state. Assign 0 for collapsing divs */
  initialHeight?: string | number;
  initiallyOpen?: boolean;
  /** Override isOpen to manage open state elsewhere — also hides "Read More" and "Close" buttons */
  isOpen?: boolean;
  openBtnText?: string;
  closeBtnText?: string;
  children?: any;
  onOpenChange?: (isOpen: boolean) => void;
}

interface State {
  childrenHeight: number;
  isControlled: boolean;
  isOpen: boolean;
  showReadMore: boolean;
  transitionState: 'idle' | 'transitioning';
}

class ReadMore extends React.Component<Props, State> {
  static defaultProps = {
    initialHeight: '4rem',
    isOpen: undefined,
    closeBtnText: 'Close',
    openBtnText: 'Read More',
    initiallyOpen: false,
    onOpenChange: undefined,
  };

  private containerRef: React.RefObject<HTMLDivElement>;
  private childrenRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.containerRef = React.createRef<HTMLDivElement>();
    this.childrenRef = React.createRef<HTMLDivElement>();
    this.state = {
      childrenHeight: 0,
      isControlled: safe(this.props.isOpen),
      isOpen: props.initiallyOpen,
      showReadMore: false,
      transitionState: 'idle',
    };
  }

  componentDidMount() {
    if (this.getChildrenHeight() > this.getContainerHeight()) {
      this.setState({
        showReadMore: !this.state.isControlled,
      });
      if (this.state.isControlled) {
        this.setState({
          isOpen: this.props.isOpen,
        });
      }
    }
    this.setState({
      childrenHeight: this.getChildrenHeight(),
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { childrenHeight } = this.state;
    const current: Props | State = this.state.isControlled
      ? this.props
      : this.state;
    const previous: Props | State = this.state.isControlled
      ? prevProps
      : prevState;
    const newHeight = this.getChildrenHeight();
    const hasChildrenUpdated = childrenHeight !== newHeight;
    if (current.isOpen !== previous.isOpen || hasChildrenUpdated) {
      this.setState({
        transitionState: 'transitioning',
      });
      setTimeout(() => {
        this.setState({
          transitionState: 'idle',
        });
      }, ANIMATE_MS);
      if (this.props.onOpenChange !== undefined) {
        this.props.onOpenChange(current.isOpen);
      }
    }
    if (hasChildrenUpdated) {
      this.setState({
        childrenHeight: newHeight,
      });
      if (newHeight > this.getContainerHeight()) {
        this.setState({
          showReadMore: !this.state.isControlled,
        });
        if (this.state.isControlled) {
          this.setState({
            isOpen: this.props.isOpen,
          });
        }
      }
    }
  }

  getChildrenHeight = () => {
    if (!safe(this.childrenRef.current)) {
      return 0;
    }
    const children = this.childrenRef.current.children;
    return this.getHeight(children);
  };

  getContainerHeight = () => {
    if (!safe(this.containerRef.current)) {
      return 0;
    }
    const children = this.containerRef.current.children;
    return this.getHeight(children);
  };

  getHeight = (children: HTMLCollection) => {
    let offsetHeight = 0;
    if (safe(children)) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < children.length; i++) {
        const child: HTMLElement = children[i] as HTMLElement;
        offsetHeight += child.offsetHeight;
      }
    }

    return offsetHeight;
  };

  render() {
    const { children, initialHeight, closeBtnText, openBtnText } = this.props;
    const { isControlled, transitionState } = this.state;
    const isOpen = isControlled ? this.props.isOpen : this.state.isOpen;

    if (children.length > 1) {
      throw new Error(
        'ReadMore must have singular child element. Put child elements in wrapping <div/> container.'
      );
    }

    return (
      <div className={style.container} ref={this.containerRef}>
        <div
          className={`${style.children} transition-max-height cubic duration-300 cubic`}
          ref={this.childrenRef}
          style={{
            maxHeight: isOpen ? this.getChildrenHeight() : initialHeight,
            overflow:
              transitionState === 'transitioning'
                ? 'hidden'
                : isOpen
                ? 'initial'
                : 'hidden',
          }}
        >
          {children}
        </div>
        {this.state.showReadMore && (
          <div className="flex justify-end">
            <button
              className="flex items-center justify-end p-1 link"
              onClick={() => this.setState({ isOpen: !isOpen })}
            >
              {isOpen ? closeBtnText : openBtnText}{' '}
              <DownIcon
                className={`transform transition-transform${
                  isOpen ? ' rotate-180' : ''
                }`}
              />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default ReadMore;
