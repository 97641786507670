import { CancelSerializer, Event } from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useTimeoutRefresh from 'hooks/useTimeoutRefresh';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cancelAppointment } from 'state/slice/appointments';
import { SHARED_ROUTES } from 'utils/routing';

const AdminCancellationView = ({
  appointment,
  isOpen,
  onClose,
}: {
  appointment: Event;
  isOpen: boolean;
  onClose(): void;
}) => {
  const timer = useTimeoutRefresh(); // triggers re-render on the :00 and :30
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleCancellation = async () => {
    const data: CancelSerializer = {
      reason: 'AdminCancelled',
      notes: [],
    };
    setSubmitting(true);
    await dispatch(cancelAppointment(appointment, data));
    history.push(SHARED_ROUTES.SCHEDULE.appointment(appointment.id));
    setSubmitting(false);
  };
  const handleModalClose = () => {
    onClose();
  };

  return (
    <Modal
      name="Admin — Cancel"
      title="Cancel as admin"
      open={isOpen}
      onClose={handleModalClose}
      maxWidth="xs"
    >
      {isSubmitting && <Loading />}
      <div className="mb-8 space-y-3">
        <p>
          Cancelling a lesson as admin at any time will refund the client's
          payment. The instructor and host will not earn any fees.
        </p>
      </div>
      <Controls variant="block">
        <BackButton onClick={onClose} disabled={isSubmitting}>
          Go back
        </BackButton>
        <Button
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
          onClick={handleCancellation}
        >
          Cancel appointment
        </Button>
      </Controls>
    </Modal>
  );
};

export default AdminCancellationView;
