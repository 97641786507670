import React from 'react';

const CharacterCount = ({
  currentLength,
  maxLength,
}: {
  currentLength: number;
  maxLength: number;
}) => (
  <div
    className={`text-sm text-right ${
      currentLength === maxLength ? 'text-gray-700' : 'text-gray-600'
    }`}
  >
    {currentLength > 0
      ? `${maxLength - currentLength} ${'character'.pluralize(
          maxLength - currentLength
        )} left`
      : ''}
  </div>
);

export default CharacterCount;
