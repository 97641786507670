import { PaymentMethodSerializer } from 'api/Serializers/PaymentMethods';
import CreditCardBrand, {
  getBrandDisplayName,
} from 'components/credit-card-brand';
import React from 'react';

const CreditCardDetails = ({
  paymentMethod,
  showDefaultBadge = true,
}: {
  paymentMethod: PaymentMethodSerializer;
  size?: 'lg' | 'sm';
  iconColor?: 'blue-700';
  showDefaultBadge?: boolean;
}) => {
  if (!paymentMethod) {
    return null;
  }
  return (
    <div className="flex items-center gap-4">
      <div>
        <CreditCardBrand brand={paymentMethod.brand} />
      </div>
      <div className="space-y-0">
        <div className="text-base font-semibold">
          {getBrandDisplayName(paymentMethod.brand)} •••• {paymentMethod.last4}
        </div>
        <div className="space-x-3 text-sm text-gray-600">
          Expires {paymentMethod.expMonth}/{paymentMethod.expYear}
        </div>
      </div>
      {showDefaultBadge && paymentMethod.isDefault && (
        <div className="text-xs border-solid border border-blue-300 bg-blue-200 text-blue-700 p-1 ml-1.5 rounded-full">
          Default
        </div>
      )}
    </div>
  );
};

export default CreditCardDetails;
