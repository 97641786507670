import { MenuLink } from 'components/account/menu';
import { HeaderTheme, HeaderVariant } from 'components/app-header';
import Link from 'components/link';
import Logo from 'components/logo';
import { EmailIcon, HelpIcon, InfoIcon, LoginIcon, ScheduleIcon } from 'icons';
import React from 'react';
import { APP_ROUTES, SHOW_HELP } from 'utils/routing';
interface Props {
  pathname: string;
  theme: HeaderTheme;

  onLoginClick(): void;
}

const HIDE_BOOK_NOW_ROUTES = [APP_ROUTES.BROWSE_CITIES, APP_ROUTES.BOOK.ROOT];

const baseMobileLiClasses = 'relative flex flex-wrap my-1';
const MobileMenu = ({ pathname, theme, onLoginClick }: Props) => {
  return (
    <div className="my-6 w-72">
      <ul className="p-0 py-6 m-0 list-none">
        <li className={`${baseMobileLiClasses}`}>
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-home"
              to={APP_ROUTES.HOME}
              Icon={(props) => <Logo {...props} />}
              text="Home"
              isActive={pathname === APP_ROUTES.HOME}
            />
          </div>
        </li>
        <li className={`${baseMobileLiClasses}`}>
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-login"
              Icon={LoginIcon}
              text="Sign in"
              onClick={onLoginClick}
              isActive={false}
            />
          </div>
        </li>
        <li className={`${baseMobileLiClasses}`}>
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-learn-more"
              to={APP_ROUTES.LEARN_MORE.SWIMMERS}
              Icon={InfoIcon}
              text="Learn More"
              isActive={pathname.indexOf(APP_ROUTES.LEARN_MORE.ROOT) !== -1}
            />
          </div>
        </li>
        <li className={`${baseMobileLiClasses}`}>
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-contact"
              onClick={SHOW_HELP.CONTACT}
              Icon={EmailIcon}
              text="Contact"
              isActive={false}
            />
          </div>
        </li>
        <li
          className={`${baseMobileLiClasses}${
            HIDE_BOOK_NOW_ROUTES.some((route) => pathname.indexOf(route) === 0)
              ? ' hidden'
              : ''
          }`}
        >
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-book-now"
              to={APP_ROUTES.BROWSE_CITIES}
              Icon={ScheduleIcon}
              text="Book Now"
              isActive={pathname.indexOf(APP_ROUTES.BROWSE_CITIES) === 0}
            />
          </div>
        </li>
        <li className={`${baseMobileLiClasses} mt-6`}>
          <div className="flex flex-1 flex-nowrap">
            <MenuLink
              id="nav-help"
              onClick={SHOW_HELP.LEARNING.ROOT}
              Icon={HelpIcon}
              text="Help"
              isActive={false}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

const activeBorderTrans = 'border-white';
const inactiveHoverTrans = 'hover:border-white';
const activeBorderWhite = 'border-gray-600';
const inactiveHoverWhite = 'hover:border-gray-700';
const DesktopMenu = ({ pathname, theme, onLoginClick }: Props) => {
  const isLearnActive = pathname.indexOf(APP_ROUTES.LEARN_MORE.ROOT) > -1;
  const liClasses = `p-0 sm:mx-2 md:mx-3 border-b-2 border-solid border-transparent `;
  const btnClasses = `border rounded-full mx-3 px-3 hover:bg-gray-100 hover:bg-opacity-20 `;
  const linkClasses = `flex items-center py-2 text-sm font-medium tracking-wide ${
    theme === HeaderTheme.Transparent
      ? 'text-white hover:text-gray-100'
      : 'text-gray-600 hover:text-gray-700'
  }`;
  return (
    <div className="flex items-center justify-center py-2">
      <ul className="flex py-0 pr-3">
        <li
          className={`${liClasses} ${
            theme === HeaderTheme.Transparent
              ? isLearnActive
                ? activeBorderTrans
                : inactiveHoverTrans
              : isLearnActive
              ? activeBorderWhite
              : inactiveHoverWhite
          }`}
        >
          <Link
            id="nav-learn-more"
            className={linkClasses}
            to={APP_ROUTES.LEARN_MORE.SWIMMERS}
          >
            <span className="">Learn More</span>
          </Link>
        </li>
        <li
          className={`${liClasses} ${
            theme === HeaderTheme.Transparent
              ? inactiveHoverTrans
              : inactiveHoverWhite
          }`}
        >
          <button
            id="nav-contact"
            className={linkClasses}
            onClick={SHOW_HELP.CONTACT}
          >
            <span className="">Contact</span>
          </button>
        </li>
        <li
          className={`${liClasses} ${
            theme === HeaderTheme.Transparent
              ? inactiveHoverTrans
              : inactiveHoverWhite
          }`}
        >
          <button
            id="nav-help"
            className={linkClasses}
            onClick={SHOW_HELP.FOR_THIS_PAGE}
          >
            <span className="">Help</span>
          </button>
        </li>
        <li
          className={`${liClasses} ${
            theme === HeaderTheme.Transparent
              ? inactiveHoverTrans
              : inactiveHoverWhite
          }`}
        >
          <button id="nav-help" className={linkClasses} onClick={onLoginClick}>
            <span className="">Sign in</span>
          </button>
        </li>
        <li
          className={`${btnClasses} ${
            HIDE_BOOK_NOW_ROUTES.some((route) => pathname.indexOf(route) === 0)
              ? 'hidden'
              : theme === HeaderTheme.Transparent
              ? `border-white hover:border-gray-100 ${inactiveHoverTrans}`
              : `border-gray-500  ${inactiveHoverWhite}`
          }`}
        >
          <Link
            id="nav-book-now"
            className={linkClasses}
            to={APP_ROUTES.BROWSE_CITIES}
          >
            <span className="">Book Now</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

const AnonymousAppMenu = ({
  pathname,
  variant,
  theme,
  onLoginClick,
}: Props & { variant: HeaderVariant }) =>
  variant === HeaderVariant.Desktop ? (
    <DesktopMenu
      pathname={pathname}
      theme={theme}
      onLoginClick={onLoginClick}
    />
  ) : (
    <MobileMenu pathname={pathname} theme={theme} onLoginClick={onLoginClick} />
  );

export default AnonymousAppMenu;
