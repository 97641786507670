import { FleeqModule } from 'components/fleeq';
import LearningModule from 'components/instructor/learning-module';
import { QueryParams } from 'config';
import Page from 'containers/page';
import ScheduleV3 from 'features/schedule/as-instructor';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ScheduleMode } from 'state/slice/schedule';
import { getUrlParam } from 'utils/routing';

const CalendarPage = () => {
  const actionParam = getUrlParam(QueryParams.Action);
  const { pathname } = useLocation();
  const history = useHistory();
  return (
    <Page title="My Schedule">
      <ScheduleV3 />
      <LearningModule
        module={FleeqModule.AvailabilityOverview}
        show={actionParam === ScheduleMode.LearnAvailability}
        onboardingAttributeKey="hasLearnedAvailability"
        onClose={() => history.push(pathname)}
      />
    </Page>
  );
};

export default CalendarPage;
