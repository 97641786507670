import api from 'api';
import {
  AccountSupportSerializer,
  AvailableTimesSerializer,
} from 'api/Serializers/Accounts';
import InstructorAccount from 'api/Serializers/Accounts/Instructor';
import { Tense } from 'api/Serializers/Schedules';
import Button from 'components/button';
import Calendar from 'components/calendar';
import CalendarControls from 'components/calendar-controls';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Loading from 'components/loading';
import { DATE_FMT } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { GenericServerError } from 'lang/en/Snackbars';
import moment, { Moment } from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getAccountDetail,
  getInstructorOnboarding,
  getScheduleRenderDate,
} from 'state/selectors';
import { fetchInstructorOnboarding } from 'state/slice/instructor';
import { setScheduleRenderDate } from 'state/slice/schedule';

interface Props {
  next: string;
}

const CalendarDay = (props: {
  date: string;
  tense: Tense;
  selectedDate: string;
  supportData: AccountSupportSerializer;
  existing: Moment;
  isLoading: boolean;
  onSelect(date: string): any;
}) => {
  if (!props.date) {
    return null;
  }
  const baseClass =
    'h-8 w-8 sm:h-12 sm:w-12 m-auto flex flex-col rounded-full items-center font-bold justify-center leading-none';
  const date = moment(props.date);
  const existing = props.existing && date.isSame(props.existing, 'day');
  const selected = props.date === props.selectedDate || existing;
  const supportDataIndex = props.supportData?.available.findIndex(
    (data) => data.date === props.date
  );
  const bookable =
    supportDataIndex > -1 &&
    props.supportData.available[supportDataIndex].times.some(
      (t) => t.available
    );
  const className = selected
    ? existing
      ? 'text-white bg-green-600 shadow cursor-pointer hover:bg-green-500'
      : 'text-white bg-blue-500 shadow cursor-pointer'
    : bookable
    ? 'text-blue-500 hover:bg-gray-300 cursor-pointer'
    : 'opacity-25';
  return (
    <div
      className={`${baseClass} ${className}${
        props.isLoading ? ' animate-pulse' : ''
      }`}
      onClick={bookable ? props.onSelect(props.date) : undefined}
    >
      {date.format(DATE_FMT.D)}
      {existing && (
        <div className="text-xxs">{props.existing.format(DATE_FMT.TIME_A)}</div>
      )}
    </div>
  );
};

const TimeSelect = ({
  times,
  onSelect,
  existing,
  selectedDate,
}: {
  times: AvailableTimesSerializer[];
  onSelect(time: AvailableTimesSerializer): void;
  existing: Moment;
  selectedDate: string;
}) => {
  const [selected, setSelected] = useState<number>();
  useEffect(() => {
    setSelected(undefined);
  }, [selectedDate]);
  const existingTimeIndex =
    existing &&
    times &&
    moment(selectedDate, DATE_FMT.DATE_FIELD).isSame(existing, 'day')
      ? times.findIndex((t) => t.time === existing.format(DATE_FMT.TIME_24))
      : undefined;
  const onTimeSelect = (i: number) => () => {
    if (selected === i) {
      setSelected(undefined);
      onSelect(undefined);
    } else {
      setSelected(i);
      onSelect(times[i]);
    }
  };
  return (
    <div className="flex justify-center max-w-sm mx-auto my-4">
      <div className="flex flex-1 button-group">
        {times &&
          times.map((timeObj, i) => {
            const time = moment(timeObj.time, DATE_FMT.TIME_24);
            return (
              <button
                key={`t${i}`}
                disabled={!timeObj.available}
                className={
                  !timeObj.available
                    ? 'disabled'
                    : i === selected
                    ? 'active'
                    : i === existingTimeIndex
                    ? 'highlight'
                    : ''
                }
                onClick={onTimeSelect(i)}
              >
                {time.format(DATE_FMT.TIME_A)}
              </button>
            );
          })}
      </div>
    </div>
  );
};

const ScheduleACall: React.FC<Props> = ({ next }) => {
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const onboarding = useSelector(getInstructorOnboarding);
  const supportCall = onboarding.callDatetime;
  const username = account.username;
  const history = useHistory();
  const [init, setInit] = useState(false);
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const [existing, setExisting] = useState<Moment>(
    supportCall ? moment(supportCall).tz(account.address.timezone) : undefined
  );
  const [datetime, setDateTime] = useState<Moment>();
  const [submitted, setSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [supportData, setSupportData] = useState<AccountSupportSerializer>();
  const [availableTimes, setAvailableTimes] =
    useState<AvailableTimesSerializer[]>();
  const renderDate = useSelector(getScheduleRenderDate);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setInit(true);
    dispatch(
      setScheduleRenderDate(
        (supportCall
          ? moment(supportCall).startOf('month')
          : moment().startOf('month')
        ).format(DATE_FMT.DATE_KEY)
      )
    );
  }, []);

  useEffect(() => {
    const fetchSupportData = async () => {
      setIsLoading(true);
      try {
        const response = await api.account.support.get({
          start: renderDate,
        });
        setSupportData(response.data);
      } catch (error) {
        enqueueSnackbar(
          'Could not fetch upcoming call availability. An error report has been submitted to the tech team, please try refreshing this page.',
          {
            variant: 'error',
          }
        );
      }
      setIsLoading(false);
    };
    fetchSupportData();
  }, [renderDate]);

  useEffect(() => {
    setDateTime(undefined);
    setTime(undefined);
    if (date) {
      setAvailableTimes(
        supportData.available.find((data) => data.date === date).times
      );
    }
  }, [date]);

  useEffect(() => {
    setDateTime(undefined);
    if (date && time) {
      setDateTime(moment(`${date} ${time}`));
    }
  }, [time]);

  // On Update Complete
  useEffect(() => {
    if (init && supportCall) {
      setTimeout(() => history.push(next), 2000);
    }
  }, [supportCall]);

  const onCallDatetimeSubmit = async () => {
    setSubmit(true);
    if (existing && !datetime) {
      // Existing time is setup, and user has not made a change
      history.push(next);
    } else {
      try {
        const parts = time.split(':');
        const data = {
          datetime: moment(date)
            .tz(account.address.timezone)
            .hour(Number(parts[0]))
            .minute(Number(parts[1]))
            .format(DATE_FMT.DATETIME_FIELD),
        };
        await api.account.support.post(data);
        dispatch(fetchInstructorOnboarding(username));
        // dispatch(retrieveAccount(username));
      } catch (error) {
        enqueueSnackbar(GenericServerError);
      }
    }
  };

  const onSelectDate = (select: string) => (event) => setDate(select);
  const onSelectTime = (t: AvailableTimesSerializer) => {
    setTime(t ? t.time : undefined);
  };

  const handleNextMonth = () => {
    dispatch(
      setScheduleRenderDate(
        moment(renderDate)
          .add(1, 'month')
          .startOf('month')
          .format(DATE_FMT.DATE_KEY)
      )
    );
  };

  return (
    <div>
      {submitted && <Loading />}
      {existing ? (
        existing.isSameOrAfter(moment(), 'hour') ? (
          <Callout type="info" className="my-8">
            <span>
              Your call is scheduled for{' '}
              {moment(supportCall)
                .tz(account.address.timezone)
                .format(DATE_FMT.DOW_MONTH_D_TIME_A)}
              . Quickly reschedule your call using the available times shown
              below.
            </span>
          </Callout>
        ) : (
          <Callout type="info" className="my-8">
            <p>
              You had a call with Propel on {existing.format(DATE_FMT.MONTH_D)}{' '}
              at {existing.format(DATE_FMT.TIME_A)}.
            </p>
          </Callout>
        )
      ) : null}
      <div>
        <CalendarControls
          minDate={moment().format(DATE_FMT.DATE_KEY)}
          maxDate={moment().add(90, 'days').format(DATE_FMT.DATE_KEY)}
        />
        <Calendar
          DayComponent={(dayprops) => (
            <CalendarDay
              {...dayprops}
              isLoading={isLoading}
              existing={existing}
              supportData={supportData}
              selectedDate={date}
              onSelect={onSelectDate}
            />
          )}
        />
        <TimeSelect
          selectedDate={date}
          times={availableTimes}
          onSelect={onSelectTime}
          existing={existing}
        />
        {!isLoading && supportData?.available.length === 0 && (
          <Callout type="warning">
            There are no available times this month.
            <br />
            <button onClick={handleNextMonth} className="link !text-yellow-800">
              Go to next month
            </button>
          </Callout>
        )}
      </div>
      <Controls variant="block">
        <Button
          disabled={submitted || (!datetime && !existing)}
          color="primary"
          variant="contained"
          onClick={onCallDatetimeSubmit}
        >
          {`${
            existing
              ? datetime
                ? 'Reschedule to '
                : 'Continue'
              : datetime
              ? `Schedule for ${datetime.format(DATE_FMT.MONTH_D_TIME_A)}`
              : 'Select time to continue'
          }`}
        </Button>
      </Controls>
    </div>
  );
};

export default ScheduleACall;
