import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

/**
 * If the images are rendering massive, then you've wrapped
 * this component in a flex or grid container and it's just
 * unconstrained widths. Set parent divs to overflow-hidden
 * until you find where to apply that css class to fix.
 */
function ImageCarousel({ children }) {
  return (
    <>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="w-full overflow-hidden select-none rounded-2xl"
        style={
          {
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          } as any
        }
      >
        {children.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              className="flex items-center justify-center select-none"
            >
              {item}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default ImageCarousel;
