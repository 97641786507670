import React from 'react';

interface Props {
  height?: 2 | 3 | 4 | 5 | 6 | 8 | 10;
  className?: string;
  children: any;
}

const Row = ({ children, height, className }: Props) => (
  <div className="px-6 sm:px-8">
    <div className={`py-${height}${cls(className)}`}>{children}</div>
  </div>
);

Row.defaultProps = {
  height: 6,
};

export default Row;
