import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import { Status } from 'api/Serializers/Accounts';
import {
  AppointmentProduct,
  AppointmentProductStatus,
} from 'api/Serializers/AppointmentProducts';
import { Facility } from 'api/Serializers/Appointments';
import Button from 'components/button';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { FleeqModule } from 'components/fleeq';
import LearningModule from 'components/instructor/learning-module';
import Loading from 'components/loading';
import Modal from 'components/modal';
import {
  CAL_LIST_ITEM_NAME_PREFIX,
  DATE_FMT,
  FETCH_STATE,
  MIN_HOURS_UNTIL_PROPOSAL_START,
} from 'config';
import FacilityScheduleUpdates from 'containers/facility-updates';
import { ScrollOptions } from 'features/schedule';
import TimeSlotList from 'features/schedule/as-instructor/base-time-slot-list';
import { AvailabilityCalendarDay } from 'features/schedule/calendar-date-box';
import { SelectAppointmentProduct } from 'features/schedule/select-appointment-product';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { CheckIcon, FacilityIcon, WeekIcon } from 'icons';
import { GenericServerError } from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import {
  getCurrentUser,
  getScheduleAppointmentProduct,
  getScheduleAppointmentProducts,
  getScheduleChanges,
  getScheduleOpenings,
  getScheduleOpeningsFetchState,
  getScheduleRenderDate,
} from 'state/selectors';
import { retrieveAccount } from 'state/slice/account';
import { fetchAvailability } from 'state/slice/availability';
import {
  clearScheduleChanges,
  fetchOpenings,
  saveScheduleChanges,
  setAppointmentProduct,
} from 'state/slice/schedule';
import { getMonthStartEndParams } from 'utils/date';
import { SHARED_ROUTES } from 'utils/routing';
import ScheduleTemplate from '../template';

function scrollToTarget(target) {
  scroller.scrollTo(target, {
    smooth: 'easeInOutCubic',
    duration: 1000,
    offset: -24,
    containerId: 'ScheduleList',
  });
}

const AvailabilityViewMode = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useSelector(getCurrentUser);
  const renderDate = useSelector(getScheduleRenderDate);
  const changes = useSelector(getScheduleChanges);
  const appointmentProduct = useSelector(getScheduleAppointmentProduct);
  const appointmentProducts = useSelector(
    getScheduleAppointmentProducts
  )?.filter((prod) => prod.status === AppointmentProductStatus.Active);
  const [cancelAttempt, setCancelAttempt] = useState(false);
  const [weeklySchedulesFacility, setWeeklySchedulesFacility] =
    useState<Facility>();
  const [isSelectingFacility, setIsSelectingFacility] = useState(
    !appointmentProduct
  );
  const selectedFacility = appointmentProduct?.facility;
  const minDate = moment();
  const maxDate = moment().add(3, 'months');
  const minDatetime = moment().add(MIN_HOURS_UNTIL_PROPOSAL_START, 'hours');
  const [isLearnAvailabilityOpen, setLearningAvailability] = useState(false);
  const scheduleOpenings = useSelector(getScheduleOpenings);
  const fetchState = useSelector(getScheduleOpeningsFetchState);
  const isLoading =
    fetchState === FETCH_STATE.GET || fetchState === FETCH_STATE.PUT;

  useEffect(() => {
    scroller.scrollTo('CalendarTop', ScrollOptions);
    dispatch(fetchOpenings());
    return () => {
      dispatch(clearScheduleChanges());
    };
  }, [appointmentProduct, renderDate]);

  const handleSuccess = (redirect: boolean) => {
    if (user.status === Status.Pending) {
      dispatch(retrieveAccount(user.username));
    }
    dispatch(fetchAvailability(getMonthStartEndParams(renderDate), true));
    enqueueSnackbar({
      message: 'Changes saved successfully',
      variant: 'success',
      action: (snackbarId) => (
        <button
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          Dismiss
        </button>
      ),
    });
    if (redirect) {
      handleExit();
    }
  };

  const handleSave = async (redirect = false) => {
    if (changes.length === 0 && redirect) {
      handleExit();
    } else {
      const response = await dispatch(saveScheduleChanges());
      if (!response) {
        enqueueSnackbar(GenericServerError);
      } else {
        handleSuccess(redirect);
      }
    }
  };

  const handleDayClick = (date: string) => {
    if (moment(renderDate).isSame(date, 'month')) {
      const target = `${CAL_LIST_ITEM_NAME_PREFIX}-${date}`;
      scrollToTarget(target);
    }
  };

  const handleAppointmentProductClick = (
    appointmentProduct: AppointmentProduct
  ) => {
    dispatch(setAppointmentProduct(appointmentProduct));
  };

  const handleChangeLocation = () => {
    dispatch(setAppointmentProduct(undefined));
  };

  const handleCancel = () => {
    // When the Cancel button is clicked, we ask to confirm, then we clear the data completely
    if (cancelAttempt || changes.length === 0) {
      handleExit();
    } else {
      setCancelAttempt(true);
    }
  };

  const handleExit = () => {
    history.push(SHARED_ROUTES.SCHEDULE.ROOT);
  };

  const handleReset = () => {
    dispatch(clearScheduleChanges());
    handleExit();
  };

  return (
    <>
      {typeof appointmentProduct !== 'undefined' && (
        <>
          {fetchState === FETCH_STATE.GET && (
            <Loading message="Getting available times..." />
          )}
          {fetchState === FETCH_STATE.PUT && (
            <Loading message="Saving changes..." />
          )}
          <Prompt
            when={changes.length > 0}
            // DO NOT CHANGE FORMAT
            message={`Leave availability?
You will lose any unsaved changes.`}
            // DO NOT CHANGE FORMAT
          />
          <ScheduleTemplate
            minDate={minDate}
            maxDate={maxDate}
            isLoading={isLoading}
            mode="availability"
            Menu={() => (
              <>
                {appointmentProducts.length > 1 && (
                  <Button
                    onClick={handleChangeLocation}
                    variant="flat"
                    size="small"
                    shape="rounded-xl"
                    icon={<FacilityIcon width={20} />}
                  >
                    Location
                  </Button>
                )}
                <Button
                  disabled={isLoading}
                  to={SHARED_ROUTES.SCHEDULE.weekly}
                  variant="flat"
                  size="small"
                  shape="rounded-xl"
                  icon={<WeekIcon width={20} />}
                >
                  <span className="hidden sm:inline">Weekly schedule</span>
                  <span className="sm:hidden">Weekly</span>
                </Button>
                <Button
                  disabled={isLoading || changes.length === 0}
                  onClick={() => handleSave(false)}
                  color="primary"
                  variant="contained"
                  size="small"
                  shape="rounded-xl"
                  icon={<CheckIcon width={20} />}
                >
                  <span className="hidden sm:inline">Save Changes</span>
                  <span className="sm:hidden">Save</span>
                </Button>
              </>
            )}
            CalendarDayLoader={(props) => {
              const data = scheduleOpenings.find(
                (dt) => dt.date === props.date
              ) || {
                aptProdId: undefined,
                times: [],
                loading: false,
              };
              return (
                <AvailabilityCalendarDay
                  {...data}
                  {...props}
                  onClick={handleDayClick}
                />
              );
            }}
          >
            <>
              {scheduleOpenings.map((scheduleDate, i) => {
                return (
                  <Element
                    key={scheduleDate.date}
                    className="px-2 py-px sm:px-6"
                    name={`${CAL_LIST_ITEM_NAME_PREFIX}-${scheduleDate.date}`}
                  >
                    <div
                      className={`p-6 mx-auto space-y-6 bg-white rounded-2xl max-w-lg`}
                    >
                      <div>
                        <div className="flex gap-1">
                          <h2>{appointmentProduct.facility.displayName}</h2>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setWeeklySchedulesFacility(
                                appointmentProduct.facility
                              )
                            }
                          >
                            <EllipsisHorizontalCircleIcon width={24} />
                          </IconButton>
                        </div>
                        <h5>
                          {moment(scheduleDate.date).format(DATE_FMT.DOW_MON_D)}
                        </h5>
                      </div>
                      <TimeSlotList {...scheduleDate} mode="availability" />
                    </div>
                  </Element>
                );
              })}
              {scheduleOpenings.length > 5 && (
                <div className="px-2 py-px sm:px-6">
                  <div
                    className={`mx-auto items-center max-w-lg flex space-x-3 justify-between`}
                  >
                    <hr className="flex-1" />
                    <button
                      className="italic text-gray-600 hover:underline"
                      onClick={() => scrollToTarget('TopOfList')}
                    >
                      Back to top
                    </button>
                    <hr className="flex-1" />
                  </div>
                </div>
              )}
            </>
          </ScheduleTemplate>
          <LearningModule
            module={FleeqModule.AvailabilityOverview}
            show={isLearnAvailabilityOpen}
            onClose={() => setLearningAvailability(false)}
          />
        </>
      )}
      <Modal
        name={`Instructor — Availability, ${
          !!selectedFacility ? 'change location' : 'edit availability'
        }`}
        open={!appointmentProduct}
        // onClose={() => {
        //   if (appointmentProduct) {
        //     setIsSelectingFacility(false);
        //   }
        // }}
        title={!!selectedFacility ? 'Change location' : 'Edit availability'}
        maxWidth="xs"
      >
        {changes.length > 0 ? (
          <div className="space-y-3">
            <Callout type="question">
              You have {changes.length} unsaved{' '}
              {'change'.pluralize(changes.length)} at{' '}
              {selectedFacility?.shortName}. Would you like to save or discard{' '}
              {changes.length === 1 ? 'it' : 'them'}?
            </Callout>
            <Controls>
              <Button
                color="default"
                variant="flat"
                onClick={() => setIsSelectingFacility(false)}
              >
                Go back
              </Button>
              <Button
                color="default"
                variant="flat"
                onClick={() => {
                  dispatch(clearScheduleChanges());
                }}
              >
                Discard changes
              </Button>
              <Button
                color="primary"
                variant="flat"
                onClick={() => handleSave(false)}
              >
                Save changes
              </Button>
            </Controls>
          </div>
        ) : (
          <>
            <SelectAppointmentProduct
              onSelect={handleAppointmentProductClick}
            />
            <Controls>
              {appointmentProduct === undefined ? (
                <Button color="default" variant="flat" onClick={handleCancel}>
                  Quit
                </Button>
              ) : (
                <Button
                  color="default"
                  variant="flat"
                  onClick={() => setIsSelectingFacility(false)}
                >
                  Go back
                </Button>
              )}
            </Controls>
          </>
        )}
      </Modal>
      <Modal
        name="Instructor — Discard availability"
        title="Unsaved changes"
        open={cancelAttempt}
        onClose={() => setCancelAttempt(false)}
        maxWidth="sm"
      >
        <Callout type="question">
          You have {changes.length} unsaved {'change'.pluralize(changes.length)}
          {'. '}
          Would you like to save or discard{' '}
          {changes.length === 1 ? 'it' : 'them'}?
        </Callout>
        <Controls>
          <Button
            color="default"
            variant="text"
            onClick={() => setCancelAttempt(false)}
          >
            Go back
          </Button>
          <Button
            color="default"
            variant="flat"
            onClick={handleReset}
            disabled={isLoading}
          >
            Discard changes
          </Button>
          <Button
            color="primary"
            variant="flat"
            onClick={() => handleSave(true)}
            disabled={isLoading}
          >
            Save changes
          </Button>
        </Controls>
      </Modal>
      <Modal
        name="Facility Weekly Schedules"
        title="Weekly Schedules"
        open={weeklySchedulesFacility !== undefined}
        onClose={() => setWeeklySchedulesFacility(undefined)}
        maxWidth="sm"
        fullWidth
      >
        <div>
          {weeklySchedulesFacility && (
            <FacilityScheduleUpdates
              facility={weeklySchedulesFacility}
              truncate={false}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default AvailabilityViewMode;
