import InstructorListDetails from 'components/instructor-list-details';
import Link from 'components/link';
import UploadProfile from 'components/uploader/profile';
import React from 'react';

interface Props {
  specialization1: string;
  specialization2: string;
  segments: string[];
  avatar: string;
  displayName: string;
  numRecommended: number;
  basePrice: number;
  to?: string;
}

const Card = (props) => (
  <div className="flex">
    <div className="">
      <UploadProfile name="avatar" />
    </div>
    <div className="flex-2">
      <InstructorListDetails {...(props as any)} />
    </div>
  </div>
);

const InstructorCard = ({ to, ...rest }: Props) =>
  safe(to) ? (
    <Link className="w-full mx-0 my-2" to={to}>
      <Card {...rest} />
    </Link>
  ) : (
    <Card {...rest} />
  );

export default InstructorCard;
