import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import api from 'api';
import {
  AccountNoteSerializer,
  OnboardingStatus,
  OnboardingStatusText,
  Status,
} from 'api/Serializers/Accounts';
import {
  InstructorOnboardingStage,
  ReasonForJoiningText,
  SelfDescribedAsText,
  YearsExperienceText,
} from 'api/Serializers/Accounts/Instructor';
import { InstructorReliabilityHistory } from 'api/Serializers/Analytics';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Callout from 'components/callout';
import CertificationCard from 'components/certification-card';
import Controls from 'components/controls';
import DetailSection from 'components/detail-section';
import Loading from 'components/loading';
import { DATE_FMT } from 'config';
import AccountNotes from 'containers/account-notes';
import InstructorFacilitySettings from 'containers/instructor-facility-settings';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  AccountIcon,
  BusinessIcon,
  CancelIcon,
  DoneIcon,
  EmailIcon,
  FacilityIcon,
  MessagesIcon,
  PhoneIcon,
  PlaceIcon,
  UserStatusIcon,
} from 'icons';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getInstructorDetails, getInstructorOnboarding } from 'state/selectors';
import { modifyOnboarding } from 'state/slice/instructor';

const TabPanel = ({ value, index, children }) => (
  <div
    role="tabpanel"
    id={`tabpanel-${index}`}
    className={value === index ? 'block' : 'hidden'}
  >
    {children}
  </div>
);

const Reliability = () => {
  const [isLoading, setIsLoading] = useState(true);
  const instructor = useSelector(getInstructorDetails);
  const [reliability, setReliability] =
    useState<InstructorReliabilityHistory>();
  const fetchReliability = async () => {
    const response = await api.account.reliability(instructor.username);
    setReliability(response.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchReliability();
  }, []);

  return (
    <DetailSection title="Cancellation Data">
      {isLoading ? (
        <Loading position="inline" size={30} />
      ) : reliability ? (
        <div className="text-base">
          <div className="flex items-center justify-between py-1 hover:bg-gray-200">
            <div>Recent cancellation rate:</div>
            <div>{(reliability.recentCancellationRate * 100).toFixed(1)}%</div>
          </div>
          <div className="flex items-center justify-between py-1 hover:bg-gray-200">
            <div>Total cancellation rate:</div>
            <div>{(reliability.totalCancellationRate * 100).toFixed(1)}%</div>
          </div>
          <div className="flex items-center justify-between py-1 hover:bg-gray-200">
            <div>Outside 7 days:</div>
            <div>{reliability.numOutside7d}</div>
          </div>
          <div className="flex items-center justify-between py-1 hover:bg-gray-200">
            <div>Within 7 days:</div>
            <div>{reliability.numOutside48h}</div>
          </div>
          <div className="flex items-center justify-between py-1 hover:bg-gray-200">
            <div>Within 48 hours:</div>
            <div>{reliability.numWithin48h}</div>
          </div>
          <div className="flex items-center justify-between py-1 hover:bg-gray-200">
            <div>Same day:</div>
            <div>{reliability.numSameDay}</div>
          </div>
          <div className="flex items-center justify-between py-1 hover:bg-gray-200">
            <div>Within 2 hours:</div>
            <div>{reliability.numWithin2h}</div>
          </div>
          <div className="flex items-center justify-between py-1 hover:bg-gray-200">
            <div>Absent:</div>
            <div>{reliability.numNoShow}</div>
          </div>
        </div>
      ) : (
        <div className="text-base">No data yet</div>
      )}
    </DetailSection>
  );
};

const OnboardingNextSteps = () => {
  const instructor = useSelector(getInstructorDetails);
  const onboarding = useSelector(getInstructorOnboarding);
  if (instructor.status === Status.Pending) {
    switch (onboarding.stage) {
      case InstructorOnboardingStage.CallLimbo:
        return (
          <Callout title="Schedule a Call" type="info">
            User must schedule their onboarding call
          </Callout>
        );
      case InstructorOnboardingStage.Orientation:
        return (
          <Callout title="Awaiting Call" type="info">
            User is awaiting their onboarding call
          </Callout>
        );
      case InstructorOnboardingStage.Launch:
        if (instructor.isTosSigned) {
          return (
            <Callout title="Sign TOS" type="info">
              User must sign the TOS
            </Callout>
          );
        } else {
          return (
            <Callout title="Add Availability" type="info">
              User must add their first availability
            </Callout>
          );
        }
      default:
        return <Callout title="Unknown" />;
    }
  } else if (instructor.status === Status.Active) {
    switch (onboarding.stage) {
      case InstructorOnboardingStage.GetBooked:
        return (
          <Callout title="Awaiting First Booking" type="info">
            User is awaiting their first lesson booking
          </Callout>
        );
      case InstructorOnboardingStage.Prepare:
        return (
          <Callout title="Preparing for First Lesson" type="info">
            User has been booked and is preparing for their first lesson
          </Callout>
        );
      case InstructorOnboardingStage.Complete:
        return (
          <Callout title="Active" type="success">
            User is actively teaching
          </Callout>
        );
      default:
        return (
          <Callout title="Active" type="success">
            User is actively teaching
          </Callout>
        );
    }
  } else if (instructor.status === Status.Inactive) {
    return (
      <Callout title="Inactive" type="warning">
        User is currently inactive (no future lessons or availability)
      </Callout>
    );
  } else if (instructor.status === Status.Done) {
    return (
      <Callout title="Done" type="info">
        User has stated they are done with Propel
      </Callout>
    );
  } else if (instructor.status === Status.Hiatus) {
    return (
      <Callout title="Hiatus" type="info">
        User is taking a break from Propel
      </Callout>
    );
  } else {
    return (
      <Callout title="Banned" type="error">
        User has been removed from Propel with a status of {instructor.status}
      </Callout>
    );
  }
};

const OnboardingTab = () => {
  const dispatch = useAppDispatch();
  const instructor = useSelector(getInstructorDetails);
  const onboarding = useSelector(getInstructorOnboarding);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingStatus, setEditingStatus] = useState(
    onboarding.status === OnboardingStatus.Pending
  );

  const onStatusConfirm = (status: OnboardingStatus) => {
    dispatch(modifyOnboarding(instructor.username, { status }));
    setIsLoading(true);
    setEditingStatus(false);
  };
  return (
    <>
      <DetailSection title="Next Steps">
        <OnboardingNextSteps />
      </DetailSection>
      <DetailSection title="Onboarding">
        <table cellPadding={5}>
          <tbody>
            <tr>
              <td>Step:</td>
              <td>{onboarding.lastStepId.replace('-', ' ').capitalize()}</td>
            </tr>
            <tr>
              <td>Signup Date:</td>
              <td>
                {moment(instructor.dateCreated).format(DATE_FMT.MONTH_D_YEAR)}
              </td>
            </tr>
            <tr>
              <td>Reason for Joining:</td>
              <td>{ReasonForJoiningText[onboarding.reasonToJoin]}</td>
            </tr>
            <tr>
              <td>Self Described:</td>
              <td>{SelfDescribedAsText[onboarding.selfDescribedAs]}</td>
            </tr>
            <tr>
              <td>Experience:</td>
              <td>{YearsExperienceText[`Exp${onboarding.numYearsExp}`]}</td>
            </tr>
            <tr>
              <td>Teaches:</td>
              <td>{instructor.segments.join(', ')}</td>
            </tr>
            <tr>
              <td>Orientation Call:</td>
              <td>
                {onboarding.callDatetime
                  ? moment(onboarding.callDatetime).format(
                      DATE_FMT.MONTH_D_TIME_A
                    )
                  : 'No call scheduled'}
              </td>
            </tr>
            <tr>
              <td>Last Login:</td>
              <td>
                {moment(instructor.lastLogin).format(DATE_FMT.MONTH_D_YEAR)}
              </td>
            </tr>
          </tbody>
        </table>
      </DetailSection>
      <DetailSection title="Learning Modules">
        <div className="flex items-center text-sm">
          {onboarding.hasLearnedAvailability ? (
            <DoneIcon width={24} className="mr-2 text-green-600" />
          ) : (
            <CancelIcon width={24} className="mr-2 text-red-500" />
          )}{' '}
          Availability
        </div>
      </DetailSection>
      <DetailSection title="Certifications">
        <div className="flex flex-col items-center">
          {instructor.certifications.map((certification, i) => (
            <div className="p-4">
              <CertificationCard
                key={i}
                certification={certification}
                account={instructor}
              />
            </div>
          ))}
        </div>
      </DetailSection>
      <DetailSection title={`Status`}>
        <div className="my-4">
          {onboarding.vettedBy ? (
            <Callout
              type="success"
              title={`${OnboardingStatusText[onboarding.status]} by ${
                onboarding.vettedBy.displayName
              }`}
            />
          ) : onboarding.status === OnboardingStatus.Pending ? (
            <Callout type="question" title="Instructor Status is Pending" />
          ) : onboarding.status === OnboardingStatus.Rejected ? (
            <Callout type="error" title="Instructor has been Rejected" />
          ) : onboarding.status === OnboardingStatus.Accepted ? (
            <Callout type="success" title="Instructor has been Accepted" />
          ) : null}
        </div>
        <Controls>
          {onboarding.status !== OnboardingStatus.Pending && (
            <Button
              variant="flat"
              color="default"
              onClick={() => setEditingStatus(true)}
              disabled={isEditingStatus}
            >
              Change
            </Button>
          )}
          <Button
            variant="flat"
            color="secondary"
            onClick={() => onStatusConfirm(OnboardingStatus.Rejected)}
            disabled={!isEditingStatus}
            icon={<CancelIcon width={24} />}
          >
            Reject or Ban
          </Button>
          <Button
            variant="flat"
            color="primary"
            onClick={() => onStatusConfirm(OnboardingStatus.Accepted)}
            disabled={!isEditingStatus}
            icon={<DoneIcon width={24} />}
          >
            Approve
          </Button>
        </Controls>
      </DetailSection>
    </>
  );
};

const NotesTab = () => {
  const instructor = useSelector(getInstructorDetails);

  return (
    <DetailSection title="Notes">
      <AccountNotes username={instructor.username} />
    </DetailSection>
  );
};

const getStatusChipStyle = (status: Status) =>
  status === Status.Active
    ? 'px-2 py-1 bg-green-200 text-green-700 rounded-full'
    : status === Status.Inactive
    ? 'px-2 py-1 bg-yellow-200 text-yellow-700 rounded-full'
    : status === Status.Done
    ? 'px-2 py-1 bg-blue-200 text-blue-700 rounded-full'
    : status === Status.Hiatus
    ? 'px-2 py-1 bg-purple-200 text-purple-700 rounded-full'
    : status === Status.Pending
    ? 'px-2 py-1 bg-orange-200 text-orange-700 rounded-full'
    : 'px-2 py-1 bg-gray-200 text-gray-700 rounded-full';

const Header = () => {
  const onboarding = useSelector(getInstructorOnboarding);
  const instructor = useSelector(getInstructorDetails);
  return (
    <div>
      <div className="flex items-center flex-1 p-6">
        <Avatar src={instructor.avatar} diameter={40} variant="rounded" />
        <div className="flex-1 ml-3 space-y-2 text-sm">
          <h2 className="text-base">{instructor.fullName}</h2>
          <div className="labeled-icon small">
            <UserStatusIcon />
            <span>
              <span className={getStatusChipStyle(instructor.status)}>
                {instructor.status.capitalize()},{' '}
                {OnboardingStatusText[onboarding.status]}
              </span>
            </span>
          </div>
          <div className="labeled-icon small">
            <EmailIcon />
            <a href={`mailto:${instructor.email}`}>{instructor.email}</a>
          </div>
          <div className="labeled-icon small">
            <PhoneIcon />
            <a href={`tel:${instructor.phoneNumber}`}>
              {instructor.phoneNumber}
            </a>
          </div>
          <div className="labeled-icon small">
            <PlaceIcon />
            <span>
              {instructor.address.city}, {instructor.address.region}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const DetailsTab = () => {
  const instructor = useSelector(getInstructorDetails);
  return (
    <>
      <Reliability />
      <DetailSection title="Profile Details">
        <p className="whitespace-pre-wrap">{instructor.description}</p>
      </DetailSection>
      <DetailSection title="Emergency Contact">
        <div className="my-4 space-y-3 text-sm">
          <div className="labeled-icon small">
            <AccountIcon />
            <span>{instructor.emergencyContact?.name}</span>
          </div>
          <div className="labeled-icon small">
            <PhoneIcon />
            <a href={`tel:${instructor.emergencyContact?.phoneNumber}`}>
              {instructor.emergencyContact?.phoneNumber}
            </a>
          </div>
        </div>
      </DetailSection>
    </>
  );
};

const LaunchedDetail = ({ onClose }) => {
  const history = useHistory();
  const instructor = useSelector(getInstructorDetails);
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<number>(0);
  const [notes, setNotes] = useState<AccountNoteSerializer[]>(undefined);

  const fetchAccountNotes = async () => {
    const response = await api.notes.list(instructor.username);
    setNotes(response.data);
  };

  useEffect(() => {
    fetchAccountNotes();
  }, []);

  const NotesTabIcon = () => (
    <span className="relative">
      <MessagesIcon width={24} />
      {notes?.length > 0 && (
        <span className="-top-1 text-white bg-red-600 -right-1.5 notification">
          {notes.length}
        </span>
      )}
    </span>
  );

  return (
    <div className="min-h-90vh">
      <div>
        <Header />
        <Tabs
          textColor="primary"
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
          scrollButtons="auto"
          variant="fullWidth"
        >
          <Tab icon={<AccountIcon width={24} />} />
          <Tab icon={<FacilityIcon width={24} />} />
          <Tab icon={<BusinessIcon width={24} />} />
          <Tab icon={<NotesTabIcon />} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <DetailsTab />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <InstructorFacilitySettings username={instructor.username} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <OnboardingTab />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <NotesTab />
        </TabPanel>
      </div>
      <div className="sticky bottom-0 px-4 m-0 bg-white border-t border-gray-300">
        <Controls>
          <Button variant="flat" onClick={onClose}>
            Close
          </Button>
        </Controls>
      </div>
    </div>
  );
};

const LaunchedDetailContainer = ({ onClose }) => {
  const instructor = useSelector(getInstructorDetails);
  const onboarding = useSelector(getInstructorOnboarding);

  if (!instructor || !onboarding) {
    return null;
  }
  return <LaunchedDetail onClose={onClose} />;
};

export default LaunchedDetailContainer;
