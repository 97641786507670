import api from 'api';
import { AppointmentProduct } from 'api/Serializers/AppointmentProducts';
import { Availability } from 'api/Serializers/Availability';
import { CartStatus } from 'api/Serializers/Cart';
import { ClientAccount, Participant } from 'api/Serializers/Clients';
import { PaymentMethodSerializer } from 'api/Serializers/PaymentMethods';
import AvailabilitySelect from 'components/availability-select';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import CreditCardDetails from 'components/credit-card-details';
import Link from 'components/link';
import Loading from 'components/loading';
import Modal from 'components/modal';
import {
  APP_ROOT_URL,
  CANCEL_POLICY_HRS,
  DATE_FMT,
  IS_SERVER,
  PAYMENT_AUTH_DAYS,
  PAYMENT_SETTLEMENT_HOURS,
  QueryParams,
  UserType,
} from 'config';
import AuthenticationModal from 'containers/authentication';
import SelectParticipants from 'containers/participant-select';
import PaymentMethodSelect from 'containers/payment-select';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import { MoneyIcon, OneParticipantIcon, TwoParticipantIcon } from 'icons';
import { GenericServerError } from 'lang/en/Snackbars';
import { RouteParams } from 'models/route';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getAccountCredit,
  getAccountDetail,
  getAccountParticipants,
  getActivity,
  getAppointments,
  getCart,
  getCartAppointmentProduct,
  getCartAvailability,
  getCartCheckoutMethod,
  getCartError,
  getCartNumParticipants,
  getCartParticipants,
  getCartPaymentMethod,
  getCartPriceDetails,
  getCartSource,
  getCartStatus,
  getIsAuthenticated,
  getUser,
  getUsername,
} from 'state/selectors';
import {
  fetchAccountCredit,
  fetchAccountParticipants,
} from 'state/slice/account';
import { fetchClientAppointments } from 'state/slice/appointments';
import {
  addAvailabilityToCart,
  checkout,
  clearCart,
  resetCart,
  setCartAppointmentProduct,
  setCartParticipants,
  setCartPaymentMethod,
  setCartUTMData,
  setNumParticipants,
  trackCartChanges,
} from 'state/slice/cart';
import { APP_ROUTES, getUTMDataFromParams, SHARED_ROUTES } from 'utils/routing';

interface StepProps<T> {
  state: StepState;
  onClickContinue(data?: any): void;
  onClickEdit(): void;
  error?: T;
}
export type BookingStep =
  | 'datetime'
  | 'participants'
  | 'payment'
  | 'checkout'
  | 'confirmation';
type PaymentMethodError = 'missing';
type StepState = 'hidden' | 'complete' | 'editing';
type UserBookingError = 'invalid_user_type' | 'not_authenticated';
type BookingData = {
  appointmentProduct: AppointmentProduct;
  selectedAvailability: Availability;
  participants?: Participant[];
  paymentMethod?: PaymentMethodSerializer;
};

const orderOfSteps: BookingStep[] = [
  'datetime',
  'participants',
  'payment',
  'checkout',
  'confirmation',
];

const getStepLink = (step: BookingStep, data: BookingData) => {
  let params = location.search;
  if (data?.selectedAvailability !== undefined) {
    let availabilityParam = `${QueryParams.Availability}=${data?.selectedAvailability?.id}`;
    params = params
      ? params + '&' + availabilityParam
      : '?' + availabilityParam;
  }
  return `${APP_ROUTES.BOOK.nav(data?.appointmentProduct.id, step)}${params}`;
};

const Divider = () => <div className="h-1 -mx-6 bg-gray-400 md:h-px md:mx-0" />;

const getCanEditCart = (status: CartStatus) => {
  return ['EMPTY', 'STARTED', 'ERROR'].includes(status);
};

const BookingHeader = ({
  title,
  onEdit,
}: {
  title: string;
  onEdit?(): void;
}) => {
  const cartStatus = useSelector(getCartStatus);
  const canEditCart = getCanEditCart(cartStatus);
  const handleClickEdit = () => {
    rudderanalytics.track(`Cart Edit clicked`, { action: title });
    onEdit();
  };
  return (
    <div className="flex justify-between mb-2">
      <h3 className="my-0 font-semibold text-md">{title}</h3>
      {onEdit && canEditCart && (
        <button className="link" onClick={handleClickEdit}>
          Edit
        </button>
      )}
    </div>
  );
};

export const PolicyAgreements = () => {
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const selectedAvailability = useSelector(getCartAvailability);

  if (selectedAvailability === undefined) {
    // HACK: fixes bug that's been popping up, but dont know how it's possible
    return null;
  }

  const hasSpacePolicy = appointmentProduct.facility.category === 'Hotel';
  const cancellationDateTime = moment(selectedAvailability.start)
    .tz(appointmentProduct.timezone)
    .subtract(CANCEL_POLICY_HRS, 'hours');
  const now = moment();
  const authDt = moment(selectedAvailability.start)
    .tz(appointmentProduct.timezone)
    .subtract(PAYMENT_AUTH_DAYS * 24, 'hours');
  const outsideAuthWindow = now.isBefore(authDt);
  const isLastMinuteBooking = moment(selectedAvailability.created).isAfter(
    moment(selectedAvailability.start).subtract(48, 'hours')
  );
  return (
    <>
      <Divider />
      {!isLastMinuteBooking ? (
        <div>
          <h3 className="my-0 font-semibold text-md">Book now, pay later</h3>
          <p>
            {outsideAuthWindow
              ? `Your card will be pre-authorized ${PAYMENT_AUTH_DAYS} days prior to the ${appointmentProduct.activity.appointmentNoun.toLowerCase()}, then charged ${PAYMENT_SETTLEMENT_HOURS} hours after.`
              : `Your card will be pre-authorized immediately, then charged ${PAYMENT_SETTLEMENT_HOURS} hours after the ${appointmentProduct.activity.appointmentNoun.toLowerCase()}.`}
          </p>
          <p>
            Any account credit will be used immediately towards the total. We'll
            also check for credit before your card is charged.
          </p>
        </div>
      ) : (
        <div>
          <h3 className="my-0 font-semibold text-md">Payment</h3>
          <p>
            Your card will be immediately charged. Existing account credit will
            be applied before a card charge is made.
          </p>
        </div>
      )}
      <div className="space-y-6">
        {!isLastMinuteBooking ? (
          <div>
            <BookingHeader
              title={`${CANCEL_POLICY_HRS}-hour cancellation policy`}
            />
            <p>
              <strong>
                Free cancellation before{' '}
                {cancellationDateTime.format(DATE_FMT.TIME_A)} on{' '}
                {cancellationDateTime.format(DATE_FMT.MONTH_D)}
              </strong>
              . After that, the{' '}
              {appointmentProduct.activity.appointmentNoun.toLowerCase()} is
              non-refundable.
            </p>
          </div>
        ) : (
          <div>
            <BookingHeader title="Cancellation policy" />
            <p>
              Bookings confirmed within 48 hours of the lesson start time are
              final. If you cancel, this lesson will not be eligible for a
              refund.
            </p>
          </div>
        )}
        {hasSpacePolicy && (
          <Modal
            name="Checkout — Pool space policy"
            open={showPolicyModal}
            onClose={() => setShowPolicyModal(false)}
            title="Pool space policy"
            maxWidth="sm"
          >
            <p>
              In the rare event that hotel guests and swimmers cannot both use
              the space without obstructing each other, the instructor must
              yield and cancel the lesson. You will not be charged, your
              instructor will be compensated, and Propel provide you with a $20
              credit for the inconvenience.
            </p>
          </Modal>
        )}
        <div>
          <p className="text-xs">
            By selecting the button below, I agree to the{' '}
            {!isLastMinuteBooking ? `${CANCEL_POLICY_HRS}-hour ` : ''}
            Cancellation Policy
            {hasSpacePolicy && (
              <>
                {' and the '}
                <span className="link" onClick={() => setShowPolicyModal(true)}>
                  Pool Space Policy
                </span>
              </>
            )}
            . I also agree to Propel's{' '}
            <Link to={APP_ROUTES.PRIVACY} target="_blank">
              Privacy Policy
            </Link>
            {' and '}
            <Link to={APP_ROUTES.TERMS_OF_SERVICE} target="_blank">
              Terms of Service
            </Link>
            .
          </p>
        </div>
      </div>
    </>
  );
};

const PriceTagline = () => {
  const activity = useSelector(getActivity);
  return (
    <div className="my-4">
      <div className="text-sm italic text-center text-gray-700">
        Total includes everything{' — '}
        {activity.instructorDescription.toLowerCase()} fees, admission to{' '}
        {activity.facilityDescription.toLowerCase()}, and any taxes. Payment
        occurs {PAYMENT_SETTLEMENT_HOURS}-hours after the{' '}
        {activity.appointmentNoun.toLowerCase()}.
      </div>
    </div>
  );
};

export const AppointmentProductPriceDetails = () => {
  const priceDetails = useSelector(getCartPriceDetails);
  if (!priceDetails) {
    return null;
  }
  return (
    <div>
      <BookingHeader title="Price details" />
      <div className="">
        {priceDetails.lineItems.map((li) => {
          return (
            <div key={li.id} className="flex justify-between space-x-2">
              <span>{li.title}</span>
              <span>{li.price.toCurrency()}</span>
            </div>
          );
        })}
        <hr />
        <div className="flex justify-between space-x-2 font-semibold">
          <span>Total ({priceDetails.currency})</span>
          <span>{priceDetails.total.toCurrency()}</span>
        </div>
        <PriceTagline />
      </div>
    </div>
  );
};

const AccountCredit = () => {
  const credit = useSelector(getAccountCredit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAccountCredit());
  }, []);
  if (!credit || credit < 1) {
    return null;
  }
  return (
    <div>
      <BookingHeader title="Account credit" />
      <div className="flex space-x-2">
        <MoneyIcon width={20} className="text-green-600" />
        <span className="text-base font-medium">{credit?.toCurrency()}</span>
        <span className="text-sm italic text-gray-600">
          Automatically applied at booking
        </span>
      </div>
    </div>
  );
};

export const SelectPaymentMethodAndBookNow = ({
  state,
  onClickContinue,
  onClickEdit,
}: StepProps<string>) => {
  if (state === 'hidden') {
    return null;
  }
  const dispatch = useAppDispatch();
  const history = useHistory();
  const username = useSelector(getUsername);
  const appointments = useSelector(getAppointments);
  const account = useSelector(getAccountDetail) as ClientAccount;
  const paymentMethods = account?.paymentMethods || [];
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const selectedAvailability = useSelector(getCartAvailability);
  const selectedPaymentMethod = useSelector(getCartPaymentMethod);
  const cartStatus = useSelector(getCartStatus);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const cartError = useSelector(getCartError);
  const [error, setError] = useState<PaymentMethodError>(undefined);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const isLastMinuteBooking = moment(selectedAvailability.created).isAfter(
    moment(selectedAvailability.start).subtract(48, 'hours')
  );

  const sameDayAppt = appointments.find(
    (appt) => appt.date === selectedAvailability.date && !appt.cancelled
  );

  const handleBookNow = async () => {
    // By using the state variable isSubmitted with useEffect, we can guard
    // against double submissions between state transitions
    setIsSubmitted(true);
    await dispatch(checkout());
    dispatch(
      fetchClientAppointments({
        start: selectedAvailability.date,
        end: selectedAvailability.date,
      })
    );
    setIsSubmitted(false);
  };

  const handleCardSelect = (paymentMethod: PaymentMethodSerializer) => {
    dispatch(setCartPaymentMethod(paymentMethod));
    onClickContinue();
    setError(undefined);
  };

  useEffect(() => {
    if (paymentMethods?.length > 0 && !selectedPaymentMethod) {
      let bestGuess = paymentMethods.find((pm) => pm.isDefault);
      if (!bestGuess) {
        bestGuess = paymentMethods.sort((a, b) =>
          a.created > b.created ? -1 : 1
        )[0];
      }
      if (bestGuess) {
        handleCardSelect(bestGuess);
      }
    } else if (typeof selectedPaymentMethod !== 'undefined') {
      onClickContinue();
    }
  }, []);

  if (!username) {
    history.push(
      getStepLink('participants', {
        appointmentProduct,
        selectedAvailability,
      })
    );
    return <Loading />;
  }

  return (
    <>
      <Divider />
      <div className="space-y-6">
        <div className="md:hidden">
          <AppointmentProductPriceDetails />
        </div>
        <AccountCredit />
        <div>
          <BookingHeader
            title="Payment method"
            onEdit={state === 'editing' ? null : onClickEdit}
          />
          {state === 'editing' && (
            <div className="flex-1 max-w-sm">
              <p>A valid payment method is required for all bookings.</p>
              <PaymentMethodSelect
                stripeConfigReturnUrl={`${APP_ROOT_URL}${getStepLink(
                  'payment',
                  {
                    appointmentProduct,
                    selectedAvailability,
                  }
                )}`}
                onSelect={handleCardSelect}
              />
              {error && (
                <div className="input-error-message">
                  Please select a payment method
                </div>
              )}
            </div>
          )}
        </div>
        {state !== 'editing' && (
          <>
            <CreditCardDetails
              paymentMethod={selectedPaymentMethod}
              showDefaultBadge={false}
            />
            {cartError?.error === 'payment_method_failed' && (
              <Callout type="error" title="Payment method failed">
                {cartError.message}
              </Callout>
            )}
            <PolicyAgreements />
          </>
        )}
      </div>
      {cartStatus === 'COMPLETE' ? (
        <Callout type="success" title="Booking complete!"></Callout>
      ) : (
        <>
          <Controls>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={() =>
                isLastMinuteBooking
                  ? setShowConfirmation(true)
                  : handleBookNow()
              }
              disabled={
                state !== 'complete' ||
                isSubmitted ||
                !getCanEditCart(cartStatus)
              }
            >
              Book now
            </Button>
          </Controls>
        </>
      )}
      <Modal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        title="Confirm booking?"
        name="non-refundable-confirmation"
        maxWidth="xs"
      >
        <p className="my-2">
          If you cancel, this lesson will not be eligible for a refund.
        </p>
        {sameDayAppt !== undefined && (
          <Callout className="my-4" type="warning">
            You have an existing booking on the same day at{' '}
            {moment(sameDayAppt.start).format(DATE_FMT.TIME_A)} which is no
            longer eligible for a refund.
            <div className="mt-4">
              <Link
                className="!underline !hover:underline" // HACK: Forcing the underline because the Link can't tell its child is a string
                to={SHARED_ROUTES.SCHEDULE.appointment(sameDayAppt.id)}
                target="_blank"
              >
                View existing appointment on{' '}
                {moment(sameDayAppt.start).format(DATE_FMT.MON_D_ORDINAL)}
              </Link>
            </div>
          </Callout>
        )}

        <Controls className="!pb-0">
          <Button
            variant="flat"
            color="primary"
            onClick={() => {
              setShowConfirmation(false);
              handleBookNow();
            }}
          >
            Confirm
          </Button>
          <Button variant="flat" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
        </Controls>
      </Modal>
    </>
  );
};

const EditCartParticipants = ({
  onClickContinue,
}: Pick<StepProps<string>, 'onClickContinue'>) => {
  const maxParticipants = 2; // one day this may be set by the activity data
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const username = useSelector(getUsername);
  const selectedParticipants = useSelector(getCartParticipants);
  const accountParticipants = useSelector(getAccountParticipants);
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const numParticipants = useSelector(getCartNumParticipants);
  const participantNoun =
    appointmentProduct.activity.clientDescription.toLowerCase();
  const [showError, setShowError] = useState(false);
  const [showAuthenticationModal, setShowAuthModal] = useState(false);

  const handleNumParticipants = (num: number) => {
    if (num > maxParticipants || num < 1) {
      return;
    }
    dispatch(setNumParticipants(num));
    setShowError(false);
  };

  const handleContinue = () => {
    const isError = numParticipants !== selectedParticipants.length;
    setShowError(isError);
    if (!isError) {
      onClickContinue(selectedParticipants);
    }
  };

  const handleAuthClose = (isAuthenticated: boolean) => {
    if (!isAuthenticated) {
      dispatch(trackCartChanges('Cart Authentication declined'));
      enqueueSnackbar({
        message: 'You must sign in or create an account to continue',
        variant: 'error',
      });
    } else {
      dispatch(trackCartChanges('Cart updated', 'Signed in'));
    }
    setShowAuthModal(false);
  };

  const handleParticipantSelect = (
    position: number,
    participant: Participant
  ) => {
    const participants = Array(numParticipants)
      .fill(0)
      .map((_, i) => (i === position ? participant : selectedParticipants[i]));
    dispatch(setCartParticipants(participants));
  };

  useEffect(() => {
    if (
      accountParticipants !== undefined &&
      numParticipants > selectedParticipants.length
    ) {
      const participants = [];
      for (var i = 0; i < numParticipants; i++) {
        if (selectedParticipants[i] !== undefined) {
          participants.push(selectedParticipants[i]);
        } else {
          const candidate = accountParticipants.find(
            (p) =>
              selectedParticipants.findIndex((s) => s.id === p.id) === -1 &&
              participants.findIndex((s) => s.id === p.id) === -1
          );
          participants.push(candidate);
        }
      }
      dispatch(setCartParticipants(participants));
    }
  }, [numParticipants]);

  return (
    <>
      <div className="mb-4 space-y-3">
        <h3 className="font-semibold text-md">Select {participantNoun}s</h3>
        <p>
          How many {participantNoun}s will be in the{' '}
          {appointmentProduct.activity.appointmentNoun.toLowerCase()}?
        </p>
        <div className="hidden gap-2 md:flex ">
          <ButtonLarge
            icon={<OneParticipantIcon width={24} />}
            title={`1 ${participantNoun.capitalize()}`}
            onClick={() => handleNumParticipants(1)}
            selected={numParticipants === 1}
          />
          <ButtonLarge
            icon={<TwoParticipantIcon width={24} />}
            title={`2 ${participantNoun.capitalize()}s`}
            onClick={() => handleNumParticipants(2)}
            selected={numParticipants === 2}
          />
        </div>
        <div className="flex gap-2 md:hidden">
          <ButtonLarge
            icon={<OneParticipantIcon width={24} />}
            title={`1 ${participantNoun.capitalize()}`}
            subtitle={`${appointmentProduct.price.toCurrency()}/hr`}
            onClick={() => handleNumParticipants(1)}
            selected={numParticipants === 1}
          />
          <ButtonLarge
            icon={<TwoParticipantIcon width={24} />}
            title={`2 ${participantNoun.capitalize()}s`}
            subtitle={`${(
              appointmentProduct.price +
              appointmentProduct.extraParticipantPrice
            ).toCurrency()}/hr`}
            onClick={() => handleNumParticipants(2)}
            selected={numParticipants === 2}
          />
        </div>
        {!username ? (
          <div className="max-w-md mx-auto">
            <div className="md:hidden">
              <PriceTagline />
            </div>
            <div className="p-4 mt-12 shadow-lg sm:p-6 bg-background rounded-2xl">
              <div className="mb-4 font-bold text-center text-md">
                Manage bookings and chat with your instructor, anytime!
              </div>
              <div className="mb-6 text-center">
                To book your{' '}
                {appointmentProduct.activity.appointmentNoun.toLowerCase()} and
                access all the great features of Propel, sign in or create an
                account.
              </div>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setShowAuthModal(true)}
              >
                Sign in or create your account
              </Button>
            </div>
          </div>
        ) : (
          <>
            {numParticipants !== undefined && (
              <SelectParticipants
                username={username}
                disabled={typeof numParticipants === 'undefined'}
                errorIfEmpty={showError}
                participantNoun={participantNoun}
                numParticipants={numParticipants}
                selectedParticipants={selectedParticipants}
                onSelect={(position: number, participant: Participant) =>
                  handleParticipantSelect(position, participant)
                }
              />
            )}
            {showError && (
              <Callout
                type="error"
                title={`Enter all ${participantNoun}s to continue`}
              >
                Help your{' '}
                {appointmentProduct.activity.instructorDescription.toLowerCase()}{' '}
                prepare by letting them know who is coming!
              </Callout>
            )}
            <Controls>
              <Button
                color="primary"
                variant="contained"
                onClick={handleContinue}
              >
                Continue
              </Button>
            </Controls>
          </>
        )}
        <AuthenticationModal
          flow="BookingFlow"
          initialForm="login"
          defaultUserType={UserType.Client}
          onClose={handleAuthClose}
          isOpen={showAuthenticationModal}
        />
      </div>
    </>
  );
};

export const SelectCartParticipants = ({
  state,
  onClickContinue,
  onClickEdit,
}: StepProps<string>) => {
  if (state === 'hidden') {
    return null;
  }
  const selectedParticipants = useSelector(getCartParticipants);
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const participantNoun =
    appointmentProduct.activity.clientDescription.toLowerCase();

  return (
    <div>
      {state === 'complete' ? (
        <div>
          <BookingHeader
            onEdit={onClickEdit}
            title={`${selectedParticipants.length} ${participantNoun
              .capitalize()
              .pluralize(selectedParticipants.length)} `}
          />
          <div className="space-y-4">
            {selectedParticipants.map((participant) => (
              <div key={participant.id}>
                <div className="labeled-icon">
                  <OneParticipantIcon width={18} />
                  <span className="font-semibold">{participant.name}</span>
                </div>
                <div className="ml-6 pl-1.5">
                  <p className="!leading-normal text-sm mb-0 whitespace-pre-wrap">
                    {participant.notes !== ''
                      ? participant.notes
                      : 'No notes given'}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <EditCartParticipants onClickContinue={onClickContinue} />
      )}
    </div>
  );
};

const SelectAvailability = ({
  state,
  error,
  onClickContinue,
  onClickEdit,
}: StepProps<UserBookingError>) => {
  if (state === 'hidden') {
    return null;
  }
  const selectedAvailability = useSelector(getCartAvailability);
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const cartSource = useSelector(getCartSource);
  const toLocal = (dt: string, timezone: string) => moment(dt).tz(timezone);

  return appointmentProduct === undefined ? (
    <Loading />
  ) : state === 'complete' ? (
    <>
      <div className="space-y-2">
        <BookingHeader title="Date & time" onEdit={onClickEdit} />
        <div>
          {selectedAvailability !== undefined ? (
            <>
              {toLocal(
                selectedAvailability.start,
                appointmentProduct.timezone
              ).format(DATE_FMT.MONTH_D_YEAR)}{' '}
              {toLocal(
                selectedAvailability.start,
                appointmentProduct.timezone
              ).format(DATE_FMT.TIME)}
              {'-'}
              {toLocal(
                selectedAvailability.end,
                appointmentProduct.timezone
              )?.format(DATE_FMT.TIME_A)}
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
    </>
  ) : (
    <AvailabilitySelect
      appointmentProductId={appointmentProduct.id}
      source={cartSource ?? 'RECAPTURE'}
    />
  );
};

const CartStatusMessage = ({ onCartReset }) => {
  const [showModal, setShowModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const availability = useSelector(getCartAvailability);
  const cartStatus = useSelector(getCartStatus);
  const error = useSelector(getCartError);

  useEffect(() => {
    setShowModal(['ERROR', 'COMPLETE'].indexOf(cartStatus) > -1);
    if (cartStatus === 'ERROR') {
      enqueueSnackbar({
        message: error.message,
        variant: 'error',
      });
    }
  }, [cartStatus]);

  const handleBookMoreClick = () => {
    rudderanalytics.track('Post Purchase Book More clicked');
    onCartReset();
  };

  const handleMessageInstructorClick = () => {
    rudderanalytics.track('Post Purchase Message Instructor clicked');
    onCartReset();
  };

  const getLocalStart = () => {
    return moment(availability.start).tz(appointmentProduct.timezone);
  };
  const title =
    cartStatus === 'COMPLETE'
      ? 'Thanks for booking!'
      : cartStatus === 'ERROR'
      ? 'Booking not complete'
      : null;
  return (
    <>
      {cartStatus === 'PROCESSING' && (
        <Loading message={`We're confirming your booking...`} />
      )}
      <Modal
        name={`Checkout — ${title}`}
        open={showModal}
        title={title}
        maxWidth="sm"
      >
        {cartStatus === 'COMPLETE' ? (
          <div>
            <p>
              You have booked a 1 hour{' '}
              {appointmentProduct.activity.name.toLowerCase()} with{' '}
              <strong>{appointmentProduct.instructor.displayName}</strong> at{' '}
              <strong>{appointmentProduct.facility.displayName}</strong> for{' '}
              <strong>
                {getLocalStart().format(DATE_FMT.MONTH_D_YEAR_TIME_A)}
              </strong>
              . Would you like to book more?
            </p>
            <Controls>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBookMoreClick}
              >
                Book more
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleMessageInstructorClick}
                to={SHARED_ROUTES.MESSAGES.nav(
                  availability.instructor.messageId
                )}
              >
                Message my{' '}
                {appointmentProduct.activity.instructorDescription.toLowerCase()}
              </Button>
            </Controls>
          </div>
        ) : cartStatus === 'ERROR' ? (
          <div>
            <Callout type="error" title="Your booking is not complete">
              {error.message}
            </Callout>
            <Controls>
              <Button variant="contained" onClick={() => setShowModal(false)}>
                Go back and fix error
              </Button>
            </Controls>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

type BookingState = 'loading' | 'ready' | 'error';

export const getBookingStepState = (
  current: BookingStep,
  target: BookingStep
): StepState =>
  current === target
    ? 'editing'
    : orderOfSteps.indexOf(target) < orderOfSteps.indexOf(current)
    ? 'complete'
    : 'hidden';

const BookingFlow = ({
  appointmentProductId,
}: {
  appointmentProductId: string;
}) => {
  // TODO: loading directly to the /datetime stage is busted
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(getUser);
  const dispatch = useAppDispatch();
  const cart = useSelector(getCart);
  const cartSource = useSelector(getCartSource);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const selectedAvailability = useSelector(getCartAvailability);
  const selectedPaymentMethod = useSelector(getCartPaymentMethod);
  const cartStatus = useSelector(getCartStatus);
  const checkoutMethod = useSelector(getCartCheckoutMethod);
  const [bookingState, setBookingState] = useState<BookingState>('loading');
  const [isLoading, setIsLoading] = useState(false);
  const [bookingError, setUserError] = useState<UserBookingError>(undefined);
  const routeMatch = useRouteMatch<RouteParams>();
  const history = useHistory();
  const query = useQuery();
  const step = routeMatch.params.step as BookingStep;
  const availabilityId = query.get(QueryParams.Availability);
  const utmData = getUTMDataFromParams(query);

  useEffect(() => {
    if (utmData !== undefined) {
      dispatch(setCartUTMData(utmData));
    }
  }, []);

  useEffect(() => {
    const _fetch = async () => {
      const r1 = await fetchCartAppointmentProduct();
      const r2 = await fetchCartAvailabilityFromParams();
      setBookingState(r1 && r2 ? 'ready' : 'error');
    };
    if (bookingState === 'loading') {
      _fetch();
    }
  }, [bookingState]);

  useEffect(() => {
    if (availabilityId !== undefined) {
      fetchCartAvailabilityFromParams();
    }
  }, [availabilityId]);

  useEffect(() => {
    if (cart && cart.status === 'COMPLETE') {
      const params = new URLSearchParams({ id: cart.id, ...utmData });
      history.push(
        APP_ROUTES.BOOK.nav(appointmentProduct.id, 'confirmation') +
          '?' +
          params.toString()
      );
    }
  }, [cart]);

  useEffect(() => {
    if (user) {
      dispatch(fetchAccountParticipants);
    }
    setUserError(
      !isAuthenticated
        ? 'not_authenticated'
        : ![UserType.Client, UserType.Admin].includes(user.type)
        ? 'invalid_user_type'
        : undefined
    );
  }, [user]);

  const _getBookingStepState = (target: BookingStep): StepState =>
    !getCanEditCart(cartStatus)
      ? 'complete'
      : getBookingStepState(step, target);

  const fetchCartAppointmentProduct = async () => {
    if (appointmentProduct && appointmentProductId === appointmentProduct.id) {
      return true;
    }
    try {
      const response = await api.appointmentProducts.retrieve(
        appointmentProductId
      );
      dispatch(setCartAppointmentProduct(response.data));
      return true;
    } catch (err) {
      enqueueSnackbar(GenericServerError);
      return false;
    }
  };

  const fetchCartAvailabilityFromParams = async () => {
    if (!availabilityId) {
      return true;
    }
    try {
      setIsLoading(true);
      const response = await api.availability.retrieve(availabilityId);
      dispatch(
        addAvailabilityToCart(
          response.data,
          'AVAILABILITY',
          cartSource ?? 'RECAPTURE'
        )
      );
      setIsLoading(false);
      return true;
    } catch (err) {
      setIsLoading(false);
      return false;
    }
  };

  const reset = () => {
    dispatch(clearCart());
    history.push(appointmentProduct.appUrl.split(APP_ROOT_URL)[1]);
  };

  const handleCartReset = async () => {
    setIsLoading(true);
    dispatch(resetCart());
    history.push(
      getStepLink('datetime', {
        appointmentProduct,
        selectedAvailability: undefined,
      })
    );
    setBookingState('loading');
    setIsLoading(false);
  };

  const handleHistoryPush = (nextStep: BookingStep) => {
    history.push(
      getStepLink(nextStep, {
        appointmentProduct,
        selectedAvailability,
      })
    );
  };

  const handleDateTimeEdit = () => {
    if (checkoutMethod === 'PROPOSAL') {
      return null;
    }
    return () => handleHistoryPush('datetime');
  };

  if (IS_SERVER || bookingState === 'loading') {
    return <Loading />;
  } else if (bookingState === 'error') {
    return (
      <div className="space-y-8">
        <Callout type="error" title="Error">
          Something went wrong, please select a new time & date
          <Controls>
            <Button onClick={handleCartReset}>Select New Time</Button>
          </Controls>
        </Callout>
      </div>
    );
  } else if (!step) {
    history.replace(
      getStepLink('datetime', { appointmentProduct, selectedAvailability })
    );
    return <Loading />;
  } else if (bookingError === 'invalid_user_type') {
    return (
      <div className="space-y-8">
        <Callout type="error" title="Invalid user type">
          Only registered Propel clients can book appointments.
          <Controls>
            <Button onClick={reset}>Return</Button>
          </Controls>
        </Callout>
      </div>
    );
  } else if (selectedAvailability?.isBookable === false) {
    return (
      <div className="space-y-8">
        <Callout type="error" title="Time is no longer available">
          The selected time is no longer available for booking.
          <Controls>
            <Button color="primary" variant="flat" onClick={handleCartReset}>
              Select a new time
            </Button>
          </Controls>
        </Callout>
      </div>
    );
  } else {
    return (
      <>
        {isLoading && <Loading />}
        <div className="space-y-8">
          <SelectAvailability
            state={_getBookingStepState('datetime')}
            error={bookingError}
            onClickContinue={() => handleHistoryPush('participants')}
            onClickEdit={handleDateTimeEdit()}
          />
          <SelectCartParticipants
            state={_getBookingStepState('participants')}
            onClickContinue={() =>
              handleHistoryPush(!selectedPaymentMethod ? 'payment' : 'checkout')
            }
            onClickEdit={() => handleHistoryPush('participants')}
          />
          <SelectPaymentMethodAndBookNow
            state={_getBookingStepState('payment')}
            onClickContinue={() => handleHistoryPush('checkout')}
            onClickEdit={() => handleHistoryPush('payment')}
          />
          <CartStatusMessage onCartReset={handleCartReset} />
        </div>
      </>
    );
  }
};

export default BookingFlow;
