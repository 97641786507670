import { DATE_FMT } from 'config';
import InstructorBaseTimeSlot from 'features/schedule/as-instructor/base-time-slot';
import { TimeSlotProps } from 'features/schedule/date-time-list/time-slot';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAppointmentProduct } from 'state/selectors';
import { setProposalData } from 'state/slice/proposals';

const AvailableTimeSlot = ({
  timeSlot,
  tense,
  aptProdId,
  description,
}: TimeSlotProps) => {
  const timezone = useSelector(getAppointmentProduct(aptProdId))?.timezone;
  const dispatch = useAppDispatch();
  let classes: string;
  let handleClick = null;
  let newDesc = description;
  if (timeSlot.isBookable) {
    classes =
      'font-semibold text-blue-800 bg-blue-50 hover:bg-blue-100 hover:text-blue-900';
    newDesc = 'Currently available';
    handleClick = () => {
      const datetime = timeSlot.datetime;
      const date = moment(timeSlot.datetime)
        .tz(timezone)
        .format(DATE_FMT.DATE_KEY);
      dispatch(setProposalData({ datetime, date }));
    };
  } else {
    classes = 'cursor-default text-gray-700 italic bg-gray-50';
  }
  return (
    <InstructorBaseTimeSlot
      onClick={handleClick}
      datetime={timeSlot.datetime}
      timezone={timezone}
      description={newDesc}
      classes={classes}
    />
  );
};

export default AvailableTimeSlot;
