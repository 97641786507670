import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { CreateReview, InstructorReview } from 'api/Serializers/Reviews';
import { GenericServerError } from 'lang/en/Snackbars';
import { enqueueSnackbar } from 'notistack';
import { getInitialStateFromStorage } from 'state/slice/utils';

interface Reducer {
  list: InstructorReview[];
}

const initialState: Reducer = {
  list: [],
};

const name: 'reviews' = 'reviews';
const Slice = createSlice({
  name,
  initialState: getInitialStateFromStorage(name, initialState),
  reducers: {
    setList(state, action: PayloadAction<InstructorReview[]>) {
      state.list = action.payload;
    },
  },
});

const { setList } = Slice.actions;

export const fetchReviews = async (dispatch, getState) => {
  try {
    const response = await api.reviews.list();
    dispatch(setList(response.data));
  } catch (err) {
    enqueueSnackbar(GenericServerError);
    dispatch(setList([]));
  }
};

export const upsertInstructorReview =
  (data: CreateReview, onSuccess, onError) => async (dispatch, getState) => {
    try {
      let response;
      if (data.id) {
        response = await api.reviews.update(data);
      } else {
        response = await api.reviews.create(data);
      }
      onSuccess();
    } catch (err) {
      onError();
    }
    return null;
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
