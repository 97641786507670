import { createTheme } from '@mui/material/styles';
import { COLOR_GREY_400, COLOR_PRIMARY_400 } from 'config';
const primary = COLOR_PRIMARY_400;
const secondary = '#e0003c';
const captionColor = COLOR_GREY_400;

export const RootTheme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          transition: undefined,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '8px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#2F333C',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#2f333c',
          backgroundColor: 'white',
          fontSize: 14,
          boxShadow:
            '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Inter', '-apple-system', 'sans-serif'].join(','),
    fontSize: 13.125, // Tailwind uses 16px base; Mui uses 14px... this gets to 15px
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primary,
      contrastText: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: secondary,
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
  breakpoints: {
    values: { xs: 0, sm: 640, md: 768, lg: 1024, xl: 1280 }, // matches tailwind
  },
});
