import Hint from 'components/hint';
import Label from 'components/input-label';
import Section from 'layouts/section';
import React from 'react';
import style from './style.module.scss';
interface Props {
  name?: string;
  title?: string;
  hint?: string;
  required?: boolean;
  inline?: boolean;
  className?: string;
  height?: 'short' | undefined;
  disabled?: boolean;
}

const Element: React.FunctionComponent<Props> = (props) => (
  <Section
    height={props.height}
    className={`${cls(props.className)}${cls(
      props.height,
      style[props.height]
    )}${
      props.inline || (!props.title && !safe(props.inline))
        ? ` ${style.inline}`
        : ''
    }`}
  >
    {props.title && (
      <Label htmlFor={props.name} required={props.required}>
        {props.title}
      </Label>
    )}
    {props.hint && <Hint title={props.hint} />}
    {props.children}
  </Section>
);

Element.defaultProps = {
  required: false,
  disabled: false,
};

export default Element;
