import { AppointmentProductStatus } from 'api/Serializers/AppointmentProducts';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import { FETCH_STATE } from 'config';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAccountFetchState } from 'state/selectors';
import { InstructorOnboardingRoutes } from 'utils/routing';
import { BaseStepProps, ControlClasses, TextClasses, TitleClasses } from '../';
import { INSTRUCTOR_ROUTES } from '../../utils';

const TeachingPreferences: React.FC<BaseStepProps> = ({
  appointmentProducts,
}) => {
  const fetchState = useSelector(getAccountFetchState);
  const hasAppointmentProducts = appointmentProducts.length > 0;
  const appointmentProduct = appointmentProducts.find(
    (aptProd) =>
      aptProd.status === AppointmentProductStatus.Active ||
      aptProd.status === AppointmentProductStatus.Pending
  );
  const facility = appointmentProduct?.facility;
  const price = appointmentProduct?.price;

  return (
    <div>
      <h1 className={TitleClasses}>Set your teaching preferences</h1>
      {hasAppointmentProducts && !appointmentProduct && (
        <Callout
          type="info"
          title="Previous locations inactive"
          className="mb-6"
        >
          It looks like the previous locations that you had selected are no
          longer active. You'll have to select a new location to continue.
        </Callout>
      )}
      <p className={`${TextClasses} mb-8`}>
        Tell us where you'd like to teach, and how much you'd like to charge per
        lesson.
      </p>
      <div className="space-y-3">
        <ButtonLarge
          to={InstructorOnboardingRoutes.S14}
          variant="check"
          title={
            fetchState === FETCH_STATE.PUT
              ? 'Saving...'
              : !!facility
              ? `Location: ${facility.displayName}`
              : 'Select a Location'
          }
          selected={!!facility}
        />
        <ButtonLarge
          to={InstructorOnboardingRoutes.SetPrice}
          variant="check"
          title={
            fetchState === FETCH_STATE.PUT
              ? 'Saving...'
              : !!price
              ? `Price: $${price} per lesson`
              : 'Choose a Starting Price'
          }
          selected={!!price}
          disabled={!facility}
        />
        {!!facility && !!price && (
          <Callout title="Looks Great!" type="success">
            <span>
              If you have any doubts, don't worry. We'll go over your choices in
              an orientation call and can make any final changes then.
            </span>
          </Callout>
        )}
      </div>
      <div className={ControlClasses}>
        <Button
          to={INSTRUCTOR_ROUTES.ORIENTATION_CALL.ROOT}
          disabled={!facility || !price || fetchState === FETCH_STATE.PUT}
          variant="contained"
          color="primary"
          type="submit"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default TeachingPreferences;
