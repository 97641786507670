import { Activity } from 'api/Serializers/Activities';
import { Media } from 'api/Serializers/Media';
import { UserSerializer } from 'api/Serializers/Users';
import { OnboardingStatus, PropelAccount } from '..';

export enum ReasonForJoining {
  Unset = '',
  Teaching = 'Teaching',
  OwnSchedule = 'OwnSchedule',
  EarningMore = 'EarningMore',
}
export enum SelfDescribedAs {
  Unset = '',
  SideHustler = 'SideHustler',
  Veteran = 'Veteran',
  Entrepreneur = 'Entrepreneur',
}

export enum ReasonForJoiningText {
  '' = 'Unset',
  Teaching = 'Teaching private lessons',
  OwnSchedule = 'Setting my own schedule',
  EarningMore = 'Earning more per hour',
}

export enum SelfDescribedAsText {
  '' = 'Unset',
  SideHustler = 'Side Hustler',
  Veteran = 'Veteran',
  Entrepreneur = 'Entrepreneur',
}

export enum YearsExperience {
  Unset = 0,
  LessThanOne = 1,
  OneToFive = 2,
  MoreThanFive = 3,
}
export enum YearsExperienceText {
  'Exp-1' = 'Unset',
  Exp0 = 'Unset',
  Exp1 = '<1 Year',
  Exp2 = '1-5 Years',
  Exp3 = '5+ Years',
}

export enum CertifyingBody {
  Unset = '',
  CanadianRedCross = 'CanadianRedCross',
  LifesavingSociety = 'LifesavingSociety',
}
export enum CertificateType {
  Unset = '',
  WaterSafetyInstructor = 'WaterSafetyInstructor',
  SwimInstructor = 'SwimInstructor',
  NationalLifeguard = 'NationalLifeguard',
}

export enum AppointmentProductStatus {
  Unlisted = -2,
  Inactive = -1,
  Active = 1,
}

export interface Certification {
  id?: number;
  certifiedBy: string;
  certifyingBody: CertifyingBody;
  certificateType: CertificateType;
  certificateNumber?: string;
  nonStandardCertificationName?: string;
  dateCreated?: string;
  dateExpiry?: string;
  verified?: boolean;
  verifiedOn?: string;
}

export interface Rate {
  price: string | number;
  facility: number;
  startDate?: string;
  facilityStatus?: string;
}

export enum Segment {
  Toddlers = 'Toddlers',
  Children = 'Children',
  AdultsTeens = 'Adults & Teens',
  Adapted = 'Adapted Aquatics',
}

interface InstructorAccount extends PropelAccount {
  liveSince: string;
  supportCall: string;
  specialization1: string;
  specialization2: string;
  certifications: Certification[];
  media: Media[];
  prices: Rate[];
  segments: Segment[];
  numAppointments: number;
  numDelivered: number;
  numClients: number;
  numRecommended: number;
  calendarLink?: string;
  preferencesLastUpdated?: string;
}

export interface AccountUpdateSerializer extends InstructorAccount {
  avatarRotation: number;
  facilitySlug: string;
  rotate: number;
  segmentsTaught: number[];
}

export enum InstructorOnboardingStage {
  Background = 0,
  Profile = 1,
  Lessons = 2,
  CallLimbo = 10,
  Orientation = 11,
  Launch = 12,
  GetBooked = 13,
  Prepare = 14,
  Complete = 100,
}

export type WeekDayIntegers = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface InstructorOnboardingSerializer {
  status: OnboardingStatus;
  stage: InstructorOnboardingStage;
  reasonToJoin: ReasonForJoining;
  selfDescribedAs: SelfDescribedAs;
  numYearsExp: YearsExperience;
  lastStepId: string;
  hasLearnedAvailability: boolean;
  hasWatchedGetBooked: boolean;
  hasWatchedFirstAppointmentPrepare: boolean;
  callDatetime?: string | null;
  vettedBy?: UserSerializer;
}

export interface InstructorActivityPreferences {
  activity: Activity;
  numDesiredBookings: number;
  times: InstructorPreferredTime[];
}

export interface InstructorPreferredTime {
  start: string;
  end: string;
  weekDay: WeekDayIntegers;
}

export default InstructorAccount;
