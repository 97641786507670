import { OnboardingStatus } from 'api/Serializers/Accounts';
import InstructorAccount, {
  InstructorOnboardingStage,
} from 'api/Serializers/Accounts/Instructor';
import { FacilityStatus } from 'api/Serializers/Facilities';
import { Tense } from 'api/Serializers/Schedules';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Card from 'components/card';
import Controls from 'components/controls';
import { FleeqModule } from 'components/fleeq';
import LearningModule from 'components/instructor/learning-module';
import Link from 'components/link';
import Modal from 'components/modal';
import { DATE_FMT, FETCH_STATE } from 'config';
import { PhoneIcon } from 'icons';
import moment from 'moment-timezone';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAccountDetail,
  getAppointmentProductsFetchState,
  getInstructorOnboarding,
  getScheduleAppointmentProducts,
} from 'state/selectors';
import { getDatetimeTense } from 'utils/date';

const PendingDashboard = () => {
  const history = useHistory();
  const appointmentProducts = useSelector(getScheduleAppointmentProducts);
  const appointmentProductsFetchState = useSelector(
    getAppointmentProductsFetchState
  );
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const onboarding = useSelector(getInstructorOnboarding);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLearnAvailabilityOpen, setLearnAvailabilityOpen] = useState(false);
  const readyToSetAvailability =
    onboarding.status === OnboardingStatus.Accepted;

  const liveLocations = appointmentProducts.filter(
    (appointmentProduct) =>
      [
        FacilityStatus.Active,
        FacilityStatus.Seeding,
        FacilityStatus.TempClosed,
      ].indexOf(appointmentProduct.facility.status) > -1
  );

  if (onboarding.stage < InstructorOnboardingStage.CallLimbo) {
    history.replace(INSTRUCTOR_ROUTES.ONBOARDING.CONTINUE);
    return null;
  }

  return (
    <Dashboard title={`Hello, ${account.firstName}!`} width="3xl">
      <div className="my-8 space-y-8">
        <Card title="Get ready" maxWidth="xl">
          <div className="space-y-3">
            {readyToSetAvailability ? (
              <>
                <ButtonLarge
                  title="Orientation Call"
                  subtitle={`Your call was on ${moment(onboarding.callDatetime)
                    .tz(account.address.timezone)
                    .format(DATE_FMT.DOW_MONTH_D_TIME_A)}`}
                  variant="check"
                  selected={true}
                  disabled={true}
                />
                <ButtonLarge
                  title="Set location & price"
                  subtitle="Select the location where you'd like to teach"
                  to={INSTRUCTOR_ROUTES.ADD_LOCATION.ROOT}
                  variant="check"
                  selected={liveLocations.length > 0}
                  disabled={liveLocations.length > 0}
                />
                <ButtonLarge
                  title="Set availability"
                  subtitle="Head to the calendar to add and modify your teaching availability"
                  to={INSTRUCTOR_ROUTES.SCHEDULE.availability}
                  variant="check"
                  disabled={
                    appointmentProductsFetchState !== FETCH_STATE.FULFILLED ||
                    (appointmentProductsFetchState === FETCH_STATE.FULFILLED &&
                      liveLocations.length === 0)
                  }
                />
              </>
            ) : !onboarding.callDatetime ? (
              <ButtonLarge
                title="Schedule an orientation call"
                subtitle="Before proceeding, you must schedule a call"
                variant="check"
                onClick={() => setModalOpen(true)}
              />
            ) : moment(onboarding.callDatetime)
                .tz(account.address.timezone)
                .isAfter(moment()) ? (
              <ButtonLarge
                title="Orientation Call"
                subtitle={`Your call is scheduled for ${moment(
                  onboarding.callDatetime
                )
                  .tz(account.address.timezone)
                  .format(DATE_FMT.DOW_MONTH_D_TIME_A)}`}
                onClick={() => setModalOpen(true)}
                icon={<PhoneIcon width={20} className="text-blue-500" />}
              />
            ) : (
              <ButtonLarge
                title="Reschedule orientation call"
                subtitle="If you missed your onboarding call with the Propel Support team, reschedule your call now!"
                icon={<PhoneIcon width={20} className="text-blue-500" />}
                onClick={() => setModalOpen(true)}
              />
            )}
            <ButtonLarge
              title="How does availability work?"
              subtitle="Learn about setting your schedule"
              onClick={() => setLearnAvailabilityOpen(true)}
              variant="check"
              disabled={!onboarding.callDatetime || readyToSetAvailability}
              selected={onboarding.hasLearnedAvailability}
            />
          </div>
        </Card>
      </div>
      <LearningModule
        module={FleeqModule.AvailabilityOverview}
        show={isLearnAvailabilityOpen}
        onboardingAttributeKey="hasLearnedAvailability"
        onClose={() => setLearnAvailabilityOpen(false)}
      />
      <Modal
        name="Instructor — Orientation call info"
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="xs"
        title="Orientation Call"
      >
        <p>
          We schedule an orientation call with every new instructor to say hello
          and show you around 👋
        </p>
        <Callout type="info" className="my-8">
          {onboarding.callDatetime ? (
            <span>
              Your call
              {getDatetimeTense(onboarding.callDatetime) === Tense.Future
                ? ' is '
                : ' was '}
              scheduled for{' '}
              {moment(onboarding.callDatetime)
                .tz(account.address.timezone)
                .format(DATE_FMT.DOW_MONTH_D_TIME_A)}
            </span>
          ) : (
            <span>
              You don't have a call scheduled yet!
              <br />
              <Link to={INSTRUCTOR_ROUTES.ORIENTATION_CALL.ROOT}>
                Schedule one now
              </Link>
            </span>
          )}
        </Callout>
        <h5>Here's what we'll cover:</h5>
        <ul className="mb-8 ml-8 space-y-2 list-disc">
          <li>What brought you here</li>
          <li>How location access works</li>
          <li>Expectations and guidelines</li>
          <li>Answer your questions</li>
        </ul>
        <p>
          After the call, you'll be ready to add availability and start
          teaching!
        </p>
        {onboarding.callDatetime ? (
          <Controls>
            <Button
              variant="outlined"
              to={INSTRUCTOR_ROUTES.ORIENTATION_CALL.ROOT}
            >
              Reschedule my call
            </Button>
            <Button variant="flat" onClick={() => setModalOpen(false)}>
              Close
            </Button>
          </Controls>
        ) : (
          <Controls>
            <Button variant="flat" onClick={() => setModalOpen(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              to={INSTRUCTOR_ROUTES.ORIENTATION_CALL.ROOT}
            >
              Schedule a call
            </Button>
          </Controls>
        )}
      </Modal>
    </Dashboard>
  );
};

export default PendingDashboard;
