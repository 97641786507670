import * as React from 'react';
import style from './style.module.scss';

interface Props {
  align?: 'center' | 'stretch';
  justify?: 'between' | 'start' | 'center';
  className?: string;
  children?: any;
  onClick?: any;
  width?: 'auto' | 'medium';
}

const Row: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      onClick={props.onClick}
      className={`${style.row} ${style[`${props.align}Align`]} ${
        style[`${props.justify}Justify`]
      } ${style[`${props.width}Width`]}${cls(props.className)}`}
    >
      {props.children}
    </div>
  );
};

Row.defaultProps = {
  align: 'center',
  justify: 'start',
  width: 'auto',
};

export default Row;
