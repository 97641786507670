import {
  FacilityCategory,
  FacilitySchedulableSerializer,
} from 'api/Serializers/Facilities';
import Button from 'components/button';
import Controls from 'components/controls';
import Modal from 'components/modal';
import FacilityMap from 'containers/facility-map';
import FacilityCard from 'containers/facility-map/facility-card';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createAppointmentProduct,
  deleteAppointmentProduct,
  fetchAppointmentProducts,
} from 'state/slice/schedule';
import { InstructorOnboardingRoutes } from 'utils/routing';

import { AppointmentProductStatus } from 'api/Serializers/AppointmentProducts';
import { useSnackbar } from 'notistack';
import { BaseStepProps } from '..';

const dummyFacility: Pick<
  FacilitySchedulableSerializer,
  | 'slug'
  | 'hero'
  | 'shortName'
  | 'admissionFee'
  | 'hasFreeParking'
  | 'category'
  | 'assetType'
  | 'numInstructors'
  | 'near'
  | 'region'
  | 'attributes'
> = {
  admissionFee: 25,
  assetType: 'Pool',
  category: 'Hotel' as FacilityCategory,
  hasFreeParking: true,
  hero: 'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvMjAxOC0xMi03L2FmMGYyOGNjLTk1Y2EtNGRmYS04NmRlLTI1OWRiNTFkODRhMC5qcGciLCAiZWRpdHMiOiB7InJlc2l6ZSI6IHsid2lkdGgiOiA0ODAsICJoZWlnaHQiOiAyNzB9fX0=',
  near: '',
  numInstructors: 8,
  region: {
    label: 'Vancouver, BC',
    slug: 'vancouver-bc',
    city: 'Vancouver',
    regionCode: 'BC',
    distance: 12,
    timezone: 'America/Vancouver',
    latlng: {
      lat: 49.2778649,
      lng: -123.1284885,
    },
  },
  attributes: {
    subtype: 'Leisure',
    iconUrl: '',
    isIndoor: true,
    isHeated: true,
    hasWashroomAccess: true,
    maxLength: '26ft',
    maxDepth: '4ft',
    minDepth: '3ft',
    temperature: '28-29',
    lifeguardOnDuty: false,
    dateCreated: '2023-10-11T22:10:00.741111Z',
    nlsRequired: false,
  },
  shortName: 'Downtown Vancouver Hotel',
  slug: 'example-facility',
};

const FacilityMapContainer: React.FC<BaseStepProps> = ({
  account,
  appointmentProducts,
  modifyOnboarding,
  modifyAccount,
  ...rest
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(true);
  const [changeFacility, setChangeFacility] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const activeAppointmentProducts = appointmentProducts.filter(
    (aptProd) => aptProd.status === AppointmentProductStatus.Active
  );

  const handleGoBack = () => {
    history.push(InstructorOnboardingRoutes.TeachingPreferences);
  };

  const addAppointmentProduct = async (
    facility: FacilitySchedulableSerializer
  ) => {
    setIsLoading(true);
    await dispatch(createAppointmentProduct(facility.id, account.id));
    dispatch(fetchAppointmentProducts(true));
    handleGoBack();
  };

  const handleSelectNewFacility = async (
    facility: FacilitySchedulableSerializer
  ) => {
    if (activeAppointmentProducts.length === 0) {
      addAppointmentProduct(facility);
    } else {
      const worksHereAlready = appointmentProducts.some(
        (aptProd) => aptProd.facility.id === facility.id
      );
      if (worksHereAlready) {
        handleGoBack();
      } else {
        setChangeFacility(facility);
      }
    }
  };

  const handleChangeFacility = async () => {
    setIsLoading(true);
    const success = await dispatch(
      deleteAppointmentProduct(appointmentProducts[0])
    );
    if (success) {
      addAppointmentProduct(changeFacility);
    } else {
      enqueueSnackbar({
        message: 'Error removing that location, please try again',
        variant: 'error',
      });
      setIsLoading(false);
    }
  };
  return (
    <>
      <FacilityMap
        home={account?.address?.latlng}
        onFacilitySelect={handleSelectNewFacility}
        appointmentProducts={appointmentProducts}
        onGoBack={handleGoBack}
      />
      <Modal
        name="Instructor — Learn facility admission"
        open={showHelp}
        onClose={() => setShowHelp(false)}
        maxWidth="sm"
        title="Learn about facility admission"
      >
        <div className="w-full max-w-md">
          <div className="space-y-6">
            <p>
              Admission is automatically deducted from your payout and allows
              use of the facility for you and your students. Each location sets
              its own fee, and usually reflects the amenities, ambiance, and
              demand.
            </p>
            <FacilityCard facility={dummyFacility} isDirectionsShown={false} />
            <p>
              For example, the card above shows a {dummyFacility.category}{' '}
              {dummyFacility.assetType} that has {dummyFacility.numInstructors}{' '}
              other instructors, features{' '}
              {dummyFacility.hasFreeParking ? 'free' : 'paid'} parking, and
              charges ${dummyFacility.admissionFee} per use.
            </p>
          </div>
          <Controls>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowHelp(false)}
            >
              Got it
            </Button>
          </Controls>
        </div>
      </Modal>
      <Modal
        name="Instructor — Onboarding, only one location"
        open={!!changeFacility}
        maxWidth="sm"
        title="Change locations?"
      >
        <div className="w-full max-w-md">
          <p>
            You may only sign up at one location to start with. Would you like
            to change your location to {changeFacility?.displayName}?
          </p>
          <Controls>
            <Button
              color="default"
              variant="contained"
              onClick={handleGoBack}
              disabled={isLoading}
            >
              Don't Change
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleChangeFacility}
              disabled={isLoading}
            >
              Yes, Change Location
            </Button>
          </Controls>
        </div>
      </Modal>
    </>
  );
};

export default FacilityMapContainer;
