import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ConnectionState,
  Conversation,
  Message,
  Participant,
  ParticipantType,
  User,
} from '@twilio/conversations';
import { MESSAGING_PAGE_SIZE } from 'config';
import moment from 'moment-timezone';
import { RootState } from 'state';
import { AppDispatch } from 'state/store';
import { SHARED_ROUTES } from 'utils/routing';

/**
 * Store the SDK conversation objects in memory to access their methods that we
 * lose in redux.
 */

export const sdkConversations = new Map<string, Conversation>();

/**
 * TYPES
 */

export interface ReduxMessagingUser {
  identity: string;
  friendlyName: string;
  avatar: string;
  messagingDisabled: boolean;
  username?: string;
}

export interface ReduxConversation {
  sid: string;
  dateUpdated: string;
  dateCreated: string;
  unreadMessageCount: number;
  lastMessage: {
    index: number | null;
    dateCreated: string | null;
  };
}

export interface ReduxParticipant {
  sid: string;
  identity: string | null;
  displayName: string;
  avatar: string;
  messagingDisabled: boolean;
  type: ParticipantType;
  username?: string;
}

export interface ReduxMessage {
  sid: string;
  index: number;
  body: string;
  author: string;
  participantSid: string;
  dateCreated: string;
}

interface MessagingReducer {
  init: boolean; // client initialized and all conversation data loaded
  connectionState: ConnectionState;
  user: ReduxMessagingUser;
  activeConversationSid: string;
  conversations: ReduxConversation[];
  participants: Record<string, ReduxParticipant[]>;
  messages: Record<string, ReduxMessage[]>;
}

/**
 * PARSERS
 */

export const parseMessagingUser = (user: User): ReduxMessagingUser => {
  return {
    identity: user.identity,
    friendlyName: user.friendlyName,
    avatar: user.attributes['avatar'] ?? '',
    messagingDisabled: user.attributes['messagingDisabled'] ?? false,
    username: user.attributes['username'] ?? '',
  };
};

export const parseConversation = async (
  conversation: Conversation
): Promise<ReduxConversation> => {
  return {
    sid: conversation.sid,
    dateUpdated: conversation.dateUpdated.toISOString(),
    dateCreated: conversation.dateCreated.toISOString(),
    unreadMessageCount: await conversation.getUnreadMessagesCount(),
    lastMessage: {
      index: conversation.lastMessage?.index ?? null,
      dateCreated: conversation.lastMessage?.dateCreated?.toISOString() ?? null,
    },
  };
};

export const parseParticipants = async (
  participants: Participant[],
  currentUser: ReduxMessagingUser
): Promise<ReduxParticipant[]> => {
  const reduxParticipants: ReduxParticipant[] = [];
  await Promise.allSettled(
    participants.map(async (participant: Participant) => {
      let user: ReduxMessagingUser | User = undefined;
      try {
        user =
          currentUser.identity === participant.identity
            ? currentUser
            : await participant.getUser();
      } catch {
      } finally {
        reduxParticipants.push({
          sid: participant.sid,
          identity: participant.identity,
          displayName: user?.friendlyName ?? 'Unknown User',
          avatar:
            user instanceof User
              ? user.attributes['avatar'] ?? ''
              : user.avatar,
          messagingDisabled:
            user instanceof User
              ? user.attributes['messagingDisabled'] ?? false
              : user.messagingDisabled,
          username:
            user instanceof User
              ? user.attributes['username'] ?? ''
              : user.username,
          type: participant.type,
        });
      }
    })
  );
  return reduxParticipants;
};

export const parseMessages = (messages: Message[]): ReduxMessage[] =>
  messages.map((message: Message) => {
    return {
      sid: message.sid,
      index: message.index,
      body: message.body,
      author: message.author,
      participantSid: message.participantSid,
      dateCreated: message.dateCreated.toISOString(),
    };
  });

/**
 * REDUCER
 */

const initialState: MessagingReducer = {
  init: undefined,
  connectionState: undefined,
  user: undefined,
  activeConversationSid: null,
  conversations: [],
  participants: {},
  messages: {},
};

const name: 'messaging' = 'messaging';
const Slice = createSlice({
  name,
  initialState,
  reducers: {
    setMessagingInit(state, action: PayloadAction<boolean>) {
      state.init = action.payload;
    },
    setConnectionState(state, action: PayloadAction<ConnectionState>) {
      state.connectionState = action.payload;
    },
    setMessagingUser(state, action: PayloadAction<ReduxMessagingUser>) {
      state.user = action.payload;
    },
    upsertConversation(state, action: PayloadAction<ReduxConversation>) {
      state.conversations = sortedConversations([
        action.payload,
        ...state.conversations.filter(
          (conversation) => conversation.sid !== action.payload.sid
        ),
      ]);
    },
    setActiveConversationSid(state, action: PayloadAction<string>) {
      state.activeConversationSid = action.payload;
      if (action.payload) {
        sdkConversations.get(action.payload).setAllMessagesRead();
      }
    },
    upsertParticipants(
      state,
      action: PayloadAction<{
        convoSid: string;
        reduxParticipants: ReduxParticipant[];
      }>
    ) {
      state.participants[action.payload.convoSid] = state.participants[
        action.payload.convoSid
      ]
        ? [
            // replace participants that already exist on update
            ...action.payload.reduxParticipants,
            ...state.participants[action.payload.convoSid].filter(
              (outer) =>
                !action.payload.reduxParticipants.some(
                  (inner) => outer.identity === inner.identity
                )
            ),
          ]
        : action.payload.reduxParticipants;
    },
    insertMessages(
      state,
      action: PayloadAction<{
        convoSid: string;
        messages: ReduxMessage[];
        old: boolean;
      }>
    ) {
      const existing = state.messages[action.payload.convoSid] ?? [];
      let sliceAt = 0;
      // ensure we are not inserting duplicate messages
      if (existing.length) {
        const first = action.payload.old ? action.payload.messages : existing;
        const second = action.payload.old ? existing : action.payload.messages;
        const calc = first[first.length - 1].index - second[0].index;
        if (calc >= 0) {
          // slice from the end if duplicate messages are old
          sliceAt = (action.payload.old ? -1 : 1) * (calc + 1);
        }
      }
      state.messages[action.payload.convoSid] = action.payload.old
        ? action.payload.messages.slice(sliceAt).concat(existing)
        : existing.concat(action.payload.messages.slice(sliceAt));
    },
    clear: () => initialState,
  },
});

/**
 * ACTION CREATORS
 */

export const parseAndSetMessagingUser =
  (user: User) => (dispatch: AppDispatch) => {
    dispatch(Slice.actions.setMessagingUser(parseMessagingUser(user)));
  };

export const parseAndUpsertConversation =
  (conversation: Conversation) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (
      state.messaging.activeConversationSid === conversation.sid &&
      location.pathname.indexOf(SHARED_ROUTES.MESSAGES.ROOT) === 0
    ) {
      await conversation.setAllMessagesRead();
    }
    const reduxConversation = await parseConversation(conversation);
    return dispatch(Slice.actions.upsertConversation(reduxConversation));
  };

export const parseAndUpsertParticipants =
  (convoSid: string, participants: Participant[]) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    return dispatch(
      Slice.actions.upsertParticipants({
        convoSid,
        reduxParticipants: await parseParticipants(
          participants,
          getState().messaging.user
        ),
      })
    );
  };

export const parseAndInsertMessages =
  (convoSid: string, messages: Message[], old: boolean = false) =>
  (dispatch: AppDispatch) => {
    return dispatch(
      Slice.actions.insertMessages({
        convoSid: convoSid,
        messages: parseMessages(messages),
        old: old,
      })
    );
  };

/*
 * Insert conversations along with their participants and messages.
 * Checks are included to avoid duplicate updates as this is run by both
 * conversationJoined and getSubscribedConversations on init.
 */
export const insertConversationDataSets =
  (conversations: Conversation[]) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await Promise.allSettled(
      conversations.map(async (conversation) => {
        sdkConversations.set(conversation.sid, conversation);
        if (
          // skip if conversation exists
          !getState().messaging.conversations.some(
            (existingConvo) => existingConvo.sid === conversation.sid
          )
        ) {
          await dispatch(parseAndUpsertConversation(conversation));
        }
        const participants = await conversation.getParticipants();
        if (
          // skip if all participants are already inserted
          getState().messaging.participants[conversation.sid]?.length !==
          participants.length
        ) {
          await dispatch(
            parseAndUpsertParticipants(conversation.sid, participants)
          );
        }

        const paginator = await conversation.getMessages(MESSAGING_PAGE_SIZE);
        const currentMessages = getState().messaging.messages[conversation.sid];
        if (
          // skip if the newest message is already inserted
          !currentMessages ||
          currentMessages.slice(-1).pop().index !==
            paginator.items.slice(-1).pop().index
        ) {
          dispatch(parseAndInsertMessages(conversation.sid, paginator.items));
        }
      })
    );
  };

export const clearMessaging = () => (dispatch: AppDispatch) => {
  return dispatch(Slice.actions.clear());
};

/**
 * UTILS
 */

export const sortedConversations = (conversations: ReduxConversation[]) => {
  const getVal = (convo: ReduxConversation) =>
    convo.lastMessage.dateCreated
      ? moment(convo.lastMessage.dateCreated).unix()
      : moment(convo.dateUpdated).unix() - 1000000000; // show convos with messages first
  return conversations.sort((a: ReduxConversation, b: ReduxConversation) => {
    return getVal(b) - getVal(a);
  });
};

export const {
  setMessagingInit,
  setConnectionState,
  setMessagingUser,
  setActiveConversationSid,
  insertMessages,
} = Slice.actions;

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
