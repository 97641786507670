import { Event, TimeFrame } from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { FETCH_STATE } from 'config';
import { getReportTypeDescription } from 'features/assistance/as-instructor/utils';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { CheckIcon } from 'icons';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAssistanceReport } from 'state/selectors';
import { fetchAppointment } from 'state/slice/appointments';
import {
  processReportAction,
  resetReport,
  submitReport,
  upsertReportAction,
} from 'state/slice/assistance';

const ClientNoShowModal = ({
  appointment,
  onClose,
}: {
  appointment: Event;
  onClose(): void;
}) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const report = useSelector(getAssistanceReport);

  if (appointment.timeFrame < TimeFrame.HasEnded) {
    return null;
  }

  const handleSuccess = () => {
    dispatch(resetReport());
    dispatch(fetchAppointment(appointment.id));
    enqueueSnackbar({
      message: 'Client no-show reported!',
      variant: 'success',
    });
    onClose();
  };
  const handleError = () => {
    dispatch(resetReport());
    enqueueSnackbar({
      message: 'There was an error submitting this report, please try again',
      variant: 'error',
    });
  };

  const trackAction = (d) => dispatch(processReportAction(d));
  const addReportData = (d) => dispatch(upsertReportAction(d));

  const handleSubmit = () => {
    dispatch(submitReport(handleSuccess, handleError));
  };

  useEffect(() => {
    trackAction({
      type: 'START',
      key: 'event',
      value: appointment.id,
    });
    trackAction({
      type: 'SET_CATEGORY',
      key: 'category',
      value: 'CLIENT',
      title: 'What do you need help with?',
      description: 'Client issues',
    });
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'CLIENT_NOT_HERE',
      title: 'What seems to be the problem?',
      description: getReportTypeDescription('CLIENT_NOT_HERE'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'CLIENT_CANCELLATION',
    });
    addReportData({
      type: 'SET_POLICY_ENFORCEMENT',
      key: 'isPolicyWaived',
      value: false,
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'CLIENT_NO_SHOW',
    });
  }, []);

  return (
    <div className="space-y-5">
      <p>
        Clients can forget about their lessons from time to time — it happens!
        It's still important to mark them absent. You and the host are paid
        either way.
      </p>
      <Callout type="info">
        You may waive your policy and provide your client a partial refund after
        cancelling.
      </Callout>
      <Controls variant="block">
        <BackButton
          disabled={report?.state === FETCH_STATE.POST}
          onClick={onClose}
        >
          Go back
        </BackButton>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          icon={<CheckIcon width={24} />}
          isLoading={report?.state === FETCH_STATE.POST}
        >
          I confirm my client never showed
        </Button>
      </Controls>
    </div>
  );
};

export default ClientNoShowModal;
