import { ReasonForJoining } from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import Redirect from 'components/redirect';
import React, { useEffect, useState } from 'react';
import { getUrlParam, InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  OnboardingScreens,
  QuestionClasses,
  TitleClasses,
} from '../';

const Welcome: React.FC<BaseStepProps> = (props) => {
  const [selected, setSelected] = useState<ReasonForJoining>(
    props.onboarding.reasonToJoin as ReasonForJoining
  );
  useEffect(() => {
    if (selected && selected !== props.onboarding.reasonToJoin) {
      props.modifyOnboarding({ reasonToJoin: selected });
    }
  }, [selected]);
  if (
    OnboardingScreens.find((sr) => sr.step > 1).isSet(
      props.account,
      props.onboarding,
      props.appointmentProducts
    ) &&
    getUrlParam('r') !== 'true'
  ) {
    return <Redirect to={InstructorOnboardingRoutes.continue} />;
  }

  return (
    <div>
      <h1 className={TitleClasses}>
        Thanks for signing up, {props.account.firstName}!
      </h1>
      <p className={QuestionClasses}>
        What interests you most about joining Propel?
      </p>
      <div className="space-y-3">
        <ButtonLarge
          variant="check"
          title="Teaching private lessons"
          subtitle="Focus your energy on one or two students"
          selected={selected === ReasonForJoining.Teaching}
          onClick={() => setSelected(ReasonForJoining.Teaching)}
        />
        <ButtonLarge
          variant="check"
          title="Setting my own schedule"
          subtitle="Set hours that work with your busy life"
          selected={selected === ReasonForJoining.OwnSchedule}
          onClick={() => setSelected(ReasonForJoining.OwnSchedule)}
        />
        <ButtonLarge
          variant="check"
          title="Earning more per hour"
          subtitle="Get paid what you deserve"
          selected={selected === ReasonForJoining.EarningMore}
          onClick={() => setSelected(ReasonForJoining.EarningMore)}
        />
      </div>
      <div className={ControlClasses}>
        <Button
          to={InstructorOnboardingRoutes.S01}
          disabled={selected === ReasonForJoining.Unset}
          variant="contained"
          color="primary"
          type="submit"
        >
          Save and Continue
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
