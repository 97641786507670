import {
  DEFAULT_SEARCH_PREFERENCES,
  LocationFilter,
  UserSearchPreferences,
  WeekDayTimeFilter,
} from 'api/Serializers/Accounts';
import { ActivitySegment } from 'api/Serializers/Activities';
import Button from 'components/button';
import Controls from 'components/controls';
import InstructorList from 'components/instructor-list';
import Popover from 'components/popover';
import {
  defaultTimeFilters,
  MaxPriceFilter,
  RadiusFilter,
  SegmentFilter,
  TimeAndWeekDayFilter,
} from 'containers/search-filters';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { DownIcon, PreferencesIcon } from 'icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAccountDetail,
  getAccountIsLoaded,
  getActivity,
  getSearchPreferences,
} from 'state/selectors';
import { setSearchPreferences } from 'state/slice/search';
import { getAddressString } from 'utils/geo';

const { activity: _, ...PREFS_WITHOUT_ACTIVITY } = DEFAULT_SEARCH_PREFERENCES;
type AllowedFilters = (keyof typeof PREFS_WITHOUT_ACTIVITY)[];

const getNumFiltersApplied = (
  allowedFilters: AllowedFilters,
  searchPreferences: UserSearchPreferences,
  defaultPreferences: Partial<UserSearchPreferences>
) => {
  let numFiltersApplied = 0;
  allowedFilters.forEach((k) => {
    switch (k) {
      case 'times':
        if (searchPreferences.times.length > 0) {
          numFiltersApplied++;
        }
        break;
      case 'maxPrice':
      case 'segment':
        // When react-select is cleared, the value is set to null instead of
        // undefined so the default case won't work
        if (!!searchPreferences[k]) {
          numFiltersApplied++;
        }
        break;
      case 'location':
        const setLatLng = searchPreferences.location?.latlng;
        const defLatLng = defaultPreferences.location?.latlng;
        if (
          setLatLng?.lat !== defLatLng?.lat ||
          setLatLng?.lng !== defLatLng?.lng
        ) {
          numFiltersApplied++;
        }
        break;
      default:
        if (searchPreferences[k] !== defaultPreferences[k]) {
          numFiltersApplied++;
        }
        break;
    }
  });
  return numFiltersApplied;
};

const SearchFilterMenu = ({
  defaultPreferences,
  onClose,
  allowedFilters,
}: {
  defaultPreferences: UserSearchPreferences;
  onClose?(): void;
  allowedFilters: AllowedFilters;
}) => {
  const dispatch = useAppDispatch();
  const searchPreferences = useSelector(getSearchPreferences);
  const [init, setInit] = useState(false);
  const [localPreferences, setLocalPreferences] =
    useState<UserSearchPreferences>(searchPreferences);

  const isDefaultTimes = (): boolean => {
    let isDefaultTimes = true;
    for (var time of defaultTimeFilters) {
      const compare = localPreferences.times?.find(
        (pref) => pref.weekDay === time.weekDay
      );
      if (
        !compare ||
        time.start !== compare.start ||
        time.end !== compare.end
      ) {
        isDefaultTimes = false;
        break;
      }
    }
    return isDefaultTimes;
  };

  const getFilteredTimes = (): WeekDayTimeFilter[] => {
    if (isDefaultTimes()) {
      // Set empty time localPreferences if the current choices are default
      return [];
    }
    return localPreferences.times.filter(
      (time) => time.start !== undefined && time.end !== undefined
    );
  };

  const handleApplyPreferences = () => {
    const data = {
      ...localPreferences,
      maxPrice: isNaN(localPreferences.maxPrice)
        ? undefined
        : localPreferences.maxPrice,
      times: getFilteredTimes(),
    };
    rudderanalytics.track('Search filters applied', { localPreferences: data });
    dispatch(setSearchPreferences(data));
    if (onClose) {
      onClose();
    }
  };

  const handlePreferencesChange = (newPreferences: UserSearchPreferences) => {
    rudderanalytics.track('Search filters updated');
    setLocalPreferences(newPreferences);
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
    } else {
      handlePreferencesChange(localPreferences);
    }
  }, [localPreferences]);

  return (
    <div className="space-y-6">
      {allowedFilters.includes('times') && (
        <>
          <TimeAndWeekDayFilter
            times={localPreferences.times}
            onChange={(times: WeekDayTimeFilter[]) => {
              setLocalPreferences({ ...localPreferences, times });
            }}
          />
          <hr />
        </>
      )}
      {allowedFilters.includes('location') &&
        localPreferences.location !== undefined && (
          <>
            <RadiusFilter
              location={localPreferences.location}
              onChange={(location: LocationFilter) => {
                setLocalPreferences({ ...localPreferences, location });
              }}
            />
            <hr />
          </>
        )}
      {allowedFilters.includes('segment') && (
        <>
          <SegmentFilter
            segment={localPreferences.segment}
            onChange={(segment: ActivitySegment) => {
              setLocalPreferences({ ...localPreferences, segment });
            }}
          />
          <hr />
        </>
      )}
      {allowedFilters.includes('maxPrice') && (
        <>
          <MaxPriceFilter
            maxPrice={localPreferences.maxPrice}
            onChange={(maxPrice: number) => {
              setLocalPreferences({ ...localPreferences, maxPrice });
            }}
          />
          <hr />
        </>
      )}
      <Controls variant="block">
        <Button
          variant="contained"
          onClick={() => setLocalPreferences(defaultPreferences)}
        >
          Clear
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleApplyPreferences}
        >
          Apply
        </Button>
      </Controls>
    </div>
  );
};

export const SearchFilters = ({
  allowedFilters = ['times', 'segment'],
}: {
  allowedFilters?: AllowedFilters;
}) => {
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail);
  const accountIsLoaded = useSelector(getAccountIsLoaded);
  const activity = useSelector(getActivity);
  const searchPreferences = useSelector(getSearchPreferences);
  const [defaultPreferences, setDefaultPreferences] = useState({
    ...DEFAULT_SEARCH_PREFERENCES,
    activity,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const isOpen = !!anchorEl;
  const numFiltersApplied = getNumFiltersApplied(
    allowedFilters,
    searchPreferences,
    defaultPreferences
  );

  const handleOpen = (event) => {
    rudderanalytics.track('Search filters opened');
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (allowedFilters.includes('location') && accountIsLoaded) {
      const defaultLocation = {
        address: getAddressString(account.address),
        radius: 25,
        latlng: account.address.latlng,
      };
      setDefaultPreferences((prev) => ({
        ...prev,
        location: defaultLocation,
      }));
      if (searchPreferences.location === undefined) {
        dispatch(
          setSearchPreferences({
            ...searchPreferences,
            activity,
            location: defaultLocation,
          })
        );
      }
    } else {
      dispatch(
        setSearchPreferences({
          ...searchPreferences,
          activity,
        })
      );
    }
  }, [accountIsLoaded]);

  return (
    <>
      <Button
        className={`bg-white w-full md:w-auto${
          numFiltersApplied > 0 ? ' border-2' : ''
        }`}
        icon={<PreferencesIcon width={24} />}
        onClick={handleOpen}
        color={numFiltersApplied > 0 ? 'primary' : 'default'}
        variant="outlined"
        fullWidth
      >
        Filters
        {numFiltersApplied > 0 ? ` (${numFiltersApplied.toString()})` : ''}
        <DownIcon className="pl-1.5" width={24} />
      </Button>
      <Popover
        open={isOpen}
        title="Search filters"
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -16, horizontal: 'right' }}
        modalBreakpoint="md"
        containerClassName="w-[28rem]"
      >
        <SearchFilterMenu
          defaultPreferences={defaultPreferences}
          allowedFilters={allowedFilters}
          onClose={() => setAnchorEl(null)}
        />
      </Popover>
    </>
  );
};

const InstructorListFiltersComponent = ({
  title = true,
}: {
  title?: boolean;
}) => {
  const activity = useSelector(getActivity);
  return (
    <>
      <div className="w-full max-w-sm mx-auto space-y-4 ">
        <div className="flex items-center justify-between">
          {title && (
            <h3>Pick your {activity.instructorDescription.toLowerCase()}</h3>
          )}
          <SearchFilters />
        </div>
        <div className="flex justify-center">
          <div className="w-full space-y-4">
            <InstructorList />
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructorListFiltersComponent;
