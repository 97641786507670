import Button from 'components/button';
import Input from 'components/input';
import InputAddress from 'components/input-address';
import InputFullDate from 'components/input-date/mmm-dd-yyyy';
import { FETCH_STATE } from 'config';
import Snuggle from 'layouts/snuggle';
import { Address } from 'models/geo';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAccountFetchState } from 'state/selectors';
import { isInstructorOldEnough } from 'utils/business-logic';
import { InstructorOnboardingRoutes } from 'utils/routing';
import { BaseStepProps, ControlClasses, TextClasses, TitleClasses } from '..';

//const debouncedSave = debounce(
//  (
//    modifyAccount: (data: Partial<AccountUpdateSerializer>) => void,
//    data: Partial<AccountUpdateSerializer>
//  ) => {
//    modifyAccount(data);
//  },
//  500
//);

const BasicInfo: React.FC<BaseStepProps> = ({ account, modifyAccount }) => {
  const history = useHistory();
  const [address, setAddress] = useState(account.address);
  const [birthdate, setBirthday] = useState<string>(account.birthdate);
  const [emergencyContactName, setEmergName] = useState(
    account.emergencyContact ? account.emergencyContact.name : ''
  );
  const [emergencyContactPhone, setEmergPhone] = useState<string>(
    account.emergencyContact ? account.emergencyContact.phoneNumber : ''
  );
  const modifyFetchState = useSelector(getAccountFetchState);

  const handleAddressUpdate = (address: Address) => {
    setAddress(address);
    //debouncedSave(modifyAccount, { address });
  };

  const handleSave = async () => {
    const result = await modifyAccount({
      address,
      birthdate,
      emergencyContact: {
        name: emergencyContactName,
        phoneNumber: emergencyContactPhone,
      },
    });
    if (result) {
      const next = isOldEnough
        ? InstructorOnboardingRoutes.S05
        : InstructorOnboardingRoutes.ErrorTooYoung;
      history.push(next);
    }
  };

  //useEffect(() => {
  //  if (birthdate && birthdate !== account.birthdate) {
  //    modifyAccount({ birthdate });
  //  }
  //}, [birthdate]);

  //useEffect(() => {
  //  if (emergencyContactName !== '' && emergencyContactPhone !== '') {
  //    let shouldSave = false;
  //    if (account.emergencyContact) {
  //      if (
  //        account.emergencyContact.name !== emergencyContactName ||
  //        account.emergencyContact.phoneNumber !== emergencyContactPhone
  //      ) {
  //        shouldSave = true;
  //      }
  //    } else {
  //      shouldSave = true;
  //    }
  //    if (shouldSave) {
  //      const updatedContact = {
  //        name: emergencyContactName,
  //        phoneNumber: emergencyContactPhone,
  //      };
  //      debouncedSave(modifyAccount, { emergencyContact: updatedContact });
  //    }
  //  }
  //}, [emergencyContactName, emergencyContactPhone]);

  useEffect(() => {
    if (
      account.emergencyContact?.phoneNumber &&
      !isValidPhoneNumber(account.emergencyContact?.phoneNumber)
    ) {
      if (isValidPhoneNumber('+1' + account.emergencyContact?.phoneNumber)) {
        setEmergPhone('+1' + account.emergencyContact?.phoneNumber);
      }
    }
  }, []);

  const isOldEnough = isInstructorOldEnough(account.age);
  const canContinue =
    [FETCH_STATE.IDLE, FETCH_STATE.FAILED].includes(
      modifyFetchState as FETCH_STATE
    ) &&
    address.line1 !== '' &&
    address.postalCode !== '' &&
    !!birthdate &&
    !!emergencyContactName &&
    !!emergencyContactPhone;
  return (
    <div>
      <h1 className={TitleClasses}>Enter your basic info</h1>
      <p className={TextClasses}>
        We don't share this or display it on your profile
      </p>
      <InputAddress
        name="address"
        onChange={handleAddressUpdate}
        {...address}
      />
      <InputFullDate
        name="birthdate"
        label="Date of Birth"
        initialDate={account.birthdate}
        onChange={(date) => setBirthday(date)}
        earliestDate={moment().startOf('year').subtract(80, 'years')}
        latestDate={moment().endOf('year').subtract(16, 'years')}
      />
      <Snuggle>
        <Input
          name="emergencyContactName"
          value={emergencyContactName}
          onChange={(event) => setEmergName(event.currentTarget.value)}
          title="Emergency Contact Name"
          required={false}
          placeholder="eg John Smith"
        />
        <label htmlFor="emergencyContactPhone">Emergency Contact Phone</label>
        <PhoneInput
          name="emergencyContactPhone"
          value={emergencyContactPhone}
          onChange={setEmergPhone}
          country="CA"
          placeholder="604-555-0938"
        />
      </Snuggle>
      <div className={ControlClasses}>
        <Button
          //to={
          //  isOldEnough
          //    ? InstructorOnboardingRoutes.S05
          //    : InstructorOnboardingRoutes.ErrorTooYoung
          //}
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!canContinue}
          isLoading={modifyFetchState === FETCH_STATE.PUT}
        >
          Save and Continue
        </Button>
      </div>
    </div>
  );
};

export default BasicInfo;
