import api from 'api';
import {
  CertificateType,
  Certification,
  CertifyingBody,
} from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import HelpButton from 'components/button-help';
import ButtonLarge from 'components/button-large';
import CreateCertification from 'components/certification-card/create';
import Link from 'components/link';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { certificationsUpdated } from 'state/slice/account';
import { InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  QuestionClasses,
  TitleClasses,
} from '..';

const getCertTitle = (cert: Certification) =>
  cert.certifyingBody === CertifyingBody.CanadianRedCross
    ? 'Canadian Red Cross'
    : cert.certifyingBody === CertifyingBody.LifesavingSociety
    ? 'Lifesaving Society'
    : cert.nonStandardCertificationName !== ''
    ? cert.nonStandardCertificationName
    : 'Unknown Certificate Type';
const getCertSubtitle = (cert: Certification) =>
  cert.certificateType === CertificateType.WaterSafetyInstructor
    ? 'Water Safety Instructor, '
    : cert.certificateType === CertificateType.SwimInstructor
    ? 'Swim Instructor, '
    : cert.certificateType === CertificateType.NationalLifeguard
    ? 'National Lifeguard, '
    : '';

const Welcome: React.FC<BaseStepProps> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [instructionCert, setInstructionCert] = useState<Certification>();
  const [lifeguardCert, setLifeguardCert] = useState<Certification>();
  const [showNotOnHandModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const waterSafetyCerts = props.account.certifications.filter(
    (cert) =>
      cert.certificateType === CertificateType.WaterSafetyInstructor ||
      cert.certificateType === CertificateType.SwimInstructor
  );
  const lifeguardCerts = props.account.certifications.filter(
    (cert) => cert.certificateType === CertificateType.NationalLifeguard
  );
  const nonStandardCerts = props.account.certifications.filter(
    (cert) => cert.nonStandardCertificationName !== ''
  );
  const askForLifeguarding = false; // props.account.address.regionCode === 'ON';
  const isFormCorrect =
    !!instructionCert && askForLifeguarding === !!lifeguardCert;
  const isCertsComplete =
    waterSafetyCerts.length > 0 &&
    ((askForLifeguarding && lifeguardCerts.length) || !askForLifeguarding);
  function onSubmit(evt) {
    if (isCertsComplete) {
      history.push(InstructorOnboardingRoutes.S09);
    } else {
      setIsSaving(true);
      const promises = [
        api.account.certifications.create(props.account.username, {
          ...instructionCert,
        }),
      ];
      if (askForLifeguarding) {
        promises.push(
          api.account.certifications.create(props.account.username, {
            ...lifeguardCert,
          })
        );
      }
      Promise.all(promises).then((responses) => {
        let certs = responses[0].data;
        if (
          responses.length > 1 &&
          responses[1].data.length > responses[0].data.length
        ) {
          certs = responses[1].data;
        }
        dispatch(certificationsUpdated(certs));
        history.push(InstructorOnboardingRoutes.S09);
      });
    }
  }

  const onInstructorCertComplete = (certificate: Certification) => {
    setInstructionCert(certificate);
  };

  const onLifeguardCertComplete = (certificate: Certification) => {
    setLifeguardCert(certificate);
  };

  return (
    <div>
      {isSaving && <Loading />}
      <h1 className={TitleClasses}>Show us your qualifications</h1>
      <p className={QuestionClasses}>Which teaching certificate do you have?</p>
      <div className="my-8">
        {waterSafetyCerts.length > 0 ? (
          waterSafetyCerts.map((cert, i) => (
            <ButtonLarge
              key={`wsi-${i}`}
              variant="check"
              title={getCertTitle(cert)}
              subtitle={`${getCertSubtitle(cert)}${cert.certificateNumber}`}
              selected={true}
            />
          ))
        ) : (
          <CreateCertification
            type="instructor"
            onComplete={onInstructorCertComplete}
          />
        )}
      </div>
      <div className="my-8">
        {lifeguardCerts.length > 0 &&
          lifeguardCerts.map((cert, i) => (
            <ButtonLarge
              key={`wsi-${i}`}
              variant="check"
              title={getCertTitle(cert)}
              subtitle={`${getCertSubtitle(cert)}${cert.certificateNumber}`}
              selected={true}
            />
          ))}
        {askForLifeguarding && lifeguardCerts.length === 0 && (
          <React.Fragment>
            <p className={QuestionClasses}>
              Which lifeguard certificate do you have? (required in Ontario)
            </p>
            <CreateCertification
              type="lifeguard"
              onComplete={onLifeguardCertComplete}
            />
          </React.Fragment>
        )}
      </div>
      <div className="my-6 text-center">
        <HelpButton onClick={() => setShowModal(true)}>
          I have these certs but not on hand
        </HelpButton>
      </div>
      <div className="my-8">
        {nonStandardCerts.length > 0 && (
          <div className="text-center">
            <p className="text-gray-700">
              We have recorded your other cert types, but you must hold the
              above certificates to teach with Propel.
            </p>
          </div>
        )}
      </div>
      <div className={ControlClasses}>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={!(isFormCorrect || isCertsComplete)}
        >
          Save and Continue
        </Button>
      </div>
      {waterSafetyCerts.length === 0 && (
        <div className="my-4 text-center">
          <Link to={InstructorOnboardingRoutes.S08}>
            I don’t have these certs right now
          </Link>
        </div>
      )}
      <Modal
        name="Instructor — Certs not on hand"
        maxWidth="xs"
        open={showNotOnHandModal}
        onClose={() => setShowModal(false)}
        title="Certs not on hand?"
      >
        <p>
          If you are certified but just don't have your awards on hand, you can
          come back and enter them later. Your progress up to this point has
          been saved.
        </p>
      </Modal>
    </div>
  );
};

export default Welcome;
