import Title from 'components/title';
import * as React from 'react';
import css from './style.module.scss';

interface Props {
  align?: 'left' | 'center' | 'right' | 'justify';
  title?: string;
  height?: 'short' | 'regular' | 'tall';
  hug?: 'top';
  className?: string;
  style?: {};
  text?: boolean;
  onClick?(): void;
  children?: any;
}

let key = 0;

const Section: React.FunctionComponent<Props> = ({
  align,
  title,
  height,
  hug,
  className,
  style,
  text,
  onClick,
  children,
}) => (
  <div
    onClick={onClick}
    style={style}
    className={`${css[height]} ${css[align]} ${css.section}${cls(
      className
    )}${cls(hug, css[hug + 'Hug'])}`}
  >
    {text ? (
      <div className={css.text}>
        {title && (
          <Title align="left" as="h4" variant="title">
            {title}
          </Title>
        )}
        {children}
      </div>
    ) : (
      [
        title && (
          <Title align="left" as="h4" variant="title" key={key++}>
            {title}
          </Title>
        ),
        children,
      ]
    )}
  </div>
);

Section.defaultProps = {
  text: false,
  hug: undefined,
  align: 'left',
  height: 'regular',
};

export default Section;
