import Header from 'components/app-header';
import Footer from 'components/footer';
import NetworkError from 'components/network-error';
import ErrorJs from 'containers/error-js';
import Help from 'containers/help';
import ScrollToTop from 'containers/scroll-to-top';
import 'helpers/styles/base.module.scss';
import usePropelSession from 'hooks/useSession';
import React from 'react';
import AppRoutes from './Routes';
// Do not remove
require('./tailwind.css');
require('utils/global');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
// import LeadMagnets from 'features/lead-magnets';

const App = () => {
  usePropelSession();
  return (
    <ScrollToTop>
      <div id="app" className="flex flex-col min-h-full">
        <Header />
        <ErrorJs>
          <div className="relative min-h-screen">
            <AppRoutes />
          </div>
          <Footer />
        </ErrorJs>
        <NetworkError />
      </div>
      {/* <LeadMagnets /> */}
      <Help />
    </ScrollToTop>
  );
};

export default App;
