import { NavigateNext } from '@mui/icons-material';
import {
  FacilityAmenitySerializer,
  PoolTemperature,
} from 'api/Serializers/Facilities';
import Button from 'components/button';
import FavouriteButton from 'components/favourite-button';
import Link from 'components/link';
import Loading from 'components/loading';
import GoogleMap from 'components/map';
import Modal from 'components/modal';
import { QueryParams } from 'config';
import ImageCarousel from 'containers/image-carousel';
import InstructorList from 'containers/instructor-list';
import ListTextItems from 'containers/list-text-items';
import Page from 'containers/page';
import Reviews from 'containers/reviews';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import { FacilityDetailMeta as Meta } from 'metadata';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import {
  getAccountDetail,
  getActivity,
  getSearchCity,
  getSearchFacility,
  getSearchFacilityGoogleReviews,
  getSearchFacilityGoogleReviewsCount,
} from 'state/selectors';
import { fetchFacilityGoogleReviewsNextPage } from 'state/slice/search';
import { canViewFacility } from 'utils/business-logic';
import { APP_ROUTES } from 'utils/routing';
import FacilityErrorRedirect from '../facility-error';

const Amenities = ({
  amenities,
}: {
  amenities: FacilityAmenitySerializer[];
}) => (
  <ListTextItems
    options={[...amenities]
      .sort((a, b) => b.value - a.value)
      .map((a) => ({ ...a, hint: '' }))}
  />
);

const VIEW_INSTRUCTORS_VALUE = 'viewInstructors';

const FacilityDetailPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const query: any = useQuery();
  const viewInstructorsParam = query.get(QueryParams.Action);
  const { facilitySlug } = useParams<any>();
  const activity = useSelector(getActivity);
  const account = useSelector(getAccountDetail);
  const facility = useSelector(getSearchFacility);
  const city = useSelector(getSearchCity);
  const reviews = useSelector(getSearchFacilityGoogleReviews);
  const reviewsCount = useSelector(getSearchFacilityGoogleReviewsCount);

  const temperatureLabel: string = facility?.attributes.temperature
    ? Object.keys(PoolTemperature).find(
        (key) => PoolTemperature[key] === facility.attributes.temperature
      ) ?? ''
    : '';

  const getViewInstructorsUrl = (): string => {
    const newQuery = new URLSearchParams(query.toString());
    newQuery.set(QueryParams.Action, VIEW_INSTRUCTORS_VALUE);
    const queryString =
      newQuery.toString() === '' ? '' : `?${newQuery.toString()}`;
    return match.url + queryString;
  };

  const handleCloseInstructorList = () => {
    query.delete(QueryParams.Action);
    const queryString = query.toString() === '' ? '' : `?${query.toString()}`;
    history.push(match.url + queryString);
  };

  const handleCityClick = () => {
    rudderanalytics.track('Facility unselected', {
      unselectedFacility: facility,
    });
  };

  if (!facility || facilitySlug !== facility.slug) {
    return <Loading />;
  } else if (!canViewFacility(facility, account)) {
    return <FacilityErrorRedirect />;
  }

  return (
    <Page {...Meta({ facility })}>
      <div className="max-w-6xl px-4 py-8 mx-auto sm:px-6 lg:px-8">
        {city?.slug && (
          <div className="pb-4 space-x-2 text-sm">
            <Link
              to={APP_ROUTES.BROWSE_CITY(city.slug)}
              onClick={handleCityClick}
            >
              {city.label || 'All cities'}
            </Link>
            <span>{'>'}</span>
            <span>{facility.displayName}</span>
          </div>
        )}
        <div className="flex md:space-x-4 lg:space-x-8">
          <div className="w-full md:w-[calc(100vw_-_26rem)] lg:w-[calc(100vw_-_30rem)] md:max-w-2xl space-y-8">
            <div className="space-y-6">
              <div className="-mx-2 sm:-mx-4 md:mr-0 md:-ml-3 lg:-ml-4">
                <ImageCarousel>
                  {facility.media.map(({ url }) => (
                    <img key={url} src={url} />
                  ))}
                </ImageCarousel>
              </div>
              <div>
                <h1 className="pr-10">{facility.displayName}</h1>
                <h4 className="font-semibold text-gray-700 text-md">
                  {facility.category} {facility.attributes.subtype}{' '}
                  {facility.assetType}
                </h4>
                <div className="text-sm">
                  <span>{`${facility.attributes.temperature}°C (${temperatureLabel})`}</span>
                  <span>{' · '}</span>
                  <span>{`${facility.attributes.maxLength} long`}</span>
                  <span>{' · '}</span>
                  <span>{`${facility.attributes.minDepth} to ${facility.attributes.maxDepth} deep`}</span>
                </div>
                <div className="absolute top-0 right-0">
                  <FavouriteButton
                    contentType="facility"
                    contentObject={facility}
                    variant="icon"
                    size="small"
                  />
                </div>
              </div>
              <p className="whitespace-pre-wrap">{facility.description}</p>
            </div>
            {reviewsCount > 0 && (
              <>
                <hr />
                <div>
                  <h3 className="mb-6 font-semibold text-gray-900 font-body">
                    What people are saying
                  </h3>
                  <Reviews
                    name={`Facility ${facility.displayName}`}
                    type="reviews"
                    reviews={reviews}
                    count={reviewsCount}
                    handleLoadNextPage={async () => {
                      await dispatch(fetchFacilityGoogleReviewsNextPage());
                    }}
                    limit={3}
                  />
                </div>
              </>
            )}
            <hr />
            <div>
              <h3 className="mb-2 font-semibold text-gray-900 font-body">
                Amenities
              </h3>
              <Amenities amenities={facility.amenities} />
            </div>
            <hr />
            <div>
              <h3 className="mb-3 font-semibold text-gray-900 font-body">
                Location
              </h3>
              <div className="overflow-hidden rounded-2xl">
                <GoogleMap latlng={facility.region.latlng} />
              </div>
            </div>
            <div>
              <h3 className="mb-2 font-semibold text-gray-900 font-body">
                {facility.parkingAndAccess ? 'Parking & access' : 'Access'}
              </h3>
              <p className="whitespace-pre-wrap">
                {facility.assetType.capitalize()} access instructions and
                address become available only after your booking is made. To
                view these, login to your Propel account and navigate to the
                lesson details section.
                {!!facility.parkingAndAccess ? facility.parkingAndAccess : ''}
              </p>
            </div>
            <div className="card bg-background">
              <h3 className="mb-2 font-semibold text-gray-900 font-body">
                Welcoming all swim instructors
              </h3>
              <p className="whitespace-pre-wrap">
                Are you a swim instructor interested in teaching your own
                private lessons? We invite you to do that here! Learn more about
                our pool and how you can use it below:
              </p>
              <span>
                <Link to={APP_ROUTES.OPPORTUNITIES.nav(facilitySlug)} underline>
                  See pool use details and schedule
                </Link>
              </span>
            </div>
          </div>
          <div className="hidden md:block w-80 lg:w-96">
            <InstructorList />
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 left-0 right-0 z-50 md:hidden">
        <div className="">
          <div className="px-6">
            <div className="py-6">
              <div className="flex justify-end rounded-md">
                <div>
                  <Button
                    to={getViewInstructorsUrl()}
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    View instructors here <NavigateNext />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        name="Booking — View facility instructors list"
        title={`Pick your ${activity.instructorDescription.toLowerCase()}`}
        open={viewInstructorsParam === VIEW_INSTRUCTORS_VALUE}
        onClose={handleCloseInstructorList}
        fullScreen={true}
      >
        <InstructorList title={false} />
      </Modal>
    </Page>
  );
};

export default FacilityDetailPage;
