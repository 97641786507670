import { Conversation, ConversationList } from '@chatscope/chat-ui-kit-react';
import { Search } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import api from 'api';
import { AdminProfile, AdminProfiles } from 'api/Serializers/Accounts/Admin';
import { ClientSerializer } from 'api/Serializers/Clients';
import { InstructorListSerializer } from 'api/Serializers/Instructors';
import Input from 'components/input';
import Loading from 'components/loading';
import { FETCH_STATE, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { MessagingConvoFailedError } from 'lang/en/Snackbars';
import { messagingClientManager } from 'messagingClientManager';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getActiveConversationSid,
  getClientList,
  getConversations,
  getInstructorListFetchState,
  getInstructorsSchedulable,
  getMessagingConnectionState,
  getMessagingInit,
  getParticipants,
  getUser,
} from 'state/selectors';
import {
  insertConversationDataSets,
  ReduxConversation,
  ReduxParticipant,
  setActiveConversationSid,
} from 'state/slice/messaging';
import { SHARED_ROUTES } from 'utils/routing';
import { MessagingAvatar } from './components';
import { getMessagingDateStr } from './utils';

interface ConvoWithParticipant {
  conversation: ReduxConversation;
  participant: ReduxParticipant;
  type: 'convoWithParticipant';
}

const ConversationsContainer = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  // FIXME match chatscope's breakpoints with our own
  const isBreakSm = useMediaQuery(theme.breakpoints.up('sm'));
  const user = useSelector(getUser);
  const { userId } = useParams<{ userId: string }>();
  const init = useSelector(getMessagingInit);
  const connectionState = useSelector(getMessagingConnectionState);
  const conversations = useSelector(getConversations);
  const participants = useSelector(getParticipants);
  const activeConvoSid = useSelector(getActiveConversationSid);
  const instructors = useSelector(getInstructorsSchedulable);
  const instructorListFetchState = useSelector(getInstructorListFetchState);
  const clients = useSelector(getClientList);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  // matched convos with participants with the current user filtered out
  const convosWithParticipant: ConvoWithParticipant[] = useMemo(
    () =>
      conversations.reduce((final, conversation: ReduxConversation) => {
        if (participants[conversation.sid]?.length > 1) {
          const participant = participants[conversation.sid].find(
            (elt: ReduxParticipant) => elt.identity !== user.id.toString()
          );
          return participant.messagingDisabled
            ? final
            : final.concat([
                { conversation, participant, type: 'convoWithParticipant' },
              ]);
        } else {
          return final;
        }
      }, []),
    [conversations, participants]
  );

  // Alphabetically sorted users with no conversation started
  const noConvoProfiles: (
    | InstructorListSerializer
    | ClientSerializer
    | AdminProfile
  )[] = useMemo(() => {
    let profiles = [];
    switch (user.type) {
      case UserType.Client:
        profiles = instructors;
        break;
      case UserType.Instructor:
        profiles = clients;
        break;
      case UserType.Admin:
        profiles = AdminProfiles.filter(
          (profile) => profile.userId !== user.id
        );
    }
    if (!profiles) {
      return [];
    }
    return profiles
      .filter(
        (profile) =>
          !profile.messagingDisabled &&
          !convosWithParticipant.some(
            (elt) => elt.participant?.identity === profile.userId.toString()
          )
      )
      .sort((a, b) =>
        a.displayName.toUpperCase() > b.displayName.toUpperCase()
          ? 1
          : b.displayName.toUpperCase() > a.displayName.toUpperCase()
          ? -1
          : 0
      );
  }, [convosWithParticipant, instructors, clients]);

  useEffect(() => {
    const func = async () => {
      if (init !== undefined) {
        if (init) {
          if (userId === undefined) {
            dispatch(setActiveConversationSid(null));
          } else {
            let matchingId =
              convosWithParticipant.find(
                (elt) => elt.participant.identity.toString() === userId
              )?.conversation.sid ??
              noConvoProfiles
                .find((elt) => elt.userId.toString() === userId)
                ?.userId.toString();
            if (matchingId !== undefined) {
              await handleConversationClick(matchingId);
            }
          }
        }
        if (isLoading) {
          setIsLoading(false); // if init failed, the error modal will display higher up
        }
      }
    };
    func();
  }, [init, userId]);

  const handleConversationClick = async (id: string) => {
    if (id.length === 34 && id !== activeConvoSid) {
      dispatch(setActiveConversationSid(id));
    } else if (id.length !== 34) {
      setIsLoading(true);
      try {
        if (connectionState !== 'connected') {
          throw new Error('Not connected to messaging service');
        }
        const response = await api.conversations.create({ id: parseInt(id) });
        const conversation =
          await messagingClientManager.client.getConversationBySid(
            response.data.sid
          );
        await dispatch(insertConversationDataSets([conversation]));
        dispatch(setActiveConversationSid(conversation.sid));
        document
          .getElementById(`conversation-sid-${conversation.sid}`)
          ?.scrollIntoView();
      } catch (err) {
        enqueueSnackbar(MessagingConvoFailedError);
        logger.error(`Conversation creation failed: ${err}`, {
          twilioErrorCode: (err as any).body?.code,
          twilioStatusCode: (err as any).body?.status,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const searchFiltered = (
    arr: (
      | InstructorListSerializer
      | ClientSerializer
      | AdminProfile
      | ConvoWithParticipant
    )[]
  ) =>
    searchValue === ''
      ? arr
      : arr.filter((elt: any) =>
          (
            (elt.type === 'convoWithParticipant' ? elt.participant : elt)
              ?.displayName ?? ''
          )
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );

  return (
    <>
      {instructorListFetchState === FETCH_STATE.GET && (
        <Loading message="Getting your instructors..." />
      )}
      <ConversationListHeader
        noAvailableConvos={
          !convosWithParticipant?.length && !noConvoProfiles?.length
        }
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {isLoading && <Loading position="absolute" />}
      <ConversationList
        className={isLoading ? 'pointer-events-none opacity-50' : null}
      >
        {(searchFiltered(convosWithParticipant) as ConvoWithParticipant[]).map(
          (elt) => (
            <Conversation
              id={`conversation-sid-${elt.conversation.sid}`}
              key={elt.conversation.sid}
              unreadCnt={elt.conversation.unreadMessageCount}
              active={elt.conversation.sid === activeConvoSid}
              onClick={() =>
                history.push(
                  SHARED_ROUTES.MESSAGES.nav(elt.participant.identity)
                )
              }
            >
              <MessagingAvatar as="Avatar" participant={elt.participant} />
              <Conversation.Content
                className={!isBreakSm && !activeConvoSid ? '!flex' : ''}
                name={
                  elt.participant?.displayName?.capitalize() ?? 'Unknown User'
                }
                info={
                  elt.conversation.lastMessage.dateCreated
                    ? getMessagingDateStr(
                        elt.conversation.lastMessage.dateCreated
                      )
                    : 'No chat history'
                }
              />
            </Conversation>
          )
        )}
        {(
          searchFiltered(noConvoProfiles) as (
            | InstructorListSerializer
            | ClientSerializer
            | AdminProfile
          )[]
        ).map((profile) => (
          <Conversation
            key={profile.userId.toString()}
            active={profile.userId.toString() === userId}
            onClick={() => handleConversationClick(profile.userId.toString())}
          >
            <MessagingAvatar
              as="Avatar"
              src={profile.avatar}
              name={profile.displayName}
            />
            <Conversation.Content
              className={!isBreakSm && !activeConvoSid ? '!flex' : ''}
              name={profile.displayName?.capitalize() ?? 'Unknown User'}
              info="No chat history"
            />
          </Conversation>
        ))}
      </ConversationList>
    </>
  );
};

const ConversationListHeader = ({
  noAvailableConvos,
  searchValue,
  setSearchValue,
}: {
  noAvailableConvos: boolean;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const theme = useTheme();
  const betweenSmMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  return (
    <>
      <div className={`flex gap-2 p-2${betweenSmMd ? ' justify-center' : ''}`}>
        <Input
          className={`!m-0 w-full${betweenSmMd ? ' hidden' : ''}`}
          inputClassName="m-0"
          name="conversation-search"
          placeholder="Search conversations"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          icon={<Search />}
        ></Input>
      </div>
      <div className={betweenSmMd || !noAvailableConvos ? 'hidden' : null}>
        <p className="p-2 m-0 text-lg text-center text-blue-700">
          You have nobody to message!
        </p>
      </div>
    </>
  );
};

export default ConversationsContainer;
