import { DATE_FMT } from 'config';
import moment from 'moment-timezone';

// Timestamp formats:
// TIME_A if sent today
// DOW_TIME_A if sent this week
// MON_D_TIME_A if within 11 months
// MON_D_YEAR_TIME_A otherwise
export const getMessagingDateStr = (dt: string): string => {
  return moment(dt).format(
    moment(dt).startOf('day').isSame(moment().startOf('day'))
      ? DATE_FMT.TIME_A
      : moment(dt).isAfter(moment().subtract(7, 'days').startOf('day'))
      ? DATE_FMT.DOW_TIME_A
      : moment(dt).isAfter(moment().startOf('month').subtract(11, 'months'))
      ? DATE_FMT.MON_D_TIME_A
      : DATE_FMT.MON_D_YEAR_TIME_A
  );
};
