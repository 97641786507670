import React from 'react';
import Background from './background';
import style from './style.module.scss';

interface Props {
  background: string;
  title?: string;
  fadeOpacity?: 0 | 10 | 20 | 30 | 40 | 50 | 60;
  height?: 'screen' | 'full' | 'auto';
  imgId?: string;
  subtitle?: string;
  className?: string;
  bodyClassName?: string;
  padding?: 'default' | 'slider' | 'none';
  backgroundPosition?: string;
  children?: any;
}

const Hero: React.FunctionComponent<Props> = (props: Props) => (
  <div
    className={`${style.hero} ${style[`${props.height}Height`]}${cls(
      props.className
    )}`}
  >
    <Background
      fadeOpacity={props.fadeOpacity}
      imgId={props.imgId}
      background={props.background}
      backgroundPosition={props.backgroundPosition}
      height={props.height}
    />
    <div className={`max-w-2xl ${style.body}${cls(props.bodyClassName)}`}>
      <div className={`${style.children} ${style[`${props.padding}-padding`]}`}>
        {props.title && (
          <h1 className="mb-4 text-5xl leading-none text-white md:text-[2.8rem] drop-shadow">
            {props.title}
          </h1>
        )}
        {props.subtitle && (
          <h2 className="mb-10 text-xl font-semibold text-white md:text-2xl drop-shadow">
            {props.subtitle}
          </h2>
        )}
        {props.children}
      </div>
    </div>
  </div>
);

Hero.defaultProps = {
  height: 'screen',
  padding: 'default',
  fadeOpacity: 40,
  backgroundPosition: '50% 50%',
};

export default Hero;
