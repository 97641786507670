import Page from 'containers/page';
import { HelpIcon } from 'icons';
import React from 'react';

interface Props {
  children: any;
  title: string;
  onClickHelp?(): void;
  width?:
    | 'full'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl';
}

const getMaxWidth = (width) =>
  width === 'full'
    ? 'max-w-full'
    : width === 'xs'
    ? 'max-w-xs'
    : width === 'sm'
    ? 'max-w-sm'
    : width === 'md'
    ? 'max-w-md'
    : width === 'lg'
    ? 'max-w-lg'
    : width === 'xl'
    ? 'max-w-xl'
    : width === '2xl'
    ? 'max-w-2xl'
    : width === '3xl'
    ? 'max-w-3xl'
    : width === '4xl'
    ? 'max-w-4xl'
    : width === '5xl'
    ? 'max-w-5xl'
    : width === '6xl'
    ? 'max-w-6xl'
    : 'max-w-3xl';

const Dashboard = ({
  title,
  width,
  onClickHelp = undefined,
  children,
}: Props) => {
  return (
    <Page title={title}>
      <div className="w-full p-1 sm:p-4 bg-background">
        <div className={`${getMaxWidth(width)} mx-auto w-full relative`}>
          <div className=" print:hidden">
            <h1 className="px-2 my-4 text-2xl font-semibold leading-tight text-left text-gray-700 sm:px-0 font-body">
              {title}
            </h1>
            {!!onClickHelp && (
              <span
                onClick={onClickHelp}
                className="absolute top-0 right-0 flex items-center gap-2 text-gray-600 cursor-pointer h-7 hover:text-blue-500 text-icon-sm"
              >
                <span className="text-sm font-light leading-none">
                  Learn more
                </span>
                <HelpIcon width={24} />
              </span>
            )}
            <hr className="my-6 border-t-0 border-gray-400" />
          </div>
          <div className="space-y-6">{children}</div>
        </div>
      </div>
    </Page>
  );
};

Dashboard.defaultProps = {
  width: '3xl',
};

export default Dashboard;
