import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import Base, { ButtonProps } from './base';
import ListBtn from './list';

export const BaseButton = Base;
export const ListButton = ListBtn;

const focus = (
  color: 'gray' | 'blue' | 'red' | 'yellow',
  opacity = 40,
  hue = 500
) =>
  `focus:outline-none focus:ring focus:ring-opacity-${opacity} focus:ring-${color}-${hue}`;

const classes = {
  text_default: `text-gray-800 hover:text-gray-900 hover:bg-gray-200 border-transparent ${focus(
    'blue'
  )}`,
  text_primary: `text-blue-700 hover:text-blue-800 hover:bg-blue-100 border-transparent ${focus(
    'blue'
  )}`,
  text_secondary: `text-red-700 hover:text-red-800 hover:bg-red-100 border-transparent ${focus(
    'red'
  )}`,
  outlined_default: `border-opacity-50 border-gray-600 text-gray-800 hover:text-gray-900 hover:border-gray-600 hover:bg-gray-200 ${focus(
    'blue'
  )}`,
  outlined_primary: `border-opacity-50 border-blue-600 text-blue-600 hover:text-blue-700 hover:border-blue-600 hover:bg-blue-100 ${focus(
    'blue'
  )}`,
  outlined_secondary: `border-opacity-50 border-red-500 text-red-500 hover:text-red-600 hover:border-red-600 hover:bg-red-100 ${focus(
    'red'
  )}`,
  contained_default: `border-gray-300 hover:border-gray-400 bg-gray-300 text-gray-800 hover:bg-gray-400 hover:text-gray-900 shadow hover:shadow-md ${focus(
    'blue'
  )}`,
  contained_primary: `border-blue-400 hover:border-blue-600 bg-blue-500 text-white hover:bg-blue-600 hover:text-white shadow hover:shadow-md ${focus(
    'blue',
    70,
    300
  )}`,
  contained_secondary: `border-red-500 hover:border-red-600 bg-red-500 text-white hover:bg-red-600 hover:text-white shadow hover:shadow-md ${focus(
    'red'
  )}`,
  contained_yellow: `border-yellow-500 hover:border-yellow-600 bg-yellow-500 text-white hover:bg-yellow-600 hover:text-white shadow hover:shadow-md ${focus(
    'yellow'
  )}`,
  flat_default: `border-gray-300 hover:border-gray-400 bg-gray-300 text-gray-800 hover:bg-gray-400 hover:text-gray-900 ${focus(
    'blue'
  )}`,
  flat_primary: `border-blue-400 hover:border-blue-600 bg-blue-500 text-white hover:bg-blue-600 hover:text-white ${focus(
    'blue',
    70,
    300
  )}`,
  flat_secondary: `border-red-500 hover:border-red-600 bg-red-500 text-white hover:bg-red-600 hover:text-white ${focus(
    'red'
  )}`,
  text_disabled: `border-transparent text-gray-500 ${focus('gray')}`,
  outlined_disabled: `border-gray-400 text-gray-500 ${focus('gray')}`,
  contained_disabled: `border-transparent bg-gray-300 text-gray-500 ${focus(
    'gray'
  )}`,
  flat_disabled: `border-transparent bg-gray-300 text-gray-500 ${focus(
    'gray'
  )}`,
};

const loadingClasses = {
  default: 'text-gray-600 border-opacity-50 border-gray-500 bg-gray-50',
  primary: 'text-blue-600 border-opacity-50 border-blue-600 bg-blue-50',
};

const Button: React.FC<ButtonProps> = ({
  disabled,
  className,
  variant,
  color,
  icon,
  children,
  isLoading,
  ...rest
}) => {
  if (isLoading) {
    return (
      <BaseButton
        disabled={true}
        className={`${loadingClasses[color]}${cls(className)}`}
        icon={icon}
        {...rest}
      >
        <span className="flex mr-2 -ml-4 ">
          <CircularProgress
            size={24}
            className="text-blue-400 shrink-0"
            color="inherit"
          />
        </span>
        {children && (
          <span className="inline-flex items-center justify-center italic font-normal animate-pulse text-inherit">
            Loading
          </span>
        )}
      </BaseButton>
    );
  }
  return (
    <BaseButton
      disabled={disabled || isLoading}
      className={`${
        classes[`${variant}_${disabled ? 'disabled' : color}`]
      }${cls(className)}`}
      icon={icon}
      {...rest}
    >
      {icon && <span className={`text-inherit`}>{icon}</span>}
      {children && (
        <span className="inline-flex items-center justify-center leading-none text-inherit">
          {children}
        </span>
      )}
    </BaseButton>
  );
};

Button.defaultProps = {
  color: 'default',
  variant: 'text',
  isLoading: false,
};

export default Button;
