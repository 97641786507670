import { Event } from 'api/Serializers/Appointments';
import Avatar from 'components/avatar';
import Link from 'components/link';
import { DATE_FMT } from 'config';
import { AccountIcon, ScheduleIcon } from 'icons';
import moment from 'moment-timezone';
import React from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const AppointmentCard = ({ appointment }: { appointment: Event }) => {
  const localDt = moment(appointment.start).tz(appointment.timezone);
  return (
    <div>
      <div className="flex space-x-3">
        <div className="w-32 h-24">
          <div className="absolute z-0 w-32 h-24 overflow-hidden bg-contain rounded-lg">
            <div className="absolute z-10 w-full h-24">
              <img
                className="min-h-full"
                src={appointment.facility.media[0].smUrl}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 space-y-1 text-sm">
          <Link to={SHARED_ROUTES.SCHEDULE.appointment(appointment.id)}>
            <div className="font-semibold leading-tight text-gray-900 hover:underline">
              {appointment.facility.shortName}{' '}
              {appointment.activity.appointmentNoun}
            </div>
          </Link>
          <div className="labeled-icon">
            <Avatar src={appointment.instructor.avatar} diameter={6} />
            <span>{appointment.instructor.fullName}</span>
          </div>
          <div className="labeled-icon">
            <AccountIcon width={24} />
            <span>{appointment.client.displayName}</span>
          </div>
          <div className="labeled-icon">
            <ScheduleIcon width={24} />
            <span>
              {localDt.format(DATE_FMT.MONTH_D_YEAR)} at{' '}
              {localDt.format(DATE_FMT.TIME_A)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
