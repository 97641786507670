import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  AppointmentListSerializer,
  Facility,
} from 'api/Serializers/Appointments';
import { Tense } from 'api/Serializers/Schedules';
import Avatar from 'components/avatar';
import Link from 'components/link';
import {
  CAL_LIST_ITEM_NAME_PREFIX,
  DATE_FMT,
  EventItemMode,
  QueryParams,
} from 'config';
import useQuery from 'hooks/useQuery';
import { ArrowForwardIcon, DownIcon } from 'icons';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Element } from 'react-scroll';
import { canModifyAvailability } from 'utils/business-logic';
import { getDatetimeTense } from 'utils/date';

interface Config {
  init: boolean;
  dateTense: Tense;
  modDate: Moment;
  canModify: boolean;
  mode: EventItemMode;
}

interface ListItemProps {
  date: Moment;
  event: AppointmentListSerializer;
}

interface Props {
  sorting: 'byDate' | 'byFacility';
  dateKey: string;
  events: AppointmentListSerializer[];
}

const getEventTitle = (event: AppointmentListSerializer) =>
  `${event.activity.appointmentNoun} with ${event.instructor.displayName} and ${
    event.client.displayName
  }, ${event.numParticipants} ${event.activity.clientDescription.pluralize(
    event.numParticipants
  )}`;

const Appointment = ({ date, event }: ListItemProps) => {
  const match = useRouteMatch();
  const queryParams = useQuery();
  const tense: Tense = getDatetimeTense(event.start);
  queryParams.set(QueryParams.AppointmentId, event.id);
  const url = `${match.url}?${queryParams.toString()}`;
  return (
    <Link
      className={`block border-b border-gray-200 sm:px-3 last:border-b-0 ${
        tense === Tense.Past
          ? 'bg-gray-50 text-gray-500 text-opacity-80'
          : tense === Tense.Now
          ? 'font-medium bg-blue-100 text-blue-700 hover:text-blue-600'
          : 'text-gray-700 hover:bg-gray-200'
      }`}
      to={url}
    >
      <div className="flex justify-between">
        <div className="flex flex-col items-center justify-between py-3 mx-2 rounded-lg sm:flex-row sm:mx-0 sm:my-0">
          <div className="relative flex items-center space-x-2 text-normal">
            <span
              className={`w-12 mr-1 text-xs text-center ${
                tense === Tense.Past
                  ? 'line-through'
                  : tense === Tense.Now
                  ? 'bg-blue-400 text-white py-0.5 rounded-full font-semibold shadow'
                  : ''
              }`}
            >
              {moment(event.start).tz(event.timezone).format(DATE_FMT.TIME)}
            </span>
            <span
              className={`text-base ${
                tense === Tense.Past ? 'italic ' : 'text-inherit'
              }`}
            >
              {getEventTitle(event)}
            </span>
          </div>
        </div>
        <ArrowForwardIcon width={24} />
      </div>
    </Link>
  );
};

const Cancellation = ({ date, event }: ListItemProps) => {
  const match = useRouteMatch();
  const queryParams = useQuery();
  const tense: Tense = getDatetimeTense(event.start);
  queryParams.set(QueryParams.AppointmentId, event.id);
  const url = `${match.url}?${queryParams.toString()}`;
  return (
    <Link
      className={`block border-b border-gray-200 sm:px-3 last:border-b-0 text-gray-700`}
      to={url}
    >
      <div className="w-full cursor-pointer sm:w-auto">
        <div className="py-3 mx-2 rounded-lg sm:mx-0 sm:my-0">
          <div className="flex items-center flex-1 space-x-2 text-normal">
            <span className={`w-12 mr-1 text-xs text-center line-through`}>
              {moment(event.start).tz(event.timezone).format(DATE_FMT.TIME)}
            </span>
            <div className="flex-1 space-y-2">
              <div className="text-base">{getEventTitle(event)}</div>
              <div className="text-sm font-normal text-gray-600 ">
                {event.cancellation.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const toUniqueFacilities = (agg: Facility[], val: AppointmentListSerializer) =>
  agg.findIndex((f) => f.id === val.facility.id) === -1
    ? agg.concat(val.facility)
    : agg;

type ViewMode = 'booked' | 'cancelled';
const AdminCalendarListItem = ({ events, dateKey, sorting }: Props) => {
  const date = moment(dateKey);
  const [booked, setBooked] = useState<AppointmentListSerializer[]>([]);
  const [cancelled, setCancelled] = useState<AppointmentListSerializer[]>([]);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [config, setConfig] = useState<Config>({
    canModify: canModifyAvailability(date),
    dateTense: moment().isSame(date, 'day')
      ? Tense.Today
      : moment().isAfter(date, 'day')
      ? Tense.Past
      : Tense.Future,
    init: true,
    modDate: date,
    mode: canModifyAvailability(date)
      ? EventItemMode.MODIFY
      : EventItemMode.CREATE,
  });

  useEffect(() => {
    if (sorting === 'byFacility') {
      setFacilities(
        events
          .reduce(toUniqueFacilities, [])
          .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
      );
    }
    setBooked(events.filter((event) => event.cancelled === false));
    setCancelled(events.filter((event) => event.cancelled === true));
  }, [events, sorting]);
  return (
    <Element
      className="px-0 py-px mx-auto"
      name={`${CAL_LIST_ITEM_NAME_PREFIX}-${dateKey}`}
    >
      <div
        className={`bg-white shadow px-0 pb-6 rounded-xl max-w-xl mx-auto${
          config.dateTense === Tense.Today ? ' border border-blue-400' : ''
        }`}
      >
        <div className="">
          <div className={`flex items-center justify-center flex-1 py-3`}>
            <h2 className="block text-lg font-medium text-center text-gray-700 sm:text-xl">
              {config.modDate.format(DATE_FMT.DOW_MONTH_D)}
              {' — '}
              {`${events.length} ${'Appointment'.pluralize(events.length)}`}
            </h2>
          </div>
        </div>
        {facilities.map((facility) => {
          const eventsHere = booked.filter(
            (event) => event.facility.id === facility.id
          );
          const cancelledHere = cancelled.filter(
            (event) => event.facility.id === facility.id
          );
          return (
            <Accordion key={facility.slug} className="" elevation={0}>
              <AccordionSummary expandIcon={<DownIcon width={24} />}>
                <div className="flex items-center">
                  <Avatar className="mr-3" src={facility.avatar} />
                  <div>
                    <h4 className="flex-1 my-0 font-medium text-gray-800 text-md">
                      {facility.displayName}
                    </h4>
                    <h5 className="text-sm italic text-gray-600">
                      {`${eventsHere.length} ${'Appointment'.pluralize(
                        eventsHere.length
                      )}`}
                      ,{' '}
                      {`${cancelledHere.length} ${'Cancellation'.pluralize(
                        cancelledHere.length
                      )}`}
                    </h5>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="flex-col !p-0">
                <div>
                  {eventsHere.map((event, i) => (
                    <Appointment
                      key={`apt-${event.id}`}
                      date={config.modDate}
                      event={event}
                    />
                  ))}
                  <div className="p-3">
                    <h5 className="text-sm italic text-gray-600">
                      Cancellations
                    </h5>
                  </div>
                  {cancelledHere.map((event, i) => (
                    <Cancellation
                      key={`apt-${event.id}`}
                      date={config.modDate}
                      event={event}
                    />
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Element>
  );
};

export default AdminCalendarListItem;
