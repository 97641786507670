import React from 'react';

interface ImageProps {
  src?: string;
  diameter?:
    | 5
    | 6
    | 8
    | 10
    | 12
    | 16
    | 20
    | 24
    | 28
    | 32
    | 40
    | 48
    | 56
    | 64
    | 'full';
  border?: boolean;
  shadow?: boolean;
  className?: any;
  imgClassName?: string;
  onClick?(): void;
  variant?: 'circle' | 'rounded' | 'square';
  children?: any;
}

const shadow = 'shadow-md';
const border = 'border-2 border-gray-300';
const getDimensions = (diameter) =>
  diameter === 5
    ? 'w-5 h-5 text-xxs font-light' // 20px
    : diameter === 6
    ? 'w-6 h-6 text-xs font-medium' // 24px
    : diameter === 8
    ? 'w-8 h-8 text-md font-medium' // 32px
    : diameter === 10
    ? 'w-10 h-10 text-lg font-semibold' // 40px
    : diameter === 12
    ? 'w-12 h-12 text-xl font-semibold' // 48px
    : diameter === 16
    ? 'w-16 h-16 text-xl font-semibold' // 64px
    : diameter === 20
    ? 'w-20 h-20 text-xl font-semibold' // 80px
    : diameter === 24
    ? 'w-24 h-24 text-xl font-semibold' // 96px
    : diameter === 28
    ? 'w-28 h-28 text-xl font-semibold' // 112px
    : diameter === 32
    ? 'w-32 h-32 text-xl font-semibold' // 128px
    : diameter === 40
    ? 'w-40 h-40 text-xl font-semibold' // 160px
    : diameter === 48
    ? 'w-48 h-48 text-xl font-semibold' // 192px
    : diameter === 56
    ? 'w-56 h-56 text-xl font-semibold' // 224px
    : diameter === 64
    ? 'w-64 h-64 text-xl font-semibold' // 256px
    : diameter === 'full'
    ? 'h-full text-xl font-semibold'
    : 'w-10 h-10 text-lg font-semibold';

const Avatar: React.FunctionComponent<ImageProps> = (props) => (
  <div
    onClick={props.onClick ? props.onClick : undefined}
    className={
      `${getDimensions(props.diameter)} flex flex-grow-0 flex-shrink-0 h-` +
      ` relative overflow-hidden items-center justify-center` +
      ` bg-gray-500 text-white` +
      ` ${
        props.variant === 'circle'
          ? ' rounded-full'
          : props.variant === 'rounded'
          ? ' rounded-xl'
          : 'font-'
      }` +
      `${cls(props.border, border)}` +
      `${cls(props.shadow, shadow)}` +
      `${cls(props.className, props.className)}`
    }
  >
    {props.src ? (
      <img
        className={`object-cover w-full h-full text-center${cls(
          props.imgClassName
        )}`}
        src={props.src}
      />
    ) : (
      props.children
    )}
  </div>
);

Avatar.defaultProps = {
  border: false,
  shadow: false,
  className: undefined,
  imgClassName: '',
  diameter: 10,
  onClick: undefined,
  variant: 'circle',
};

export default Avatar;
