import ColdIcon from '@mui/icons-material/AcUnit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Loading from 'components/loading';
import { fahrenheitToCelsius } from 'features/assistance/as-instructor/utils';
import { SuccessIcon } from 'icons';
import { AssistanceWizardProps, ReportResolution } from 'models/Assistance';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';

export const TemperatureCallout = ({
  tempC,
  minTempCelsius,
}: {
  tempC: number;
  minTempCelsius: number;
}) =>
  tempC < minTempCelsius ? (
    <div className="space-y-5">
      <Callout
        type="info"
        icon={<ColdIcon color="inherit" />}
        title="Cold water"
      />
      <p>
        Since the pool is more than 1°C outside its posted temperature range,
        the lesson may be cancelled at your discretion.
      </p>
    </div>
  ) : (
    <div className="space-y-5">
      <Callout
        type="info"
        icon={<SuccessIcon width={24} />}
        title="Acceptable temperature"
      />
      <p>
        This is within the pool's posted temperature range.{' '}
        <strong>This lesson will not be cancelled</strong>, though a cold water
        report may still be submitted.
      </p>
    </div>
  );

const FacilityCancelDecisionView = (props: AssistanceWizardProps) => {
  const history = useHistory();
  const { appointment, trackAction, getReportData } = props;
  const temperature = getReportData('temperature') as number;
  const isCelsius = getReportData('isCelsius') as boolean;
  const tempC =
    temperature !== undefined
      ? isCelsius
        ? temperature
        : fahrenheitToCelsius(temperature)
      : temperature;
  const minTempCelsius =
    parseInt(appointment.facility.temperature.split('-')[0]) - 1;

  const handleContinueAppointment = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'PROPEL_REVIEW' as ReportResolution,
      title: 'Instructor decision',
      description: 'The lesson will continue',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'NO_CHANGE',
    });
  };

  const handleCancelAppointment = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_TOO_COLD' as ReportResolution,
      title: 'Instructor decision',
      description: 'The water is too cold to continue',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'FACILITY_NOT_AVAILABLE',
    });
  };

  if (tempC === undefined) {
    history.push(
      INSTRUCTOR_ROUTES.ASSISTANCE.nav(appointment.id, 'facility/cold')
    );
    return <Loading />;
  }

  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>The pool is cold</h4>
      <div className="my-8 space-y-3">
        <TemperatureCallout tempC={tempC} minTempCelsius={minTempCelsius} />
        <ButtonLarge
          title="Continue the lesson"
          subtitle="A cold water report will be submitted, but the lesson will not be cancelled"
          icon={<CheckIcon className="text-gray-600" color="action" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(props.appointment.id, 'summary')}
          onClick={handleContinueAppointment}
        />
        {tempC < minTempCelsius && (
          <ButtonLarge
            title="Cancel the lesson"
            subtitle="Swimmers are too cold to continue"
            icon={<CancelIcon className="text-gray-600" color="inherit" />}
            to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
            onClick={handleCancelAppointment}
          />
        )}
      </div>
    </>
  );
};

export default FacilityCancelDecisionView;
