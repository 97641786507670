import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  AppointmentListSerializer,
  Facility,
} from 'api/Serializers/Appointments';
import { Tense } from 'api/Serializers/Schedules';
import Avatar from 'components/avatar';
import Link from 'components/link';
import Title from 'components/title';
import {
  CAL_LIST_ITEM_NAME_PREFIX,
  DATE_FMT,
  EventItemMode,
  QueryParams,
} from 'config';
import useQuery from 'hooks/useQuery';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Element } from 'react-scroll';
import { canModifyAvailability } from 'utils/business-logic';
import { getDatetimeTense } from 'utils/date';

interface Config {
  init: boolean;
  dateTense: Tense;
  modDate: Moment;
  canModify: boolean;
  mode: EventItemMode;
}

interface ListItemProps {
  date: Moment;
  event: AppointmentListSerializer;
}

interface Props {
  sorting: 'byDate' | 'byFacility';
  dateKey: string;
  events: AppointmentListSerializer[];
}

const getEventTitle = (event: AppointmentListSerializer) =>
  `${event.activity.appointmentNoun} with ${event.instructor.displayName} and ${
    event.client.displayName
  }, ${event.numParticipants} ${event.activity.clientDescription.pluralize(
    event.numParticipants
  )}`;

const Appointment = ({ date, event }: ListItemProps) => {
  const match = useRouteMatch();
  const queryParams = useQuery();
  const tense: Tense = getDatetimeTense(event.start);
  queryParams.set(QueryParams.AppointmentId, event.id);
  const url = `${match.url}?${queryParams.toString()}`;
  return (
    <div
      className={`block border-b border-gray-200 sm:px-3 sm:-mx-3 last:border-b-0 ${
        tense === Tense.Past
          ? 'bg-gray-50 text-gray-500 text-opacity-80'
          : tense === Tense.Now
          ? 'font-medium bg-blue-100 text-blue-700 hover:text-blue-600'
          : 'text-gray-700'
      }`}
    >
      <Link className="w-full cursor-pointer sm:w-auto" to={url}>
        <div className="flex flex-col items-center justify-between py-3 mx-2 rounded-lg sm:flex-row sm:mx-0 sm:my-0">
          <div className="relative flex items-center space-x-2 text-normal">
            <span
              className={`w-12 mr-1 text-xs text-center ${
                tense === Tense.Past
                  ? 'line-through'
                  : tense === Tense.Now
                  ? 'bg-blue-400 text-white py-0.5 rounded-full font-semibold shadow'
                  : ''
              }`}
            >
              {moment(event.start).tz(event.timezone).format(DATE_FMT.TIME)}
            </span>
            <span
              className={`text-base ${
                tense === Tense.Past ? 'italic ' : 'text-inherit'
              }`}
            >
              {getEventTitle(event)}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

const toUniqueFacilities = (agg: Facility[], val: AppointmentListSerializer) =>
  agg.findIndex((f) => f.id === val.facility.id) === -1
    ? agg.concat(val.facility)
    : agg;

const HostCalendarListItems = ({ events, dateKey, sorting }: Props) => {
  const date = moment(dateKey);
  const [booked, setBooked] = useState<AppointmentListSerializer[]>([]);
  const [cancelled, setCancelled] = useState<AppointmentListSerializer[]>([]);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [config, setConfig] = useState<Config>({
    canModify: canModifyAvailability(date),
    dateTense: moment().isSame(date, 'day')
      ? Tense.Today
      : moment().isAfter(date, 'day')
      ? Tense.Past
      : Tense.Future,
    init: true,
    modDate: date,
    mode: canModifyAvailability(date)
      ? EventItemMode.MODIFY
      : EventItemMode.CREATE,
  });

  useEffect(() => {
    if (sorting === 'byFacility') {
      setFacilities(
        events
          .filter((event) => event.cancelled === false)
          .reduce(toUniqueFacilities, [])
          .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
      );
    }
    setBooked(events.filter((event) => event.cancelled === false));
    setCancelled(events.filter((event) => event.cancelled === true));
  }, [events, sorting]);

  const mtrem = `-mt-16`;
  const hrem = `h-16`;
  const toprem = `top-16`;
  return (
    <Element
      className="px-0 py-px mx-auto"
      name={`${CAL_LIST_ITEM_NAME_PREFIX}-${dateKey}`}
    >
      <div
        className={`bg-white shadow-lg px-0 sm:px-3 pb-6 pt-2 rounded-lg max-w-xl mx-auto${
          config.dateTense === Tense.Past
            ? ' opacity-75'
            : config.dateTense === Tense.Today
            ? ' border border-blue-400'
            : ''
        }`}
      >
        <div
          className={`sticky z-20 flex flex-1 justify-center sm:justify-start items-center w-9 ${hrem} ${toprem}`}
        >
          <div className="flex flex-col">
            <span className="font-medium leading-none text-blue-700 text-opacity-75 uppercase text-xxs">
              {config.modDate.format(DATE_FMT.DOW_SHORT)}
            </span>
            <span className="text-lg font-bold leading-none text-blue-700 sm:text-xl">
              {config.modDate.format(DATE_FMT.D)}
            </span>
          </div>
        </div>
        <div className="">
          <div
            className={`flex items-center justify-center flex-1 ${hrem} ${mtrem}`}
          >
            <span className="block text-lg text-center text-gray-700 sm:text-xl">{`${
              events.length
            } ${'Appointment'.pluralize(events.length)}`}</span>
          </div>
        </div>
        {facilities.map((facility) => {
          const eventsHere = booked.filter(
            (event) => event.facility.id === facility.id
          );
          return (
            <div key={facility.slug} className="">
              <div
                className={`sticky z-10 flex items-center ${hrem} pl-10 sm:pl-14 sm:pr-6 sm:-mx-3 py-2 border-gray-300 border-t border-b bg-gray-100 ${toprem}`}
              >
                <Avatar className="mr-3" src={facility.avatar} />
                <h4 className="flex-1 my-0 font-medium text-blue-800 text-md">
                  {facility.displayName} ({eventsHere.length})
                </h4>
              </div>
              {eventsHere.map((event, i) => (
                <Appointment
                  key={`apt-${event.id}`}
                  date={config.modDate}
                  event={event}
                />
              ))}
            </div>
          );
        })}
        {cancelled.length > 0 && (
          <Accordion elevation={0}>
            <AccordionSummary className="mt-10" expandIcon={<ExpandMore />}>
              <Title
                as="h5"
                align="left"
                height="none"
                className=""
                variant="header"
                shade="500"
              >
                {`${cancelled.length} ${'appointment'.pluralize(
                  cancelled.length
                )} cancelled`}
              </Title>
            </AccordionSummary>
            <AccordionDetails className="flex-col">
              {cancelled.map((event, index) => (
                <Appointment key={index} date={config.modDate} event={event} />
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </Element>
  );
};

export default HostCalendarListItems;
