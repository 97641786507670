import Page from 'containers/page';
import TermsOfServiceContainer from 'containers/terms-of-service';
import { TermsOfServiceMeta as Meta } from 'metadata';
import React from 'react';

const TermsOfServicePage = () => {
  return (
    <Page {...Meta}>
      <div className="p-4 sm:p-6">
        <TermsOfServiceContainer />
      </div>
    </Page>
  );
};

export default TermsOfServicePage;
