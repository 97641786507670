import { TimeFrame } from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { UserType } from 'config';
import TextArea from 'features/assistance/components/textArea';
import { AssistanceWizardProps } from 'models/Assistance';
import React, { useEffect } from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const dataKey = 'noteToInstructor';
const NoteView = (props: AssistanceWizardProps) => {
  const { appointment, state, trackAction, getReportData } = props;
  const { id, instructor, timeFrame } = appointment;
  const inPast =
    timeFrame === TimeFrame.HasEnded || timeFrame === TimeFrame.Complete;

  useEffect(() => {
    trackAction({
      type: 'SET_CATEGORY',
      key: 'category',
      value: 'CANCELLATION',
    });
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'INSTRUCTOR_NOT_HERE',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'INSTRUCTOR_CANCELLATION',
      title: 'What happened?',
      description: 'My instructor was absent',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'INSTRUCTOR_NO_SHOW',
    });
  }, []);

  return (
    <>
      <h2>Instructor Assistance</h2>
      <h4>
        {inPast ? 'My instructor was absent' : 'My instructor will be absent'}
      </h4>
      <Callout className="my-4" type="warning">
        Reports of an instructor absence are taken very seriously.
      </Callout>
      <p>
        Please provide any relevant details about what happened below. A member
        of our team will be assigned to investigate and follow up with you.
      </p>
      <p>
        We understand that you may be feeling frustrated by this experience.
        Rest assured that we will confirm that your instructor is okay, return
        any payments back to you, and where appropriate, provide you with
        additional compensation for the inconvenience caused.
      </p>
      <Callout className="my-4">
        Your note will be sent to your instructor.
      </Callout>
      <div className="my-8 space-y-6">
        <TextArea
          id={dataKey}
          title="Please let us know the details of what happened:"
          label="Please let us know the details of what happened:"
          placeholder="Describe the situation..."
          recipientUserType={UserType.Instructor}
          required={false}
        />
      </div>
      <Controls variant="block">
        <BackButton />
        <Button
          variant="contained"
          color="primary"
          to={SHARED_ROUTES.ASSISTANCE.nav(id, 'summary')}
          // onClick={handleContinue}
        >
          Continue
        </Button>
      </Controls>
    </>
  );
};

export default NoteView;
