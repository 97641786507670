import CircularProgress from '@mui/material/CircularProgress';
import api from 'api';
import InstructorAccount, {
  AccountUpdateSerializer,
  InstructorOnboardingStage,
} from 'api/Serializers/Accounts/Instructor';
import { HTTP_200_OK } from 'api/status';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import HelpButton from 'components/button-help';
import Card from 'components/card';
import Controls from 'components/controls';
import { FleeqModule } from 'components/fleeq';
import Input from 'components/input';
import Label from 'components/input-label';
import LearningModule from 'components/instructor/learning-module';
import ProfileCard from 'components/instructor/profile-card';
import Modal from 'components/modal';
import Uploader from 'components/uploader/slider';
import { APP_ROOT_URL, FETCH_STATE } from 'config';
import useAccountPatch from 'hooks/useAccountPatch';
import { useAppDispatch } from 'hooks/useAppDispatch';
import debounce from 'lodash.debounce';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  getAccountFetchState,
  getCurrentUser,
  getInstructorOnboarding,
  getScheduleAppointmentProducts,
} from 'state/selectors';
import ClientExperience from '../onboarding/r09-client-experience';
import UniqueExperience from '../onboarding/r10-unique-experience';
const usernameRegex = /^[a-zA-Z0-9_]*$/;

interface Props {
  account: InstructorAccount;
  save(data: Partial<AccountUpdateSerializer>): void;
}

const checkUsernameDebounced = debounce(
  async (username: string, setIsValid, setError, setIsFetching) => {
    const response = await api.users.usernameAvailable(username);
    if (response.status === HTTP_200_OK) {
      setIsValid(response.data.valid);
      if (!response.data.valid) {
        setError(response.data.errors.username.message);
      }
    } else {
      setIsValid(false);
      setError('Unable to verify this username. Please try again.');
    }
    setIsFetching(false);
  },
  450
);

const ChangeUsernameWarnings = ({ username, newSlug, onSaveClick }) => {
  return (
    <div>
      <p>This will be your new Propel shortlink:</p>
      <Shortlink username={newSlug === '' ? username : newSlug} />
      <p>
        Be sure to update your social media or listing site links.
        <br />
        <span className="text-gray-600">
          <em>eg: Facebook, Twitter, Craigslist, Kijiji</em>
        </span>
      </p>
      <Controls>
        <Button>Cancel</Button>
        <Button variant="contained" color="primary" onClick={onSaveClick}>
          Create New Shortlink
        </Button>
      </Controls>
    </div>
  );
};

const Shortlink = ({ username }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const onCopyClick = () => {
    copyToClipboard(`${APP_ROOT_URL}/${username}`);
    enqueueSnackbar({
      message: 'Shortlink copied to clipboard!',
      variant: 'success',
    });
  };
  return (
    <div className="flex items-center justify-between p-3 bg-gray-200 border border-gray-200 rounded">
      <div>
        <span className="text-gray-700">{APP_ROOT_URL}/</span>
        <span>{username}</span>
      </div>
      <div className="absolute right-0 mr-2 center-y">
        <button
          className="px-2 py-1 text-sm bg-white border border-gray-400 border-solid rounded"
          onClick={onCopyClick}
        >
          Copy
        </button>
      </div>
    </div>
  );
};

const CustomUsername: React.FC<Props> = ({ account, save }) => {
  const { enqueueSnackbar } = useSnackbar();
  const minLength = 5;
  const maxLength = 25;
  const orig = account.slug;
  const [isFetching, setIsFetching] = useState(false);
  const [username, setUsername] = useState(orig);
  const [isFocus, setIsFocus] = useState(false);
  const [error, setError] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>();
  const [showWarning, setShowWarning] = useState(false);
  const canSave = !error && isValid && username !== orig;
  const onUsernameChange = async (event) => {
    const value = event.currentTarget.value;
    setUsername(value);
    setIsValid(undefined);
    if (value === '') {
      // Special case - reset username to original value
      setError(undefined);
      setIsValid(true);
    } else if (value === orig) {
      setError(undefined);
    } else if (value.length < minLength) {
      setError(`Your username must be at least ${minLength} characters`);
    } else if (value.length > maxLength) {
      setError(`Your username cannot be longer than ${maxLength} characters`);
    } else if (!usernameRegex.test(value)) {
      setError(
        'Custom usernames can only contain letters, numbers, or underscores'
      );
    } else {
      setError(undefined);
      setIsFetching(true);
      checkUsernameDebounced(value, setIsValid, setError, setIsFetching);
    }
  };

  const reset = (event) => {
    setError(undefined);
    setIsFocus(false);
    setUsername(orig);
    return event && event.target && event.target.focus();
  };

  const onSaveClick = () => {
    save({ slug: username === '' ? account.username : username });
    setError(undefined);
    setIsFocus(false);
    setShowWarning(false);
    setUsername(username === '' ? account.username : username);
    enqueueSnackbar({ message: 'New shortlink created', variant: 'success' });
  };

  return (
    <div className="">
      <div className="flex my-2">
        <div className="relative flex-1 mr-2">
          <Input
            title="Custom Username"
            className={`my-4`}
            errors={!!error ? [error] : undefined}
            name={'username'}
            value={username}
            placeholder={orig}
            required={false}
            onChange={onUsernameChange}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            maxLength={maxLength}
          />
          {isFetching && (
            <div className="absolute bottom-0 right-0 px-2 py-1">
              <CircularProgress size={28} />
            </div>
          )}
        </div>
        <div className="flex-1 mt-4 ml-2">
          <Label>My Propel shortlink:</Label>
          <div>
            <Shortlink
              username={username === '' ? account.username : username}
            />
          </div>
        </div>
      </div>
      {(isFocus || canSave) && (
        <div>
          <Controls>
            <Button color="default" variant="outlined" onClick={reset}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!canSave}
              onClick={() => setShowWarning(true)}
            >
              {username === '' ? 'Reset' : 'Save'}
            </Button>
          </Controls>
        </div>
      )}
      <Modal
        name="Instructor — Change username warning"
        title="Heads up!"
        open={showWarning}
        onClose={() => setShowWarning(false)}
        maxWidth="sm"
      >
        <ChangeUsernameWarnings
          username={account.username}
          newSlug={username}
          onSaveClick={onSaveClick}
        />
      </Modal>
    </div>
  );
};

const Profile = () => {
  const account = useSelector(getCurrentUser) as InstructorAccount;
  const onboarding = useSelector(getInstructorOnboarding);
  const fetchState = useSelector(getAccountFetchState);
  const appointmentProducts = useSelector(getScheduleAppointmentProducts);
  const { handleAccountUpdate, handleOnboardingUpdate } = useAccountPatch();
  const [confirmDiscard, setConfirmDiscard] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showHelpModule, setShowModule] = useState(
    onboarding &&
      onboarding.stage === InstructorOnboardingStage.GetBooked &&
      !onboarding.hasWatchedGetBooked
  );
  const [description, setDescription] = useState(account.description);
  const handleCloseDiscardModal = () => setConfirmDiscard(false);
  const handleConfirmDiscard = () => {
    setConfirmDiscard(true);
  };
  const handleDiscard = () => {
    setDescription(account.description);
    handleCloseDiscardModal();
  };
  const handleSave = async () => {
    await handleAccountUpdate({ description });
    enqueueSnackbar('Saved description changes', { variant: 'success' });
  };
  return (
    <Dashboard title="Profile">
      <Prompt
        message={`Leave description changes?
You will lose any unsaved changes to your description.`}
        when={description !== account.description}
      />
      <div className="my-4 sm:my-6">
        <Card maxWidth="full">
          <CustomUsername account={account} save={handleAccountUpdate} />
          <Uploader
            name="media"
            media={[...account.media].map((m) => ({ ...m, protected: false }))}
            buttonTitle="Add Teaching Photos"
            onFinish={(url) => {
              handleAccountUpdate({ media: [{ url }] });
            }}
          />
          <hr className="my-4" />
          <ProfileCard
            specialization1={account.specialization1}
            specialization2={account.specialization2}
            segments={account.segments}
            avatar={account.avatar}
            displayName={account.displayName}
            numRecommended={0}
            basePrice={0}
          />
          <h4 className="mt-12 mb-1 font-semibold text-gray-700">
            Craft an engaging &quot;About Me&quot;
          </h4>
          <p className="mt-1 mb-3 text-gray-700">
            Tell potential clients about your teaching experience, unique style,
            and what makes you a great instructor — a short paragraph for each
            works best!
          </p>
          <textarea
            name={'description'}
            value={description}
            cols={40}
            rows={10}
            onChange={(event) => setDescription(event.currentTarget.value)}
            placeholder={
              'Describe your experience, teaching style, and what makes you a great instructor!'
            }
          />
          <div className="py-3 space-x-3 text-right">
            <Button
              onClick={handleConfirmDiscard}
              variant="flat"
              disabled={
                description === account.description ||
                fetchState !== FETCH_STATE.IDLE
              }
            >
              Discard changes
            </Button>
            <Button
              onClick={handleSave}
              variant="flat"
              color="primary"
              disabled={description === account.description}
              isLoading={fetchState === FETCH_STATE.PUT}
            >
              Save changes
            </Button>
          </div>
          <ClientExperience
            account={account}
            onboarding={onboarding}
            appointmentProducts={appointmentProducts}
            modifyAccount={handleAccountUpdate}
            modifyOnboarding={handleOnboardingUpdate}
            hideControls={true}
          />
          <UniqueExperience
            account={account}
            onboarding={onboarding}
            appointmentProducts={appointmentProducts}
            modifyAccount={handleAccountUpdate}
            modifyOnboarding={handleOnboardingUpdate}
            hideControls={true}
          />
          <div className="my-6 text-center">
            <HelpButton onClick={() => setShowModule(true)}>
              How can I get more bookings?
            </HelpButton>
          </div>
        </Card>
      </div>
      <Modal
        name={'Instructor — Discard "About me" changes'}
        open={confirmDiscard}
        onClose={handleCloseDiscardModal}
        title="Discard changes?"
      >
        <div>
          Are you sure you want to discard changes made to your "About me"
          section?
        </div>
        <Controls>
          <Button onClick={handleCloseDiscardModal} variant="flat">
            Go back
          </Button>
          <Button onClick={handleDiscard} variant="flat" color="secondary">
            Yes, discard changes
          </Button>
        </Controls>
      </Modal>
      <LearningModule
        show={showHelpModule}
        onClose={() => setShowModule(false)}
        module={FleeqModule.BookingsAndNotifications}
        onboardingAttributeKey="hasWatchedGetBooked"
      />
    </Dashboard>
  );
};

export default Profile;
