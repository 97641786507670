import Loading from 'components/loading';
import ScheduleACall from 'components/schedule-a-call';
import TaskCard from 'containers/task-card';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { getInstructorOnboarding } from 'state/selectors';
import { TextClasses, TitleClasses } from '../onboarding';

const OrientationCall = () => {
  const onboarding = useSelector(getInstructorOnboarding);
  return (
    <TaskCard>
      {!onboarding ? (
        <Loading message="Getting data..." />
      ) : onboarding.callDatetime ? (
        <>
          <h1 className={TitleClasses}>Orientation Call</h1>
          <p className={TextClasses}>
            Can't make your original time? Need to finish your orientation call?
            Let us know what works for you!
          </p>
        </>
      ) : (
        <>
          <h1 className={TitleClasses}>One last thing! ☝️</h1>
          <p className={TextClasses}>
            Please schedule a quick orientation call. After the call your
            account will be approved and you can start teaching!
          </p>
        </>
      )}
      <ScheduleACall next={INSTRUCTOR_ROUTES.DASHBOARD.ROOT} />
    </TaskCard>
  );
};

export default OrientationCall;
