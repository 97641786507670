import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { ClientSerializer } from 'api/Serializers/Clients';
import {
  NewProposal,
  Proposal,
  ProposalListItem,
  ProposalListParams,
  ProposalStatus,
} from 'api/Serializers/Proposals';
import axios from 'axios';
import { DATE_FMT, FETCH_STATE, UserType } from 'config';
import {
  CancelProposalError,
  FetchProposalError,
  GenericServerError,
} from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import {
  getCurrentUser,
  getProposalCreateList,
  getProposalDetail,
  getProposalDetailFetchState,
  getProposals,
  getProposalUpdate,
  getScheduleRenderDate,
  getUserType,
} from 'state/selectors';
import { AppDispatch } from 'state/store';
import { getClientStartEndParams, getMonthStartEndParams } from 'utils/date';
import { getInitialStateFromStorage } from '../utils';

interface ProposalsReducer {
  list: ProposalListItem[];
  listFetchState: FETCH_STATE;
  listFetchedAt: string;
  detail: Proposal;
  create: NewProposal[];
  update: NewProposal;
  client: ClientSerializer;
  detailFetchState: FETCH_STATE;
  createFetchState: FETCH_STATE;
}

const initialState: ProposalsReducer = {
  list: [],
  listFetchState: FETCH_STATE.IDLE,
  listFetchedAt: undefined,
  detail: undefined,
  create: [],
  update: undefined,
  client: undefined,
  detailFetchState: FETCH_STATE.IDLE,
  createFetchState: FETCH_STATE.IDLE,
};

const name: 'proposals' = 'proposals';
const Slice = createSlice({
  name,
  initialState: getInitialStateFromStorage(name, initialState),
  reducers: {
    fetchingDetail(state) {
      state.detail = undefined;
      state.detailFetchState = FETCH_STATE.GET;
    },
    errorFetchingDetail(state) {
      state.detailFetchState = FETCH_STATE.FAILED;
    },
    setDetail(state, action: PayloadAction<Proposal>) {
      state.detail = action.payload;
      state.detailFetchState = FETCH_STATE.FULFILLED;
    },
    setListFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.listFetchState = action.payload;
    },
    setListFetchedAt(state, action: PayloadAction<string>) {
      state.listFetchedAt = action.payload;
    },
    setList(state, action: PayloadAction<ProposalListItem[]>) {
      state.list = action.payload;
      state.listFetchState = FETCH_STATE.IDLE;
    },
    createProposalStart(state) {
      state.createFetchState = FETCH_STATE.POST;
    },
    createProposalError(state) {
      state.createFetchState = FETCH_STATE.FAILED;
    },
    clearCreatedProposals(state) {
      state.create = [];
      state.update = undefined;
      state.detail = undefined;
      state.createFetchState = FETCH_STATE.IDLE;
    },
    clearProposal(state) {
      state.detail = undefined;
      state.client = undefined;
      state.createFetchState = FETCH_STATE.IDLE;
    },
    setClient(state, action: PayloadAction<ClientSerializer>) {
      state.client = action.payload;
      state.create = [];
      state.update = undefined;
      state.detail = undefined;
    },
    setUpdate(state, action: PayloadAction<NewProposal>) {
      state.update = action.payload;
      state.createFetchState = FETCH_STATE.IDLE;
    },
    flushProposal(state) {
      state.create = [...state.create, state.update].sort((a, b) =>
        a.datetime < b.datetime ? -1 : 1
      );
      state.update = {
        aptProdId: state.update.aptProdId,
        client: state.update.client,
        numParticipants: state.update.numParticipants,
      } as NewProposal;
    },
    removeNewProposal(state, action: PayloadAction<{ datetime: string }>) {
      state.create = state.create.filter(
        (p) => p.datetime !== action.payload.datetime
      );
    },
    updateListProposal(state, action: PayloadAction<ProposalListItem>) {
      state.list = state.list.filter((p) => p.id !== action.payload.id);
      state.list.push(action.payload);
      state.list.sort((a, b) => (a.start < b.start ? -1 : 1));
    },
    softArchiveProposal(state, action: PayloadAction<string>) {
      state.list = state.list.filter((p) => p.id !== action.payload);
    },
    softCancelProposal(state, action: PayloadAction<string>) {
      state.list = state.list.map((li) =>
        li.id === action.payload
          ? { ...li, status: ProposalStatus.ClientCancelled }
          : li
      );
    },
    softCompleteProposal(state, action: PayloadAction<string>) {
      state.list = state.list.map((li) =>
        li.id === action.payload
          ? { ...li, status: ProposalStatus.BookingSuccessful }
          : li
      );
    },
    clearProposalsFetchStates(state) {
      state.listFetchState = FETCH_STATE.IDLE;
      state.listFetchedAt = undefined;
    },
    removeListProposal(state, action: PayloadAction<ProposalListItem>) {
      state.list = state.list.filter((p) => p.id !== action.payload.id);
    },
  },
});

const {
  setUpdate,
  fetchingDetail,
  errorFetchingDetail,
  setDetail,
  setList,
  setClient,
  setListFetchState,
  setListFetchedAt,
  createProposalStart,
  createProposalError,
  softArchiveProposal,
  softCancelProposal,
} = Slice.actions;

export const {
  flushProposal,
  clearProposal,
  clearCreatedProposals,
  clearProposalsFetchStates,
  removeNewProposal,
  updateListProposal,
  removeListProposal,
  softCompleteProposal,
} = Slice.actions;

export const fetchProposal =
  (id: string) => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const fetchState = getProposalDetailFetchState(state);
    const detail = getProposalDetail(state);
    if (fetchState === FETCH_STATE.FULFILLED && detail?.id === id) {
      return detail;
    }
    dispatch(clearProposal());
    dispatch(fetchingDetail());
    try {
      const response = await api.proposals.retrieve(id);
      dispatch(setDetail(response.data));
      return response.data;
    } catch (error) {
      dispatch(errorFetchingDetail());
      enqueueSnackbar(FetchProposalError);
      return undefined;
    }
  };

let abortController: AbortController;
export const fetchProposals =
  (params: ProposalListParams = undefined, force = false) =>
  async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const userType = getUserType(state); // Logged in user type
    const user = getCurrentUser(state); // Selected user, if admin using, might be client or instructor
    const renderDate = getScheduleRenderDate(state);
    // const lastFetchedAt = getProposalsFetchedAt(state);
    // if (
    //   !force &&
    //   lastFetchedAt &&
    //   Math.abs(moment().diff(lastFetchedAt, 'minutes')) < 10
    // ) {
    //   logger.debug(
    //     'skip the reload for',
    //     10 - Math.abs(moment().diff(lastFetchedAt, 'minutes')),
    //     'minutes'
    //   );
    //   return null;
    // }
    if (abortController) {
      abortController.abort();
    }
    let requestParams: ProposalListParams = params;
    if (!requestParams) {
      if (userType === UserType.Client) {
        requestParams = getClientStartEndParams();
      } else if (userType === UserType.Admin) {
        requestParams = {
          ...getMonthStartEndParams(renderDate),
          username: user.username,
        };
      } else {
        requestParams = getMonthStartEndParams(renderDate);
      }
    }
    try {
      abortController = new AbortController();
      dispatch(setListFetchState(FETCH_STATE.GET));
      dispatch(setListFetchedAt(moment().format(DATE_FMT.DATETIME_FIELD)));
      const response = await api.proposals.list(params, abortController);
      const stillValid = getScheduleRenderDate(getState()) === renderDate;
      if (!stillValid) {
        return null;
      }
      dispatch(setList(response.data));
      return response;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return undefined;
      }
      dispatch(setListFetchedAt(undefined));
      dispatch(setListFetchState(FETCH_STATE.FAILED));
      logger.captureAxiosError('Error fetching proposals', error);
      return undefined;
    }
  };

export const completeProposal =
  (proposal: Proposal) => async (dispatch: AppDispatch, getState) => {
    dispatch(softCompleteProposal(proposal.id));
    setTimeout(() => dispatch(removeListProposal(proposal)), 1000);
  };

export const setProposalData =
  (data: Partial<NewProposal>) => (dispatch: AppDispatch, getState) => {
    const state = getState();
    const proposal = getProposalUpdate(state);
    return dispatch(setUpdate({ ...proposal, ...data }));
  };

export const saveProposals =
  (onSuccess, onError) => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const data = getProposalCreateList(state);
    if (data.length === 0) {
      return null;
    }
    const currentList = getProposals(state);
    try {
      dispatch(createProposalStart());
      const response = await api.proposals.create(data);
      const newList = currentList.concat(response.data);
      dispatch(setList(newList));
      dispatch(setClient(undefined));
      onSuccess();
    } catch (error: any) {
      dispatch(createProposalError());
      onError(error);
    }
  };

export const cancelProposal =
  (proposal: ProposalListItem) => async (dispatch: AppDispatch, getState) => {
    try {
      dispatch(softCancelProposal(proposal.id));
      setTimeout(() => dispatch(removeListProposal(proposal)), 1000);
      api.proposals.cancel(proposal.id);
    } catch (error) {
      enqueueSnackbar(CancelProposalError);
    }
  };
export const archiveProposal =
  (proposal: ProposalListItem) => async (dispatch: AppDispatch, getState) => {
    try {
      dispatch(softArchiveProposal(proposal.id));
      setTimeout(() => dispatch(removeListProposal(proposal)), 1000);
      api.proposals.archive(proposal.id);
    } catch (error) {
      enqueueSnackbar(GenericServerError);
    }
  };

export const removeProposal = (proposal: { datetime: string }) =>
  removeNewProposal(proposal);

export const setProposalClient =
  (client: ClientSerializer) => (dispatch, getState) => {
    dispatch(setClient(client));
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
