import Page from 'containers/page';
import { LearnMoreClient as Meta } from 'metadata';
import React from 'react';
import { useParams } from 'react-router';
import InstructorsPage from './instructors';
import SwimmersPage from './swimmers';

const LearnMore = () => {
  const { audience } = useParams<any>();
  return (
    <Page {...Meta}>
      {audience === 'instructors' ? <InstructorsPage /> : <SwimmersPage />}
    </Page>
  );
};

export default LearnMore;
