import { IS_SERVER } from 'config';

export interface DecodedMediaUrl {
  bucket: string;
  key: string;
  edits: {
    rotate: number;
    resize: {
      width: number;
      height: number;
    };
  };
}

export interface Media {
  url: string;
  id?: number;
  smUrl?: string;
  order?: number;
  protected?: boolean;
}

export class EncodedMedia {
  private url: string;
  private data: DecodedMediaUrl;
  constructor(_url: string) {
    this.url = _url;
    this.data = this.decode();
  }

  get rotation(): number {
    if (
      safe(this.data) &&
      'edits' in this.data &&
      'rotate' in this.data.edits
    ) {
      return this.data.edits.rotate;
    }
    return 0;
  }

  get key(): string {
    if (safe(this.data) && 'key' in this.data) {
      return this.data.key;
    }
    return undefined;
  }

  public decode() {
    if (!this.url || IS_SERVER) {
      return undefined;
    } else if (this.url.indexOf('/v4/') !== -1) {
      return JSON.parse(atob(this.url.split('/v4/')[1]));
    } else {
      return undefined;
    }
  }
}
