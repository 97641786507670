import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { FETCH_STATE } from 'config';
import AccountSlice from 'state/slice/account';
import AdminSlice from 'state/slice/admin';
import ApplicationSlice from 'state/slice/app';
import AppointmentSlice from 'state/slice/appointments';
import AssistanceSlice from 'state/slice/assistance';
import AuthSlice from 'state/slice/authentication';
import AvailabilitySlice from 'state/slice/availability';
import CartSlice from 'state/slice/cart';
import ClientsSlice from 'state/slice/clients';
import FacilitySlice from 'state/slice/facility';
import FavouritesSlice from 'state/slice/favourites';
import InstructorSlice from 'state/slice/instructor';
import MessagingSlice from 'state/slice/messaging';
import ProposalsSlice from 'state/slice/proposals';
import ReviewsSlice from 'state/slice/reviews';
import ScheduleSlice from 'state/slice/schedule';
import SearchSlice from 'state/slice/search';

export type RootState = ReturnType<typeof AppReducer>;
export type AdminState = ReturnType<typeof AdminSlice.reducer>;
export type AccountState = ReturnType<typeof AccountSlice.reducer>;
export type AppointmentState = ReturnType<typeof AppointmentSlice.reducer>;
export type ApplicationState = ReturnType<typeof ApplicationSlice.reducer>;
export type AssistanceState = ReturnType<typeof AssistanceSlice.reducer>;
export type AvailabilityState = ReturnType<typeof AvailabilitySlice.reducer>;
export type AuthenticationState = ReturnType<typeof AuthSlice.reducer>;
export type ClientsState = ReturnType<typeof ClientsSlice.reducer>;
export type FacilityState = ReturnType<typeof FacilitySlice.reducer>;
export type FavouriteState = ReturnType<typeof FavouritesSlice.reducer>;
export type InstructorState = ReturnType<typeof InstructorSlice.reducer>;
export type MessagingState = ReturnType<typeof MessagingSlice.reducer>;
export type ProposalState = ReturnType<typeof ProposalsSlice.reducer>;
export type ReviewsState = ReturnType<typeof ReviewsSlice.reducer>;
export type ScheduleState = ReturnType<typeof ScheduleSlice.reducer>;
export type SearchState = ReturnType<typeof SearchSlice.reducer>;
export interface APIData<T> {
  data: T;
  fetchState: FETCH_STATE;
  fetchedAt: string;
}

const AppReducer = combineReducers({
  [AccountSlice.name]: AccountSlice.reducer,
  [AdminSlice.name]: AdminSlice.reducer,
  [AppointmentSlice.name]: AppointmentSlice.reducer,
  [ApplicationSlice.name]: ApplicationSlice.reducer,
  [AssistanceSlice.name]: AssistanceSlice.reducer,
  [AuthSlice.name]: AuthSlice.reducer,
  [AvailabilitySlice.name]: AvailabilitySlice.reducer,
  [CartSlice.name]: CartSlice.reducer,
  [ClientsSlice.name]: ClientsSlice.reducer,
  [FacilitySlice.name]: FacilitySlice.reducer,
  [FavouritesSlice.name]: FavouritesSlice.reducer,
  [InstructorSlice.name]: InstructorSlice.reducer,
  [MessagingSlice.name]: MessagingSlice.reducer,
  [ProposalsSlice.name]: ProposalsSlice.reducer,
  [ReviewsSlice.name]: ReviewsSlice.reducer,
  [ScheduleSlice.name]: ScheduleSlice.reducer,
  [SearchSlice.name]: SearchSlice.reducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'authentication/logout') {
    return {
      ...state,
      [AccountSlice.name]: { ...AccountSlice.initialState },
      [AdminSlice.name]: { ...AdminSlice.initialState },
      [AppointmentSlice.name]: { ...AppointmentSlice.initialState },
      [ApplicationSlice.name]: { ...ApplicationSlice.initialState },
      [AssistanceSlice.name]: { ...AssistanceSlice.initialState },
      [AuthSlice.name]: { ...AuthSlice.initialState },
      [AvailabilitySlice.name]: { ...AvailabilitySlice.initialState },
      [CartSlice.name]: { ...CartSlice.initialState },
      [ClientsSlice.name]: { ...ClientsSlice.initialState },
      [FacilitySlice.name]: { ...FacilitySlice.initialState },
      [FavouritesSlice.name]: { ...FavouritesSlice.initialState },
      [InstructorSlice.name]: { ...InstructorSlice.initialState },
      [MessagingSlice.name]: { ...MessagingSlice.initialState },
      [ProposalsSlice.name]: { ...ProposalsSlice.initialState },
      [ReviewsSlice.name]: { ...ReviewsSlice.initialState },
      [ScheduleSlice.name]: { ...ScheduleSlice.initialState },
    };
  }
  return AppReducer(state, action);
};

export default rootReducer;
