import { IconButton } from '@mui/material';
import api from 'api';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import Callout from 'components/callout';
import Card from 'components/card';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { FETCH_STATE } from 'config';
import { CreateContact, UpdateContact } from 'features/contacts';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AddIcon, EditIcon, TrashIcon } from 'icons';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getContacts,
  getContactsFetchState,
  getUsername,
} from 'state/selectors';
import { fetchContacts, removeContact } from 'state/slice/account';

const DeleteContact = ({
  contactId,
  contactName,
  onSuccess = undefined,
  onCancel = undefined,
}: {
  contactId: string;
  contactName: string;
  onSuccess?(): void;
  onCancel?(): void;
}) => {
  const dispatch = useAppDispatch();
  const username = useSelector(getUsername);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await api.account.contacts.delete(username, { id: contactId });
      dispatch(removeContact(contactId));
      enqueueSnackbar({
        message: 'Contact successfully deleted',
        variant: 'success',
      });
      onSuccess();
    } catch (error) {
      enqueueSnackbar({
        message: 'Failed to delete contact. Please try again.',
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-8 mt-4">
      <p className="m-0">Remove {contactName} from your contacts?</p>
      <Controls className="!p-0">
        <Button disabled={isLoading} onClick={onCancel} variant="flat">
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          variant="flat"
          color="secondary"
          onClick={handleDelete}
        >
          Remove
        </Button>
      </Controls>
    </div>
  );
};

const Settings = () => {
  const dispatch = useAppDispatch();
  const contacts = useSelector(getContacts);
  const contactsFetchState = useSelector(getContactsFetchState);
  const [contactIdToUpdate, setContactIdToUpdate] = useState('');
  const [contactIdToDelete, setContactIdToDelete] = useState('');
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  useEffect(() => {
    if (contactsFetchState === FETCH_STATE.PRISTINE) {
      dispatch(fetchContacts());
    }
  }, []);

  const handleCloseAddModal = () => setAddModalIsOpen(false);
  const handleCloseEditModal = () => setContactIdToUpdate('');
  const handleCloseDeleteModal = () => setContactIdToDelete('');

  return (
    <Dashboard title="Settings">
      <Card title="Contacts" maxWidth="full">
        <p>Add anyone you'd like to receive notifications.</p>
        <div className="flex flex-col gap-8">
          {contactsFetchState === FETCH_STATE.FAILED ? (
            <Callout type="error" title="Error loading contacts">
              <p>Please refresh to try again.</p>
            </Callout>
          ) : contactsFetchState === FETCH_STATE.PRISTINE ||
            contactsFetchState === FETCH_STATE.GET ? (
            <div className="flex justify-center">
              <Loading
                message="Loading contacts..."
                position="inline-contained"
              />
            </div>
          ) : (
            <>
              <div className="my-4">
                {contacts.length === 0 ? (
                  <Callout type="info" title="You have no contacts" />
                ) : (
                  <div className="flex flex-col gap-8">
                    {contacts.map((contact) => (
                      <div
                        key={contact.id}
                        className="flex flex-col w-full gap-1"
                      >
                        <div className="flex justify-between gap-4">
                          <div>
                            <div className="flex items-center gap-4 grow-0">
                              <div className="text-lg">{contact.name}</div>
                              <div className="text-sm text-gray-700">
                                {contact.email}
                              </div>
                            </div>
                            <div className="flex overflow-hidden text-sm">
                              {`${contact.notifications.length} notification`.pluralize(
                                contact.notifications.length
                              )}
                            </div>
                          </div>
                          <div className="flex gap-3">
                            <div>
                              <IconButton
                                size="large"
                                onClick={() => setContactIdToDelete(contact.id)}
                              >
                                <TrashIcon width={30} />
                              </IconButton>
                            </div>
                            <div>
                              <IconButton
                                size="large"
                                onClick={() => setContactIdToUpdate(contact.id)}
                              >
                                <EditIcon width={30} />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="mx-auto">
                <Button
                  variant="flat"
                  color="primary"
                  icon={<AddIcon width={24} />}
                  onClick={() => setAddModalIsOpen(true)}
                >
                  Add new contact
                </Button>
              </div>
            </>
          )}
        </div>
      </Card>
      <Modal
        name="Host — Create contact"
        open={addModalIsOpen}
        onClose={handleCloseAddModal}
        title="Add Contact"
        maxWidth="xs"
        fullWidth
      >
        <CreateContact
          onSuccess={handleCloseAddModal}
          onCancel={handleCloseAddModal}
        />
      </Modal>
      <Modal
        name="Host — Update contact"
        open={!!contactIdToUpdate}
        onClose={handleCloseEditModal}
        title="Update Contact"
        maxWidth="xs"
        fullWidth
      >
        {contactIdToUpdate && (
          <UpdateContact
            contactId={contactIdToUpdate}
            onSuccess={handleCloseEditModal}
            onCancel={handleCloseEditModal}
          />
        )}
      </Modal>
      <Modal
        name="Host — Remove contact"
        open={!!contactIdToDelete}
        onClose={handleCloseDeleteModal}
        title="Remove Contact"
        maxWidth="xs"
        fullWidth
      >
        <DeleteContact
          contactId={contactIdToDelete}
          contactName={
            contacts.find((elt) => elt.id === contactIdToDelete)?.name
          }
          onSuccess={handleCloseDeleteModal}
          onCancel={handleCloseDeleteModal}
        />
      </Modal>
    </Dashboard>
  );
};

export default Settings;
