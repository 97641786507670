import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import api from 'api';
import { AccountNoteSerializer } from 'api/Serializers/Accounts';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import { DATE_FMT } from 'config';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'state/selectors';

interface Props {
  username: string;
  onLoad?(notes: AccountNoteSerializer[]): void;
}

const NewNote = ({
  username,
  onSubmit,
  existing,
}: {
  username: string;
  onSubmit(refresh: boolean): void;
  existing?: AccountNoteSerializer;
}) => {
  const [newNote, setNote] = useState<string>(existing ? existing.note : '');
  const [isSubmitting, setSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSave();
    }
  };

  const onDelete = async () => {
    await api.notes.delete(existing.id);
    onSubmit(true);
  };

  const onSave = async () => {
    if (newNote === '') {
      onSubmit(false);
      return;
    }
    setSubmitted(true);
    try {
      if (existing) {
        if (existing.note !== newNote) {
          await api.notes.patch(existing.id, { note: newNote });
        }
        onSubmit(existing.note !== newNote);
      } else {
        await api.notes.create(username, { note: newNote });
        setNote('');
        onSubmit(true);
      }
    } catch (error) {
      enqueueSnackbar({
        message: 'An error occurred',
        variant: 'error',
      });
    }
    setSubmitted(false);
  };

  return (
    <ClickAwayListener onClickAway={() => onSubmit(false)}>
      <div className="flex flex-col">
        <TextField
          autoFocus={!!existing}
          placeholder=""
          label={existing ? null : 'Add note'}
          className="flex-1"
          onChange={(event) => setNote(event.target.value)}
          onKeyPress={handleKeyPress}
          disabled={isSubmitting}
          value={newNote}
          multiline={true}
        />
        <Controls>
          {existing && (
            <Button
              color="secondary"
              variant="text"
              size="small"
              onClick={onDelete}
            >
              Delete
            </Button>
          )}
          <Button
            color="primary"
            variant="text"
            size="small"
            disabled={newNote === '' || isSubmitting}
            onClick={onSave}
          >
            Save
          </Button>
        </Controls>
      </div>
    </ClickAwayListener>
  );
};

const Note = ({
  username,
  onSave,
  note,
}: {
  username: string;
  onSave(refresh: boolean): void;
  note: AccountNoteSerializer;
}) => {
  const [editing, setEdit] = useState(false);
  const user = useSelector(getUser);
  const canEdit = note.author.id === user.id;
  const onEdit = (refresh: boolean) => {
    if (refresh) {
      onSave(refresh);
    }
    setEdit(false);
  };
  return (
    <div className="flex my-1">
      <div className="flex flex-col flex-1 p-2 bg-gray-100 rounded-lg hover:bg-gray-200">
        <div className="flex items-center space-x-1">
          <Avatar className="bg-blue-500" diameter={5}>
            {note.author.firstName[0]}
            {note.author.lastName[0]}
          </Avatar>
          <span>{note.author.displayName}</span>
        </div>
        {!editing ? (
          <div
            className={`flex-1 text-gray-700${
              canEdit ? ' cursor-pointer' : ''
            }`}
            onClick={() => setEdit(canEdit)}
          >
            {note.note}
          </div>
        ) : (
          <NewNote username={username} existing={note} onSubmit={onEdit} />
        )}
        <div className="text-xs font-light text-gray-600">
          {moment(note.created).format(DATE_FMT.MON_D_TIME_A)}
        </div>
      </div>
    </div>
  );
};

const AccountNotes: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState<AccountNoteSerializer[]>(undefined);

  const fetchAccountNotes = async (username) => {
    setIsLoading(true);
    const response = await api.notes.list(username);
    setNotes(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAccountNotes(props.username);
  }, []);

  const onModify = (refresh: boolean) => {
    if (refresh) {
      fetchAccountNotes(props.username);
    }
  };

  return (
    <div className={'style.container'}>
      {isLoading ? (
        <Loading />
      ) : (
        notes.map((note, key) => (
          <Note
            key={note.id}
            note={note}
            username={props.username}
            onSave={onModify}
          />
        ))
      )}
      <NewNote username={props.username} onSubmit={onModify} />
    </div>
  );
};

export default AccountNotes;
