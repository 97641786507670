import { MapPinIcon } from '@heroicons/react/24/outline';
import { City, CityListItem } from 'api/Serializers/Activities';
import InputSuggestPlace from 'components/input-suggest-place';
import Link from 'components/link';
import Loading from 'components/loading';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Locality } from 'models/geo';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getSearchCities } from 'state/selectors';
import { setSearchCity } from 'state/slice/search';
import { APP_ROUTES } from 'utils/routing';
import { slugify } from 'utils/string';
// import Button from 'components/button';
// import { useGeolocation } from 'hooks/useGeolocation';
// import { NEAR_ME } from 'config';

const CityList = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const cities = useSelector(getSearchCities);
  const [isGettingLocation, setIsGettingLocation] = useState(false);

  const onCityClick = (locality: City) => {
    dispatch(setSearchCity(locality));
  };

  const onSuggestSelect = (locality: Locality) => {
    if (!locality) {
      return;
    }
    rudderanalytics.track('City selected', {
      method: 'search',
      city: locality,
    });
    onCityClick(locality);
    history.push(APP_ROUTES.BROWSE_CITY(locality.slug));
  };
  const geocodeCallback = (results, status) => {
    const genericErrorMsg = {
      message: 'Error getting location. Please select a city.',
      variant: 'error',
    };
    let locality = undefined;
    try {
      if (status !== google.maps.GeocoderStatus.OK) {
        enqueueSnackbar(genericErrorMsg);
        return;
      }
      if (results.length === 0) {
        enqueueSnackbar(genericErrorMsg);
        return;
      }
      const addressComponents = results[0].address_components;
      const countryObj = addressComponents.find((item) =>
        item.types.includes('country')
      );
      const regionObj = addressComponents.find((item) =>
        item.types.includes('administrative_area_level_1')
      );
      locality = addressComponents.find((item) =>
        item.types.includes('locality')
      )?.long_name;
      if (
        countryObj === undefined ||
        regionObj === undefined ||
        locality === undefined
      ) {
        enqueueSnackbar(genericErrorMsg);
      } else if (countryObj.long_name !== 'Canada') {
        enqueueSnackbar({
          message:
            'Propel is unavailable in your region. Please select a city.',
        });
        return;
      }
      const [country, countryCode] = [
        countryObj.long_name,
        countryObj.short_name,
      ];
      const [region, regionCode] = [regionObj.long_name, regionObj.short_name];
      const label = `${locality}, ${regionCode}`;
      const slug = slugify(label);
      const cityObj: City = {
        name: locality,
        city: locality,
        region,
        label,
        slug,
        regionCode,
        country,
        countryCode,
      };
      onCityClick(cityObj);
      history.push(APP_ROUTES.BROWSE_CITY(slug));
    } finally {
      rudderanalytics.track('City selected', {
        method: 'navigator',
        city: locality,
      });
      setIsGettingLocation(false);
    }
  };
  // TODO: This should use the actual latlng and not the locality center
  const onUseMyLocationClick = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const geo = new google.maps.Geocoder();
      setIsGettingLocation(true);
      geo.geocode(
        {
          location: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        },
        geocodeCallback
      );
    });
  };
  const onClick = (cityList: CityListItem) => () => {
    const { order, regionOrder, ...city } = cityList;
    rudderanalytics.track('City selected', { method: 'click', city });
    onCityClick(city);
  };

  if (!safe(cities)) {
    return null;
  }

  return (
    <>
      {isGettingLocation && <Loading position="absolute" />}
      <div className="min-h-screen px-4 py-8 md:py-16 bg-background">
        <div className="max-w-2xl mx-auto space-y-8 md:space-y-24">
          <h1 className="text-center">Find nearby locations</h1>
          <div className="shadow card">
            <div className="flex flex-col">
              <InputSuggestPlace
                placeholder="Search by City"
                types={['(cities)']}
                onSuggestSelect={onSuggestSelect}
              />
              <div className="flex justify-center gap-1 mt-6 cursor-pointer">
                <MapPinIcon width={24} />
                <a
                  className="flex gap-0.5 text-md font-normal no-underline hover:underline"
                  onClick={onUseMyLocationClick}
                >
                  Use my location
                </a>
              </div>
            </div>
            <div className="flex flex-wrap justify-between my-6 gap-x-4 gap-y-8 sm:min-w-md sm:flex-no-wrap">
              {Object.entries<CityListItem[]>(
                [...cities]
                  .sort((a, b) => a.regionOrder - b.regionOrder)
                  .groupBy((city) => city.regionCode)
              ).map(([regionCode, citiesList], k) => (
                <div key={k} className="flex-1">
                  <h3 className="mb-4 font-black">{regionCode}</h3>
                  <div className="flex flex-col space-y-3">
                    {citiesList
                      .sort((a, b) => a.order - b.order)
                      .map((city) => (
                        <Link
                          key={city.slug}
                          onClick={onClick(city)}
                          className="no-underline hover:underline"
                          to={APP_ROUTES.BROWSE_CITY(city.slug)}
                        >
                          {city.name}
                        </Link>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CityList;
