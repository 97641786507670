import { PropelAccount } from 'api/Serializers/Accounts';
import Loading from 'components/loading';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail } from 'state/selectors';

export enum FleeqModule {
  AvailabilityOverview = '2o2i8a0tbf-7bdexw541e',
  BookingsAndNotifications = 'xf0jxrokem-ng6727ffj3',
  TeachingWithPropel = 'xdfv4er6pk-r9tq01h6bi',
  FirstAppointmentPrepare = 'dinmm1xjw9-p9xovh01di',
}

interface Props {
  module: FleeqModule;
  onLoad?(): void;
}

const embedUrl = (module: FleeqModule) => `https://embed.fleeq.io/l/${module}`;
const trackingUrl = (account: PropelAccount, module: FleeqModule) =>
  `https://app.fleeq.io/learner/register?redirect=${embedUrl(
    module
  )}?mtype=g&email=${account.email}`;

const Fleeq: React.FC<Props> = ({ module, onLoad }) => {
  const account = useSelector(getAccountDetail);
  const [isLoaded, setLoaded] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const setWidthHeight = () => {
    const padding = window.innerWidth >= 600 ? 96 + 64 : 45; // This is how the modal window padding is styled across mobile/sm sized viewports
    const w =
      window.innerWidth > 600 + padding ? 600 : window.innerWidth - padding;
    const h = (w * 3) / 4;
    setWidth(w);
    setHeight(h);
  };
  const onIFrameLoad = () => {
    setLoaded(true);
    if (onLoad) {
      onLoad();
    }
  };
  useEffect(() => {
    setWidthHeight();
    window.addEventListener('resize', setWidthHeight);
    return () => {
      window.removeEventListener('resize', setWidthHeight);
    };
  }, []);
  return (
    <div>
      {!isLoaded && <Loading />}
      <iframe
        name="propel_fleeq_videos"
        src={trackingUrl(account, module)}
        frameBorder={0}
        allowFullScreen={true}
        style={{ width, height }}
        onLoad={onIFrameLoad}
      />
    </div>
  );
};

export default Fleeq;
