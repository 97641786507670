import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
const center = 'flex items-center justify-center';
const MAX_PAGES_SM = 5;

interface Props<Type> {
  data: Type[];
  render: (obj: Type & { key: string }) => JSX.Element;
  className?: string;
  itemsPerPage?: number;
  position?: 'top' | 'bottom' | 'both';
  spaceY?: 0 | 2 | 3 | 4 | 6 | 8 | 10;
}

const Pages = ({
  className,
  pages,
  page,
  numPages,
  setPage,
}: {
  className: string;
  pages: JSX.Element[];
  page: number;
  numPages: number;
  setPage: (int) => () => void;
}) => (
  <div className="flex justify-center">
    <ul className={`flex flex-1 max-w-xs my-2 p-0 ${className}`}>
      <li className={`${center} mx-1`}>
        <IconButton
          size="small"
          disabled={page === 0}
          onClick={setPage(page - 1)}
        >
          <ArrowLeft className="" />
        </IconButton>
      </li>
      {pages}
      <li className={`${center} mx-1`}>
        <IconButton
          size="small"
          disabled={page === numPages - 1}
          onClick={setPage(page + 1)}
        >
          <ArrowRight className="" />
        </IconButton>
      </li>
    </ul>
  </div>
);

function Pagination<T>({
  data,
  render,
  position = 'top',
  className = '',
  itemsPerPage = 10,
  spaceY = 3,
}: Props<T>) {
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  if (data.length === 0) {
    return null;
  } else if (data.length <= itemsPerPage) {
    return (
      <div className={`space-y-${spaceY}`}>
        {data.map((obj, k) => render({ ...obj, key: `itm-${k}` }))}
      </div>
    );
  }
  const pages = [];
  const numPages = Math.ceil(data.length / itemsPerPage);

  const handleClick = (i) => () => {
    setPage(i);
    if (i >= start + MAX_PAGES_SM) {
      setStart(i - MAX_PAGES_SM + 1);
    } else if (i < start) {
      setStart(i);
    }
  };
  for (let i = 0; i < numPages; i++) {
    if (i >= start && i < MAX_PAGES_SM + start) {
      const btnClass = `${center} leading-none h-8 w-8 text-sm ${
        i === page ? 'text-blue-500 font-bold' : ''
      }`;
      pages.push(
        <li key={`page-${i}`} className={`${center} flex-1 mx-1`}>
          <IconButton size="small" onClick={handleClick(i)}>
            <span className={btnClass}>{i + 1}</span>
          </IconButton>
        </li>
      );
    }
  }
  return (
    <div className="max-w-full overflow-hidden">
      {(position === 'top' || position === 'both') && (
        <Pages
          className={className}
          pages={pages}
          page={page}
          setPage={handleClick}
          numPages={numPages}
        />
      )}
      <div className={`space-y-${spaceY}`}>
        {data
          .slice(page * itemsPerPage, itemsPerPage * (1 + page))
          .map((obj, k) => render({ ...obj, key: `itm-${k}` }))}
      </div>
      {(position === 'bottom' || position === 'both') && (
        <Pages
          className={className}
          pages={pages}
          page={page}
          setPage={handleClick}
          numPages={numPages}
        />
      )}
    </div>
  );
}

export default Pagination;
