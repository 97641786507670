import { AccountNoticeSerializer } from 'api/Serializers/Accounts';
import Button from 'components/button';
import Controls from 'components/controls';
import Modal from 'components/modal';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAccountNotices, getProposals } from 'state/selectors';
import { dismissNotice } from 'state/slice/account';

const storagePrefix = '_viewedNotice_';
const ClientNotices = () => {
  const dispatch = useAppDispatch();
  const notices = useSelector(getAccountNotices);
  const [notice, setNotice] = useState<AccountNoticeSerializer>(undefined);
  const proposals = useSelector(getProposals);
  const location = useLocation();

  const getNotice = (): AccountNoticeSerializer => {
    return notices.find(
      (notice) =>
        !notice.dismissed &&
        location.pathname.indexOf(notice.ctaLink) !== 0 &&
        sessionStorage.getItem(`${storagePrefix}${notice.type}`) !== 'true'
    );
  };

  const handleModalClose = () => {
    dispatch(dismissNotice(notice));
    sessionStorage.setItem(`${storagePrefix}${notice.type}`, 'true');
    setNotice(undefined);
  };

  useEffect(() => {
    setNotice(getNotice());
  }, [proposals]);

  if (!notice) {
    return null;
  }

  return (
    <Modal
      open={true}
      name={`Notice | ${notice.type}`}
      title={notice.title}
      maxWidth="xs"
      onClose={handleModalClose}
    >
      <div dangerouslySetInnerHTML={{ __html: notice.body }} />
      <Controls>
        <Button
          onClick={handleModalClose}
          to={notice.ctaLink}
          variant="flat"
          color="primary"
        >
          {notice.ctaTitle}
        </Button>
      </Controls>
    </Modal>
  );
};

export default ClientNotices;
