import { Facility, Instructor } from 'api/Serializers/Appointments';
import {
  ProposalListItem as ProposalListItemType,
  ProposalStatus,
} from 'api/Serializers/Proposals';
import { SchedulableObject } from 'api/Serializers/Schedules';
import Dashboard from 'components/account/dashboard';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Callout from 'components/callout';
import Card from 'components/card';
import Controls from 'components/controls';
import Loading from 'components/loading';
import { DATE_FMT, FETCH_STATE } from 'config';
import ReadMore from 'containers/read-more';
import ProposalActionModal from 'features/proposals/action-modal';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getActivity,
  getProposals,
  getProposalsFetchState,
} from 'state/selectors';
import { archiveProposal } from 'state/slice/proposals';
import { getProposalExpiryText } from 'utils/date';
import { SHARED_ROUTES, SHOW_HELP } from 'utils/routing';

interface Props {
  proposal: ProposalListItemType;
  onApprove?(proposalId): void;
}

const ProposalListItem = ({ proposal, onApprove }: Props) => {
  const [init, setInit] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [remove, setRemove] = useState(
    proposal.status === ProposalStatus.BookingSuccessful
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const datetime = moment(proposal.start).tz(proposal.timezone);
  const expiry = moment(proposal.expiry).tz(proposal.timezone);
  const actionable = proposal.status === ProposalStatus.AwaitingClient;
  const handleArchive = async () => {
    setIsOpen(false);
    enqueueSnackbar({
      message: 'Archived',
      variant: 'success',
    });
    setTimeout(() => dispatch(archiveProposal(proposal)), 300);
  };

  const hide = () => {
    setIsOpen(false);
    setTimeout(() => setRemove(true), 300);
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
    } else if (proposal.status === ProposalStatus.BookingSuccessful) {
      enqueueSnackbar({
        message: `${datetime.format(
          DATE_FMT.DOW_MONTH_D_TIME_A
        )} has been booked`,
        variant: 'success',
      });
      // Delay this ever so slightly so the animation is not lost amongst other page transitions
      setTimeout(hide, 400);
    } else if (proposal.status === ProposalStatus.ClientCancelled) {
      enqueueSnackbar({
        message: `Declined ${datetime.format(DATE_FMT.DOW_MONTH_D_TIME_A)}`,
        variant: 'info',
      });
      setTimeout(hide, 300);
    }
  }, [proposal.status]);

  const expiryText = getProposalExpiryText(proposal);

  if (remove) {
    return null;
  }

  return (
    <>
      <ReadMore
        initiallyOpen
        isOpen={isOpen}
        initialHeight={0}
        openBtnText=""
        closeBtnText=""
      >
        <div
          className={`transition-colors duration-300 ease-in-out flex justify-between flex-col sm:flex-row px-card py-6 md:py-3 text-sm space-y-2 sm:space-y-0 ${
            actionable
              ? 'bg-white text-gray-800'
              : 'text-gray-500 bg-gray-100 italic'
          }`}
        >
          <div className="flex flex-col flex-1">
            <span className="font-semibold">
              {datetime.format(DATE_FMT.DOW_MONTH_D_TIME_A)}
            </span>
            <span className="text-sm text-gray-700">{expiryText}</span>
          </div>
          <div className="flex-1">
            {proposal.status === ProposalStatus.AwaitingClient ? (
              <div className="flex items-center justify-end space-x-2">
                <Button
                  size="small"
                  className="flex-1"
                  variant="outlined"
                  to={SHARED_ROUTES.PROPOSALS.nav(proposal.id, 'cancel')}
                >
                  Decline
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="flex-1"
                  to={SHARED_ROUTES.PROPOSALS.nav(proposal.id, 'approve')}
                >
                  Book now
                </Button>
              </div>
            ) : (
              <div className="flex justify-end space-x-2">
                <div className="flex-1" />
                <Button
                  variant="flat"
                  size="small"
                  color="default"
                  className="flex-1"
                  onClick={handleArchive}
                >
                  Dismiss
                </Button>
              </div>
            )}
          </div>
        </div>
      </ReadMore>
    </>
  );
};

const ClientProposalPage = () => {
  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const activity = useSelector(getActivity);
  const fetchState = useSelector(getProposalsFetchState);
  const allProposals = useSelector(getProposals);
  const reduceUniqueInstructors = (agg: Instructor[], val: SchedulableObject) =>
    agg.find((i) => i.id === val.instructor.id)
      ? agg
      : agg.concat(val.instructor);
  const reduceUniqueFacilities = (agg: Facility[], val: SchedulableObject) =>
    agg.find((i) => i.id === val.facility.id) ? agg : agg.concat(val.facility);

  const allActiveProposals =
    allProposals.filter((p) => p.status === ProposalStatus.AwaitingClient) ||
    [];

  useEffect(() => {
    if (allProposals) {
      setInstructors(allProposals.reduce(reduceUniqueInstructors, []));
    }
  }, [allProposals]);

  return (
    <Dashboard
      title={`${activity.name} proposals (${allActiveProposals.length})`}
      width="2xl"
      onClickHelp={SHOW_HELP.LEARNING.HOW_DO_LESSON_PROPOSALS_WORK}
    >
      {fetchState === FETCH_STATE.GET ? (
        <Loading message="Getting your proposals..." />
      ) : instructors.length > 0 ? (
        instructors.map((instructor, i) => {
          const facilities = allProposals.reduce(reduceUniqueFacilities, []);
          const proposals = allProposals.filter(
            (p) => p.instructor.id === instructor.id
          );
          const activeProposals = proposals.filter(
            (p) => p.status === ProposalStatus.AwaitingClient
          );

          return (
            <div key={instructor.id}>
              <Card maxWidth="full">
                <div className="flex flex-col items-center mb-4 lg:flex-row">
                  <Avatar
                    src={instructor.avatar}
                    className="border-4"
                    variant="circle"
                    diameter={28}
                    border
                  />
                  <div className="flex-shrink-0 sm:flex-1">
                    <div className="flex flex-col text-center lg:text-left lg:mt-3 lg:ml-3">
                      <h2 className="my-0 font-bold text-gray-800 lg:pr-10">
                        Proposed {activity.appointmentNoun.toLowerCase()}s with{' '}
                        {instructor.displayName}
                      </h2>
                      <h4 className="font-medium text-md">
                        {`${activeProposals.length} ${'proposal'.pluralize(
                          activeProposals.length
                        )} to book`}
                      </h4>
                    </div>
                  </div>
                </div>
                <div>
                  <p>
                    You have proposals that are ready to book, but they're only
                    reserved for a limited time. Don't miss out on securing your
                    spot!
                  </p>
                </div>
                <div>
                  {facilities.map((facility) => {
                    const proposals = allProposals.filter(
                      (p) =>
                        p.instructor.id === instructor.id &&
                        p.facility.id === facility.id
                    );
                    const activeStatusValues = [
                      ProposalStatus.AwaitingClient,
                      ProposalStatus.ClientCancelled, // Yes, this status is hit in transition
                      ProposalStatus.BookingInProgress,
                      ProposalStatus.BookingSuccessful,
                    ];
                    const activeProposals = proposals.filter((p) =>
                      activeStatusValues.some((s) => s === p.status)
                    );
                    const expiredProposals = proposals.filter(
                      (p) => !activeStatusValues.some((s) => s === p.status)
                    );
                    return (
                      <div key={`${instructor.id}-${facility.id}`}>
                        <h3 className="my-0 font-semibold text-md">
                          {facility.displayName}
                        </h3>
                        <div></div>
                        {activeProposals.length > 0 ? (
                          <div className="space-y-4">
                            <div className="divide-y divide-gray-300 -mx-card">
                              {activeProposals.map((proposal) => (
                                <ProposalListItem
                                  key={proposal.id}
                                  proposal={proposal}
                                  // onApprove={handleProposalApprove}
                                />
                              ))}
                            </div>
                          </div>
                        ) : (
                          <p className="text-sm text-gray-800">
                            No proposals to book at this time
                          </p>
                        )}
                        {expiredProposals.length > 0 && (
                          <div className="mt-4 space-y-4">
                            <h5 className="my-0 font-semibold">
                              No longer available
                            </h5>
                            <div className="-mx-card">
                              {expiredProposals.map((proposal) => (
                                <ProposalListItem
                                  key={proposal.id}
                                  proposal={proposal}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Card>
            </div>
          );
        })
      ) : (
        <div>
          <Callout title="What are proposals?" type="info">
            Proposals are a great way to plan your lessons ahead of time. Take
            the worry out of waiting for schedule updates; let your instructor
            know you'd like to reserve your favourite spots in advance with
            Proposals!
            <Controls>
              <Button
                variant="flat"
                color="primary"
                onClick={SHOW_HELP.LEARNING.HOW_DO_LESSON_PROPOSALS_WORK}
              >
                Learn more
              </Button>
            </Controls>
          </Callout>
        </div>
      )}
      <ProposalActionModal />
    </Dashboard>
  );
};

export default ClientProposalPage;
