import {
  CertificateType,
  Certification,
  CertifyingBody,
} from 'api/Serializers/Accounts/Instructor';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Input from 'components/input';
import InputFullDate from 'components/input-date/mmm-dd-yyyy';
import Link from 'components/link';
import { DATE_FMT } from 'config';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { EXTERNAL_ROUTES } from 'utils/routing';

interface Props {
  type: 'instructor' | 'lifeguard';
  onComplete(cert: Certification): void;
  certification?: Certification;
}

enum Descriptions {
  CanadianRedCross = 'Water Safety Instructor',
  LifesavingSociety = 'Swim Instructor',
  Lifeguard = 'National Lifeguard',
}
const CreateCertification: React.FC<Props> = (props) => {
  const [init, setInit] = useState(false);
  const [id, setId] = useState<number>(
    props.certification ? props.certification.id : undefined
  );
  const [body, setBody] = useState<CertifyingBody>(
    props.certification ? props.certification.certifyingBody : undefined
  );
  const [type, setType] = useState<CertificateType>(
    props.certification ? props.certification.certificateType : undefined
  );
  const [certNumber, setCertNumber] = useState<string>(
    props.certification ? props.certification.certificateNumber : undefined
  );
  const [expiry, setExpiry] = useState<string>(
    props.certification ? props.certification.dateExpiry : undefined
  );
  const [isValid, setIsValid] = useState(false);
  const [warning, setWarning] = useState<'expired'>();
  const setExpiryDate = (date: string) => {
    if (date) {
      setExpiry(date);
    } else {
      setExpiry(undefined);
    }
  };

  const checkIsValid = () => {
    if (init && body && certNumber && expiry) {
      const isExpired = moment(expiry, DATE_FMT.DATE_KEY).isBefore(
        moment(),
        'day'
      );
      if (isExpired) {
        setIsValid(false);
        setWarning('expired');
      } else {
        setWarning(undefined);
        setIsValid(true);
      }
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    if (props.type === 'instructor') {
      if (body === CertifyingBody.CanadianRedCross) {
        setType(CertificateType.WaterSafetyInstructor);
      } else if (body === CertifyingBody.LifesavingSociety) {
        setType(CertificateType.SwimInstructor);
      } else {
        setType(undefined);
      }
    } else {
      if (body) {
        setType(CertificateType.NationalLifeguard);
      } else {
        setType(undefined);
      }
    }
    checkIsValid();
  }, [body]);

  useEffect(() => {
    checkIsValid();
  }, [certNumber, expiry]);

  useEffect(() => {
    if (isValid) {
      const cert: Certification = {
        id,
        certifyingBody: body,
        certificateType: type,
        certificateNumber: certNumber,
        certifiedBy: body,
        dateExpiry: expiry,
      };
      props.onComplete(cert);
    } else {
      props.onComplete(undefined);
    }
  }, [isValid]);

  useEffect(() => setInit(true), []);

  return (
    <div className="w-full">
      <div className="space-y-3">
        <ButtonLarge
          variant="radio"
          title="Canadian Red Cross"
          subtitle={
            props.type === 'instructor'
              ? Descriptions.CanadianRedCross
              : Descriptions.Lifeguard
          }
          selected={body === CertifyingBody.CanadianRedCross}
          onClick={() => setBody(CertifyingBody.CanadianRedCross)}
        />
        <ButtonLarge
          variant="radio"
          title="Lifesaving Society"
          subtitle={
            props.type === 'instructor'
              ? Descriptions.LifesavingSociety
              : Descriptions.Lifeguard
          }
          selected={body === CertifyingBody.LifesavingSociety}
          onClick={() => setBody(CertifyingBody.LifesavingSociety)}
        />
      </div>
      <Input
        name="certNumber"
        value={certNumber}
        disabled={!body}
        placeholder="Certificate or Member ID"
        onChange={(event) => setCertNumber(event.target.value)}
        title="Certificate # or Member ID"
        required={false}
      />
      <InputFullDate
        name="expiry"
        label="Expiry Date"
        initialDate={expiry}
        latestDate={moment().add(5, 'years')}
        earliestDate={moment()}
        onChange={setExpiryDate}
      />
      {warning === 'expired' && (
        <Callout title="Expired Cert" type="info">
          <p>You must renew this cert before continuing with your profile.</p>
          <p>
            <Link
              to={`${EXTERNAL_ROUTES.BLOG.INSTRUCTOR_PERKS}?ref=instructor-signup-cert-page`}
            >
              Check out our Propel instructor perks page
            </Link>{' '}
            for discounts you can get from local certification programs!
          </p>
        </Callout>
      )}
    </div>
  );
};

export default CreateCertification;
