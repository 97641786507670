import { MaxWidth } from 'config';
import React from 'react';
interface Props {
  className?: string;
  maxWidth?: MaxWidth;
  children: any;
}
const TaskCard: React.FC<Props> = ({ className, maxWidth, children }) => (
  <main className={`sm:py-16 w-full${cls(className)}`}>
    <section
      className={`m-auto sm:shadow-md bg-white w-full sm:max-w-${maxWidth} sm:rounded-xl sm:h-auto`}
    >
      <div
        className={`px-5 sm:px-10 py-8 max-w-md sm:max-w-${maxWidth} mx-auto`}
      >
        {children}
      </div>
    </section>
  </main>
);
TaskCard.defaultProps = {
  maxWidth: 'md',
};
export default TaskCard;
