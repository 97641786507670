import { configureStore } from '@reduxjs/toolkit';
import { env, STORE_THROTTLE_MS } from 'config';
import throttle from 'lodash.throttle';
import rootReducer from 'state';
import { saveState } from './loadStore';

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const persistState = (store) => () => saveState(store.getState());

const isDebug = process.env.NODE_ENV !== 'production';
const middleware = [];
// if (!isServer) {
//   if (isDebug) {
//     middleware.push(logger);
//   }
// }

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
    devTools: isDebug,
  });
}

export const store = makeStore();

if (!isServer && env === 'development' && (module as any).hot) {
  (module as any).hot.accept('state', () => {
    store.replaceReducer(require('state'));
  });
}

if (!isServer) {
  store.subscribe(throttle(persistState(store), STORE_THROTTLE_MS));
  delete window.__PRELOADED_STATE__;
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
