import api from 'api';
import { MonthlyData, PayoutSummaryData } from 'api/Serializers/Payouts';
import { AxiosResponse } from 'axios';
import Dashboard from 'components/account/dashboard';
import Card from 'components/card';
import { KPI } from 'components/kpi-card';
import Loading from 'components/loading';
import { DATE_FMT, MONTH_NAMES } from 'config';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface Graph {
  title: string;
  getter: (e: MonthlyData) => number;
  maxVal: number;
  gridStep: number;
  data: Array<{
    month: string;
    [year: number]: number;
  }>;
  colors: string[];
}

const getGraphData = (data: MonthlyData[]): Graph[] => {
  const earningsGraph = getEarningsGraphs(data);
  const kpiGraph = getKpiGraph(data);
  return [kpiGraph].concat(earningsGraph);
};

const getKpiGraph = (data: MonthlyData[]): Graph => {
  const graph = {
    title: 'Marketplace GMV',
    getter: (e) => e.value,
    maxVal: 0,
    gridStep: 0,
    data: undefined,
    colors: ['#2BC2F5', '#F5495C', '#7448F7', '#FA7A2F'],
  };
  const end = moment().startOf('month');
  const start = moment(end).subtract(3, 'years');
  const filtered = data.filter((d) =>
    moment(d.month).isBetween(start, end, 'month', '[]')
  );
  const arr = [];
  for (var i = 1; i <= MONTH_NAMES.length; i++) {
    const month = MONTH_NAMES[i - 1];
    const obj = {
      month,
    };
    filtered
      .filter((d) => i === d.numMonth)
      .map((d) => (obj[d.numYear] = graph.getter(d)));
    arr.push(obj);
  }
  const maxVal = filtered.reduce(
    (agg: number, d: MonthlyData, i) =>
      Number(graph.getter(d)) > agg ? Number(graph.getter(d)) : agg,
    0
  );
  const gridStep = (maxVal / 4) * 1.2;
  const rounded = Number(Math.ceil(maxVal / gridStep).toFixed()) * gridStep;
  return { ...graph, data: arr, maxVal: rounded, gridStep };
};

const colors = [
  '#2BC2F5',
  '#F5495C',
  '#7448F7',
  '#FA7A2F',
  '#20F54B',
  '#F51E9D',
];

const getEarningsGraphs = (data: MonthlyData[]): Graph[] => {
  let graphs: Graph[] = [
    {
      title: 'GMV',
      getter: (e) => e.value,
      maxVal: 0,
      gridStep: 0,
      data: undefined,
      colors,
    },
    {
      title: 'Revenue',
      getter: (e) => e.revenue,
      maxVal: 0,
      gridStep: 0,
      data: undefined,
      colors,
    },
    {
      title: 'Net Income',
      getter: (e) => e.netIncome,
      maxVal: 0,
      gridStep: 0,
      data: undefined,
      colors,
    },
    {
      title: 'Lessons Delivered',
      getter: (e) => e.complete,
      maxVal: 0,
      gridStep: 0,
      data: undefined,
      colors,
    },
    {
      title: 'Instructors',
      getter: (e) => e.numInstructors,
      maxVal: 0,
      gridStep: 0,
      data: undefined,
      colors,
    },
    {
      title: 'Clients',
      getter: (e) => e.numClients,
      maxVal: 0,
      gridStep: 0,
      data: undefined,
      colors,
    },
    {
      title: 'Client Avg. GMV',
      getter: (e) => Math.round(e.cashReceived / e.numClients),
      maxVal: 0,
      gridStep: 0,
      data: undefined,
      colors,
    },
  ];
  graphs = graphs.map((graph) => {
    const arr = [];
    for (var i = 1; i <= MONTH_NAMES.length; i++) {
      const month = MONTH_NAMES[i - 1];
      const obj = {
        month,
      };
      data
        .filter((d) => i === d.numMonth)
        .map((d) => (obj[d.numYear] = graph.getter(d)));
      arr.push(obj);
    }
    const maxVal = data.reduce(
      (agg: number, d: MonthlyData, i) =>
        Number(graph.getter(d)) > agg ? Number(graph.getter(d)) : agg,
      0
    );
    const gridStep = (maxVal / 4) * 1.2;
    const rounded = Number(Math.ceil(maxVal / gridStep).toFixed()) * gridStep;
    return { ...graph, data: arr, maxVal: rounded, gridStep };
  });
  return graphs;
};

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const SummaryData = () => {
  const [data, setData] = useState<PayoutSummaryData>(undefined);
  async function fetchData() {
    const response = await api.payouts.summary();
    setData(response.data);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return !data ? (
    <Loading position="inline" />
  ) : (
    <Card maxWidth="full">
      <div className="flex space-x-4">
        <div className="flex-1 space-y-2">
          <div>
            <h4 className="">Recent</h4>
            <h5 className="font-light text-gray-600">
              {moment(data.recent.payoutDate).format(DATE_FMT.DOW_MONTH_D_YEAR)}
            </h5>
          </div>
          <KPI title="Revenue" value={data.recent.revenue} type="money" />
          <KPI title="Expenses" value={data.recent.expenses} type="money" />
          <KPI title="Net Income" value={data.recent.net} type="money" />
          <KPI title="Tax" value={data.recent.tax} type="money" />
        </div>
        <div className="flex-1 space-y-2">
          <div>
            <h4 className="">Current</h4>
            <h5 className="font-light text-gray-600">
              {moment(data.current.payoutDate).format(
                DATE_FMT.DOW_MONTH_D_YEAR
              )}
            </h5>
          </div>
          <KPI
            title="Revenue"
            value={data.current.revenue}
            type="money"
            help="Admission + booking fees"
          />
          <KPI
            title="Expenses"
            value={data.current.expenses}
            type="money"
            help="Facility fees + tax"
          />
          <KPI
            title="Net Income"
            value={data.current.net}
            type="money"
            help="Revenue - Expenses"
          />
          <KPI
            title="Tax"
            value={data.current.tax}
            type="money"
            help="Taxes collected less facility fee taxes"
          />
        </div>
        <div className="flex-1 space-y-2">
          <div>
            <h4 className="">Future</h4>
            <h5 className="font-light text-gray-600">
              {moment(data.future.payoutDate).format(DATE_FMT.DOW_MONTH_D_YEAR)}
            </h5>
          </div>
          <KPI title="Revenue" value={data.future.revenue} type="money" />
          <KPI title="Expenses" value={data.future.expenses} type="money" />
          <KPI title="Net Income" value={data.future.net} type="money" />
          <KPI title="Tax" value={data.future.tax} type="money" />
        </div>
      </div>
    </Card>
  );
};

const Payouts = () => {
  const [graphs, setGraphs] = useState<Graph[]>([]);

  async function getData() {
    const response: AxiosResponse<MonthlyData[]> = await api.payouts.monthly();
    setGraphs(getGraphData(response.data));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dashboard title="Earnings" width="4xl">
      <div className="space-y-8">
        <SummaryData />
        {graphs.map((graph, i) => {
          return (
            <div key={`graph-${i}`}>
              <Card
                name={graph.title}
                title={graph.title}
                maxWidth="full"
                space="tight"
              >
                <ResponsiveContainer width="99%" height={300}>
                  <LineChart
                    data={graph.data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="month"
                      allowDataOverflow={true}
                      interval={0}
                      height={60}
                      tick={(props) => <CustomizedAxisTick {...props} />}
                    />
                    <YAxis
                      name="GMV"
                      domain={[0, graph.maxVal]}
                      scale="linear"
                      tickCount={graph.maxVal / graph.gridStep + 1}
                    />
                    <Tooltip />
                    <Legend />
                    {graph.colors.map((color, i) => {
                      const year = moment().year() - i;
                      return (
                        <Line
                          key={'color-key-' + i}
                          type="monotone"
                          dataKey={String(year)}
                          stroke={color}
                          strokeWidth={i === 0 ? 3 : i === 1 ? 2 : 1}
                        />
                      );
                    })}
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </div>
          );
        })}
      </div>
    </Dashboard>
  );
};

export default Payouts;
