import { DEBUG, IS_SERVER } from 'config';
import Cookies from 'js-cookie';
import { StorageHandler } from '.';

export enum CookieKey {
  AB = '_prpl_device_ab_',
  DeviceId = '_prpl_device_id_',
  AuthToken = 'token',
  RefreshToken = 'refresh',
  IsProxySession = '_prpl_is_proxy_',
  SessionId = '_prpl_session_id_',
  MessagingToken = 'messaging_token',
  DismissTaxInfo = '_prpl_dismiss_tax_info',
}

type CookieOptions = {
  expires?: number | Date;
  'max-age'?: number;
};
const _cookieStore = {};

class CookieHandler implements StorageHandler<CookieKey, CookieOptions> {
  private defaults;
  private enabled: boolean;
  constructor() {
    this.enabled = IS_SERVER || navigator.cookieEnabled;
    const path = '/';
    const secure = !DEBUG;
    const sameSite = 'Lax';
    const domain = DEBUG ? 'localhost' : '.propelhq.com';
    this.defaults = { path, domain, secure, sameSite };
    this.removeInvalidCookies();
  }
  isEnabled(): boolean {
    return this.enabled;
  }
  private removeInvalidCookies() {
    const keys = [
      CookieKey.AuthToken,
      CookieKey.RefreshToken,
      CookieKey.MessagingToken,
      CookieKey.DeviceId,
      CookieKey.SessionId,
    ];
    for (let i = 0; i < keys.length; i++) {
      // Cookies.remove(keys[i], { domain: '.propelhq.com' });
      Cookies.remove(keys[i], { domain: 'www.propelhq.com' });
    }
  }
  set(
    key: CookieKey,
    value: string | object | any[],
    options?: CookieOptions
  ): void {
    if (!this.enabled) {
      _cookieStore[key] = value;
    } else {
      Cookies.set(key, value, { ...this.defaults, ...options });
    }
  }
  get(key: CookieKey): string {
    if (!this.enabled) {
      return _cookieStore[key];
    }
    return Cookies.get(key);
  }
  getJSON(key: CookieKey): any {
    if (!this.enabled) {
      return _cookieStore[key];
    }
    return Cookies.getJSON(key);
  }
  remove(key): void {
    if (!this.enabled) {
      delete _cookieStore[key];
      return;
    }
    Cookies.remove(key, this.defaults);
  }
}

export default CookieHandler;
