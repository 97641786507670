import Avatar from 'components/avatar';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { DATE_FMT } from 'config';
import {
  AppointmentProductPriceDetails,
  BookingStep,
  getBookingStepState,
  SelectCartParticipants,
  SelectPaymentMethodAndBookNow,
} from 'features/booking-flow';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import { BusinessIcon, FacilityIcon } from 'icons';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getCartAppointmentProduct,
  getCartError,
  getCartPaymentMethod,
  getCartStatus,
  getProposalDetail,
} from 'state/selectors';
import { fetchAppointments } from 'state/slice/appointments';
import {
  clearCart,
  fetchAvailabilityForCart,
  fetchCartAppointmentProduct,
} from 'state/slice/cart';
import {
  cancelProposal,
  completeProposal,
  fetchProposal,
} from 'state/slice/proposals';
import { getClientStartEndParams } from 'utils/date';
import { SHARED_ROUTES } from 'utils/routing';

const BookingHeader = ({
  title,
  onEdit = undefined,
}: {
  title: string;
  onEdit?(): void;
}) => {
  return (
    <div className="flex justify-between mb-2">
      <h3 className="my-0 font-semibold text-md">{title}</h3>
      {onEdit && (
        <button className="link" onClick={onEdit}>
          Edit
        </button>
      )}
    </div>
  );
};

const ProposalActionModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingMessage, setLoadingMessage] = useState('');
  const proposal = useSelector(getProposalDetail);
  const appointmentProduct = useSelector(getCartAppointmentProduct);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<BookingStep>('participants');
  const [closingAction, setClosingAction] = useState<'cancel' | 'approve'>(
    undefined
  );
  const cartStatus = useSelector(getCartStatus);
  const cartError = useSelector(getCartError);
  const selectedPaymentMethod = useSelector(getCartPaymentMethod);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useQuery();
  const actionParam = params.get('action') as 'cancel' | 'approve';
  const action = actionParam ? actionParam : closingAction;
  const id = params.get('id');

  const initProposalData = async () => {
    setLoadingMessage('Getting proposal');
    try {
      const proposal = await dispatch(fetchProposal(id));
      if (action === 'approve') {
        try {
          await dispatch(
            fetchCartAppointmentProduct(proposal.appointmentProductId)
          );
          await dispatch(
            fetchAvailabilityForCart(
              proposal.availabilityId,
              'PROPOSAL',
              'PROPOSAL'
            )
          );
          setLoadingMessage('');
          setIsOpen(true);
        } catch (err) {
          handleClose();
        }
      } else {
        setLoadingMessage('');
        setIsOpen(true);
      }
    } catch (err) {
      handleClose();
    }
  };

  const handleProposalBooked = () => {
    const params = getClientStartEndParams();
    dispatch(completeProposal(proposal));
    dispatch(fetchAppointments(params));
    handleClose();
  };

  const handleClosed = () => {
    setClosingAction(undefined);
    dispatch(clearCart());
  };

  const reset = () => {
    setIsOpen(false);
    setLoadingMessage('');
    setStep('participants');
    handleClosed();
  };

  useEffect(() => {
    reset();
    if (id) {
      initProposalData();
    }
  }, [id]);

  useEffect(() => {
    if (cartStatus === 'COMPLETE') {
      handleProposalBooked();
      setLoadingMessage('');
    } else if (cartStatus === 'ERROR') {
      logger.error('Cart error');
      enqueueSnackbar({
        message: cartError?.message
          ? cartError.message
          : 'An error was encountered while booking your proposal. Please try again.',
        variant: 'error',
      });
      setLoadingMessage('');
    } else if (cartStatus === 'PROCESSING') {
      setLoadingMessage('Booking proposal');
    }
  }, [cartStatus]);

  const handleClose = () => {
    setClosingAction(actionParam);
    history.push(SHARED_ROUTES.PROPOSALS.ROOT);
    reset();
  };

  const getLocalStart = () => {
    return proposal ? moment(proposal.start).tz(proposal.timezone) : undefined;
  };

  const handleCancel = async () => {
    dispatch(cancelProposal(proposal));
    handleClose();
  };

  return (
    <>
      {loadingMessage && <Loading message={`${loadingMessage}...`} />}
      <Modal
        name={`Proposal — ${action}`}
        title={
          action === 'approve'
            ? 'Complete your proposal'
            : action === 'cancel'
            ? 'Decline proposal'
            : ''
        }
        open={isOpen}
        onClose={loadingMessage ? null : handleClose}
        maxWidth="sm"
        fullScreen={action === 'approve'}
      >
        {proposal && (
          <div className="max-w-lg mx-auto space-y-8">
            {action === 'approve' && appointmentProduct ? (
              <>
                <div className="px-6 -mx-6 border-gray-300 md:mx-0 md:border md:rounded-xl">
                  <div className="flex py-6 space-x-3 ">
                    <div className="w-32 h-28">
                      <div className="absolute z-0 w-32 overflow-hidden bg-contain rounded-lg h-28">
                        <div className="absolute z-10 w-full h-28">
                          <img
                            className="min-h-full"
                            src={proposal.facility.hero}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 py-1 space-y-1 text-sm">
                      <div className="labeled-icon !py-0">
                        <Avatar src={proposal.instructor.avatar} diameter={6} />
                        <span>{proposal.instructor.displayName}</span>
                      </div>
                      <div className="labeled-icon !py-0">
                        <FacilityIcon width={24} />
                        <span>{proposal.facility.displayName}</span>
                      </div>
                      <div className="labeled-icon !py-0">
                        <BusinessIcon width={24} />
                        <span>{proposal.activity.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="hidden mb-6 md:block">
                    <AppointmentProductPriceDetails />
                  </div>
                </div>
                <div className="space-y-2">
                  <BookingHeader title="Date & time" />
                  <div>
                    {getLocalStart().format(DATE_FMT.MONTH_D_YEAR_TIME_A)}
                  </div>
                </div>
                <SelectCartParticipants
                  state={getBookingStepState(step, 'participants')}
                  onClickContinue={() =>
                    setStep(!selectedPaymentMethod ? 'payment' : 'checkout')
                  }
                  onClickEdit={() => setStep('participants')}
                />
                <SelectPaymentMethodAndBookNow
                  state={getBookingStepState(step, 'payment')}
                  onClickContinue={() => setStep('checkout')}
                  onClickEdit={() => setStep('payment')}
                />
              </>
            ) : action === 'cancel' ? (
              <>
                <div className="space-y-2">
                  <BookingHeader title="Date & time" />
                  <div>
                    {getLocalStart().format(DATE_FMT.MONTH_D_YEAR_TIME_A)}
                  </div>
                </div>
                <p>Are you sure you wish to decline this spot?</p>
                <Controls>
                  <Button
                    to={SHARED_ROUTES.PROPOSALS.ROOT}
                    disabled={!!loadingMessage}
                  >
                    Go back
                  </Button>
                  <Button
                    variant="contained"
                    color={'secondary'}
                    onClick={handleCancel}
                    disabled={!!loadingMessage}
                  >
                    Yes, decline
                  </Button>
                </Controls>
              </>
            ) : null}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ProposalActionModal;
