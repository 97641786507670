import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import {
  APP_RELEASE,
  DEBUG,
  IS_SERVER,
  RUN_SENTRY_DEV,
  SENTRY_CLIENT_KEY,
} from 'config';
const environment = DEBUG ? 'debug' : IS_SERVER ? 'server' : 'client';
const allowUrls =
  DEBUG && RUN_SENTRY_DEV
    ? [
        new RegExp('http://localhost:3000/.*'),
        new RegExp('http://localhost:3001/.*'),
      ]
    : !DEBUG
    ? [new RegExp('https://www.propelhq.com/.*')]
    : [];

if (!window) {
  throw new Error('This util is specifically for the browser app');
}

Sentry.init({
  dsn: SENTRY_CLIENT_KEY,
  release: APP_RELEASE,
  tracesSampleRate: DEBUG ? 1 : 0.25,
  environment,
  allowUrls,
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
    new ExtraErrorData(),
    // new HttpClient(),
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // integrations: [
  //   new posthog.SentryIntegration(posthog, organization, projectId),
  // ],
});
