import Link from 'components/link';
import React from 'react';

type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;

interface ButtonExtra {
  to?: string;
  icon?: JSX.Element;
  target?: string;
  fullWidth?: boolean;
  color?: 'default' | 'primary' | 'secondary' | 'yellow';
  size?: 'default' | 'large' | 'small';
  shape?:
    | 'rounded-none'
    | 'rounded'
    | 'rounded-sm'
    | 'rounded-md'
    | 'rounded-lg'
    | 'rounded-xl'
    | 'rounded-full';
  variant?: 'text' | 'outlined' | 'contained' | 'flat';
  replace?: boolean;
  /**
   * When true, the button is disabled to prevent further clicking
   * and the icon will change to an infinite loading-indicator to show it is busy
   */
  isLoading?: boolean;
}

export interface ButtonProps
  extends SimpleSpread<React.HTMLProps<HTMLButtonElement>, ButtonExtra> {}

const baseRoot =
  'space-x-2 border border-solid m-0 inline-flex relative items-center select-none align-middle justify-center !no-underline !hover:no-underline whitespace-nowrap';
const buttonRoot =
  'box-border transition-colors duration-150 ease-in-out font-semibold font-body';

const getSize = (size: 'default' | 'large' | 'small') =>
  size === 'small' ? 'text-sm' : size === 'large' ? 'text-lg' : 'text-base';
const getPadding = (size: 'default' | 'large' | 'small', hasIcon) =>
  hasIcon
    ? size === 'small'
      ? 'pl-2 pr-3 h-10'
      : size === 'large'
      ? 'pl-4 pr-6 h-16'
      : 'pl-3 pr-5 h-12'
    : size === 'small'
    ? 'px-3 h-10'
    : size === 'large'
    ? 'px-8 py-4'
    : 'px-5 h-12';
const BaseButton = ({
  to,
  size,
  fullWidth,
  icon,
  className,
  children,
  target,
  shape,
  type,
  replace = false,
  ...rest
}: ButtonProps) => {
  const isLink = safe(to) && !rest.disabled;
  let Component: any;
  let linkProps = {};

  if (isLink) {
    const isAbs = to.indexOf('http') > -1 || to.indexOf('webcal') > -1;
    const _target = target ? target : '_blank';
    if (isAbs) {
      Component = 'a';
      linkProps = {
        ...rest,
        href: to,
        target: _target,
        role: type,
      };
    } else {
      Component = Link;
      linkProps = {
        ...rest,
        to,
        target,
        replace,
        role: type,
      };
    }
  } else {
    Component = 'button';
    linkProps = { ...rest, type };
  }
  const padding = getPadding(size, !!icon);
  const clsSize = `min-w-16 ${padding} ${getSize(size)}`;
  const clsFullWidth = fullWidth ? ' w-full' : '';
  const cursor = rest.disabled ? 'cursor-default' : 'cursor-pointer';
  return (
    <Component
      className={`${buttonRoot} ${baseRoot} ${shape} ${clsSize}${clsFullWidth} ${cursor} ${cls(
        className
      )}`}
      {...linkProps}
    >
      {children}
    </Component>
  ) as JSX.Element;
};

BaseButton.defaultProps = {
  type: 'button',
  size: 'default',
  fullWidth: false,
  disabled: false,
  shape: 'rounded-full',
};

export default BaseButton;
