import Button from 'components/button';
import {
  API_ROOT_URL,
  MAX_IMAGE_UPLOAD_DIMENSION,
  MAX_IMAGE_UPLOAD_FILESIZE_BYTES,
} from 'config';
import React from 'react';
import { v4 } from 'uuid';
const ReactS3Uploader = require('react-s3-uploader');

interface UploadProps {
  onFinish(imageServerUrl: string, rawUrl?: string): void;
  icon?: boolean;
  name?: string;
  title?: string;
  disabled?: boolean;
  maxDimension?: number;
  onError?(message: string): void;
  onProgress?(progress: number): void;
  onStart?(): void;
}

const hasBlobConstructor =
  typeof Blob !== 'undefined' &&
  (function () {
    try {
      return Boolean(new Blob());
    } catch (e) {
      return false;
    }
  })();

const hasArrayBufferViewSupport =
  hasBlobConstructor &&
  typeof Uint8Array !== 'undefined' &&
  (function () {
    const Bl: new (arr, opts?) => Blob = Blob;
    try {
      return new Bl([new Uint8Array(100)]).size === 100;
    } catch (e) {
      return false;
    }
  })();

const dataURLToBlob = (dataURL) => {
  if (typeof window !== 'undefined') {
    const BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts1 = dataURL.split(',');
      const contentType1 = parts1[0].split(':')[1];
      const raw1 = parts1[1];
      return new Blob([raw1], { type: contentType1 });
    }

    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  } else {
    return null;
  }
};

const processSelectedFile = (
  file,
  callback,
  onError: (message: string) => void,
  options: { minDimension: number; maxDimension: number }
) => {
  const split = file.name.split('.');
  const ext: string = split[split.length - 1].toLowerCase();
  const fileName = v4() + '.' + ext;
  const hasReaderSupport = typeof FileReader !== 'undefined';
  // do not resize if broswer doesn't support it
  // TODO Allow for resizing in browsers without support...somewhere.
  if (file.size > MAX_IMAGE_UPLOAD_FILESIZE_BYTES) {
    onError('Image size cannot be greater than 10MB');
    return false;
  }
  if (!hasReaderSupport || !hasArrayBufferViewSupport) {
    return callback(file);
  }
  // Load the image
  const reader = new FileReader();
  reader.onload = (readerEvent) => {
    const image = new Image();
    image.onload = (imageEvent) => {
      // Resize the image
      const canvas = document.createElement('canvas');
      let width = image.width;
      let height = image.height;
      if (width < options.minDimension || height < options.minDimension) {
        onError(
          `Image dimensions are too small. Please upload an image of at least ${options.minDimension}x${options.minDimension} pixels in size.`
        );
      } else if (
        width > options.maxDimension ||
        height > options.maxDimension
      ) {
        if (width > height) {
          if (width > options.maxDimension) {
            height *= options.maxDimension / width;
            width = options.maxDimension;
          }
        } else {
          if (height > options.maxDimension) {
            width *= options.maxDimension / height;
            height = options.maxDimension;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg');
        const resizedImage: any = dataURLToBlob(dataUrl);
        resizedImage.lastModifiedDate = new Date();
        resizedImage.name = fileName;
        callback(resizedImage);
      } else {
        callback(file);
      }
    };
    const target: any = readerEvent.target;
    image.src = target.result;
  };
  reader.readAsDataURL(file);
};

const Uploader = ({
  title = 'Add Image',
  name = 'file-uploader',
  icon = true,
  disabled,
  onError,
  onStart,
  onFinish,
  onProgress,
  maxDimension = MAX_IMAGE_UPLOAD_DIMENSION,
}: UploadProps) => {
  const handleError = (err) => {
    if (onError) {
      onError(
        err.message
          ? err.message
          : 'Oops! Something went wrong trying to upload your file. Try it again.'
      );
    }
  };

  const handleProgress = (progress: number) => {
    if (onProgress) {
      onProgress(progress);
    }
  };

  const handleStart = (file, callback) => {
    const options = {
      minDimension: 250,
      maxDimension: maxDimension,
    };
    processSelectedFile(file, callback, handleError, options);
    if (onStart) {
      onStart();
    }
  };

  const scrubFilename = (filename) => filename.replace(/[^\w\d_\-\.]+/gi, '');

  const handleFinish = (result: { signedUrl: string }) => {
    const rawS3Url = result.signedUrl.split('?')[0];
    const imageServerUrl = urlToImageServerUrl(
      `/uploads/${rawS3Url.split('/uploads/')[1]}`
    );
    onFinish(imageServerUrl, rawS3Url);
  };

  const handleClick = (event) => {
    const fileElem = document.getElementById(name);
    if (!fileElem) {
      return;
    }
    fileElem.click();
    event.preventDefault();
  };

  return (
    <div className="flex items-center justify-center group">
      {icon && (
        <span>
          <svg
            className="w-12 h-12 mx-auto text-gray-400 transition-colors duration-300 ease-in-out group-hover:text-blue-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}
      <Button
        onClick={handleClick}
        disabled={disabled}
        className="shadow-sm"
        variant="outlined"
      >
        {title}
      </Button>
      <ReactS3Uploader
        id={name}
        name={name}
        disabled={disabled}
        preprocess={handleStart}
        onProgress={handleProgress}
        onError={handleError}
        onFinish={handleFinish}
        accept="image/apng,image/jpeg,image/pjpeg,image/png,image/tiff,image/webp"
        className="absolute w-px h-px overflow-hidden opacity-0 pointer-events-none focus:outline-none"
        contentDisposition="auto"
        scrubFilename={scrubFilename}
        server={API_ROOT_URL}
        signingUrl={'/v1/sign/'}
        signingUrlMethod="GET"
        uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
      />
    </div>
  );
};

export default Uploader;
