import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import api from 'api';
import { FacilityKpiData } from 'api/Serializers/Analytics';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import Card from 'components/card';
import { KPI } from 'components/kpi-card';
import Loading from 'components/loading';
import Progress from 'components/progress';
import { AVG_SPACE_PER_INSTRUCTOR, DATE_FMT, FETCH_STATE } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useSchedulableFacilities from 'hooks/useSchedulableFacilities';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip as ChartToolTip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  getAdminFetchState,
  getAdminKpi,
  getScheduleRenderDate,
} from 'state/selectors';
import { fetchKpi } from 'state/slice/admin';
import { setScheduleRenderDate } from 'state/slice/schedule';

const FacilityData = ({ data }: { data: FacilityKpiData }) => {
  const { facilities, isFacilitiesLoading } = useSchedulableFacilities();
  const facility = facilities.find((f) => f.id === data.facility);
  if (!facility) {
    return null;
  }
  const numInstructorsNeeded =
    Math.round(data.space / AVG_SPACE_PER_INSTRUCTOR) - data.numInstructors;
  const numClientsNeeded = Math.ceil(data.opportunity / 2);
  return (
    <>
      <div className="flex items-center font-bold text-gray-700">
        {/* <Button
          to={`${ADMIN_ROUTES.AVAILABILITY_MAP.ROOT}/${facility.slug}`}
          fullWidth={true}
        >
          {facility.shortName}
        </Button> */}
        {facility.shortName}
      </div>
      <div className="">
        {data.supply}/{Math.round(data.space)}
        <Progress percent={Math.round((data.supply / data.space) * 100)} />
      </div>
      <div className="">
        {data.demand}/{data.supply}
        <Progress percent={Math.ceil((data.demand / data.supply) * 100)} />
      </div>
      <KPI value={numInstructorsNeeded} />
      <KPI value={numClientsNeeded} />
    </>
  );
};

const KPIs = () => {
  const dispatch = useAppDispatch();
  const kpiData = useSelector(getAdminKpi);
  const fetchState = useSelector(getAdminFetchState);
  const renderDate = useSelector(getScheduleRenderDate);
  const loading = fetchState === FETCH_STATE.GET;
  useEffect(() => {
    dispatch(fetchKpi());
  }, [renderDate]);

  if (loading || !kpiData) {
    return <Card>Loading...</Card>;
  }

  return (
    <div className="max-w-full pt-8 pb-6 mx-auto bg-white rounded-lg shadow-md px-card min-w-xs">
      <h2 className="pb-4 m-0 text-lg font-bold text-left text-gray-700 font-body">
        {moment(kpiData.startDate).format(DATE_FMT.MONTH_D)} to{' '}
        {moment(kpiData.endDate).format(DATE_FMT.MONTH_D_YEAR)}
      </h2>
      {loading ? (
        <Loading position="fixed" />
      ) : (
        <div className="space-y-8">
          <div className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row">
            <KPI title="Facilities" value={kpiData.data?.length} />
            <KPI title="Space" value={kpiData['totalPrimeSpace']} />
            <KPI
              title="Supply"
              value={kpiData['totalPrimeSupply']}
              total={kpiData['totalPrimeSpace']}
            />
            <KPI
              title="Demand"
              value={kpiData['totalPrimeDemand']}
              total={kpiData['totalPrimeSupply']}
            />
          </div>
          <div className="relative">
            <h3>Greatest Opportunity</h3>
            <h4>Drive clients to these locations</h4>
            <div className="grid flex-1 grid-cols-5 gap-4">
              <div className="font-bold text-left">Facility</div>
              <div className="font-bold text-left">Supply</div>
              <div className="font-bold text-left">Demand</div>
              <div className="font-bold text-left">Instructors Needed</div>
              <div className="font-bold text-left">Clients Needed</div>
              {kpiData.data
                ?.filter((f) => f.needsDemand)
                .sort((a, b) => b.opportunity - a.opportunity)
                .map((fd, i) => (
                  <FacilityData key={`${fd.facility}-opp`} data={fd} />
                ))}
            </div>
          </div>
          <div className="relative">
            <h3>Additional Supply Needed</h3>
            <h4>
              These locations have excess space for instructor availability
            </h4>
            <div className="grid flex-1 grid-cols-5 gap-4">
              <div className="font-bold text-left">Facility</div>
              <div className="font-bold text-left">Supply</div>
              <div className="font-bold text-left">Demand</div>
              <div className="font-bold text-left">Instructors Needed</div>
              <div className="font-bold text-left">Clients Needed</div>
              {kpiData.data
                ?.filter((f) => f.needsSupply)
                .sort(
                  (a, b) =>
                    b.space / 2 -
                    b.numInstructors -
                    (a.space / 2 - a.numInstructors)
                )
                .map((fd, i) => (
                  <FacilityData key={`${fd.facility}-opp`} data={fd} />
                ))}
            </div>
          </div>
          <div className="relative">
            <h3>Insufficient Prime Hours</h3>
            <h4>
              Locations with fewer than 60 available prime hours per month
            </h4>
            <div className="grid flex-1 grid-cols-5 gap-4">
              <div className="font-bold text-left">Facility</div>
              <div className="font-bold text-left">Supply</div>
              <div className="font-bold text-left">Demand</div>
              <div className="font-bold text-left">Instructors Needed</div>
              <div className="font-bold text-left">Clients Needed</div>
              {kpiData.data
                ?.filter((f) => f.needsSpace)
                .sort((a, b) => b.demand - a.demand)
                .map((fd, i) => (
                  <FacilityData key={`${fd.facility}-opp`} data={fd} />
                ))}
            </div>
          </div>
          <div className="relative">
            <h3>Doin Great</h3>
            <h4>Stacked, stocked, and rakin' it in</h4>
            <div className="grid flex-1 grid-cols-5 gap-4">
              <div className="font-bold text-left">Facility</div>
              <div className="font-bold text-left">Supply</div>
              <div className="font-bold text-left">Demand</div>
              <div className="font-bold text-left">Instructors Needed</div>
              <div className="font-bold text-left">Clients Needed</div>
              {kpiData.data
                ?.filter(
                  (f) => !f.needsDemand && !f.needsSpace && !f.needsSupply
                )
                .sort((a, b) => b.demand - a.demand)
                .map((fd, i) => (
                  <FacilityData key={`${fd.facility}-opp`} data={fd} />
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const DateControls = () => {
  const renderDate = useSelector(getScheduleRenderDate);
  const dispatch = useAppDispatch();
  const minDate = moment().subtract(12, 'months');
  const maxDate = moment().add(6, 'months');
  return (
    <Card maxWidth="sm">
      <div className="relative flex justify-between capitalize cursor-default">
        <Button
          onClick={() =>
            dispatch(
              setScheduleRenderDate(
                moment(renderDate)
                  .subtract(1, 'month')
                  .format(DATE_FMT.DATE_KEY)
              )
            )
          }
          disabled={moment(renderDate).isSameOrBefore(minDate, 'month')}
          icon={<ChevronLeft />}
        />
        <Button
          className="whitespace-nowrap"
          onClick={() =>
            dispatch(setScheduleRenderDate(moment().format(DATE_FMT.DATE_KEY)))
          }
        >
          {moment(renderDate).format(DATE_FMT.MONTH_YEAR)}
        </Button>
        <Button
          onClick={() =>
            dispatch(
              setScheduleRenderDate(
                moment(renderDate).add(1, 'month').format(DATE_FMT.DATE_KEY)
              )
            )
          }
          disabled={moment(renderDate).isSameOrAfter(maxDate, 'month')}
          icon={<ChevronRight />}
        />
      </div>
    </Card>
  );
};

const SalesChart = () => {
  const [dailySales, setDailySales] = useState(undefined);
  const renderDate = useSelector(getScheduleRenderDate);
  const start = moment(renderDate).startOf('month').format(DATE_FMT.DATE_KEY);
  const end = moment(renderDate).endOf('month').format(DATE_FMT.DATE_KEY);

  useEffect(() => {
    getDailySales();
  }, [renderDate]);

  const getDailySales = async () => {
    const params = {
      start,
      end,
    };
    const response = await api.analytics.dailySales(params);
    setDailySales(response.data);
  };

  const getBarChartData = () => {
    const arr = [];
    for (let i = 1; i <= moment(end).date(); i++) {
      const date = moment(start).date(i);
      const data = dailySales.find(
        (day) => day.date === date.format(DATE_FMT.DATE_KEY)
      );
      if (!data) {
        continue;
      }
      const obj = {
        key: date.format(DATE_FMT.DATE_KEY),
        label: i,
        sales: data.numSales,
        cancellations: data.numCancellations,
        lessons: data.numLessons,
      };
      arr.push(obj);
    }
    return arr;
  };

  if (!dailySales) {
    return <Loading />;
  }

  return (
    <Card
      maxWidth="6xl"
      title={`Daily Sales for ${moment(renderDate).format(
        DATE_FMT.MONTH_YEAR
      )}`}
    >
      <ResponsiveContainer width="99%" height={300}>
        <BarChart
          data={getBarChartData()}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis />
          <ChartToolTip />
          <Legend />
          <Bar dataKey="cancellations" fill="#f43f5e" />
          <Bar dataKey="sales" fill="#34d399" />
          <Bar dataKey="lessons" fill="#38bdf8" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

const AdminDashboard = () => {
  return (
    <Dashboard title="Overview" width="6xl">
      <div className="space-y-8">
        <SalesChart />
        <KPIs />
      </div>
    </Dashboard>
  );
};

export default AdminDashboard;
