import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Button from 'components/button';
import React from 'react';

const ThumbsButtonGroup = ({
  value,
  handleClick,
}: {
  value: boolean;
  handleClick(value: boolean): void;
}) => {
  return (
    <div className="space-x-2 w-36">
      <Button
        variant={value === false ? 'contained' : 'flat'}
        color={value === false ? 'secondary' : 'default'}
        icon={<ThumbDownIcon />}
        onClick={() => handleClick(false)}
      />
      <Button
        variant={value === true ? 'contained' : 'flat'}
        color={value === true ? 'primary' : 'default'}
        icon={<ThumbUpIcon />}
        onClick={() => handleClick(true)}
      />
    </div>
  );
};

export default ThumbsButtonGroup;
