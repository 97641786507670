import { SelectOption as SelectOptionState } from 'components/select';
import React from 'react';

interface State {
  context: string;
  selectValue: SelectOptionState<any>;
}
export interface SelectOptionComponentProps extends SelectOptionState<any> {
  state?: State;
  children?: any;
}

const baseStyle = 'text-base px-4 py-2';
const getStyles = (props) =>
  `${baseStyle}${props.isSelected ? ' text-gray-800' : ''} ${
    props.isDisabled
      ? 'text-gray-500 bg-gray-200'
      : 'text-gray-700 cursor-pointer hover:bg-blue-200 hover:text-gray-800'
  }`;

const SelectOption = (props) => {
  if (!props.innerProps) {
    return null;
  }
  const { onMouseMove, onMouseOver, ...otherInnerProps } = props.innerProps;
  return (
    <div className={getStyles(props)} {...otherInnerProps}>
      {props.label}
    </div>
  );
};

export default SelectOption;
