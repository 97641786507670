import Section from 'layouts/section';
import * as React from 'react';
import style from './style.module.scss';

// TODO change title to text
const Hint = ({ title }) => {
  return (
    <Section style={{ marginBottom: '1rem' }}>
      <div className={style.text}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </div>
    </Section>
  );
};

export default Hint;
