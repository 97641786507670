import {
  FacilitySchedulableSerializer,
  FacilityStatus,
} from 'api/Serializers/Facilities';
import { FETCH_STATE } from 'config';
import { useSelector } from 'react-redux';
import {
  getFacilitiesSchedulable,
  getFacilitiesSchedulableFetchState,
} from 'state/selectors';

const useSchedulableFacilities = (onlyActive = false) => {
  const _facilities = useSelector(getFacilitiesSchedulable);
  const facilityFetchState = useSelector(getFacilitiesSchedulableFetchState);
  const facilities: FacilitySchedulableSerializer[] = _facilities
    ? onlyActive
      ? _facilities.filter(
          (f) =>
            f.status === FacilityStatus.Active ||
            f.status === FacilityStatus.Seeding
        )
      : // .sort((a, b) => b.rank - a.rank)
        _facilities
    : [];
  const isFacilitiesLoading = facilityFetchState === FETCH_STATE.GET;
  return { facilities, facilityFetchState, isFacilitiesLoading };
};

export default useSchedulableFacilities;
