import { useEffect, useState } from 'react';

/**
 * Initializes PostHog javascript library, which is loaded
 * by Rudderstack, and is not part of the yarn installation.
 *
 * Usage:
 *  ```const { ready, getFeatureFlag } = usePostHog();
 *  useEffect(() => {
 *    if (ready) {
 *      getFeatureFlag('test-page');
 *  }
 * }, [ready]);```
 * @returns ready, getFeatureFlag
 */
export const usePostHog = () => {
  const _postHogTest = () => typeof posthog !== 'undefined';
  const _flagFnTest = () => typeof posthog.getFeatureFlag !== 'undefined';
  const [isPostHogReady, setIsReady] = useState(
    _postHogTest() && _flagFnTest()
  );

  const initPostHog = async () => {
    if (typeof posthog === 'undefined') {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (typeof posthog !== 'undefined') {
            clearInterval(interval);
            resolve(true);
          }
        }, 100);
      });
    }
    return true;
  };

  const initPostHogFlags = async () => {
    if (_flagFnTest()) {
      return true;
    }
    return new Promise((resolve) => {
      posthog.onFeatureFlags(() => {
        resolve(true);
      });
    });
  };

  const getFeatureFlag = (featureFlagKey: string) => {
    if (!isPostHogReady) {
      logger.error(
        'DevError: getFeatureFlag called without checking if isPostHogReady'
      );
      return undefined;
    }
    return posthog.getFeatureFlag(featureFlagKey);
  };

  const init = async () => {
    await initPostHog();
    await initPostHogFlags();
    setIsReady(true);
  };

  useEffect(() => {
    init();
  }, []);

  return { isPostHogReady, getFeatureFlag };
};
