import { TimeFrame } from 'api/Serializers/Appointments';
import Avatar from 'components/avatar';
import { ListButton } from 'components/button';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { FETCH_STATE, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AccountIcon, BookmarkIcon, MessagesIcon, WarningIcon } from 'icons';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getAppointment,
  getAppointmentFetchState,
  getUser,
} from 'state/selectors';
import { fetchAppointment } from 'state/slice/appointments';
import { processReportAction } from 'state/slice/assistance';
import { SHARED_ROUTES } from 'utils/routing';
import ManageSection from './sections/manage';
import ParticipantSection from './sections/participants';
import PaymentSection from './sections/payment';
import SummarySection from './sections/summary';
import { SectionProps, SECTION_CLASSES } from './types';

const Hero = ({ src }) => (
  <div className="relative flex justify-center">
    <div className="absolute z-0 w-full h-56 overflow-hidden bg-contain">
      <div className="absolute z-10 w-full h-56">
        <img className="min-h-full" src={src} />
      </div>
    </div>
  </div>
);

const InstructorSection = ({ appointment, userType }: SectionProps) => {
  if (userType === UserType.Instructor || userType === UserType.Host) {
    return null;
  }
  const dispatch = useAppDispatch();
  return (
    <div className={SECTION_CLASSES}>
      <h4>Your {appointment.activity.instructorDescription.toLowerCase()}</h4>
      <div className="space-y-2">
        <div className="labeled-icon h-">
          <Avatar
            src={appointment.instructor.avatar}
            className=""
            diameter={6}
          />
          <span className="text-gray-900">
            {appointment.instructor.fullName}
          </span>
        </div>
        {appointment.timeFrame >= TimeFrame.Within2 &&
          appointment.timeFrame < TimeFrame.Complete && (
            <ListButton
              title={`I can't find my ${appointment.activity.instructorDescription.toLowerCase()}`}
              subtitle={
                appointment.timeFrame !== TimeFrame.HasStarted
                  ? `Only available during ${appointment.activity.appointmentNoun.toLowerCase()} time`
                  : null
              }
              to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'instructor')}
              disabled={appointment.timeFrame !== TimeFrame.HasStarted}
              onClick={() =>
                dispatch(
                  processReportAction({
                    type: 'SET_CATEGORY',
                    key: 'category',
                    value: 'INSTRUCTOR',
                    title: 'What do you need help with?',
                    description: `I can't find my ${appointment.activity.instructorDescription.toLowerCase()}`,
                  })
                )
              }
              icon={<WarningIcon width={24} />}
            />
          )}
        <ListButton
          title={`Message your ${appointment.activity.instructorDescription.toLowerCase()}`}
          to={SHARED_ROUTES.MESSAGES.nav(appointment.instructor.messageId)}
          icon={<MessagesIcon width={24} />}
        />
      </div>
    </div>
  );
};

const ClientSection = ({ appointment, userType }: SectionProps) => {
  const dispatch = useAppDispatch();
  if (userType === UserType.Client || userType === UserType.Host) {
    return null;
  }
  return (
    <div className={SECTION_CLASSES}>
      <h4>Your client</h4>
      <div className="space-y-2">
        <div className="labeled-icon">
          <AccountIcon width={24} />
          <span className="text-gray-900">{appointment.client.fullName}</span>
        </div>
        <div className="labeled-icon">
          <BookmarkIcon width={24} />
          <span className="text-gray-900">
            {appointment.numAppointment.toOrdinal()}{' '}
            {appointment.activity.name.toLowerCase()}
          </span>
        </div>
        {appointment.timeFrame >= TimeFrame.Within2 &&
          appointment.timeFrame < TimeFrame.Complete && (
            <ListButton
              title="I can't find my client"
              subtitle={
                appointment.timeFrame !== TimeFrame.HasStarted
                  ? `Only available during ${appointment.activity.appointmentNoun.toLowerCase()} time`
                  : null
              }
              to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'client/call')}
              disabled={appointment.timeFrame !== TimeFrame.HasStarted}
              onClick={() => {
                dispatch(
                  processReportAction({
                    type: 'SET_CATEGORY',
                    key: 'category',
                    value: 'CLIENT',
                    title: 'What do you need help with?',
                    description: 'Client issues',
                  })
                );
                dispatch(
                  processReportAction({
                    type: 'SET_TYPE',
                    key: 'type',
                    value: 'CLIENT_NOT_HERE',
                    title: 'What seems to be the problem?',
                    description: 'Client did not show up to this lesson',
                  })
                );
              }}
              icon={<WarningIcon width={24} />}
            />
          )}
        <ListButton
          title="Message your client"
          to={SHARED_ROUTES.MESSAGES.nav(appointment.client.messageId)}
          icon={<MessagesIcon width={24} />}
        />
      </div>
    </div>
  );
};

const AppointmentLoader = ({
  id,
  onClose,
}: {
  id: string;
  onClose(): void;
}) => {
  const user = useSelector(getUser);
  const appointment = useSelector(getAppointment);
  const fetchState = useSelector(getAppointmentFetchState);
  const dispatch = useAppDispatch();
  const open = !!id && !!appointment;
  const loading = !!id && fetchState === FETCH_STATE.GET;
  const sectionProps = { appointment: appointment, userType: user.type };

  useEffect(() => {
    dispatch(fetchAppointment(id));
  }, [id]);

  return (
    <>
      {loading && (
        <div>
          <Loading message="Getting your booking..." />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30" />
        </div>
      )}
      <Modal
        name="Appointment Detail"
        open={open}
        onClose={onClose}
        maxWidth="sm"
        variant="borderless"
      >
        {!!appointment && (
          <div className="w-full text-base sm:w-120">
            <div className="flex flex-col w-full rounded-lg">
              <div className="h-56 overflow-hidden sm:hidden">
                <Hero src={appointment.facility.hero} />
              </div>
              <div className="hidden h-56 overflow-hidden rounded-t-lg sm:block">
                <Hero src={appointment.facility.hero} />
              </div>
              <div className="flex flex-col flex-1 gap-2 bg-background">
                <SummarySection {...sectionProps} />
                <InstructorSection {...sectionProps} />
                <ClientSection {...sectionProps} />
                <ParticipantSection {...sectionProps} />
                <ManageSection {...sectionProps} />
                <PaymentSection {...sectionProps} />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AppointmentLoader;
