import api from 'api';
import { AppointmentProductStatus } from 'api/Serializers/AppointmentProducts';
import Button from 'components/button';
import HelpButton from 'components/button-help';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { DATE_FMT, INSTRUCTOR_RATE_INCR, MAX_PRICE, MIN_PRICE } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AddIcon, MinusIcon } from 'icons';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchAppointmentProducts } from 'state/slice/schedule';
import { InstructorOnboardingRoutes } from 'utils/routing';
import { BaseStepProps, TextClasses, TitleClasses } from '..';

const SelectPrice = ({
  account,
  onboarding,
  appointmentProducts,
  modifyOnboarding,
}: BaseStepProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const appointmentProduct = appointmentProducts.find(
    (aptProd) =>
      aptProd.status === AppointmentProductStatus.Active ||
      AppointmentProductStatus.Pending
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showBenefits, toggleBenefits] = useState<boolean>(false);
  const [price, setPrice] = useState(
    appointmentProduct?.facility.averageAppointmentPrice - 5
  );
  const getEarningsBreakdown = (price: number) => {
    if (!price) {
      return {
        admissionFee: appointmentProduct.admissionFee,
        bookingFee: 0,
        salesTax: 0,
        earnings: 0,
        extraSwimmer: 0,
      };
    }
    const admissionFee = appointmentProduct.admissionFee;
    const bookingFee = price * appointmentProduct.bookingRate;
    const totalFees = admissionFee + bookingFee;
    const salesTax = totalFees * appointmentProduct.taxRate;
    const earnings = price - totalFees - salesTax;
    const extraSwimmer = earnings * 1.2;
    return {
      admissionFee,
      bookingFee,
      salesTax,
      earnings,
      extraSwimmer,
    };
  };

  const newBreakdown = getEarningsBreakdown(price);

  const handlePriceChange = (newPrice: number) => {
    if (newPrice > MAX_PRICE || newPrice < MIN_PRICE) {
      enqueueSnackbar({
        message: `The minimum price is ${MIN_PRICE.toCurrency()} and the maximum price is ${MAX_PRICE.toCurrency()}`,
        variant: 'error',
        preventDuplicate: true,
      });
    } else {
      setPrice(newPrice);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const data = {
        price,
        startDate: moment().format(DATE_FMT.DATE_KEY),
        appointmentProductId: appointmentProduct.id,
      };
      await api.prices.create(data);
      await dispatch(fetchAppointmentProducts(true));
      history.push(InstructorOnboardingRoutes.TeachingPreferences);
    } catch (error) {
      logger.error(error);
      enqueueSnackbar({
        message: 'There was a problem saving that price, please try again.',
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="w-full py-4 space-y-4 sm:w-96">
        <h1 className={TitleClasses}>Set your price</h1>
        <p className={TextClasses}>
          This is the price your clients pay per appointment.
        </p>
        <p className={TextClasses}>
          We suggest starting with{' '}
          <strong>
            ${appointmentProduct.facility.averageAppointmentPrice - 5}
          </strong>
          , and increase your price after getting more popular.
        </p>
        <div className="p-4 rounded-lg bg-background">
          <h2 className="font-semibold text-center text-md">Your price</h2>
          <div className="flex-center">
            <button
              className="rounded-full btn gray !px-1.5"
              onClick={() => handlePriceChange(price - INSTRUCTOR_RATE_INCR)}
              disabled={isLoading}
            >
              <MinusIcon width={18} />
            </button>
            <div className="w-16 text-2xl font-semibold text-center text-gray-900">
              ${price}
            </div>
            <button
              className="rounded-full btn gray !px-1.5"
              onClick={() => handlePriceChange(price + INSTRUCTOR_RATE_INCR)}
              disabled={isLoading}
            >
              <AddIcon width={18} />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4 space-y-2 text-base">
          <div className="col-span-3" />
          <div className="font-medium text-right text-gray-700">Price</div>

          <div className="col-span-3">Price per lesson</div>
          <div className="font-semibold text-right text-gray-900 text-md">
            {price.toCurrency()}
          </div>

          <div className="col-span-3">Pool admission</div>
          <div className="font-normal text-right text-gray-700">
            -{newBreakdown.admissionFee.toCurrency()}
          </div>

          <div className="col-span-3">16% booking fee</div>
          <div className="font-normal text-right text-gray-700">
            -{newBreakdown.bookingFee.toCurrency()}
          </div>

          <div className="col-span-3">Sales tax</div>
          <div className="font-normal text-right text-gray-700">
            -{newBreakdown.salesTax.toCurrency()}
          </div>

          <hr className="col-span-4" />

          <div className="col-span-3 font-bold">Payout per lesson</div>
          <div className="font-bold text-right text-gray-900">
            {newBreakdown.earnings.toCurrency()}
          </div>

          <div className="col-span-3 italic">With 2 swimmers</div>
          <div className="italic font-medium text-right text-gray-900">
            {newBreakdown.extraSwimmer.toCurrency()}
          </div>
        </div>
        <p className={TextClasses}>
          Clients pay online when they book their lessons, so you never need to
          worry about payments.
        </p>
        <div className="my-6 text-center">
          <HelpButton onClick={() => toggleBenefits(true)}>
            What do booking fees pay for?
          </HelpButton>
        </div>
        <Controls>
          <Button
            disabled={isLoading}
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Controls>
      </div>
      <Modal
        name="Instructor — Learn booking fees"
        open={showBenefits}
        onClose={() => toggleBenefits(false)}
        title="Everything You Need"
      >
        <div className="max-w-md">
          <p>
            Booking fees allow Propel to handle the less-fun side of teaching
            lessons, so you can focus on being a great teacher.
          </p>
          <h3>With Propel, you get</h3>
          <ol>
            <li>$10M Liability insurance 🎉</li>
            <li>Customer support for you and your clients 🙌</li>
            <li>Promotion to thousands of new clients 🤩</li>
            <li>New features and capabilities 🚀</li>
          </ol>
          <Controls>
            <Button
              onClick={() => toggleBenefits(false)}
              color="default"
              variant="flat"
            >
              Close
            </Button>
          </Controls>
        </div>
      </Modal>
    </>
  );
};
export default SelectPrice;
