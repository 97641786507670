import api from 'api';
import InstructorAccount from 'api/Serializers/Accounts/Instructor';
import { Details, Payout, YearlySummary } from 'api/Serializers/Payouts';
import Dashboard from 'components/account/dashboard';
import PeriodSummary from 'components/dashboards/earnings/period-summary';
import YearSummary from 'components/dashboards/earnings/year-summary';
import Link from 'components/link';
import Loading from 'components/loading';
import Select, { SelectOption } from 'components/select';
import { DATE_FMT, FETCH_STATE, PAYOUT_CONTROL_DATE } from 'config';
import AppointmentDetail from 'features/appointment-detail';
import { GenericServerError } from 'lang/en/Snackbars';
import { AccountRouteParams } from 'models/route';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getCurrentUser } from 'state/selectors';
import { EXTERNAL_ROUTES, SHOW_HELP } from 'utils/routing';

const getStatementOptions = (allPayouts: Payout[]): SelectOption<string>[] =>
  allPayouts.map((payouts: Payout) => {
    const payoutsStart = moment(payouts.start).utc();
    const payoutsEnd = moment(payouts.end).utc();
    return {
      label: `${payoutsStart.format(DATE_FMT.MON_D_YEAR)} - ${payoutsEnd.format(
        DATE_FMT.MON_D_YEAR
      )}`,
      value: payouts.id,
    };
  });

const UnpaidCard = () => {
  return (
    <div>
      <h2>No summary yet</h2>
      <p>
        Summaries are generated every 2nd Friday and show your earnings over the
        previous two weeks.
      </p>
      <p>Make sure you've submitted your direct deposit information:</p>
      <p>
        <Link className="link" to={EXTERNAL_ROUTES.DIRECT_DEPOSIT}>
          Submit your Direct Deposit info here
        </Link>
      </p>
    </div>
  );
};

type Mode = 'latest' | 'payout' | 'year';

const Earnings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector(getCurrentUser) as InstructorAccount;
  const { params } = useRouteMatch<AccountRouteParams>();
  const history = useHistory();
  const currentYear = moment().year();
  const [statementOptions, setStatementOptions] = useState<
    SelectOption<string>[]
  >([]);
  const [appointmentId, setAppointmentId] = useState(undefined);
  const [invoiceDetails, setInvoiceDetails] = useState<Details>();
  const [yearlySummary, setYearlySummary] = useState<YearlySummary>();
  const [payouts, setPayouts] = useState<Payout[]>();
  const [payoutFetchState, setFetchState] = useState<FETCH_STATE>(
    FETCH_STATE.PRISTINE
  );

  const isYear =
    params.mode &&
    params.mode.length === 4 &&
    !isNaN(Number(params.mode)) &&
    Number(params.mode) >= 2018 &&
    Number(params.mode) <= currentYear;
  const detail: string | number = isYear ? Number(params.mode) : params.mode;
  const mode: Mode =
    typeof params.mode === 'undefined' ? 'latest' : isYear ? 'year' : 'payout';

  function getYearOptions() {
    let num = 3;
    if (account.liveSince) {
      num = moment().year() - moment(account.liveSince).year() + 1;
    }
    return [...Array(num).keys()].map((i) => ({
      label: currentYear - i + '',
      value: currentYear - i,
    }));
  }

  const init = async () => {
    setFetchState(FETCH_STATE.GET);
    const response = await api.payouts.list();
    const payoutData = response.data;
    setPayouts(payoutData);
    setStatementOptions(getStatementOptions(payoutData));
    setFetchState(FETCH_STATE.IDLE);
  };

  const fetchPayoutsInvoices = async (pid) => {
    setFetchState(FETCH_STATE.GET);
    try {
      const payoutResponse = await api.payouts.retrieve(pid);
      const invoiceData = await api.payouts.details(pid, {
        invoice: payoutResponse.data.periodInvoices[0].id,
      });
      setInvoiceDetails(invoiceData.data);
      setFetchState(FETCH_STATE.IDLE);
    } catch (error) {
      enqueueSnackbar(GenericServerError);
    }
  };

  const fetchPayoutSummary = async () => {
    const year = Number(detail);
    if (year > currentYear || year < 2018) {
      enqueueSnackbar('Invalid year selection', { variant: 'error' });
    } else {
      setFetchState(FETCH_STATE.GET);
      const response = await api.payouts.byYear(detail);
      setYearlySummary(response.data[0]);
      setFetchState(FETCH_STATE.IDLE);
    }
  };

  const performDesiredAction = () => {
    if (payouts.length > 0) {
      if (mode === 'latest') {
        fetchPayoutsInvoices(payouts[0].id);
      } else if (mode === 'year') {
        fetchPayoutSummary();
      } else {
        fetchPayoutsInvoices(detail);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (typeof payouts !== 'undefined') {
      performDesiredAction();
    }
  }, [detail, payouts]);

  const numPaydays = Math.floor(
    moment().diff(PAYOUT_CONTROL_DATE, 'weeks') / 2
  );
  const nextPayday = moment(PAYOUT_CONTROL_DATE).add(
    (numPaydays + 1) * 2,
    'weeks'
  );
  const nextStart = moment(nextPayday).subtract(3, 'weeks').add(1, 'days');
  const nextEnd = moment(nextPayday).subtract(1, 'weeks');
  const yearOptions = getYearOptions();

  return (
    <Dashboard title="Earnings" width="5xl">
      <div className="grid gap-6 md:grid-cols-2 no-print">
        <div className="card">
          <h2>Statements</h2>
          <div className="flex flex-row gap-4">
            <div className="flex-1">
              <label>Bi-weekly</label>
              <Select
                options={statementOptions}
                value={statementOptions.find((opt) => opt.value === detail)}
                onChange={(option: SelectOption<string>) =>
                  history.push(INSTRUCTOR_ROUTES.EARNINGS.DETAIL(option.value))
                }
                disabled={statementOptions.length === 0}
              />
            </div>
            <div className="flex-1">
              <label>Annual</label>
              <Select
                options={yearOptions}
                onChange={(option: SelectOption<string>) =>
                  history.push(INSTRUCTOR_ROUTES.EARNINGS.DETAIL(option.value))
                }
                disabled={yearOptions.length === 0}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <h2>Quick help</h2>
          <div>
            <ul className="pl-4 list-disc">
              <li>
                Next payout date is{' '}
                <strong>
                  Friday {nextPayday.format(DATE_FMT.MONTH_D_ORDINAL)}
                </strong>
              </li>
              <li>
                Includes earnings between {nextStart.format(DATE_FMT.MON_D)} and{' '}
                {nextEnd.format(DATE_FMT.MON_D_YEAR)}
              </li>
              <li>
                Read our guide on{' '}
                <span
                  onClick={SHOW_HELP.TEACHING.FILING_TAXES}
                  className="link"
                >
                  filing your taxes
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="">
        <div className="card">
          {payoutFetchState === FETCH_STATE.PRISTINE ? (
            <div />
          ) : payoutFetchState === FETCH_STATE.GET ? (
            <Loading message="Loading earnings..." />
          ) : payoutFetchState === FETCH_STATE.IDLE ? (
            payouts.length === 0 ? (
              <UnpaidCard />
            ) : isYear ? (
              <YearSummary details={yearlySummary} />
            ) : (
              <PeriodSummary
                details={invoiceDetails}
                onClickAppointment={(id) => () => setAppointmentId(id)}
              />
            )
          ) : null}
          <AppointmentDetail
            id={appointmentId}
            onClose={() => setAppointmentId(undefined)}
          />
        </div>
      </div>
    </Dashboard>
  );
};

export default Earnings;
