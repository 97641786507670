import HostAccount from 'api/Serializers/Accounts/Host';
import Schedule from 'features/schedule/as-host';
import { RouteProps } from 'models/route';
import React from 'react';

interface ReduxProps {
  account: HostAccount;
}

const CalendarPage = (props: RouteProps & ReduxProps) => <Schedule />;

export default CalendarPage;
