import { APP_ROOT_URL, DEBUG } from 'config';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const SITE_URL = APP_ROOT_URL;

const defaultTitle = 'Propel';
const defaultDescription =
  'Propel creates access to affordable private swim lessons in local pools, giving you the flexibility to book anytime with your choice of instructor and location';
const defaultImage =
  'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL3Byb3BlbC1oZWFkLWxvZ28ucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ==';
const defaultTwitter = '@propelhq';
const defaultSep = ' · ';

export interface MetaTags {
  title?: string;
  titleSuffix?: boolean;
  description?: string;
  image?: string;
  contentType?: string;
  twitter?: string;
  noCrawl?: boolean;
  published?: string;
  updated?: string;
  category?: string;
  tags?: string;
}

const getMetaTags = (
  {
    title,
    titleSuffix,
    description,
    image,
    contentType,
    twitter,
    noCrawl,
    published,
    updated,
    category,
    tags,
  }: MetaTags,
  pathname
) => {
  const theTitle = title
    ? titleSuffix
      ? title + defaultSep + defaultTitle
      : title
    : defaultTitle;
  const theDescription = description ? description : defaultDescription;
  const theImage = image ? image : defaultImage;

  const metaTags = [
    { itemprop: 'name', content: theTitle },
    { itemprop: 'description', content: theDescription },
    { itemprop: 'image', content: theImage },
    { name: 'description', content: theDescription },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: defaultTwitter },
    { name: 'twitter:title', content: theTitle },
    { name: 'twitter:description', content: theDescription },
    { name: 'twitter:creator', content: twitter || defaultTwitter },
    { name: 'twitter:image:src', content: theImage },
    { property: 'og:title', content: theTitle },
    { property: 'og:type', content: contentType || 'website' },
    { property: 'og:url', content: SITE_URL + pathname },
    {
      property: 'og:image',
      content: urlToImageServerUrl(theImage, 1200, 630),
    },
    { property: 'og:description', content: theDescription },
    { property: 'og:site_name', content: defaultTitle },
  ];

  if (noCrawl) {
    metaTags.push({ name: 'robots', content: 'noindex, nofollow' });
  }

  if (published) {
    metaTags.push({ name: 'article:published_time', content: published });
  }
  if (updated) {
    metaTags.push({ name: 'article:modified_time', content: updated });
  }
  if (category) {
    metaTags.push({ name: 'article:section', content: category });
  }
  if (tags) {
    metaTags.push({ name: 'article:tag', content: tags });
  }

  return metaTags;
};

interface Props extends MetaTags {
  id?: string;
  children: any;
}

const Page = ({ id, title, children, titleSuffix = true, ...rest }: Props) => {
  const location = useLocation();

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
          itemscope: undefined,
          itemtype: `http://schema.org/WebPage`,
        }}
        title={`
        ${DEBUG ? '(DEV)' + defaultSep : ''}
          ${
            title
              ? titleSuffix
                ? title + defaultSep + defaultTitle
                : title
              : defaultTitle
          }
        `}
        link={[
          {
            rel: 'canonical',
            href: SITE_URL + location.pathname,
          },
        ]}
        meta={getMetaTags(rest, location.pathname)}
      />
      {children}
    </>
  );
};

export default Page;
