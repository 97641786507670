import Link from 'components/link';
import React from 'react';

type Color = 'gray' | 'blue' | 'red' | 'yellow' | 'purple' | 'green' | 'orange';
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: any;
  to?: string;
  target?: string;
  color?: Color;
  size?: 'default' | 'large' | 'small';
  height?: 1 | 2 | 3;
  variant?: 'default' | 'outlined';
  text?: string;
}

const focus = (color: Color, opacity = 40, hue = 500) =>
  `focus:outline-none focus:ring focus:ring-opacity-${opacity} focus:ring-${color}-${hue}`;

const classes = {
  default_gray: `bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800 ${focus(
    'blue'
  )}`,
  default_blue: `bg-blue-200 text-blue-600 hover:bg-blue-300 hover:text-blue-800 ${focus(
    'blue',
    70,
    300
  )}`,
  default_purple: `bg-purple-200 text-purple-600 hover:bg-purple-300 hover:text-purple-700 ${focus(
    'purple',
    70,
    300
  )}`,
  default_red: `bg-red-200 text-red-600 hover:bg-red-300 hover:text-red-700 ${focus(
    'red'
  )}`,
  default_yellow: `bg-yellow-200 text-yellow-600 hover:bg-yellow-300 hover:text-yellow-600 ${focus(
    'yellow'
  )}`,
  default_green: `bg-green-100 text-green-600 hover:bg-green-300 hover:text-green-600 ${focus(
    'green'
  )}`,
  default_orange: `bg-orange-200 text-orange-600 hover:bg-orange-300 hover:text-orange-600 ${focus(
    'orange'
  )}`,
  disabled: `bg-gray-300 text-gray-500 `,
  outlined_blue: `bg-white text-blue-500 hover:bg-blue-100 hover:border-blue-300 hover:text-blue-500 ${focus(
    'blue',
    70,
    300
  )}`,
};

const baseRoot =
  'border-0 border-solid m-0 inline-flex relative items-center select-none align-middle justify-center no-underline';
const buttonRoot =
  'box-border transition-colors duration-200 ease-in-out font-semibold leading-7 font-body';

const Button: React.FC<ButtonProps> = ({
  to,
  color,
  size,
  icon,
  className,
  children,
  target,
  type,
  height,
  variant,
  text,
  ...rest
}) => {
  const isLink = safe(to) && !rest.disabled;
  let Component: any;
  let linkProps = {};

  if (isLink) {
    const isAbs = to.indexOf('http') > -1;
    const _target = target ? target : '_blank';
    if (isAbs) {
      Component = 'a';
      linkProps = {
        ...rest,
        href: to,
        target: _target,
        role: type,
      };
    } else {
      Component = Link;
      linkProps = {
        ...rest,
        to,
        target,
        role: type,
      };
    }
  } else {
    Component = 'button';
    linkProps = { ...rest, type };
  }
  const Icon = icon;

  const shadow = rest.disabled
    ? 'shadow-md'
    : height === 3
    ? 'shadow-md hover:shadow-lg'
    : height === 2
    ? 'shadow-sm hover:shadow-md'
    : 'hover:shadow-sm';
  const clsRound =
    size === 'default'
      ? 'rounded-2xl'
      : size === 'small'
      ? 'rounded-xl'
      : 'rounded-2xl';
  const clsColor = classes[rest.disabled ? 'disabled' : variant + '_' + color];
  const cursor = rest.disabled ? 'cursor-default' : 'cursor-pointer';
  return (
    <Component
      className={`${buttonRoot} ${baseRoot} ${clsRound} ${shadow} space-x-4 p-4 ${cursor} ${clsColor}${cls(
        className
      )}`}
      {...linkProps}
    >
      <span className="flex items-center text-2xl text-inherit ">
        {<Icon width={24} />}
      </span>
      {text && <span className="text-base">{text}</span>}
    </Component>
  );
};

Button.defaultProps = {
  type: 'button',
  size: 'default',
  color: 'gray',
  disabled: false,
  height: 3,
  variant: 'default',
};

export default Button;
