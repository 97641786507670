import Grid from '@mui/material/Grid';
import Button from 'components/button';
import Hero from 'components/hero';
import Link from 'components/link';
import Title, { TitleProps } from 'components/title';
import Page from 'containers/page';
import layout from 'layouts/common.module.scss';
import { AboutUsMeta as Meta } from 'metadata';
import { RouteProps } from 'models/route';
import React from 'react';
import { Element, Link as ScrollTo } from 'react-scroll';
import { SHOW_HELP } from 'utils/routing';
import style from './style.module.scss';

const TitleAttrs: Partial<TitleProps> = {
  align: 'left',
  as: 'h1',
  shade: '600',
  variant: 'title',
  height: 'none',
};

const Press = [
  {
    to: 'https://www.thestar.com/news/gta/2019/08/27/how-hotel-pools-could-be-used-to-trim-wait-lists-for-toronto-swimming-lessons.html',
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL21lZGlhLTEucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ==',
    title: 'Toronto Star Feature',
  },
  {
    to: 'https://startupheretoronto.com/type/profiles/taking-the-plunge-into-a-new-way-to-deliver-swim-lessons/',
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL21lZGlhLTIucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ==',
    title: 'StartUp Here Toronto',
  },
  {
    to: 'https://betakit.com/next-36-founders-learn-to-see-through-a-corporate-lens-at-mastercard-challenge/',
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL21lZGlhLTMucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ==',
    title: 'Betakit',
  },
  {
    to: 'http://www.vancouversun.com/hundreds+metro+vancouver+children+turned+away+from+swimming+lessons/8728264/story.html',
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL21lZGlhLTQucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ==',
    title: 'Vancouver Sun',
  },
];

interface Props extends RouteProps {
  children?: any;
}

const SVGFill = '#00AEFF';
const SVGContainer = ({ children }) => (
  <span className="block w-10 h-10">{children}</span>
);
const InstagramSVG = () => (
  <SVGContainer>
    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>Instagram icon</title>
      <path
        fill={SVGFill}
        d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
      />
    </svg>
  </SVGContainer>
);
const TwitterSVG = () => (
  <SVGContainer>
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>Twitter icon</title>
      <path
        fill={SVGFill}
        d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
      />
    </svg>
  </SVGContainer>
);
const FacebookSVG = () => (
  <SVGContainer>
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>Facebook icon</title>
      <path
        fill={SVGFill}
        d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
      />
    </svg>
  </SVGContainer>
);
const LinkedInSVG = () => (
  <SVGContainer>
    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>LinkedIn icon</title>
      <path
        fill={SVGFill}
        d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
      />
    </svg>
  </SVGContainer>
);

const AboutUsPage = () => {
  return (
    <Page {...Meta}>
      <div className={style.container}>
        <div style={{ position: 'relative' }}>
          <Hero
            background="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL2hlcm8tYmcuanBnIiwiYnVja2V0Ijoib2xla3NpYWsifQ=="
            title="Pushing aquatics forward"
            subtitle="On a mission to make private lessons a flexible and accessible option for everyone."
            backgroundPosition="24% 100%"
          >
            <div className={style.action}>
              <ScrollTo
                smooth={true}
                activeClass="active"
                to="scrollToContact"
                duration={500}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={style.btn}
                >
                  Connect with us
                </Button>
              </ScrollTo>
            </div>
          </Hero>
        </div>
        <div className={`${layout.body}`}>
          <div className={style.aboutUs}>
            <section className={style.text}>
              <h1>About Us</h1>
              <p>
                In 2015, we made it our mission to help the world learn how to
                swim by providing private lessons that are more affordable,
                accessible, flexible, and effective. Today, we build technology
                that helps swim instructors leverage their experience to better
                serve their communities as recreation entrepreneurs.
              </p>
              <p>
                We connect thousands of swimmers and instructors, while
                providing under-utilized pools for them to have lessons in,
                creating an entirely new personal swim instruction industry.
              </p>
              <hr />
              <h1>Our Story</h1>
              <p>
                As former lifeguards, co-founders Kiel Olver and Amie Nguyen
                each spent over ten years teaching public swimming lessons in
                their hometown of Vancouver, British Columbia. They learned that
                while being an instructor was fun and rewarding, being a
                learning swimmer was really frustrating; classes were packed,
                progress was slow, and waitlists were in the thousands, causing
                the process of registering for lessons to be more like buying
                concert tickets than signing up for neighborhood classes.
              </p>
              <p>
                After teaching, Amie worked in marketing with a sports tech
                startup. Kiel went to engineering school, where he met Sean
                Hudson, a fellow engineering student and experienced web
                developer. The three became fast friends, and they formed Propel
                with the mission to break the status quo in the aquatics
                industry.
              </p>
            </section>
            <div className={style.image}>
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL3Bob25lLXNjcmVlbnNob3QucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ==" />
            </div>
          </div>
        </div>
        <div className={style.problemToday}>
          <Hero
            background="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL3RoZS1wcm9ibGVtLWJnLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0="
            title=""
            height="full"
            fadeOpacity={20}
          >
            <div className={`${layout.white} ${layout.center}`}>
              <Title {...TitleAttrs} align="center" color="white">
                The Problem Today
              </Title>
              <p>
                The way people learn how to swim has fundamentally never
                changed. Swimmers spend most of their time fighting off the cold
                while waiting for their turn. With Propel, we asked ourselves,
                what if it was always their turn?
              </p>
            </div>
          </Hero>
        </div>
        <div className={`${layout.body} ${style.founders}`}>
          <div className={`${style.founder} ${layout.row} ${layout.flex}`}>
            <span className="m-4">
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL2ZvdW5kZXItYW1pZS5wbmciLCJidWNrZXQiOiJvbGVrc2lhayJ9" />
            </span>
            <span className={style.text}>
              <h3 className="mb-1">Amie Nguyen</h3>
              <h5 className="font-medium text-gray-700">Toronto, ON</h5>
              <p>
                Amie is the co-founder and CMO of Propel. She oversees Marketing
                & Community, Instructor Talent, and Venue Recruitment.
              </p>
              <hr />
              <span className={style.quote}>
                “My mission in life is to give everyone in the world the
                opportunity to learn and play sports because of how much it has
                impacted my life for the better.”
              </span>
            </span>
          </div>
          <div
            className={`${style.founder} ${layout.row} ${layout.flex} ${layout.reverse}`}
          >
            <span className="m-4">
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL2ZvdW5kZXItc2Vhbi5wbmciLCJidWNrZXQiOiJvbGVrc2lhayJ9" />
            </span>
            <span className={style.text}>
              <h3 className="mb-1">Sean Hudson</h3>
              <h5 className="font-medium text-gray-700">Squamish, BC</h5>
              <p>
                Sean is the co-founder and CTO of Propel. He leads Engineering,
                Payments, and User Experience.
              </p>
              <hr />
              <span className={style.quote}>
                “Nothing drives me crazier than a bad user experience. Building
                Propel allows me to make great solutions and hopefully help
                people live better lives.”
              </span>
            </span>
          </div>
          <div className={`${style.founder} ${layout.row} ${layout.flex}`}>
            <span className="m-4">
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL2ZvdW5kZXIta2llbC5wbmciLCJidWNrZXQiOiJvbGVrc2lhayJ9" />
            </span>
            <span className={style.text}>
              <h3 className="mb-1">Kiel Olver</h3>
              <h5 className="font-medium text-gray-700">Toronto, ON</h5>
              <p>
                Kiel is the co-founder and CEO of Propel. Kiel sets the
                company’s strategy and oversees Design, Communication, and
                Policy.
              </p>
              <hr />
              <span className={style.quote}>
                “I believe positive disruption should empower consumers, advance
                workers rights, and make better use of existing infrastructure
                – all at the same time.”
              </span>
            </span>
          </div>
        </div>
        <div className={layout.body}>
          <div className={`${layout.row} ${style.items}`}>
            <Grid container={true} spacing={2}>
              {Press.map((item, key) => (
                <Grid
                  key={key}
                  className={`${style.item}`}
                  item={true}
                  xs={12}
                  sm={3}
                >
                  <Link to={item.to}>
                    <img src={item.srcSm} />
                    <Title as="h5">{item.title}</Title>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <Element name="scrollToContact" />
        <div className={`${layout.body} ${style.contact}`}>
          <div
            className={`${layout.row} ${layout.flex} ${layout.alignTop} ${style.container}`}
          >
            <div className={style.content}>
              <Button
                onClick={SHOW_HELP.CONTACT}
                color="primary"
                variant="contained"
                size="large"
              >
                Connect with Us
              </Button>
            </div>
            <div className={style.content}>
              <ul className={style.follow}>
                <li className="">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.instagram.com/propelhq/"
                  >
                    <InstagramSVG />
                  </a>
                </li>
                <li className="">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://twitter.com/propel"
                  >
                    <TwitterSVG />
                  </a>
                </li>
                <li className="">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.facebook.com/propelhq/"
                  >
                    <FacebookSVG />
                  </a>
                </li>
                <li className="">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.linkedin.com/company/3003681/"
                  >
                    <LinkedInSVG />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default AboutUsPage;
