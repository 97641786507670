import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import PhoneIcon from '@mui/icons-material/PhoneCallback';
import { TimeFrame } from 'api/Serializers/Appointments';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { UserType } from 'config';
import { getReportTypeDescription } from 'features/assistance/as-instructor/utils';
import DialThrough from 'features/dial-through';
import { AssistanceWizardProps } from 'models/Assistance';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';

const allowCancellationMinutes = 5;

const getClosureResultKey = (getReportData) =>
  getReportData('type') === 'FACILITY_FOULING' &&
  getReportData('foulingCausedBy') === 'CLIENT'
    ? 'CLIENT_CAUSED_FOULING'
    : 'FACILITY_NOT_AVAILABLE';

const CallHostView = (props: AssistanceWizardProps) => {
  const { appointment, trackAction, addReportData, getReportData } = props;
  const history = useHistory();
  const callHistory = getReportData('callHistory') as string;
  const numCalls = getReportData('numCalls') as number;
  const [callAttempts, setAttempts] = useState(numCalls || 0);
  const canCancelAfter = moment(appointment.start).add(
    allowCancellationMinutes,
    'minutes'
  );
  const allowCancellation =
    callAttempts >= 3 || moment().isAfter(canCancelAfter);
  const handleCallPlaced = () => {
    const placedAt = moment().format('h:mm:ssa');
    if (numCalls) {
      addReportData({
        type: 'COUNT_CALL',
        key: 'numCalls',
        value: numCalls + 1,
      });
      addReportData({
        type: `PLACE_CALL`,
        key: `callHistory`,
        value: `${callHistory}\n- ${placedAt}`,
        title: `Call history with host`,
        description: `${callHistory}\n- ${placedAt}`,
      });
    } else {
      addReportData({
        type: 'COUNT_CALL',
        key: 'numCalls',
        value: 1,
      });
      addReportData({
        type: 'PLACE_CALL',
        key: 'callHistory',
        value: `- ${placedAt}`,
        title: 'Call history with host',
        description: `- ${placedAt}`,
      });
    }
    setAttempts(callAttempts + 1);
  };
  const handleCallSuccess = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_HELP_IS_COMING',
      title: 'How did that go?',
      description: 'Issue resolved, or help is on the way',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'NO_CHANGE',
    });
  };
  const handleFacilityClosure = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_WILL_NOT_OPEN',
      title: 'How did that go?',
      description:
        'After speaking with host, the pool is unlikely to open, so cancel the lesson',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: getClosureResultKey(props.getReportData),
    });
  };
  const handleHostUnreachable = () => {
    addReportData({
      type: `PLACE_CALL`,
      key: `callHistory`,
      value: `${callHistory}\n- I could not reach the host for help`,
      title: `Call history with host`,
      description: `${callHistory}\n- I could not reach the host for help`,
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_WILL_NOT_OPEN',
      title: 'How did that go?',
      description: 'I could not reach the host',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: getClosureResultKey(props.getReportData),
    });
  };
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>Call the host</h4>
      <DialThrough
        appointment={appointment}
        recipient={UserType.Host}
        onClose={history.goBack}
        onCallPlaced={handleCallPlaced}
        PostCallView={({ dialThroughState }) => (
          <>
            <div className="space-y-3">
              <ButtonLarge
                title="Issue resolved, or help is on the way"
                subtitle="The lesson can start soon"
                icon={<CheckIcon color="action" />}
                to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
                onClick={handleCallSuccess}
              />
              {!!dialThroughState.userProxyPhone && (
                <ButtonLarge
                  title="No answer or busy; retry the call"
                  subtitle="Continue calling the host to solve most issues quickly"
                  icon={<PhoneIcon color="action" />}
                  to={`tel:${dialThroughState.userProxyPhone}`}
                  onClick={handleCallPlaced}
                  disabled={moment().utc().isAfter(appointment.end)}
                />
              )}
              {appointment.timeFrame < TimeFrame.HasEnded && (
                <>
                  <ButtonLarge
                    title="I have spoken with the host and the pool will not open"
                    subtitle="Cancel the lesson"
                    icon={<CancelIcon className="text-gray-600" />}
                    to={SHARED_ROUTES.ASSISTANCE.nav(
                      appointment.id,
                      'facility/cancel'
                    )}
                    onClick={handleFacilityClosure}
                    disabled={appointment.timeFrame >= TimeFrame.HasEnded}
                  />
                  <ButtonLarge
                    title="I cannot reach the host"
                    subtitle={
                      !allowCancellation
                        ? `**Disabled until trying to call 3 or more times, or ${allowCancellationMinutes} minutes after the lesson start**`
                        : "If you're unable to reach the host, you may cancel the lesson"
                    }
                    icon={<CancelIcon className="text-gray-600" />}
                    disabled={!allowCancellation}
                    to={SHARED_ROUTES.ASSISTANCE.nav(
                      appointment.id,
                      'facility/cancel'
                    )}
                    onClick={handleHostUnreachable}
                  />
                </>
              )}
            </div>
            <Controls variant="block">
              <BackButton />
            </Controls>
          </>
        )}
      />
    </>
  );
};

const FacilityCallHostView = (props: AssistanceWizardProps) => {
  const { appointment, trackAction } = props;
  const { timeFrame } = appointment;
  const [showCallDialog, setShowCall] = useState(false);
  if (showCallDialog) {
    return <CallHostView {...props} />;
  }
  const canCallOrCancel =
    timeFrame > TimeFrame.Within48 && timeFrame < TimeFrame.Locked;
  const handleFacilityClosure = () => {
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_WILL_NOT_OPEN',
      title: 'Does the host know of the issue?',
      description:
        'I have spoken with the host and they have said the pool will not open, so cancel the lesson',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: getClosureResultKey(props.getReportData),
    });
  };
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>{getReportTypeDescription(props.state.type)}</h4>
      <Callout className="my-8" type="info">
        Get immediate assistance by calling the host. Explain the issue and
        confirm if they are able to assist you quickly.
      </Callout>
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="Call the host"
          subtitle="Quickly solve most maintenance issues by speaking directly with the host"
          icon={<PhoneIcon className="text-gray-600" color="inherit" />}
          disabled={!canCallOrCancel}
          onClick={() => setShowCall(true)}
        />
        <ButtonLarge
          title={
            timeFrame < TimeFrame.HasEnded
              ? 'I have spoken with the host and the pool will not open'
              : 'The pool was not available for all or part of this lesson'
          }
          subtitle="Cancel the lesson"
          icon={<CancelIcon className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'facility/cancel')}
          onClick={handleFacilityClosure}
          disabled={!canCallOrCancel}
        />
      </div>
      <Controls variant="block">
        <BackButton />
      </Controls>
    </>
  );
};

export default FacilityCallHostView;
