import { CreateReview } from 'api/Serializers/Reviews';
import axios from 'axios';
import { API_ROOT_URL, METHOD } from 'config';
import { Schema } from './schema';
import { AvailabilityFeedback } from './Serializers/AvailabilityFeedback';
import { PropelServiceSerializer } from './Serializers/Services';
import urls from './urls';

export enum StatusText {
  OK = 'OK',
}

const ROOT = `${API_ROOT_URL}/v1`;
const baseUrl = ROOT;

function getUrl(idOrAction: string, detailEndpoint: string) {
  if (!this.root) {
    throw new Error(
      'getURL() ERROR: Improperly configured API root value: root attribute value missing'
    );
  } else if (this.root[0] !== '/') {
    throw new Error(
      "getURL() ERROR: Improperly configured API root value: must start with '/'"
    );
  } else if (this.root.slice(-1) === '/') {
    throw new Error(
      "getURL() ERROR: Improperly configured API root value: must not end with '/'"
    );
  }
  let endpoint = '/';
  if (idOrAction && detailEndpoint) {
    if (idOrAction[0] === '/') {
      throw new Error('getURL() ERROR: idOrAction must not start with "/"');
    } else if (idOrAction.slice(-1) === '/') {
      throw new Error('getURL() ERROR: idOrAction must not end with "/"');
    }
    endpoint = `/${idOrAction}/${detailEndpoint}/`;
  } else if (idOrAction && !detailEndpoint) {
    endpoint = `/${idOrAction}/`;
  }
  return `${this.baseUrl}${this.root}${endpoint}`;
}

const api: Schema = {
  account: {
    root: ROOT + '/account',
    retrieve: async (username) =>
      axios({
        url: urls.account.root(username),
        method: METHOD.GET,
      }),
    update: async (username, data) =>
      axios({
        url: urls.account.root(username),
        method: METHOD.PUT,
        data,
      }),
    certifications: {
      create: async (username, data) =>
        axios({
          url: urls.account.certifications(username),
          method: data.id ? METHOD.PUT : METHOD.POST,
          data,
        }),
      retrieve: async (username) =>
        axios({
          url: urls.account.certifications(username),
          method: METHOD.GET,
        }),
      update: async (username, data) =>
        axios({
          url: urls.account.certifications(username),
          method: METHOD.PUT,
          data,
        }),
    },
    credit: async (username) =>
      axios({
        url: urls.account.credit(username),
        method: METHOD.GET,
      }),
    notices: async (username) =>
      axios({
        url: urls.account.notices(username),
        method: METHOD.GET,
      }),
    contacts: {
      create: async (username, data) =>
        axios({
          url: urls.account.contacts(username),
          method: METHOD.POST,
          data,
        }),
      update: async (username, data) =>
        axios({
          url: urls.account.contacts(username),
          method: METHOD.PUT,
          data,
        }),
      list: async (username) =>
        axios({
          url: urls.account.contacts(username),
          method: METHOD.GET,
        }),
      delete: async (username, data) =>
        axios({
          url: urls.account.contacts(username),
          method: METHOD.DELETE,
          data,
        }),
    },
    earlyAccessWindows: async (username) =>
      axios({
        url: urls.account.earlyAccessWindows(username),
        method: METHOD.GET,
      }),
    instructors: async (username) =>
      axios({
        url: urls.account.instructors(username),
        method: METHOD.GET,
      }),
    reviews: async (instructor) =>
      axios({
        url: urls.account.reviews(instructor),
        method: METHOD.GET,
      }),
    overview: async (instructor) =>
      axios({
        url: urls.account.overview(instructor),
        method: METHOD.GET,
      }),
    preferredTimes: {
      list: async (username) =>
        axios({
          url: urls.account.preferredTimes(username),
          method: METHOD.GET,
        }),
      update: async (username, data) =>
        axios({
          url: urls.account.preferredTimes(username),
          method: METHOD.PUT,
          data,
        }),
    },
    preferences: async (username, data) =>
      axios({
        url: urls.account.preferences(username),
        method: data === undefined ? METHOD.GET : METHOD.POST,
        data,
      }),
    support: {
      get: async (params) =>
        axios({
          url: urls.account.support,
          method: METHOD.GET,
          params,
        }),
      post: async (data) =>
        axios({
          url: urls.account.support,
          method: METHOD.POST,
          data,
        }),
    },
    onboarding: async (username, data) =>
      axios({
        url: urls.account.onboarding(username),
        method: data ? METHOD.PUT : METHOD.GET,
        data,
      }),
    reliability: async (username) =>
      axios({
        url: urls.account.reliability(username),
        method: METHOD.GET,
      }),
    earnings: async (username) =>
      axios({
        url: urls.account.earnings(username),
        method: METHOD.GET,
      }),
  },
  activities: {
    root: ROOT + '/activities',
    retrieve: async (activity) =>
      axios({
        url: urls.activities.detail(activity),
        method: METHOD.GET,
        headers: {
          Authorization: undefined,
        },
      }),
  },
  analytics: {
    root: ROOT + '/analytics',
    kpis: async (params, signal?) =>
      axios({
        url: urls.analytics.kpi,
        method: METHOD.GET,
        params,
        signal,
      }),
    dailySales: async (params) =>
      axios({
        url: urls.analytics.dailySales,
        method: METHOD.GET,
        params,
      }),
  },
  appointmentFeedback: {
    root: ROOT + '/appointment-feedback',
    create: async (data) =>
      axios({
        url: urls.appointmentFeedback.create,
        method: METHOD.POST,
        data,
      }),
  },
  appointmentProducts: {
    root: ROOT + '/appointment-products',
    list: async (params) =>
      axios({
        url: urls.appointmentProducts.root,
        method: METHOD.GET,
        params,
      }),
    search: async (params) =>
      axios({
        url: urls.appointmentProducts.search,
        method: METHOD.GET,
        params,
      }),
    retrieve: async (id) =>
      axios({
        url: urls.appointmentProducts.retrieve(id),
        method: METHOD.GET,
      }),
    delete: async (id) =>
      axios({
        url: urls.appointmentProducts.retrieve(id),
        method: METHOD.DELETE,
      }),
    create: async (data) =>
      axios({
        url: urls.appointmentProducts.root,
        method: METHOD.POST,
        data,
      }),
  },
  appointments: {
    root: ROOT + '/appointments',
    list: async (params, abortController) =>
      axios({
        url: urls.appointments.list,
        method: METHOD.GET,
        params,
        signal: abortController?.signal,
      }),
    retrieve: async (id) =>
      axios({
        url: urls.appointments.retrieve(id),
        method: METHOD.GET,
      }),
    partialUpdate: async (id, data) =>
      axios({
        url: urls.appointments.retrieve(id),
        method: METHOD.PATCH,
        data,
      }),
    cancel: async (id, data) =>
      axios({
        url: urls.appointments.cancel(id),
        method: METHOD.PUT,
        data,
      }),
    feedbackStage: async (id) =>
      axios({
        url: urls.appointments.feedbackStage(id),
        method: METHOD.GET,
      }),
    receipt: async (id) =>
      axios({
        url: urls.appointments.receipt(id),
        method: METHOD.GET,
      }),
    googleReview: async (id) =>
      axios({
        url: urls.appointments.googleReview(id),
        method: METHOD.PUT,
      }),
    proxySessions: async (id, params, data = undefined, method = METHOD.GET) =>
      axios({
        url: urls.appointments.proxySessions(id),
        method,
        params,
        data,
      }),
    paymentMethod: async (id, data) =>
      axios({
        url: urls.appointments.paymentMethod(id),
        method: METHOD.PUT,
        data,
      }),
  },
  auth: {
    root: API_ROOT_URL + '/auth',
    refresh: async (refresh) =>
      axios({
        url: urls.auth.refresh,
        method: METHOD.POST,
        data: { refresh },
      }),
    verify: async (token) =>
      axios({
        url: urls.auth.verify,
        method: METHOD.POST,
        data: { token },
      }),
    token: async (credentials) =>
      axios({
        url: urls.auth.token,
        method: METHOD.POST,
        data: credentials,
      }),
    proxyToken: async (credentials) =>
      axios({
        url: urls.auth.proxyToken,
        method: METHOD.POST,
        data: credentials,
      }),
    forgotPassword: async (credentials) =>
      axios({
        url: urls.auth.passwordForgot,
        method: METHOD.POST,
        data: credentials,
      }),
    changePassword: async (credentials, uid) =>
      axios({
        url: urls.auth.passwordReset(uid),
        method: METHOD.POST,
        data: credentials,
      }),
    verifyResetToken: async (uid) =>
      axios({
        url: urls.auth.passwordReset(uid),
        method: METHOD.GET,
      }),
    messaging: async () =>
      axios({ url: urls.auth.messaging, method: METHOD.GET }),
  },
  availability: {
    root: ROOT + '/availability',
    list: async (params, abortController) =>
      axios({
        url: urls.availability.list,
        method: METHOD.GET,
        params,
        signal: abortController?.signal,
      }),
    retrieve: async (id) =>
      axios({
        url: urls.availability.retrieve(id),
        method: METHOD.GET,
      }),
  },
  availabilityFeedback: {
    root: ROOT + '/availability-feedback',
    update: async (uid: string, data: AvailabilityFeedback) =>
      axios({
        url: urls.availabilityFeedback.update(uid),
        method: METHOD.PUT,
        data,
      }),
  },
  cancellations: {
    root: ROOT + '/cancellations',
    waive: async (id: string) =>
      axios({ url: urls.cancellations.waive(id), method: METHOD.PATCH }),
  },
  cart: {
    root: ROOT + '/cart',
    list: async function () {
      return axios({
        url: this.root + '/',
      });
    },
    create: async (data) =>
      axios({
        url: urls.cart.list,
        method: METHOD.POST,
        data,
      }),
    checkout: async (id, data) =>
      axios({
        url: urls.cart.checkout(id),
        method: METHOD.PUT,
        data,
      }),
  },
  clients: {
    root: ROOT + '/clients',
    retrieve: async (username) =>
      axios({
        url: `${urls.clients.list}${username}/`,
        method: METHOD.GET,
      }),
    list: async (params) =>
      axios({
        url: urls.clients.list,
        method: METHOD.GET,
        params,
      }),
    participants: async (username, data) =>
      axios({
        url: urls.clients.participants(username),
        method: data ? METHOD.POST : METHOD.GET,
        data,
      }),
  },
  conversations: {
    root: ROOT + '/conversations',
    list: async () =>
      axios({ url: urls.conversations.root, method: METHOD.GET }),
    create: async (data: { id: number }) =>
      axios({ url: urls.conversations.root, method: METHOD.POST, data }),
    messages: async (sid: string) =>
      axios({ url: urls.conversations.messages(sid), method: METHOD.GET }),
  },
  facilities: {
    root: ROOT + '/facilities',
    detail: async (slug) =>
      axios({
        url: urls.facilities.detail(slug),
        method: METHOD.GET,
      }),
    list: async (params) =>
      axios({
        url: urls.facilities.list,
        method: METHOD.GET,
        params,
      }),
    limiters: async (slug, params) =>
      axios({
        url: urls.facilities.limiters(slug),
        method: METHOD.GET,
        params,
      }),
    schedules: async (slug, params) =>
      axios({
        url: urls.facilities.schedules(slug),
        method: METHOD.GET,
        params,
      }),
    schedulable: async (params?, cancelToken?) =>
      axios({
        url: urls.facilities.schedulable,
        method: METHOD.GET,
        params,
        cancelToken,
      }),
    googleReviews: async (slug, params) =>
      axios({
        url: urls.facilities.googleReviews(slug),
        method: METHOD.GET,
        params,
      }),
  },
  favourites: {
    root: ROOT + '/favourites',
    list: async () => axios({ url: urls.favourites.root, method: METHOD.GET }),
    create: async (data) =>
      axios({
        url: urls.favourites.root,
        method: METHOD.POST,
        data,
      }),
    delete: async (naturalKey) =>
      axios({
        url: urls.favourites.detail(naturalKey),
        method: METHOD.DELETE,
      }),
  },
  giftCards: {
    root: ROOT + '/gift-cards',
    create: async (data) =>
      axios({ url: urls.giftCards.root, method: METHOD.POST, data }),
    checkout: async (id) =>
      axios({ url: urls.giftCards.checkout(id), method: METHOD.PUT }),
    redeem: async (data) =>
      axios({
        url: urls.giftCards.redeem,
        method: METHOD.PUT,
        data,
      }),
  },
  instructors: {
    root: ROOT + '/instructors',
    retrieve: async (username, params) =>
      axios({
        url: urls.instructors.retrieve(username),
        method: METHOD.GET,
        params,
      }),
    facilities: async (username) =>
      axios({
        url: urls.instructors.facilities(username),
        method: METHOD.GET,
      }),
    reviews: async (username) =>
      axios({
        url: urls.instructors.reviews(username),
        method: METHOD.GET,
      }),
    list: async (params) =>
      axios({
        url: urls.instructors.list,
        method: METHOD.GET,
        params,
      }),
    experience: async () =>
      axios({
        url: urls.instructors.experience,
        method: METHOD.GET,
      }),
  },
  media: {
    root: ROOT + '/media',
    delete: async (mediaId) =>
      axios({
        url: urls.medias.retrieve(mediaId),
        method: METHOD.DELETE,
      }),
  },
  notes: {
    root: ROOT + '/notes',
    list: async (username) =>
      axios({
        url: urls.notes.list(username),
        method: METHOD.GET,
      }),
    create: async (username: string, data: { note: string }) =>
      axios({
        url: urls.notes.list(username),
        method: METHOD.POST,
        data,
      }),
    patch: async (id: number, data: { note: string }) =>
      axios({
        url: urls.notes.detail(id),
        method: METHOD.PATCH,
        data,
      }),
    delete: async (id: number) =>
      axios({
        url: urls.notes.detail(id),
        method: METHOD.DELETE,
      }),
  },
  paymentMethods: {
    root: ROOT + '/payments',
    create: async (data) =>
      axios({ url: urls.paymentMethods.root, method: METHOD.POST, data }),
    delete: async (id: string) =>
      axios({ url: urls.paymentMethods.detail(id), method: METHOD.DELETE }),
    default: async (id: string) =>
      axios({ url: urls.paymentMethods.default(id), method: METHOD.PUT }),
    setup: async () =>
      axios({ url: urls.paymentMethods.setup, method: METHOD.POST }),
  },
  payouts: {
    root: ROOT + '/payouts',
    list: async () =>
      axios({
        url: urls.payouts.list,
        method: METHOD.GET,
      }),
    retrieve: async (id: string) =>
      axios({
        url: urls.payouts.retrieve(id),
        method: METHOD.GET,
      }),
    instructors: async (id: string) =>
      axios({
        url: urls.payouts.instructors(id),
        method: METHOD.GET,
      }),
    details: async (id: string, params: { invoice: string }) =>
      axios({
        url: urls.payouts.details(id),
        method: METHOD.GET,
        params,
      }),
    paid: async (id, data) =>
      axios({
        url: urls.payouts.paid(id),
        method: METHOD.PUT,
        data,
      }),
    monthly: async () =>
      axios({
        url: urls.payouts.monthly,
        method: METHOD.GET,
      }),
    byYear: async (year) =>
      axios({
        url: urls.payouts.byYear(year),
        method: METHOD.GET,
      }),
    summary: async (params) =>
      axios({
        url: urls.payouts.summary,
        method: METHOD.GET,
        params,
      }),
  },
  prices: {
    root: ROOT + '/prices',
    list: (params) =>
      axios({
        url: urls.prices.list,
        method: METHOD.GET,
        params,
      }),
    retrieve: (id) =>
      axios({
        url: urls.prices.detail(id),
        method: METHOD.GET,
      }),
    create: (data) =>
      axios({
        url: urls.prices.list,
        method: METHOD.POST,
        data,
      }),
    delete: (id) =>
      axios({
        url: urls.prices.detail(id),
        method: METHOD.DELETE,
      }),
  },
  promotions: {
    baseUrl,
    root: '/promotions',
    getUrl,
    signUp: async function (data) {
      return axios({
        url: this.getUrl('sign-up'),
        method: METHOD.PUT,
        data,
      });
    },
  },
  proposals: {
    root: ROOT + '/proposals',
    list: async (params, abortController) =>
      axios({
        url: urls.proposals.root,
        method: METHOD.GET,
        params,
        signal: abortController?.signal,
      }),
    retrieve: async (id) =>
      axios({
        url: urls.proposals.retrieve(id),
        method: METHOD.GET,
      }),
    create: async (data) =>
      axios({
        url: urls.proposals.root,
        method: METHOD.POST,
        data,
      }),
    cancel: async (id) =>
      axios({
        url: urls.proposals.cancel(id),
        method: METHOD.PUT,
      }),
    archive: async (id) =>
      axios({
        url: urls.proposals.archive(id),
        method: METHOD.PUT,
      }),
  },
  regions: {
    root: ROOT + '/regions',
    retrieve: async (slug) =>
      axios({
        url: urls.regions.retrieve(slug),
        method: METHOD.GET,
      }),
  },
  reports: {
    root: ROOT + '/reports',
    create: async (data) =>
      axios({
        url: urls.reports.list,
        data,
        method: METHOD.POST,
      }),
  },
  reviews: {
    root: ROOT + '/reviews',
    list: async () =>
      axios({
        url: urls.reviews.list,
        method: METHOD.GET,
      }),
    create: async (data: CreateReview) =>
      axios({
        url: urls.reviews.list,
        method: METHOD.POST,
        data,
      }),
    update: async ({ id, ...data }: CreateReview) =>
      axios({
        url: urls.reviews.detail(id),
        method: METHOD.PUT,
        data,
      }),
    ratings: async () =>
      axios({ url: urls.reviews.ratings, method: METHOD.GET }),
  },
  schedules: {
    root: ROOT + '/schedules',
    dates: async (username, params) =>
      axios({
        url: urls.schedule.dates(username),
        params,
      }),
    openings: async (params, abortController) =>
      axios({
        url: urls.schedule.openings,
        params,
        signal: abortController?.signal,
      }),
    times: async (username, params, signal) =>
      axios({
        url: urls.schedule.times(username),
        params,
        signal,
      }),
    put: async (username, data) =>
      axios({
        url: urls.schedule.retrieve(username),
        method: METHOD.PUT,
        data,
      }),
    weekly: {
      create: async (username, data) =>
        axios({
          url: urls.schedule.weekly(username),
          method: METHOD.POST,
          data,
        }),
    },
  },
  services: {
    baseUrl,
    root: '/services',
    getUrl,
    list: async function () {
      return axios<PropelServiceSerializer[]>({
        url: this.getUrl(),
      });
    },
  },
  termsOfService: {
    root: ROOT + '/terms-of-service',
    retrieve: async (audience) =>
      axios({
        url: urls.termsOfService.retrieve(audience),
        method: METHOD.GET,
      }),
  },
  users: {
    root: ROOT + '/users',
    activate: {
      verify: async (key) =>
        axios({
          url: urls.users.activate,
          params: { activation_key: key },
          method: METHOD.PUT,
        }),
      resend: async () =>
        axios({
          url: urls.users.activate,
          method: METHOD.POST,
        }),
    },
    retrieve: async (id) =>
      axios({
        url: urls.users.retrieve(id),
        method: METHOD.GET,
      }),
    create: async (data) =>
      axios({
        url: urls.users.root,
        data,
        method: METHOD.POST,
      }),
    usernameAvailable: async (username: string) =>
      axios({
        url: urls.users.usernameAvailable,
        method: METHOD.GET,
        params: {
          username,
        },
      }),
  },
  utils: {
    root: ROOT + '/utils',
    version: async () =>
      axios({
        url: urls.utils.version,
        method: METHOD.GET,
      }),
    waitlist: {
      create: async (data) =>
        axios({
          url: urls.utils.waitlist,
          method: METHOD.POST,
          data,
        }),
      get: async (params) =>
        axios({
          url: urls.utils.waitlist,
          method: METHOD.GET,
          params,
        }),
    },
  },
};

export default api;
