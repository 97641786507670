import api from 'api';
import { HTTP_201_CREATED } from 'api/status';
import Button from 'components/button';
import { FETCH_STATE } from 'config';
import useForm from 'hooks/useForm';
import { DoneIcon } from 'icons';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail } from 'state/selectors';
import { validateEmail } from 'utils/string';

type EmailMarketingForm = {
  email: string;
};
const EmailMarketingSignUp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetchState, setFetchState] = useState<FETCH_STATE>(
    FETCH_STATE.PRISTINE
  );
  const [hasError, setHasError] = useState(false);
  const account = useSelector(getAccountDetail);
  const handleFormCallback = async (inputs: EmailMarketingForm) => {
    const error = validateEmail(inputs.email);
    if (error !== '') {
      setHasError(true);
      enqueueSnackbar({
        message: `${error}. Please enter a valid email.`,
        variant: 'error',
      });
    } else {
      try {
        setHasError(false);
        setFetchState(FETCH_STATE.PUT);
        const data = {
          email: inputs.email,
          promotion: '2024-email-sign-up',
        };
        const response = await api.promotions.signUp(data);
        setFetchState(FETCH_STATE.FULFILLED);
        const message =
          response.status === HTTP_201_CREATED
            ? 'Sign up successful'
            : 'Already signed up';
        const variant =
          response.status === HTTP_201_CREATED ? 'success' : 'info';
        enqueueSnackbar({
          message,
          variant,
        });
      } catch (error) {
        setFetchState(FETCH_STATE.PRISTINE);
        enqueueSnackbar({
          message: `There was a problem saving your entry. Please try again.`,
          variant: 'error',
        });
      }
    }
  };
  const { inputs, handleInputChange, handleSubmit } =
    useForm<EmailMarketingForm>(
      {
        email: account ? account.email : '',
      },
      handleFormCallback
    );
  return (
    <div className="flex flex-col items-center">
      <div className="space-y-4">
        <h2 className="title">Get poolside perks</h2>
        <h3 className="subtitle">
          Dive in and get early access to new features and secret promos
        </h3>
      </div>
      <ul className="mt-8 ml-4 space-y-3">
        <li className="flex items-center space-x-2">
          <DoneIcon className="text-green-500" width={24} />
          <span>Unlock beta test offers and gift card giveaways</span>
        </li>
        <li className="flex items-center space-x-2">
          <DoneIcon className="text-green-500" width={24} />
          <span>Get alerts about upcoming lesson availability</span>
        </li>
        <li className="flex items-center space-x-2">
          <DoneIcon className="text-green-500" width={24} />
          <span>Earn free lessons for trying new instructors</span>
        </li>
      </ul>
      <div className="w-full max-w-lg p-6 mt-10 shadow-md sm:min-w-sm sm:w-auto bg-background rounded-xl">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <h4>Yes! Send me email updates:</h4>
          <input
            type="email"
            name="email"
            placeholder="hello@example.com"
            className={`${hasError ? 'input-error' : ''}`}
            value={inputs.email}
            onChange={handleInputChange}
            disabled={fetchState !== FETCH_STATE.PRISTINE}
          />
          {fetchState === FETCH_STATE.FULFILLED && (
            <div className="flex items-center space-x-2">
              <DoneIcon className="text-green-500" width={24} />
              Okay, we've got your submission!
            </div>
          )}
          <div className="flex justify-end">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              isLoading={fetchState === FETCH_STATE.PUT}
              disabled={fetchState !== FETCH_STATE.PRISTINE}
            >
              {fetchState === FETCH_STATE.PRISTINE ? 'Sign up' : 'Signed up'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailMarketingSignUp;
