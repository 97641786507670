import {
  AppointmentProduct,
  AppointmentProductStatus,
} from 'api/Serializers/AppointmentProducts';
import { FacilityStatus } from 'api/Serializers/Facilities';
import Avatar from 'components/avatar';
import ListButton from 'components/button/list';
import Callout from 'components/callout';
import Loading from 'components/loading';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getAppointmentProductsFetchState,
  getScheduleAppointmentProduct,
  getScheduleAppointmentProducts,
} from 'state/selectors';

interface Props {
  onSelect(appointmentProduct: AppointmentProduct): void;
}

export const SelectAppointmentProduct = ({ onSelect }: Props) => {
  const appointmentProduct = useSelector(getScheduleAppointmentProduct);
  const appointmentProducts = useSelector(getScheduleAppointmentProducts);
  const appointmentProductFetchState = useSelector(
    getAppointmentProductsFetchState
  );
  const active = appointmentProducts.filter(
    (aptProd) =>
      aptProd.facility.status === FacilityStatus.Active &&
      aptProd.status === AppointmentProductStatus.Active
  );

  useEffect(() => {
    if (appointmentProductFetchState === 'FULFILLED') {
      const active = appointmentProducts.filter(
        (aptProd) =>
          aptProd.facility.status === FacilityStatus.Active &&
          aptProd.status === AppointmentProductStatus.Active
      );
      if (active.length === 1) {
        onSelect(active[0]);
      }
    }
  }, [appointmentProductFetchState]);

  if (appointmentProductFetchState !== 'FULFILLED') {
    return <Loading />;
  }
  return (
    <div className="space-y-6">
      <Callout type="question">
        Which location would you like to change your schedule for?
      </Callout>
      <div className="space-y-8">
        <div className="">
          {active.map((aptProd) => {
            const facility = aptProd.facility;
            return (
              <ListButton
                key={aptProd.id}
                onClick={() => onSelect(aptProd)}
                icon={
                  <Avatar src={facility.avatar} diameter={10} border={true} />
                }
                title={facility.displayName}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
