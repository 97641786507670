import { Status } from 'api/Serializers/Accounts';
import { InstructorOnboardingStage } from 'api/Serializers/Accounts/Instructor';
import { InstructorListSerializer } from 'api/Serializers/Instructors';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';

export interface Column {
  slug: string;
  title: string;
  filter(instructors: InstructorListSerializer): boolean;
  defaultSorting(
    a: InstructorListSerializer,
    b: InstructorListSerializer
  ): number;
  sorting?: Array<{
    title: string;
    func(a: InstructorListSerializer, b: InstructorListSerializer): number;
  }>;
}

export const isUpcomingOrientation = (i: InstructorListSerializer) =>
  i.onboarding.stage === InstructorOnboardingStage.Orientation &&
  moment(i.onboarding.callDatetime).isSameOrAfter(moment(), 'day');

export const hasNoFirstLesson = (i: InstructorListSerializer) =>
  i.status === Status.Active &&
  i.onboarding.stage === InstructorOnboardingStage.GetBooked;

export const isChurned = (i: InstructorListSerializer) =>
  i.status === Status.Inactive &&
  i.onboarding.stage === InstructorOnboardingStage.GetBooked;

export const isActive = (i: InstructorListSerializer) =>
  i.status === Status.Active &&
  i.onboarding.stage === InstructorOnboardingStage.Complete;

const byCancellationRate = (
  a: InstructorListSerializer,
  b: InstructorListSerializer
) => b.cancellationRate - a.cancellationRate;

const byName = (a: InstructorListSerializer, b: InstructorListSerializer) =>
  a.fullName < b.fullName ? -1 : 1;

const byDateLive = (a: InstructorListSerializer, b: InstructorListSerializer) =>
  a.liveSince < b.liveSince ? -1 : 1;

const byCallDate = (a: InstructorListSerializer, b: InstructorListSerializer) =>
  a.onboarding.callDatetime < b.onboarding.callDatetime ? -1 : 1;

const byLessonsTaught = (
  a: InstructorListSerializer,
  b: InstructorListSerializer
) => (a.numAppointments > b.numAppointments ? -1 : 1);

const byNumClients = (
  a: InstructorListSerializer,
  b: InstructorListSerializer
) => (a.numClients > b.numClients ? -1 : 1);

const byCallTime = (a, b) =>
  a.onboarding.callDatetime < b.onboarding.callDatetime ? -1 : 1;

export const baseColumns: Column[] = [
  {
    slug: 'active',
    title: 'Active',
    filter: isActive,
    defaultSorting: byName,
    sorting: [
      {
        title: 'By name',
        func: byName,
      },
      {
        title: 'By lessons taught',
        func: byLessonsTaught,
      },
      {
        title: 'By clients',
        func: byNumClients,
      },
      {
        title: 'By cancellation rate',
        func: byCancellationRate,
      },
    ],
  },
  {
    slug: 'no-first-lesson',
    title: 'No First Lesson',
    filter: hasNoFirstLesson,
    defaultSorting: byDateLive,
    sorting: [
      {
        title: 'By name',
        func: byName,
      },
      {
        title: 'By date live',
        func: byDateLive,
      },
    ],
  },
  {
    slug: 'never-booked',
    title: 'Never Booked',
    filter: isChurned,
    defaultSorting: byDateLive,
    sorting: [
      {
        title: 'By name',
        func: byName,
      },
      {
        title: 'By date live',
        func: byDateLive,
      },
    ],
  },
  {
    slug: 'orientation',
    title: 'Upcoming Orientations',
    filter: isUpcomingOrientation,
    defaultSorting: byCallTime,
    sorting: [
      {
        title: 'By name',
        func: byName,
      },
      {
        title: 'By call date',
        func: byCallDate,
      },
    ],
  },
];

export const debouncedSearch = debounce(function (
  search: string,
  instructors: InstructorListSerializer[],
  onSearch: (results: InstructorListSerializer[]) => void
) {
  let results: InstructorListSerializer[];
  const _search = search.toLowerCase();
  if (search === '') {
    results = [];
  } else if (search.length > 0) {
    // Find first
    results = [...instructors]
      .filter((i) => i.fullName.toLowerCase().indexOf(_search) > -1)
      .sort((a, b) => {
        const ai = a.fullName.toLowerCase().indexOf(_search);
        const bi = b.fullName.toLowerCase().indexOf(_search);
        return ai === bi
          ? a.fullName.toLowerCase() < b.fullName.toLowerCase()
            ? -1
            : 1
          : ai < bi
          ? -1
          : 1;
      });
  }
  onSearch(results);
},
800);
