import api from 'api';
import { AxiosError } from 'axios';
import Button from 'components/button';
import Callout from 'components/callout';
import Controls from 'components/controls';
import CreditCardForm from 'components/credit-card-form';
import Label from 'components/input-label';
import Loading from 'components/loading';
import { DATE_FMT, QueryParams } from 'config';
import Page from 'containers/page';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import { GiftCardsMeta as Meta } from 'metadata';
import { RouteParams } from 'models/route';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { CLIENT_ROUTES } from 'pages/account/client/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { RequireAuth } from 'Routes';
import { getAccountDetail, getAccountIsLoaded } from 'state/selectors';
import { logUserOut } from 'state/slice/authentication';
import { APP_ROUTES } from 'utils/routing';
import { validateEmail } from 'utils/string';

const SELECTABLE_AMOUNTS = ['20', '50', '100', '200'];
const CARD_IMAGES = ['snorkel', 'surf', 'drinks', 'beach', 'cruise', 'plain'];
const VALID_PIN_CHARS = '0-9ABCDEFGHJKLMNPQRSTUVWXYZ';

interface CheckoutFormData {
  amount: string;
  image: string;
  recipientName: string;
  recipientEmail: string;
  message: string;
  senderName: string;
  senderEmail: string;
  sendDate: string;
}

interface RedeemFormData {
  id: string;
  pin: string;
}

const initialCheckoutFormData: CheckoutFormData = {
  amount: '',
  image: CARD_IMAGES[5],
  recipientName: '',
  recipientEmail: '',
  message: '',
  senderName: '',
  senderEmail: '',
  sendDate: '',
};

const getGiftCardUrl = (name: string) =>
  `https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/gift-cards/gc-${name}.png`;

const validateCheckoutFormData = (
  dataToValidate: CheckoutFormData
): [CheckoutFormData, boolean] => {
  const errors = { ...initialCheckoutFormData, image: '' };
  let hasErrors = false;
  if (
    dataToValidate.amount === '' ||
    isNaN(dataToValidate.amount as any) ||
    dataToValidate.amount.indexOf('.') !== -1 ||
    parseInt(dataToValidate.amount) < 10 ||
    parseInt(dataToValidate.amount) > 1000
  ) {
    errors.amount = 'Amount must be a whole number from 10 to 1000';
  }
  if (dataToValidate.image === undefined) {
    errors.image = 'Must choose a gift card image';
  }
  if (!dataToValidate.recipientName) {
    errors.recipientName = 'Recipient name cannot be blank';
  }
  if (!dataToValidate.senderName) {
    errors.senderName = 'Sender name cannot be blank';
  }
  if (dataToValidate.sendDate) {
    const dateObj = moment(dataToValidate.sendDate, DATE_FMT.DATE_KEY, true);
    if (
      dateObj.isBefore(moment().endOf('day')) ||
      dateObj.isAfter(moment().add(6, 'months'))
    ) {
      errors.sendDate =
        'Send date must be between tomorrow and six months from now';
    }
  }
  errors.recipientEmail = validateEmail(dataToValidate.recipientEmail);
  errors.senderEmail = validateEmail(dataToValidate.senderEmail);
  for (let v of Object.values(errors)) {
    if (v) {
      hasErrors = true;
      break;
    }
  }
  return [errors, hasErrors];
};

const FormInputWrapper = ({
  name,
  label = '',
  error = '',
  children,
}: {
  name: string;
  label?: string;
  error?: string;
  children: any;
}) => (
  <div>
    {label && (
      <Label htmlFor={name} className={error ? 'text-red-600' : ''}>
        {label}
      </Label>
    )}
    {children}
    {error && <div className="text-red-600">{error}</div>}
  </div>
);

const GiftCardImages = ({
  selected = '',
  onClick,
}: {
  selected?: string;
  onClick(cardImage: string): void;
}) => (
  <div className="flex flex-wrap justify-center gap-2">
    {CARD_IMAGES.map((cardImage) => (
      <div
        className="flex-auto basis-40 sm:basis-60 hover:cursor-pointer"
        key={'selectable-img-' + cardImage}
      >
        <img
          className={
            selected === cardImage ? 'brightness-50' : 'hover:brightness-50'
          }
          src={getGiftCardUrl(cardImage)}
          alt={`${cardImage.capitalize()} Gift Card Image`}
          onClick={() => onClick(cardImage)}
        />
      </div>
    ))}
  </div>
);

export const GiftCards = () => (
  <Page {...Meta}>
    <div className="bg-background sm:min-h-90vh">
      <Switch>
        <Route exact path={APP_ROUTES.GIFT_CARDS.ROOT}>
          <Home />
        </Route>
        <Route exact path={APP_ROUTES.GIFT_CARDS.BUY.PATH}>
          <Form />
        </Route>
        <Route exact path={APP_ROUTES.GIFT_CARDS.REDEEM}>
          <RequireAuth>
            <Redeem />
          </RequireAuth>
        </Route>
      </Switch>
    </div>
  </Page>
);

const Home = () => {
  const history = useHistory();
  useEffect(() => {
    rudderanalytics.track('Gift Card Checkout started');
  }, []);
  return (
    <>
      <div className="w-full p-4 bg-white min-h-90vh">
        <div className="max-w-4xl m-auto">
          <div className="flex justify-center">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/logos/flat-blue.svg"
              alt=""
            />
          </div>
          <h1 className="text-4xl font-medium text-center sm:text-6xl">
            Propel gift cards
          </h1>
          <img
            className="h-[325px] m-auto object-cover sm:h-[600px]"
            src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/gift-cards/gift-card-cover.png"
          />
          <h2 className="my-3 text-lg font-medium text-center sm:text-2xl">
            Unlock water adventure forever
          </h2>
          <p className="my-3 text-center">
            Give a lifetime of aquatic memories with life skills, confidence,
            and gift cards that never expire.
          </p>
          <div className="my-8">
            <GiftCardImages
              onClick={(cardImg) =>
                history.push(
                  `${APP_ROUTES.GIFT_CARDS.BUY.nav(
                    'info'
                  )}?giftCardImg=${cardImg}`
                )
              }
            />
          </div>
          <div className="justify-center hidden gap-16 sm:flex">
            <Button
              to={APP_ROUTES.GIFT_CARDS.REDEEM}
              size="large"
              variant="flat"
            >
              Redeem
            </Button>
            <Button
              to={APP_ROUTES.GIFT_CARDS.BUY.nav('info')}
              variant="flat"
              size="large"
              color="primary"
            >
              Buy Gift Card
            </Button>
          </div>
          <h2 className="my-6 font-medium text-center">
            Frequently Asked Questions
          </h2>
          <div className="my-3">
            <h5>Are gift cards physical or digital?</h5>
            <p>
              Gift cards bought on Propelhq.com are digital only. At this time
              we do not offer physical cards.
            </p>
          </div>
          <div className="my-3">
            <h5>What can gift cards be used for?</h5>
            <p>
              Gift cards can be used for any location, instructor, or time, and
              never expire.
            </p>
          </div>
          <div className="my-3">
            <h5>Do gift cards expire?</h5>
            <p>No, gift cards do not expire.</p>
          </div>
          <div className="my-3">
            <h5>
              Can I send a gift card to someone who lives in a different
              country?
            </h5>
            <p>
              Gift cards purchased in Canada can only be redeemed by users who
              reside in Canada. The recipient must also have a valid payment
              method in Canada.
            </p>
          </div>
          <div className="my-3">
            <h5>How can I check my gift card balance?</h5>
            <p>
              Once a gift card has been redeemed, it is added to your account as
              Propel Credit. You can view your current Propel Credit balance on
              your Account Settings page.
            </p>
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 border-t-2 sm:hidden">
        <div className="flex justify-center gap-8 p-2 bg-white">
          <Button to={APP_ROUTES.GIFT_CARDS.REDEEM} variant="flat">
            Redeem
          </Button>
          <Button
            to={APP_ROUTES.GIFT_CARDS.BUY.nav('info')}
            variant="flat"
            color="primary"
          >
            Buy Gift Card
          </Button>
        </div>
      </div>
    </>
  );
};

const Form = () => {
  const query = useQuery();
  const match = useRouteMatch<RouteParams>();
  const [formData, setFormData] = useState<CheckoutFormData>({
    ...initialCheckoutFormData,
    image: query.get(QueryParams.GiftCardImg) ?? CARD_IMAGES[5],
  });

  return (
    <div className="flex justify-center w-full">
      <div className="w-full p-4 bg-white sm:rounded-lg sm:shadow-lg sm:m-8 sm:max-w-xl">
        {match.params.step === 'info' ? (
          <Info formData={formData} setFormData={setFormData} />
        ) : match.params.step === 'checkout' ? (
          <Checkout formData={formData} setFormData={setFormData} />
        ) : null}
      </div>
    </div>
  );
};

const Info = ({
  formData,
  setFormData,
}: {
  formData: CheckoutFormData;
  setFormData: React.Dispatch<React.SetStateAction<CheckoutFormData>>;
}) => {
  const history = useHistory();
  const [errors, setErrors] = useState<CheckoutFormData>({
    ...initialCheckoutFormData,
    image: '',
    sendDate: '',
  });
  const [selectedAmount, setSelectedAmount] = useState<string>(
    SELECTABLE_AMOUNTS.includes(formData.amount) ? formData.amount : undefined
  );
  const [sendImmediately, setSendImmediately] = useState(
    formData.sendDate === ''
  );

  const handleFormDataChange = (val, field: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: val,
    }));
  };

  const handleAmountClick = (amount: string) => {
    setSelectedAmount(amount);
    setFormData((prev) => ({ ...prev, amount }));
  };

  const handleSubmit = () => {
    let [formErrors, formHasErrors] = validateCheckoutFormData(formData);
    if (!formErrors.sendDate && !sendImmediately && !formData.sendDate) {
      formErrors.sendDate =
        'Must choose a send date if not sending immediately';
      if (!formHasErrors) formHasErrors = true;
    }
    if (formHasErrors) {
      setErrors(formErrors);
      enqueueSnackbar({
        message: 'Please fix form errors before continuing',
        variant: 'error',
      });
    } else {
      history.push(APP_ROUTES.GIFT_CARDS.BUY.nav('checkout'));
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-4">
        <h1>Propel Gift Cards</h1>
        <div className="flex justify-center">
          <img
            className="w-4/5"
            src={getGiftCardUrl(formData.image)}
            alt={`${formData.image.capitalize()} Gift Card Image`}
          />
        </div>
        <h3 className="my-3 font-medium">Pick a design</h3>
        <GiftCardImages
          selected={formData.image}
          onClick={(name: string) => {
            setFormData((prev) => ({ ...prev, image: name }));
          }}
        />
        <h3 className="my-3 font-medium">Choose an amount</h3>
        <div className="flex flex-col justify-center gap-4 sm:flex-row">
          {SELECTABLE_AMOUNTS.map((amt) => (
            <Button
              key={'button-amt-' + amt}
              className="sm:flex-1"
              variant={selectedAmount === amt ? 'flat' : 'outlined'}
              color={selectedAmount === amt ? 'primary' : 'default'}
              onClick={() => handleAmountClick(amt)}
            >
              ${amt}
            </Button>
          ))}
        </div>
        <FormInputWrapper
          name="custom-amount-input"
          label="Custom"
          error={errors.amount}
        >
          <div className="flex items-center">
            <span>$&nbsp;</span>
            <input
              className={errors.amount ? 'border-red-600' : ''}
              name="custom-amount-input"
              value={selectedAmount === undefined ? formData.amount : ''}
              placeholder="Or enter a custom amount..."
              onChange={(e) => {
                handleFormDataChange(e.target.value, 'amount');
                if (selectedAmount !== undefined) {
                  setSelectedAmount(undefined);
                }
              }}
            />
          </div>
        </FormInputWrapper>
        <h3 className="my-3 font-medium">Who is it for?</h3>
        <FormInputWrapper
          name="recipient-name"
          label="Recipient Name"
          error={errors.recipientName}
        >
          <input
            className={errors.recipientName ? 'border-red-600' : ''}
            name="recipient-name"
            value={formData.recipientName}
            onChange={(e) =>
              handleFormDataChange(e.target.value, 'recipientName')
            }
          />
        </FormInputWrapper>
        <FormInputWrapper
          name="recipient-email"
          label="Recipient Email"
          error={errors.recipientEmail}
        >
          <input
            className={errors.recipientEmail ? 'border-red-600' : ''}
            type="email"
            name="recipient-email"
            value={formData.recipientEmail}
            onChange={(e) =>
              handleFormDataChange(e.target.value, 'recipientEmail')
            }
          />
        </FormInputWrapper>
        <FormInputWrapper name="message" label="Message (optional)">
          <textarea
            name="message"
            value={formData.message}
            onChange={(e) => handleFormDataChange(e.target.value, 'message')}
            maxLength={500}
          />
        </FormInputWrapper>
        <h3 className="my-3 font-medium">Who is it from?</h3>
        <FormInputWrapper
          name="sender-name"
          label="Sender Name"
          error={errors.senderName}
        >
          <input
            className={errors.senderName ? 'border-red-600' : ''}
            name="sender-name"
            value={formData.senderName}
            onChange={(e) => handleFormDataChange(e.target.value, 'senderName')}
          />
        </FormInputWrapper>
        <FormInputWrapper
          name="sender-email"
          label="Sender Email"
          error={errors.senderEmail}
        >
          <input
            className={errors.senderEmail ? 'border-red-600' : ''}
            name="sender-email"
            value={formData.senderEmail}
            onChange={(e) =>
              handleFormDataChange(e.target.value, 'senderEmail')
            }
          />
        </FormInputWrapper>
        <FormInputWrapper
          name="sender-date"
          label="When should we send the gift card?"
          error={errors.sendDate}
        >
          <div className="flex justify-start my-1.5 gap-2">
            <input
              id="send-immediately"
              className="w-5 hover:cursor-pointer"
              name="send-immediately"
              type="checkbox"
              checked={sendImmediately}
              onChange={() => {
                setSendImmediately((prev) => !prev);
                handleFormDataChange('', 'sendDate');
              }}
            />
            <label className="m-0" htmlFor="send-immediately">
              Immediately
            </label>
          </div>
          {!sendImmediately && (
            <input
              className={errors.sendDate ? 'border-red-600' : ''}
              name="send-date"
              type="date"
              value={formData.sendDate}
              disabled={sendImmediately}
              onChange={(e) => handleFormDataChange(e.target.value, 'sendDate')}
            />
          )}
        </FormInputWrapper>
      </div>
      <Controls className="!mb-0 !pb-0">
        <Button variant="flat" color="primary" onClick={handleSubmit}>
          Add to cart
        </Button>
      </Controls>
    </div>
  );
};

const Checkout = ({
  formData,
  setFormData,
}: {
  formData: CheckoutFormData;
  setFormData: React.Dispatch<React.SetStateAction<CheckoutFormData>>;
}) => {
  const history = useHistory();
  const [giftCardId, setGiftCardId] = useState('');
  const [checkoutSucceeded, setCheckoutSucceeded] = useState<boolean>();

  const handleSubmit = (success: boolean) => {
    setCheckoutSucceeded(success);
    window.scrollTo(0, 0);
    if (!giftCardId) return;
    api.giftCards.checkout(giftCardId);
    if (success) {
      rudderanalytics.track('Gift Card Purchase complete', {
        product: 'Gift card',
        category: 'Gift card',
        subtotal: formData.amount,
      });
    }
  };

  if (validateCheckoutFormData(formData)[1]) {
    // Prevent loading page directly without completing form
    setFormData(initialCheckoutFormData);
    return (
      <Redirect
        from={APP_ROUTES.GIFT_CARDS.ROOT}
        to={APP_ROUTES.GIFT_CARDS.BUY.nav('info')}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <h1>
        {checkoutSucceeded === undefined
          ? 'Confirm and Pay'
          : checkoutSucceeded
          ? 'Order Confirmed'
          : 'Order Failed'}
      </h1>
      <div className="flex justify-center">
        <img
          className="w-4/5"
          src={getGiftCardUrl(formData.image)}
          alt={`${formData.image.capitalize()} Gift Card Image`}
        />
      </div>
      {checkoutSucceeded !== false && (
        <div className="whitespace-pre-line">
          <h3>{formData.recipientName}</h3>
          <span>{formData.recipientEmail}</span>
          <hr className="my-3" />
          <span>
            Delivery date:{' '}
            {formData.sendDate
              ? moment(formData.sendDate).format(DATE_FMT.MONTH_D_ORDINAL)
              : 'Today'}
          </span>
          {formData.message && (
            <>
              <hr className="my-3" />
              {formData.message}
              <br />
              &mdash; {formData.senderName}
            </>
          )}
          <br />
          <button
            className="mt-2 link"
            onClick={() =>
              history.replace(APP_ROUTES.GIFT_CARDS.BUY.nav('info'))
            }
          >
            Edit
          </button>
          <hr className="my-3" />
          <span className="text-2xl">Total: ${formData.amount}</span>
        </div>
      )}
      <hr className="my-0 border-b-4" />
      {checkoutSucceeded === undefined ? (
        <CreditCardForm
          isSetupIntent={false}
          fetchClientSecret={async () => {
            try {
              const response = await api.giftCards.create({
                ...formData,
                sendDate: formData.sendDate ? formData.sendDate : null,
              });
              setGiftCardId(response.data.id);
              return Promise.resolve(response);
            } catch (err) {
              return Promise.reject(err);
            }
          }}
          onCreated={() => handleSubmit(true)}
          onError={() => handleSubmit(false)}
          onClose={() => history.replace(APP_ROUTES.GIFT_CARDS.BUY.nav('info'))}
        />
      ) : (
        <>
          <Callout
            title={checkoutSucceeded ? 'Order Confirmed' : 'Order Failed'}
            type={checkoutSucceeded ? 'success' : 'error'}
          >
            {checkoutSucceeded
              ? 'Your order was successful! Check your email for confirmation.'
              : 'There was a problem completing your order. Please try again.'}
          </Callout>
          <Controls className="!m-0 !p-0">
            <Button to={APP_ROUTES.HOME} variant="flat">
              Return Home
            </Button>
            {checkoutSucceeded === false && (
              <Button
                to={APP_ROUTES.GIFT_CARDS.BUY.nav('info')}
                variant="flat"
                color="primary"
                replace
              >
                Try Again
              </Button>
            )}
          </Controls>
        </>
      )}
    </div>
  );
};

const Redeem = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const formatPin = (val): string => {
    const validCharsOnly = val
      .toUpperCase()
      .replace(new RegExp(`[^${VALID_PIN_CHARS}]`, 'g'), '');
    const regex = new RegExp(
      `^${Array(4)
        .fill('')
        .map(() => `([${VALID_PIN_CHARS}]{0,4})`)
        .join('')}$`,
      'g'
    );
    return validCharsOnly.replace(regex, (_, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(' ')
    );
  };

  const account = useSelector(getAccountDetail);
  const accountIsLoaded = useSelector(getAccountIsLoaded);
  const [pin, setPin] = useState(
    formatPin(query.get(QueryParams.GiftCardPin) ?? '')
  );
  const [pinError, setPinError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setPinError('');
    setIsLoading(true);
    const parsedPin = pin.replace(/\s/g, '');
    const validPinRegex = new RegExp(`[${VALID_PIN_CHARS}]{16}`, 'g');
    if (!validPinRegex.test(parsedPin)) {
      setPinError(
        parsedPin.length !== 16
          ? 'PIN must be exactly 16 digits long'
          : 'PIN is invalid'
      );
      setIsLoading(false);
      return;
    }
    try {
      await api.giftCards.redeem({ pin: parsedPin });
      history.replace(`${CLIENT_ROUTES.SETTINGS.ROOT}?newCredit=true`);
    } catch (err) {
      enqueueSnackbar({
        message:
          err instanceof AxiosError &&
          ['card_not_found', 'card_previously_redeemed'].includes(
            err.response.data.code
          )
            ? err.response.data.detail
            : 'Error redeeming gift card. A report has been sent to Propel. Please try again.',
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!accountIsLoaded) {
    return <Loading message="Loading account..." />;
  }

  return (
    <div className="flex justify-center w-full">
      <div className="w-full p-4 bg-white sm:rounded-lg sm:shadow-lg sm:m-8 sm:max-w-xl">
        <div className="flex flex-col gap-4">
          <h1>Let's redeem your gift card</h1>
          <div className="flex justify-center">
            <img
              className="w-4/5"
              src={getGiftCardUrl(
                query.get(QueryParams.GiftCardImg) ?? CARD_IMAGES[5]
              )}
              alt="Gift Card Image"
            />
          </div>
          <FormInputWrapper name="account-name" label="Account">
            <input
              name="account-name"
              className="text-center"
              value={`${account.fullName} <${account.email}>`}
              disabled
            />
            <div className="flex justify-end text-sm mt-1.5">
              <span
                className="link"
                onClick={() => {
                  dispatch(logUserOut);
                }}
              >
                Switch accounts
              </span>
            </div>
          </FormInputWrapper>
          <FormInputWrapper label="PIN" name="card-pin" error={pinError}>
            <input
              className={`text-center uppercase text-xl p-1.5${
                pinError ? 'border-red-600' : ''
              }`}
              name="card-pin"
              maxLength={19} // includes spaces
              value={pin}
              placeholder="PRPL 1234 PRPL 1234"
              onChange={(e) => setPin(formatPin(e.target.value))}
            />
          </FormInputWrapper>
          <Controls className="!m-0 !p-0">
            <Button
              variant="flat"
              color="primary"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Redeem
            </Button>
          </Controls>
        </div>
      </div>
    </div>
  );
};
