import { Status } from 'api/Serializers/Accounts';
import InstructorAccount, {
  InstructorOnboardingStage,
} from 'api/Serializers/Accounts/Instructor';
import Callout from 'components/callout';
import Card from 'components/card';
import Loading from 'components/loading';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AccountRouteParams } from 'models/route';
import { InstructorOrientationMenuItems } from 'pages/account/instructor/utils';
import { AppointmentDebugToolbar } from 'pages/account/utils';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { getAccountDetail, getInstructorOnboarding } from 'state/selectors';
import { fetchInstructorOnboarding } from 'state/slice/instructor';
import { SidebarMenu } from '..';
import { InstructorBannedMenuItems, InstructorMenuItems } from './utils';

const ActiveInstructor = () => {
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const filteredMenuItems = InstructorMenuItems.filter(
    (item) =>
      item.Icon &&
      item.text &&
      (item.isHidden === undefined || !item.isHidden(account))
  );

  return (
    <>
      <SidebarMenu menuItems={filteredMenuItems}>
        <Switch>
          {InstructorMenuItems.map((item, key) => (
            <Route
              key={`route${key}`}
              exact={true}
              path={item.route.PATH}
              render={(routeProps) => <item.Component {...routeProps} />}
            />
          ))}
        </Switch>
      </SidebarMenu>
      <AppointmentDebugToolbar />
    </>
  );
};

const PendingInstructor = () => {
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const onboarding = useSelector(getInstructorOnboarding);
  const history = useHistory();
  const { params } = useRouteMatch<AccountRouteParams>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchInstructorOnboarding(account.username));
  }, []);
  if (!onboarding) {
    return <Loading />;
  }
  const filteredOrientationMenuItems = InstructorOrientationMenuItems.filter(
    (item) =>
      item.Icon && item.text && (!item.isHidden || !item.isHidden(account))
  );
  // const isOnboarding = onboarding.stage <= InstructorOnboardingStage.CallLimbo;
  // if (isOnboarding) {
  //   if (!params.mode) {
  //     history.push(INSTRUCTOR_ROUTES.ONBOARDING.CONTINUE);
  //     return <Loading />;
  //   } else {
  //     return <InstructorOnboarding />;
  //   }
  // }
  // const isCallLimbo =
  //   onboarding.stage === InstructorOnboardingStage.CallLimbo &&
  //   location.pathname.indexOf(INSTRUCTOR_ROUTES.ORIENTATION_CALL.ROOT) !== 0;
  // const isTOSRequired =
  //   onboarding.status === OnboardingStatus.Accepted && !account.isTosSigned;
  // if (isCallLimbo) {
  //   return <Redirect to={INSTRUCTOR_ROUTES.ORIENTATION_CALL.ROOT} />;
  // } else if (isTOSRequired) {
  //   return (
  //     <SidebarMenu menuItems={InstructorTermsOfServiceMenuItems}>
  //       <TermsOfService />
  //     </SidebarMenu>
  //   );
  // } else {
  return (
    <SidebarMenu
      menuItems={filteredOrientationMenuItems}
      hideMenu={onboarding.stage <= InstructorOnboardingStage.CallLimbo}
    >
      <Switch>
        {InstructorOrientationMenuItems.map((item, key) => (
          <Route
            key={`route${key}`}
            exact={true}
            path={item.route.PATH}
            render={(routeProps) => <item.Component {...routeProps} />}
          />
        ))}
      </Switch>
    </SidebarMenu>
  );
  // }
};

const Banned = () => (
  <SidebarMenu menuItems={InstructorBannedMenuItems}>
    <div className="my-8">
      <Card maxWidth="xl">
        <Callout title="Deactivated Profile" type="info">
          Your profile has been deactivated.
        </Callout>
      </Card>
    </div>
  </SidebarMenu>
);

const Unqualified = () => (
  <SidebarMenu menuItems={InstructorBannedMenuItems}>
    <div className="my-8">
      <Card maxWidth="xl">
        <Callout title="Account Suspended" type="info">
          After careful review, we've made the tough decision to suspend your
          profile due to one of the following:
          <ul className="ml-5 list-disc">
            <li>Insufficient work experience</li>
            <li>Invalid or expired certificates</li>
            <li>Incorrect qualifications</li>
          </ul>
          Thank you for your interest in Propel
        </Callout>
      </Card>
    </div>
  </SidebarMenu>
);

const FullAccessStatuses = [
  Status.Active,
  Status.Inactive,
  Status.Stale,
  Status.Hiatus,
  Status.Done,
  Status.Unlisted,
];

const Account = () => {
  const { status, ...rest } = useSelector(
    getAccountDetail
  ) as InstructorAccount;
  if (FullAccessStatuses.indexOf(status) > -1) {
    return <ActiveInstructor />;
  } else if (status === Status.Pending) {
    return <PendingInstructor />;
  } else if (status === Status.Unqualified) {
    return <Unqualified />;
  } else if (status === Status.Banned) {
    return <Banned />;
  }
  return null;
};

export default Account;
