import React from 'react';
import ClientRootView from './views/client';
// import ClientChangeParticipantsView from './views/client/change-participants';
import { AssistanceWizardProps } from 'models/Assistance';
import { Route, Switch } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';
import CancelByClientView from './views/client/cancel';
import FacilityRootView from './views/facility';
import FacilityCancelNote from './views/facility/cancel';
import FacilityFoulingView from './views/facility/fouling';
import FacilityGeneralView from './views/facility/general';
import InstructorCancellation from './views/instructor-cancellation';
import OtherRootView from './views/other';
import SummaryView from './views/summary';

const ReportWizard = (props: AssistanceWizardProps) => {
  const { appointment } = props;

  const { id } = appointment;
  return (
    <div className="w-full">
      <Switch>
        {/* <Route exact path={SHARED_ROUTES.ASSISTANCE.nav(id, 'new')}>
          <SelectCategoryView {...props} />
        </Route> */}
        <Route exact path={SHARED_ROUTES.ASSISTANCE.nav(id, 'summary')}>
          <SummaryView {...props} />
        </Route>
        {!appointment.cancelled && (
          <>
            <Route
              exact
              path={SHARED_ROUTES.ASSISTANCE.nav(id, 'instructor-cancellation')}
            >
              <InstructorCancellation {...props} />
            </Route>
            <Route
              exact
              path={SHARED_ROUTES.ASSISTANCE.nav(id, 'client/cancel')}
            >
              <CancelByClientView {...props} />
            </Route>
            <Route exact path={SHARED_ROUTES.ASSISTANCE.nav(id, 'client')}>
              <ClientRootView {...props} />
            </Route>
            {/* <Route
              exact
              path={SHARED_ROUTES.ASSISTANCE.nav(
                id,
                'client/change-participants'
              )}
            >
              <ClientChangeParticipantsView {...props} />
            </Route> */}
            <Route exact path={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility')}>
              <FacilityRootView {...props} />
            </Route>
            <Route
              exact
              path={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/general')}
            >
              <FacilityGeneralView {...props} />
            </Route>
            <Route
              exact
              path={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/fouling')}
            >
              <FacilityFoulingView {...props} />
            </Route>
            <Route
              exact
              path={SHARED_ROUTES.ASSISTANCE.nav(id, 'facility/cancel')}
            >
              <FacilityCancelNote {...props} />
            </Route>
            <Route exact path={SHARED_ROUTES.ASSISTANCE.nav(id, 'general')}>
              <OtherRootView {...props} />
            </Route>
          </>
        )}
      </Switch>
    </div>
  );
};

export default ReportWizard;
