import { PopoverProps, useMediaQuery, useTheme } from '@mui/material';
import MuiPopover from '@mui/material/Popover';
import Modal from 'components/modal';
import React from 'react';

const Popover = ({
  title,
  containerClassName,
  modalBreakpoint,
  ...popoverProps
}: {
  title: string;
  containerClassName?: string;
  modalBreakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;
} & PopoverProps) => {
  const theme = useTheme();
  const isBreakpoint = useMediaQuery(theme.breakpoints.down(modalBreakpoint));
  return modalBreakpoint === undefined || !isBreakpoint ? (
    <MuiPopover {...popoverProps}>
      <div className={`p-4${cls(containerClassName)}`}>
        {popoverProps.children}
      </div>
    </MuiPopover>
  ) : (
    <Modal
      open={popoverProps.open}
      title={title}
      onClose={(e) => {
        popoverProps.onClose(e, undefined);
      }}
      name={title}
    >
      {popoverProps.children}
    </Modal>
  );
};

export default Popover;
