import InstructorBaseTimeSlot from 'features/schedule/as-instructor/base-time-slot';
import { TimeSlotProps } from 'features/schedule/date-time-list/time-slot';
import React from 'react';
import { useSelector } from 'react-redux';
import { getScheduleAppointmentProduct } from 'state/selectors';

const ProposedTimeSlot = ({
  timeSlot,
  tense,
  aptProdId,
  description,
}: TimeSlotProps) => {
  const timezone = useSelector(getScheduleAppointmentProduct)?.timezone;
  // const title = <span>Proposed lesson with {timeSlot.title}</span>;
  let classes = 'font-semibold bg-gray-100 text-gray-700';
  // const description =
  //   timeSlot.facilityStatus !== FacilityTimeSlotType.Open ? (
  //     <span className="flex flex-col">
  //       {title}
  //       <div className="flex items-center text-sm italic font-normal">
  //         <WarningIcon fontSize="inherit" className="mr-1" />
  //         Pool no longer available
  //       </div>
  //     </span>
  //   ) : (
  //     title
  //   );
  return (
    <InstructorBaseTimeSlot
      datetime={timeSlot.datetime}
      timezone={timezone}
      description={description}
      classes={classes}
    />
  );
};

export default ProposedTimeSlot;
