import { RouteComponentProps } from 'react-router';

export interface RouteProps extends RouteComponentProps<RouteParams> {
  // match: Match;
}

export enum Entity {
  Marketplace = 'marketplace',
  Instructors = 'instructors',
  Hosts = 'hosts',
  Clients = 'clients',
}

export interface Match {
  isExact: boolean;
  params: RouteParams;
  path: string;
  url: string;
}

export type ActionRouteParam =
  | 'accept'
  | 'add-location'
  | 'add'
  | 'availability'
  | 'complete'
  | 'create'
  | 'delete'
  | 'edit'
  | 'fees'
  | 'info'
  | 'latest'
  | 'new'
  | 'payment'
  | 'reject'
  | 'review'
  | 'select-client'
  | 'select'
  | 'set-price'
  | 'summary';

export interface AccountRouteParams {
  view: string;
  mode: string;
  action: string;
}

export interface RouteParams {
  action: ActionRouteParam;
  appointmentId: string;
  appointmentProductId: string;
  audience: 'swimmers' | 'instructors' | 'hosts';
  date: string;
  detail: string;
  entity: Entity;
  facilitySlug: string;
  id: string;
  instructors: 'instructors';
  instructorSlug: string;
  payoutId: string;
  regionSlug: string;
  reviewId: string;
  step: string;
  subaction: string;
  uid: string;
  username: string;
  view: string;
  [x: string]: string;
}

export interface UTMData {
  source: string;
  medium?: string;
  campaign?: string;
}
