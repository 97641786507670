import Loading from 'components/loading';
import useSchedulableFacilities from 'hooks/useSchedulableFacilities';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getAccountDetail, getInstructorsSchedulable } from 'state/selectors';
import { SidebarMenu } from '..';
import { HostMenuItems } from './utils';

const Account = () => {
  const account = useSelector(getAccountDetail);
  const instructors = useSelector(getInstructorsSchedulable);
  const { facilities, isFacilitiesLoading } = useSchedulableFacilities();
  const [init, setInit] = useState<boolean>(!!instructors && !!facilities);

  useEffect(() => {
    setInit(!!instructors);
  }, [instructors, facilities]);

  if (!init) {
    return <Loading message="Getting ready..." />;
  }

  return (
    <>
      <SidebarMenu menuItems={HostMenuItems}>
        <Switch>
          {HostMenuItems.map((item, key) => (
            <Route
              key={`route${key}`}
              exact={true}
              path={item.route.PATH}
              render={(routeProps) => (
                <item.Component {...routeProps} account={account} />
              )}
            />
          ))}
        </Switch>
      </SidebarMenu>
    </>
  );
};

export default Account;
