import { UserType } from 'config';
import { PropelAccount } from '..';

export interface AdminProfile {
  userId: number;
  displayName: string;
  avatar: string;
  type: UserType.Admin;
}

export const AdminProfiles: AdminProfile[] = [];

export default interface AdminAccount extends PropelAccount {}
