import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

const refreshMins = 30;
const buffer = 250;

/**
 * For use with setTimeout functions to trigger some
 * function at the top of the hour or on the half hour
 * @returns int : the number of milliseconds until
 * the next half-hour or top of the hour interval
 */
const getRefreshTime = () => {
  const now = moment();
  const delta2 = refreshMins - (now.minute() % refreshMins);
  const ms2 =
    moment()
      .add(delta2, 'minutes')
      .second(0)
      .millisecond(0)
      .diff(now, 'milliseconds') + buffer;
  return ms2;
};

/**
 * @returns integer which increments on the half hour and top of hour, causing the using-component to rerender.
 * Useful for components that vary with time, such as time-to-appointment, or disabling features after an appointment has finished
 */
const useTimeoutRefresh = () => {
  const [ref, setRef] = useState<NodeJS.Timeout>();
  const [refresh, setRefresh] = useState(0);

  const handleRefresh = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    setRef(setTimeout(handleRefresh, getRefreshTime()));
    return () => {
      clearTimeout(ref);
    };
  }, [refresh]);
  return refresh;
};

export default useTimeoutRefresh;
