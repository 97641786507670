import api from 'api';
import { WaitlistSerializer } from 'api/Serializers/Utils';
import { HTTP_200_OK } from 'api/status';
import Button from 'components/button';
import Controls from 'components/controls';
import Input from 'components/input';
import Label from 'components/input-label';
import Loading from 'components/loading';
import { UserType } from 'config';
import useForm from 'hooks/useForm';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail, getSearchCity } from 'state/selectors';
import { EXTERNAL_ROUTES } from 'utils/routing';

interface SignUpForm {
  firstName: string;
  lastName: string;
  email: string;
}

const DetailsForm = () => {
  const [isWaitlisted, setWaitlisted] = useState(false);
  const account = useSelector(getAccountDetail);
  const region = useSelector(getSearchCity);
  const [isSubmitted, setSubmitted] = useState(false);
  const handleFormCallback = async (inputs: SignUpForm) => {
    setSubmitted(true);
    const data: WaitlistSerializer = {
      ...inputs,
      ...region,
      userType: account ? account.type : UserType.Anonymous,
    };
    const response = await api.utils.waitlist.create(
      data as WaitlistSerializer
    );
    if (response.status === HTTP_200_OK) {
      setWaitlisted(true);
    }
  };
  const { inputs, handleInputChange, handleSubmit } = useForm<SignUpForm>(
    {
      firstName: account ? account.firstName : '',
      lastName: account ? account.lastName : '',
      email: account ? account.email : '',
    },
    handleFormCallback
  );
  return (
    <div className="">
      <h2>{region.label} Waitlist</h2>
      <p>Sign up to get notified when we launch a new location nearby!</p>
      {isWaitlisted ? (
        <Button
          to={EXTERNAL_ROUTES.BLOG.WHILE_YOU_WAIT}
          variant="contained"
          color="secondary"
        >
          What to do while you wait
        </Button>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="my-2">
              <Label htmlFor="firstName">First Name</Label>
              <Input
                name="firstName"
                placeholder="Your first name"
                value={inputs.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="my-2">
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                name="lastName"
                placeholder="Your last name"
                value={inputs.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="my-2">
              <Label htmlFor="email">Email</Label>
              <Input
                name="email"
                placeholder="Your email"
                value={inputs.email}
                onChange={handleInputChange}
              />
            </div>
            <p>
              We'll keep you in the loop when new locations open in your area!
            </p>
            <Controls>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitted}
              >
                Add Me to the Waitlist
              </Button>
            </Controls>
          </form>
        </>
      )}
    </div>
  );
};

const Waitlist = ({ onClose }: { onClose?(): void }) => {
  const [isWaitlisted, setWaitlisted] = useState(false);
  const [regionLabel, setRegionLabel] = useState<string>();
  const [isAdding, setIsAdding] = useState(false);
  const [askForDetails, setAskForDetails] = useState(false);
  return (
    <>
      {isAdding && <Loading />}
      {!askForDetails ? (
        <div className="text-center">
          <h2>Coming to a pool near you</h2>
          <p>
            It looks like there aren't any facilities that are convenient for
            you. Don’t worry — we're adding new locations all the time and
            hoping to be in a pool near you soon!
          </p>
          <h2>Sign up to hear it first!</h2>
          <p>
            Add yourself to the {regionLabel} waitlist to be notified when new
            locations open up.
          </p>
          <Controls variant="block">
            {isWaitlisted ? (
              <Button
                to={EXTERNAL_ROUTES.BLOG.WHILE_YOU_WAIT}
                variant="contained"
                color="secondary"
              >
                What to do while you wait
              </Button>
            ) : (
              <Button
                onClick={() => setAskForDetails(true)}
                variant="contained"
                color="primary"
                disabled={isAdding}
              >
                Add Me to the Waitlist
              </Button>
            )}
          </Controls>
        </div>
      ) : (
        <DetailsForm />
      )}
    </>
  );
};

export default Waitlist;
