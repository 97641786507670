import {
  AppointmentListSerializer,
  TimeFrame,
} from 'api/Serializers/Appointments';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import Callout from 'components/callout';
import Card from 'components/card';
import ColumnTitle from 'components/column-title';
import Link from 'components/link';
import Loading from 'components/loading';
import {
  Action,
  DATE_FMT,
  FETCH_STATE,
  QueryParams,
  RESOLVED_FETCH_STATES,
} from 'config';
import AppointmentDetail from 'features/appointment-detail';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import useTimeoutRefresh from 'hooks/useTimeoutRefresh';
import { AddIcon, ArrowForwardIcon } from 'icons';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getActivity,
  getAppointments,
  getAppointmentsFetchState,
  getClientHasMoreAppointments,
  getCurrentUser,
  getInstructorsSchedulable,
} from 'state/selectors';
import { fetchClientAppointments } from 'state/slice/appointments';
import { LocalStore } from 'state/storage';
import { getAppointmentTimeFrame } from 'utils/business-logic';
import { APP_ROUTES, getUrlParam, SHARED_ROUTES } from 'utils/routing';
import { CLIENT_ROUTES } from '../utils';

type Filter = 'Upcoming' | 'Past' | 'Cancelled';
const today = moment().format(DATE_FMT.DATE_KEY);
const digitClasses =
  'font-medium bg-gray-300 rounded-full px-2 py-0.5 text-gray-500 ml-2';
const digitActiveClasses =
  'font-medium text-blue-500 bg-blue-200 rounded-full px-2 py-0.5 text-gray-600 ml-2';
const baseClasses =
  'text-sm sm:text-base font-medium transition-colors duration-200 ease-in-out cursor-pointer px-0 py-1.5 mx-2 sm:mr-0 sm:ml-6 text-center border-b border-transparent border-solid focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-blue-300';

const isUpcoming = (apt: AppointmentListSerializer) =>
  apt.date >= today && !apt.cancelled;
const isPast = (apt: AppointmentListSerializer) =>
  apt.date < today && !apt.cancelled;
const isCancelled = (apt: AppointmentListSerializer) => apt.cancelled === true;

const getFilterDescription = (filter: Filter) =>
  filter === 'Upcoming'
    ? 'Here are all your upcoming bookings'
    : filter === 'Past'
    ? 'Showing all past appointments'
    : filter === 'Cancelled'
    ? 'These bookings were cancelled.'
    : '';

const getTimeToAppointmentText = (apt: AppointmentListSerializer) =>
  apt.timeFrame === TimeFrame.Within2
    ? 'starting soon'
    : apt.timeFrame === TimeFrame.HasStarted
    ? 'happening now'
    : '';

const getAppointmentQueryString = (
  query: URLSearchParams,
  appointment: AppointmentListSerializer,
  action
) => {
  query.set(QueryParams.Action, action);
  query.set(QueryParams.AppointmentId, appointment.id);
  return query.toString();
};

const getFilteredAppointments = (schedule, filter) => {
  if (filter === 'Upcoming') {
    return schedule.filter(isUpcoming);
  } else {
    return schedule
      .filter(filter === 'Past' ? isPast : isCancelled)
      .sort((a, b) => (a.start > b.start ? -1 : 1));
  }
};

const reduceUniqueMonths = (agg: string[], val: AppointmentListSerializer) =>
  agg.indexOf(moment(val.date).format(DATE_FMT.YEAR_MO)) === -1
    ? agg.concat(moment(val.date).format(DATE_FMT.YEAR_MO))
    : agg;

const AppointmentListItem = ({
  appointment,
  isActive = false,
}: {
  appointment: AppointmentListSerializer;
  isActive?: boolean;
}) => {
  const query = useQuery();
  const queryString = getAppointmentQueryString(
    query,
    appointment,
    Action.DETAIL
  );
  const datetime = moment(appointment.start).tz(appointment.timezone);
  return (
    <Link to={`${CLIENT_ROUTES.SCHEDULE.ROOT}?${queryString}`}>
      <div
        className={`group relative flex hover:text-gray-800 w-auto p-6 pr-0 border-b border-gray-200 duration-200 transition-all hover:shadow-sm border-transparent border ${
          isActive
            ? 'bg-blue-200 border-blue-300 my-2'
            : 'bg-white text-gray-800 sm:text-gray-600 sm:bg-gray-50 hover:bg-white'
        }`}
      >
        <div className="flex flex-col flex-1 sm:flex-row">
          <div className="flex mb-4 sm:mb-0">
            <div className="flex items-start">
              <div className="flex flex-col items-start w-20">
                <ColumnTitle>{datetime.format(DATE_FMT.DOW_FULL)}</ColumnTitle>
                <div className="flex items-baseline">
                  <span className="text-lg font-medium text-gray-700">
                    {datetime.format(DATE_FMT.MON_D)}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-start">
              <div className="flex flex-col items-start w-20">
                <ColumnTitle>Time</ColumnTitle>
                <div className="flex items-baseline">
                  <span className="text-lg font-medium text-gray-700">
                    {datetime.format('h:mm')}
                  </span>
                  <span className="font-medium text-gray-500 text-xxs">
                    {datetime.format('a')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start flex-1">
            <div className="flex flex-col flex-1 mr-4">
              <ColumnTitle>Description</ColumnTitle>
              <div className="flex items-center flex-1 gap-3">
                <div className="flex-1 mb-0 text-lg font-medium text-gray-800 sm:text-gray-700 group-hover:text-gray-800">
                  {appointment.facility.displayName} {appointment.activity.name}
                </div>
                <div className="text-gray-500 transition-colors duration-200 rounded-full hover:bg-gray-200 hover:shadow-sm group-hover:text-gray-800">
                  <ArrowForwardIcon width={24} />
                </div>
              </div>
              <div className="flex text-gray-700">
                {appointment.instructor.displayName} with{' '}
                {appointment.numParticipants}{' '}
                {appointment.activity.clientDescription.pluralize(
                  appointment.numParticipants
                )}
              </div>
              {isCancelled(appointment) && (
                <div className="flex mt-1">
                  <Callout type="error">
                    <span className="text-base">
                      {appointment.cancellation.description}
                    </span>
                  </Callout>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Schedule = () => {
  const activity = useSelector(getActivity);
  const dispatch = useAppDispatch();
  const refresh = useTimeoutRefresh();
  const [init, setInit] = useState(false);
  const [months, setMonths] = useState<string[]>([]);
  const [schedule, setSchedule] = useState<AppointmentListSerializer[]>([]);
  const hasMore = useSelector(getClientHasMoreAppointments);
  const [filter, setFilter] = useState<Filter>('Upcoming');
  const [upcomingAppointment, setUpcomingAppointment] =
    useState<AppointmentListSerializer>();
  const history = useHistory();
  const primarySchedule = useSelector(getAppointments);
  const appointmentId = getUrlParam(QueryParams.AppointmentId);
  const appointmentsFetchState = useSelector(getAppointmentsFetchState);
  const instructors = useSelector(getInstructorsSchedulable);
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (clientHeight + scrollTop + 1000 < scrollHeight) {
        return;
      }
      dispatch(fetchClientAppointments());
    };
    if (
      hasMore &&
      filter !== 'Upcoming' &&
      appointmentsFetchState !== FETCH_STATE.GET
    ) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, [schedule, filter, appointmentsFetchState]);

  useEffect(() => {
    if (!init && RESOLVED_FETCH_STATES.includes(appointmentsFetchState)) {
      setInit(true);
    }
  }, [appointmentsFetchState]);

  useEffect(() => {
    if (primarySchedule) {
      const upcomingSchedule = [...primarySchedule].filter(isUpcoming);
      const nextAppointment = upcomingSchedule.length && upcomingSchedule[0];
      // Calculate timeFrame each run, as nextAppointment.timeFrame could be incorrect
      const nextApptTimeFrame = getAppointmentTimeFrame(nextAppointment);
      if (
        [TimeFrame.Within2, TimeFrame.HasStarted].some(
          (v) => v === nextApptTimeFrame
        )
      ) {
        setUpcomingAppointment(nextAppointment);
        if (
          LocalStore.get('assistanceClosed') !==
          moment().format(DATE_FMT.DATE_KEY)
        ) {
          LocalStore.set(
            'assistanceClosed',
            moment().format(DATE_FMT.DATE_KEY)
          );
          history.push(SHARED_ROUTES.SCHEDULE.appointment(nextAppointment.id));
        }
      } else if (upcomingAppointment) {
        setUpcomingAppointment(undefined);
      }
    }
  }, [primarySchedule, refresh]);

  useEffect(() => {
    if (primarySchedule) {
      const filteredSchedule = getFilteredAppointments(primarySchedule, filter);
      const uniqueMonths = filteredSchedule.reduce(reduceUniqueMonths, []);
      setMonths(uniqueMonths);
      setSchedule(filteredSchedule);
    }
  }, [primarySchedule, filter]);

  return (
    <Dashboard title={`${activity.name} bookings`} width="3xl">
      {upcomingAppointment && (
        <Button
          className="w-full my-4 !whitespace-normal"
          size="large"
          variant="flat"
          color="secondary"
          to={SHARED_ROUTES.SCHEDULE.appointment(upcomingAppointment.id)}
        >
          You have a{' '}
          {upcomingAppointment.activity.appointmentNoun.toLowerCase()}{' '}
          {getTimeToAppointmentText(upcomingAppointment)}! Need assistance?
        </Button>
      )}
      <div className="max-w-sm mx-auto my-8 button-group">
        <button
          className={filter === 'Upcoming' ? 'active' : ''}
          onClick={() => setFilter('Upcoming')}
        >
          Upcoming
          <span
            className={
              'Upcoming' === filter ? digitActiveClasses : digitClasses
            }
          >
            {primarySchedule.filter(isUpcoming).length}
          </span>
        </button>
        <button
          className={filter === 'Past' ? 'active' : ''}
          onClick={() => setFilter('Past')}
        >
          Past
        </button>
        <button
          className={filter === 'Cancelled' ? 'active' : ''}
          onClick={() => setFilter('Cancelled')}
        >
          Cancelled
        </button>
      </div>
      <div>
        <p>{getFilterDescription(filter)}</p>
      </div>
      {primarySchedule && primarySchedule.length === 0 && (
        <div>
          <Callout title="No Upcoming Lessons" type="info">
            You currently don't have any upcoming lessons booked. Keep working
            towards your goals and book your next set now!
          </Callout>
        </div>
      )}
      <div className="flex items-center justify-center my-12">
        {instructors.length > 0 ? (
          <Button
            to={CLIENT_ROUTES.SEARCH.ROOT}
            color="primary"
            variant="contained"
            size="large"
            icon={<AddIcon width={20} />}
          >
            Book More
          </Button>
        ) : (
          <Button
            to={APP_ROUTES.BROWSE_CITY(currentUser.address?.slug)}
            color="primary"
            variant="contained"
            size="large"
            icon={<AddIcon />}
          >
            Get Started
          </Button>
        )}
      </div>
      {!init ? (
        <Loading />
      ) : months.length > 0 ? (
        months.map((monthKey) => {
          const appointments = schedule.filter(
            (e) => e.date.substring(0, 7) === monthKey
          );
          const month = moment(monthKey, DATE_FMT.YEAR_MO);
          return (
            <Card
              key={monthKey}
              title={month.format(DATE_FMT.MONTH_YEAR)}
              className="mb-12"
              maxWidth="full"
              space="tight"
            >
              <div className="-mx-card-tight">
                {appointments &&
                  appointments.map((appointment) => (
                    <AppointmentListItem
                      key={appointment.id}
                      appointment={appointment}
                      isActive={
                        appointmentId && appointmentId === appointment.id
                      }
                    />
                  ))}
              </div>
            </Card>
          );
        })
      ) : null}
      <div
        className={`flex justify-center w-full flex-0${
          appointmentsFetchState === FETCH_STATE.GET && filter !== 'Upcoming'
            ? ''
            : ' invisible'
        }`}
      >
        <Loading position="inline-contained" />
      </div>
      <AppointmentDetail
        id={appointmentId}
        onClose={() => history.push(CLIENT_ROUTES.SCHEDULE.ROOT)}
      />
    </Dashboard>
  );
};

export default Schedule;
