import InstructorAccount, {
  InstructorOnboardingSerializer,
  InstructorOnboardingStage,
} from 'api/Serializers/Accounts/Instructor';
import { AppointmentProduct } from 'api/Serializers/AppointmentProducts';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import React from 'react';
import { InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  OnboardingScreens,
  ScreenRoute,
  TextClasses,
  TitleClasses,
} from '../';
import { INSTRUCTOR_ROUTES } from '../../utils';

interface BtnProps {
  onboarding: InstructorOnboardingSerializer;
  account: InstructorAccount;
  nextRoute: ScreenRoute;
}

const getLastRoute = (
  account: InstructorAccount,
  onboarding: InstructorOnboardingSerializer,
  appointmentProducts: AppointmentProduct[]
) => {
  let lastRoute: ScreenRoute;
  for (const page of OnboardingScreens) {
    if (page.isSet) {
      if (page.isSet(account, onboarding, appointmentProducts)) {
        lastRoute = page;
      } else {
        break;
      }
    }
  }
  if (!lastRoute) {
    return OnboardingScreens.find(
      (page) => page.mode === InstructorOnboardingRoutes.S00
    );
  }
  return lastRoute;
};

const getNextRoute = (page: ScreenRoute): ScreenRoute => {
  const index = OnboardingScreens.findIndex(
    (screen) => screen.mode === page.mode
  );
  const nextIndex = OnboardingScreens.findIndex(
    (screen: ScreenRoute, i: number) =>
      i > index && typeof screen.isSet !== 'undefined'
  );
  return OnboardingScreens[nextIndex !== -1 ? nextIndex : index];
};

const Btn1 = ({ account, nextRoute }: BtnProps) => {
  return (
    <div>
      <ButtonLarge
        to={`${INSTRUCTOR_ROUTES.ONBOARDING.WELCOME}?r=true`}
        selected={nextRoute.stage !== 'background'}
        variant="check"
        title="1. Background Info"
        subtitle="Address, birthday, and qualifications"
      />
    </div>
  );
};

const Btn2 = ({ account, nextRoute }: BtnProps) => {
  return (
    <div>
      <ButtonLarge
        title="2. Your Profile"
        subtitle="Photo, about me, and expertise"
        to={INSTRUCTOR_ROUTES.ONBOARDING.CLIENT_EXPERIENCE}
        selected={nextRoute.stage === 'appointments'}
        disabled={nextRoute.stage === 'background'}
        variant="check"
      />
    </div>
  );
};

const Btn3 = ({ account, onboarding, nextRoute }: BtnProps) => {
  return (
    <div>
      <ButtonLarge
        to={INSTRUCTOR_ROUTES.ONBOARDING.SET_LOCATION_AND_PRICE}
        selected={onboarding.stage >= InstructorOnboardingStage.CallLimbo}
        disabled={nextRoute.stage !== 'appointments'}
        variant="check"
        title="3. Booking Preferences"
        subtitle="Facility and price"
      />
    </div>
  );
};

const ContinueOnboarding: React.FC<BaseStepProps> = ({
  account,
  onboarding,
  appointmentProducts,
}) => {
  const lastRoute: ScreenRoute = getLastRoute(
    account,
    onboarding,
    appointmentProducts
  );
  const nextRoute: ScreenRoute = getNextRoute(lastRoute);

  return (
    <div>
      <h1 className={TitleClasses}>Welcome back, {account.firstName}!</h1>
      <p className={TextClasses}>Let's finish your profile.</p>
      <div className="w-full space-y-3">
        <Btn1 account={account} onboarding={onboarding} nextRoute={nextRoute} />
        <Btn2 account={account} onboarding={onboarding} nextRoute={nextRoute} />
        <Btn3 account={account} onboarding={onboarding} nextRoute={nextRoute} />
      </div>
      <div className={ControlClasses}>
        <Button
          to={`${INSTRUCTOR_ROUTES.ONBOARDING.ROOT}/${lastRoute.mode}`}
          variant="contained"
          color="primary"
          type="submit"
        >
          Pickup where I left off
        </Button>
      </div>
    </div>
  );
};

export default ContinueOnboarding;
