import { MenuItem } from 'components/account/menu';
import { HomeIcon, ScheduleIcon, SettingsIcon } from 'icons';
import { SHARED_ROUTES } from 'utils/routing';
import { isPathnameInRouteRoot } from '../utils';
import Dashboard from './dashboard';
import Schedule from './schedule';
import Settings from './settings';

export const HOST_ROUTES = {
  ...SHARED_ROUTES,
  INSTRUCTORS: {
    PATH: '/account/instructors/:username?/:mode?',
    ROOT: '/account/instructors',
    nav: (username: string, mode?: string) =>
      `/account/instructors${
        username ? `/${username}${mode ? `/${mode}` : ''}` : ''
      }`,
  },
  SETTINGS: {
    PATH: '/account/settings',
    ROOT: '/account/settings',
  },
};

export const HostMenuItems: MenuItem[] = [
  {
    id: 'dashboard',
    route: HOST_ROUTES.DASHBOARD,
    text: 'Dashboard',
    Icon: HomeIcon,
    Component: Dashboard,
    isActive: (pathname) => pathname === SHARED_ROUTES.DASHBOARD.ROOT,
  },
  {
    id: 'schedule',
    route: HOST_ROUTES.SCHEDULE,
    text: 'Schedule',
    Icon: ScheduleIcon,
    Component: Schedule,
    isActive: isPathnameInRouteRoot,
    isFixedHeightRoute: true,
  },
  {
    id: 'settings',
    route: HOST_ROUTES.SETTINGS,
    text: 'Settings',
    Icon: SettingsIcon,
    Component: Settings,
    isActive: isPathnameInRouteRoot,
  },
  // {
  //   route: HOST_ROUTES.INSTRUCTORS,
  //   text: 'Instructors',
  //   Icon: MyInstructorsIcon,
  //   Component: Instructors,
  //   isActive: (pathname: string) => pathname === HOST_ROUTES.INSTRUCTORS.ROOT,
  // },
];
