import Delete from '@mui/icons-material/Delete';
import DeleteConfirm from '@mui/icons-material/DeleteForever';
import { Media } from 'api/Serializers/Media';
import ImageUpload from 'components/uploader';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useState } from 'react';
import { removeImage } from 'state/slice/account';

interface Props {
  name: string;
  media: Media[];
  onFinish: (location: string) => void;
  buttonTitle?: string;
}

const GalleryUploader = ({ buttonTitle, name, media, onFinish }: Props) => {
  const dispatch = useAppDispatch();
  const [removeConfirm, setRemoveConfirm] = useState();
  const handleImageRemove = (mediaId) => () => {
    if (removeConfirm === mediaId) {
      dispatch(removeImage(mediaId));
    } else {
      setRemoveConfirm(mediaId);
    }
  };

  return (
    <div className="space-y-4">
      {media && media.length > 0 ? (
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
          {media.map((item) => (
            <div key={item.url} className="overflow-hidden rounded-lg max-w">
              {!item.protected && (
                <div
                  className={`absolute bg-white rounded-full shadow right-2 top-2 ${
                    removeConfirm === item.id
                      ? 'bg-red-200 text-red-600'
                      : 'bg-white text-gray-600 hover:text-blue-500'
                  }`}
                >
                  <button
                    className="p-1 transition-colors duration-200 ease-out text-inherit"
                    onClick={handleImageRemove(item.id)}
                  >
                    {removeConfirm === item.id ? (
                      <DeleteConfirm color="inherit" />
                    ) : (
                      <Delete color="inherit" />
                    )}
                  </button>
                </div>
              )}
              <img className="object-cover h-full" src={item.smUrl} />
            </div>
          ))}
        </div>
      ) : (
        <span>
          <svg
            className="w-20 h-20 mx-auto text-gray-400 transition-colors duration-300 ease-in-out group-hover:text-blue-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}
      <ImageUpload
        onFinish={onFinish}
        icon={false}
        name={name}
        title={buttonTitle}
      />
    </div>
  );
};

export default GalleryUploader;
