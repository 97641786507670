import ButtonCopyText from 'components/button-copy-text';
import React from 'react';

export const FeedbackTitle = ({
  title,
  titleTwo = '',
  subtitle = '',
}): JSX.Element => {
  return (
    <div className="mb-8">
      <h2 className="text-xl font-bold text-center text-gray-800">{title}</h2>
      {titleTwo ? (
        <h2 className="text-xl font-bold text-center text-gray-800">
          {titleTwo}
        </h2>
      ) : null}
      {subtitle ? (
        <h3 className="text-md font-normal text-center text-gray-700">
          {subtitle}
        </h3>
      ) : null}
    </div>
  );
};

export const CopyPreviousFeedback = ({ title, text }): JSX.Element => {
  return (
    <div className={text ? '' : 'hidden'}>
      <h5 className="mb-1 font-semibold text-center text-gray-700">{title}</h5>
      <div className="flex gap-x-5 items-center">
        <ButtonCopyText copyText={text} />
        <span className="w-full text-base">{text}</span>
      </div>
    </div>
  );
};
