import { AppointmentListSerializer } from 'api/Serializers/Appointments';
import Button from 'components/button';
import Controls from 'components/controls';
import Modal from 'components/modal';
import { CAL_LIST_ITEM_NAME_PREFIX, FETCH_STATE, QueryParams } from 'config';
import FacilityScheduleUpdates from 'containers/facility-updates';
import AppointmentDetail from 'features/appointment-detail';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import useTimeoutRefresh from 'hooks/useTimeoutRefresh';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { scroller } from 'react-scroll';
import {
  getAccountDetail,
  getAppointments,
  getClientDetail,
  getFacilitiesSchedulable,
  getFacilityDetail,
  getInstructorDetails,
  getInstructorFetchState,
  getScheduleFilterDate,
  getScheduleListMode,
  getScheduleRenderDate,
} from 'state/selectors';
import { fetchAppointments } from 'state/slice/appointments';
import {
  ScheduleMode,
  setScheduleFilterDate,
  setScheduleListMode,
} from 'state/slice/schedule';
import { AppDispatch } from 'state/store';
import { getUrlParam } from 'utils/routing';
import ScheduleTemplate from '../as-instructor/template';
import CalendarDay from './calendar-day';
import CalendarEventList from './event-list';

const callApi = debounce((dispatch: AppDispatch) => {
  return dispatch(fetchAppointments());
}, 350);

const ScheduleV1 = () => {
  useTimeoutRefresh();
  const facilities = useSelector(getFacilitiesSchedulable);
  const appointments = useSelector(getAppointments);
  const dispatch = useAppDispatch();
  const queryParams = useQuery();
  const history = useHistory();
  const match = useRouteMatch();
  const user = useSelector(getAccountDetail);
  const renderDate = useSelector(getScheduleRenderDate);
  const filterDate = useSelector(getScheduleFilterDate);
  const listMode = useSelector(getScheduleListMode);
  const instructor = useSelector(getInstructorDetails);
  const client = useSelector(getClientDetail);
  const instructorFetchState = useSelector(getInstructorFetchState);
  const facility = useSelector(getFacilityDetail);
  const [events, setEvents] = useState<AppointmentListSerializer[]>([]);
  const onDateClick = (
    events: AppointmentListSerializer[],
    date: string,
    performAction: boolean
  ) => {
    if (events.length > 0) {
      scroller.scrollTo(`${CAL_LIST_ITEM_NAME_PREFIX}-${date}`, {
        duration: 350,
        smooth: true,
        offset: -50,
      });
    }
    dispatch(setScheduleFilterDate(date));
  };

  useEffect(() => {
    let filteredEvents = [];
    let mode: 'date' | 'month' = 'date';
    if (facility) {
      filteredEvents = appointments.filter(
        (event) => event.facility.slug === facility.slug
      );
      mode = 'month';
    } else if (instructor) {
      filteredEvents = appointments.filter(
        (event) => event.instructor.username === instructor.username
      );
      mode = 'month';
    } else if (client) {
      filteredEvents = appointments.filter(
        (event) => event.client.id === client.id
      );
      mode = 'month';
    } else {
      filteredEvents = appointments;
    }
    setListMode(mode);
    setEvents(filteredEvents);
  }, [filterDate, listMode, client, facility, instructor, appointments]);

  const getDateFilteredEvents = (
    events: AppointmentListSerializer[],
    ignoreFilterDate: boolean
  ) => {
    if (events && !ignoreFilterDate && listMode === 'date') {
      return [...events].filter((event) => event.date === filterDate);
    }
    return events;
  };

  useEffect(() => {
    callApi(dispatch);
  }, [renderDate, user]);

  const onCloseModal = () => history.push(location.pathname);
  const actionParam = queryParams.get(QueryParams.Action);
  const onAppointmentClose = () => {
    queryParams.delete(QueryParams.AppointmentId);
    const params =
      queryParams.toString() !== '' ? `?${queryParams.toString()}` : '';
    const url = `${match.url}${params}`;
    history.push(url);
  };

  const setListMode = (mode: 'date' | 'month') => {
    dispatch(setScheduleListMode(mode));
  };

  return (
    <>
      <ScheduleTemplate
        minDate={moment().subtract(6, 'months')}
        maxDate={moment().add(6, 'months')}
        isLoading={instructorFetchState === FETCH_STATE.GET}
        mode="bookings"
        CalendarDayLoader={(props) => (
          <CalendarDay
            {...props}
            highlight={filterDate === props.date}
            events={getDateFilteredEvents(events, true).filter(
              (event) => event.date === props.date
            )}
            onClick={onDateClick}
          />
        )}
      >
        <CalendarEventList events={getDateFilteredEvents(events, false)} />
      </ScheduleTemplate>
      <AppointmentDetail
        id={getUrlParam(QueryParams.AppointmentId)}
        onClose={onAppointmentClose}
      />
      <Modal
        name="Admin — View facility schedules"
        open={actionParam === ScheduleMode.FacilitySchedules}
        onClose={onCloseModal}
      >
        {facilities.map((facility) => (
          <FacilityScheduleUpdates key={facility.slug} facility={facility} />
        ))}
        <Controls>
          <Button onClick={onCloseModal} color="primary" variant="contained">
            Close
          </Button>
        </Controls>
      </Modal>
    </>
  );
};

export default ScheduleV1;
