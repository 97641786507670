import api from 'api';
import { Contact } from 'api/Serializers/Accounts';
import Button from 'components/button';
import Callout from 'components/callout';
import Checkbox from 'components/checkbox';
import Controls from 'components/controls';
import Loading from 'components/loading';
import { NOTIFICATION_TYPES } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getContacts, getUsername } from 'state/selectors';
import { addContact, updateContact } from 'state/slice/account';

const ContactForm = ({
  contact,
  setContact,
  onSubmit,
  isSubmitDisabled = false,
  onCancel = undefined,
}: {
  contact: Contact;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
  onSubmit(): Promise<void> | void;
  isSubmitDisabled?: boolean;
  onCancel?(): void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await onSubmit();
        setIsLoading(false);
      }}
    >
      <div className="flex flex-col gap-8">
        <div>
          <label htmlFor="name">Name</label>
          <input
            name="name"
            type="text"
            required
            onChange={(e) =>
              setContact((x) => ({ ...x, name: e.target.value.capitalize() }))
            }
            value={contact.name}
            maxLength={255}
          />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            name="email"
            type="email"
            required
            onChange={(e) =>
              setContact((x) => ({ ...x, email: e.target.value }))
            }
            value={contact.email}
            maxLength={255}
          />
        </div>
        <div className="flex flex-col gap-8">
          {NOTIFICATION_TYPES.map((elt) => (
            <div
              className="flex items-center justify-between gap-2"
              key={`notification-checkbox-${elt.value}`}
            >
              <label
                className="w-full"
                htmlFor={`notification-id-${elt.value}`}
              >
                <div className="select-none">{elt.label}</div>
                <div className="font-light select-none">{elt.description}</div>
              </label>
              <div>
                <Checkbox
                  id={`notification-id-${elt.value}`}
                  checked={contact.notifications.some(
                    (val) => val.type === elt.value
                  )}
                  onChange={(e) =>
                    e.target.checked
                      ? setContact((x) => ({
                          ...x,
                          notifications: [
                            ...x.notifications,
                            { type: elt.value },
                          ],
                        }))
                      : setContact((x) => ({
                          ...x,
                          notifications: x.notifications.filter(
                            (y) => y.type !== elt.value
                          ),
                        }))
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Controls className="!p-0 mt-8">
        {onCancel !== undefined && (
          <Button variant="flat" onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
        )}
        <Button
          variant="flat"
          color="primary"
          isLoading={isLoading}
          type="submit"
          disabled={isSubmitDisabled}
        >
          Save
        </Button>
      </Controls>
    </form>
  );
};

export const UpdateContact = ({
  contactId,
  onSuccess = undefined,
  onCancel = undefined,
}: {
  contactId: string;
  onSuccess?(): void;
  onCancel?(): void;
}) => {
  const contacts = useSelector(getContacts);
  const dispatch = useAppDispatch();
  const username = useSelector(getUsername);
  const [initialContact, setInitialContact] = useState<Contact>();
  const [contact, setContact] = useState<Contact>();
  const [init, setInit] = useState(false);

  useEffect(() => {
    const contactData = contacts.find((elt) => elt.id === contactId);
    const copy = {
      id: contactData.id,
      name: contactData.name,
      email: contactData.email,
      notifications: [...contactData.notifications],
    };
    setInitialContact(copy);
    setContact(copy);
    setInit(true);
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await api.account.contacts.update(username, contact);
      await dispatch(updateContact(response.data));
      enqueueSnackbar({
        message: 'Contact successfully updated',
        variant: 'success',
      });
      if (onSuccess !== undefined) {
        onSuccess();
      }
    } catch (error) {
      enqueueSnackbar({
        message: 'Failed to update contact. Please try again.',
        variant: 'error',
      });
    }
  };

  return (
    <div className="w-full">
      {!init ? (
        <Loading position="inline-contained" />
      ) : initialContact === undefined || contact === undefined ? (
        <Callout title="Error loading contact">
          <p>Please refresh to try again.</p>
        </Callout>
      ) : (
        <ContactForm
          contact={contact}
          setContact={setContact}
          isSubmitDisabled={
            !(
              initialContact.name !== contact.name ||
              initialContact.email !== contact.email ||
              initialContact.notifications.length !==
                contact.notifications.length ||
              initialContact.notifications.some((elt, i) => elt !== contact[i])
            )
          }
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

export const CreateContact = ({
  onSuccess = undefined,
  onCancel = undefined,
}: {
  onSuccess?(): void;
  onCancel?(): void;
}) => {
  const dispatch = useAppDispatch();
  const username = useSelector(getUsername);
  const getInitialContactState = () => ({
    name: '',
    email: '',
    notifications: [],
  });
  const [contact, setContact] = useState<Contact>(getInitialContactState());

  const handleSubmit = async () => {
    try {
      const response = await api.account.contacts.create(username, contact);
      await dispatch(addContact(response.data));
      enqueueSnackbar({
        message: 'Contact successfully added',
        variant: 'success',
      });
      if (onSuccess !== undefined) {
        onSuccess();
      }
      setContact(getInitialContactState());
    } catch (error) {
      enqueueSnackbar({
        message: 'Failed to add contact. Please try again.',
        variant: 'error',
      });
    }
  };

  return (
    <ContactForm
      contact={contact}
      setContact={setContact}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
};
