import React from 'react';

interface ErrorProps {
  title?: string;
  children?: any;
}

const Error = (props) =>
  props.children ? (
    <div className="mt-2 ml-1 text-sm text-red-600">
      {props.title && (
        <h5 className="mt-0 font-semibold font-body">{props.title}</h5>
      )}
      {props.children}
    </div>
  ) : null;

export default Error;
