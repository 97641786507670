import {
  FacilitySchedulableSerializer,
  getTemperatureLabel,
} from 'api/Serializers/Facilities';
import Avatar from 'components/avatar';
import FacilityCategoryIcon from 'components/facility-icon';
import { FreeParkingIcon, PaidParkingIcon, UserGroupIcon } from 'icons';
import React from 'react';
import { Element } from 'react-scroll';
import { FacilityDirections } from '.';
import DirectionsInfo from './directions-info';

const FacilityCard = ({
  facility,
  directions,
  isDirectionsShown = true,
}: {
  facility: Pick<
    FacilitySchedulableSerializer,
    | 'slug'
    | 'hero'
    | 'shortName'
    | 'admissionFee'
    | 'hasFreeParking'
    | 'category'
    | 'assetType'
    | 'numInstructors'
    | 'near'
    | 'region'
    | 'attributes'
  >;
  directions?: FacilityDirections;
  isDirectionsShown?: boolean;
}) => {
  if (!facility) {
    return null;
  }
  const temperatureLabel: string = getTemperatureLabel(
    facility?.attributes.temperature
  );
  return (
    <div className="w-full p-3 overflow-hidden transition-all duration-150 bg-white border border-gray-400 shadow-sm rounded-2xl">
      {/* <div className="absolute top-3 text-xs right-3 items-center gap-1 flex px-1.5 text-gray-100 bg-gray-900 rounded-full py-1 leading-none">
        <span>${facility.admissionFee}</span>
      </div> */}
      <div className="space-y-3">
        <div className="flex gap-4 text-left">
          <div>
            <Avatar src={facility.hero} diameter={32} variant="rounded" />
          </div>
          <div className="pt-1">
            <h3 className="my-0 font-bold text-gray-900 text-md">
              {facility.shortName}
            </h3>
            <div className="text-gray-800 ">
              <h4 className="text-sm font-semibold text-gray-700">
                {facility.category} {facility.attributes.subtype}{' '}
                {facility.assetType}
              </h4>
              <div className="text-sm font-normal">
                <span>{`${facility.attributes.temperature}°C (${temperatureLabel})`}</span>
                <span>{' · '}</span>
                <span>{`${facility.attributes.maxLength} long`}</span>
                <span>{' · '}</span>
                <span>{`${facility.attributes.minDepth} to ${facility.attributes.maxDepth} deep`}</span>
              </div>
              {isDirectionsShown && (
                <div>
                  <DirectionsInfo
                    directions={directions}
                    placeholder="View Directions"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div>
          <div className="text-sm button-group">
            <div className={getDOWClasses('sunday')}>Sun</div>
            <div className={getDOWClasses('monday')}>Mon</div>
            <div className={getDOWClasses('tuesday')}>Tue</div>
            <div className={getDOWClasses('wednesday')}>Wed</div>
            <div className={getDOWClasses('thursday')}>Thu</div>
            <div className={getDOWClasses('friday')}>Fri</div>
            <div className={getDOWClasses('saturday')}>Sat</div>
          </div>
        </div> */}
        <div className="flex justify-between px-3 text-xs font-normal text-gray-700">
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              {facility.numInstructors}
            </div>
            <div className="text-xs text-gray-700">
              {'Instructor'.pluralize(facility.numInstructors)}
            </div>
          </div>
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              {facility.hasFreeParking ? 'Free' : 'Paid'}
            </div>
            <div className="text-xs text-gray-700">Parking</div>
          </div>
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              ${facility.admissionFee}
            </div>
            <div className="text-xs text-gray-700">per lesson</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FacilityCardDep = ({
  facility,
  directions,
  isDirectionsShown = true,
}: {
  facility: Pick<
    FacilitySchedulableSerializer,
    | 'slug'
    | 'hero'
    | 'shortName'
    | 'admissionFee'
    | 'hasFreeParking'
    | 'category'
    | 'assetType'
    | 'numInstructors'
    | 'near'
    | 'region'
  >;
  directions?: FacilityDirections;
  isDirectionsShown?: boolean;
}) => {
  return (
    <div className="w-full overflow-hidden text-left transition-shadow duration-300 bg-white border-t border-gray-200 rounded-lg shadow cubic hover:shadow-md">
      {facility && (
        <div className="flex flex-col">
          <Element name={facility.slug} />
          <div className="flex">
            <div>
              <div className="w-28 h-full min-h-[9rem] lg:w-36">
                <img className="object-cover min-h-full" src={facility.hero} />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col justify-between w-full h-full p-2 lg:pt-4 lg:px-4">
                <div className="mb-2 space-y-0">
                  <div>
                    <div className="flex mb-1 font-bold leading-none text-gray-800 text-md">
                      {facility.shortName}
                    </div>
                    <div className="text-sm">
                      {facility.category} {facility.assetType}
                    </div>
                    <div className="absolute top-0 right-0 text-right">
                      <div className="font-semibold leading-none text-gray-900">
                        ${facility.admissionFee}
                      </div>
                    </div>
                  </div>
                  <div>
                    {isDirectionsShown && (
                      <DirectionsInfo
                        directions={directions}
                        placeholder="View Directions"
                      />
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col items-center justify-center text-xs text-gray-700">
                    <span className="flex items-center justify-end flex-1 text-gray-900">
                      <FacilityCategoryIcon category={facility.category} />
                    </span>
                    <span>
                      {facility.category} {facility.assetType}
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-center text-xs text-gray-700 border-l border-r border-gray-300">
                    {facility.hasFreeParking ? (
                      <>
                        <span className="flex items-center justify-end flex-1 text-gray-800">
                          <FreeParkingIcon width={24} />
                        </span>
                        <span>Free Parking</span>
                      </>
                    ) : (
                      <>
                        <span className="flex items-center justify-end flex-1 text-gray-800">
                          <PaidParkingIcon width={24} />
                        </span>
                        <span>Paid Parking</span>
                      </>
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center text-xs text-gray-700">
                    <span className="flex items-center justify-end flex-1 text-gray-900">
                      <UserGroupIcon width={24} />
                    </span>
                    <span>{`${facility.numInstructors} ${'Instructor'.pluralize(
                      facility.numInstructors
                    )}`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FacilityCard;
