import Button from 'components/button';
import BackButton from 'components/button-back';
import Controls from 'components/controls';
import { UserType } from 'config';
import AttachImages from 'features/assistance/components/images';
import TextArea from 'features/assistance/components/textArea';
import { AssistanceWizardProps } from 'models/Assistance';
import React from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const dataKey = 'facilityIssueDescription';
const FacilityCancelView = (props: AssistanceWizardProps) => {
  const { appointment } = props;
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>Note & Photos</h4>
      <p className="my-2">
        Provide a short explanation and photos that illustrate the problem.
      </p>
      <div className="my-8 space-y-6">
        <TextArea
          id={dataKey}
          label="Briefly describe the issue to the host:"
          title="Note for host"
          placeholder="The lesson cannot proceed due to..."
          recipientUserType={UserType.Host}
        />
        <TextArea
          id={dataKey}
          label="Leave a note for your client:"
          title="Note for client"
          placeholder="The lesson cannot proceed due to..."
          recipientUserType={UserType.Client}
        />
        <AttachImages
          label="If helpful, include 1 or more photos of the issue (optional):"
          {...props}
        />
      </div>
      <Controls variant="block">
        <BackButton />
        <Button
          variant="contained"
          color="primary"
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
        >
          Continue
        </Button>
      </Controls>
    </>
  );
};

export default FacilityCancelView;
