import Loading from 'components/loading';
import { FETCH_STATE } from 'config';
import Page from 'containers/page';
import Messages from 'features/messages';
import React from 'react';
import { useSelector } from 'react-redux';
import { getClientListFetchState } from 'state/selectors';

const MessagesPage = () => {
  const clientListFetchState = useSelector(getClientListFetchState);
  return (
    <Page title="Messages">
      {clientListFetchState !== FETCH_STATE.FULFILLED ? (
        <Loading message="Getting clients" />
      ) : (
        <Messages />
      )}
    </Page>
  );
};

export default MessagesPage;
