import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Card from 'components/card';
import Controls from 'components/controls';
import FacilityList from 'components/facility-list';
import Link from 'components/link';
import Loading from 'components/loading';
import { FETCH_STATE } from 'config';
import Page from 'containers/page';
import { FacilityListMeta as Meta } from 'metadata';
import { FacilityListReduxProps } from 'models/pages/locations';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getActivity,
  getSearchCity,
  getSearchFacilities,
  getSearchFacilitiesFetchState,
} from 'state/selectors';
import { APP_ROUTES, SHOW_HELP } from 'utils/routing';

type State = 'ready' | 'zero' | 'failed' | 'loading';
const getState = (fetchState, facilities): State => {
  if (fetchState === FETCH_STATE.FULFILLED && facilities.length > 0) {
    return 'ready';
  } else if (fetchState === FETCH_STATE.FULFILLED && facilities.length === 0) {
    return 'zero';
  } else if (fetchState === FETCH_STATE.FAILED) {
    return 'failed';
  } else {
    return 'loading';
  }
};

const ZeroResultsPage = () => {
  return (
    <div className="px-4 py-10 mx-auto lg:w-lg md:w-md sm:w-sm">
      <h2 className="mb-8 text-gray-900">Shucks...</h2>
      <Card title="Sorry we're not in your city yet!" maxWidth="lg">
        <p>
          We are thrilled that you are interested in our swim lessons, but we
          are not in your city yet.
        </p>
        <p>
          We know, it's a bummer. But don't fret, we are working hard to get
          there as soon as we can. We are looking forward to helping you reach
          your swimming goals. Until then, please check out our help article for
          more, and sign up to be notified of new cities as we expand!
        </p>
        <Controls>
          <BackButton variant="contained">Try another city</BackButton>
          <Button
            onClick={SHOW_HELP.NEW_POOL_NOTIFICATION}
            variant="contained"
            color="primary"
          >
            Add Me to the Waitlist
          </Button>
        </Controls>
      </Card>
    </div>
  );
};

const FacilityListPage = () => {
  const history = useHistory();
  const activity = useSelector(getActivity);
  const city = useSelector(getSearchCity);
  const facilities = useSelector(getSearchFacilities);
  const fetchState = useSelector(getSearchFacilitiesFetchState);
  const metadata: FacilityListReduxProps = {
    facilities,
    region: city?.label,
  };
  const hasInstructors = facilities.filter(
    (facility) => facility.numInstructors > 0
  );
  const hasNoInstructors = facilities.filter(
    (facility) => facility.numInstructors === 0
  );

  const handleChangeCity = () => {
    rudderanalytics.track('City unselected');
  };

  const state = getState(fetchState, facilities);

  useEffect(() => {
    if (fetchState === FETCH_STATE.FAILED) {
      history.replace(APP_ROUTES.BROWSE_CITIES);
    }
  }, [fetchState]);

  return (
    <Page {...Meta(metadata)}>
      <div className="max-w-6xl px-4 py-8 mx-auto space-y-4 md:space-y-6 sm:px-6 lg:px-8">
        {city && (
          <div className="space-x-2 text-sm">
            <Link to={APP_ROUTES.BROWSE_CITIES} onClick={handleChangeCity}>
              Find nearby locations
            </Link>
            <span>{'>'}</span>
            <span>{city?.label}</span>
          </div>
        )}
        {state === 'ready' ? (
          <>
            <div>
              <h2 className="text-gray-900">
                {facilities.length === 1
                  ? `1 ${activity.facilityDescription.toLowerCase()}`
                  : `${
                      facilities.length
                    } ${activity.facilityDescription.toLowerCase()}s`}{' '}
                near {city?.city}
              </h2>
            </div>
            <div className="mx-auto lg:w-lg sm:w-sm">
              {hasInstructors.length > 0 && (
                <FacilityList
                  list={hasInstructors}
                  linkGenerator={(f) =>
                    APP_ROUTES.BROWSE_FACILITY(f.region.slug, f.slug)
                  }
                />
              )}
              {hasNoInstructors.length > 0 && (
                <>
                  <div className="flex justify-center py-10">
                    <div className="inline-block">
                      <Callout title="Not currently available" type="warning">
                        We're actively recruiting for the following locations
                      </Callout>
                    </div>
                  </div>
                  <FacilityList
                    list={hasNoInstructors}
                    linkGenerator={(f) =>
                      APP_ROUTES.BROWSE_FACILITY(f.region.slug, f.slug)
                    }
                  />
                </>
              )}
            </div>
          </>
        ) : state === 'zero' ? (
          <ZeroResultsPage />
        ) : state === 'failed' ? (
          <div className="flex flex-wrap px-4 mx-auto my-0 lg:w-lg sm:w-sm sm:p-0">
            <h2 className="text-gray-900">
              There was a problem getting that city
            </h2>
          </div>
        ) : state === 'loading' ? (
          <Loading />
        ) : null}
      </div>
    </Page>
  );
};

export default FacilityListPage;
