import api from 'api';
import { Review } from 'api/Serializers/Accounts';
import Dashboard from 'components/account/dashboard';
import Callout from 'components/callout';
import Card from 'components/card';
import Loading from 'components/loading';
import Testimonials from 'containers/reviews';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail, getUsername } from 'state/selectors';

const byCreated = (r1: Review, r2: Review) =>
  r1.created > r2.created ? -1 : 1;

const TestimonialsPage = () => {
  const account = useSelector(getAccountDetail);
  const username = useSelector(getUsername);
  const [reviews, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTestimonials = async () => {
    const response = await api.account.reviews(username);
    setTestimonials(response.data.sort(byCreated));
    setIsLoading(false);
  };

  useMemo(() => {
    getTestimonials();
  }, []);

  return (
    <Dashboard title="My testimonials">
      <div className="space-y-4">
        {isLoading ? (
          <Loading message="Getting testimonials..." />
        ) : reviews.length === 0 ? (
          <Card title="No testimonials">
            <Callout type="info">You don't yet have any testimonials!</Callout>
          </Card>
        ) : (
          <Card maxWidth="3xl">
            <Testimonials
              name={`My testimonials`}
              type="testimonials"
              count={reviews.length}
              reviews={reviews}
              truncate={false}
            />
          </Card>
        )}
      </div>
    </Dashboard>
  );
};

export default TestimonialsPage;
