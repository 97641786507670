import Button from 'components/button';
import Title from 'components/title';
import React from 'react';
import style from './style.module.scss';

const PromoText = ({
  title,
  icon,
  subtitle,
  description,
  buttonTitle,
  to,
  onClick,
}: {
  title: string;
  icon: string;
  subtitle: string;
  description: string;
  buttonTitle?: string;
  to?: string;
  onClick?: () => void;
}) => {
  return (
    <div className={style.column}>
      <section className={style.promoText}>
        <div className={style.container}>
          <Title as="h3" shade="600" className={style.title}>
            {title}
          </Title>
          <div className={style.icon}>
            <img className="my-2 mx-auto" src={icon} />
          </div>
          <Title as="h4" shade="600" className={style.subtitle}>
            {subtitle}
          </Title>
          <p className={style.description}>{description}</p>
          {buttonTitle && (
            <Button
              className="mt-auto"
              to={safe(to) ? to : undefined}
              onClick={safe(onClick) ? onClick : undefined}
              fullWidth={true}
              variant="outlined"
              color="default"
            >
              {buttonTitle}
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};

export default PromoText;
