import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { UserSerializer } from 'api/Serializers/Users';
import { COOKIE_MAX_AGE, FETCH_STATE } from 'config';
import { GenericServerError } from 'lang/en/Snackbars';
import { enqueueSnackbar } from 'notistack';
import { getIsAuthenticated } from 'state/selectors';
import { getInitialStateFromServer } from 'state/slice/utils';
import { clearStorage, CookieStore } from 'state/storage';
import { CookieKey } from 'state/storage/cookies';
import { AppDispatch } from 'state/store';
import { tokenDecode } from 'utils/auth';

interface AuthReducer {
  isAuthenticated: boolean;
  isUserDataLoaded: boolean;
  isIdentified: boolean;
  username: string;
  fetchState: string;
  user: UserSerializer;
}

const initialState: AuthReducer = {
  isUserDataLoaded: false,
  isAuthenticated: false,
  isIdentified: false,
  username: undefined,
  fetchState: FETCH_STATE.IDLE,
  user: undefined,
};

const name: 'authentication' = 'authentication';
const Slice = createSlice({
  name,
  initialState: getInitialStateFromServer(name, initialState),
  reducers: {
    signUpRequest(state) {
      state.fetchState = FETCH_STATE.POST;
    },
    login(state, action: PayloadAction<UserSerializer>) {
      state.username = action.payload.username;
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    clearErrorState(state) {
      state.fetchState = FETCH_STATE.IDLE;
    },
    passwordResetStart(state) {
      state.fetchState = FETCH_STATE.POST;
    },
    passwordResetSuccess(state) {
      state.fetchState = FETCH_STATE.FULFILLED;
    },
    setUserDataLoaded(state, action: PayloadAction<boolean>) {
      state.isUserDataLoaded = action.payload;
    },
    setIsIdentified(state, action: PayloadAction<boolean>) {
      state.isIdentified = action.payload;
    },
    resetAuthState(state) {
      state.isAuthenticated = false;
      state.username = undefined;
      state.fetchState = FETCH_STATE.IDLE;
    },
    logout(state) {
      // intentionally blank
    },
  },
});

export const {
  setUserDataLoaded,
  setIsIdentified,
  clearErrorState,
  resetAuthState,
} = Slice.actions;

const { login, logout, passwordResetStart, passwordResetSuccess } =
  Slice.actions;

export const forgotPassword =
  (credentials: { email: string }) => async (dispatch: AppDispatch) => {
    dispatch(passwordResetStart());
    try {
      await api.auth.forgotPassword(credentials);
      return dispatch(passwordResetSuccess());
    } catch (error: any) {
      return enqueueSnackbar(GenericServerError);
    }
  };

export const changePassword =
  (credentials: { password: string }, uid: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(passwordResetStart());
    try {
      await api.auth.changePassword(credentials, uid);
      return dispatch(passwordResetSuccess());
    } catch (error: any) {
      return enqueueSnackbar(GenericServerError);
    }
  };

export const loginSuccess =
  (token, refresh, isProxy = false) =>
  (dispatch: AppDispatch) => {
    saveAuthTokens(token, refresh, isProxy);
    return dispatch(authWithToken(token));
  };

/**
 * Upon successful authentication, store the users token and URL as cookie, then fetch their account details
 * @param param0 Token data from API
 */
export const authWithToken =
  (token: string) => async (dispatch: AppDispatch, getState) => {
    const user = tokenDecode(token);
    if (
      !token ||
      !user ||
      typeof user.username === undefined ||
      user.username === 'undefined' ||
      user.username === ''
    ) {
      return dispatch(logUserOut);
    }
    const response = await api.users.retrieve(user.user_id);
    return dispatch(login(response.data));
    // return dispatch(retrieveAccount(user.username));
  };

export const saveAuthTokens = (
  token: string,
  refresh: string,
  isProxy: boolean = false
) => {
  const options = {
    expires: COOKIE_MAX_AGE,
  };
  CookieStore.set(CookieKey.AuthToken, token, options);
  CookieStore.set(CookieKey.RefreshToken, refresh, options);
  if (isProxy) {
    CookieStore.set(CookieKey.IsProxySession, 'true');
  } else if (
    !isProxy &&
    CookieStore.get(CookieKey.IsProxySession) !== undefined
  ) {
    CookieStore.remove(CookieKey.IsProxySession);
  }
};

export type TokenStatus = 'valid' | 'expiring' | 'invalid';

export const logUserOut = (dispatch: AppDispatch, getState) => {
  const state = getState();
  if (getIsAuthenticated(state)) {
    clearStorage();
    dispatch(logout());
  }
};

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
