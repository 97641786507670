import DoneIcon from '@mui/icons-material/Done';
import * as React from 'react';
import style from './style.module.scss';

interface CheckmarkProps {
  className?: string;
  valid?: boolean;
}

const Checkmark: React.FunctionComponent<CheckmarkProps> = ({
  className,
  valid,
}) => (
  <DoneIcon
    className={`${style.checkmark} ${className} ${valid ? style.valid : ''}`}
  />
);

Checkmark.defaultProps = {
  className: '',
};

export default Checkmark;
