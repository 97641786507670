import { FacilityGoogleReviewSerializer } from 'api/Serializers/Facilities';
import { InstructorReview } from 'api/Serializers/Reviews';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import Review from 'components/review';
import React, { useState } from 'react';

interface _Review {
  createdAt: string;
  reviewer: string;
  avatar: string;
  comment: string;
  title?: string;
}

const abbreviateName = (name) => {
  const split = name.split(' ');
  if (split.length === 1) {
    return split[0];
  } else {
    return split[0] + ' ' + split[1].charAt(0) + '.';
  }
};

/**
 * Google super-imposes a star on the bottom right corner on the avatars of
 * super reviewers which we want to remove. The star is added through the url.
 */
const removeAvatarStar = (url: string) => {
  const split = url.split('=');
  if (split.length === 1) return url;
  const newParams = split[1].replace(/-ba[0-9]+/g, '');
  return split[0] + '=' + newParams;
};

const getReviewData = (
  review: FacilityGoogleReviewSerializer | InstructorReview
): _Review => {
  const type = (review as any).title !== undefined ? 'instructor' : 'facility';
  if (type === 'instructor') {
    review = review as InstructorReview;
    return {
      createdAt: review.created,
      reviewer: review.reviewer.capitalize(),
      title: review.title,
      avatar: '',
      comment: review.review,
    };
  } else {
    review = review as FacilityGoogleReviewSerializer;
    return {
      createdAt: review.created,
      reviewer: abbreviateName(review.reviewerName.capitalize()),
      avatar: removeAvatarStar(review.reviewerAvatar),
      comment: review.comment,
    };
  }
};

const Reviews = ({
  name,
  reviews,
  count,
  type,
  handleLoadNextPage = undefined,
  limit = undefined,
  truncate = true,
}: {
  /**
   * name: string — used to track the specific entity in analytics
   */
  name: string;
  type: 'reviews' | 'testimonials';
  reviews: FacilityGoogleReviewSerializer[] | InstructorReview[];
  count: number;
  handleLoadNextPage?(): void | Promise<void>;
  limit?: number;
  truncate?: boolean;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const showViewAll = limit !== undefined && limit < count;

  const handleModalScroll = async (e) => {
    const isBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (
      handleLoadNextPage === undefined ||
      count <= reviews.length ||
      pageIsLoading ||
      !isBottom
    ) {
      return;
    }
    setPageIsLoading(true);
    await handleLoadNextPage();
    setPageIsLoading(false);
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          {reviews.slice(0, limit).map((review) => (
            <Review
              key={review.id}
              truncate={truncate}
              {...getReviewData(review)}
            />
          ))}
        </div>
        {showViewAll && (
          <div className="flex justify-center">
            <Button variant="outlined" onClick={() => setModalIsOpen(true)}>
              View all {count} {type}
            </Button>
          </div>
        )}
      </div>
      {showViewAll && (
        <Modal
          name={`All ${type} — ${name}`}
          maxWidth="sm"
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          title={`All ${type}`}
        >
          <div
            className="flex flex-wrap gap-6 overflow-y-auto max-h-[60vh] space-y-4"
            onScroll={handleModalScroll}
          >
            {reviews.map((review) => (
              <Review
                key={review.id}
                truncate={false}
                {...getReviewData(review)}
              />
            ))}
            {pageIsLoading && (
              <div className="flex justify-center w-full flex-0">
                <Loading position="inline-contained" />
              </div>
            )}
          </div>
          <Controls>
            <Button variant="flat" onClick={() => setModalIsOpen(false)}>
              Close
            </Button>
          </Controls>
        </Modal>
      )}
    </>
  );
};

export default Reviews;
