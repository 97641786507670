import api from 'api';
import { TermsOfService } from 'api/Serializers/TermsOfService';
import { UserType } from 'config';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserType } from 'state/selectors';

const fetchTermsOfService = async (userType: UserType, setDocument) => {
  const response = await api.termsOfService.retrieve(userType);
  setDocument(response.data);
};

const TermsOfServiceContainer = () => {
  const userType = useSelector(getUserType);
  const [document, setDocument] = useState<TermsOfService>();
  useEffect(() => {
    fetchTermsOfService(userType, setDocument);
  }, []);
  return (
    <article
      id="146aecf9-5859-4be5-8f9a-dab1655de6b8"
      className="w-full max-w-3xl mx-auto"
    >
      <header>
        <h1>Terms of Service</h1>
        <div>
          <span className="pr-2">Last Updated</span>
          <span className="font-bold">Jan 14, 2019 5:50PM</span>
        </div>
      </header>
      {document && (
        <div
          className="w-full break-words"
          dangerouslySetInnerHTML={{ __html: document.htmlText }}
        />
      )}
    </article>
  );
};

export default TermsOfServiceContainer;
