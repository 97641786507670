import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { CustomCartError } from 'api/errors';
import { AppointmentProduct } from 'api/Serializers/AppointmentProducts';
import { Availability } from 'api/Serializers/Availability';
import {
  BookingSource,
  Cart,
  CartCheckoutMethod,
  CartError,
  CartStatus,
} from 'api/Serializers/Cart';
import { Participant } from 'api/Serializers/Clients';
import { PaymentMethodSerializer } from 'api/Serializers/PaymentMethods';
import { AxiosError } from 'axios';
import { DATE_FMT } from 'config';
import { UTMData } from 'models/route';
import moment from 'moment-timezone';
import {
  getActivity,
  getCartAppointmentProduct,
  getCartAvailability,
  getCartCheckoutMethod,
  getCartNumParticipants,
  getCartParticipants,
  getCartPaymentMethod,
  getCartStatus,
  getUser,
} from 'state/selectors';
import { getInitialStateFromSession } from 'state/slice/utils';
import { invalidateScheduleInstructors } from '../instructor';

interface LineItem {
  id: string;
  title: string;
  description?: string;
  price: number;
}
interface PriceDetails {
  lineItems: LineItem[];
  currency: 'CAD';
  total: number;
}

type CheckoutErrors =
  | 'not_bookable'
  | 'max_daily_limit'
  | 'max_total_limit'
  | 'payment_method_failed'
  | 'payment_method_invalid'
  | 'invalid_participants'
  | 'invalid_availability'
  | 'invalid_cart_status'
  | 'cart_is_complete';

interface Reducer {
  id: string;
  source: BookingSource;
  appointmentProduct: AppointmentProduct;
  availability: Availability;
  participants: Participant[];
  numParticipants: number;
  paymentMethod: PaymentMethodSerializer;
  status: CartStatus;
  error: CustomCartError<CheckoutErrors>;
  priceDetails: PriceDetails;
  utmData: UTMData;
  checkoutMethod: CartCheckoutMethod;
  cart: Cart;
}
const initialState: Reducer = {
  id: undefined,
  source: undefined,
  checkoutMethod: undefined,
  appointmentProduct: undefined,
  availability: undefined,
  paymentMethod: undefined,
  participants: [],
  numParticipants: undefined,
  status: 'EMPTY',
  error: undefined,
  utmData: undefined,
  priceDetails: undefined,
  cart: undefined,
};

const name: 'cart' = 'cart';
const Slice = createSlice({
  name,
  initialState: getInitialStateFromSession(name, initialState),
  reducers: {
    setCart(state, action: PayloadAction<Cart>) {
      state.cart = action.payload;
      state.id = action.payload.id;
    },
    _setCartAppointmentProduct(
      state,
      action: PayloadAction<AppointmentProduct>
    ) {
      state.numParticipants = undefined;
      state.priceDetails = undefined;
      state.availability = undefined;
      state.appointmentProduct = action.payload;
      Slice.caseReducers._updateCartPriceDetails(state);
    },
    _addAvailabilityToCart(state, action: PayloadAction<Availability>) {
      state.availability = action.payload;
      Slice.caseReducers._updateCartPriceDetails(state);
    },
    _setCartPaymentMethod(
      state,
      action: PayloadAction<PaymentMethodSerializer>
    ) {
      state.paymentMethod = action.payload;
      Slice.caseReducers._updateCartPriceDetails(state);
      if (state.error?.error === 'payment_method_failed') {
        state.error = undefined;
      }
    },
    _setNumParticipants(state, action: PayloadAction<number>) {
      if (action.payload > 2 || action.payload < 1) {
        return;
      }
      state.numParticipants = action.payload;
      state.participants.splice(action.payload);
      Slice.caseReducers._updateCartPriceDetails(state);
    },
    _setParticipants(state, action: PayloadAction<Participant[]>) {
      state.participants = action.payload;
      Slice.caseReducers._updateCartPriceDetails(state);
    },
    _updateCartPriceDetails(state) {
      if (!state.appointmentProduct) {
        state.priceDetails = undefined;
      }
      // Very swim-centric, 1 or 2 person code! Smelly.
      const extraParticipantPrice = Number(
        state.appointmentProduct.extraParticipantPrice
      );
      const lineItems = [
        {
          id: `${state.appointmentProduct.id}`,
          title: `${state.appointmentProduct.activity.name} with ${state.appointmentProduct.instructor.displayName}`,
          price: Number(state.appointmentProduct.price),
        },
      ];
      if (state.numParticipants === 2) {
        lineItems.push({
          id: `extra-participant`,
          title: 'Extra swimmer',
          price: extraParticipantPrice,
        });
      }
      const total = lineItems.reduce((agg, li, i) => agg + li.price, 0);
      state.priceDetails = {
        lineItems,
        currency: 'CAD',
        total,
      };
    },
    setCartUTMData(state, action: PayloadAction<UTMData>) {
      state.utmData = action.payload;
    },
    checkoutInitiated(state) {
      state.status = 'PROCESSING';
      state.error = undefined;
    },
    checkoutComplete(state) {
      state.status = 'COMPLETE';
      state.error = undefined;
    },
    checkoutError(
      state,
      action: PayloadAction<CustomCartError<CheckoutErrors>>
    ) {
      state.status = 'ERROR';
      state.error = action.payload;
    },
    setCartCheckoutMethod(state, action: PayloadAction<CartCheckoutMethod>) {
      state.checkoutMethod = action.payload;
    },
    setCartSource(state, action: PayloadAction<BookingSource>) {
      state.source = action.payload;
    },
    clearCartError(state) {
      state.status = 'STARTED';
      state.error = undefined;
    },
    resetCart(state) {
      state.status = 'EMPTY';
      state.numParticipants = undefined;
      state.priceDetails = undefined;
      state.availability = undefined;
      state.cart = undefined;
      state.error = undefined;
      Slice.caseReducers._updateCartPriceDetails(state);
    },
    _clearCart(state) {
      state.status = 'EMPTY';
      state.appointmentProduct = undefined;
      state.numParticipants = undefined;
      state.paymentMethod = undefined;
      state.participants = [];
      state.priceDetails = undefined;
      state.availability = undefined;
      state.cart = undefined;
      state.error = undefined;
    },
  },
});

export const {
  setCartCheckoutMethod,
  resetCart,
  clearCartError,
  setCartUTMData,
} = Slice.actions;

const {
  checkoutInitiated,
  checkoutComplete,
  checkoutError,
  setCart,
  setCartSource,
  _setCartPaymentMethod,
  _setCartAppointmentProduct,
  _addAvailabilityToCart,
  _setParticipants,
  _setNumParticipants,
  _clearCart,
} = Slice.actions;

export const clearCart = () => (dispatch, getState) => {
  dispatch(_clearCart());
};

// The reason not to use a debounced tracking method is that we
// would lose all context of changes occurring in a users cart.
// const debouncedTrack = debounce((event, data) => {
//   rudderanalytics.track(event, data);
// }, 5000);

type CartEvent =
  | 'Cart Authentication declined'
  | 'Cart updated'
  | 'Checkout cancelled'
  | 'Checkout started'
  | 'Purchase complete';
export const trackCartChanges =
  (event: CartEvent, action: string = undefined) =>
  (dispatch, getState) => {
    const timestamp = moment().format(DATE_FMT.UNIX_MS);
    const state = getState();
    const cartState = state.cart as Reducer;
    const activity = getActivity(state);
    const user = getUser(state);
    const availability = getCartAvailability(state);
    if (availability !== undefined) {
      const cartData = {
        userId: user?.id,
        username: user?.username,
        action,
        product: cartState?.checkoutMethod.capitalize(), // eg Availability
        bookingSource: cartState?.source,
        category: activity.name, // Swim lesson
        activity: activity.appointmentNoun,
        facility_id: availability.facility.id, // 156
        instructor_id: availability.instructor.id, // 18
        num_participants: cartState?.numParticipants,
        availability_id: availability.id, // UUID
        instructor: availability.instructor.displayName,
        facility: availability.facility.displayName,
        start_str: availability.startStr,
        url: location.href,
        currency: cartState?.priceDetails?.currency, // CAD
        subtotal: cartState?.priceDetails?.total, // 110
        utm_source: cartState.utmData?.source,
        utm_medium: cartState.utmData?.medium,
        utm_campaign: cartState.utmData?.campaign,
        timestamp: timestamp,
      };
      rudderanalytics.track(event, cartData);
    }
  };

export const setCartPaymentMethod =
  (paymentMethod: PaymentMethodSerializer) => (dispatch, getState) => {
    const state = getState();
    const oldData = getCartPaymentMethod(state);
    if (!oldData || oldData.id !== paymentMethod.id) {
      dispatch(_setCartPaymentMethod(paymentMethod));
      dispatch(trackCartChanges('Cart updated', 'Payment Method selected'));
    }
  };

export const setCartAppointmentProduct =
  (appointmentProduct: AppointmentProduct) => (dispatch, getState) => {
    dispatch(_setCartAppointmentProduct(appointmentProduct));
  };

export const fetchAvailabilityForCart =
  (
    availabilityId: string,
    checkoutMethod: CartCheckoutMethod,
    source: BookingSource
  ) =>
  async (dispatch, getState) => {
    const state = getState();
    const availability = getCartAvailability(state);
    if (availability && availabilityId === availability.id) {
      dispatch(trackCartChanges('Cart updated', 'Availability selected'));
      return true;
    }
    try {
      const response = await api.availability.retrieve(availabilityId);
      dispatch(setCartCheckoutMethod(checkoutMethod));
      dispatch(setCartSource(source));
      dispatch(_addAvailabilityToCart(response.data));
      dispatch(trackCartChanges('Checkout started'));
      dispatch(trackCartChanges('Cart updated', 'Availability selected'));
      return true;
    } catch (error) {
      logger.captureAxiosError(
        'Error in fetchAvailabilityForCart',
        error as AxiosError
      );
      return false;
    }
  };

export const addAvailabilityToCart =
  (
    availability: Availability,
    checkoutMethod: CartCheckoutMethod,
    source: BookingSource
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const oldData = getCartAvailability(state);
    if (!oldData || oldData.id !== availability.id) {
      dispatch(setCartCheckoutMethod(checkoutMethod));
      dispatch(setCartSource(source));
      dispatch(_addAvailabilityToCart(availability));
      if (!oldData) {
        dispatch(trackCartChanges('Checkout started'));
      }
      dispatch(trackCartChanges('Cart updated', 'Availability selected'));
    }
  };

export const setCartParticipants =
  (participants: Participant[]) => (dispatch, getState) => {
    const state = getState();
    const newParticipants = participants.filter((par) => par !== undefined);
    const oldParticipants = getCartParticipants(state);
    let changed = false;
    if (newParticipants.length !== oldParticipants.length) {
      changed = true;
    } else if (
      // Reduce the new participants to a single boolean, of whether
      // any participant in the array's ID has changed
      newParticipants.reduce(
        (agg, val, i) => (val.id !== oldParticipants[i].id ? true : agg),
        false
      )
    ) {
      changed = true;
    }
    if (changed) {
      dispatch(_setParticipants(newParticipants));
    }
  };

export const setNumParticipants =
  (numParticipants: number) => (dispatch, getState) => {
    const state = getState();
    const oldNum = getCartNumParticipants(state);
    if (numParticipants !== oldNum) {
      dispatch(_setNumParticipants(numParticipants));
      dispatch(trackCartChanges('Cart updated', 'Num Participants selected'));
    }
  };

export const checkout = () => async (dispatch, getState) => {
  const state = getState();
  if (getCartStatus(state) === 'PROCESSING') {
    return null;
  }
  const paymentMethod = getCartPaymentMethod(state);
  const availability = getCartAvailability(state);
  const participants = getCartParticipants(state);
  const checkoutMethod = getCartCheckoutMethod(state);
  const data: Pick<
    Cart,
    'availability' | 'participants' | 'paymentMethod' | 'checkoutMethod'
  > = {
    paymentMethod: paymentMethod.id,
    availability: availability.id,
    participants: participants.map((p) => p.id),
    checkoutMethod,
  };
  dispatch(checkoutInitiated());
  dispatch(trackCartChanges('Cart updated', 'Booking in progress'));
  try {
    const create = await api.cart.create({
      checkoutMethod,
      paymentMethod: paymentMethod.id,
      availability: availability.id,
    });
    const cart = create.data;
    dispatch(setCart(create.data));
    const response = await api.cart.checkout(cart.id, data);
    dispatch(trackCartChanges('Purchase complete'));
    dispatch(setCart(response.data));
    dispatch(invalidateScheduleInstructors());
    dispatch(checkoutComplete());
    return response.data;
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      return error;
    }
    if (
      // These errors are expected, so don't log them
      ![
        CartError.NotBookable,
        CartError.CartIsComplete,
        CartError.MaxTotalLimit,
        CartError.MaxDailyLimit,
        CartError.PaymentMethodFailed,
      ].includes(error.response.data.error)
    ) {
      logger.captureAxiosError('Error in checkout', error);
    }

    // Overwrites default error values with response specific ones:
    const apiError: CustomCartError<CheckoutErrors> = {
      message:
        'There was a problem processing your order request. Please try again.',
      error: 'generic',
      ...error.response.data,
    };
    dispatch(checkoutError(apiError));

    return error;
  }
};

export const fetchCartAppointmentProduct =
  (appointmentProductId: string) => async (dispatch, getState) => {
    const state = getState();
    const appointmentProduct = getCartAppointmentProduct(state);
    if (appointmentProduct && appointmentProductId === appointmentProduct.id) {
      return true;
    }
    try {
      const response = await api.appointmentProducts.retrieve(
        appointmentProductId
      );
      dispatch(setCartAppointmentProduct(response.data));
      return true;
    } catch (error) {
      logger.captureAxiosError(
        'Error in fetchAppointmentProduct',
        error as AxiosError
      );
      return false;
    }
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
