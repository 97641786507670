import Link from 'components/link';
import { QueryParams } from 'config';
import SignUpForm from 'containers/authentication/sign-up';
import Page from 'containers/page';
import TaskCard from 'containers/task-card';
import useQuery from 'hooks/useQuery';
import { SignUpGeneral as Meta } from 'metadata';
import React from 'react';
import { APP_ROUTES } from 'utils/routing';

const SignUp = () => {
  const query = useQuery();
  const nextParam = query.get(QueryParams.Next);

  return (
    <Page {...Meta}>
      <div className="bg-background min-h-screen">
        <TaskCard>
          <h2 className="mt-6 text-xl font-bold text-left text-gray-800">
            Create your Propel account
          </h2>
          <SignUpForm flow="SignUpPage" />
          <p className="mt-8 text-sm text-center text-gray-600">
            Have an account?{' '}
            <Link
              to={
                APP_ROUTES.LOGIN + `${nextParam ? `?${query.toString()}` : ''}`
              }
              tabIndex={5}
            >
              Sign in
            </Link>
          </p>
        </TaskCard>
      </div>
    </Page>
  );
};

export default SignUp;
