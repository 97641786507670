import Title from 'components/title';
import * as React from 'react';
import css from './style.module.scss';

interface ArticleProps {
  title?: string;
  className?: string;
  justify?: boolean;
  theme?: 'white' | 'blue';
  children?: any;
  width?: 'sm' | 'md' | 'lg' | 'xl';
}

const Article: React.FunctionComponent<ArticleProps> = ({
  title,
  className,
  theme,
  justify,
  children,
  width,
}) => (
  <section className={`${css.section} ${className} ${css[theme]}`}>
    <div className={css.container}>
      {!!title && (
        <Title color={theme === 'white' ? 'default' : 'white'} shade="600">
          {title}
        </Title>
      )}
      <div className={`${css.body} ${css[width]}${cls(justify, css.justify)}`}>
        {children}
      </div>
    </div>
  </section>
);

Article.defaultProps = {
  theme: 'white',
  justify: false,
  width: 'md',
};

export default Article;
