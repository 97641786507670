import React from 'react';
import { Element } from 'react-scroll';

interface Card {
  id?: string;
  children?: any;
  className?: string;
  title?: string;
  name?: string;
  height?: 1 | 2 | 3;
  space?: 'default' | 'tight';
  maxWidth?:
    | 'full'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl';
  minWidth?: '0' | '40' | '48' | '56' | '64' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const getMinWidth = (minWidth) =>
  minWidth === '0'
    ? 'min-w-0'
    : minWidth === '40'
    ? 'min-w-40'
    : minWidth === '48'
    ? 'min-w-48'
    : minWidth === '56'
    ? 'min-w-56'
    : minWidth === '64'
    ? 'min-w-64'
    : minWidth === 'xs'
    ? 'min-w-xs'
    : minWidth === 'sm'
    ? 'sm:min-w-sm'
    : minWidth === 'md'
    ? 'sm:min-w-sm md:min-w-md'
    : minWidth === 'lg'
    ? 'sm:min-w-sm md:min-w-md lg:min-w-lg'
    : minWidth === 'xl'
    ? 'sm:min-w-sm md:min-w-md lg:min-w-lg xl:min-w-xl'
    : 'min-w-xs';

const getMaxWidth = (maxWidth) =>
  maxWidth === 'full'
    ? 'max-w-full'
    : maxWidth === 'xs'
    ? 'max-w-xs'
    : maxWidth === 'sm'
    ? 'max-w-sm'
    : maxWidth === 'md'
    ? 'max-w-md'
    : maxWidth === 'lg'
    ? 'max-w-lg'
    : maxWidth === 'xl'
    ? 'max-w-xl'
    : maxWidth === '2xl'
    ? 'max-w-2xl'
    : maxWidth === '3xl'
    ? 'max-w-3xl'
    : maxWidth === '4xl'
    ? 'max-w-4xl'
    : maxWidth === '5xl'
    ? 'max-w-5xl'
    : maxWidth === '6xl'
    ? 'max-w-6xl'
    : 'max-w-sm';

const getHeight = (height) =>
  height === 1
    ? 'shadow'
    : height === 2
    ? 'shadow-md'
    : height === 3
    ? 'shadow-lg'
    : 'shadow-md';

const getSpace = (space) => (space === 'tight' ? 'p-card-tight' : 'p-card');

const Card = ({
  id,
  title,
  name,
  children,
  className,
  height,
  space,
  maxWidth,
  minWidth,
}: Card) => {
  const classes = `${getSpace(space)} ${getHeight(height)} ${getMaxWidth(
    maxWidth
  )} ${getMinWidth(minWidth)}${cls(className)}`;
  return (
    <>
      <Element className="px-0 mx-auto" name={name} />
      <div id={id} className={`mx-auto bg-white rounded-xl ${classes}`}>
        {title && (
          <h2 className="flex items-center pb-4 m-0 text-lg font-bold text-gray-800">
            {title}
          </h2>
        )}
        {children}
      </div>
    </>
  );
};

export default Card;
