import Label from 'components/input-label';
import Select, { SelectOption } from 'components/select';
import { DATE_FMT } from 'config';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

interface Props {
  name: string;
  /**
   * Earliest date allowed for input
   */
  earliestDate: moment.Moment;
  /**
   * Latest date allowed for input
   */
  latestDate: moment.Moment;
  initialDate?: string;
  onChange(date: string): void;
  autoFocus?: boolean;
  label?: string;
  maxHeight?: number;
  disabled?: boolean;
}

const defaultProps = {
  maxHeight: 300,
  disabled: false,
  autoFocus: false,
};

const MonthOptions: SelectOption<number>[] = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

const DateRanges = {
  January: (year: number) => 31,
  February: (year: number) => (year % 4 === 0 ? 29 : 28),
  March: (year: number) => 31,
  April: (year: number) => 30,
  May: (year: number) => 31,
  June: (year: number) => 30,
  July: (year: number) => 31,
  August: (year: number) => 31,
  September: (year: number) => 30,
  October: (year: number) => 31,
  November: (year: number) => 30,
  December: (year: number) => 31,
};

const InputFullDate: React.FC<Props> = (props) => {
  // const minYear: number = moment().year() - 15;
  // const latestYear: number = moment().year() - 80;
  if (props.earliestDate.isAfter(props.latestDate)) {
    throw new Error('Min date must be earlier in time than the max date');
  }
  const thisYear = moment().year();
  const earliestYear: number = props.earliestDate.year();
  const latestYear: number = props.latestDate.year();
  const [month, setMonth] = useState<number>();
  const [date, setDate] = useState<number>();
  const [year, setYear] = useState<number>();
  const [dateOptions, setDateOptions] = useState<SelectOption<number>[]>([]);
  const [monthOptions, setMonthOptions] = useState<SelectOption<number>[]>([]);
  const [yearOptions, setYearOptions] = useState<SelectOption<number>[]>([]);

  useEffect(() => {
    // init
    if (props.initialDate) {
      const initialDate = moment(props.initialDate, DATE_FMT.DATE_KEY);
      setMonth(initialDate.month() + 1); // moment months are zero indexed, so January is month 0.
      setDate(initialDate.date());
      setYear(initialDate.year());
    }
    setYearOptions(
      Array.from(new Array(latestYear - earliestYear), (val, index) => ({
        label: latestYear - index,
        value: latestYear - index,
      }))
    );
    setMonthOptions(MonthOptions);
  }, []);

  useEffect(() => {
    if (!year && !month && !date) {
      return;
    } else if (year && month) {
      const monthName = MonthOptions[month - 1].label;
      const max = DateRanges[monthName](year);
      setDateOptions(
        Array.from(new Array(max), (val, index) => ({
          label: index + 1,
          value: index + 1,
        }))
      );
    }
    if (month && date && year) {
      const fullDate = `${year}-${month}-${date}`;
      props.onChange(fullDate);
    }
  }, [month, date, year]);

  return (
    <div className="flex flex-col mt-4">
      {props.label && <Label htmlFor={props.name}>{props.label}</Label>}
      <div className="flex flex-col items-center space-y-2 sm:space-y-0 sm:flex-row sm:justify-between sm:space-x-2">
        <Select
          className="w-full sm:w-auto"
          maxMenuHeight={props.maxHeight}
          name="year"
          options={yearOptions}
          onChange={(opt: SelectOption<number>) => setYear(opt.value)}
          placeholder="Year"
          value={yearOptions.find((opt) => opt.value === year)}
          disabled={props.disabled}
        />
        <Select
          className="w-full sm:w-auto sm:flex-1"
          autoFocus={props.autoFocus}
          maxMenuHeight={props.maxHeight}
          name="month"
          options={monthOptions}
          onChange={(opt: SelectOption<number>) => setMonth(opt.value)}
          placeholder="Month"
          value={monthOptions.find((opt) => opt.value === month)}
          disabled={props.disabled || !year}
        />
        <Select
          className="w-full sm:w-24"
          maxMenuHeight={props.maxHeight}
          name="date"
          options={dateOptions}
          onChange={(opt: SelectOption<number>) => setDate(opt.value)}
          placeholder="Date"
          value={dateOptions.find((opt) => opt.value === date)}
          disabled={props.disabled || !month}
        />
      </div>
    </div>
  );
};

InputFullDate.defaultProps = defaultProps;

export default InputFullDate;
