import Link from 'components/link';
import { FETCH_STATE } from 'config';
import Page from 'containers/page';
import PasswordResetForm from 'features/password-reset-form';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { PasswordResetRequestMeta as Meta } from 'metadata';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAuthenticationFetchState } from 'state/selectors';
import { resetAuthState } from 'state/slice/authentication';
import { APP_ROUTES } from 'utils/routing';

const NewAccountSetup = () => {
  const fetchState = useSelector(getAuthenticationFetchState);
  const dispatch = useAppDispatch();

  const handleReset = () => {
    dispatch(resetAuthState());
  };

  return (
    <Page {...Meta}>
      <div className="flex justify-center px-4 py-12 bg-background min-h-90vh sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {fetchState !== FETCH_STATE.FULFILLED ? (
            <div className="px-4 pt-12 pb-16 mb-4 space-y-8 bg-white rounded-lg shadow-lg sm:px-12">
              <div>
                <h2 className="mt-6 text-xl font-bold text-left text-gray-800">
                  Setup your account
                </h2>
                <p className="text-sm text-gray-700">
                  Enter your email address, and we will send you a link to set
                  your password
                </p>
              </div>
              <PasswordResetForm />
            </div>
          ) : (
            <div className="px-4 pt-12 pb-16 mb-4 space-y-8 bg-white rounded-lg shadow-lg sm:px-12">
              <div>
                <h2 className="mt-6 text-xl font-bold text-left text-gray-800">
                  Thanks, check your email for instructions to set your password
                </h2>
              </div>
              <p>
                Didn't get the email? Check your spam folder or{' '}
                <span
                  className="font-medium text-blue-600 cursor-pointer hover:text-blue-500"
                  onClick={handleReset}
                >
                  try again
                </span>
                .
              </p>
            </div>
          )}
          <p className="mt-2 text-sm text-center text-gray-600">
            <Link
              to={APP_ROUTES.LOGIN}
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Return to sign in
            </Link>
          </p>
        </div>
      </div>
    </Page>
  );
};

export default NewAccountSetup;
