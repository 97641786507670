import CircleProgress from 'components/circular-progress';
import Tooltip from 'components/tooltip';
import { HelpIcon } from 'icons';
import React from 'react';

interface KpiProps {
  title?: string;
  value: number;
  type?: 'number' | 'money';
  total?: number;
  help?: string;
  color?: Color;
}

export const KPI = ({
  title,
  value,
  type = 'number',
  total,
  help,
  color = 'default',
}: KpiProps) => {
  return (
    <div className="relative flex flex-col flex-1 px-4 py-2 bg-gray-100 rounded-lg group">
      {title && (
        <div className="flex">
          <span className="flex-1 text-sm font-light text-left uppercase text-slate-600">
            {title}
          </span>
          {help && (
            <span className="btn-icon gray small">
              <Tooltip title={help} placement="top">
                <HelpIcon width={18} />
              </Tooltip>
            </span>
          )}
        </div>
      )}
      <div className="flex items-center flex-1">
        <h4 className="flex-1 my-0 text-2xl font-bold text-right text-slate-700 group-hover:text-slate-900 sm:text-left">
          {type === 'number'
            ? value
            : type === 'money'
            ? value.toCurrency()
            : value}
        </h4>
        {total && (
          <div className="flex items-center justify-end">
            <CircleProgress
              progress={Math.round((value / total) * 100)}
              size="lg"
              color={color}
              stroke={5}
            />
          </div>
        )}
      </div>
    </div>
  );
};
