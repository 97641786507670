import {
  ProposalListItem as ProposalListItemType,
  ProposalStatus,
} from 'api/Serializers/Proposals';
import Button from 'components/button';
import Link from 'components/link';
import { DATE_FMT, UserType } from 'config';
import ReadMore from 'containers/read-more';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail } from 'state/selectors';
import { getProposalExpiryText } from 'utils/date';
import { SHARED_ROUTES } from 'utils/routing';

interface Props {
  proposal: ProposalListItemType;
}

const ActionAwaitingClient = ({
  proposal,
  userType,
}: {
  proposal: ProposalListItemType;
  userType: UserType;
}) => {
  return (
    <div className="flex justify-end space-x-2">
      <Link to={SHARED_ROUTES.PROPOSALS.nav(proposal.id, 'cancel')}>
        <button className="btn gray">Cancel</button>
      </Link>
    </div>
  );
};

export const ProposalAction = ({
  proposal,
  onArchive,
}: {
  proposal: ProposalListItemType;
  onArchive: (evt) => void;
}) => {
  const user = useSelector(getAccountDetail);
  if (proposal.status === ProposalStatus.AwaitingClient) {
    return <ActionAwaitingClient proposal={proposal} userType={user.type} />;
  } else if (proposal.status === ProposalStatus.BookingSuccessful) {
    return (
      <div className="flex justify-end space-x-2">
        <Button variant="flat" size="small" color="default" onClick={onArchive}>
          Dismiss
        </Button>
      </div>
    );
  } else {
    return (
      <div className="flex justify-end space-x-2">
        <Button variant="flat" size="small" color="default" onClick={onArchive}>
          Dismiss
        </Button>
      </div>
    );
  }
};

const ProposalStatusIcon = ({ proposal }: Props) => {
  if (proposal.status === ProposalStatus.AwaitingClient) {
  }
};

const ProposalListItem = ({ proposal }: Props) => {
  const [init, setInit] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [remove, setRemove] = useState(
    proposal.status === ProposalStatus.BookingSuccessful
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const datetime = moment(proposal.start).tz(proposal.timezone);
  const expiry = moment(proposal.expiry).tz(proposal.timezone);
  const actionable = proposal.status === ProposalStatus.AwaitingClient;
  const handleArchive = async (event) => {
    setIsOpen(false);
    enqueueSnackbar({
      message: 'Archived',
      variant: 'success',
    });
    // setTimeout(() => dispatch(archiveProposal(proposal)), 300);
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
    } else if (proposal.status === ProposalStatus.BookingSuccessful) {
      enqueueSnackbar({
        message: `Booked ${datetime.format(DATE_FMT.DOW_MONTH_D_TIME_A)}`,
        variant: 'success',
      });
      setIsOpen(false);
      setTimeout(() => setRemove(true), 300);
    }
  }, [proposal.status]);

  const expiryText = getProposalExpiryText(proposal);

  if (remove) {
    return null;
  }

  return (
    <>
      <ReadMore
        initiallyOpen
        isOpen={isOpen}
        initialHeight={0}
        openBtnText=""
        closeBtnText=""
      >
        <div
          className={`transition-colors duration-300 ease-in-out flex justify-between flex-col sm:flex-row px-card py-6 md:py-3 text-sm space-y-2 sm:space-y-0 ${
            actionable
              ? 'bg-white text-gray-800'
              : 'text-gray-600 italic bg-gray-200'
          }`}
        >
          <div className="flex flex-col flex-1">
            <span className="font-semibold">
              {datetime.format(DATE_FMT.DOW_MONTH_D_TIME_A)}
            </span>
            {[ProposalStatus.AwaitingClient, ProposalStatus.Expired].some(
              (v) => v === proposal.status
            ) && <span className="text-opacity-50">{expiryText}</span>}
          </div>
          <div className="flex-1">
            <ProposalAction proposal={proposal} onArchive={handleArchive} />
          </div>
        </div>
      </ReadMore>
    </>
  );
};

export default ProposalListItem;
