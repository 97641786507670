import { InstructorListSerializer } from 'api/Serializers/Instructors';
import Dashboard from 'components/account/dashboard';
import Loading from 'components/loading';
import MenuWrap from 'components/menu-wrapper';
import Modal from 'components/modal';
import { FETCH_STATE } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { RouteParams } from 'models/route';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  getInstructorDetails,
  getInstructorFetchState,
  getInstructorListFetchState,
  getInstructorsAllSchedulable,
} from 'state/selectors';
import {
  clearInstructorDetail,
  fetchInstructorDetail,
} from 'state/slice/instructor';
import { LocalStore } from 'state/storage';
import { ADMIN_ROUTES } from '../utils';
import InstructorCard from './card';
import InstructorDetail from './detail';
import { baseColumns, Column } from './utils';

export enum ValidFilters {
  Orientation = 'orientations',
  GetBooked = 'get-booked',
  Prepare = 'prepare',
  Active = 'active',
  Inactive = 'inactive',
  Stale = 'stale',
  Suspended = 'suspended',
  Banned = 'banned',
  Search = 'search',
}

const InstructorDetailModal = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const instructor = useSelector(getInstructorDetails);
  const instructorFetchState = useSelector(getInstructorFetchState);
  const { params } = useRouteMatch<RouteParams>();

  useEffect(() => {
    if (
      params.instructorSlug &&
      (!instructor || instructor.username !== params.instructorSlug)
    ) {
      dispatch(fetchInstructorDetail(params.instructorSlug));
    } else if (!params.instructorSlug) {
      dispatch(clearInstructorDetail());
    }
  }, [params.instructorSlug]);

  const handleInstructorClose = () => {
    dispatch(clearInstructorDetail());
    history.push(ADMIN_ROUTES.INSTRUCTORS.ROOT);
  };

  return (
    <>
      <Modal
        name="Admin — Instructor detail"
        open={typeof instructor !== 'undefined'}
        onClose={handleInstructorClose}
      >
        <div className="w-full sm:w-128">
          <InstructorDetail onClose={handleInstructorClose} />
        </div>
      </Modal>
      {instructorFetchState === FETCH_STATE.GET && (
        <Loading message="Getting instructor..." />
      )}
    </>
  );
};

const InstructorList = ({ title, filter, defaultSorting, sorting }: Column) => {
  const instructors = useSelector(getInstructorsAllSchedulable);
  const [slice, setSlice] = useState<InstructorListSerializer[]>([]);

  const handleSorting = (func) => {
    setSlice(instructors.filter(filter).sort(func));
  };
  useEffect(() => {
    setSlice(instructors.filter(filter).sort(defaultSorting));
  }, [instructors]);
  return (
    <div className="w-1/4 space-y-3 min-w-xs">
      <div className="flex items-end justify-between h-10">
        <h4>
          {title} ({slice?.length})
        </h4>
        {sorting && (
          <MenuWrap>
            {sorting.map((sort, i) => (
              <button
                key={`btn-${i}`}
                onClick={() => handleSorting(sort.func)}
                className="px-2 py-2 text-left hover:bg-gray-200"
              >
                {sort.title}
              </button>
            ))}
          </MenuWrap>
        )}
      </div>
      {slice.map((instructor) => {
        return <InstructorCard key={instructor.id} instructor={instructor} />;
      })}
    </div>
  );
};

const InstructorsPage = (props) => {
  const settings = LocalStore.get('adm_hidden_cols');
  const [hiddenColumnSlugs, setHiddenColumns] = useState<string[]>(
    settings ? settings.split(',') : []
  );
  const fetchState = useSelector(getInstructorListFetchState);

  const handleColumnFilterClick = (slug: string) => {
    let newCols = [];
    if (hiddenColumnSlugs.some((s) => s === slug)) {
      newCols = [...hiddenColumnSlugs.filter((s) => s !== slug)];
    } else {
      newCols = [...hiddenColumnSlugs, slug];
    }
    LocalStore.set('adm_hidden_cols', newCols.join(','));
    setHiddenColumns(newCols);
  };

  return (
    <div className="absolute left-0 right-0">
      <Dashboard title="Instructors" width="full">
        <div className="">
          <div className="my-8 space-x-2">
            {baseColumns.map((column) => (
              <button
                key={column.slug}
                className={`btn ${
                  hiddenColumnSlugs.indexOf(column.slug) > -1 ? 'gray' : 'blue'
                }`}
                onClick={() => handleColumnFilterClick(column.slug)}
              >
                {column.title}
              </button>
            ))}
          </div>
          <div className="flex py-1 space-x-6 overflow-x-auto">
            {baseColumns
              .filter((column) => hiddenColumnSlugs.indexOf(column.slug) === -1)
              .map((column) => (
                <div key={column.slug}>
                  <InstructorList {...column} />
                </div>
              ))}
          </div>
        </div>
        {fetchState === FETCH_STATE.GET && (
          <Loading message="Loading instructors..." />
        )}
        <InstructorDetailModal />
      </Dashboard>
    </div>
  );
};

export default InstructorsPage;
