import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { UserType } from 'config';
import AttachImages from 'features/assistance/components/images';
import TextArea from 'features/assistance/components/textArea';
import {
  AssistanceWizardProps,
  ReportResolution,
  ReportType,
} from 'models/Assistance';
import React, { useEffect } from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const dataKey = 'otherIssueDescription';
const SelectProblem = (props: AssistanceWizardProps) => {
  const { appointment, trackAction, getReportData } = props;

  useEffect(() => {
    props.trackAction({
      type: 'SET_CATEGORY',
      key: 'category',
      value: 'GENERAL',
      title: 'What do you need help with?',
      description: 'General issues',
    });
  }, []);

  const handleNext = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'GENERAL' as ReportType,
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'NO_CHANGE',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'PROPEL_REVIEW' as ReportResolution,
      title: 'Next steps',
      description:
        'Propel will handle any further action needed by this submission',
    });
  };

  return (
    <>
      <h2>General Assistance</h2>
      <h4>Submit a general report</h4>
      <p>
        General reports are useful when you need to report something, but the
        issue is not described in any of the other assistance report types. It
        will be sent to Propel for review.
      </p>
      <Callout className="my-6" type="question">
        What would you like to report, and how should it be fixed?
      </Callout>
      <div className="my-8 space-y-6">
        <TextArea
          id={dataKey}
          title="What would you like to report, and how should it be fixed?"
          label="Briefly describe the issue:"
          placeholder="Today while teaching I noticed..."
          recipientUserType={UserType.Admin}
        />
        <AttachImages
          {...props}
          label="Include any relevant photos (optional):"
        />
      </div>
      <Controls variant="block">
        <BackButton>Go back</BackButton>
        <Button
          variant="contained"
          color="primary"
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
          onClick={handleNext}
        >
          Continue
        </Button>
      </Controls>
    </>
  );
};

export default SelectProblem;
