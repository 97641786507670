import { FavouriteNotificationFrequency } from 'api/Serializers/Accounts';
import { MIN_SWIMMER_AGE } from 'config';
import { OptionsObject, SnackbarMessage } from 'notistack';

export type SnackbarType = 'default' | 'success' | 'error';
export type SnackbarData = OptionsObject & { message: SnackbarMessage };
//export interface SnackbarData {
//  message: SnackbarMessage;
//  variant?: 'default' | 'success' | 'error' | 'warning' | 'info';
//  action?(): void;
//  anchorOrigin?: {
//    horizontal: 'left' | 'center' | 'right';
//    vertical: 'top' | 'bottom';
//  };
//  persist?: boolean;
//  preventDuplicate?: boolean;
//}

export const GenericServerError: SnackbarData = {
  message:
    'Error encountered, please try again. A report was submitted to Propel.',
  variant: 'error',
};
export const CancelProposalError: SnackbarData = {
  message: 'There was an error cancelling that proposal. Please try again.',
  variant: 'error',
};
export const FetchProposalError: SnackbarData = {
  message:
    'There was an error getting that proposal. Please confirm the link and try again.',
  variant: 'error',
};

export const MessagingAuthError: SnackbarData = {
  message:
    'Error starting the Propel messaging service. A report was submitted to Propel. Please refresh to try again.',
  variant: 'error',
};

export const MessageFailedError: SnackbarData = {
  message: 'Message failed to send. Please refresh and try again.',
  variant: 'error',
};

export const MessagingConvoFailedError: SnackbarData = {
  message:
    'Error starting conversation with user. Please refresh and try again.',
  variant: 'error',
};

export const AccountUpdateError: SnackbarData = {
  message: 'Error updating account. A report was submitted to Propel',
  variant: 'error',
};

export const InstructorProposalSubmitSuccess: SnackbarData = {
  message: 'Proposals have been sent to your client for approval!',
  variant: 'success',
};

export const ProposalBookingSuccess: SnackbarData = {
  message: 'Proposal has been booked!',
  variant: 'success',
};

export const ClientReviewSubmitSuccess: SnackbarData = {
  message: 'Review saved',
  variant: 'success',
};

export const FeedbackSubmitSuccess: SnackbarData = {
  message: 'Thanks! Your feedback has been received',
  variant: 'success',
};

export const FavouritesUpdated = (
  name: string,
  frequency: FavouriteNotificationFrequency
): SnackbarData => {
  if (frequency === FavouriteNotificationFrequency.NEVER) {
    return {
      message: `${name} added to favourites, but your frequency is set to "Never"`,
      variant: 'default',
    };
  }
  return {
    message: `${name} added to favourites`,
    variant: 'success',
  };
};

export const FavouritesFetchError: SnackbarData = {
  message: 'Could not get favourites; please try again later',
  variant: 'error',
};

export const FavouriteUpdateError: SnackbarData = {
  message: 'Could not update favourites; please try again later',
  variant: 'error',
};

export const PreviousAppointmentFeedbackFound: SnackbarData = {
  message: 'Feedback has already been submitted for this lesson',
  variant: 'success',
};

export const FeedbackErrorAppointmentInFuture: SnackbarData = {
  message: "Slow down there! This appointment hasn't happened yet.",
  variant: 'error',
};

export const GoogleReviewSubmitSuccess: SnackbarData = {
  message: 'Thanks for leaving a Google review!',
  variant: 'success',
};

export const RegionNotFound: SnackbarData = {
  message:
    "Sorry, we couldn't locate that region in our system. Please try your search again.",
  variant: 'error',
};

export const FacilityNotFound: SnackbarData = {
  message: "Sorry, we couldn't find that pool in our system. Please try again.",
  variant: 'error',
};

export const AssistanceSubmitSuccess: SnackbarData = {
  message: 'Problem report received',
  variant: 'success',
};

export const UserAppointmentCancelSuccess: SnackbarData = {
  message: 'Appointment has been cancelled',
  variant: 'success',
};

export const NumParticipantsChanged: SnackbarData = {
  message: 'Participants have been updated',
  variant: 'success',
};

export const ApplicationUpdated: SnackbarData = {
  message:
    'A new version is available! Please refresh your browser to access all the latest updates & features.',
  variant: 'info',
  persist: true,
};

export const SessionExpired: SnackbarData = {
  message: 'Your session has expired. Please reauthenticate.',
  variant: 'info',
  preventDuplicate: true,
};

export const InvalidLoginCredentials: SnackbarData = {
  message: 'Unable to login with those credentials',
  variant: 'error',
};

export const LoginSuccessfulMessage: SnackbarData = {
  message: "You're now logged in",
  variant: 'success',
};

export const LogoutSuccessfulMessage: SnackbarData = {
  message: 'You have been logged out on this device',
  variant: 'success',
};

export const InvalidNumberError = (value: string | number): SnackbarData => ({
  message: `The value "${value}" does not appear to be a number; please try again`,
  variant: 'error',
});

export const MinimumAgeError: SnackbarData = {
  message: `The minimum age for swimmers is ${MIN_SWIMMER_AGE} years of age.`,
  variant: 'error',
  preventDuplicate: true,
};

export const ErrorDeletingAppointmentProduct: SnackbarData = {
  message:
    "Can't remove facility with future availability, proposals or bookings.",
  variant: 'error',
};

export const AvailabilityFetchError: SnackbarData = {
  message: 'Error fetching availability. Please refresh to try again.',
  variant: 'error',
};
