import { Status } from 'api/Serializers/Accounts';
import Select, { SelectOption } from 'components/select';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAppointments,
  getInstructorDetails,
  getInstructorsSchedulable,
} from 'state/selectors';
import {
  clearInstructorDetail,
  fetchInstructorDetail,
} from 'state/slice/instructor';

const FilterOptionComponent = ({ label, value, ...props }) => {
  const appointments = useSelector(getAppointments);
  const { onMouseMove, onMouseOver, ...otherInnerProps } = props.innerProps;
  const num = appointments.filter(
    (e) => !e.cancelled && e.instructor.username === value
  ).length;
  return (
    <div
      className={`px-4 py-2 text-gray-700 cursor-pointer ${
        props.isFocused ? 'bg-blue-200' : ''
      } ${
        props.isSelected ? 'text-gray-800' : ''
      } text-base hover:bg-blue-200 hover:text-gray-800`}
      {...otherInnerProps}
    >
      {label}
      <div className="text-xs font-light uppercase">{`${num} ${'lesson'.pluralize(
        num
      )}`}</div>
    </div>
  );
};

const SelectInstructor = ({
  onSelect,
}: {
  onSelect?(value: SelectOption<string>): void;
}) => {
  const instructors = useSelector(getInstructorsSchedulable);
  const instructor = useSelector(getInstructorDetails);
  const dispatch = useAppDispatch();
  const handleSelect = (value: SelectOption<string>) => {
    if (value?.value) {
      dispatch(fetchInstructorDetail(value.value));
    } else {
      dispatch(clearInstructorDetail());
    }
  };
  const [options, setOptions] = useState<SelectOption<string>[]>([]);
  useEffect(() => {
    setOptions(
      instructors
        .filter((instructor) => instructor.status === Status.Active)
        .map((instructor) => {
          return {
            label: instructor.fullName,
            value: instructor.username,
          };
        })
    );
  }, [instructors]);

  if (!instructors) {
    return (
      <Select
        key="instructor-select"
        type="text"
        name="instructorText"
        placeholder="Loading..."
        options={[]}
      />
    );
  }

  return (
    <>
      <Select
        key="instructor-select"
        type="text"
        name="instructorText"
        placeholder="Select instructor"
        onChange={handleSelect}
        options={options}
        components={{ Option: FilterOptionComponent }}
        value={
          instructor
            ? {
                label: instructor.fullName,
                value: instructor.username,
              }
            : null
        }
        isClearable={true}
      />
    </>
  );
};

export default SelectInstructor;
