import Element from 'components/form-element';
import React from 'react';
// import style from './style.module.scss';

const InputLong = ({
  title,
  name,
  value,
  placeholder,
  required,
  onChange,
  rows = 4,
  className = '',
  disabled = false,
}) => (
  <Element
    inline={false}
    className={className}
    title={title}
    name={name}
    required={required}
  >
    <textarea
      disabled={disabled}
      key={name}
      name={name}
      cols={40}
      rows={rows}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  </Element>
);

export default InputLong;
