import { ProposalListItem, ProposalStatus } from 'api/Serializers/Proposals';
import { Tense } from 'api/Serializers/Schedules';
import { APPOINTMENT_LENGTH, DATE_FMT } from 'config';
import moment, { Moment } from 'moment-timezone';

/**
 * Calculates appropriate offset from computers localtime against datetime timezone
 * and returns formated datetime string displaying time local to its origin
 * @param dateStr ISO datestring to display in its local timezone
 * @param fmt format for the datestring
 */
export const format = (dateStr, fmt, timezoneId = '') => {
  return timezoneId === ''
    ? moment(dateStr).format(fmt)
    : moment(dateStr).tz(timezoneId).format(fmt);
};

export const toFullDateTime = (dateString) =>
  moment(dateString).format(DATE_FMT.MON_D_TIME_A);
export const toDisplayTime = (dateString) =>
  moment(dateString).format(DATE_FMT.TIME_A);
export const toShortTime = (dateString) => {
  const m = moment(dateString);
  const fmt = m.minute() === 0 ? DATE_FMT.HOUR_A : DATE_FMT.TIME_A;
  return moment(m, fmt);
};
export const toDateString = (date) => moment(date).format(DATE_FMT.YEAR_MO_DD);
export const toNewDate = (time) => moment(time).format(DATE_FMT.TIME_A);
export const toDisplayDate = (dateString) =>
  moment(dateString).format(DATE_FMT.MON_D_YEAR);
export const toServerDate = (date) => moment(date).format(DATE_FMT.SERVER_DATE);
export const toMoment = (date) => moment(date);

export const addMonths = (date, num) => moment(date).add(num, 'M');
export const addWeeks = (date, num) => moment(date).add(num, 'w');
export const getDay = (date) => moment(date).day();

export const isTaxSeason = () =>
  moment().isBetween(
    moment().month(1).startOf('month'),
    moment().month(4).endOf('month')
  );

/**
 * moment.isSame can give wrong results across timezones.
 * This compares the integer values of date, month, year to determine likeness.
 * @param m1 moment date to compare
 * @param m2 moment date to compare
 */
export const isSameDate = (m1: Moment, m2: Moment) =>
  m1.date() === m2.date() &&
  m1.month() === m2.month() &&
  m1.year() === m2.year();
export const isSameTimezone = (m1: Moment, m2: Moment) =>
  m1.hour() === m2.hour() &&
  m1.date() === m2.date() &&
  m1.month() === m2.month() &&
  m1.year() === m2.year();

const hourMins = 60;
const dayMins = hourMins * 24;
const weekMins = dayMins * 7;
const monthMins = weekMins * 4.33;
export const getTimeSinceText = (datetime: string, fullText = false) => {
  const test = moment();
  let prefix = '';
  let suffix = '';
  let recent = '';
  if (moment(datetime).isAfter(test)) {
    prefix = 'in ';
    suffix = '';
    recent = 'soon';
  } else {
    prefix = '';
    suffix = ' ago';
    recent = 'recently';
  }
  const mins = Math.abs(moment().diff(moment(datetime), 'minutes'));
  return mins > monthMins
    ? `${prefix}${Math.floor(mins / monthMins)}${
        fullText ? ` ${'month'.pluralize(Math.floor(mins / monthMins))}` : 'm'
      }${suffix}`
    : mins > weekMins
    ? `${prefix}${Math.floor(mins / weekMins)}${
        fullText ? ` ${'week'.pluralize(Math.floor(mins / weekMins))}` : 'w'
      }${suffix}`
    : mins > dayMins
    ? `${prefix}${Math.floor(mins / dayMins)}${
        fullText ? ` ${'day'.pluralize(Math.floor(mins / dayMins))}` : 'd'
      }${suffix}`
    : mins > hourMins
    ? `${prefix}${Math.floor(mins / hourMins)}${
        fullText ? ` ${'hour'.pluralize(Math.floor(mins / hourMins))}` : 'h'
      }${suffix}`
    : mins > 0
    ? `${prefix}${mins}${fullText ? ' mins' : 'm'}${suffix}`
    : recent;
};

// export const getReservedForText = (expiryDatetime: string) => {
//   const test = moment();
//   const expiry = moment(expiryDatetime);
//   const inFuture = expiry.isAfter(test);
//   if (!inFuture) {
//     return "No longer reserved";
//   }
// }

/**
 * Highly specific string generator function that returns human readable
 * text for how long ago or when will the datetime expire.
 *
 * Example strings evaluating time from now:
 *
 * +8 days from now returns "Expires in more than 7 days"
 *
 * -8 days returns "Expired more than 7 days ago"
 *
 * +28 hours returns "Expires in 1 day, 4 hours"
 *
 * -28 hours returns "Expired 1 day, 4 hours ago"
 *
 * +59 minutes returns "Expires soon"
 *
 * -59 minutes returns "Expired recently"
 *
 * @param proposal
 * @returns str
 */
export const getProposalExpiryText = (proposal: ProposalListItem) => {
  const expiry = proposal.expiry;
  const test = moment();
  const compare = moment(expiry);
  const inFuture = compare.isAfter(test);
  let endToday = '';
  let endTomrw = '';
  let endFuture = '';
  let endRecent = '';
  if (inFuture) {
    endFuture = 'Reserved until';
    endToday = 'Reservation ends today';
    endTomrw = 'Reserved until tomorrow';
    endRecent = 'Reservation ends soon';
  } else if (proposal.status === ProposalStatus.AwaitingClient) {
    return 'Not reserved but available';
  } else {
    endFuture = 'Reservation ended';
    endToday = 'Reservation ended today';
    endTomrw = 'Reservation ended yesterday';
    endRecent = 'Reservation ended recently';
  }
  const mins = Math.abs(moment().diff(compare, 'minutes'));
  const atStr = compare.format(DATE_FMT.TIME_A);
  const isToday =
    moment().format(DATE_FMT.DATE_KEY) === compare.format(DATE_FMT.DATE_KEY);
  const isTomorrow =
    moment().add(1, 'day').format(DATE_FMT.DATE_KEY) ===
    compare.format(DATE_FMT.DATE_KEY);
  const isYesterday =
    moment().subtract(1, 'day').format(DATE_FMT.DATE_KEY) ===
    compare.format(DATE_FMT.DATE_KEY);
  if (mins < hourMins) {
    return endRecent;
  } else if (isToday) {
    return `${endToday} at ${atStr}`;
  } else if (isTomorrow || isYesterday) {
    return `${endTomrw} at ${atStr}`;
  } else {
    return `${endFuture} ${compare.format(DATE_FMT.MONTH_D_TIME_A)}`;
  }
};

/**
 * @returns Tense enum value for a string date YYYY-MM-DD
 */
export const getDateTense = (date: string) => {
  const mdt = moment(date, DATE_FMT.DATE_KEY);
  return mdt.isSame(moment(), 'day')
    ? Tense.Today
    : mdt.isBefore(moment(), 'day')
    ? Tense.Past
    : Tense.Future;
};

/**
 * @returns Tense enum value for a string date YYYY-MM-DDTHH:MM:SSZ
 */
export const getDatetimeTense = (datetime: string) => {
  const diff = moment().diff(datetime, 'minutes');
  return diff < 0 // == in the future
    ? moment().isSame(datetime, 'date')
      ? Tense.Today
      : Tense.Future
    : diff < APPOINTMENT_LENGTH
    ? Tense.Now
    : Tense.Past;
};

export const getMonthStartEndParams = (datetime: string) => {
  const start = moment(datetime).startOf('month').format(DATE_FMT.DATE_KEY);
  const end = moment(datetime).endOf('month').format(DATE_FMT.DATE_KEY);
  return {
    start,
    end,
  };
};

export const getClientStartEndParams = () => {
  const start = moment().subtract(90, 'days').format(DATE_FMT.DATE_KEY);
  const end = moment().add(180, 'days').format(DATE_FMT.DATE_KEY);
  return {
    start,
    end,
  };
};
