import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { FacilitySchedulableSerializer } from 'api/Serializers/Facilities';
import { FETCH_STATE } from 'config';
import { RootState } from 'state';
import { getFacilitiesSchedulableFetchState } from 'state/selectors';
import { AppDispatch } from 'state/store';

interface FacilityReducer {
  schedulable: FacilitySchedulableSerializer[];
  detail: FacilitySchedulableSerializer;
  schedulableFetchState: string;
  selectedSlug: string;
}
const initialState: FacilityReducer = {
  schedulableFetchState: FETCH_STATE.IDLE,
  detail: undefined,
  selectedSlug: undefined,
  schedulable: [],
};

const name: 'facility' = 'facility';
const Slice = createSlice({
  name,
  initialState,
  reducers: {
    setScheduleSelectedFacility(
      state,
      action: PayloadAction<FacilitySchedulableSerializer>
    ) {
      state.detail = action.payload;
    },
    fetchFacilityListFailed(state) {
      state.schedulableFetchState = FETCH_STATE.FAILED;
    },
    fetchFacilityListStart(state) {
      state.schedulableFetchState = FETCH_STATE.GET;
    },
    fetchFacilityListSuccess(
      state,
      action: PayloadAction<FacilitySchedulableSerializer[]>
    ) {
      state.schedulable = action.payload;
      state.schedulableFetchState = FETCH_STATE.FULFILLED;
    },
  },
});

export const { setScheduleSelectedFacility } = Slice.actions;

const {
  fetchFacilityListSuccess,
  fetchFacilityListStart,
  fetchFacilityListFailed,
} = Slice.actions;

/**
 * Fetches the available facilities to work at for the signed in user
 */
export const fetchSchedulableFacilities =
  (params?: { forceNew?: 'true'; username?: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const fetchState = getFacilitiesSchedulableFetchState(getState());
    const forceNew = params?.forceNew === 'true';
    if (fetchState === FETCH_STATE.GET) {
      return null;
    } else if (fetchState === FETCH_STATE.FULFILLED && !forceNew) {
      return null;
    }
    dispatch(fetchFacilityListStart());
    try {
      const response = await api.facilities.schedulable(params);
      const data = response.data.sort(
        (a, b) => a.region.distance - b.region.distance
      );
      return dispatch(fetchFacilityListSuccess(data));
    } catch (err) {
      dispatch(fetchFacilityListFailed());
    }
    return null;
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
