import { AppointmentListSerializer } from 'api/Serializers/Appointments';
import Loading from 'components/loading';
import SelectFacility from 'components/select-facility';
import Title from 'components/title';
import { DATE_FMT, FETCH_STATE } from 'config';
import {
  DateTimeLoading,
  TodayEmpty,
} from 'features/schedule/date-time-list/card';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAppointmentsFetchState,
  getScheduleRenderDate,
} from 'state/selectors';
import EventListDay from '../event-list-day';
// import SelectClient from 'components/select-client';
import SelectInstructor from 'components/select-instructor';

const reduceUniqueDays = (agg: string[], val: AppointmentListSerializer) =>
  agg.indexOf(val.date) === -1 ? agg.concat(val.date) : agg;

const CalendarEventList = ({
  events,
}: {
  events: AppointmentListSerializer[];
}) => {
  const renderDate = useSelector(getScheduleRenderDate);
  const appointmentFetchState = useSelector(getAppointmentsFetchState);
  const today = moment().format(DATE_FMT.DATE_KEY);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (events) {
      const uniqueDays = [...events].reduce(reduceUniqueDays, []);
      if (
        moment().isSame(renderDate, 'month') &&
        uniqueDays.findIndex((d) => d === today) === -1 &&
        uniqueDays.length > 1
      ) {
        uniqueDays.push(today);
      }
      setDates(uniqueDays.sort((a, b) => (a > b ? 1 : -1)));
    }
  }, [events]);

  if (!safe(events)) {
    return (
      <Title shade="400" as="h5" height="tall" variant="caption">
        Loading...
      </Title>
    );
  }
  return (
    <div className="flex-1 px-4 space-y-4" id="_eventList">
      {appointmentFetchState === FETCH_STATE.GET && <Loading />}
      <div className="flex flex-col p-4 space-y-1 bg-white shadow sm:flex-row sm:space-y-0 sm:space-x-1 rounded-xl">
        {/* <div className="flex-1 text-gray-600">
          <h5 className="text-xs">Client</h5>
          <SelectClient />
        </div> */}
        <div className="flex-1 text-gray-600">
          <h5 className="text-xs">Instructor</h5>
          <SelectInstructor />
        </div>
        <div className="flex-1 text-gray-600">
          <h5 className="text-xs">Facility</h5>
          <SelectFacility />
        </div>
      </div>
      <div className="relative pb-16 space-y-12 transition-opacity duration-300 ease-in-out bg-background">
        {dates.length > 0 ? (
          dates.map((date) => {
            const dateEvents = [...events].filter(
              (event) => event.date === date
            );
            if (date === today && dateEvents.length === 0) {
              return <TodayEmpty key={`date-${date}`} date={date} />;
            }
            return (
              <EventListDay
                key={`date-${date}`}
                events={dateEvents}
                dateKey={date}
                sorting={'byFacility'}
              />
            );
          })
        ) : events.length === 0 ? (
          appointmentFetchState === FETCH_STATE.GET ? (
            <>
              <DateTimeLoading />
              <DateTimeLoading />
              <DateTimeLoading />
              <DateTimeLoading />
            </>
          ) : (
            <div className="m-2 sm:m-4">
              <div className="max-w-3xl px-6 py-4 mx-auto text-left text-gray-700 bg-white rounded-lg">
                <h3 className="my-2 text-lg font-semibold text-gray-900">
                  Zero Results
                </h3>
                <p>There were no appointments found for this month</p>
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default CalendarEventList;
