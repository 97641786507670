import { AccountUpdateSerializer } from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import InputLong from 'components/input-long';
import ProfileCard from 'components/instructor/profile-card';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  QuestionClasses,
  TitleClasses,
} from '..';

const debouncedSave = debounce(
  (
    modifyAccount: (data: Partial<AccountUpdateSerializer>) => void,
    data: Partial<AccountUpdateSerializer>
  ) => {
    modifyAccount(data);
  },
  500
);

const BasicProfile: React.FC<BaseStepProps> = ({ account, modifyAccount }) => {
  const [init, setInit] = useState(false);
  const [description, setDescription] = useState(account.description);
  const handleDescription = (event) => {
    setDescription(event.currentTarget.value);
  };

  useEffect(() => {
    if (init) {
      debouncedSave(modifyAccount, { description });
    } else {
      setInit(true);
    }
  }, [description]);

  return (
    <div>
      <h1 className={TitleClasses}>Finish your profile</h1>
      <p className={QuestionClasses}>What makes you stand out?</p>
      <h4 className="mb-1 font-semibold text-gray-700">Profile Pic</h4>
      <p className="mt-1 mb-3 text-gray-700">
        Choose a well lit photo that shows your face.
      </p>
      <ProfileCard
        specialization1={account.specialization1}
        specialization2={account.specialization2}
        segments={account.segments}
        avatar={account.avatar}
        displayName={account.displayName}
        numRecommended={0}
        basePrice={0}
      />
      <h4 className="mt-12 mb-1 font-semibold text-gray-700">
        Craft an engaging &quot;About Me&quot;
      </h4>
      <p className="mt-1 mb-3 text-gray-700">
        Tell potential clients about your teaching experience, unique style, and
        what makes you a great instructor — a short paragraph for each works
        best!
      </p>
      <InputLong
        title={''}
        name="description"
        value={description}
        placeholder="Describe your experience, teaching style, and what makes you a great instructor!"
        required={true}
        onChange={handleDescription}
      />
      <div className={ControlClasses}>
        <Button
          color="primary"
          variant="contained"
          to={InstructorOnboardingRoutes.TeachingPreferences}
          disabled={description === '' || account.avatar === ''}
        >
          Save and Continue
        </Button>
      </div>
    </div>
  );
};

export default BasicProfile;
