import React from 'react';
// import { connect } from 'react-redux';
// import {
//   getAccountDetail,
//   getScheduleFacilityFetchState,
// } from 'state/selectors';
// import Title from 'components/title';
// import { RENDER, FETCH_STATE } from 'config';
// import FacilityCard from 'components/facility-card';
// import Loading from 'components/loading';
// import { FacilitySchedulableSerializer } from 'api/Serializers/Facilities';
// import { HOST_ROUTES } from 'utils/routing';
// import { fetchSchedulableFacilities } from 'state/slice/facility';
// import { PropelAccount, Status } from 'api/Serializers/Accounts';
import HostAccount from 'api/Serializers/Accounts/Host';
import Dashboard from 'components/account/dashboard';
import Overview from '../overview';

interface Props {
  account: HostAccount;
}

const DashboardContainer: React.FC<Props> = ({ account, ...rest }) => {
  return (
    <Dashboard title="Overview">
      <Overview />
    </Dashboard>
  );
};

export default DashboardContainer;

// class HostDashboard extends React.Component<ReduxProps, {}> {
//   constructor(props: ReduxProps) {
//     super(props);
//     this.state = {
//     };
//   }

//   componentDidMount() {
//     this.props.fetchSchedulableFacilities();
//   }

//   public render() {
//     const { account, facilities, facilityFetchState } = this.props;
//     return (
//       <div>
//         <div className="p-8 mx-8 my-16 bg-white">
//           <Title as="h3" align="left">Hey {account.displayName}</Title>
//           <Title as="h4" align="left" shade="500" variant="subheader">Here's your current facilities:</Title>
//           <div className="my-8">
//           {!facilities || facilityFetchState === FETCH_STATE.GET ? (
//             <Loading />
//           ) : facilities.length === 0 ? (
//             <div>Admins will create your facility</div>
//           ) : facilities.length > 0 ? facilities.map(facility => (
//             <div className="my-4">
//               <Title as="h5" align="left" height="none">{facility.displayName}</Title>
//               <Title as="h5" align="left" height="none">Current status: {facility.status}</Title>
//             </div>
//           )) : <Loading />}
//           </div>
//           <p>
//             Dashboard functionality is severely limited at this time! Get in touch with the Propel team for any requests or issues.
//           </p>
//         </div>
//       </div>
//     );
//   }
// }

// export default connect(mapState, mapDispatch)(HostDashboard);
