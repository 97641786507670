import React from 'react';

import {
  AlertIcon,
  ErrorIcon,
  HelpIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from 'icons';

export type CalloutType =
  | 'default'
  | 'info'
  | 'success'
  | 'error'
  | 'warning'
  | 'question';

interface Props {
  title?: string;
  type?: CalloutType;
  icon?: JSX.Element;
  className?: string;
  children?: any;
}

const getIcon = (type: CalloutType) =>
  type === 'default' ? (
    <AlertIcon width={24} />
  ) : type === 'info' ? (
    <InfoIcon width={24} />
  ) : type === 'success' ? (
    <SuccessIcon width={24} />
  ) : type === 'error' ? (
    <ErrorIcon width={24} />
  ) : type === 'warning' ? (
    <WarningIcon width={24} />
  ) : type === 'question' ? (
    <HelpIcon width={24} />
  ) : null;

const typeColor = {
  default: 'gray',
  info: 'blue',
  success: 'green',
  error: 'red',
  warning: 'yellow',
  question: 'purple',
};
/**
 * text-gray-800 border-gray-800 bg-gray-100
 * text-blue-800 border-blue-800 bg-blue-100
 * text-green-800 border-green-800 bg-green-100
 * text-red-800 border-red-800 bg-red-100
 * text-yellow-800 border-yellow-800 bg-yellow-100
 * text-purple-800 border-purple-800 bg-purple-100
 */

const Callout = ({
  className,
  type = 'default',
  icon,
  title,
  children,
}: Props) => {
  const color = typeColor[type];
  const classes = `text-${color}-800 border-${color}-800 bg-${color}-100`;
  return (
    <div
      className={`py-3 pl-2 pr-3 border rounded-xl border-opacity-50 bg-opacity-50 flex ${
        className ? `${className} ` : ''
      }${classes}`}
    >
      <span
        className={`flex items-start justify-center text-lg opacity-80 text-${color}-800`}
      >
        {icon ? icon : getIcon(type)}
      </span>
      <div className="flex-1 ml-1.5 text-base font-normal">
        {title && <h4 className="leading-6 text-md">{title}</h4>}
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

export default Callout;
