import { ArrowForwardIcon } from 'icons';
import React from 'react';
import BaseButton, { ButtonProps } from './base';

const colors = {
  default: 'text-gray-800 hover:bg-gray-200 hover:text-gray-900',
  disabled: 'bg-gray-300 text-gray-600 pointer-events-none',
};

const ListButton = ({
  title,
  subtitle,
  disabled,
  icon,
  selected = false,
  ...rest
}: ButtonProps & {
  title: string;
  subtitle?: string;
}) => (
  <BaseButton
    className={`box-content w-full h-auto py-1.5 border-0 px-3 -mx-3 ${
      colors[disabled ? 'disabled' : 'default']
    }`}
    disabled={disabled}
    shape="rounded-xl"
    size="small"
    {...rest}
  >
    {icon && <span className="flex items-center">{icon}</span>}
    <div className="w-full text-left py-1.5 flex flex-col">
      <span className="text-base font-normal whitespace-pre-wrap">{title}</span>
      {subtitle && <span className="text-sm font-light">{subtitle}</span>}
    </div>
    <ArrowForwardIcon width={24} />
  </BaseButton>
);

export default ListButton;
