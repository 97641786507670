export default {
  base: {
    closed: 'transparent-bg italic text-gray-400 cursor-default',
    open: 'transparent-bg font-semibold border border-gray-200',
    available: '',
    unavailable: 'italic',
    pending:
      'font-semibold bg-amber-100 text-amber-700 hover:bg-amber-200 hover:text-amber-800 border-amber-200',
    booked:
      'bg-emerald-200 border border-solid border-emerald-300 bg-opacity-70 text-emerald-700 text-opacity-75 font-semibold hover:bg-emerald-300 hover:text-emerald-700',
    bookedUnavailable:
      'bg-emerald-200 border border-solid border-emerald-300 bg-opacity-70 text-emerald-700 text-opacity-75 font-semibold hover:bg-emerald-300 hover:text-emerald-700',
    bookedAvailable:
      'bg-emerald-200 border border-solid border-blue-300 bg-opacity-70 text-emerald-700 text-opacity-75 font-semibold hover:bg-emerald-300 hover:text-emerald-700',
  },
  static: {
    closed: '',
    open: 'text-gray-500 cursor-default',
    available:
      'font-semibold bg-blue-100 border border-solid border-blue-200 text-blue-700 text-opacity-70 cursor-default',
    unavailable:
      'font-medium bg-blue-100 border border-solid border-blue-200 text-blue-700 text-opacity-50 cursor-default',
    pending: '',
    booked: '',
  },
  changing: {
    closed: '',
    open: 'animate-pulse bg-blue-500 text-blue-700 cursor-default',
    available: 'animate-pulse bg-red-500 text-red-700 cursor-default',
    unavailable: 'animate-pulse bg-red-500 text-red-700 cursor-default',
    pending: '',
    booked: '',
  },
  proposal: {
    closed:
      'transparent-bg italic text-gray-500 text-opacity-80 cursor-default',
    open: 'font-semibold bg-blue-200 bg-opacity-80 text-blue-700 text-opacity-70 hover:bg-blue-300 hover:bg-opacity-80 hover:text-blue-800 cursor-pointer',
    available:
      'font-semibold bg-blue-200 bg-opacity-80 text-blue-700 text-opacity-70 hover:bg-blue-300 hover:bg-opacity-80 hover:text-blue-800 cursor-pointer',
    unavailable:
      'font-medium bg-gray-200 bg-opacity-40 text-gray-700 text-opacity-60 cursor-default',
    pending:
      'font-semibold bg-orange-200 bg-opacity-30 text-orange-600 text-opacity-30 cursor-default',
    booked:
      'bg-emerald-300 bg-opacity-20 text-emerald-600 text-opacity-30 font-semibold cursor-default',
  },
  available: {
    closed: 'font-medium transparent-bg italic text-gray-400 cursor-default',
    open: 'font-semibold text-gray-500 hover:bg-gray-200 hover:text-gray-600 border border-gray-200 cursor-pointer',
    available:
      'font-semibold bg-blue-100 border border-solid border-blue-200 text-blue-700 text-opacity-70 hover:bg-blue-200 hover:bg-opacity-60 hover:text-opacity-90 cursor-pointer',
    unavailable:
      'font-medium bg-blue-100 border border-solid border-blue-200 text-blue-700 text-opacity-80 hover:bg-blue-200 hover:bg-opacity-50 hover:text-opacity-100 cursor-pointer',
    removing:
      'font-medium bg-red-100 border border-solid border-red-200 text-red-700 text-opacity-80 hover:bg-red-200 hover:bg-opacity-50 hover:text-opacity-100 cursor-pointer',
    pending:
      'font-semibold bg-orange-200 bg-opacity-30 text-orange-600 text-opacity-30 cursor-default',
    booked:
      'bg-emerald-300 bg-opacity-20 text-emerald-600 text-opacity-30 font-semibold cursor-default',
    bookedUnavailable:
      'bg-emerald-200 border-2 border-solid border-emerald-200 bg-opacity-70 text-emerald-700 text-opacity-75 font-semibold hover:bg-emerald-300 hover:text-emerald-700 cursor-pointer',
    bookedAvailable:
      'bg-emerald-200 border-2 border-solid border-blue-300 bg-opacity-70 text-emerald-700 text-opacity-75 font-semibold hover:bg-blue-200 hover:text-blue-700 cursor-pointer',
  },
};
