import Loading from 'components/loading';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'utils/routing';

const FacilityErrorRedirect = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useAppDispatch();
  useEffect(() => {
    enqueueSnackbar({
      message: 'This location is not currently open',
      variant: 'error',
    });
    setTimeout(() => history.push(APP_ROUTES.BROWSE_CITIES), 5000);
  }, []);
  return <Loading />;
};

export default FacilityErrorRedirect;
