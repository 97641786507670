import AddIcon from '@mui/icons-material/Add';
import api from 'api';
import { Client, Instructor } from 'api/Serializers/Appointments';
import {
  Proposal,
  ProposalListItem as ProposalListItemType,
} from 'api/Serializers/Proposals';
import { SchedulableObject } from 'api/Serializers/Schedules';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import Fab from 'components/button-fab';
import ListButton from 'components/button/list';
import Callout from 'components/callout';
import Card from 'components/card';
import Controls from 'components/controls';
import EventParticipants from 'components/event-participants';
import FloatingMenu from 'components/floating-menu';
import Loading from 'components/loading';
import { DATE_FMT, FETCH_STATE, QueryParams } from 'config';
import ProposalActionModal from 'features/proposals/action-modal';
import ProposalListHeader from 'features/proposals/list-header';
import ProposalListItem from 'features/proposals/list-item';
import ProposalDetail from 'features/schedule/as-instructor/proposals/detail';
import { DateCardTitle } from 'features/schedule/date-time-list/card';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { getProposalListFetchState, getProposals } from 'state/selectors';
import { fetchProposals, removeListProposal } from 'state/slice/proposals';
import { getUrlParam, SHARED_ROUTES, SHOW_HELP } from 'utils/routing';
import { ADMIN_ROUTES } from '../utils';

const ActionMenu = () => {
  return (
    <FloatingMenu>
      <span>
        <Fab
          color="blue"
          className="animate-pop"
          text="New Proposal"
          icon={AddIcon}
          height={3}
          to={SHARED_ROUTES.PROPOSALS.ROOT}
        />
      </span>
    </FloatingMenu>
  );
};

const ClientProposals = ({
  client,
  proposals,
  handleDecline,
}: {
  client: Client;
  proposals: ProposalListItemType[];
  handleDecline(proposal: Proposal): void;
}) => {
  const instructors = proposals.reduce(
    (agg: Instructor[], val: ProposalListItemType, i) =>
      agg.find((inst) => inst.id === val.instructor.id)
        ? agg
        : agg.concat([val.instructor]),
    []
  );
  return (
    <div>
      {instructors.map((instructor, i) => (
        <Card
          key={`ins-${i}`}
          name={'cli-' + client.id}
          className="mb-12"
          maxWidth="full"
          space="tight"
        >
          <DateCardTitle
            title={`${client.fullName}: ${
              proposals.length
            } ${'Proposal'.pluralize(proposals.length)}`}
          />
          <div className="space-y-8">
            <EventParticipants event={proposals[0]} showStatus={false} />
            <div className="space-y-8">
              {proposals.length > 0 ? (
                <div>
                  <h6>Current Proposals</h6>
                  <div className="-mx-card-tight">
                    <ProposalListHeader />
                    {proposals.map((proposal) => (
                      <ProposalListItem key={proposal.id} proposal={proposal} />
                    ))}
                  </div>
                </div>
              ) : (
                <p className="text-sm text-gray-800">
                  No proposals to book at this time
                </p>
              )}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

const reduceUniqueClients = (agg: Client[], val: SchedulableObject) =>
  agg.find((i) => i.id === val.client.id) ? agg : agg.concat(val.client);

const Proposals = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDecline, setConfirmDecline] = useState<Proposal>();
  const allProposals = useSelector(getProposals);
  const fetchState = useSelector(getProposalListFetchState);
  const proposalId = getUrlParam(QueryParams.ProposalId);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getClientProposals = (id) =>
    allProposals.filter((p) => p.client.id === id);

  useEffect(() => {
    dispatch(fetchProposals());
  }, []);

  useEffect(() => {
    if (allProposals) {
      setClients(
        allProposals
          .reduce(reduceUniqueClients, [])
          .sort((a, b) => (a.fullName < b.fullName ? -1 : 1))
      );
    } else {
      setClients([]);
    }
  }, [allProposals]);

  const handleNameClick = (target: string) => {
    scroller.scrollTo(target, {
      smooth: true,
      duration: 350,
      offset: -96,
    });
  };

  const handleClose = () => {
    history.push(ADMIN_ROUTES.PROPOSALS.ROOT);
  };

  const handleDecline = async (proposal: Proposal) => {
    if (!confirmDecline || confirmDecline.id !== proposal.id) {
      setConfirmDecline(proposal);
    } else {
      setIsLoading(true);
      setConfirmDecline(undefined);
      const timeStr = moment(proposal.start).format(
        DATE_FMT.DOW_MONTH_D_TIME_A
      );
      try {
        const response = await api.proposals.cancel(proposal.id);
        enqueueSnackbar({
          message: `Proposal for ${timeStr} has been cancelled.`,
          variant: 'success',
        });
        dispatch(removeListProposal(response.data));
      } catch (error) {
        enqueueSnackbar({
          message: `There was an error declining the proposal for ${timeStr}. Please try again.`,
          variant: 'error',
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <Dashboard
      title="Lesson Proposals"
      width="5xl"
      onClickHelp={SHOW_HELP.TEACHING.PROPOSALS.HOW_DO_LESSON_PROPOSALS_WORK}
    >
      <div className="flex flex-col justify-center flex-1 space-y-8 sm:space-y-0 sm:space-x-4 sm:flex-row">
        <div className="flex-1 flex-shrink sm:flex-none sm:w-48 md:w-56">
          <div className="relative flex flex-col p-2 space-y-1 overflow-auto bg-white rounded-lg shadow-md sm:sticky sm:top-20 max-h-90vh">
            <ListButton
              to={SHARED_ROUTES.PROPOSALS.ROOT}
              icon={<AddIcon />}
              title="New Proposal"
            />
            <hr />
          </div>
        </div>
        <div className="flex-1">
          {fetchState === FETCH_STATE.FULFILLED && allProposals.length === 0 && (
            <div>
              <Callout title="Set your clients up for success" type="info">
                Some clients prefer the same lesson time, week after week. Some
                have been with you for so long that you'd like to give them
                advanced notice of your schedule. Proposals let you schedule
                your clients into available time slots, preferentially.
                <Controls>
                  <Button
                    onClick={
                      SHOW_HELP.TEACHING.PROPOSALS.HOW_DO_LESSON_PROPOSALS_WORK
                    }
                  >
                    Learn More
                  </Button>
                </Controls>
              </Callout>
            </div>
          )}
          {clients.map((client, i) => {
            const proposals = getClientProposals(client.id);
            if (proposals.length === 0) {
              return null;
            }
            return (
              <ClientProposals
                key={'pc-' + client.id}
                client={client}
                proposals={proposals}
                handleDecline={handleDecline}
              />
            );
          })}
        </div>
      </div>
      <ProposalActionModal />
      <ProposalDetail id={proposalId} onClose={handleClose} />
      <ActionMenu />
      {fetchState === FETCH_STATE.GET && <Loading />}
      {isLoading && <Loading />}
    </Dashboard>
  );
};

export default Proposals;
