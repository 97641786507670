import {
  ScheduleChange,
  ScheduleChangeAction,
} from 'api/Serializers/Schedules';
import { DATE_FMT } from 'config';
import InstructorBaseTimeSlot from 'features/schedule/as-instructor/base-time-slot';
import { TimeSlotProps } from 'features/schedule/date-time-list/time-slot';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { getScheduleAppointmentProduct } from 'state/selectors';
import { addScheduleChange } from 'state/slice/schedule';

const AvailableTimeSlot = ({
  timeSlot,
  tense,
  aptProdId,
  isChanged,
  description,
}: TimeSlotProps) => {
  const timezone = useSelector(getScheduleAppointmentProduct).timezone;
  const dispatch = useAppDispatch();
  let classes: string;
  let handleClick = null;
  let newDesc;
  if (isChanged) {
    newDesc = timeSlot.isBookable
      ? '* Add availability'
      : `* Add availability, ${(description as string).toLowerCase()}`;
    classes =
      'font-semibold text-blue-800 bg-blue-100 hover:bg-blue-200 hover:text-blue-900';
  } else {
    newDesc = timeSlot.isBookable ? 'Currently available' : description;
    classes =
      'font-semibold text-blue-800 bg-blue-50 hover:bg-blue-100 hover:text-blue-900';
  }
  handleClick = () => {
    const change: ScheduleChange = {
      aptProdId,
      id: timeSlot.id,
      datetime: timeSlot.datetime,
      date: moment(timeSlot.datetime).tz(timezone).format(DATE_FMT.DATE_KEY),
      action: ScheduleChangeAction.Delete,
    };
    dispatch(addScheduleChange(change));
  };
  return (
    <InstructorBaseTimeSlot
      onClick={handleClick}
      datetime={timeSlot.datetime}
      timezone={timezone}
      description={newDesc}
      classes={classes}
    />
  );
};

export default AvailableTimeSlot;
