import CancelIcon from '@mui/icons-material/Cancel';
import ClientIcon from '@mui/icons-material/Face';
import { TimeFrame } from 'api/Serializers/Appointments';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { getReportTypeDescription } from 'features/assistance/as-instructor/utils';
import {
  AssistanceWizardProps,
  ReportResolution,
  ReportType,
} from 'models/Assistance';
import React from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const SelectProblem = (props: AssistanceWizardProps) => {
  const { appointment, trackAction } = props;
  const timeFrame = props.appointment.timeFrame;
  const handleClientNoShow = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'CLIENT_NOT_HERE' as ReportType,
      title: 'What seems to be the problem?',
      description: getReportTypeDescription('CLIENT_NOT_HERE'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'CLIENT_CANCELLATION' as ReportResolution,
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'CLIENT_NO_SHOW',
    });
  };

  const handleClientCancelClick = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'CLIENT_CANCEL_REQUEST' as ReportType,
      title: 'What seems to be the problem?',
      description: getReportTypeDescription('CLIENT_CANCEL_REQUEST'),
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'CLIENT_CANCELLATION' as ReportResolution,
    });
  };
  return (
    <>
      <h2>Client Assistance</h2>
      <h4>Manage client related issues</h4>
      <Callout
        className="my-8"
        type="question"
        title="What seems to be the problem?"
      />
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="Client would like to cancel"
          subtitle="Client has said they will not attend"
          icon={<CancelIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'client/cancel')}
          disabled={timeFrame >= TimeFrame.HasEnded}
          onClick={handleClientCancelClick}
        />
        <ButtonLarge
          title="Client never showed up"
          subtitle="The client never showed up to the lesson, and the instructor did not report it"
          icon={<ClientIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'client/cancel')}
          disabled={timeFrame < TimeFrame.HasEnded}
          onClick={handleClientNoShow}
        />
        {/* <ButtonLarge
          title="Change number of swimmers"
          subtitle="Modify the number of swimmers in this lesson"
          icon={<SwimmersIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(
            appointment.id,
            'client/change-participants'
          )}
          onClick={() =>
            trackAction({
              type: 'SET_TYPE',
              key: 'type',
              value: 'CHANGE_NUM_PARTICIPANTS' as ReportType,
              title: 'What seems to be the problem?',
              description: getReportTypeDescription('CHANGE_NUM_PARTICIPANTS'),
            })
          }
        /> */}
      </div>
      <Controls variant="block">
        <BackButton>Go back</BackButton>
      </Controls>
    </>
  );
};

export default SelectProblem;
