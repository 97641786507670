import Avatar from 'components/avatar';
import { DATE_FMT } from 'config';
import ReadMore from 'containers/read-more';
import moment from 'moment-timezone';
import React from 'react';

const Review = ({
  comment,
  reviewer,
  createdAt = '',
  avatar = '',
  title = '',
  truncate = true,
}: {
  comment: string;
  reviewer: string;
  createdAt: string;
  avatar?: string;
  title?: string;
  truncate?: boolean;
}) => (
  <div className="flex flex-col gap-2">
    <div className="flex items-center gap-4">
      <Avatar className="bg-blue-500" src={avatar}>
        {!avatar ? reviewer.charAt(0).capitalize() : null}
      </Avatar>
      <div className="flex flex-col">
        <h5 className="font-medium">{reviewer}</h5>
        <div className="text-gray-600">
          {moment(createdAt).format(DATE_FMT.MONTH_D_YEAR)}
        </div>
      </div>
    </div>
    {!!title && <h5 className="font-medium">{title}</h5>}
    {truncate ? (
      <ReadMore initialHeight={75}>
        <div>{comment}</div>
      </ReadMore>
    ) : (
      <div>{comment}</div>
    )}
  </div>
);

export default Review;
