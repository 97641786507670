import api from 'api';
import InstructorAccount from 'api/Serializers/Accounts/Instructor';
import { AppointmentProduct } from 'api/Serializers/AppointmentProducts';
import { DaySerializer } from 'api/Serializers/Facilities';
import { WeeklySchedule } from 'api/Serializers/Schedules';
import Dashboard from 'components/account/dashboard';
import Avatar from 'components/avatar';
import Button from 'components/button';
import ListButton from 'components/button/list';
import Callout from 'components/callout';
import Controls from 'components/controls';
import InputCalendar from 'components/input-calendar';
import Loading from 'components/loading';
import Modal from 'components/modal';
import Slider from 'components/slider';
import {
  DATE_FMT,
  DAYS_OF_WEEK_JS,
  DAYS_OF_WEEK_PY,
  WeekdayLower,
} from 'config';
import ReadMore from 'containers/read-more';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useFocus from 'hooks/useFocus';
import { GenericServerError } from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  getCurrentUser,
  getScheduleAppointmentProduct,
  getScheduleWeeklySchedule,
} from 'state/selectors';
import {
  addNewWeeklySchedule,
  clearWeeklySchedules,
  setAppointmentProduct,
  submitWeeklySchedules,
  updateWeekdaySchedule,
} from 'state/slice/schedule';
import { SHARED_ROUTES } from 'utils/routing';
import { SelectAppointmentProduct } from '../../select-appointment-product';

const SlideOptions = {
  centerMode: false,
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 0,
  arrows: true,
  dots: true,
  className: 'px-8 md:px-0',
  speed: 250,
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const WeekdaySchedule = ({
  start,
  end,
  appointmentProduct,
}: {
  start: string;
  end: string;
  appointmentProduct: AppointmentProduct;
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const weeklySchedules = useSelector(getScheduleWeeklySchedule);

  useEffect(() => {
    dispatch(clearWeeklySchedules());
    (async () => {
      setIsLoading(true);
      const response = await api.facilities.schedules(
        appointmentProduct.facility.slug,
        {
          start,
          end,
        }
      );
      response.data.map((schedule) => {
        const from = moment(start).isBefore(schedule.startDate)
          ? schedule.startDate
          : start;
        const to = moment(end).isAfter(schedule.endDate)
          ? schedule.endDate
          : end;
        dispatch(
          addNewWeeklySchedule(from, to, appointmentProduct.id, schedule)
        );
      });
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (weeklySchedules.length === 0) {
    return (
      <div>
        <Callout title="No weekly schedules available" type="warning">
          It looks like this location is not available over that date range.
          Select new dates, or try another location.
        </Callout>
      </div>
    );
  }

  return (
    <div>
      <h3 className="text-gray-700 ">Set your weekly hours</h3>
      <div className="space-y-8">
        {weeklySchedules.map((schedule, i) => (
          <Schedule key={schedule.start} weeklySchedule={schedule} />
        ))}
      </div>
      <p className="my-2 text-sm text-gray-600">
        * Your availability will always shift to match existing lessons or
        proposals. It will also skip days where you're working at another
        location.{' '}
        <span className="link" onClick={() => setReadMore(!readMore)}>
          {readMore ? 'Close' : 'Learn More'}
        </span>
      </p>
      <ReadMore initialHeight={0} isOpen={readMore}>
        <div className="pt-1">
          <Callout type="info">
            <p className="mb-6 text-sm text-gray-600">
              If your availability is set to start on the half-hour (:30), but
              you have a lesson or proposal which starts on the hour (:00), then
              for that day only, your availability will be shifted by +30
              minutes so that your start times line up with existing bookings.
            </p>
            <p className="mb-6 text-sm text-gray-600">
              If you have any lessons, proposals, or availability already
              scheduled at another Propel location, the weekly schedule tool
              will not add availability to those days.
            </p>
          </Callout>
        </div>
      </ReadMore>
    </div>
  );
};

const Schedule = ({ weeklySchedule }: { weeklySchedule: WeeklySchedule }) => {
  const dispatch = useAppDispatch();
  const handleWeekdayChange = (weekday: WeekdayLower, selected: string[]) => {
    dispatch(updateWeekdaySchedule(weeklySchedule.start, weekday, selected));
  };
  if (
    weeklySchedule.schedule.publicAccessDate &&
    moment().isBefore(weeklySchedule.schedule.publicAccessDate, 'date')
  ) {
    return (
      <div className="p-4 mx-auto space-y-4 border border-gray-500 rounded-xl">
        <h5 className="font-medium text-gray-700 ">
          From {moment(weeklySchedule.start).format(DATE_FMT.DOW_SHORT_MON_D)}{' '}
          to {moment(weeklySchedule.end).format(DATE_FMT.DOW_SHORT_MON_D)}
        </h5>
        <Callout type="info" title="Schedule not public">
          This schedule opens for general availability on{' '}
          {moment(weeklySchedule.schedule.publicAccessDate).format(
            DATE_FMT.DOW_MONTH_D_YEAR
          )}
          .
        </Callout>
      </div>
    );
  }
  return (
    <div className="grid w-full grid-cols-7 gap-2 p-2 mx-auto border border-gray-500 rounded-xl sm:gap-4 sm:p-4">
      <h5 className="my-0 font-medium text-gray-700 col-span-full">
        From {moment(weeklySchedule.start).format(DATE_FMT.DOW_SHORT_MON_D)} to{' '}
        {moment(weeklySchedule.end).format(DATE_FMT.DOW_SHORT_MON_D)}
      </h5>
      <div className="col-span-full">
        <Slider settings={SlideOptions} arrowColor="gray" arrowSize="xs">
          {DAYS_OF_WEEK_JS.map((Day) => (
            <Weekday
              key={`dow-${Day}`}
              weekday={Day.toLowerCase() as WeekdayLower}
              schedule={weeklySchedule.schedule[Day.toLowerCase()]}
              onSelect={handleWeekdayChange}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

const baseClasses =
  'rounded px-1 py-1.5 border text-xs text-center animate-pop font-medium focus:ring-1';
const activeClasses = `${baseClasses} text-blue-700 bg-blue-100 border-blue-700 hover:bg-blue-200 hover:bg-opacity-60 border-opacity-50`;
const inactiveClasses = `${baseClasses} text-gray-600 bg-gray-50 border-gray-300 hover:bg-gray-200 hover:text-gray-600 focus:ring-gray-500`;
const Weekday = ({
  weekday,
  schedule,
  onSelect,
}: {
  weekday: WeekdayLower;
  schedule: DaySerializer;
  onSelect(weekday: WeekdayLower, selected: string[]): void;
}) => {
  const [minuteStart, setMinuteStart] = useState(
    schedule ? moment(schedule.open, DATE_FMT.TIME_FIELD).minutes() : undefined
  );
  const [availHours, setAvailHours] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleClear = () => setSelected([]);
  const handleAdd = (hr) => setSelected((sel) => [...sel, hr]);
  const handleRemove = (hr) =>
    setSelected((sel) => sel.filter((h) => h !== hr));
  const handleSwitch = (evt) => setMinuteStart((val) => (val === 0 ? 30 : 0));
  const handleClick = (hr) => (event) => {
    if (selected.indexOf(hr) > -1) {
      handleRemove(hr);
    } else {
      handleAdd(hr);
    }
  };
  const handleOpenClose = () => {
    if (isOpen) {
      // will close
      handleClear();
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!schedule) {
      return;
    }
    const _availHours = [];
    let nextSpot;
    const open = moment(schedule.open, DATE_FMT.TIME_FIELD);
    const lastAptTime = moment(schedule.close, DATE_FMT.TIME_FIELD).subtract(
      60,
      'minutes'
    );
    nextSpot = minuteStart === open.minutes() ? open : open.add(30, 'minutes');
    while (nextSpot.isSameOrBefore(lastAptTime, 'minute')) {
      _availHours.push(moment(nextSpot).format(DATE_FMT.TIME_A));
      nextSpot.add(60, 'minutes');
    }
    setAvailHours(_availHours);
    handleClear();
  }, [minuteStart]);

  useEffect(() => {
    onSelect(weekday, selected);
  }, [selected]);

  return (
    <div className="flex flex-col p-2">
      <h4 className="text-sm font-bold text-left text-gray-800">
        {weekday.capitalize()}
      </h4>
      <h5 className="mb-0.5 text-xs font-normal text-left text-gray-600">
        Facility hours:
      </h5>
      <h5 className="text-xs font-medium text-left text-gray-700">
        {schedule
          ? `${moment(schedule.open, DATE_FMT.TIME_FIELD).format(
              DATE_FMT.TIME_A
            )} to ${moment(schedule.close, DATE_FMT.TIME_FIELD).format(
              DATE_FMT.TIME_A
            )}`
          : 'Closed'}
      </h5>
      {!!schedule && (
        <React.Fragment>
          <h5 className="mb-1 text-xs font-normal text-left text-gray-600">
            My Hours:
          </h5>
          <button
            disabled={!schedule}
            className="btn gray"
            onClick={handleOpenClose}
          >
            {isOpen ? 'Cancel' : 'Set Hours'}
          </button>
          <ReadMore isOpen={isOpen} initialHeight={0}>
            <div className="flex flex-col pt-px pb-4 space-y-1">
              <span
                className="my-1 text-sm text-center link"
                onClick={handleSwitch}
              >
                Switch to {minuteStart === 30 ? ':00' : ':30'}
              </span>
              {availHours.map((hr) => {
                const active = selected.indexOf(hr) > -1;
                const classes = active ? activeClasses : inactiveClasses;
                return (
                  <button
                    key={hr}
                    className={`${classes}`}
                    onClick={handleClick(hr)}
                  >
                    {hr}
                  </button>
                );
              })}
              {/* <button
                className="btn gray"
                onClick={selected.length === 0 ? handleAll : handleClear}
              >
                {selected.length === 0 ? 'All' : 'Clear'}
              </button> */}
            </div>
          </ReadMore>
        </React.Fragment>
      )}
    </div>
  );
};

const Confirmation = ({ start, end, onCancel, onSubmit }) => {
  const weeklySchedules = useSelector(getScheduleWeeklySchedule);
  const getHours = (hours: string[]) => {
    if (hours.length === 0) {
      return 'Unavailable';
    }
    let first, last;
    let hasBreaks = false;
    if (hours.length === 1) {
      first = last = hours[0];
    } else {
      const unordered = [...hours];
      const ordered = unordered.sort((a, b) =>
        moment(a, DATE_FMT.TIME_A).isBefore(moment(b, DATE_FMT.TIME_A)) ? -1 : 1
      );
      first = ordered[0];
      last = ordered[ordered.length - 1];
      const diff = moment(last, DATE_FMT.TIME_A).diff(
        moment(first, DATE_FMT.TIME_A),
        'hours'
      );
      hasBreaks = diff !== hours.length - 1;
    }
    return `${first} to ${moment(last, DATE_FMT.TIME_A)
      .add(1, 'hour')
      .format(DATE_FMT.TIME_A)}${hasBreaks ? ', with some breaks' : ''}`;
  };

  return (
    <div className="space-y-2 text-base sm:min-w-md">
      {weeklySchedules.map((schedule) => {
        const from = moment(start).isBefore(schedule.start)
          ? schedule.start
          : start;
        const to = moment(end).isAfter(schedule.end) ? schedule.end : end;
        return (
          <div>
            <h5 className="my-0 font-medium text-gray-700 col-span-full">
              From {moment(from).format(DATE_FMT.DOW_SHORT_MON_D)} to{' '}
              {moment(to).format(DATE_FMT.DOW_SHORT_MON_D)}
            </h5>
            {DAYS_OF_WEEK_PY.map((Day) => {
              const hours = getHours(schedule[Day.toLowerCase()]);
              return (
                <div
                  key={`${schedule.start}-${Day.toLowerCase()}`}
                  className="flex"
                >
                  <span className="w-32">{Day}</span>
                  <span
                    className={`flex-1${
                      hours === 'Unavailable' ? ' italic text-gray-600' : ''
                    }`}
                  >
                    {hours}
                  </span>
                </div>
              );
            })}
          </div>
        );
      })}
      <Controls>
        <Button onClick={onCancel}>Cancel</Button>
        <Button color="primary" variant="contained" onClick={onSubmit}>
          Confirm
        </Button>
      </Controls>
    </div>
  );
};

const WeeklyMode = () => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(getCurrentUser) as InstructorAccount;
  const appointmentProduct = useSelector(getScheduleAppointmentProduct);
  const [isSelectingFacility, setIsSelectingFacility] = useState(
    !appointmentProduct
  );
  const [init, setInit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isFetchingConflicts, setIsFetching] = useState(false);
  const [start, setStartDate] = useState<string>();
  const [end, setEndDate] = useState<string>();
  const [numExistingDates, setNumExistingDates] = useState(0);
  const [endRef, setEndFocus] = useFocus();
  const selectedFacility = appointmentProduct
    ? appointmentProduct.facility
    : undefined;
  const dateRange = [
    moment().add(2, 'days').format(DATE_FMT.DATE_KEY),
    moment().add(6, 'months').format(DATE_FMT.DATE_KEY),
  ];
  const weeklySchedules = useSelector(getScheduleWeeklySchedule);
  const showSchedule =
    init &&
    start &&
    end &&
    appointmentProduct &&
    numExistingDates === 0 &&
    !isFetchingConflicts;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const fetchExistingDates = async () => {
    setIsFetching(true);
    setNumExistingDates(0);
    try {
      const response = await api.schedules.dates(user.username, {
        start,
        end,
        aptProdId: appointmentProduct.id,
      });
      setNumExistingDates(response.data.length);
    } catch (error) {
      enqueueSnackbar(GenericServerError);
    }
    setIsFetching(false);
  };

  const handleStartSelect = (date: string) => {
    setStartDate(date);
  };

  const handleEndSelect = (date: string) => {
    setEndDate(date);
  };

  const handleClearDates = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setNumExistingDates(0);
  };

  const handleSubmitSuccess = () => {
    // On success, we redirect and let the availability page update data
    history.push(SHARED_ROUTES.SCHEDULE.availability);
  };

  const handleSubmitError = (error) => {
    setSubmitted(false);
    setConfirmed(false);
    let message = 'An error occurred submitting this schedule';
    if (error?.response?.data?.message) {
      message = error.response.data.message;
    }
    enqueueSnackbar({
      message,
      variant: 'error',
    });
  };

  const handleSaveClick = () => {
    setSubmitted(true);
  };

  const handleAppointmentProductClick = (
    appointmentProduct: AppointmentProduct
  ) => {
    setIsSelectingFacility(false);
    dispatch(setAppointmentProduct(appointmentProduct));
  };

  useEffect(() => {
    setInit(true);
    return () => {
      dispatch(clearWeeklySchedules());
    };
  }, []);

  useEffect(() => {
    if (appointmentProduct && end) {
      fetchExistingDates();
    }
  }, [appointmentProduct, end]);

  useEffect(() => {
    if (init && start) {
      setEndDate(undefined);
      setEndFocus();
    }
  }, [start]);

  useEffect(() => {
    if (confirmed && submitted) {
      dispatch(submitWeeklySchedules(handleSubmitSuccess, handleSubmitError));
    }
  }, [confirmed, submitted]);

  return (
    <Dashboard title="Weekly Schedule" width="6xl">
      <Prompt
        when={weeklySchedules.length > 0}
        // DO NOT CHANGE FORMAT
        message={`Leave weekly schedule?
You will lose any unsaved changes.`}
        // DO NOT CHANGE FORMAT
      />
      <div
        className={`flex flex-col mx-auto rounded-lg shadow-md px-card-tight py-6 bg-white space-y-8`}
      >
        {selectedFacility && (
          <ListButton
            key={appointmentProduct.id}
            onClick={() => setIsSelectingFacility(true)}
            icon={
              <Avatar
                src={selectedFacility.avatar}
                diameter={8}
                border={true}
              />
            }
            title={selectedFacility.displayName}
          />
        )}
        {!end || !start ? (
          <div className="flex flex-col space-y-4 md:space-x-4 md:flex-row md:space-y-0">
            <div className="flex-1 rounded">
              <InputCalendar
                name="start"
                title="Start Date"
                placeholder="Select a start date"
                minDate={dateRange[0]}
                maxDate={dateRange[1]}
                onSelect={handleStartSelect}
                isClearable={false}
                autoFocus={true}
                default={start}
              />
            </div>
            <div className="flex-1 rounded">
              <InputCalendar
                fwdRef={endRef}
                name="end"
                title="End Date"
                placeholder="Select an end date"
                minDate={
                  start
                    ? moment(start, DATE_FMT.DATE_KEY)
                        .add(1, 'days')
                        .format(DATE_FMT.DATE_KEY)
                    : undefined
                }
                maxDate={dateRange[1]}
                onSelect={handleEndSelect}
                isClearable={false}
                disabled={!start}
                default={end}
              />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <span>
              Setting hours for{' '}
              {moment(start, DATE_FMT.DATE_KEY).format(
                DATE_FMT.DOW_SHORT_MON_D
              )}{' '}
              to{' '}
              {moment(end, DATE_FMT.DATE_KEY).format(DATE_FMT.DOW_SHORT_MON_D)}
            </span>
            <Button onClick={handleClearDates} variant="flat">
              Change
            </Button>
          </div>
        )}
        {numExistingDates !== 0 && (
          <div>
            <Callout title="Heads Up!" type="info">
              <p>
                You have {numExistingDates} {'day'.pluralize(numExistingDates)}{' '}
                at {selectedFacility.shortName} over this date range with
                availability, lessons, or proposals already scheduled.
              </p>
              <p className="mb-0 font-semibold">
                This action overwrites your availability, but leaves all
                proposals and lessons scheduled as is.
              </p>
              <Controls>
                <Button
                  variant="flat"
                  color="primary"
                  onClick={() => setNumExistingDates(0)}
                >
                  Got it
                </Button>
              </Controls>
            </Callout>
          </div>
        )}
        {showSchedule && (
          <WeekdaySchedule
            start={start}
            end={end}
            appointmentProduct={appointmentProduct}
          />
        )}
        <Controls>
          <Button to={SHARED_ROUTES.SCHEDULE.availability} disabled={submitted}>
            Quit
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={
              !showSchedule || weeklySchedules.length === 0 || submitted
            }
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </Controls>
      </div>
      <Modal
        name="Instructor — Select facility for weekly schedule"
        open={isSelectingFacility}
        onClose={() => setIsSelectingFacility(false)}
        title="Change Facility"
        maxWidth="sm"
      >
        <>
          <SelectAppointmentProduct onSelect={handleAppointmentProductClick} />
          <Controls>
            <Button
              color="default"
              variant="flat"
              onClick={() => setIsSelectingFacility(false)}
              disabled={typeof appointmentProduct === 'undefined'}
            >
              Go back
            </Button>
          </Controls>
        </>
      </Modal>
      <Modal
        name="Instructor — Confirm weekly schedule"
        open={submitted && !confirmed}
        title="Confirm Weekly Schedule"
        onClose={() => setSubmitted(false)}
      >
        <Confirmation
          onCancel={() => setSubmitted(false)}
          onSubmit={() => setConfirmed(true)}
          start={start}
          end={end}
        />
      </Modal>
      {(isFetchingConflicts || confirmed) && <Loading />}
    </Dashboard>
  );
};

export default WeeklyMode;
