import { FacilityScheduleSerializer } from 'api/Serializers/Facilities';
import Loading from 'components/loading';
import { DATE_FMT } from 'config';
import moment from 'moment-timezone';
import React from 'react';

/**
 *
 * @param detailed Show a more detailed version of the schedule including if the schedule is closed, its bounds, and its exceptions
 */
const WeeklySchedule = ({
  schedule,
  detailed = false,
}: {
  schedule: FacilityScheduleSerializer;
  detailed?: boolean;
}) => {
  let isClosed = true;

  if (!schedule) {
    return (
      <div>
        <Loading position="inline-contained" />
      </div>
    );
  }

  for (let [key, value] of Object.entries(schedule)) {
    if (key.includes('day') && value) {
      isClosed = false;
    }
  }
  if (!detailed && isClosed) {
    return null;
  }

  const boundsStr = `Hours ${
    moment(schedule.startDate).isBefore(moment())
      ? 'until'
      : `from ${moment(schedule.startDate).format(DATE_FMT.MONTH_D_YEAR)} to`
  } ${moment(schedule.endDate).format(DATE_FMT.MONTH_D_YEAR)}`;

  /**
   * Object example:
   * [['January:', [1, 2, 3]], ['Februrary:', [4, 5, 6]]]
   */
  const blocksbyMonth: [string, string[]][] = schedule.exceptions.reduce(
    (final, current, index) => {
      const latestTuple = final.slice(-1)[0];
      const currentDate = moment(current);
      const previousDate =
        index > 0 ? moment(schedule.exceptions[index - 1]) : undefined;
      return !previousDate || previousDate.month() !== currentDate.month()
        ? [
            ...final,
            [
              currentDate.format(DATE_FMT.MONTH) + ':',
              [currentDate.format(DATE_FMT.D)],
            ],
          ]
        : [
            ...final.slice(0, -1),
            [
              latestTuple[0],
              [...latestTuple[1], currentDate.format(DATE_FMT.D)],
            ],
          ];
    },
    []
  );

  return (
    <div>
      {isClosed ? (
        <div className="pb-3 text-base font-semibold">Closed</div>
      ) : (
        <>
          <div className="pb-3">
            {detailed && (
              <div className="text-base font-semibold">{boundsStr}</div>
            )}
            <div className="flex justify-between">
              {/* TODO: do this with an Array.map */}
              <div className="gap-y-4">
                <div>Monday:</div>
                <div>Tuesday:</div>
                <div>Wednesday:</div>
                <div>Thursday:</div>
                <div>Friday:</div>
                <div>Saturday:</div>
                <div>Sunday:</div>
              </div>
              <div>
                <div className="flex justify-end">
                  {schedule.monday
                    ? `${moment(
                        schedule.monday.open,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)} - ${moment(
                        schedule.monday.close,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)}`
                    : 'Closed'}
                </div>
                <div className="flex justify-end">
                  {schedule.tuesday
                    ? `${moment(
                        schedule.tuesday.open,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)} - ${moment(
                        schedule.tuesday.close,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)}`
                    : 'Closed'}
                </div>
                <div className="flex justify-end">
                  {schedule.wednesday
                    ? `${moment(
                        schedule.wednesday.open,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)} - ${moment(
                        schedule.wednesday.close,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)}`
                    : 'Closed'}
                </div>
                <div className="flex justify-end">
                  {schedule.thursday
                    ? `${moment(
                        schedule.thursday.open,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)} - ${moment(
                        schedule.thursday.close,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)}`
                    : 'Closed'}
                </div>
                <div className="flex justify-end">
                  {schedule.friday
                    ? `${moment(
                        schedule.friday.open,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)} - ${moment(
                        schedule.friday.close,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)}`
                    : 'Closed'}
                </div>
                <div className="flex justify-end">
                  {schedule.saturday
                    ? `${moment(
                        schedule.saturday.open,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)} - ${moment(
                        schedule.saturday.close,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)}`
                    : 'Closed'}
                </div>
                <div className="flex justify-end">
                  {schedule.sunday
                    ? `${moment(
                        schedule.sunday.open,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)} - ${moment(
                        schedule.sunday.close,
                        DATE_FMT.TIME_FIELD
                      ).format(DATE_FMT.HOUR_A)}`
                    : 'Closed'}
                </div>
              </div>
            </div>
          </div>
          {detailed && schedule.exceptions.length > 0 && (
            <div className="pb-3">
              <div className="text-base font-semibold">
                Reduced Hours or Closed
              </div>
              <div className="flex justify-between">
                <div className="gap-y-4">
                  {blocksbyMonth.map((elt, index) => (
                    <div key={index}>{elt[0]}</div>
                  ))}
                </div>
                <div>
                  {blocksbyMonth.map((elt, index) => (
                    <div className="flex justify-end" key={index}>
                      {elt[1].join(', ')}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WeeklySchedule;
