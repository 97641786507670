import api from 'api';
import { ApiException } from 'api/errors';
import { SignUpSerializer, TokenSerializer } from 'api/Serializers/Users';
import { AxiosResponse } from 'axios';
import Button from 'components/button';
import Callout from 'components/callout';
import PasswordInput from 'components/input-password';
import InputSuggestPlace from 'components/input-suggest-place';
import Modal from 'components/modal';
import Select, { SelectOption } from 'components/select';
import { FETCH_STATE, MIN_PASSWORD_LENGTH, UserType } from 'config';
import ReadMore from 'containers/read-more';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useForm from 'hooks/useForm';
import { GenericServerError } from 'lang/en/Snackbars';
import { Locality } from 'models/geo';
import { useSnackbar } from 'notistack';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import React, { useEffect, useRef, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { useHistory } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { clearErrorState, loginSuccess } from 'state/slice/authentication';
import { tokenDecode } from 'utils/auth';
import { APP_ROUTES } from 'utils/routing';
import { validateEmail } from 'utils/string';
import { AuthFlow } from '.';

const userTypeOptions = [
  {
    label: 'Instructor — I will teach lessons with Propel',
    value: UserType.Instructor,
  },
  {
    label: 'Client — I will book swim lessons',
    value: UserType.Client,
  },
];

const Label = ({ name, label, error = undefined }) => (
  <>
    <label htmlFor={name} className={!!error ? 'text-red-800' : ''}>
      {label}
    </label>
  </>
);

const Error = ({ error }: { error: ApiException<string> }) => {
  if (!error) {
    return null;
  }
  return <div className="input-error-message">{error.message}</div>;
};

const Input = ({
  label,
  name,
  onChange,
  inputs,
  disabled,
  type = 'text',
  error = null,
  placeholder = null,
  maxLength = null,
}) => {
  return (
    <>
      <Label name={name} label={label} error={error} />
      <input
        id={name}
        name={name}
        type={type}
        autoComplete={name}
        placeholder={placeholder}
        className={`${!!error ? 'input-error' : ''}`}
        onChange={onChange}
        value={inputs[name]}
        maxLength={maxLength}
        disabled={disabled}
      />
      <Error error={error} />
    </>
  );
};

const Confirmation = ({
  initialValue = '',
  onSuggestSelect,
}: {
  initialValue: string;
  onSuggestSelect(selected: Locality): void;
}) => {
  const enforcedSuggestRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (enforcedSuggestRef?.current) {
      enforcedSuggestRef.current.focus();
    }
  }, [enforcedSuggestRef]);
  return (
    <div>
      <h4>Let's make sure we got this right...</h4>
      <p>Select an option from the list shown below</p>
      <InputSuggestPlace
        ref={enforcedSuggestRef as any}
        className={`w-full`}
        initialValue={initialValue}
        onSuggestSelect={onSuggestSelect}
        types={['geocode']}
      />
      <div className="mt-2">
        <ReadMore
          initialHeight={0}
          initiallyOpen={false}
          openBtnText="Can't find what you're looking for?"
        >
          <div className="py-2">
            Try entering your street address, the first 3 digits of your postal
            code, or a nearby city center.
          </div>
        </ReadMore>
      </div>
    </div>
  );
};

type Error = { [Property in keyof SignUpSerializer]: ApiException<string> };

const fieldHierarchy = [
  'email',
  'firstName',
  'lastName',
  'address',
  'phoneNumber',
  'password',
  'type',
];

const SignUpForm = ({
  flow,
  defaultUserType = UserType.Unset,
}: {
  flow: AuthFlow;
  defaultUserType?: UserType;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [state, setState] = useState<FETCH_STATE>(FETCH_STATE.PRISTINE);

  // Suggest Place state values
  const [confirmValue, setConfirmValue] = useState('');
  const [showConfirmModal, setConfirm] = useState(false);
  const [place, setPlace] = useState<Locality>();
  const [timeoutInt, setTimeoutInt] = useState(undefined);
  // end suggest values

  const [firstError, setFirstError] = useState(undefined);
  const [errors, setErrors] = useState<Error>(undefined);

  const hasErrors = errors && Object.keys(errors).length > 0;

  const handleSuggestSelect = (_place: Locality) => {
    if (_place) {
      if (timeoutInt) {
        clearTimeout(timeoutInt);
        setTimeoutInt(undefined);
      }
      setConfirm(false);
    }
    setPlace(_place);
  };

  const validateFields = (inputs: SignUpSerializer): Error => {
    let firstError = undefined;
    let invalid = {} as Error;
    const emailError = validateEmail(inputs.email);
    if (emailError !== '') {
      invalid['email'] = {
        code: 'invalid_email',
        message: validateEmail(inputs.email),
      };
      firstError = 'email';
    }
    if (inputs.firstName === '') {
      invalid['firstName'] = {
        code: 'invalid_first_name',
        message: 'Cannot be blank',
      };
      firstError = firstError ?? 'firstName';
    }
    if (inputs.lastName === '') {
      invalid['lastName'] = {
        code: 'invalid_last_name',
        message: 'Cannot be blank',
      };
      firstError = firstError ?? 'lastName';
    }
    if (!place) {
      invalid['address'] = {
        code: 'invalid_address',
        message: 'Select from the options presented',
      };
      firstError = firstError ?? 'address';
    }
    if (inputs.phoneNumber === '') {
      invalid['phoneNumber'] = {
        code: 'blank_phone_number',
        message: 'Cannot be blank',
      };
    } else if (!isValidPhoneNumber(inputs.phoneNumber, 'CA')) {
      invalid['phoneNumber'] = {
        code: 'invalid_phone_number',
        message: 'Must be a valid Canadian phone number',
      };
      firstError = firstError ?? 'phoneNumber';
    }
    if (
      inputs.password === '' ||
      inputs.password.length < MIN_PASSWORD_LENGTH
    ) {
      invalid['password'] = {
        code: 'password_too_short',
        message:
          'Minimum 8 character length with a number or special character',
      };
      firstError = firstError ?? 'password';
    }
    if (inputs.type === UserType.Unset) {
      invalid['type'] = {
        code: 'invalid_user_type',
        message: 'Select an option from the dropdown',
      };
      firstError = firstError ?? 'userType';
    }
    setFirstError(firstError);
    return invalid;
  };

  const handleSignUpSuccess = (
    response: AxiosResponse<TokenSerializer, any>
  ) => {
    dispatch(loginSuccess(response.data.token, response.data.refresh));
    const { id, username, ...rest } = tokenDecode(response.data.token);
    setState(FETCH_STATE.FULFILLED);
    rudderanalytics.track('Signed Up', {
      id,
      username,
      email: inputs.email,
      type: inputs.type,
      url: location.href,
      flow,
    });
    if (inputs.type === UserType.Instructor) {
      history.push(INSTRUCTOR_ROUTES.ONBOARDING.WELCOME);
    }
  };

  const handleSignUpError = (error) => {
    setState(FETCH_STATE.FAILED);
    if (!error.response) {
      enqueueSnackbar(GenericServerError);
      logger.captureEvent({
        level: 'error',
        message: 'Sign Up Failed - Server Error',
        extra: {
          email: inputs.email,
          type: inputs.type,
          error,
        },
      });
    } else {
      let firstErrorIndex = 99;
      let firstError = undefined;
      let errors = {};
      for (var key of Object.keys(error.response.data)) {
        const errorHierarchy = fieldHierarchy.indexOf(key);
        if (errorHierarchy < firstErrorIndex) {
          firstError = key;
          firstErrorIndex = errorHierarchy;
        }
        errors[key] = error.response.data[key][0];
      }

      setFirstError(firstError);
      setErrors(errors as any);
      rudderanalytics.track('Sign Up Failed - User Error', errors);
      logger.captureEvent({
        level: 'warning',
        message: 'Sign Up Failed - User Error',
        extra: {
          email: inputs.email,
          type: inputs.type,
          error,
        },
      });
    }
  };

  const onSubmit = async () => {
    setErrors(undefined);
    setState(FETCH_STATE.PRISTINE);
    const invalidFields = validateFields(inputs);
    if (Object.keys(invalidFields).length > 0) {
      setErrors(invalidFields);
      return;
    }
    const data: SignUpSerializer = {
      ...inputs,
      address: place,
      postalCode: place.postalCode,
    };
    setState(FETCH_STATE.POST);
    try {
      const response = await api.users.create(data);
      handleSignUpSuccess(response);
    } catch (error: any) {
      handleSignUpError(error);
    }
  };

  const initialData: SignUpSerializer = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
    postalCode: '',
    address: undefined,
    type: defaultUserType,
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    initialData,
    onSubmit
  );

  const onChange = (event) => {
    if (errors && errors[event.target.name] !== undefined) {
      setErrors({ ...errors, [event.target.name]: undefined });
    }
    handleInputChange(event);
  };

  const handlePhoneChange = (value) => {
    const event = {
      target: {
        name: 'phoneNumber',
        value,
      },
    };
    onChange(event);
  };

  const handleNameChange = (name: string, value: string) => {
    const event = {
      target: {
        name,
        value: value.charAt(0).toUpperCase() + value.slice(1),
      },
    };
    onChange(event);
  };

  const handleOnPlaceBlur = (value: string) => {
    setConfirmValue(value);
    const nothingSelected = value && !place;
    if (nothingSelected) {
      const int = setTimeout(() => {
        setConfirm(true);
      }, 500);
      setTimeoutInt(int);
    }
  };

  const handleForceClose = () => {
    handleSuggestSelect(undefined);
    setPlace(undefined);
    setConfirm(false);
  };

  const handleTypeChange = (option: SelectOption<string>) => {
    const event = option
      ? {
          target: {
            name: 'type',
            value: option.value,
          },
        }
      : {
          target: {
            name: 'type',
            value: undefined,
          },
        };
    onChange(event);
  };

  useEffect(() => {
    dispatch(clearErrorState());
  }, []);

  useEffect(() => {
    if (firstError) {
      scroller.scrollTo(firstError, {
        duration: 350,
        smooth: true,
        offset: -50,
      });
      setFirstError(undefined);
    }
  }, [firstError]);

  return (
    <div className="space-y-8 ">
      <form className="mt-8" onSubmit={handleSubmit}>
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-6">
            <Input
              label="Email"
              name="email"
              type="email"
              inputs={inputs}
              onChange={onChange}
              maxLength={254}
              placeholder="sally@example.com"
              disabled={state === FETCH_STATE.POST}
              error={errors ? errors['email'] : null}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="First Name"
              name="firstName"
              inputs={inputs}
              onChange={(e) => handleNameChange('firstName', e.target.value)}
              maxLength={30}
              placeholder="Sally"
              disabled={state === FETCH_STATE.POST}
              error={errors ? errors['firstName'] : null}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Last Name"
              name="lastName"
              inputs={inputs}
              onChange={(e) => handleNameChange('lastName', e.target.value)}
              maxLength={150}
              placeholder="Swimmer"
              disabled={state === FETCH_STATE.POST}
              error={errors ? errors['lastName'] : null}
            />
          </div>

          <div className="col-span-6">
            <Label
              name="address"
              label="Postal code or address"
              error={errors ? errors['address'] : undefined}
            />
            <InputSuggestPlace
              id="address"
              name="address"
              placeholder="V6K..."
              initialValue={place?.description}
              inputClassName={`${
                errors && errors['address'] ? 'input-error' : ''
              }`}
              error={errors ? !!errors['address'] : false}
              onBlur={handleOnPlaceBlur}
              onSuggestSelect={handleSuggestSelect}
              disabled={state === FETCH_STATE.POST}
              types={['geocode']}
            />
            <Error error={errors && errors['address']} />
          </div>

          <div className="col-span-6">
            <Label
              name="phoneNumber"
              label="Phone"
              error={errors ? errors['phoneNumber'] : undefined}
            />
            <PhoneInput
              name="phoneNumber"
              value={inputs.phoneNumber}
              onChange={handlePhoneChange}
              country="CA"
              placeholder="604-555-0938"
              className={`${
                errors && errors['phoneNumber'] ? 'input-error' : ''
              }`}
              disabled={state === FETCH_STATE.POST}
            />
            <Error error={errors && errors['phoneNumber']} />
          </div>

          <div className="col-span-6">
            <Label
              name="password"
              label="Password"
              error={errors ? errors['password'] : undefined}
            />
            <PasswordInput
              value={inputs.password}
              onChange={onChange}
              disabled={state === FETCH_STATE.POST}
              error={errors && errors['password']}
            />
            <Error error={errors && errors['password']} />
          </div>

          <div className="col-span-6">
            <fieldset>
              <label
                className={errors && errors['type'] ? 'text-red-800' : ''}
                htmlFor="type"
              >
                What type of account is this?
              </label>
              <Select
                name="type"
                onChange={handleTypeChange}
                isClearable={true}
                error={errors && !!errors['type']}
                isSearchable={false}
                value={userTypeOptions.find((opt) => opt.value === inputs.type)}
                options={userTypeOptions}
              />
              <Error error={errors && errors['type']} />
            </fieldset>
          </div>
          <div className="col-span-6 text-sm">
            By proceeding, I agree to the{' '}
            <a target="_blank" href={APP_ROUTES.TERMS_OF_SERVICE}>
              terms of service
            </a>
            {' and '}
            <a target="_blank" href={APP_ROUTES.PRIVACY}>
              privacy policy
            </a>
            . I agree to receive feature updates and communications but may opt
            out anytime.
          </div>
          {hasErrors && state === FETCH_STATE.PRISTINE ? (
            <div className="col-span-6">
              <Callout type="error">
                Could not submit your request because of the
                {' error'.pluralize(Object.keys(errors).length)} shown above.
                Ensure that all fields are filled correctly and try again.
              </Callout>
            </div>
          ) : hasErrors && state === FETCH_STATE.FAILED ? (
            <div className="col-span-6">
              <Callout type="error">
                Could not create your account due to the
                {' error'.pluralize(Object.keys(errors).length)} shown above.
                Ensure that all fields are filled correctly and try again.
              </Callout>
            </div>
          ) : null}
          <div className="col-span-6 text-sm">
            <Button
              isLoading={state === FETCH_STATE.POST}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth={true}
            >
              Create account
            </Button>
          </div>
        </div>
        <Modal
          open={showConfirmModal}
          onClose={handleForceClose}
          name="Confirm geo location"
          allowOverflow
          maxWidth="xs"
        >
          <Confirmation
            initialValue={confirmValue}
            onSuggestSelect={handleSuggestSelect}
          />
        </Modal>
      </form>
    </div>
  );
};

export default SignUpForm;
