/* tslint:disable */
import { COLOR_PRIMARY_400 } from 'config';
import React from 'react';

interface Props {
  diameter?: 4 | 5 | 6 | 8 | 10 | 12 | 16 | 20 | 24 | 32 | 40 | 48 | 56 | 64;
  className?: string;
}

const Logo: React.FunctionComponent<Props> = ({
  diameter = 6,
  className = undefined,
}) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={(diameter / 4) * 16 - 2}
    height={(diameter / 4) * 16 - 2}
    viewBox="0 0 408 401"
    preserveAspectRatio="xMidYMid meet"
    className={`p-0.5${className ? ` ${className}` : ''}`}
  >
    <g id="svgg">
      <path
        fill={COLOR_PRIMARY_400}
        id="path0"
        d="M184.000 0.933 C 115.194 7.407,56.056 57.101,38.496 123.200 C 24.758 174.912,36.943 226.175,74.341 274.000 C 79.422 280.497,198.127 399.113,199.605 399.169 C 200.048 399.186,210.764 388.842,223.417 376.183 L 246.424 353.165 184.668 291.983 C 145.557 253.234,121.210 228.509,118.272 224.552 C 68.758 157.881,116.358 63.789,199.600 63.789 C 289.137 63.789,335.596 171.730,271.200 230.144 C 264.993 235.774,254.468 243.640,245.800 249.125 C 241.730 251.701,238.400 253.894,238.400 253.999 C 238.400 254.558,293.033 306.800,293.618 306.800 C 297.566 306.800,331.000 271.630,339.779 258.240 C 415.817 142.272,322.365 -12.087,184.000 0.933 M188.400 131.232 C 174.564 135.675,164.640 149.474,164.600 164.323 C 164.501 201.361,214.796 212.720,231.502 179.433 C 245.144 152.251,217.881 121.763,188.400 131.232 "
        stroke="none"
        fillRule="evenodd"
      ></path>
    </g>
  </svg>
);

export default Logo;
