import SearchIcon from '@mui/icons-material/Search';
import ViewAllOnIcon from '@mui/icons-material/Visibility';
import ViewAllOffIcon from '@mui/icons-material/VisibilityOutlined';
import { FacilitySchedulableSerializer } from 'api/Serializers/Facilities';
import Link from 'components/link';
import { IS_SERVER } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import { AccountRouteParams } from 'models/route';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getAccountDetail,
  getFacilitiesSchedulable,
  getSearchInstructors,
} from 'state/selectors';
import { fetchFacilityInstructors } from 'state/slice/search';
import { SHARED_ROUTES } from 'utils/routing';
import AvailabilityMapV2 from './availability';
import FacilityCard from './facility-card';
import FacilityDetail from './facility-detail';
import Map, { Pin } from './map';

export interface FacilityDirections {
  facilityId: number;
  travelMode: google.maps.TravelMode;
  directions: google.maps.DirectionsResult & {
    status?: google.maps.DirectionsStatus;
  };
}

const facilityToPin = (facility: FacilitySchedulableSerializer): Pin =>
  facility
    ? {
        id: facility.id,
        slug: facility.slug,
        latlng: facility.region.latlng,
        detailUrl: SHARED_ROUTES.FACILITIES.ROOT + `/${facility.slug}`,
        label: facility.shortName,
      }
    : undefined;

const facilitiesToPins = (facilities: FacilitySchedulableSerializer[]): Pin[] =>
  facilities.map(facilityToPin);

const FacilityMapPage = () => {
  if (IS_SERVER) {
    return <div />;
  }
  const account = useSelector(getAccountDetail);
  const { params } = useRouteMatch<AccountRouteParams>();
  const history = useHistory();
  const [facility, setFacility] = useState<FacilitySchedulableSerializer>();
  const [highlight, setHighlight] = useState<FacilitySchedulableSerializer>();
  const facilities = useSelector(getFacilitiesSchedulable);
  const [mapPins, setPins] = useState<Pin[]>(facilitiesToPins(facilities));
  const [sidebarFacilities, setSidebarFacilities] = useState(facilities);
  const instructors = useSelector(getSearchInstructors);
  const query = useQuery();
  const filter = query.get('filter');
  const region = query.get('region');
  const dispatch = useAppDispatch();

  // Map Loaded
  useEffect(() => {
    if (params.mode && (!facility || facility.slug !== params.mode)) {
      const fac = facilities.find((f) => f.slug === params.mode);
      setFacility(fac);
      dispatch(fetchFacilityInstructors(fac.slug));
    } else {
      // setBounds();
    }
  }, [params.mode]);

  const handleSearchUpdate = (event) => {
    const search = event.target.value;
    updateSidebar(search);
  };

  const updateSidebar = (search?: string) => {
    if (search) {
      setSidebarFacilities(
        facilities
          .filter(
            (f) =>
              f.displayName.toLowerCase().indexOf(search.toLowerCase()) >= 0
          )
          .sort(
            (a, b) =>
              a.displayName.toLowerCase().indexOf(search.toLowerCase()) -
              b.displayName.toLowerCase().indexOf(search.toLowerCase())
          )
      );
    } else {
      setSidebarFacilities(facilities);
    }
  };

  // URL param facilitySlug changes
  useEffect(() => {
    if (params.mode) {
      const selected = facilities.find((f) => f.slug === params.mode);
      setPins([facilityToPin(selected)]);
      setFacility(selected);
    } else if (region) {
      setPins(
        facilitiesToPins(
          facilities.filter(
            (f) => f.region.regionCode.toLowerCase() === region.toLowerCase()
          )
        )
      );
      setFacility(undefined);
      updateSidebar();
    } else {
      setFacility(undefined);
      setPins(facilitiesToPins(facilities));
      updateSidebar();
    }
  }, [facilities, region, params.mode]);

  const onPinClick = (key, markerProps) => {
    if (markerProps.detailUrl) {
      history.push(markerProps.detailUrl);
    }
  };

  const onMapReset = () => {
    history.push(SHARED_ROUTES.FACILITIES.ROOT);
  };

  return (
    <div className="flex flex-1 md:flex-row">
      <div className="flex flex-col flex-shrink md:bg-white md:w-96 xl:w-120">
        {facility ? (
          <FacilityDetail facility={facility} instructors={instructors} />
        ) : (
          <>
            <div className="m-2">
              <SearchIcon
                className={`absolute left-0 ml-1 ${
                  filter === 'search' ? 'text-blue-500' : 'text-gray-500'
                } center-y absolute`}
                color="inherit"
                fontSize="small"
              />
              <input
                name="search"
                onChange={handleSearchUpdate}
                className="py-1 pl-8 my-1 text-gray-600 bg-blue-100 border-gray-300 rounded-full shadow-sm"
              />
            </div>
            <div className="space-y-6 ">
              {Object.entries<FacilitySchedulableSerializer[]>(
                sidebarFacilities.groupBy((f) => f.region.regionCode)
              ).map((data, i) => {
                const prov = data[0];
                const facilities = data[1];
                const active = region?.toLowerCase() === prov.toLowerCase();
                return (
                  <div key={`prov-${i}`}>
                    <div className="sticky z-30 p-2 bg-white border-t border-gray-300 shadow-sm top-16">
                      <div className="flex items-center justify-between">
                        <span className="text-xl font-bold">{prov}</span>
                        <span className="flex-1 text-lg font-medium text-center">
                          {`${facilities.length} ${'Location'.pluralize(
                            facilities.length
                          )}`}
                        </span>
                        <Link
                          to={
                            SHARED_ROUTES.FACILITIES.ROOT +
                            `${active ? '' : `?region=${prov.toLowerCase()}`}`
                          }
                          className={
                            active
                              ? 'text-blue-500'
                              : 'text-gray-800 hover:text-blue-500'
                          }
                        >
                          {active ? <ViewAllOnIcon /> : <ViewAllOffIcon />}
                        </Link>
                      </div>
                    </div>
                    <div className="px-1 ">
                      {facilities.map((fac) => {
                        const isMapped = mapPins.some(
                          (pin) => pin.id === fac.id
                        );
                        return (
                          <Link
                            key={fac.id}
                            className={`block mb-3${
                              !isMapped ? ' opacity-50' : ''
                            }`}
                            to={SHARED_ROUTES.FACILITIES.ROOT + `/${fac.slug}`}
                          >
                            <div
                              // onClick={() => onClick(fac)}
                              onPointerOver={() => setHighlight(fac)}
                              onPointerOut={() => setHighlight(undefined)}
                            >
                              <FacilityCard facility={fac} />
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {params.view === 'availability' ? (
        <div className="flex-1">
          <AvailabilityMapV2 facility={facility} />
        </div>
      ) : (
        <div className="sticky flex-1 hidden top-16 min-h-screen md:block">
          <div className="absolute top-0 bottom-0 left-0 right-0">
            <div className="sticky h-screen top-16">
              <Map
                pins={mapPins}
                defaultCenter={account?.address?.latlng}
                pinFocus={facilityToPin(facility)}
                pinHighlight={facilityToPin(highlight)}
                onPinClick={onPinClick}
                onReset={onMapReset}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FacilityMapPage;
