import { Chip } from '@mui/material';
import FavouriteButton from 'components/favourite-button';
import { CheckBadgeIcon } from 'icons';
import React from 'react';

interface Props {
  instructorId?: number;
  specialization1?: string;
  specialization2?: string;
  segments?: string[];
  avatar: string;
  displayName: string;
  basePrice: number;
  className?: string;
  to?: string;
}

const InstructorListDetailsProposal: React.FC<Props> = ({
  instructorId,
  specialization1,
  specialization2,
  segments,
  displayName,
  basePrice,
}) => {
  const chips = [specialization1, specialization2].map((spec) =>
    spec ? <Chip label={spec} size="small" /> : null
  );
  const teaches =
    !!segments && segments.length > 0
      ? `Teaches ${segments.map((s) => s.toLowerCase()).join(', ')}`
      : '';
  return (
    <>
      <div className="absolute z-10 top-3 right-3">
        <FavouriteButton
          contentType={'instructor'}
          contentObject={{ id: instructorId, displayName: displayName }}
          size="medium"
          variant="icon"
        />
      </div>
      <div className="flex flex-col w-full h-full p-3 space-y-3">
        <div className="mr-10 space-y-1">
          <h3 className="my-0 font-medium leading-none text-gray-900 text-md">
            {displayName}
          </h3>
          <h4 className="text-xs font-medium leading-none text-gray-700">
            Learn to Swim & Water Safety Instructor
          </h4>
        </div>
        <div className="flex flex-col space-y-2 text-sm font-normal text-gray-900">
          <span>{teaches}</span>
          <span className="space-x-1">{chips}</span>
        </div>
        <div className="grid grid-cols-3 -mr-3">
          <div className="flex flex-col items-center justify-center text-xs text-gray-700 border-l border-r border-gray-300">
            <span className="flex items-center justify-end flex-1">
              <CheckBadgeIcon width={24} />
            </span>
            <span>Verified</span>
          </div>
          <div className="flex flex-col items-center justify-center text-xs text-gray-700">
            <span className="flex items-center justify-end flex-1 font-medium text-md">
              ${basePrice}
            </span>
            <span>1-hour lesson</span>
          </div>
        </div>
      </div>
    </>
  );
};

const InstructorListDetails: React.FC<Props> = ({
  instructorId,
  specialization1,
  specialization2,
  segments,
  displayName,
  basePrice,
}) => {
  const flair = `Learn to Swim & Water Safety Instructor${
    specialization1 ? ` • ${specialization1}` : ''
  }${specialization2 ? ` • ${specialization2}` : ''}`;
  const teaches =
    !!segments && segments.length > 0
      ? `Teaches ${segments.map((s) => s.toLowerCase()).join(', ')}`
      : '';
  return (
    <div className="flex flex-col w-full h-full p-3">
      <div className="flex items-center justify-between gap-4 mb-2">
        <h5 className="my-0 font-semibold leading-none text-gray-900 text-md">
          {displayName}
        </h5>
        {instructorId && (
          <FavouriteButton
            contentType={'instructor'}
            contentObject={{ id: instructorId, displayName: displayName }}
            size="small"
            variant="icon"
          />
        )}
      </div>
      <div className="flex flex-col space-y-2 text-sm font-normal text-gray-900">
        <span>{flair}</span>
        <span>{teaches}</span>
      </div>
      {basePrice > 0 && (
        <div className="absolute bottom-0 right-0 px-2 py-1 m-0 font-semibold text-gray-800 bg-white">
          <span>${basePrice}</span>
        </div>
      )}
    </div>
  );
};

export default InstructorListDetails;
