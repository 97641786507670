import React from 'react';
const ProfileLoadingList = () => (
  <>
    {[...Array(3).keys()].map((i) => (
      <div
        key={`l${i}`}
        className="w-full p-3 overflow-hidden duration-200 bg-white border border-gray-400 rounded-2xl animate-pulse"
      >
        <div className="space-y-3">
          <div className="flex gap-2">
            <div>
              <div className="w-32 h-32 bg-gray-500 rounded-xl" />
            </div>
            <div className="pt-1 space-y-1">
              <div className="w-32 h-6 bg-gray-500 rounded-lg" />

              <div className="flex gap-1">
                <div className="w-8 h-4 bg-gray-400 rounded-full" />
                <div className="w-12 h-4 bg-gray-400 rounded-full" />
                <div className="w-10 h-4 bg-gray-400 rounded-full" />
              </div>
            </div>
          </div>

          <div className="space-y-1">
            <div className="w-full h-2 bg-gray-400 rounded-lg" />
            <div className="w-full h-2 bg-gray-400 rounded-lg" />
            <div className="w-3/4 h-2 bg-gray-400 rounded-lg" />
          </div>
          <div className="flex justify-between px-3 text-xs font-normal text-gray-700">
            <div className="">
              <div className="flex justify-center space-x-2 font-bold text-md">
                <div className="w-12 h-10 bg-gray-400 rounded-lg" />
              </div>
            </div>
            <div className="">
              <div className="flex justify-center space-x-2 font-bold text-md">
                <div className="w-12 h-10 bg-gray-400 rounded-lg" />
              </div>
            </div>
            <div className="">
              <div className="w-12 h-10 bg-gray-400 rounded-lg" />
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);
export default ProfileLoadingList;
