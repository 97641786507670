import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import { TimeFrame } from 'api/Serializers/Appointments';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Controls from 'components/controls';
import { UserType } from 'config';
import DialThrough from 'features/dial-through';
import { AssistanceWizardProps } from 'models/Assistance';
import moment from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';

/**
 * url: account/assistance/:appointment-id/instructor/call
 */
const ClientCallView = (props: AssistanceWizardProps) => {
  const history = useHistory();
  const { appointment, trackAction, addReportData, getReportData } = props;
  const callHistory = getReportData('callHistory') as string;
  const numCalls = getReportData('numCalls') as number;
  const handleCallPlaced = () => {
    const placedAt = moment().format('h:mm:ssa');
    if (numCalls) {
      addReportData({
        type: 'COUNT_CALL',
        key: 'numCalls',
        value: numCalls + 1,
      });
      addReportData({
        type: `PLACE_CALL`,
        key: `callHistory`,
        value: `${callHistory}\n- ${placedAt}`,
        title: `Call history with instructor`,
        description: `${callHistory}\n- ${placedAt}`,
      });
    } else {
      addReportData({
        type: 'COUNT_CALL',
        key: 'numCalls',
        value: 1,
      });
      addReportData({
        type: 'PLACE_CALL',
        key: 'callHistory',
        value: `- ${placedAt}`,
        title: 'Call history with instructor',
        description: `- ${placedAt}`,
      });
    }
  };
  const handleCallSuccess = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'INSTRUCTOR_NOT_HERE',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'INSTRUCTOR_FOUND',
      title: 'How did that go?',
      description: 'I am with my instructor or they will be here soon',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'NO_CHANGE',
    });
  };
  const handleInstructorCancellation = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'INSTRUCTOR_NOT_HERE',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'INSTRUCTOR_CANCELLATION',
      title: 'How did that go?',
      description:
        'I have spoken to the instructor and it is clear they will not attend',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'INSTRUCTOR_NO_SHOW',
    });
  };
  const handleInstructorNoShow = () => {
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'INSTRUCTOR_NOT_HERE',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'INSTRUCTOR_CANCELLATION',
      title: 'How did that go?',
      description: 'My instructor never showed up!',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'INSTRUCTOR_NO_SHOW',
    });
  };
  return (
    <>
      <h2>Instructor Assistance</h2>
      <h4 className="my-2">Call my instructor</h4>
      <DialThrough
        appointment={appointment}
        recipient={UserType.Instructor}
        onClose={history.goBack}
        onCallPlaced={handleCallPlaced}
        PostCallView={({ dialThroughState }) => (
          <div className="space-y-3">
            <ButtonLarge
              title="I am with my instructor or they will be here soon"
              subtitle="The lesson can start soon"
              icon={<CheckIcon className="text-gray-600" />}
              to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
              onClick={handleCallSuccess}
            />
            {!!dialThroughState.userProxyPhone && (
              <ButtonLarge
                title="No answer or busy, retry the call"
                subtitle="Try to reach your instructor until the lesson ends"
                icon={<PhoneIcon className="text-gray-600" />}
                onClick={handleCallPlaced}
                to={`tel:${dialThroughState.userProxyPhone}`}
              />
            )}
            <ButtonLarge
              title="Instructor is not going to attend"
              subtitle="You have spoken to your instructor and it is clear they will not attend"
              icon={<CancelIcon className="text-gray-600" />}
              to={SHARED_ROUTES.ASSISTANCE.nav(
                appointment.id,
                'instructor/note'
              )}
              disabled={appointment.timeFrame >= TimeFrame.HasEnded}
              onClick={handleInstructorCancellation}
            />
            <ButtonLarge
              title="My instructor never showed up"
              subtitle={
                appointment.timeFrame < TimeFrame.HasEnded
                  ? 'Disabled until lesson has ended'
                  : 'Your instructor did not show and you were unable to reach them'
              }
              icon={<CancelIcon className="text-gray-600" />}
              to={SHARED_ROUTES.ASSISTANCE.nav(
                appointment.id,
                'instructor/note'
              )}
              disabled={appointment.timeFrame < TimeFrame.HasEnded}
              onClick={handleInstructorNoShow}
            />
            <Controls variant="block">
              <BackButton>Go back</BackButton>
            </Controls>
          </div>
        )}
      />
    </>
  );
};

export default ClientCallView;
