import { Favorite } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FavouriteNotificationFrequency } from 'api/Serializers/Accounts';
import { FavouriteSerializer } from 'api/Serializers/Favourites';
import Dashboard from 'components/account/dashboard';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Card from 'components/card';
import Controls from 'components/controls';
import FavouriteButton from 'components/favourite-button';
import Link from 'components/link';
import useAccountPatch from 'hooks/useAccountPatch';
import { useAppDispatch } from 'hooks/useAppDispatch';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAccountDetail,
  getFavouritesList,
  getInstructorsSchedulable,
} from 'state/selectors';
import { fetchFavourites } from 'state/slice/favourites';
import { LocalStore } from 'state/storage';
import { APP_ROUTES, SHOW_HELP } from 'utils/routing';

const FavouriteListItems = ({
  title,
  items,
  variant = 'icon',
}: {
  title?: string;
  items: FavouriteSerializer[];
  variant?: 'full' | 'icon';
}) => {
  if (items.length === 0) {
    return null;
  }
  return (
    <div className="max-w-lg mx-auto">
      {title && <h3 className="mb-2 font-medium text-gray-700">{title}</h3>}
      <div className="flex flex-col gap-2 mb-6">
        {items.map((favourite) => {
          const to =
            favourite.contentType === 'facility'
              ? APP_ROUTES.BROWSE_FACILITY(
                  favourite.contentObject.region.slug,
                  favourite.contentObject.slug
                )
              : APP_ROUTES.INSTRUCTOR_SHORTCODE.link(
                  favourite.contentObject.slug
                );
          return (
            <div
              key={`${favourite.contentType}-${favourite.contentObject.id}`}
              className={`p-4 flex items-center justify-between rounded-lg cubic min-h-[3.5rem] bg-white shadow-sm`}
            >
              <div className="flex items-center gap-4">
                <Avatar diameter={12} src={favourite.contentObject.avatar} />
                <Link to={to}>
                  <div className="text-lg font-bold text-gray-800">
                    {favourite.contentObject.displayName}
                  </div>
                  {favourite.contentObject.region ? (
                    <div className="text-base text-gray-800">
                      {favourite.contentObject.region.label}
                    </div>
                  ) : null}
                </Link>
              </div>
              <FavouriteButton
                contentObject={favourite.contentObject}
                contentType={favourite.contentType}
                variant={variant}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FavouritesHelper = ({
  favourites,
}: {
  favourites: FavouriteSerializer[];
}) => {
  const account = useSelector(getAccountDetail);
  const instructors = useSelector(getInstructorsSchedulable);
  const hasReadFavHelp = LocalStore.get('_has_read_fav_help') === 'true';
  const [showFavHelper, setShowFavHelper] = useState(!hasReadFavHelp);
  const candidates: FavouriteSerializer[] = [
    ...instructors.map(
      (instructor) =>
        ({
          contentType: 'instructor',
          contentObject: instructor,
        } as FavouriteSerializer)
    ),
  ];

  const handleDismiss = () => {
    setShowFavHelper(false);
    LocalStore.set('_has_read_fav_help', 'true');
  };

  if (
    !showFavHelper ||
    favourites.length > 1 ||
    account.favouriteNotificationFrequency ===
      FavouriteNotificationFrequency.NEVER
  ) {
    return null;
  } else if (favourites.length === 0) {
    return (
      <div>
        <Card title="What are favourites?" maxWidth="lg">
          <div>
            <h5>Use favourites to be notified of newly added lesson times!</h5>
            <p>
              When you add a pool or instructor to your favourites, you'll
              receive an email summary of newly added lesson times. Add to your
              favourites list by clicking the{' '}
              <Button
                className="text-red-600 hover:text-red-600"
                variant="flat"
                icon={<Favorite color="inherit" />}
                size="small"
              >
                Favourite
              </Button>{' '}
              button on any pool or instructor profile
            </p>
          </div>
          {instructors && instructors.length > 0 && (
            <div>
              <h5>Get a head start!</h5>
              <p>
                Let's start by adding your instructor to your favourites list:
              </p>
              <FavouriteListItems variant="full" items={candidates} />
            </div>
          )}
        </Card>
      </div>
    );
  } else if (favourites.length === 1) {
    if (moment().diff(moment(favourites[0].created), 'days') > 15) {
      // Don't show the favourites continued-help modal after 15 days
      return null;
    } else {
      return (
        <div>
          <Card title="You've added your first favourite!" maxWidth="lg">
            <div>
              <h5>Now set the frequency to your liking</h5>
              <p>
                Using the Daily, Weekly, and Never options above, tell us how
                often you'd like to receive updates.
              </p>
              <p>
                "Daily" updates are sent every day, "Weekly" updates are sent
                every Friday, and "Never" will stop all favourite updates from
                being sent. If there are no newly added lesson times, you will
                not receive an email.
              </p>
            </div>
            <Controls>
              <Button
                variant="outlined"
                color="default"
                onClick={SHOW_HELP.LEARNING.FAVORITES}
              >
                Learn more
              </Button>
              <Button variant="flat" color="primary" onClick={handleDismiss}>
                Dismiss
              </Button>
            </Controls>
          </Card>
        </div>
      );
    }
  }

  return null;
};

const Favourites = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail);
  const { handleAccountUpdate } = useAccountPatch();
  const favourites = useSelector(getFavouritesList);
  const [frequency, setFrequency] = useState<FavouriteNotificationFrequency>(
    account.favouriteNotificationFrequency
  );
  const [frequencyErrored, setFrequencyErrored] = useState(false);

  const handleUnload = () => {
    dispatch(fetchFavourites());
  };

  useEffect(() => {
    return handleUnload;
  }, []);

  const handleRadioChange = async (event) => {
    event.stopPropagation();
    const favouriteNotificationFrequency = parseInt(event.target.value);
    setFrequency(favouriteNotificationFrequency);
    await handleAccountUpdate({
      favouriteNotificationFrequency,
    });
    enqueueSnackbar({
      message: 'Frequency settings saved',
      variant: 'success',
    });
  };

  return (
    <Dashboard title="Favourites" onClickHelp={SHOW_HELP.LEARNING.FAVORITES}>
      <div className="mx-2">
        <div>
          <p>
            Receive updates when new availability is added for your favourite
            instructors and pools.
          </p>
          <div
            className={`flex justify-center mb-4${
              frequencyErrored ? ' pointer-events-none opacity-60' : ''
            }`}
          >
            <FormControl>
              <RadioGroup value={frequency} onChange={handleRadioChange} row>
                <FormControlLabel
                  className="!mx-3"
                  value={FavouriteNotificationFrequency.DAILY}
                  control={<Radio color="primary" />}
                  label="Daily"
                />
                <FormControlLabel
                  className="!mx-3"
                  value={FavouriteNotificationFrequency.WEEKLY}
                  control={<Radio color="primary" />}
                  label="Weekly"
                />
                <FormControlLabel
                  className="!mx-3"
                  value={FavouriteNotificationFrequency.NEVER}
                  control={<Radio color="primary" />}
                  label="Never"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            {frequency === FavouriteNotificationFrequency.NEVER && (
              <Card title="Favourites Paused" maxWidth="lg" className="mb-16">
                <p>Get too many emails? We get it!</p>
                <p>
                  While your notifications are set to "Never", you won't receive
                  any updates. If you ever change your mind, click a new
                  frequency value and all your favourites will start sending
                  again.
                </p>
              </Card>
            )}
            <div
              className={`${
                frequency === FavouriteNotificationFrequency.NEVER
                  ? 'opacity-60'
                  : ''
              }`}
            >
              <FavouriteListItems
                title="Pools"
                items={favourites.filter(
                  (fav) => fav.contentType === 'facility'
                )}
              />
              <FavouriteListItems
                title="Instructors"
                items={favourites.filter(
                  (fav) => fav.contentType === 'instructor'
                )}
              />
              <FavouritesHelper favourites={favourites} />
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default Favourites;
