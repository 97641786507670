import AddIcon from '@mui/icons-material/Add';
import CarIcon from '@mui/icons-material/DirectionsCar';
import TransitIcon from '@mui/icons-material/DirectionsTransit';
import HelpIcon from '@mui/icons-material/Help';
import RemoveIcon from '@mui/icons-material/Remove';
import api from 'api';
import {
  AppointmentProduct,
  AppointmentProductStatus,
} from 'api/Serializers/AppointmentProducts';
import { FacilitySchedulableSerializer } from 'api/Serializers/Facilities';
import Button from 'components/button';
import HelpButton from 'components/button-help';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import ProfileLoadingList from 'components/profile-loading-list';
import Waitlist from 'components/waitlist';
import { GOOGLE_MAPS_API_KEY } from 'config';
import GoogleMap from 'google-map-react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { DoneIcon } from 'icons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUsername } from 'state/selectors';
import { fetchFacilityInstructors } from 'state/slice/search';
import FacilityCard from './facility-card';
import FacilityDetail from './facility-detail';
import MapPin from './mapPin';

const AnyReactComponent = ({
  className,
  children,
  onMarkerClick = null,
  onMouseOver = null,
  onMouseOut = null,
  ...rest
}) => (
  <div
    className={`absolute flex items-center justify-center ${className}`}
    style={{
      transform: 'translate(-50%, -100%)',
    }}
    onClick={onMarkerClick}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
  >
    <div>{children}</div>
  </div>
);

const Sidebar = ({
  facility,
  facilities,
  onOver,
  directionsSet,
  travelMode,
  setTravelMode,
  onFacilitySelect,
  onHelpClick,
  appointmentProducts,
}: {
  facility: FacilitySchedulableSerializer;
  facilities: FacilitySchedulableSerializer[];
  onOver(facilityId: number): void;
  directionsSet: FacilityDirections[];
  travelMode: google.maps.TravelMode;
  setTravelMode(mode: google.maps.TravelMode): void;
  onFacilitySelect(facility: FacilitySchedulableSerializer): void;
  onHelpClick(): void;
  appointmentProducts: AppointmentProduct[];
}) => {
  return (
    <div className="flex flex-col justify-between flex-shrink md:w-104 lg:w-120 md:flex-col-reverse bg-background">
      <div className="flex-1">
        <div className="pb-20 space-y-3 md:space-y-6 md:pb-12 md:px-4">
          {facility ? (
            <FacilityDetail
              facility={facility}
              directions={directionsSet.find(
                (ds) =>
                  ds.facilityId === facility.id && ds.travelMode === travelMode
              )}
              setTravelMode={setTravelMode}
              onHelpClick={onHelpClick}
              onClose={() => onFacilitySelect(undefined)}
            />
          ) : !facilities ? (
            <ProfileLoadingList />
          ) : (
            <>
              <h2 className="mt-6 text-lg font-semibold text-center md:text-left">{`${
                facilities.length
              } nearby ${'location'.pluralize(facilities.length)}`}</h2>
              <div className="text-center text-gray-600">
                Select a pool to learn more about it
              </div>
              <div className="my-2 text-center">
                <HelpButton onClick={onHelpClick}>
                  Things to consider when choosing
                </HelpButton>
              </div>
              {facilities.length > 0 ? (
                <div className="max-w-md mx-auto space-y-4 md:max-w-none">
                  {facilities.map((fac) => {
                    const facDirs = directionsSet.find(
                      (ds) =>
                        ds.facilityId === fac.id && ds.travelMode === travelMode
                    );
                    const worksHereAlready = appointmentProducts.some(
                      (aptProd) =>
                        aptProd.facility.id === fac.id &&
                        aptProd.status !== AppointmentProductStatus.Unlisted
                    );
                    return (
                      <button
                        key={fac.id}
                        className={`block w-full relative`}
                        onClick={() => onFacilitySelect(fac)}
                      >
                        <div
                          onPointerOver={() => onOver(fac.id)}
                          onPointerOut={() => onOver(undefined)}
                        >
                          <FacilityCard facility={fac} directions={facDirs} />
                        </div>
                        {worksHereAlready && (
                          <span className="absolute text-green-600 bg-white rounded-full right-4 top-4">
                            <DoneIcon width={24} />
                          </span>
                        )}
                      </button>
                    );
                  })}
                </div>
              ) : (
                <div className="px-6 py-4 bg-gray-200 rounded-lg shadow">
                  <Waitlist />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const HelpModal = ({ onHelpClick }) => {
  return (
    <>
      <p>
        Each pool has unique characteristics that you should consider when
        choosing where to work.
      </p>
      <ol>
        <li>Location and how you'll get there</li>
        <li>Size and depth for the levels you teach</li>
        <li>Free or paid parking if you need it</li>
      </ol>
      <Callout title="Freedom to move" type="info">
        <p>
          New facilities are added all the time. You can always list at another
          later later if it's more convenient.
        </p>
      </Callout>
      <Controls>
        <Button onClick={onHelpClick} variant="contained" color="primary">
          Close
        </Button>
      </Controls>
    </>
  );
};

export interface FacilityDirections {
  facilityId: number;
  travelMode: google.maps.TravelMode;
  directions: google.maps.DirectionsResult & {
    status?: google.maps.DirectionsStatus;
  };
}

const FacilityMap = ({
  home,
  onFacilitySelect,
  appointmentProducts,
  onGoBack,
  fullscreen = false,
}: {
  home: {
    lat: number;
    lng: number;
  };
  appointmentProducts: AppointmentProduct[];
  onFacilitySelect(facility: FacilitySchedulableSerializer): void;
  onGoBack(): void;
  fullscreen?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [facilities, setFacilities] = useState(undefined);
  const [travelMode, setTravelMode] = useState<google.maps.TravelMode>(
    typeof google !== 'undefined' ? google.maps.TravelMode.DRIVING : undefined
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [iconHighlight, setIconHighlight] = useState<number>();
  const [loaded, setIsLoaded] = useState(false);
  const [mapObj, setMapObj] = useState<google.maps.Map>();
  const [googleInit, setGoogleInit] = useState(typeof google !== 'undefined');
  const [facility, setFacility] = useState<FacilitySchedulableSerializer>();
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();
  const [directionsSet, setDirectionsSet] = useState<FacilityDirections[]>([]);
  const [mapDirectionsService, setDirectionsService] =
    useState<google.maps.DirectionsService>();
  const [mapRenderService, setRenderService] =
    useState<google.maps.DirectionsRenderer>();
  const [showHelp, setShowHelp] = useState(false);
  const dispatch = useAppDispatch();
  const username = useSelector(getUsername);
  const worksAtFacility =
    !!facility &&
    appointmentProducts?.some(
      (aptProd) =>
        aptProd.facility.id === facility.id &&
        aptProd.status !== AppointmentProductStatus.Unlisted
    );
  const fetchFacilities = async () => {
    const response = await api.facilities.schedulable({
      username,
      forceNew: 'true',
    });
    setFacilities(response.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchFacilities();
  }, []);
  useEffect(() => {
    if (typeof google !== 'undefined') {
      setGoogleInit(true);
      setTravelMode(google.maps.TravelMode.DRIVING);
    }
  }, [typeof google]);

  // Map Loaded
  useEffect(() => {
    if (loaded && facilities && mapObj) {
      setBounds();
    }
  }, [loaded, facilities, mapObj]);

  // Directions object changes
  useEffect(() => {
    if (directions) {
      mapRenderService.setMap(mapObj);
      mapRenderService.setDirections(directions);
    } else {
      clearDirections();
    }
  }, [directions]);

  // Facility detail object changes
  useEffect(() => {
    if (facility && mapDirectionsService) {
      fetchDirections(facility);
      dispatch(fetchFacilityInstructors(facility.slug));
    } else {
      // When facility is deselected, after initial loading, clear the map of current directions
      // otherwise it shows briefly while new directions are being fetched.
      setDirections(undefined);
    }
  }, [facility, mapDirectionsService]);

  // Travel mode changes
  useEffect(() => {
    if (!loaded) {
      return;
    } else if (travelMode === google.maps.TravelMode.DRIVING) {
      enqueueSnackbar({
        message: 'Showing driving directions',
        variant: 'info',
      });
    } else if (travelMode === google.maps.TravelMode.TRANSIT) {
      enqueueSnackbar({
        message: 'Showing transit directions',
        variant: 'info',
      });
    }

    if (directions && mapDirectionsService) {
      fetchDirections(facility);
    }
  }, [travelMode, mapDirectionsService]);

  const onHelpClick = () => {
    setShowHelp(!showHelp);
  };

  const clearDirections = () => {
    if (mapObj && facilities.length > 0) {
      mapRenderService.setMap(null);
      setBounds();
    }
  };

  const handleAddFacility = () => {
    onFacilitySelect(facility);
    setIsSubmitted(true);
  };

  const fetchDirections = (fac) => {
    const fetchedDirections = directionsSet.find(
      (ds) => ds.facilityId === fac.id && ds.travelMode === travelMode
    );
    if (
      fetchedDirections &&
      fetchedDirections.directions.status === google.maps.DirectionsStatus.OK
    ) {
      setDirections(fetchedDirections.directions);
      return true;
    } else if (mapDirectionsService) {
      const routeData: google.maps.DirectionsRequest = {
        origin: home,
        destination: fac.region.latlng,
        travelMode,
      };
      mapDirectionsService.route(routeData, function (response, status) {
        setDirections(response);
        setDirectionsSet([
          ...directionsSet,
          { facilityId: fac.id, directions: response, travelMode },
        ]);
      });
      return true;
    } else {
      return false;
    }
  };

  const handleGoBack = () => {
    if (!!facility) {
      setFacility(undefined);
    } else {
      onGoBack();
    }
  };

  const handleSelectFacility = (facility: FacilitySchedulableSerializer) => {
    setFacility(facility);
  };

  const handleMapPinClick = (evt, facility) => {
    evt.stopPropagation();
    if (facility) {
      handleSelectFacility(facility);
    }
  };

  const handleMapClick = (arg) => {
    handleSelectFacility(undefined);
  };

  const setBounds = () => {
    if (!mapObj || facilities.length === 0) {
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    facilities.map((fac) => {
      bounds.extend(fac.region.latlng);
      return fac;
    });
    bounds.extend(home);
    mapObj.fitBounds(bounds);
  };

  const handleApiLoaded = (map, maps) => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
    setMapObj(map);
    const renderOptions = {
      markerOptions: {
        visible: false,
      },
    };
    const _mapDirectionsService = new google.maps.DirectionsService();
    const _directionsRenderer = new google.maps.DirectionsRenderer(
      renderOptions as google.maps.DirectionsRendererOptions
    );
    setDirectionsService(_mapDirectionsService);
    setRenderService(_directionsRenderer);
  };

  if (!googleInit) {
    return <Loading message="Loading map tools..." />;
  }

  if (!home || !home.lat || !home.lng) {
    return <Loading message="Waiting for home coordinates..." />;
  }

  return (
    <>
      <div className="fixed z-10 top-24 right-8">
        <div className="flex flex-col w-10 text-gray-700 bg-gray-100 rounded-lg shadow">
          <button
            className="flex items-center justify-center w-full h-10 transition-colors duration-300 rounded-t-lg cubic hover:bg-blue-200 hover:text-blue-700"
            onClick={() => mapObj.setZoom(mapObj.getZoom() + 1)}
          >
            <AddIcon />
          </button>
          <hr className="my-0" />
          <button
            className="flex items-center justify-center w-full h-10 transition-colors duration-300 rounded-b-lg cubic hover:bg-blue-200 hover:text-blue-700"
            onClick={() => mapObj.setZoom(mapObj.getZoom() - 1)}
          >
            <RemoveIcon />
          </button>
        </div>
        <div className="flex flex-col gap-2 mt-2">
          {travelMode === google.maps.TravelMode.DRIVING ? (
            <button
              className="bg-gray-100 shadow btn-icon hover:bg-blue-200 hover:text-blue-700"
              onClick={() => setTravelMode(google.maps.TravelMode.TRANSIT)}
            >
              <CarIcon />
            </button>
          ) : travelMode === google.maps.TravelMode.TRANSIT ? (
            <button
              className="bg-gray-100 shadow btn-icon hover:bg-blue-200 hover:text-blue-700"
              onClick={() => setTravelMode(google.maps.TravelMode.DRIVING)}
            >
              <TransitIcon />
            </button>
          ) : null}
          <button
            className="bg-gray-100 shadow btn-icon hover:bg-blue-200 hover:text-blue-700"
            onClick={onHelpClick}
          >
            <HelpIcon />
          </button>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 z-10 p-4 space-x-3 text-right bg-white border-t border-gray-300 rounded-lg shadow-lg md:border-t-0 md:left-auto md:bottom-4 md:right-8">
        <Button
          onClick={handleGoBack}
          variant="flat"
          color="default"
          disabled={isSubmitted}
        >
          Go back
        </Button>
        <Button
          onClick={handleAddFacility}
          variant="contained"
          color="primary"
          isLoading={isSubmitted}
          disabled={!facility || worksAtFacility || isSubmitted}
        >
          Add facility
        </Button>
      </div>
      <div className="flex flex-col-reverse flex-1 md:flex-row">
        <Sidebar
          facilities={facilities}
          directionsSet={directionsSet}
          travelMode={travelMode}
          setTravelMode={setTravelMode}
          facility={facility}
          onOver={setIconHighlight}
          onFacilitySelect={handleSelectFacility}
          onHelpClick={onHelpClick}
          appointmentProducts={appointmentProducts}
        />
        <div className="flex-1">
          <div
            className={`top-0 z-0 w-full bg-white md:sticky h-[50vh] ${
              fullscreen ? 'md:h-[100vh]' : 'md:min-h-screen md:top-16'
            }`}
          >
            <GoogleMap
              className="bg-red-200"
              bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
              defaultZoom={14}
              defaultCenter={home}
              disableDefaultUI={true}
              options={(maps) => ({
                disableDefaultUI: true,
                clickableIcons: false,
                gestureHandling: 'greedy',
                scrollwheel: true,
              })}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              onClick={handleMapClick}
            >
              <AnyReactComponent
                lat={home.lat}
                lng={home.lng}
                className="z-40 "
              >
                <svg
                  width="44"
                  height="50"
                  viewBox="0 0 44 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.5753 50C21.5753 50 11.1305 39.9764 7.22879 36.2381C3.30494 32.4786 0 26.5104 0 20.7612C0 9.29509 9.65961 0 21.5753 0C24.4106 0 27.1181 0.526242 29.5981 1.48276C37.541 4.54624 43.1507 12.0233 43.1507 20.7612C43.1507 26.9111 40.5873 32.4365 36.1718 36.2381C32.3551 39.5242 21.5753 50 21.5753 50Z"
                    fill="#F56565"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.5753 47.5079C21.5753 47.5079 12.1717 38.4835 8.65889 35.1179C5.12618 31.7331 2.15068 26.3599 2.15068 21.1837C2.15068 10.8606 10.8474 2.49207 21.5753 2.49207C24.1279 2.49207 26.5655 2.96585 28.7984 3.82702C35.9495 6.58513 41 13.3169 41 21.1837C41 26.7206 38.6921 31.6952 34.7168 35.1179C31.2805 38.0764 21.5753 47.5079 21.5753 47.5079Z"
                    fill="#FEE6E6"
                  />
                  <path
                    d="M9 21.6943L20.9387 9.45018C21.5253 8.84994 22.4747 8.84994 23.06 9.45018L35 21.6943M12 18.6179V32.4618C12 33.3109 12.672 34 13.5 34H19V27.3344C19 26.4853 19.672 25.7962 20.5 25.7962H23.5C24.328 25.7962 25 26.4853 25 27.3344V34H30.5C31.328 34 32 33.3109 32 32.4618V18.6179M17 34H28"
                    stroke="#F56565"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </AnyReactComponent>
              {facilities &&
                facilities.map((fac) => {
                  const worksHereAlready = appointmentProducts?.some(
                    (aptProd) =>
                      aptProd.facility.id === fac.id &&
                      aptProd.status !== AppointmentProductStatus.Unlisted
                  );
                  const isHighlight = iconHighlight === fac.id;
                  const isSelected = facility?.slug === fac.slug;
                  const color =
                    isHighlight || isSelected
                      ? 'emphasis'
                      : !!facility
                      ? 'deemphasis'
                      : 'default';
                  const size = isHighlight
                    ? 'large'
                    : isSelected
                    ? 'large'
                    : 'default';
                  return (
                    <AnyReactComponent
                      key={fac.id}
                      lat={fac.region.latlng.lat}
                      lng={fac.region.latlng.lng}
                      onMarkerClick={(evt) => handleMapPinClick(evt, fac)}
                      className={`"border-0 cursor-pointer ${
                        isHighlight || isSelected ? 'z-50' : 'z-10'
                      }`}
                      onMouseOver={() => setIconHighlight(fac.id)}
                      onMouseOut={() => setIconHighlight(undefined)}
                    >
                      <span
                        className={`absolute z-50 pointer-events-none ${
                          isHighlight ? 'block' : 'hidden'
                        } p-1 -translate-x-1/2 bg-white border border-gray-300 rounded-full shadow-md left-1/2 -top-4 w-max group-hover:block`}
                      >
                        {fac.shortName}
                      </span>
                      <MapPin
                        checked={worksHereAlready}
                        size={size}
                        color={color}
                      />
                    </AnyReactComponent>
                  );
                })}
            </GoogleMap>
          </div>
        </div>
      </div>
      <Modal
        title="Choosing a location"
        name="Instructor — Help Choosing Facility"
        open={showHelp}
        onClose={onHelpClick}
        maxWidth="xs"
      >
        <HelpModal onHelpClick={onHelpClick} />
      </Modal>
    </>
  );
};

export default FacilityMap;
