import Link from 'components/link';
import * as React from 'react';
import css from './style.module.scss';

export interface BaseProps {
  size?: number;
  className?: string;
  themeName?: string;
  href?: string;
  onClick?: () => void;
  style?: any;
}

interface Props extends BaseProps {
  id?: string;
  icon: string;
}

const Icon: React.FunctionComponent<Props> = (props) => {
  const theme = !!props.themeName ? ' ' + css[props.themeName] : '';
  const className = !!props.className ? ' ' + props.className : '';
  const clickable = !!props.onClick ? ' ' + css.clickable : '';
  const classNames = `material-icons${theme}${className}${clickable}`;
  const size = props.size && {
    fontSize: props.size,
    height: props.size,
    lineHeight: 1,
    width: props.size,
  };
  const style = props.style ? { ...props.style, ...size } : { ...size };
  return typeof props.href === 'undefined' ? (
    <span
      id={props.id}
      onClick={props.onClick}
      style={style}
      className={classNames}
    >
      {props.icon}
    </span>
  ) : (
    <Link to={props.href}>
      <span id={props.id} style={style} className={classNames}>
        {props.icon}
      </span>
    </Link>
  );
};

export default Icon;
