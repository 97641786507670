import CancelIcon from '@mui/icons-material/Cancel';
import PhoneIcon from '@mui/icons-material/Phone';
import { TimeFrame } from 'api/Serializers/Appointments';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { AssistanceWizardProps } from 'models/Assistance';
import moment from 'moment-timezone';
import React from 'react';
import { SHARED_ROUTES } from 'utils/routing';

const minWaitMinutes = 15;
const ClientSelectProblemView = (props: AssistanceWizardProps) => {
  const { appointment, trackAction } = props;

  const canReportNoShow = moment().isSameOrAfter(
    moment(appointment.start).add(minWaitMinutes, 'minutes')
  );

  const canCallInstructor = appointment.timeFrame === TimeFrame.HasStarted;
  const handleInstructorCancellation = () => {
    trackAction({
      type: 'SET_CATEGORY',
      key: 'category',
      value: 'INSTRUCTOR',
    });
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'INSTRUCTOR_NOT_HERE',
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'INSTRUCTOR_CANCELLATION',
      title: 'What happened?',
      description:
        appointment.timeFrame < TimeFrame.HasEnded
          ? 'I have previously spoken to the instructor and they will not attend'
          : 'My instructor never showed up!',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'INSTRUCTOR_NO_SHOW',
    });
  };

  return (
    <>
      <h2>Instructor Assistance</h2>
      <h4>I can't find my instructor</h4>
      <Callout className="my-8" type="info">
        Stand at the meeting spot and use the "Call my instructor" feature below
      </Callout>
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="Call my instructor"
          subtitle="Call your instructor to quickly locate them"
          icon={<PhoneIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'instructor/call')}
          disabled={!canCallInstructor}
        />
        {appointment.timeFrame < TimeFrame.HasEnded ? (
          <ButtonLarge
            title="Instructor has said they won't attend"
            subtitle={`${
              !canReportNoShow
                ? '**Disabled until 15 minutes after lesson start** '
                : ''
            }After speaking with your instructor, they have stated they will not attend`}
            icon={<CancelIcon className="text-gray-600" color="inherit" />}
            to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'instructor/note')}
            disabled={
              appointment.timeFrame < TimeFrame.HasStarted || !canReportNoShow
            }
            onClick={handleInstructorCancellation}
          />
        ) : (
          <ButtonLarge
            title="My instructor never showed up"
            subtitle="Your instructor did not show and you were unable to reach them"
            icon={<CancelIcon className="text-gray-600" color="inherit" />}
            to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'instructor/note')}
            disabled={appointment.timeFrame === TimeFrame.Locked}
            onClick={handleInstructorCancellation}
          />
        )}
      </div>
      <Controls variant="block">
        <BackButton>Go back</BackButton>
      </Controls>
    </>
  );
};

export default ClientSelectProblemView;
