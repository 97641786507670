import { Event, TimeFrame } from 'api/Serializers/Appointments';
import { ReportType } from 'models/Assistance';
import { getAppointmentTimeFrame } from 'utils/business-logic';

export const canCallHost = (appointment: Event) => {
  const timeFrame = getAppointmentTimeFrame(appointment);
  return (
    [TimeFrame.SameDay, TimeFrame.Within2, TimeFrame.HasStarted].indexOf(
      timeFrame
    ) !== -1
  );
};

export const canCallClient = (appointment: Event) => {
  const timeFrame = getAppointmentTimeFrame(appointment);
  return [TimeFrame.Within2, TimeFrame.HasStarted].indexOf(timeFrame) !== -1;
};

export const celsiusToFahrenheit = (temp: number) =>
  Math.round((temp * 9) / 5 + 32);
export const fahrenheitToCelsius = (temp: number) =>
  Math.round(((temp - 32) * 5) / 9);

export const getReportTypeDescription = (type: ReportType) => {
  switch (type) {
    case 'CHANGE_NUM_PARTICIPANTS':
      return 'Lesson has incorrect number of swimmers';
    case 'CLIENT_NOT_HERE':
      return 'Client did not show up to this lesson';
    case 'CLIENT_CANCEL_REQUEST':
      return 'Client would like to cancel';

    case 'INSTRUCTOR_CANCEL_REQUEST':
      return 'Instructor would like to cancel';
    case 'INSTRUCTOR_NOT_HERE':
      return 'Instructor is not here';

    case 'FACILITY_INACCESSIBLE':
      return 'Pool closed or inaccessible';
    case 'FACILITY_FOULING':
      return 'There was a pool fouling';
    case 'FACILITY_URGENT_MAINTENANCE':
      return 'Urgent pool maintenance needed';
    case 'FACILITY_COLD':
      return 'Pool is cold';
    case 'FACILITY_CROWDED':
      return 'Pool is too crowded to safely continue';
    case 'FACILITY_NEEDS_ATTENTION':
      return 'Facility needs attention';
    case 'GENERAL':
      return 'General report submission';
    default:
      return '';
  }
};

// export const getReportResults = (result: ReportResult) => {
//   switch (result) {
//     case 'NO_CHANGE':
//       return {
//         isAppointmentCancelled: false,
//         isClientRefunded: false,
//         isInstructorPaid: true,
//       };
//     case 'CANCELLATION':
//       return {
//         isAppointmentCancelled: true,
//         isClientRefunded: true,
//         isInstructorPaid: false,
//       };
//     case 'INSTRUCTOR_NO_SHOW':
//       return {
//         isAppointmentCancelled: true,
//         isClientRefunded: true,
//         isInstructorPaid: false,
//       };
//     case 'CLIENT_NO_SHOW':
//       return {
//         isAppointmentCancelled: true,
//         isClientRefunded: false,
//         isInstructorPaid: true,
//       };
//     case 'CLIENT_CAUSED_FOULING':
//       return {
//         isAppointmentCancelled: true,
//         isClientRefunded: false,
//         isInstructorPaid: true,
//       };
//     case 'FACILITY_NOT_AVAILABLE':
//       return {
//         isAppointmentCancelled: true,
//         isClientRefunded: true,
//         isInstructorPaid: true,
//       };
//     default:
//       return {
//         isAppointmentCancelled: false,
//         isClientRefunded: false,
//         isInstructorPaid: true,
//       };
//   }
// };
