import { Google } from '@mui/icons-material';
import api from 'api';
import {
  AppointmentFeedbackSerializer,
  FeedbackCategory,
} from 'api/Serializers/AppointmentFeedback';
import {
  AppointmentFeedbackStageSerializer,
  Event,
} from 'api/Serializers/Appointments';
import Avatar from 'components/avatar';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Hero from 'components/hero';
import Link from 'components/link';
import Loading from 'components/loading';
import ThumbsButtonGroup from 'components/ratings-button-group';
import Redirect from 'components/redirect';
import { DATE_FMT } from 'config';
import Page from 'containers/page';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  FeedbackErrorAppointmentInFuture,
  FeedbackSubmitSuccess,
  GenericServerError,
  GoogleReviewSubmitSuccess,
  PreviousAppointmentFeedbackFound,
} from 'lang/en/Snackbars';
import { AppointmentFeedbackMeta as Meta } from 'metadata';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { CLIENT_ROUTES } from 'pages/account/client/utils';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { APP_ROUTES } from 'utils/routing';
import { FeedbackTitle } from '../components';
import { capitalizeFirstLetter } from '../utils';

const APPOINTMENT_TAG_OPTIONS = {
  INSTRUCTOR: {
    POSITIVE: [
      {
        label: 'Punctual and prepared',
        subject: 'Punctuality',
        positive: true,
        category: FeedbackCategory.Instructor,
      },
      {
        label: 'Friendly and professional',
        subject: 'Professionalism',
        positive: true,
        category: FeedbackCategory.Instructor,
      },
      {
        label: 'Knowledgeable and good at teaching',
        subject: 'TeachingAbility',
        positive: true,
        category: FeedbackCategory.Instructor,
      },
      {
        label: 'A good fit for my needs',
        subject: 'MetNeeds',
        positive: true,
        category: FeedbackCategory.Instructor,
      },
      {
        label: 'Other (please explain below)',
        subject: 'Other',
        positive: true,
        category: FeedbackCategory.Instructor,
      },
    ],
    NEGATIVE: [
      {
        label: 'Arrived late or unprepared',
        subject: 'Punctuality',
        positive: false,
        category: FeedbackCategory.Instructor,
      },
      {
        label: 'Unfriendly or unprofessional',
        subject: 'Professionalism',
        positive: false,
        category: FeedbackCategory.Instructor,
      },
      {
        label: 'Poor teaching skills or not knowledgeable',
        subject: 'TeachingAbility',
        positive: false,
        category: FeedbackCategory.Instructor,
      },
      {
        label: 'Not a good fit for my needs',
        subject: 'MetNeeds',
        positive: false,
        category: FeedbackCategory.Instructor,
      },
      {
        label: 'Other (please explain below)',
        subject: 'Other',
        positive: false,
        category: FeedbackCategory.Instructor,
      },
    ],
  },
  FACILITY: {
    POSITIVE: [
      {
        label: 'Deck/changing areas were clean',
        subject: 'DeckCleanliness',
        positive: true,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Pool water was clear and comfortable',
        subject: 'WaterQuality',
        positive: true,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Pool was a comfortable temperature',
        subject: 'WaterTemperature',
        positive: true,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Pool ambience and lighting was good',
        subject: 'Ambience',
        positive: true,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Observation area seating was good',
        subject: 'Seating',
        positive: true,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Other (please explain below)',
        subject: 'Other',
        positive: true,
        category: FeedbackCategory.Facility,
      },
    ],
    NEGATIVE: [
      {
        label: 'Deck/changing areas were not clean',
        subject: 'DeckCleanliness',
        positive: false,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Pool water was cloudy or dirty',
        subject: 'WaterQuality',
        positive: false,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Pool was not at a comfortable temperature',
        subject: 'WaterTemperature',
        positive: false,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Pool ambience and lighting was dark or dingy',
        subject: 'Ambience',
        positive: false,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Observation area seating was not good',
        subject: 'Seating',
        positive: false,
        category: FeedbackCategory.Facility,
      },
      {
        label: 'Other (please explain below)',
        subject: 'Other',
        positive: false,
        category: FeedbackCategory.Facility,
      },
    ],
  },
  PROPEL: {
    POSITIVE: [
      {
        label: 'Access instructions were clear',
        subject: 'AccessInstructions',
        positive: true,
        category: FeedbackCategory.Propel,
      },
      {
        label: 'Confirmations and reminders were useful',
        subject: 'AppointmentReminders',
        positive: true,
        category: FeedbackCategory.Propel,
      },
      {
        label: 'Help was available when I needed it',
        subject: 'CustomerService',
        positive: true,
        category: FeedbackCategory.Propel,
      },
      {
        label: 'Booking and managing this lesson was easy',
        subject: 'EaseOfBooking',
        positive: true,
        category: FeedbackCategory.Propel,
      },
      {
        label: 'Other (please explain below)',
        subject: 'Other',
        positive: true,
        category: FeedbackCategory.Propel,
      },
    ],
    NEGATIVE: [
      {
        label: 'Access instructions were not clear',
        subject: 'AccessInstructions',
        positive: false,
        category: FeedbackCategory.Propel,
      },
      {
        label: 'Confirmations and reminders were not useful',
        subject: 'AppointmentReminders',
        positive: false,
        category: FeedbackCategory.Propel,
      },
      {
        label: 'Help was not available when I needed it',
        subject: 'CustomerService',
        positive: false,
        category: FeedbackCategory.Propel,
      },
      {
        label: 'Booking and managing this lesson was difficult',
        subject: 'EaseOfBooking',
        positive: false,
        category: FeedbackCategory.Propel,
      },
      {
        label: 'Other (please explain below)',
        subject: 'Other',
        positive: false,
        category: FeedbackCategory.Propel,
      },
    ],
  },
};

enum OverallRating {
  Positive = 'positive',
  Negative = 'negative',
}

const AppointmentFeedback = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { eventId, overallRating } = useParams<any>();
  const [eventData, setEventData] = useState<Event>();
  const [stageData, setStageData] =
    useState<AppointmentFeedbackStageSerializer>();
  // const [submittedData, setSubmittedData] = useState({
  //   submitted: false,
  //   instructor: {
  //     positive: null,
  //     comments: null,
  //   },
  //   facility: {
  //     positive: null,
  //     comments: null,
  //   },
  // });
  const isOverallPositive = overallRating === OverallRating.Positive;

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [submittedData]);

  useEffect(() => {
    const getStageData = async () => {
      try {
        const res = await api.appointments.feedbackStage(eventId);
        setStageData(res.data);
      } catch {
        enqueueSnackbar(GenericServerError);
        history.push(CLIENT_ROUTES.SCHEDULE.ROOT);
      }
    };
    const getEventData = async () => {
      try {
        const res = await api.appointments.retrieve(eventId);
        setEventData(res.data);
      } catch {
        enqueueSnackbar(GenericServerError);
      }
    };
    getEventData();
    getStageData();
  }, []);

  const previousFeedbackFound = () => {
    enqueueSnackbar(PreviousAppointmentFeedbackFound);
    return <Redirect to={CLIENT_ROUTES.SCHEDULE.ROOT} />;
  };

  const appointmentInFuture = () => {
    enqueueSnackbar(FeedbackErrorAppointmentInFuture);
    return <Redirect to={CLIENT_ROUTES.SCHEDULE.ROOT} />;
  };

  if (!Object.values(OverallRating).includes(overallRating)) {
    enqueueSnackbar(GenericServerError);
    return <Redirect to={CLIENT_ROUTES.SCHEDULE.ROOT} />;
  }

  return (
    <Page {...Meta}>
      {stageData === undefined || eventData === undefined ? (
        <Loading />
      ) : stageData.appointmentFeedbackSubmitted ? (
        previousFeedbackFound()
      ) : moment().utc().isBefore(eventData.start) ? (
        appointmentInFuture()
      ) : (
        <div className="flex justify-center bg-background min-h-90vh py-8 sm:px-6 lg:px-8">
          <div>
            {isOverallPositive &&
            !stageData.googleReviewSubmitted &&
            stageData.facility.googleReviewUrl ? (
              <GoogleReview
                eventId={eventId}
                stageData={stageData}
                setStageData={setStageData}
              />
            ) : isOverallPositive && !stageData.instructorReviewSubmitted ? (
              <InstructorReview
                stageData={stageData}
                // submittedData={submittedData}
              />
            ) : (
              <FeedbackForm
                eventId={eventId}
                isOverallPositive={isOverallPositive}
                stageData={stageData}
                eventData={eventData}
                // setSubmittedData={setSubmittedData}
              />
            )}
          </div>
        </div>
      )}
    </Page>
  );
};

const FeedbackForm = ({
  eventId,
  isOverallPositive,
  stageData,
  eventData,
}: // setSubmittedData,
{
  eventId: string;
  isOverallPositive: boolean;
  stageData: AppointmentFeedbackStageSerializer;
  eventData: Event;
  // setSubmittedData(data): void;
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [disabled, setDisabled] = useState<boolean>(false);
  // const [appointmentRating, setAppointmentRating] = useState(isOverallPositive);
  const [instructorFeedback, setInstructorFeedback] = useState({
    rating: {
      category: FeedbackCategory.Instructor,
      positive: null,
      comments: '',
    },
    tags: [],
  });
  const [facilityFeedback, setFacilityFeedback] = useState({
    rating: {
      category: FeedbackCategory.Facility,
      positive: null,
      comments: '',
    },
    tags: [],
  });
  const [propelFeedback, setPropelFeedback] = useState({
    rating: { category: FeedbackCategory.Propel, positive: null, comments: '' },
    tags: [],
  });

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setDisabled(true);
    try {
      const ratings = [
        {
          category: FeedbackCategory.Appointment,
          positive: isOverallPositive,
          comments: '',
        },
      ];
      [instructorFeedback, facilityFeedback, propelFeedback].forEach((elt) => {
        if (elt.rating.positive !== null) {
          ratings.push(elt.rating);
        }
      });
      const feedback: AppointmentFeedbackSerializer = {
        event: eventId,
        ratings: ratings,
        tags: [].concat(
          instructorFeedback.tags,
          facilityFeedback.tags,
          propelFeedback.tags
        ),
      };
      await api.appointmentFeedback.create(feedback);
      // if (
      //   stageData.googleReviewSubmitted &&
      //   stageData.instructorReviewSubmitted
      // ) {
      enqueueSnackbar(FeedbackSubmitSuccess);
      history.push(CLIENT_ROUTES.SCHEDULE.ROOT);
      // } else {
      // setSubmittedData({
      //   submitted: true,
      //   instructor: {
      //     positive: instructorFeedback.rating.positive,
      //     comments: instructorFeedback.rating.comments,
      //   },
      //   facility: {
      //     positive: facilityFeedback.rating.positive,
      //     comments: facilityFeedback.rating.comments,
      //   },
      // });
      // enqueueSnackbar(FeedbackSubmitSuccess);
      // }
    } catch {
      enqueueSnackbar(GenericServerError);
      setDisabled(false);
    }
  };

  return (
    <div className="px-6 sm:px-10 py-12 bg-white rounded-lg shadow-lg">
      <FeedbackTitle
        title="Tell us more about your experience"
        subtitle="Your answers help improve your community."
      />
      <form className="space-y-8" onSubmit={handleSubmit}>
        <Callout type="info">
          {eventData.activity.name}{' '}
          {moment(eventData.start)
            .tz(eventData.timezone)
            .format(DATE_FMT.DISPLAY_DT)}
        </Callout>
        {/* <div className="flex flex-col items-center justify-between my-2 space-y-2 sm:flex-row sm:space-y-0">
          <span className="font-semibold text-gray-800 text-md">
            How did your {eventData.activity.appointmentNoun.toLowerCase()} go?
          </span>
          <ThumbsButtonGroup
            value={appointmentRating}
            handleClick={setAppointmentRating}
          />
        </div> */}
        {/* <div
          className={`flex flex-col gap-y-8${
            appointmentRating === null ? ' hidden' : ''
          }`}
        > */}
        <FeedbackFormCategory
          feedbackData={instructorFeedback}
          setFeedbackData={setInstructorFeedback}
          categoryLabel="Would you recommend your instructor to others?"
          thumbLabel={stageData.instructor.fullName}
          avatar={stageData.instructor.avatar}
          options={
            instructorFeedback.rating.positive
              ? APPOINTMENT_TAG_OPTIONS.INSTRUCTOR.POSITIVE
              : !instructorFeedback.rating.positive
              ? APPOINTMENT_TAG_OPTIONS.INSTRUCTOR.NEGATIVE
              : []
          }
        />
        <FeedbackFormCategory
          feedbackData={facilityFeedback}
          setFeedbackData={setFacilityFeedback}
          categoryLabel="Did you enjoy the pool where you took your lesson?"
          thumbLabel={stageData.facility.displayName}
          avatar={stageData.facility.avatar}
          options={
            facilityFeedback.rating.positive
              ? APPOINTMENT_TAG_OPTIONS.FACILITY.POSITIVE
              : !facilityFeedback.rating.positive
              ? APPOINTMENT_TAG_OPTIONS.FACILITY.NEGATIVE
              : []
          }
        />
        <FeedbackFormCategory
          feedbackData={propelFeedback}
          setFeedbackData={setPropelFeedback}
          categoryLabel="Did you enjoy using Propel's service?"
          thumbLabel="Propel"
          avatar="https://s3.ca-central-1.amazonaws.com/www.propelhq.com/android-chrome-192x192.png"
          options={
            propelFeedback.rating.positive
              ? APPOINTMENT_TAG_OPTIONS.PROPEL.POSITIVE
              : !propelFeedback.rating.positive
              ? APPOINTMENT_TAG_OPTIONS.PROPEL.NEGATIVE
              : []
          }
        />
        {/* </div> */}
        <Button
          // disabled={disabled || appointmentRating === null}
          disabled={disabled}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={true}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

const FeedbackFormCategory = ({
  options,
  categoryLabel,
  thumbLabel,
  avatar,
  feedbackData,
  setFeedbackData,
}): JSX.Element => {
  const onThumbClick = (isPositive: boolean) => {
    setFeedbackData({
      rating: {
        category: feedbackData.rating.category,
        positive:
          feedbackData.rating.positive !== isPositive ? isPositive : null,
        comments: '',
      },
      tags: [],
    });
  };
  return (
    <div>
      <span className="font-normal text-gray-700 text-md">{categoryLabel}</span>
      <div className="flex flex-col items-center justify-between my-2 space-y-2 sm:flex-row sm:space-y-0">
        <div className="flex items-center gap-x-2">
          <Avatar className="bg-transparent" src={avatar} />
          <span className="font-semibold text-gray-800">{thumbLabel}</span>
        </div>
        <ThumbsButtonGroup
          value={feedbackData.rating.positive}
          handleClick={onThumbClick}
        />
      </div>
      {/* <ReadMore initialHeight={0} openBtnText="Tell us more"> */}
      <div
        className={`pb-8${
          feedbackData.rating.positive === null ? ' hidden' : ''
        }`}
      >
        <div className="mt-4">
          <label className="mb-2">
            {feedbackData.positive
              ? 'What did you enjoy?'
              : 'What could be improved?'}{' '}
            (Select all that apply)
          </label>
          <div className="space-y-3">
            {options.map((option) => {
              const isSelected =
                feedbackData.tags.findIndex(
                  (i) => i.subject === option.subject
                ) !== -1;
              return (
                <ButtonLarge
                  key={feedbackData.rating.category + option.subject}
                  className="max-w-xl"
                  variant="check"
                  title={option.label}
                  selected={isSelected}
                  onClick={() => {
                    setFeedbackData({
                      rating: feedbackData.rating,
                      tags: !isSelected
                        ? [...feedbackData.tags].concat([
                            {
                              subject: option.subject,
                              positive: option.positive,
                              category: option.category,
                            },
                          ])
                        : [...feedbackData.tags].filter(
                            (i) => i.subject !== option.subject
                          ),
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor={feedbackData.rating.category.toLowerCase() + 'Comments'}
          >
            Additional{' '}
            {feedbackData.rating.category === FeedbackCategory.Propel
              ? capitalizeFirstLetter(
                  feedbackData.rating.category.toLowerCase()
                )
              : feedbackData.rating.category.toLowerCase()}{' '}
            feedback (optional)
          </label>
          <textarea
            className="mt-2"
            name={feedbackData.rating.category.toLowerCase() + 'Comments'}
            value={feedbackData.rating.comments}
            onChange={(event) =>
              setFeedbackData({
                rating: {
                  category: feedbackData.rating.category,
                  positive: feedbackData.rating.positive,
                  comments: event.target.value,
                },
                tags: feedbackData.tags,
              })
            }
            rows={3}
            maxLength={800}
          />
          <div
            className={`text-sm text-right ${
              feedbackData.rating.comments.length === 800
                ? 'text-gray-700'
                : 'text-gray-600'
            }`}
          >
            {feedbackData.rating.comments.length > 0
              ? `${
                  800 - feedbackData.rating.comments.length
                } ${'character'.pluralize(
                  800 - feedbackData.rating.comments.length
                )} left`
              : ''}
          </div>
        </div>
      </div>
      {/* </ReadMore> */}
    </div>
  );
};

const GoogleReview = ({ eventId, stageData, setStageData }): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const handleSubmit = async (writtenPreviously) => {
    setDisabled(true);
    if (writtenPreviously) {
      try {
        await api.appointments.googleReview(eventId);
        const response = await api.appointments.feedbackStage(eventId);
        setStageData(response.data);
        enqueueSnackbar(GoogleReviewSubmitSuccess);
        window.scrollTo(0, 0);
      } catch {
        enqueueSnackbar(GenericServerError);
        history.push(CLIENT_ROUTES.SCHEDULE.ROOT);
      }
    } else {
      enqueueSnackbar(GoogleReviewSubmitSuccess);
      history.push(CLIENT_ROUTES.SCHEDULE.ROOT);
      window.open(stageData.facility.googleReviewUrl);
    }
  };
  return (
    <div className="max-w-2xl py-12 bg-white rounded-lg shadow-lg">
      <FeedbackTitle title="Terrific! Can you help us keep it going?" />
      <p className="px-6 sm:px-8">
        We're on a mission make swimming lessons more accessible, flexible, and
        impactful.
      </p>
      <Hero
        background="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2Fib3V0LXVzL2hlcm8tYmcuanBnIiwiYnVja2V0Ijoib2xla3NpYWsifQ=="
        height="auto"
      />
      <div className="pt-8 px-6 sm:px-8 space-y-8">
        <p>
          With your support, we can add new features and pool space while
          ensuring instructors earn sustainable living wages doing what they
          love!
        </p>
        {/* <CopyPreviousFeedback
          title="Copy your feedback for Google"
          text={submittedData.facility.additional}
        /> */}
        <div className="flex flex-col sm:flex-row gap-4">
          <Button
            disabled={disabled}
            type="button"
            name="newReview"
            variant="flat"
            color="primary"
            onClick={() => handleSubmit(false)}
            icon={<Google />}
            fullWidth
          >
            Leave us a Google Review
          </Button>
          <Button
            disabled={disabled}
            type="button"
            name="previousReview"
            variant="flat"
            onClick={() => handleSubmit(true)}
            fullWidth
          >
            I've already left one 😎
          </Button>
        </div>
        <div className="flex justify-center">
          <Link to={APP_ROUTES.ABOUT_US}>Read more about our mission</Link>
        </div>
      </div>
    </div>
  );
};

const InstructorReview = ({
  stageData,
  // submittedData
}): JSX.Element => {
  return (
    <div className="max-w-xl px-6 sm:px-10 py-12 bg-white rounded-lg shadow-lg">
      <FeedbackTitle title="Leave your instructor a review!" />
      <div className="space-y-8">
        <div className="flex items-center gap-x-5">
          <Avatar
            className="bg-transparent"
            src={stageData.instructor.avatar}
            diameter={32}
          />
          <p className="text-gray-700">
            We're glad you had a great lesson with your instructor,{' '}
            {stageData.instructor.firstName}. Show your support by leaving an
            instructor review!
          </p>
        </div>
        {/* <CopyPreviousFeedback
          title="Copy your feedback for the review"
          text={submittedData.instructor.comments}
        /> */}
        <Button
          to={CLIENT_ROUTES.INSTRUCTORS.nav(
            stageData.instructor.slug,
            'review'
          )}
          variant="contained"
          color="primary"
          fullWidth
        >
          Go to instructor review
        </Button>
      </div>
    </div>
  );
};

export default AppointmentFeedback;
