import {
  AccountUpdateSerializer,
  CertificateType,
  default as Account,
  default as InstructorAccount,
  InstructorOnboardingSerializer,
  ReasonForJoining,
  SelfDescribedAs,
  YearsExperience,
} from 'api/Serializers/Accounts/Instructor';
import { AppointmentProduct } from 'api/Serializers/AppointmentProducts';
import Button from 'components/button';
import Loading from 'components/loading';
import Modal from 'components/modal';
import Waitlist from 'components/waitlist';
import { FETCH_STATE, MaxWidth, ONBOARDING_V2_DATE, UserType } from 'config';
import Page from 'containers/page';
import TaskCard from 'containers/task-card';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { NewInstructorFormMeta as Meta } from 'metadata';
import { AccountRouteParams } from 'models/route';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import {
  getAccountDetail,
  getInstructorFetchState,
  getInstructorOnboarding,
  getScheduleAppointmentProducts,
  getUsername,
  getUserType,
} from 'state/selectors';
import { modifyAccount, retrieveAccount } from 'state/slice/account';
import {
  fetchInstructorDetail,
  fetchInstructorOnboarding,
  modifyOnboarding,
} from 'state/slice/instructor';
import { fetchAppointmentProducts } from 'state/slice/schedule';
import { isInstructorOldEnough } from 'utils/business-logic';
import { InstructorOnboardingRoutes, SHARED_ROUTES } from 'utils/routing';
import { INSTRUCTOR_ROUTES } from '../utils';
import Progress from './components/progress';
import ErrorTooYoung from './e01-too-young';
import ContinueOnboarding from './r00-continue';
import Welcome from './r00-welcome';
import Learning_1 from './r01-learning-1';
import Learning_2 from './r02-learning-2';
import WhatDescribesYou from './r03-what-describes-you';
import BasicInfo from './r04-basic-info';
import WorkExperience from './r05-work-experience';
import InsuffExperience from './r06-insuff-work-experience';
import Qualifications from './r07-qualifications';
import InsuffQualifications from './r08-insuff-qualifications';
import ClientExperience from './r09-client-experience';
import UniqueExperience from './r10-unique-experience';
import Profile from './r11-profile';
import TeachingPreferences from './r12-teaching-preferences';
import BrowseFacilities from './r14-browse-facilities';
import SelectPriceAtFacility from './r15-select-price';

export interface BaseStepProps {
  account: Account;
  onboarding: InstructorOnboardingSerializer;
  appointmentProducts: AppointmentProduct[];
  modifyAccount(data: Partial<AccountUpdateSerializer>): Promise<any>;
  modifyOnboarding(data: Partial<InstructorOnboardingSerializer>): Promise<any>;
}

export const TitleClasses = 'text-xl text-gray-800 my-6';
export const HeaderClasses = 'text-lg text-gray-900 mt-6 my-2';
export const TextClasses = 'text-gray-900';
export const QuestionClasses = 'my-8 text-md font-bold text-gray-900';
export const ControlClasses = 'mt-10 mb-6 text-center';
export type InstructorAccountError =
  | 'TOO_YOUNG'
  | 'INSUFFICIENT_EXP'
  | 'INVALID_QUALIFICATIONS';

export interface ScreenRoute {
  step?: number;
  stage?: 'background' | 'profile' | 'appointments';
  isStandalone?: boolean;
  mode: string;
  Component: React.FC<BaseStepProps>;
  maxWidth?: MaxWidth;
  isSet?(
    account: Account,
    onboarding: InstructorOnboardingSerializer,
    appointmentProducts: AppointmentProduct[]
  ): boolean;
  doNotTrack?: boolean; // Overrides setting the user's pageId value
}

export const OnboardingScreens: ScreenRoute[] = [
  {
    step: 1,
    stage: 'background',
    mode: InstructorOnboardingRoutes.S00,
    Component: Welcome,
    isSet: (account, onboarding) =>
      onboarding.reasonToJoin !== ReasonForJoining.Unset,
  },
  {
    step: 1,
    stage: 'background',
    mode: InstructorOnboardingRoutes.continue,
    Component: ContinueOnboarding,
  },
  {
    stage: 'background',
    mode: InstructorOnboardingRoutes.S01,
    Component: Learning_1,
  },
  {
    step: 2,
    stage: 'background',
    mode: InstructorOnboardingRoutes.S02,
    Component: WhatDescribesYou,
    isSet: (account, onboarding) =>
      onboarding.selfDescribedAs !== SelfDescribedAs.Unset,
  },
  {
    stage: 'background',
    mode: InstructorOnboardingRoutes.S03,
    Component: Learning_2,
  },
  {
    step: 3,
    stage: 'background',
    mode: InstructorOnboardingRoutes.S04,
    Component: BasicInfo,
    isSet: (account, onboarding) =>
      account.address.line1 !== '' &&
      account.address.postalCode !== '' &&
      !!account.birthdate &&
      account.emergencyContact &&
      account.emergencyContact.name !== '' &&
      account.emergencyContact.phoneNumber !== '',
  },
  {
    stage: 'background',
    mode: InstructorOnboardingRoutes.ErrorTooYoung,
    Component: ErrorTooYoung,
    doNotTrack: true,
  },
  {
    step: 4,
    stage: 'background',
    mode: InstructorOnboardingRoutes.S05,
    Component: WorkExperience,
    isSet: (account, onboarding) =>
      onboarding.numYearsExp !== YearsExperience.Unset &&
      onboarding.numYearsExp !== YearsExperience.LessThanOne,
  },
  {
    stage: 'background',
    mode: InstructorOnboardingRoutes.S06,
    Component: InsuffExperience,
    doNotTrack: true,
  },
  {
    step: 5,
    stage: 'background',
    mode: InstructorOnboardingRoutes.S07,
    Component: Qualifications,
    isSet: (account, onboarding) =>
      account.certifications.filter(
        (c) => c.certificateType !== CertificateType.Unset
      ).length > 0,
  },
  {
    mode: InstructorOnboardingRoutes.S08,
    Component: InsuffQualifications,
    doNotTrack: true,
  },
  {
    step: 6,
    stage: 'profile',
    mode: InstructorOnboardingRoutes.S09,
    Component: ClientExperience,
    isSet: (account, onboarding) => account.segments.length > 0,
  },
  {
    step: 7,
    stage: 'profile',
    mode: InstructorOnboardingRoutes.S10,
    Component: UniqueExperience,
    isSet: (account, onboarding) => true,
  },
  {
    step: 8,
    stage: 'profile',
    maxWidth: 'xl',
    mode: InstructorOnboardingRoutes.S11,
    Component: Profile,
    isSet: (account, onboarding) =>
      account.description !== '' && account.avatar !== '',
  },
  {
    step: 9,
    stage: 'appointments',
    mode: InstructorOnboardingRoutes.TeachingPreferences,
    Component: TeachingPreferences,
  },
  {
    stage: 'appointments',
    maxWidth: '6xl',
    isStandalone: true,
    mode: InstructorOnboardingRoutes.S14,
    Component: BrowseFacilities,
    isSet: (account, onboarding, appointmentProducts) =>
      appointmentProducts?.length > 0,
  },
  {
    step: 9,
    stage: 'appointments',
    mode: InstructorOnboardingRoutes.SetPrice,
    Component: SelectPriceAtFacility,
    isSet: (account, onboarding, appointmentProducts) =>
      appointmentProducts?.length > 0 && !!appointmentProducts[0].price,
  },
];

const InstructorOnboarding = () => {
  const history = useHistory();
  const username = useSelector(getUsername);
  const userType = useSelector(getUserType);
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const onboarding = useSelector(getInstructorOnboarding);
  const appointmentProducts = useSelector(getScheduleAppointmentProducts);
  const detailFetchState = useSelector(getInstructorFetchState);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState<InstructorAccountError>();
  const [showFacilityWarning, setFacilityWarning] = useState(false);
  const [isWaitlisted, setWaitlisted] = useState(false);
  const dispatch = useAppDispatch();
  const { params } = useRouteMatch<AccountRouteParams>();
  const openModalTest =
    onboarding?.lastStepId === 'welcome' &&
    moment(account?.dateCreated).isBefore(moment(ONBOARDING_V2_DATE));
  const [isOnboardingChangedModalOpen, setChangesModalOpen] =
    useState(openModalTest);

  const validateAccount = (): InstructorAccountError | undefined => {
    if (isInstructorOldEnough(account?.age) === false) {
      return 'TOO_YOUNG';
    }
    return undefined;
  };

  if (userType !== UserType.Instructor) {
    history.replace(SHARED_ROUTES.DASHBOARD.ROOT);
    return null;
  }

  useEffect(() => {
    dispatch(retrieveAccount(username));
    dispatch(fetchInstructorOnboarding(username));
    dispatch(fetchInstructorDetail(username));
    dispatch(fetchAppointmentProducts(true));
    // const script = document.createElement('script');
    // script.src = 'https://static.zcal.co/embed/v1/embed.js';
    // script.async = true;
    // document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (account && !validated) {
      setError(validateAccount());
      setValidated(true);
    }
  }, [account]);

  // useEffect(() => {
  //   const fetchWaitlistedStatus = async () => {
  //     const response = await api.utils.waitlist.get({
  //       email: account.email,
  //       city: account.address.city,
  //       regionCode: account.address.regionCode,
  //       country: account.address.country,
  //       userType: account.type,
  //     });
  //     setWaitlisted(response.data);
  //     setFacilityWarning(!response.data);
  //   };
  //   if (
  //     account &&
  //     facilityFetchState === FETCH_STATE.FULFILLED &&
  //     facilities.length === 0 &&
  //     !showFacilityWarning
  //   ) {
  //     fetchWaitlistedStatus();
  //   }
  // }, [account, facilities, facilityFetchState]);

  useEffect(() => {
    const lastStepIndex = OnboardingScreens.findIndex(
      (s) => s.mode === onboarding.lastStepId
    );
    const thisStepIndex = OnboardingScreens.findIndex(
      (s) => s.mode === params.mode
    );
    if (
      lastStepIndex < thisStepIndex &&
      !OnboardingScreens[thisStepIndex].doNotTrack
    ) {
      dispatch(modifyOnboarding(username, { lastStepId: params.mode }));
    }
  }, [params]);

  if (detailFetchState !== FETCH_STATE.FULFILLED) {
    return <Loading message="Getting progress..." />;
  }

  const closeModal = () => {
    setChangesModalOpen(false);
  };

  const handleModifyAccount = (data: Partial<AccountUpdateSerializer>) =>
    dispatch(modifyAccount(username, data));

  const handleModifyOnboarding = (
    data: Partial<InstructorOnboardingSerializer>
  ) => dispatch(modifyOnboarding(username, data));

  /**
   * Note:
   * Routes are not put into a Switch on purpose.
   * It is done this way to avoid a bug with react-router
   * by letting all routes render.
   */

  if (error === 'TOO_YOUNG') {
    return (
      <TaskCard>
        <ErrorTooYoung />
      </TaskCard>
    );
  }

  // if (!params.mode) {
  //   if (!onboarding.callDatetime) {
  //     return <Redirect to={INSTRUCTOR_ROUTES.ONBOARDING.CONTINUE} />;
  //   } else {
  //     return <PendingDashboard />;
  //   }
  // }

  return (
    <>
      <Switch>
        {OnboardingScreens.map((page, i) => (
          <Route
            key={`p${i}`}
            path={`${INSTRUCTOR_ROUTES.ONBOARDING.ROOT}/${page.mode}`}
            render={(routeProps) => {
              const Component = page.Component as any;
              if (page.isStandalone === true) {
                return (
                  <Component
                    {...routeProps}
                    account={account}
                    onboarding={onboarding}
                    appointmentProducts={appointmentProducts}
                    modifyAccount={handleModifyAccount}
                    modifyOnboarding={handleModifyOnboarding}
                  />
                );
              } else {
                return (
                  <TaskCard maxWidth={page.maxWidth}>
                    {page.mode !== InstructorOnboardingRoutes.continue && (
                      <Progress current={page.stage} />
                    )}
                    <Component
                      {...routeProps}
                      account={account}
                      onboarding={onboarding}
                      appointmentProducts={appointmentProducts}
                      modifyAccount={handleModifyAccount}
                      modifyOnboarding={handleModifyOnboarding}
                    />
                    {page.step && (
                      <div className="mt-4 text-sm text-center text-gray-600">
                        Step {page.step} of{' '}
                        {OnboardingScreens.reduce(
                          (r, c) => (c.step ? r + 1 : r),
                          0
                        )}
                      </div>
                    )}
                    {isWaitlisted && (
                      <div className="p-4 mt-4 text-blue-600 bg-blue-100 border border-blue-300 rounded-lg">
                        We've got you on the facility waitlist for{' '}
                        {account.address.city}, {account.address.regionCode}!
                      </div>
                    )}
                  </TaskCard>
                );
              }
            }}
          />
        ))}
        <Route path={INSTRUCTOR_ROUTES.ONBOARDING.ROOT}>
          <TaskCard>
            <ContinueOnboarding
              account={account}
              onboarding={onboarding}
              appointmentProducts={appointmentProducts}
              modifyAccount={handleModifyAccount}
              modifyOnboarding={handleModifyOnboarding}
            />
          </TaskCard>
        </Route>
      </Switch>
      <Modal
        name="Instructor — Onboarding facility warning"
        maxWidth="sm"
        open={showFacilityWarning}
        onClose={() => setFacilityWarning(false)}
      >
        <Waitlist />
      </Modal>
      <Modal
        name="Instructor — Welcome back"
        maxWidth="sm"
        open={isOnboardingChangedModalOpen}
        title="Welcome back!"
        onClose={closeModal}
      >
        <p>
          It's been a little while since we've seen you 'round these parts 🤠
        </p>
        <p>
          Let's make sure we've still got your answers right, and maybe there's
          a few things we still need your help with.
        </p>
        <div className="flex justify-end">
          <Button variant="contained" onClick={closeModal} color="primary">
            Get started
          </Button>
        </div>
      </Modal>
    </>
  );
};

const InstructorOnboardingContainer = () => {
  return (
    <Page {...Meta}>
      <div className="flex-1 min-h-screen bg-background">
        <InstructorOnboarding />
      </div>
    </Page>
  );
};

export default InstructorOnboardingContainer;
