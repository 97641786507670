import { API_ROOT_URL, UserType } from 'config';
import { ActivityType } from './schema';

const API_V1 = `${API_ROOT_URL}/v1`;

const root = {
  accounts: `${API_V1}/accounts`,
  activities: `${API_V1}/activities`,
  analytics: `${API_V1}/analytics`,
  appointmentFeedback: `${API_V1}/appointment-feedback`,
  appointmentProducts: `${API_V1}/appointment-products`,
  availability: `${API_V1}/availability`,
  availabilityFeedback: `${API_V1}/availability-feedback`,
  auth: `${API_ROOT_URL}/auth`,
  cancellations: `${API_V1}/cancellations`,
  cart: `${API_V1}/cart`,
  giftCards: `${API_V1}/gift-cards`,
  clients: `${API_V1}/clients`,
  conversations: `${API_V1}/conversations`,
  appointments: `${API_V1}/appointments`,
  facilities: `${API_V1}/facilities`,
  favourites: `${API_V1}/favourites`,
  instructors: `${API_V1}/instructors`,
  medias: `${API_V1}/media`,
  notes: `${API_V1}/notes`,
  paymentMethods: `${API_V1}/payment-methods`,
  payouts: `${API_V1}/payouts`,
  prices: `${API_V1}/prices`,
  promotions: `${API_V1}/promotions`,
  proposals: `${API_V1}/proposals`,
  proxySession: `${API_V1}/proxy-session`,
  regions: `${API_V1}/regions`,
  reports: `${API_V1}/reports`,
  reviews: `${API_V1}/reviews`,
  schedules: `${API_V1}/schedules`,
  termsOfService: `${API_V1}/terms-of-service`,
  users: `${API_V1}/users`,
  utils: `${API_V1}/utils`,
};

const urls = {
  account: {
    root: (username: string) => `${root.accounts}/${username}/`,
    activate: (username: string) => `${root.accounts}/${username}/activate/`,
    earlyAccessWindows: (username: string) =>
      `${root.accounts}/${username}/early-access-windows/`,
    certifications: (username: string) =>
      `${root.accounts}/${username}/certifications/`,
    contacts: (username: string) => `${root.accounts}/${username}/contacts/`,
    overview: (username: string) => `${root.accounts}/${username}/overview/`,
    onboarding: (username: string) =>
      `${root.accounts}/${username}/onboarding/`,
    reliability: (username: string) =>
      `${root.accounts}/${username}/reliability/`,
    earnings: (username: string) => `${root.accounts}/${username}/earnings/`,
    instructors: (username: string) =>
      `${root.accounts}/${username}/instructors/`,
    reviews: (username: string) => `${root.accounts}/${username}/reviews/`,
    credit: (username: string) => `${root.accounts}/${username}/credit/`,
    preferredTimes: (username: string) =>
      `${root.accounts}/${username}/preferred-times/`,
    preferences: (username: string) =>
      `${root.accounts}/${username}/preferences/`,
    notices: (username: string) => `${root.accounts}/${username}/notices/`,
    support: `${root.accounts}/support/`,
  },
  activities: {
    root: `${root.activities}/`,
    detail: (activity: ActivityType) => `${root.activities}/${activity}/`,
  },
  analytics: {
    dailySales: `${root.analytics}/daily_sales/`,
    marketplaceKpis: `${root.analytics}/marketplace_kpis/`,
    kpi: `${root.analytics}/kpi/`,
  },
  appointmentProducts: {
    root: `${root.appointmentProducts}/`,
    search: `${root.appointmentProducts}/search/`,
    retrieve: (id: string) => `${root.appointmentProducts}/${id}/`,
    prices: (id: string) => `${root.appointmentProducts}/${id}/prices/`,
  },
  auth: {
    token: `${root.auth}/token/`,
    proxyToken: `${root.auth}/token/proxy/`,
    refresh: `${root.auth}/token/refresh/`,
    verify: `${root.auth}/token/verify/`,
    messaging: `${root.auth}/messaging/`,
    passwordForgot: `${API_ROOT_URL}/password-forgot/`,
    passwordReset: (uid: string) => `${API_ROOT_URL}/password-reset/${uid}/`,
  },
  appointmentFeedback: {
    create: `${root.appointmentFeedback}/`,
  },
  appointments: {
    list: `${root.appointments}/`,
    retrieve: (id: number | string) => `${root.appointments}/${id}/`,
    cancel: (id: number | string) => `${root.appointments}/${id}/cancel/`,
    reports: (id: number | string) => `${root.appointments}/${id}/reports/`,
    participants: (id: string) => `${root.appointments}/${id}/participants/`,
    paymentMethod: (id: string) => `${root.appointments}/${id}/payment_method/`,
    feedbackStage: (id: string) => `${root.appointments}/${id}/feedback_stage/`,
    receipt: (id: string) => `${root.appointments}/${id}/receipt/`,
    googleReview: (id: string) => `${root.appointments}/${id}/google_review/`,
    proxySessions: (id: string) => `${root.appointments}/${id}/proxy_sessions/`,
  },
  availability: {
    list: `${root.availability}/`,
    retrieve: (id: string) => `${root.availability}/${id}/`,
  },
  availabilityFeedback: {
    update: (uid: string) => `${root.availabilityFeedback}/${uid}/`,
  },
  cancellations: {
    waive: (id: string) => `${root.cancellations}/${id}/waive/`,
  },
  cart: {
    list: `${root.cart}/`,
    retrieve: (id: string) => `${root.cart}/${id}/`,
    checkout: (id: string) => `${root.cart}/${id}/checkout/`,
  },
  clients: {
    list: `${root.clients}/`,
    participants: (username: string) =>
      `${root.clients}/${username}/participants/`,
  },
  conversations: {
    root: `${root.conversations}/`,
    messages: (sid: string) => `${root.conversations}/${sid}/messages/`,
  },
  facilities: {
    list: `${root.facilities}/`,
    detail: (slug) => `${root.facilities}/${slug}/`,
    schedulable: `${root.facilities}/schedulable/`,
    instructors: (slug) => `${root.facilities}/${slug}/instructors/`,
    limiters: (slug) => `${root.facilities}/${slug}/limiters/`,
    schedules: (slug) => `${root.facilities}/${slug}/schedules/`,
    googleReviews: (slug) => `${root.facilities}/${slug}/google-reviews/`,
  },
  favourites: {
    root: `${root.favourites}/`,
    detail: (naturalKey) =>
      `${root.favourites}/${naturalKey.contentType}-${naturalKey.objectId}/`,
  },
  reports: {
    list: `${root.reports}/`,
  },
  giftCards: {
    root: `${root.giftCards}/`,
    detail: (id: string) => `${root.giftCards}/${id}/`,
    checkout: (id: string) => `${root.giftCards}/${id}/checkout/`,
    redeem: `${root.giftCards}/redeem/`,
  },
  instructors: {
    /** api.propelhq.com/v1/instructors/instructors/ */
    list: `${root.instructors}/`,
    /** api.propelhq.com/v1/instructors/sean-h/ */
    retrieve: (username: string) => `${root.instructors}/${username}/`,
    /** api.propelhq.com/v1/instructors/sean-h/reviews/ */
    reviews: (username: string) => `${root.instructors}/${username}/reviews/`,
    /** api.propelhq.com/v1/instructors/sean-h/view/ */
    view: (username: string) => `${root.instructors}/${username}/view/`,
    /** api.propelhq.com/v1/instructors/experience/ */
    experience: `${root.instructors}/experience/`,
    /** api.propelhq.com/v1/instructors/instructor-username/facilities/ */
    facilities: (username: string) =>
      `${root.instructors}/${username}/facilities/`,
  },
  medias: {
    retrieve: (mediaId: string) => `${root.medias}/${mediaId}/`,
  },
  notes: {
    list: (username: string) => `${root.notes}/?username=${username}`,
    detail: (id: number) => `${root.notes}/${id}/`,
  },
  paymentMethods: {
    root: `${root.paymentMethods}/`,
    setup: `${root.paymentMethods}/setup/`,
    detail: (id: string) => `${root.paymentMethods}/${id}/`,
    default: (id: string) => `${root.paymentMethods}/${id}/default/`,
  },
  payouts: {
    list: `${root.payouts}/`,
    retrieve: (id) => `${root.payouts}/${id}/`,
    instructors: (id) => `${root.payouts}/${id}/instructors/`,
    details: (id) => `${root.payouts}/${id}/details/`,
    paid: (id) => `${root.payouts}/${id}/paid/`,
    next: `${root.payouts}/next/`,
    monthly: `${root.payouts}/monthly/`,
    summary: `${root.payouts}/summary/`,
    byYear: (year) => `${root.payouts}/by_year/?year=${year}`,
  },
  promotions: {
    retrieve: (promo) => `${root.promotions}/${promo}/`,
    redeem: (promo) => `${root.promotions}/${promo}/redeem/`,
    subscribe: `${root.promotions}/subscribe/`,
    current: `${root.promotions}/current/`,
  },
  proposals: {
    root: `${root.proposals}/`,
    retrieve: (id) => `${root.proposals}/${id}/`,
    approve: (id) => `${root.proposals}/${id}/approve/`,
    cancel: (id) => `${root.proposals}/${id}/cancel/`,
    complete: (id) => `${root.proposals}/${id}/complete/`,
    archive: (id) => `${root.proposals}/${id}/archive/`,
  },
  proxySession: {
    list: `${root.proxySession}/`,
  },
  prices: {
    list: `${root.prices}/`,
    detail: (id) => `${root.prices}/${id}/`,
  },
  regions: {
    root: `${root.regions}/`,
    retrieve: (slug) => `${root.regions}/${slug}/`,
  },
  reviews: {
    list: `${root.reviews}/`,
    detail: (id: string) => `${root.reviews}/${id}/`,
    ratings: `${root.reviews}/ratings/`,
  },
  schedule: {
    list: root.schedules,
    openings: `${root.schedules}/openings/`,
    retrieve: (id: string) => `${root.schedules}/${id}/`,
    dates: (id: string) => `${root.schedules}/${id}/dates/`,
    times: (id: string) => `${root.schedules}/${id}/times/`,
    support: (id: string) => `${root.schedules}/${id}/support/`,
    weekly: (id: string) => `${root.schedules}/${id}/weekly/`,
  },
  termsOfService: {
    retrieve: (audience: UserType) => `${root.termsOfService}/${audience}/`,
  },
  users: {
    root: `${root.users}/`,
    activate: `${root.users}/activate/`,
    retrieve: (id) => `${root.users}/${id}/`,
    usernameAvailable: `${root.users}/username_available/`,
  },
  utils: {
    version: `${root.utils}/version/`,
    waitlist: `${root.utils}/waitlist/`,
  },
};

export default urls;
