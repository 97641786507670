import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APP_RELEASE } from 'config';

interface NetworkRetry {
  retryAttempts: number;
}

interface Reducer {
  newsFeedState: 'loading' | 'ready';
  release: string;
  networkRetry: number;
}

const initialState: Reducer = {
  newsFeedState: 'loading',
  release: APP_RELEASE,
  networkRetry: 0,
};

const name: 'app' = 'app';
const Slice = createSlice({
  name,
  initialState,
  reducers: {
    setRelease(state, action: PayloadAction<string>) {
      state.release = action.payload;
    },
    networkError(state, action: PayloadAction<NetworkRetry>) {
      state.networkRetry = action.payload.retryAttempts;
    },
    networkSuccess(state) {
      state.networkRetry = 0;
    },
    setNewsFeedLoaded(state) {
      state.newsFeedState = 'ready';
    },
  },
});
export const { setRelease, networkError, networkSuccess, setNewsFeedLoaded } =
  Slice.actions;

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
