import React from 'react';

interface Props {
  percent: number;
  color?: 'primary' | 'secondary' | 'gradient';
  reverse?: boolean;
}

const getShade = (color, percent) =>
  color === 'gradient'
    ? percent > 90
      ? 'bg-emerald-400'
      : percent > 70
      ? 'bg-lime-500'
      : percent > 50
      ? 'bg-amber-500'
      : 'bg-red-500'
    : color === 'primary'
    ? 'bg-blue-500'
    : color === 'secondary'
    ? 'bg-red-500'
    : 'bg-gray-500';

const Progress: React.FunctionComponent<Props> = ({
  percent,
  color,
  reverse,
}) => (
  <div className="relative block w-full h-1.5 rounded-sm">
    <span
      className="absolute left-0 z-10 h-1.5 rounded-sm transition-all duration-300 ease-in-out"
      style={{ width: Math.max(Math.min(percent, 100), 1) + '%' }}
    >
      <div
        className={`w-full h-full ${getShade(
          color,
          reverse ? 100 - percent : percent
        )}`}
      />
    </span>
    <span className="z-0 block w-full h-1.5 rounded-sm bg-slate-300" />
  </div>
);

Progress.defaultProps = {
  color: 'gradient',
  reverse: false,
};

export default Progress;
