import {
  FETCH_STATE,
  IS_SERVER,
  LOCAL_STORAGE_KEY,
  SESSION_STORAGE_KEY,
} from 'config';
import { watchSlice } from 'state/store/loadStore';

/**
 * Provides redux state object that merges prefetched SSR
 * data with initial state data.
 * @param sliceName name of redux state
 * @param initialState initial/default state
 * @returns initial state merged with data prefetched by SSR
 */
export function getInitialStateFromServer<T>(
  sliceName: string,
  initialState: T,
  reshapeData?: (data: T) => T
): T {
  if (IS_SERVER) {
    return initialState;
  }
  const json = window.__PRELOADED_STATE__;
  return mergeStates<T>(sliceName, initialState, json, reshapeData);
}

/**
 * Provides redux state object that merges localStorage
 * data with initial state data.
 * @param sliceName name of redux state
 * @param initialState initial/default state values
 * @returns initial state merged with data saved in the user's localStorage object
 */
export function getInitialStateFromStorage<T>(
  sliceName: string,
  initialState: T,
  reshapeData?: (data: T) => T
): T {
  if (IS_SERVER) {
    return initialState;
  }
  watchSlice(sliceName, 'local');
  const json = localStorage.getItem(LOCAL_STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
    : undefined;
  return mergeStates<T>(sliceName, initialState, json, reshapeData);
}

export function getInitialStateFromSession<T>(
  sliceName: string,
  initialState: T,
  reshapeData?: (data: T) => T
): T {
  if (IS_SERVER) {
    return initialState;
  }
  watchSlice(sliceName, 'session');
  const json = sessionStorage.getItem(SESSION_STORAGE_KEY)
    ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY))
    : undefined;
  return mergeStates<T>(sliceName, initialState, json, reshapeData);
}

function mergeStates<T>(
  stateKey: string,
  initialState: T,
  json: T,
  reshapeData: (data: T) => T
): T {
  const state = { ...initialState };
  if (json && json.hasOwnProperty(stateKey)) {
    const data = reshapeData ? reshapeData(json[stateKey]) : json[stateKey];
    Object.keys(initialState).map(
      (key) =>
        (state[key] = data.hasOwnProperty(key) ? data[key] : initialState[key])
    );
  }
  return state as T;
}

export function BLANK_API_DATA<T>(initialData: T = undefined) {
  return {
    data: initialData,
    fetchState: FETCH_STATE.PRISTINE,
    fetchedAt: undefined,
  };
}
