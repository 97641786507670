import { Event, TimeFrame } from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import Controls from 'components/controls';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect } from 'react';
import { processReportAction } from 'state/slice/assistance';
import { SHARED_ROUTES } from 'utils/routing';

const ClientNoShowModal = ({ appointment }: { appointment: Event }) => {
  const dispatch = useAppDispatch();

  if (appointment.timeFrame < TimeFrame.HasEnded) {
    return null;
  }

  const trackAction = (d) => dispatch(processReportAction(d));

  useEffect(() => {
    trackAction({
      type: 'SET_CATEGORY',
      key: 'category',
      value: 'CANCELLATION',
    });
    trackAction({
      type: 'SET_TYPE',
      key: 'type',
      value: 'CLIENT_CANCEL_REQUEST',
      title: 'What do you need help with?',
      description: `I was absent from this lesson`,
    });
    trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'CLIENT_CANCELLATION',
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'CLIENT_NO_SHOW',
      title: 'Thank you for letting us know',
      description: '',
    });
  }, []);

  return (
    <>
      <h4>Let's make sure we got that right</h4>
      <p>
        We appreciate you letting us know you were absent from this{' '}
        {appointment.activity.appointmentNoun.toLowerCase()}.
      </p>
      <Controls variant="block">
        <BackButton>Go back</BackButton>
        <Button
          color="primary"
          variant="contained"
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
        >
          Yes, I was absent
        </Button>
      </Controls>
    </>
  );
};

export default ClientNoShowModal;
