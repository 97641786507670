import Button from 'components/button';
import React from 'react';
import { SHOW_HELP } from 'utils/routing';

interface Question {
  text: string;
  link: () => void;
}

const ClientQuestions: Question[] = [
  { text: 'What ages and ability levels are taught?', link: SHOW_HELP.AGES },
  { text: 'How much do swim lessons cost?', link: SHOW_HELP.COST },
  {
    text: 'How and when can I book swimming lessons?',
    link: SHOW_HELP.REGISTER,
  },
  { text: 'Where can I learn more general information?', link: SHOW_HELP.ROOT },
];
const InstructorQuestions: Question[] = [
  {
    text: 'How does teaching with Propel work?',
    link: SHOW_HELP.TEACHING.HOW_IT_WORKS,
  },
  {
    text: 'Is there any minimum time commitment?',
    link: SHOW_HELP.TEACHING.MINIMUM_TIME_COMMITMENT,
  },
  {
    text: 'What about liability insurance?',
    link: SHOW_HELP.TEACHING.LIABILITY_INSURANCE,
  },
  { text: 'Where can I learn more general information?', link: SHOW_HELP.ROOT },
];

const CommonQuestions = ({
  type,
}: {
  type: 'client' | 'instructor';
}): JSX.Element => {
  const questions = type === 'client' ? ClientQuestions : InstructorQuestions;
  return (
    <section className="bg-gradient-to-tr from-cyan-500 to-blue-500">
      <div>
        <h2 className="!text-white title">Common Questions</h2>
        <div className="space-y-6">
          {questions.map((elt: Question, i) => (
            <a
              key={`q-${i}`}
              className="block text-white text-md link hover:text-white"
              onClick={elt.link}
            >
              {elt.text}
            </a>
          ))}
          <div className="text-center">
            <Button onClick={SHOW_HELP.CONTACT} variant="contained">
              Contact Propel
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonQuestions;
