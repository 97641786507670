import {
  AccountUpdateSerializer,
  Segment,
} from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import ButtonLarge, { Toggle } from 'components/button-large';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { InstructorOnboardingRoutes } from 'utils/routing';
import {
  BaseStepProps,
  ControlClasses,
  QuestionClasses,
  TextClasses,
  TitleClasses,
} from '..';

const debouncedSave = debounce(
  (
    modifyAccount: (data: Partial<AccountUpdateSerializer>) => void,
    data: Partial<AccountUpdateSerializer>
  ) => {
    modifyAccount(data);
  },
  500
);

interface Props extends BaseStepProps {
  hideControls?: boolean;
}

const ClientExperience: React.FC<Props> = (props) => {
  const [init, setInit] = useState(false);
  const [selected, setSelected] = useState(props.account.segments);

  const selectSegment = (seg) => () => {
    setSelected(
      selected.indexOf(seg) === -1
        ? [...selected].concat([seg])
        : [...selected].filter((s) => s !== seg)
    );
  };

  const selectAll = () => {
    setSelected(
      selected.length === 4
        ? []
        : [
            Segment.Toddlers,
            Segment.Children,
            Segment.AdultsTeens,
            Segment.Adapted,
          ]
    );
  };

  useEffect(() => {
    if (init) {
      debouncedSave(props.modifyAccount, { segments: selected });
    } else {
      setInit(true);
    }
  }, [selected]);

  return (
    <div>
      <h1 className={TitleClasses}>Tell us about your skills</h1>
      <p className={TextClasses}>
        Clients like to know they’re choosing an instructor with the right
        experience.
      </p>
      <p className={QuestionClasses}>
        Who are you comfortable teaching on your own?
      </p>
      <div className="space-y-3">
        <ButtonLarge
          variant="toggle"
          title="Toddlers"
          subtitle="Ages 2 or 3"
          selected={selected.indexOf(Segment.Toddlers) !== -1}
          onClick={selectSegment(Segment.Toddlers)}
        />
        <ButtonLarge
          variant="toggle"
          title="Children"
          subtitle="Ages 4 to 12"
          selected={selected.indexOf(Segment.Children) !== -1}
          onClick={selectSegment(Segment.Children)}
        />
        <ButtonLarge
          variant="toggle"
          title="Adults & Teens"
          subtitle="Anyone over 13"
          selected={selected.indexOf(Segment.AdultsTeens) !== -1}
          onClick={selectSegment(Segment.AdultsTeens)}
        />
        <ButtonLarge
          variant="toggle"
          title="Adapted Aquatics"
          subtitle="Adapted & behavioral intervention"
          selected={selected.indexOf(Segment.Adapted) !== -1}
          onClick={selectSegment(Segment.Adapted)}
        />
      </div>
      <div className="flex justify-end px-4 py-2">
        <button className="flex items-center text-sm" onClick={selectAll}>
          All <Toggle selected={selected.length === 4} />
        </button>
      </div>
      {!props.hideControls && (
        <div className={ControlClasses}>
          <Button
            color="primary"
            variant="contained"
            to={InstructorOnboardingRoutes.S10}
            disabled={selected.length === 0}
          >
            Save and Continue
          </Button>
        </div>
      )}
    </div>
  );
};

export default ClientExperience;
