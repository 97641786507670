import {
  ContentObjectSerializer,
  ValidContentType,
} from 'api/Serializers/Favourites';
import Button from 'components/button';
import Controls from 'components/controls';
import Modal from 'components/modal';
import { UserType } from 'config';
import AuthenticationModal from 'containers/authentication';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FavoriteIcon, FavoriteOnIcon } from 'icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getFavouritesList,
  getFavouritesLoaded,
  getUserType,
} from 'state/selectors';
import { addFavourite, removeFavourite } from 'state/slice/favourites';

const RemoveFavouriteModal = ({
  open,
  onClose,
  contentType,
  contentObject,
}: {
  open: boolean;
  onClose: (removeFavourite: boolean) => void;
  contentType: ValidContentType;
  contentObject: any;
}): JSX.Element => {
  return (
    <Modal
      name="Client — Remove Favourites"
      open={open}
      onClose={() => onClose(false)}
      maxWidth="xs"
      fullWidth={true}
      title="Remove"
    >
      <p>
        Are you sure you want to remove {contentObject.displayName} from your
        favourites?
      </p>
      <Controls>
        <Button variant="flat" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onClose(true)}
        >
          Yes, Remove
        </Button>
      </Controls>
    </Modal>
  );
};

const FavouriteButton = ({
  contentType,
  contentObject,
  variant = 'full',
  size = 'medium',
}: {
  contentType: ValidContentType;
  contentObject: ContentObjectSerializer;
  size?: 'small' | 'medium';
  variant?: 'full' | 'icon';
}) => {
  const userType = useSelector(getUserType);
  const dispatch = useAppDispatch();
  const favourites = useSelector(getFavouritesList);
  const favouritesLoaded = useSelector(getFavouritesLoaded);
  const [showRemoveFavouriteModal, setShowRemoveFavouriteModal] =
    useState<boolean>(false);
  const [showAnonymousModal, setShowAnonymousModal] = useState<boolean>(false);

  if (userType !== UserType.Anonymous && userType !== UserType.Client) {
    return null;
  }

  const isFavourited = favourites.some(
    (obj) =>
      obj.contentObject.id === contentObject.id &&
      obj.contentType === contentType &&
      !obj.isRemoving
  );
  const Icon = !isFavourited ? FavoriteIcon : FavoriteOnIcon;
  const classes = `shrink-0${cls(
    userType === UserType.Client &&
      !favouritesLoaded &&
      'pointer-events-none opacity-50'
  )} ${isFavourited ? 'text-red-600 hover:text-red-600' : 'text-gray-600'}`;

  const handleClick = async (e) => {
    e.preventDefault();
    if (userType === UserType.Anonymous) {
      setShowAnonymousModal(true);
    } else {
      if (!isFavourited) {
        dispatch(
          addFavourite({
            contentType,
            contentObject,
          })
        );
        rudderanalytics.track(`${contentType.capitalize()} favourited`, {
          id: contentObject.id,
          displayName: contentObject.displayName,
        });
      } else {
        setShowRemoveFavouriteModal(true);
      }
    }
  };

  const handleRemove = (confirmRemove: boolean) => {
    setShowRemoveFavouriteModal(false);
    if (confirmRemove) {
      dispatch(
        removeFavourite({
          contentType,
          contentObject,
        })
      );
      rudderanalytics.track(`${contentType.capitalize()} unfavourited`, {
        id: contentObject.id,
        displayName: contentObject.displayName,
      });
    }
  };

  return (
    <>
      {variant === 'full' ? (
        <Button
          className={classes}
          variant={isFavourited ? 'flat' : 'outlined'}
          icon={<Icon width={24} />}
          onClick={handleClick}
        >
          {!isFavourited ? 'Favourite' : 'Favourited'}
        </Button>
      ) : (
        <button
          className={`btn-icon gray ${classes} border border-gray-300`}
          onClick={handleClick}
        >
          <Icon
            className={isFavourited ? 'text-red-600' : 'text-gray-600'}
            width={24}
          />
        </button>
      )}
      {userType === UserType.Anonymous ? (
        <AuthenticationModal
          flow="FavouritesFlow"
          initialForm="login"
          isOpen={showAnonymousModal}
          onClose={() => setShowAnonymousModal(false)}
          defaultUserType={UserType.Client}
        />
      ) : (
        <RemoveFavouriteModal
          open={showRemoveFavouriteModal}
          onClose={handleRemove}
          contentType={contentType}
          contentObject={contentObject}
        />
      )}
    </>
  );
};

export default FavouriteButton;
