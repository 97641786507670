import DeleteIcon from '@mui/icons-material/Delete';
import Uploader from 'components/uploader';
import { AssistanceWizardProps } from 'models/Assistance';
import React from 'react';

export const MAX_IMAGES = 4;

const Images = ({
  label,
  state,
  trackAction,
  disabled = false,
  readonly = false,
  ...rest
}: AssistanceWizardProps & {
  label: string;
  readonly?: boolean;
  disabled?: boolean;
}) => {
  const images = state.images || [];
  const handleRemove = (removeSrc: string) => (e) => {
    trackAction({
      type: 'SET_IMAGES',
      key: 'images',
      value: [...images.filter(({ url }) => url !== removeSrc)] as any,
    });
  };
  const handleUpload = (url: string) => {
    trackAction({
      type: 'SET_IMAGES',
      key: 'images',
      value: [...images, { url, imgServerUri: url }] as any,
    });
  };
  return (
    <div className="space-y-4">
      <label htmlFor="image-uploader">{label}</label>
      {!readonly && (
        <div>
          <Uploader
            name="image-uploader"
            icon={false}
            title="Attach Photos"
            onFinish={handleUpload}
            disabled={disabled || images.length >= MAX_IMAGES}
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-4">
        {images.map(({ id, url, ...rest }, index) => (
          <div key={`img-${index}`} className="w-full h-40 group">
            <div className="relative w-full pt-40 overflow-hidden rounded-lg">
              {!readonly && (
                <div className="absolute top-0.5 right-0.5 z-20 opacity-50 group-hover:opacity-100 transition-opacity duration-150">
                  <button className="btn gray" onClick={handleRemove(url)}>
                    <DeleteIcon />
                  </button>
                </div>
              )}
              <img
                src={url}
                className="absolute z-10 block object-cover w-full min-w-full min-h-full -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
              />
            </div>
          </div>
        ))}
      </div>
      <p className="text-xs italic text-center text-gray-600">
        Photos are reviewed by Propel as necessary
      </p>
    </div>
  );
};

export default Images;
