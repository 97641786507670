import CreditIcon from '@mui/icons-material/AttachMoney';
import InstructorIcon from '@mui/icons-material/EventBusy';
import NoCreditIcon from '@mui/icons-material/MoneyOff';
import { CancelSerializer, TimeFrame } from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Select from 'components/select';
import { UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AssistanceWizardProps } from 'models/Assistance';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cancelAppointment } from 'state/slice/appointments';
import { SHARED_ROUTES } from 'utils/routing';

const senderOptions = [
  {
    label: 'Instructor',
    value: UserType.Instructor,
  },
  {
    label: 'Propel',
    value: UserType.Admin,
  },
];

const Index = ({ appointment, onClickNext, onClickBack }) => {
  return (
    <>
      {appointment.timeFrame > TimeFrame.HasStarted ? (
        <h2>Instructor No-Show</h2>
      ) : (
        <h2>Instructor Scheduling Issues</h2>
      )}
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="Start instructor cancellation"
          subtitle="Cancel due to personal reasons or schedule conflicts"
          icon={<InstructorIcon />}
          onClick={onClickNext}
        />
      </div>
      <Controls variant="block">
        <BackButton onClick={onClickBack} />
      </Controls>
    </>
  );
};

const Note = ({
  appointment,
  note,
  setNote,
  sender,
  setSender,
  onClickNext,
  onClickBack,
}) => {
  return (
    <>
      <h2>Cancellation Assistance</h2>
      <p>
        The client will receive email and SMS notifications that the instructor
        is unable to attend this lesson, along with the note below, which will
        be sent from either Propel or the instructor.
      </p>
      <div className="my-8 space-y-6">
        <div>
          <label htmlFor="sender">From:</label>
          <Select
            options={senderOptions}
            value={
              sender ? senderOptions.find((opt) => opt.value === sender) : null
            }
            onChange={(opt) => setSender(opt.value)}
          />
        </div>
        <div>
          <label htmlFor="noteToClient">{`To: ${appointment.client.fullName}`}</label>
          <textarea
            id="noteToClient"
            name="noteToClient"
            placeholder="I'm sorry to cancel your booking..."
            defaultValue={note}
            maxLength={1000}
            onChange={(evt) => setNote(evt.target.value)}
            rows={3}
          />
        </div>
      </div>
      <Controls variant="block">
        <BackButton onClick={onClickBack} />
        <Button variant="contained" color="primary" onClick={onClickNext}>
          Continue
        </Button>
      </Controls>
    </>
  );
};

const Credit = ({
  appointment,
  credit,
  setCredit,
  sender,
  setSender,
  onClickNext,
  onClickBack,
}) => {
  const handleCredit = (value: number) => (event) => {
    setCredit(value);
    onClickNext();
  };
  return (
    <>
      <h2>Cancellation Assistance</h2>
      <Callout className="my-8" type="question">
        <div>Provide additional courtesy credit?</div>
      </Callout>
      <div className="my-8 space-y-6">
        <div>
          <label htmlFor="sender">Who will compensate?</label>
          <Select
            options={senderOptions}
            value={
              sender ? senderOptions.find((opt) => opt.value === sender) : null
            }
            onChange={(opt) => setSender(opt.value)}
          />
        </div>
        <div className="space-y-3">
          <label htmlFor="sender">Select the amount</label>
          <ButtonLarge
            title="Nothing, only return their lesson fee"
            icon={<NoCreditIcon className="text-gray-600" color="inherit" />}
            onClick={handleCredit(0)}
            selected={credit === 0}
          />
          <ButtonLarge
            title="Small lesson credit of $10"
            icon={<CreditIcon className="text-gray-600" color="inherit" />}
            onClick={handleCredit(10)}
            selected={credit === 10}
            disabled={!sender}
          />
          <ButtonLarge
            title="Bigger lesson credit of $20"
            icon={<CreditIcon className="text-gray-600" color="inherit" />}
            onClick={handleCredit(20)}
            selected={credit === 20}
            disabled={!sender}
          />
          <ButtonLarge
            title={`1/2 lesson credit of $${Math.ceil(
              Number(appointment.value) / 2
            ).toFixed(0)}`}
            icon={<CreditIcon className="text-gray-600" color="inherit" />}
            onClick={handleCredit(Number(appointment.value) / 2)}
            selected={credit === Number(appointment.value) / 2}
            disabled={!sender}
          />
          <ButtonLarge
            title={`Full lesson credit of $${Number(appointment.value).toFixed(
              0
            )}`}
            icon={<CreditIcon className="text-gray-600" color="inherit" />}
            onClick={handleCredit(Number(appointment.value))}
            selected={credit === Number(appointment.value)}
            disabled={!sender}
          />
        </div>
      </div>
      <Controls variant="block">
        <BackButton onClick={onClickBack}>Go back</BackButton>
      </Controls>
    </>
  );
};

const Confirm = ({
  appointment,
  credit,
  note,
  setStep,
  submitting,
  onClickNext,
  onClickBack,
}) => {
  return (
    <>
      <h2>Cancellation Assistance</h2>
      <div className="my-8 space-y-4">
        <Callout title="Ready to submit?" type="question">
          Here are the details of the cancellation.
        </Callout>
        <div>
          <h4 className="m-0 text-sm font-bold text-gray-700">
            {`To: ${appointment.client.fullName}`}
          </h4>
          <h4 className="m-0 text-sm font-bold text-gray-700">
            {`From: ${appointment.instructor.fullName}`}
          </h4>
          <div className="p-4 text-sm text-gray-900 break-words whitespace-pre-wrap bg-gray-200 rounded-lg">
            {note}
          </div>
        </div>
        <hr />
        <div>
          <h4 className="m-0 text-sm font-bold text-gray-700">
            Courtesy Credit
          </h4>
          <div className="text-sm text-gray-900 break-words whitespace-pre-wrap">
            {credit === 0
              ? 'No Courtesy Credit'
              : `${credit.toCurrency()} Courtesy Credit`}
          </div>
        </div>
        <hr />
        <div>
          <h4 className="m-0 text-sm font-bold text-gray-700">Payment</h4>
          <div className="text-sm text-gray-900 break-words whitespace-pre-wrap">
            You will not be paid for this lesson. Your client's payment will be
            automatically returned to the original payment method.
          </div>
        </div>
      </div>
      <Controls variant="block">
        <BackButton disabled={submitting} onClick={onClickBack}>
          Go back
        </BackButton>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={onClickNext}
          disabled={submitting}
        >
          Cancel appointment
        </Button>
      </Controls>
    </>
  );
};

type Step = 'index' | 'note' | 'credit' | 'confirm';
const InstructorCancellationView = (props: AssistanceWizardProps) => {
  const { appointment, state, trackAction } = props;
  const [note, setNote] = useState('');
  const [credit, setCredit] = useState(0);
  const [creditSender, setCreditSender] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [noteSender, setNoteSender] = useState();
  const [step, setStep] = useState<Step>('index');
  const history = useHistory();
  const dispatch = useAppDispatch();
  useEffect(() => {
    props.trackAction({
      type: 'SET_CATEGORY',
      key: 'category',
      value: 'INSTRUCTOR',
      title: 'What do you need help with?',
      description: 'Instructor issues',
    });
  }, []);
  const handleSubmit = async () => {
    const data: CancelSerializer = {
      reason:
        appointment.timeFrame >= TimeFrame.Within2
          ? 'InstructorAbsent'
          : 'InstructorCancelled',
      instructorCourtesyCredit:
        creditSender === UserType.Instructor ? credit : undefined,
      propelCourtesyCredit:
        creditSender === UserType.Admin ? credit : undefined,
      notes: [
        {
          recipient: UserType.Client,
          sender: noteSender,
          text: note,
        },
      ],
    };
    setSubmitting(true);
    if (await dispatch(cancelAppointment(appointment, data))) {
      history.push(SHARED_ROUTES.SCHEDULE.appointment(appointment.id));
    }
    setSubmitting(false);
  };
  const handleNext = () => {
    if (step === 'index') {
      setStep('note');
    } else if (step === 'note') {
      setStep('credit');
    } else if (step === 'credit') {
      setStep('confirm');
    } else {
      handleSubmit();
    }
  };
  const handleBack = () => {
    if (step === 'note') {
      setStep('index');
    } else if (step === 'credit') {
      setStep('note');
    } else if (step === 'confirm') {
      setStep('credit');
    } else {
      history.goBack();
    }
  };

  return (
    <>
      {step === 'index' ? (
        <Index
          appointment={appointment}
          onClickNext={handleNext}
          onClickBack={handleBack}
        />
      ) : step === 'note' ? (
        <Note
          appointment={appointment}
          note={note}
          setNote={setNote}
          sender={noteSender}
          setSender={setNoteSender}
          onClickNext={handleNext}
          onClickBack={handleBack}
        />
      ) : step === 'credit' ? (
        <Credit
          appointment={appointment}
          credit={credit}
          setCredit={setCredit}
          sender={creditSender}
          setSender={setCreditSender}
          onClickNext={handleNext}
          onClickBack={handleBack}
        />
      ) : step === 'confirm' ? (
        <Confirm
          submitting={submitting}
          appointment={appointment}
          credit={credit}
          note={note}
          setStep={setStep}
          onClickNext={handleNext}
          onClickBack={handleBack}
        />
      ) : null}
    </>
  );
};

export default InstructorCancellationView;
