import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { FETCH_STATE } from 'config';
import ReportSummary from 'features/assistance/components/report-summary';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { GenericServerError } from 'lang/en/Snackbars';
import { AssistanceWizardProps } from 'models/Assistance';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchAppointments } from 'state/slice/appointments';
import { submitReport } from 'state/slice/assistance';
import { SHARED_ROUTES } from 'utils/routing';

const AssistanceSummary = (props: AssistanceWizardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [submitState, setSubmitState] = useState(FETCH_STATE.IDLE);
  const history = useHistory();
  const { appointment, state } = props;
  const dispatch = useAppDispatch();
  const onSuccess = () => {
    dispatch(fetchAppointments());
    enqueueSnackbar('Report submitted', { variant: 'success' });
    history.push(SHARED_ROUTES.SCHEDULE.appointment(appointment.id));
  };
  const onError = () => {
    enqueueSnackbar(GenericServerError);
    setSubmitState(FETCH_STATE.FAILED);
  };
  const handleSubmit = async () => {
    setSubmitState(FETCH_STATE.POST);
    dispatch(submitReport(onSuccess, onError));
  };

  return (
    <>
      <h2 className="mb-1">Review & Submit</h2>
      <Callout type="question">
        Here's the final report. Ready to submit?
      </Callout>
      <ReportSummary report={state} appointment={appointment} />
      {/* <p className="italic font-light text-center text-gray-600">
        A copy of this report will be sent to your email
      </p> */}
      <Controls variant="block">
        <BackButton />
        <Button
          isLoading={submitState === FETCH_STATE.POST}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </Controls>
    </>
  );
};

export default AssistanceSummary;
