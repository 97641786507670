import EditIcon from '@mui/icons-material/Edit';
import PhoneIcon from '@mui/icons-material/Phone';
import { PropelAccount } from 'api/Serializers/Accounts';
import { Event } from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Loading from 'components/loading';
import { DATE_FMT, QueryParams, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import { useSelector } from 'react-redux';
import {
  getAccountDetail,
  getCurrentUser,
  getProxySession,
} from 'state/selectors';
import {
  fetchProxySession,
  ProxySession,
  setProxySession,
  startProxySession,
} from 'state/slice/assistance';
import { LocalStore } from 'state/storage';

const ExistingSession = ({
  state,
  setState,
  onSubmit,
  onCancel,
  goBack,
}: {
  state: ProxySession;
  setState: (obj: ProxySession) => void;
  onCancel;
  onSubmit(userProxyPhone: string): void;
  goBack(): void;
}): JSX.Element => {
  return (
    <div className="space-y-6">
      <Callout type="question" title="Confirm Number">
        Are you still calling from:
        <br />
        <strong>{formatPhoneNumber(state.userPhone)}</strong>
      </Callout>
      <div className="space-y-3">
        <ButtonLarge
          onClick={() => onSubmit(state.userProxyPhone)}
          title={`Yes, call the ${state.recipient} now`}
          subtitle="Try calling again in case they were busy"
          icon={<PhoneIcon color="action" />}
        />
        <ButtonLarge
          onClick={onCancel}
          title="Modify phone number"
          subtitle={`If you're not calling from ${formatPhoneNumber(
            state.userPhone
          )}`}
          icon={<EditIcon color="action" />}
        />
      </div>
      <Controls variant="block">
        <BackButton onClick={goBack} />
      </Controls>
    </div>
  );
};

const ConfirmPhone = ({
  appointment,
  state,
  setState,
  goBack,
}: {
  appointment: Event;
  state: ProxySession;
  setState: (obj: ProxySession) => void;
  goBack(): void;
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [isError, setError] = useState(false);
  const dispatch = useAppDispatch();
  const query = useQuery();
  query.set(QueryParams.Step, 'call');
  const handleNext = () => {
    if (!isValidPhoneNumber(state.userPhone)) {
      setError(true);
      enqueueSnackbar({
        message: 'Please enter a valid phone number.',
        variant: 'error',
      });
    } else {
      setError(false);
      setState({ ...state, step: 'call' });
    }
  };
  return (
    <div className="space-y-6">
      <div>
        <p>
          To maintain privacy, an anonymized phone number will be setup to
          connect you with your {state.recipient}. This feature can only be used
          during your {appointment.activity.appointmentNoun.toLowerCase()} and
          must be started with this dialog.
        </p>
        <p>To begin, please confirm the number you will be calling from.</p>
      </div>
      <Callout className="hidden mb-8 sm:flex" type="warning">
        This feature will only work from a device capable of sending and
        receiving phone calls
      </Callout>
      <div>
        <label htmlFor="userPhone">I will call from phone number:</label>
        <PhoneInput
          name="userPhone"
          disabled={state.existingSession}
          value={state.userPhone}
          onChange={(value) =>
            setState({
              ...state,
              userPhone: value,
            })
          }
          country="CA"
          placeholder="(604) 555-0938"
        />
      </div>
      {isError && <Callout type="error">Phone number is invalid</Callout>}
      <Controls variant="block">
        <BackButton onClick={goBack} />
        <Button
          onClick={handleNext}
          color="primary"
          variant="flat"
          disabled={!state.userPhone}
        >
          Continue
        </Button>
      </Controls>
    </div>
  );
};

const PlaceCall = ({
  appointment,
  state,
  setState,
  onSubmit,
}: {
  appointment: Event;
  state: ProxySession;
  setState: (obj: ProxySession) => void;
  onSubmit(userProxyPhone: string): void;
}): JSX.Element => {
  const account = useSelector(getAccountDetail);
  const [isCreatingProxy, setCreatingProxy] = useState(false);
  const dispatch = useAppDispatch();

  const handleBack = () => {
    setState({
      ...state,
      step: 'confirm',
    });
  };

  const getClientProxyPhone = (evt) => {
    setCreatingProxy(true);
    dispatch(startProxySession(appointment, onSubmit));
  };

  return (
    <div className="space-y-6">
      <Callout type="success" title="Number Confirmed">
        You're about to call your {state.recipient} from:
        <br />
        <strong>{formatPhoneNumber(state.userPhone)}</strong>
      </Callout>
      <p>
        They may not answer right away, so please be patient and try calling
        more than once if they do not answer.
      </p>
      {state.recipient === UserType.Client ? (
        <p>
          Start your call to {appointment.client.firstName} with a friendly
          <br />
          "Hello I'm {account.firstName}, your Propel{' '}
          {appointment.activity.instructorDescription.toLowerCase()}!"
        </p>
      ) : (
        <p>
          Start your call with a friendly "Hello I'm {account.firstName}
          {', '}your {account.type.toLowerCase()} for today's{' '}
          {appointment.activity.appointmentNoun.toLowerCase()}
          !"
        </p>
      )}
      <Controls variant="block">
        <BackButton onClick={handleBack} disabled={isCreatingProxy} />
        <Button
          icon={<PhoneIcon />}
          onClick={getClientProxyPhone}
          color="primary"
          variant="flat"
          isLoading={isCreatingProxy}
        >
          Call{' '}
          {state.recipient === UserType.Client
            ? appointment.client.firstName
            : 'Now'}
        </Button>
      </Controls>
    </div>
  );
};

const PostCall = ({
  state,
  PostCallView,
  ...rest
}: {
  appointment: Event;
  state: ProxySession;
  setState: (obj: ProxySession) => void;
  PostCallView({ dialThroughState }): JSX.Element;
}): JSX.Element => {
  return (
    <div className="space-y-6">
      <Callout type="question" title="How did that go?">
        <span>Were you able to reach your {state.recipient}?</span>
      </Callout>
      {<PostCallView dialThroughState={state} />}
    </div>
  );
};

const Error = ({
  appointment,
  state,
  setState,
  onClose,
}: {
  appointment: Event;
  state: ProxySession;
  setState: (obj: ProxySession) => void;
  onClose(): void;
}): JSX.Element => {
  const handleClose = () => {
    LocalStore.set('assistanceClosed', moment().format(DATE_FMT.DATE_KEY));
    onClose();
  };
  if (!state.error) {
    setState({ ...state, step: 'call' });
  }
  return (
    <div className="mb-8">
      <Callout type="error" title="Error Encountered">
        {state.error === 'creation_error' ? (
          <span>
            An error was encountered setting up the call service. If you still
            need assistance, please connect with Propel Support using the
            buttons below.
          </span>
        ) : state.error === 'permission_error' ? (
          <span>
            Call service is unavailable at this time. It may only be initiated
            during your {appointment.activity.appointmentNoun.toLowerCase()}{' '}
            time.
          </span>
        ) : null}
      </Callout>
      <Controls variant="block">
        <BackButton />
        {/* Show support number if we're 10 min past start time or there was an error getting the proxy phone number */}
        {moment().isBetween(
          moment(appointment.start).add(10, 'minutes'),
          moment(appointment.end).subtract(5, 'minutes')
        ) && (
          <Button
            className="w-full"
            icon={<PhoneIcon />}
            variant="flat"
            color="secondary"
            to={'tel:+18339776735'}
          >
            Call Propel Support
          </Button>
        )}
      </Controls>
    </div>
  );
};

const isWithValid = (user: PropelAccount, param: UserType) => {
  if (Object.values(UserType).indexOf(param) === -1) {
    return false;
  } else if (user.type === UserType.Admin) {
    return true;
  } else if (user.type === UserType.Client) {
    return param === UserType.Instructor;
  } else if (user.type === UserType.Instructor) {
    return param !== UserType.Instructor;
  } else {
    return false;
  }
};

export interface PostCallView {
  dialThroughState: ProxySession;
}

const DialThrough = ({
  appointment,
  recipient,
  onClose,
  onCallPlaced = null,
  PostCallView = null,
}: {
  appointment: Event;
  recipient: UserType;
  onClose(): void;
  onCallPlaced?(): void;
  PostCallView?(props: PostCallView): JSX.Element;
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser) as PropelAccount;
  const state = useSelector(getProxySession);
  const setState = (obj) => dispatch(setProxySession(obj));

  const handleCancel = async () => {
    setState({
      ...state,
      userProxyPhone: '',
      existingSession: false,
    });
  };

  const handleProxyCall = (userProxyPhone: string) => {
    if (!userProxyPhone) {
      setState({ ...state, step: 'confirm' });
      return;
    }
    window.location.href = `tel:${userProxyPhone}`;
    if (typeof onCallPlaced === 'function') {
      onCallPlaced();
    }
    setTimeout(
      () => setState({ ...state, userProxyPhone, step: 'complete' }),
      1000
    );
  };

  useEffect(() => {
    dispatch(fetchProxySession(currentUser, appointment, recipient));
    return () => {
      handleCancel();
    };
  }, []);

  if (!isWithValid(currentUser, recipient)) {
    onClose();
  } else if (!state.loaded) {
    return <Loading message="Loading call service..." />;
  }
  switch (state.step) {
    case 'confirm':
      if (state.existingSession) {
        return (
          <ExistingSession
            state={state}
            setState={setState}
            onCancel={handleCancel}
            onSubmit={handleProxyCall}
            goBack={onClose}
          />
        );
      }
      return (
        <ConfirmPhone
          appointment={appointment}
          state={state}
          setState={setState}
          goBack={onClose}
        />
      );
    case 'call':
      return (
        <PlaceCall
          state={state}
          setState={setState}
          appointment={appointment}
          onSubmit={handleProxyCall}
        />
      );
    case 'complete':
      return (
        <PostCall
          state={state}
          setState={setState}
          appointment={appointment}
          PostCallView={PostCallView}
        />
      );
    case 'error':
      return (
        <Error
          state={state}
          setState={setState}
          appointment={appointment}
          onClose={onClose}
        />
      );
    default:
      return (
        <ConfirmPhone
          appointment={appointment}
          state={state}
          setState={setState}
          goBack={onClose}
        />
      );
  }
};

export default DialThrough;
