import { AxiosResponse } from 'axios';
import { SchedulableObject } from '../Schedules';

export interface VerifiedProposal {
  id: string;
  verifiedAppointments: VerifiedAppointment[];
}

export interface VerifiedAppointment {
  id: string;
  isValid: boolean;
  reason: string;
}

export enum ProposalStatus {
  AwaitingClient = 'AWAITING_CLIENT',
  InstructorCancelled = 'INSTRUCTOR_CANCELLED',
  ClientCancelled = 'CLIENT_CANCELLED',
  Expired = 'EXPIRED',
  BookingInProgress = 'BOOKING_IN_PROGRESS',
  BookingSuccessful = 'BOOKING_SUCCESSFUL',
}

export interface ProposalListItem extends SchedulableObject {
  expiry: string;
  status: ProposalStatus;
}
export interface Proposal extends ProposalListItem {
  availabilityId: string;
  appointmentProductId: string;
}

export interface NewProposal {
  id?: string;
  aptProdId: string;
  client: number;
  numParticipants: number;
  datetime: string;
  date: string;
}

export interface ProposalListParams {
  username?: string;
  start?: string;
  end?: string;
}

export interface Api {
  list: (
    params?: ProposalListParams,
    abortController?: AbortController
  ) => Promise<AxiosResponse<ProposalListItem[]>>;
  retrieve: (id: string) => Promise<AxiosResponse<Proposal>>;
  create: (data: NewProposal[]) => Promise<AxiosResponse<ProposalListItem[]>>;
  cancel: (id: string) => Promise<AxiosResponse<Proposal>>;
  archive: (id: string) => Promise<AxiosResponse<Proposal>>;
}
