import Hidden from '@mui/material/Hidden';
import Button from 'components/button';
import Calendar from 'components/calendar';
import CalendarControls from 'components/calendar-controls';
import Link from 'components/link';
import Modal from 'components/modal';
import { DATE_FMT, UserType } from 'config';
import ReadMore from 'containers/read-more';
import { EditIcon, ProposalIcon, ScheduleIcon } from 'icons';
import { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import { getUserType } from 'state/selectors';
import { SHARED_ROUTES } from 'utils/routing';
import {
  AvailabilityCalendarLegend,
  BookingsCalendarLegend,
  DayLoadingComponent,
  ProposalCalendarLegend,
} from '../calendar-date-box';

type Tabs = 'bookings' | 'availability' | 'proposals';

export const ScheduleLoading = () => {
  return (
    <>
      {[...Array(3).keys()].map((i) => (
        <div key={`loader${i}`} className="px-2 py-px mx-auto sm:px-6">
          <div
            className={`py-6 px-card mx-auto space-y-6 shadow shadow-slate-300/30 bg-white rounded-2xl max-w-lg animate-pulse`}
          >
            <div className="space-y-2">
              <div className="w-56 bg-blue-300 rounded-full h-7" />
              <div className="h-5 bg-blue-300 rounded-full w-28" />
            </div>
            {[0, 1, 2].map((i, k) => (
              <div
                key={`load-${k}`}
                className={`flex items-center justify-start my-2`}
              >
                <div className="w-12 px-3 py-2 mr-1 bg-blue-300 rounded-md" />
                <div className="flex-1 w-12 h-6 px-3 py-2 bg-blue-300 rounded-md" />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

const DesktopTabs = ({ mode }: { mode: Tabs }) => {
  const userType = useSelector(getUserType);
  if (userType === UserType.Admin) {
    return null;
  }
  return (
    <div className="flex pt-1 mx-6 space-x-2 text-sm text-gray-800 lg:bg-background rounded-t-xl">
      {mode === 'bookings' ? (
        <div className="flex flex-1 pb-2 bg-white rounded-t-xl group">
          <div className="absolute w-4 h-full -left-4">
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-white" />
            <div className="absolute top-0 bottom-0 left-0 right-0 lg:bg-background rounded-br-2xl" />
          </div>
          <span className="flex items-center flex-1 gap-2 px-2.5 py-2 text-left">
            <ScheduleIcon width={18} /> Bookings
          </span>
          <div className="absolute w-4 h-full -right-4">
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-white" />
            <div className="absolute top-0 bottom-0 left-0 right-0 lg:bg-background rounded-bl-2xl" />
          </div>
        </div>
      ) : (
        <Link
          to={SHARED_ROUTES.SCHEDULE.ROOT}
          className="z-10 flex flex-1 pb-2 group"
        >
          <span className="flex items-center flex-1 gap-2 px-2.5 py-2 text-left transition-colors duration-300 bg-slate-200/50 rounded-xl group-hover:bg-slate-200">
            <ScheduleIcon width={18} /> Bookings
          </span>
        </Link>
      )}

      {mode === 'availability' ? (
        <div className="flex flex-1 pb-2 bg-white rounded-t-xl group">
          <div className="absolute w-4 h-full -left-4">
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-white" />
            <div className="absolute top-0 bottom-0 left-0 right-0 lg:bg-background rounded-br-2xl" />
          </div>
          <span className="flex items-center flex-1 gap-2 px-2.5 py-2 text-left">
            <EditIcon width={18} /> Availability
          </span>
          <div className="absolute w-4 h-full -right-4">
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-white" />
            <div className="absolute top-0 bottom-0 left-0 right-0 lg:bg-background rounded-bl-2xl" />
          </div>
        </div>
      ) : (
        <Link
          to={SHARED_ROUTES.SCHEDULE.availability}
          className="z-10 flex flex-1 pb-2 group"
        >
          <span className="flex items-center flex-1 gap-2 px-2.5 py-2 text-left transition-colors duration-300 bg-slate-200/50 rounded-xl group-hover:bg-slate-200">
            <EditIcon width={18} /> Availability
          </span>
        </Link>
      )}
      {mode === 'proposals' ? (
        <div className="flex flex-1 pb-2 bg-white rounded-t-xl group">
          <div className="absolute w-4 h-full -left-4">
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-white" />
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-background rounded-br-2xl" />
          </div>
          <span className="flex items-center flex-1 gap-2 px-2.5 py-2 text-left">
            <ProposalIcon width={18} /> Proposal
          </span>
          <div className="absolute w-4 h-full -right-4">
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-white" />
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-background rounded-bl-2xl" />
          </div>
        </div>
      ) : (
        <Link
          to={SHARED_ROUTES.SCHEDULE.proposals}
          className="z-10 flex flex-1 pb-2 group"
        >
          <span className="flex items-center flex-1 gap-2 px-2.5 py-2 text-left transition-colors duration-300 bg-slate-200/50 rounded-xl group-hover:bg-slate-200">
            <ProposalIcon width={18} /> Proposal
          </span>
        </Link>
      )}
    </div>
  );
};

const MobileTabs = ({
  mode,
  showCalendar,
  onCalendarClick,
  onCalendarClose,
  Menu,
}: {
  mode: Tabs;
  showCalendar: boolean;
  onCalendarClick(): void;
  onCalendarClose(): void;
  Menu?: () => JSX.Element;
}) => {
  const userType = useSelector(getUserType);
  if (userType === UserType.Admin) {
    return (
      <>
        {Menu && (
          <div
            className={`fixed z-10 flex justify-center bottom-16 max-w-lg right-0`}
          >
            <div className="flex-1 w-full mx-2 mb-6 overflow-x-auto bg-white shadow rounded-xl md:mx-3">
              <div className="flex items-center justify-between flex-1 p-2 space-x-2">
                <Menu />
              </div>
            </div>
          </div>
        )}
        <div className="fixed bottom-0 left-0 z-50 w-full h-20 p-2 bg-white border-t-2 border-gray-400 sm:pl-14">
          <div className="grid h-full max-w-lg grid-cols-4 gap-1 mx-auto text-xs">
            {mode === 'bookings' ? (
              <div className="flex flex-1 pb-2 group" onClick={onCalendarClose}>
                <span className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-left rounded-xl bg-blue-200 text-blue-900 font-semibold">
                  <ScheduleIcon width={18} /> Bookings
                </span>
              </div>
            ) : (
              <Link
                to={SHARED_ROUTES.SCHEDULE.ROOT}
                onClick={onCalendarClose}
                className="flex flex-1 pb-2 group"
              >
                <span className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-center font-medium text-gray-800">
                  <ScheduleIcon width={18} /> Bookings
                </span>
              </Link>
            )}
            {showCalendar ? (
              <div className="flex flex-1 pb-2 group">
                <button
                  onClick={onCalendarClick}
                  className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-left rounded-xl bg-blue-200 text-blue-900 font-semibold"
                >
                  <ScheduleIcon width={18} /> Calendar
                </button>
              </div>
            ) : (
              <div className="flex flex-1 pb-2 group">
                <button
                  onClick={onCalendarClick}
                  className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-center font-medium text-gray-800"
                >
                  <ScheduleIcon width={18} /> Calendar
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {Menu && (
        <div
          className={`fixed z-10 flex justify-center bottom-16 max-w-lg right-0`}
        >
          <div className="flex-1 w-full mx-2 mb-6 overflow-x-auto bg-white shadow rounded-xl md:mx-3">
            <div className="flex items-center justify-between flex-1 p-2 space-x-2">
              <Menu />
            </div>
          </div>
        </div>
      )}
      <div className="fixed bottom-0 left-0 z-50 w-full h-20 p-2 bg-white border-t-2 border-gray-400 sm:pl-14">
        <div className="grid h-full max-w-lg grid-cols-4 gap-1 mx-auto text-xs">
          {mode === 'bookings' ? (
            <div className="flex flex-1 pb-2 group" onClick={onCalendarClose}>
              <span className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-left rounded-xl bg-blue-200 text-blue-900 font-semibold">
                <ScheduleIcon width={18} /> Bookings
              </span>
            </div>
          ) : (
            <Link
              to={SHARED_ROUTES.SCHEDULE.ROOT}
              onClick={onCalendarClose}
              className="flex flex-1 pb-2 group"
            >
              <span className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-center font-medium text-gray-800">
                <ScheduleIcon width={18} /> Bookings
              </span>
            </Link>
          )}
          {mode === 'availability' ? (
            <div className="flex flex-1 pb-2 group" onClick={onCalendarClose}>
              <span className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-left rounded-xl bg-blue-200 text-blue-900 font-semibold">
                <EditIcon width={18} /> Availability
              </span>
            </div>
          ) : (
            <Link
              to={SHARED_ROUTES.SCHEDULE.availability}
              onClick={onCalendarClose}
              className="flex flex-1 pb-2 group"
            >
              <span className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-center font-medium text-gray-800">
                <EditIcon width={18} /> Availability
              </span>
            </Link>
          )}
          {mode === 'proposals' ? (
            <div className="flex flex-1 pb-2 group" onClick={onCalendarClose}>
              <span className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-left rounded-xl bg-blue-200 text-blue-900 font-semibold">
                <ProposalIcon width={18} /> Proposal
              </span>
            </div>
          ) : (
            <Link
              to={SHARED_ROUTES.SCHEDULE.proposals}
              onClick={onCalendarClose}
              className="flex flex-1 pb-2 group"
            >
              <span className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-center font-medium text-gray-800">
                <ProposalIcon width={18} /> Proposal
              </span>
            </Link>
          )}
          {showCalendar ? (
            <div className="flex flex-1 pb-2 group">
              <button
                onClick={onCalendarClick}
                className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-left rounded-xl bg-blue-200 text-blue-900 font-semibold"
              >
                <ScheduleIcon width={18} /> Calendar
              </button>
            </div>
          ) : (
            <div className="flex flex-1 pb-2 group">
              <button
                onClick={onCalendarClick}
                className="flex flex-col items-center flex-1 gap-2 px-2.5 py-2 text-center font-medium text-gray-800"
              >
                <ScheduleIcon width={18} /> Calendar
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const CalendarLegend = ({ mode }: { mode: Tabs }) => (
  <div className="mb-3">
    <ReadMore openBtnText="Legend" initialHeight={0}>
      <div className="py-0.5">
        {mode === 'bookings' ? (
          <BookingsCalendarLegend />
        ) : mode === 'availability' ? (
          <AvailabilityCalendarLegend />
        ) : mode === 'proposals' ? (
          <ProposalCalendarLegend />
        ) : null}
      </div>
    </ReadMore>
  </div>
);

const DesktopMenu = ({ children }) => (
  <div className="fixed bottom-0 z-10 flex justify-center right-4">
    <div className="flex-1 mx-2 mb-6 bg-white shadow-lg rounded-xl md:mx-3">
      <div className="flex items-center justify-between flex-1 gap-2 p-2">
        {children}
      </div>
    </div>
  </div>
);

interface Props {
  mode: Tabs;
  minDate: Moment;
  maxDate: Moment;
  isLoading: boolean;
  Menu?: () => JSX.Element;
  CalendarDayLoader: (props?: any) => JSX.Element;
  children: any;
}
const ScheduleTemplate = ({
  mode,
  minDate,
  maxDate,
  isLoading,
  Menu,
  CalendarDayLoader,
  children,
  ...rest
}: Props) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const handleCalendarClick = () => {
    setShowCalendar(!showCalendar);
  };
  const handleCalendarClose = () => {
    setShowCalendar(false);
  };
  return (
    <div className="flex flex-col flex-1 min-h-screen lg:flex-row">
      <Hidden lgDown>
        <aside
          className={`flex-shrink max-w-xl pl-6 py-6 overflow-auto lg:w-1/2`}
        >
          <div className="">
            <DesktopTabs mode={mode} />
            <div className="p-3 bg-white rounded-xl">
              <div className="flex justify-between pb-2 mb-2 space-x-2 border-b border-gray-300">
                <div className="flex-1">
                  <CalendarControls
                    minDate={minDate.format(DATE_FMT.DATE_KEY)}
                    maxDate={maxDate.format(DATE_FMT.DATE_KEY)}
                  />
                </div>
              </div>
              <Calendar
                DayComponent={
                  isLoading ? DayLoadingComponent : CalendarDayLoader
                }
              />
              {/* <CalendarLegend mode={mode} /> */}
              {Menu && (
                <DesktopMenu>
                  <Menu />
                </DesktopMenu>
              )}
            </div>
          </div>
        </aside>
      </Hidden>
      <section
        id="ScheduleList"
        className="flex-1 block h-full py-6 overflow-auto"
      >
        <Element name="TopOfList" />
        <div className="space-y-6 mb-28">{children}</div>
      </section>
      <Hidden lgUp>
        <>
          <MobileTabs
            mode={mode}
            showCalendar={showCalendar}
            onCalendarClick={handleCalendarClick}
            onCalendarClose={handleCalendarClose}
            Menu={Menu}
          />
          <Modal
            name="Instructor — View schedule calendar"
            open={showCalendar}
            variant="borderless"
          >
            <div className="overflow-auto">
              <div className="p-3 bg-white rounded-xl">
                <div className="flex justify-between pb-2 mb-2 space-x-2 border-b border-gray-300">
                  <div className="flex-1">
                    <CalendarControls
                      minDate={minDate.format(DATE_FMT.DATE_KEY)}
                      maxDate={maxDate.format(DATE_FMT.DATE_KEY)}
                    />
                  </div>
                </div>
                <div onClick={handleCalendarClose}>
                  <Calendar
                    DayComponent={
                      isLoading ? DayLoadingComponent : CalendarDayLoader
                    }
                  />
                </div>
                {/* <CalendarLegend mode={mode} /> */}
                <Button
                  onClick={handleCalendarClose}
                  variant="flat"
                  fullWidth={true}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal>
        </>
      </Hidden>
    </div>
  );
};

export default ScheduleTemplate;
