import { IS_SERVER } from 'config';
import { StorageHandler } from '.';

type LocalStoreKey =
  | 'assistanceClosed'
  | 'renderDate'
  | 'filterDate'
  | 'PropelRegionData'
  | 'regionSlug'
  | 'preferences'
  | 'preferredTimes'
  | 'preferredTimesGranularity'
  | 'adm_hidden_cols'
  | 'openings'
  | 'deviceLocation'
  | '_has_read_fav_help';

const _localStorage = {};
type LocalStoreOptions = any;

/**
 * Local storage should not be used for anything
 * that a user would not want posted publicly on Facebook:
 * https://www.rdegges.com/2018/please-stop-using-local-storage/
 */
class LocalStorageHandler
  implements StorageHandler<LocalStoreKey, LocalStoreOptions>
{
  private enabled: boolean;
  constructor() {
    this.enabled = !IS_SERVER && this.storageAvailable('localStorage');
    if (!this.get('preferences')) {
      this.set('preferences', {});
    }
  }

  isEnabled(): boolean {
    return this.enabled;
  }

  storageAvailable = (type: 'localStorage' | 'sessionStorage') => {
    let storage;
    try {
      storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      );
    }
  };
  private isDefined() {
    return IS_SERVER === false && this.storageAvailable('localStorage');
  }

  set(
    key: LocalStoreKey,
    value: string | boolean | number | object | any[]
  ): void {
    if (!this.isDefined()) {
      _localStorage[key] = value;
      return;
    }
    let newValue;
    if (['string', 'boolean', 'number'].indexOf(typeof value) > -1) {
      newValue = value;
    } else {
      newValue = JSON.stringify(value);
    }
    localStorage.setItem(key, newValue);
  }
  get(key: LocalStoreKey): string {
    if (!this.isDefined()) {
      return _localStorage[key];
    }
    return localStorage.getItem(key);
  }
  getJSON(key: LocalStoreKey): object | any[] {
    if (!this.isDefined()) {
      return _localStorage[key];
    }
    return JSON.parse(localStorage.getItem(key));
  }
  remove(key: LocalStoreKey): void {
    if (!this.isDefined()) {
      delete _localStorage[key];
      return;
    }
    localStorage.removeItem(key);
  }
  setPreference(key: string, value: string | number | boolean): void {
    const preferences = this.getJSON('preferences') || {};
    preferences[key] = value;
    localStorage.setItem('preferences', JSON.stringify(preferences));
  }
  getPreference(
    key: string,
    defaultValue: string | number | boolean = undefined
  ) {
    const preferences = this.getJSON('preferences');
    if (!preferences) {
      this.setPreference('setup', undefined);
    }
    if (typeof preferences[key] === 'undefined') {
      this.setPreference(key, defaultValue);
      return defaultValue;
    }
    return preferences[key];
  }
}

export default LocalStorageHandler;
