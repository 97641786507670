import { MAX_PASSWORD_LENGTH } from 'config';
import { EyeOffIcon, EyeOnIcon } from 'icons';
import React, { useState } from 'react';

const PasswordInput = ({
  disabled,
  value,
  onChange,
  label = 'Password',
  placeholder = 'Password',
  name = 'password',
  error = undefined,
}) => {
  const [showHint, setShowHint] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleFocus = () => {
    setShowHint(true);
  };

  const handleBlur = () => {
    setShowHint(false);
  };

  return (
    <>
      {/* <PasswordRequirementHint isVisible={!!error} password={value} /> */}
      <div className="relative">
        <span
          onClick={() => setShowPassword(!showPassword)}
          className="absolute top-0 right-0 flex items-center justify-center w-12 h-12 leading-none text-gray-500 cursor-pointer hover:text-blue-500"
        >
          {showPassword ? <EyeOffIcon width={24} /> : <EyeOnIcon width={24} />}
        </span>
        <input
          id={name}
          name={name}
          type={showPassword ? 'text' : 'password'}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          autoComplete="new-password"
          maxLength={MAX_PASSWORD_LENGTH}
          disabled={disabled}
          placeholder={placeholder}
          className={`pr-12 ${!!error ? 'input-error' : ''}`}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
        />
      </div>
    </>
  );
};

export default PasswordInput;
