import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { KpiSerializer } from 'api/Serializers/Analytics';
import { FETCH_STATE } from 'config';
import { APIData } from 'state';
import { AppDispatch } from 'state/store';
import { BLANK_API_DATA } from '../utils';

interface AdminReducer {
  kpis: APIData<KpiSerializer>;
}

const initialState: AdminReducer = {
  kpis: BLANK_API_DATA(),
};
const name: 'admin' = 'admin';
const Slice = createSlice({
  name,
  initialState,
  reducers: {
    setKPIData(state, action: PayloadAction<KpiSerializer>) {
      state.kpis.data = action.payload;
    },
    setKPIFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.kpis.fetchState = action.payload;
    },
  },
});

const { setKPIData, setKPIFetchState } = Slice.actions;

export const fetchKpi = () => async (dispatch: AppDispatch, getState) => {
  dispatch(setKPIFetchState(FETCH_STATE.GET));
  dispatch(setKPIData(undefined));
  const response = await api.analytics.kpis();
  dispatch(setKPIFetchState(FETCH_STATE.IDLE));
  return dispatch(setKPIData(response.data));
};

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
