import { AxiosResponse } from 'axios';
import { Activity } from '../Activities';
import { Client, Facility, Instructor, TimeFrame } from '../Appointments';
import { Participant } from '../Clients';
import { FacilityScheduleSerializer } from '../Facilities';

export interface SchedulableObject {
  id: string;
  activity: Activity;
  client?: Client;
  participants?: Participant[];
  instructor: Instructor;
  facility: Facility;
  summary: string;
  created?: string;
  modified?: string;
  start: string;
  end: string;
  date: string;
  time: string;
  cancelled: boolean;
  timezone: string;
  timeFrame: TimeFrame;
}

export enum Tense {
  /** In the past */
  LastMonth = -2,
  Past = -1,
  Now = 0,
  Today = 1,
  Future = 2,
  NextMonth = 3,
}

export enum FacilityTimeSlotType {
  Closed = 0,
  Open = 1,
  Blocked = 2,
  Full = 3,
  Private = 4,
}

export enum InstructorTimeSlotType {
  Closed = 0,
  Available = 1,
  Pending = 2,
  Booked = 3,
}

export type ScheduleDateStatus =
  | 'valid'
  | 'instructor_works_elsewhere'
  | 'invalid_date'
  | 'facility_closed'
  | 'fetch_error'
  | 'early_access'
  | 'private';

export interface ScheduleDateSerializer {
  aptProdId?: string;
  date: string;
  times?: ScheduleTimeSerializer[];
  tense?: Tense;
  loading?: boolean;
  isNew?: boolean;
  status?: ScheduleDateStatus;
}

export enum ScheduleChangeAction {
  Undo = 'Undo',
  Create = 'CREATE',
  Delete = 'DELETE',
}

export interface WeeklySchedule {
  start: string;
  end: string;
  aptProdId: string;
  schedule: FacilityScheduleSerializer;
  monday: string[];
  tuesday: string[];
  wednesday: string[];
  thursday: string[];
  friday: string[];
  saturday: string[];
  sunday: string[];
}

export interface ScheduleChange {
  id: string;
  aptProdId: string;
  datetime: string;
  date: string;
  action: ScheduleChangeAction;
  created?: string;
}

export interface ScheduleTimeSerializer {
  id: string;
  clientId?: number;
  title: string;
  description: string;
  datetime: string;
  otherData?: { clientId: number; clientName: string; numParticipants: number };
  facilityStatus: FacilityTimeSlotType;
  instructorStatus: InstructorTimeSlotType;
  isBookable: boolean;
  isAvailable: boolean;
}

export interface ScheduleChangeSerializer {
  id: string;
  toCreate: string[];
  toDelete: string[];
}

export interface AvailabilityMap {
  datetime: string;
  facilityStatus: FacilityTimeSlotType;
  available: number[];
  scheduled: string[];
  proposed: string[];
  spots: number;
  supply: number;
  pending: number;
  booked: number;
  isBookable: boolean;
  prev: string;
  next: string;
}

interface CreatedWeeklyScheduleResponse {
  result: string;
  created: number;
  modified: number;
}

interface Api {
  put: (
    username: string,
    data: ScheduleChangeSerializer[]
  ) => Promise<AxiosResponse<{ success: boolean; message: string }>>;
  dates: (
    username: string,
    params: { start: string; end: string; aptProdId?: string }
  ) => Promise<AxiosResponse<ScheduleDateSerializer[]>>;
  times: (
    username: string,
    params: { aptProdId?: string; date: string; version?: number },
    signal?: AbortSignal
  ) => Promise<AxiosResponse<ScheduleDateSerializer>>;
  openings: (
    params: { aptProdId: string; start: string; end: string; version?: number },
    abortController?: AbortController
  ) => Promise<AxiosResponse<ScheduleDateSerializer[]>>;
  weekly: {
    create: (
      username: string,
      data: WeeklySchedule[]
    ) => Promise<AxiosResponse<CreatedWeeklyScheduleResponse>>;
  };
}

export default Api;
