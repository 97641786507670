import { LatLng } from 'api/Serializers/Facilities';
import { GOOGLE_MAPS_API_KEY, IS_SERVER } from 'config';
import React from 'react';

interface Props {
  latlng?: LatLng;
  shortAddress?: string;
  zoom?: number;
  width?: number;
  height?: number;
}

const StaticMap = React.memo(function Component({
  latlng,
  zoom = 12,
  width = 682,
  height = 430,
}: Props) {
  if (IS_SERVER) {
    return <div>Loading...</div>;
  }
  logger.info(`Render Static Map @ ${latlng.lat},${latlng.lng}`);
  const base = 'https://maps.googleapis.com/maps/api/staticmap?';
  const size = `size=${width}x${height}&scale=2`;
  const key = `key=${GOOGLE_MAPS_API_KEY}`;
  const marker = `markers=${latlng.lat},${latlng.lng}`;
  const zoom_ = `zoom=${zoom}`;
  const params = encodeURI(`${key}&${size}&${marker}&${zoom_}`);
  return <img src={`${base}${params}`} />;
});

/**
 * MemoMap is a memoized dynamic google map implementation, which is done
 * to prevent multiple renders of a per-refresh charged service.
 *
 * IMPORTANT: Before implementing on production, you must verify that the
 * map correctly shows the Google logo and attribution in the bottom right
 * corner, or the API key may be revoked.
 */
export const DynamicMap = React.memo(function Component({
  latlng,
  shortAddress,
  zoom = 14,
}: Props) {
  if (IS_SERVER) {
    return <div>Loading...</div>;
  }
  return (
    <iframe
      id="gmaps"
      name="gmaps"
      className="w-full -mt-32 h-96"
      width="450"
      height="250"
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${shortAddress}`}
      allowFullScreen
    ></iframe>
  );
});

const MapComponent = (props: Props) => {
  return <StaticMap {...props} />;
};

export default MapComponent;
