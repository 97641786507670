import RefreshIcon from '@mui/icons-material/Refresh';
import WifiIcon from '@mui/icons-material/Wifi';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { MAX_NETWORK_RETRIES, NETWORK_RETRIES_BEFORE_ALERT_USER } from 'config';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAppNetworkRetry } from 'state/selectors';

const NetworkError = () => {
  const networkRetry = useSelector(getAppNetworkRetry);
  const handleClose = () => {
    return null;
  };
  if (networkRetry < NETWORK_RETRIES_BEFORE_ALERT_USER) {
    return null;
  } else if (networkRetry < MAX_NETWORK_RETRIES) {
    return <Loading message="Connectivity Error: Retrying request..." />;
  } else if (networkRetry === MAX_NETWORK_RETRIES) {
    return (
      <Modal
        name="Network Error"
        open={true}
        onClose={handleClose}
        title="Network Error"
        maxWidth="sm"
      >
        <div>
          <p>Uh oh — are we in a tunnel? I can't get a signal!</p>
          <p>
            It seems you've lost internet connectivity. Here's a few things you
            can try:
          </p>
          <div className="flex items-center my-2">
            <RefreshIcon className="mr-2 text-green-500" />
            Refresh the page to try again
          </div>
          <div className="flex items-center my-2">
            <WifiIcon className="mr-2 text-green-500" />
            On mobile? Wait until you're on wifi
          </div>
          <Controls>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          </Controls>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default NetworkError;
