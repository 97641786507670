import api from 'api';
import { Proposal, ProposalStatus } from 'api/Serializers/Proposals';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { Action, DATE_FMT, QueryParams, UserType } from 'config';
import ProposalStatusBadge from 'features/proposals/proposal-status-badge';
import ProposalCancel from 'features/schedule/as-instructor/proposals/cancel';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AccountIcon, ScheduleIcon } from 'icons';
import { GenericServerError } from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from 'state/selectors';
import { fetchProposals } from 'state/slice/proposals';
import { getUrlParam, setQueryParam, SHARED_ROUTES } from 'utils/routing';
import ProposalBook from './book';

const Hero = ({ src }) => (
  <div className="relative h-full">
    <div
      className="absolute left-0 right-0 top-1/2"
      style={{ transform: 'translateY(-50%)' }}
    >
      <img src={src} />
    </div>
  </div>
);

const ProposalDetail = ({
  proposal,
  onClose,
}: {
  onClose(): void;
  proposal: Proposal;
}) => {
  const appointment = proposal;
  const datetime = moment(appointment.start)
    .tz(appointment.timezone)
    .format(DATE_FMT.DOW_MONTH_D_TIME_A);
  return (
    <div className="text-base sm:min-w-lg">
      <div className="flex flex-col w-full rounded-lg">
        <div className="h-56 overflow-hidden sm:hidden">
          <Hero src={appointment.facility.hero} />
        </div>
        <div className="hidden h-56 overflow-hidden rounded-t-lg sm:block">
          <Hero src={appointment.facility.hero} />
        </div>
        <div className="flex flex-col justify-between flex-1 sm:flex-row">
          <div className="flex-1 px-4 pt-8 pb-10 space-y-6 bg-white sm:px-8">
            <div>
              <h3 className="text-gray-700">
                {appointment.facility.displayName}{' '}
                {appointment.activity.name.toLowerCase()}
              </h3>
              <ProposalStatusBadge proposal={proposal} />
            </div>
            <div className="space-y-4">
              <div className="labeled-icon">
                <Avatar
                  src={appointment.instructor.avatar}
                  className=""
                  diameter={6}
                />
                <span>{appointment.instructor.fullName}</span>
              </div>
              <div className="labeled-icon">
                <AccountIcon width={24} />
                <span>{appointment.client.fullName}</span>
              </div>
              <div className="labeled-icon">
                <ScheduleIcon width={24} />
                <span>
                  {datetime}
                  {appointment.cancelled && ' [Cancelled]'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 px-4 m-0 bg-white border-t border-gray-300">
        <Controls>
          <Button variant="flat" onClick={onClose}>
            Close
          </Button>
          {proposal.status === ProposalStatus.AwaitingClient && (
            <Button
              variant="flat"
              color="secondary"
              to={`${SHARED_ROUTES.SCHEDULE.proposal(
                proposal.id
              )}&action=cancel`}
              onClick={onClose}
            >
              Cancel
            </Button>
          )}
          {/* <Button
            variant="contained"
            to={SHARED_ROUTES.SCHEDULE.nav()}
            disabled={isActionDisabled}
          >
            Access Instructions
          </Button>
          <Button
            to={history.location.pathname + }
            color="primary"
            variant="contained"
            disabled={isActionDisabled}
          >
            Manage {appointment.activity.appointmentNoun}
          </Button> */}
        </Controls>
      </div>
    </div>
  );
};

const ProposalDetailLoader = ({ id, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [proposal, setProposal] = useState<Proposal>();
  const user = useSelector(getCurrentUser);
  const action = getUrlParam(QueryParams.Action);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const fetchProposal = async () => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await api.proposals.retrieve(id);
      setProposal(response.data);
    } catch (error) {
      enqueueSnackbar(GenericServerError);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id && id !== '') {
      fetchProposal();
    } else {
      setProposal(undefined);
    }
  }, [id]);

  const handleProposalBook = async () => {
    if (user.type !== UserType.Admin) {
      return;
    }
    try {
      console.warn('Previously was Proposal .book');
      // await api.proposals.book(id);
    } catch (error) {
      enqueueSnackbar(GenericServerError);
    }
  };

  const handleProposalCancel = async () => {
    const date = proposal.date;
    onGoBack();
    setIsLoading(true);

    try {
      await api.proposals.cancel(proposal.id);
      // dispatch(refreshDate(date));
      fetchProposal();
      dispatch(fetchProposals(undefined, true));
    } catch (error) {
      enqueueSnackbar(GenericServerError);
    }
  };

  const onGoBack = () => {
    history.push(
      history.location.pathname + setQueryParam(QueryParams.Action, undefined)
    );
  };

  if (!isLoading && proposal === undefined) {
    return null;
  }

  return (
    <Modal
      name="Instructor — Proposal detail"
      open={Boolean(id && id !== '')}
      onClose={onClose}
      maxWidth="md"
      variant={!action || action === Action.DETAIL ? 'borderless' : 'default'}
    >
      {isLoading ? (
        <Loading />
      ) : !action ? (
        <ProposalDetail proposal={proposal} onClose={onClose} />
      ) : action === Action.CANCEL ? (
        <ProposalCancel
          proposal={proposal}
          goBack={onGoBack}
          onProposalCancel={handleProposalCancel}
        />
      ) : action === Action.BOOK ? (
        <ProposalBook
          proposal={proposal}
          goBack={onGoBack}
          onProposalBook={handleProposalBook}
        />
      ) : null}
    </Modal>
  );
};

export default ProposalDetailLoader;
