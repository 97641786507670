import { ProposalStatus } from 'api/Serializers/Proposals';
import Loading from 'components/loading';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AppointmentDebugToolbar } from 'pages/account/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  getAccountDetail,
  getInstructorsSchedulable,
  getProposals,
} from 'state/selectors';
import { SHARED_ROUTES } from 'utils/routing';
import { SidebarMenu } from '..';
import ClientNotices from './notices';
import { ClientMenuItems } from './utils';

const Account = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail);
  const proposals = useSelector(getProposals);
  const instructors = useSelector(getInstructorsSchedulable);
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    setInit(!!account && !!instructors && !!proposals);
  }, [account, instructors, proposals]);

  if (!init) {
    return <Loading message="Getting ready..." />;
  }

  const filteredMenuItems = ClientMenuItems.filter(
    (item) =>
      item.Icon &&
      item.text &&
      (item.isHidden === undefined || !item.isHidden(account))
  );

  const proposalsAwaiting =
    proposals.filter((p) => p.status === ProposalStatus.AwaitingClient) || [];

  if (location.pathname === SHARED_ROUTES.DASHBOARD.ROOT) {
    history.replace(SHARED_ROUTES.SCHEDULE.ROOT + history.location.search);
    return <Loading />;
  }

  return (
    <>
      <SidebarMenu menuItems={filteredMenuItems}>
        <Switch>
          {ClientMenuItems.filter((item) => !!item.Component).map(
            (item, key) => (
              <Route
                key={`route${key}`}
                exact={true}
                path={item.route.PATH}
                render={(routeProps) => (
                  <item.Component {...routeProps} account={account} />
                )}
              />
            )
          )}
        </Switch>
      </SidebarMenu>
      <AppointmentDebugToolbar />
      <ClientNotices />
    </>
  );
};

export default Account;
