import { ReasonForJoining } from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import { CheckIcon } from 'icons';
import React from 'react';
import { InstructorOnboardingRoutes } from 'utils/routing';
import { BaseStepProps, ControlClasses, TextClasses, TitleClasses } from '..';

const personalizedText = (reason: ReasonForJoining) =>
  reason === ReasonForJoining.Teaching
    ? 'Teach private lessons'
    : reason === ReasonForJoining.OwnSchedule
    ? 'Work when you want'
    : reason === ReasonForJoining.EarningMore
    ? 'Earn more for your time'
    : '';

const EverythingYouNeed: React.FC<BaseStepProps> = (props) => {
  return (
    <div>
      <h1 className={TitleClasses}>You've come to the right place</h1>
      <p className={TextClasses}>
        Propel is an online platform where anyone can book hourly private
        swimming lessons with you.
      </p>
      <p className={TextClasses}>
        We provide everything you need.{' '}
        {personalizedText(props.onboarding.reasonToJoin)} and teach as much or
        as little as you like.
      </p>
      <div>
        <h5 className="mb-2">Propel provides everything you need.</h5>
        <ul className="space-y-3">
          <li className="flex items-center space-x-2">
            <CheckIcon className="text-green-500" width={20} />
            <span>Access to beautiful private pools</span>
          </li>
          <li className="flex items-center space-x-2">
            <CheckIcon className="text-green-500" width={20} />
            <span>Control over your rate and schedule</span>
          </li>
          <li className="flex items-center space-x-2">
            <CheckIcon className="text-green-500" width={20} />
            <span>Your own profile and booking page</span>
          </li>
        </ul>
      </div>
      <div className={ControlClasses}>
        <Button
          to={InstructorOnboardingRoutes.S02}
          variant="contained"
          color="primary"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default EverythingYouNeed;
