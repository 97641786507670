import HelpIcon from '@mui/icons-material/Help';
import React from 'react';

const HelpButton = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="inline-flex items-center justify-center py-1 pl-1 pr-3 text-sm leading-none text-blue-600 align-middle transition-colors duration-300 bg-blue-100 border border-blue-200 border-solid rounded-full cubic hover:bg-blue-200 hober:text-blue-700"
  >
    <HelpIcon className="mr-1 text-blue-400" /> {children}
  </button>
);

export default HelpButton;
