import {
  Client,
  ConnectionState,
  Conversation,
  Message,
  Participant,
} from '@twilio/conversations';
import api from 'api';
import { IS_SERVER } from 'config';
import { getFreshToken } from 'hooks/useSession';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import {
  insertConversationDataSets,
  parseAndInsertMessages,
  parseAndSetMessagingUser,
  parseAndUpsertConversation,
  parseAndUpsertParticipants,
  setConnectionState,
  setMessagingInit,
} from 'state/slice/messaging';
import { CookieStore } from 'state/storage';
import { CookieKey } from 'state/storage/cookies';
import { store } from 'state/store';

class MessagingClientManager {
  client: Client;
  init: boolean;
  connectionAttempt: number;
  tokenFetchedAt: string;
  isRefreshing: boolean;

  constructor() {
    this.client = undefined;
    this.init = false;
    this.connectionAttempt = 0;
    this.tokenFetchedAt = undefined;
    this.isRefreshing = false;
  }

  async startClient() {
    if (this.connectionAttempt > 0 || IS_SERVER) return;
    const token = CookieStore.get(CookieKey.MessagingToken);
    await this._refreshClient(token);
  }

  async logout() {
    if (IS_SERVER) return;
    CookieStore.remove(CookieKey.MessagingToken);
    await this._shutdownClient();
    this.init = false;
    this.connectionAttempt = 0;
    this.tokenFetchedAt = undefined;
    this.isRefreshing = false;
  }

  async _refreshClient(oldToken?: string) {
    if (this.isRefreshing) return;
    this.isRefreshing = true;
    this.connectionAttempt++;
    await this._shutdownClient();
    let token = oldToken;
    if (
      token === undefined ||
      moment().isAfter(moment(jwtDecode(token).exp * 1000))
    ) {
      try {
        token = await this._fetchToken();
      } catch (err) {
        this.logout();
        return;
      }
    }
    this.client = this._createClient(token);
    this.isRefreshing = false;
  }

  async _shutdownClient() {
    if (this.client === undefined) return;
    await this.client.shutdown();
    this.client = undefined;
  }

  async _fetchToken() {
    const setAuthTokens = await getFreshToken();
    if (setAuthTokens) {
      const response = await api.auth.messaging();
      CookieStore.set(CookieKey.MessagingToken, response.data.token, {
        expires: 0.5,
      });
      this.tokenFetchedAt = moment().format();
      return response.data.token;
    } else {
      throw new Error('User is not authenticated');
    }
  }

  _createClient(token: string) {
    const client = new Client(token, { logLevel: 'error' });
    client.on('initialized', async () => {
      if (!this.init) {
        this.init = true;
        store.dispatch(parseAndSetMessagingUser(client.user));
      }
      try {
        let paginator = await client.getSubscribedConversations();
        do {
          const insertPagePromise = store.dispatch(
            insertConversationDataSets(paginator.items)
          );
          if (paginator.hasNextPage) {
            paginator = await paginator.nextPage();
          } else {
            await insertPagePromise;
          }
        } while (paginator.hasNextPage);
      } finally {
        store.dispatch(setMessagingInit(true));
      }
    });

    client.on('conversationJoined', (conversation: Conversation) => {
      store.dispatch(insertConversationDataSets([conversation]));
    });

    client.on('messageAdded', (message: Message) => {
      store.dispatch(
        parseAndInsertMessages(message.conversation.sid, [message])
      );
    });

    client.on(
      'conversationUpdated',
      ({ conversation }: { conversation: Conversation }) => {
        store.dispatch(parseAndUpsertConversation(conversation));
      }
    );

    client.on('participantJoined', (participant: Participant) => {
      store.dispatch(
        parseAndUpsertParticipants(participant.conversation.sid, [participant])
      );
    });

    // token will expire in 3 minutes or less
    client.on('tokenAboutToExpire', () => {
      this._fetchToken()
        .then((token) => client.updateToken(token))
        .catch(() => this.logout());
    });

    client.on('tokenExpired', () => {
      this.connectionAttempt = 0;
      this._refreshClient();
    });

    client.on('connectionStateChanged', (state: ConnectionState) => {
      store.dispatch(setConnectionState(state));
    });

    client.on('connectionError', (err) => {
      if (!err.terminal) return;
      if (this.connectionAttempt < 3) {
        this._refreshClient();
      } else {
        if (store.getState().messaging.init === undefined) {
          store.dispatch(setMessagingInit(false));
        }
        logger.error(err.message, {
          connectionState: client.connectionState,
          connectionAborted: err.terminal,
          clientInit: this.init,
          tokenFetchedAt: this.tokenFetchedAt,
          tokenStartsAt: moment(jwtDecode(token).nbf * 1000).format(),
          tokenExpiresAt: moment(jwtDecode(token).exp * 1000).format(),
          httpStatusCode: (err as any).httpStatusCode,
          twilioErrorCode: (err as any).errorCode,
        });
      }
    });
    return client;
  }
}

export const messagingClientManager = new MessagingClientManager();
