import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { PropelAccount } from 'api/Serializers/Accounts';
import { Event } from 'api/Serializers/Appointments';
import { HTTP_200_OK } from 'api/status';
import { AxiosError } from 'axios';
import { DATE_FMT, FETCH_STATE, METHOD, UserType } from 'config';
import {
  Report,
  ReportAction,
  ReportCategory,
  // ReportGeolocation,
  ReportResolution,
  ReportResult,
  ReportType,
} from 'models/Assistance';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { RootState } from 'state';
import {
  getAppointment,
  getAssistanceReport,
  getProxySession,
} from 'state/selectors';
import { AppDispatch } from 'state/store';
import { getInitialStateFromStorage } from '../utils';

type Step = 'confirm' | 'call' | 'complete' | 'error';
export interface ProxySession {
  status: string;
  loaded: boolean;
  existingSession: boolean;
  userPhone: string;
  userProxyPhone: string;
  recipient: UserType;
  sender: UserType;
  error: 'permission_error' | 'creation_error';
  step: Step;
}

interface AssistanceReducer {
  report: Report;
  proxySession: ProxySession;
}

const blankReport: Report = {
  created: undefined,
  category: undefined,
  type: undefined,
  resolution: undefined,
  result: undefined,
  event: undefined,
  timeFrame: undefined,
  actions: [],
  images: [],
  state: FETCH_STATE.IDLE,
  // geolocation: undefined,
};

const initialState: AssistanceReducer = {
  report: blankReport,
  proxySession: {} as ProxySession,
};

const name: 'assistance' = 'assistance';
const Slice = createSlice({
  name,
  initialState: getInitialStateFromStorage(name, initialState),
  reducers: {
    setReportCategory(state, action: PayloadAction<ReportCategory>) {
      state.report.category = action.payload;
    },
    setReportAttribute(
      state,
      action: PayloadAction<{
        key: keyof Event;
        value: string | boolean | number;
      }>
    ) {},
    setReportType(state, action: PayloadAction<ReportType>) {
      state.report.type = action.payload;
    },
    setReportResolution(state, action: PayloadAction<ReportResolution>) {
      state.report.resolution = action.payload;
    },
    setReportResult(state, action: PayloadAction<ReportResult>) {
      state.report.result = action.payload;
    },
    processReportAction(state, action: PayloadAction<ReportAction>) {
      const created = moment().format(DATE_FMT.DATETIME_FIELD);
      const newAction = { ...action.payload, created };
      if (action.payload.type === 'START') {
        state.report = { ...blankReport };
        state.report.actions = [newAction];
      } else {
        const index = state.report.actions.findIndex(
          (a) => a.type === action.payload.type
        );
        if (index > -1) {
          if (state.report.actions[index].value === action.payload.value) {
            return;
          }
          state.report.actions[index] = newAction;
        } else {
          state.report.actions.push(newAction);
        }
      }
      state.report[action.payload.key] = action.payload.value;
    },
    upsertReportAction(state, action: PayloadAction<ReportAction>) {
      const created = moment().format(DATE_FMT.DATETIME_FIELD);
      const newAction = { ...action.payload, created };
      if (!state.report.actions) {
        state.report.actions = [newAction];
      } else {
        const index = state.report.actions.findIndex(
          (a) => a.key === action.payload.key && a.type === action.payload.type
        );
        if (index > -1) {
          if (state.report.actions[index].value === action.payload.value) {
            return;
          }
          state.report.actions[index] = newAction;
        } else {
          state.report.actions.push(newAction);
        }
      }
    },
    deleteReportData(
      state,
      action: PayloadAction<{ key: string; type?: string }>
    ) {
      if (!state.report.actions) {
        state.report.actions = [];
      } else {
        state.report.actions = state.report.actions.filter(
          (d) =>
            d.key !== action.payload.key ||
            (action.payload.type !== undefined &&
              d.type !== action.payload.type)
        );
      }
    },
    setProxySession(state, action: PayloadAction<Partial<ProxySession>>) {
      state.proxySession = { ...state.proxySession, ...action.payload };
    },
    resetReport: (state) => {
      state.report = { ...blankReport };
    },
  },
});

export const {
  setReportCategory,
  setReportResolution,
  setReportResult,
  setReportType,
  setReportAttribute,
  processReportAction,
  setProxySession,
  upsertReportAction,
  deleteReportData,
  resetReport,
} = Slice.actions;

export const fetchProxySession =
  (user: PropelAccount, appointment: Event, recipient: UserType) =>
  async (dispatch, getState) => {
    const proxySession = getProxySession(getState());
    let userPhone: string = user.phoneNumber;
    let userProxyPhone: string = '';
    let existingSession: boolean = false;
    try {
      const response = await api.appointments.proxySessions(appointment.id, {
        recipient,
      });
      if (response.status === HTTP_200_OK && response.data) {
        userPhone = response.data.phone;
        userProxyPhone = response.data.proxyPhone;
        existingSession = true;
      }
    } catch (err) {}
    return dispatch(
      setProxySession({
        recipient,
        sender: user.type,
        error: undefined,
        userPhone,
        userProxyPhone,
        existingSession,
        loaded: true,
        status: 'ready',
        step: 'confirm',
      })
    );
  };

export const startProxySession =
  (appointment: Event, onSuccess: (proxyPhone: string) => void) =>
  async (dispatch, getState) => {
    const state = getProxySession(getState());
    dispatch(setProxySession({ status: 'creating' }));
    let userProxyPhone = state.userProxyPhone;
    if (!userProxyPhone) {
      try {
        const response = await api.appointments.proxySessions(
          appointment.id,
          { recipient: state.recipient },
          { phone: state.userPhone },
          METHOD.POST
        );
        userProxyPhone = response.data.proxyPhone;
        onSuccess(userProxyPhone);
      } catch (err: any) {
        dispatch(
          setProxySession({
            ...state,
            step: 'error',
            status: 'error',
            error: err.response?.data?.reason,
          })
        );
        enqueueSnackbar({
          message:
            'An error was encountered while setting up the call. A report was submitted to Propel.',
          variant: 'error',
        });
        return;
      }
    } else {
      onSuccess(userProxyPhone);
    }
    setTimeout(
      () =>
        dispatch(
          setProxySession({ userProxyPhone, status: 'ready', step: 'complete' })
        ),
      1000
    );
  };

export const submitReport =
  (onSuccess: () => void, onError?: (err) => void) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(
      processReportAction({
        type: 'SUBMIT',
        key: 'state',
        value: FETCH_STATE.POST,
      })
    );
    const state = getState();
    const appointment = getAppointment(state);
    const report = getAssistanceReport(getState());
    // const json: GeolocationPosition = JSON.parse(
    //   LocalStore.get('deviceLocation')
    // );
    // const geolocation: ReportGeolocation = {
    //   timestamp: json.timestamp,
    //   latlng: { lat: json.coords.latitude, lng: json.coords.longitude },
    //   accuracy: json.coords.accuracy,
    //   altitude: json.coords.altitude,
    //   altitudeAccuracy: json.coords.altitudeAccuracy,
    //   heading: json.coords.heading,
    //   speed: json.coords.speed,
    // };
    const data: Report = {
      ...report,
      actions: report.actions
        .filter((a) => a.key !== 'images')
        .map((a) => ({ ...a, value: a.value.toString() })),
      event: appointment.id,
    };
    try {
      await api.reports.create(data as any);
    } catch (err) {
      if (err instanceof AxiosError) {
        logger.captureAxiosError('Error submitting problem report', err);
      }
      if (onError) onError(err);
      return dispatch(
        processReportAction({
          type: 'ERROR',
          key: 'state',
          value: FETCH_STATE.FAILED,
        })
      );
    }
    onSuccess();
    return dispatch(
      processReportAction({
        type: 'SUBMIT',
        key: 'state',
        value: FETCH_STATE.FULFILLED,
      })
    );
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
