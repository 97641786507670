import Check from '@mui/icons-material/Check';
import Icon from 'components/icon';
import * as React from 'react';
import style from './style.module.scss';

interface ListItem {
  id: number;
  title: string;
  hint: string;
  icon?: string;
  iconUrl?: string;
}

interface ListItems {
  options: ListItem[];
  title?: string;
  selectedList?: number[];
  onClick?: (item: ListItem) => () => void;
}

const ListTextItems: React.FunctionComponent<ListItems> = ({
  options,
  title,
  selectedList,
  onClick,
}) => {
  if (!options) {
    return null;
  }
  return (
    <div className={style.container}>
      {title !== '' && (
        <div className={style.header}>
          <h2>{title}</h2>
        </div>
      )}
      <div className={style.textItems}>
        {options.map((item, index) => {
          if (!item || item.title === '') {
            return null;
          }
          const itemTitle = item.title;
          const id = item.id;
          const icon = item.icon;
          const iconUrl = item.iconUrl;
          const hint = item.hint;
          const selected = selectedList.findIndex((i) => i === id) !== -1;
          return (
            <div
              key={index}
              className={`${style.textItem}${
                onClick ? ` ${style.clickable}` : ''
              }${selected ? ` ${style.selected}` : ''}`}
              onClick={!!onClick ? onClick(item) : undefined}
            >
              {iconUrl && <img className={style.icon} src={iconUrl} />}
              {!iconUrl && icon && <Icon icon={icon} className={style.icon} />}
              <p>{itemTitle}</p>
              {hint && hint !== '' && (
                <span className={style.hint}>{hint}</span>
              )}
              {selected && <Check className={style.checkmark} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ListTextItems.defaultProps = {
  selectedList: [],
  title: '',
};

export default ListTextItems;
