import api from 'api';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import Checkbox from 'components/checkbox';
import Redirect from 'components/redirect';
import {
  DATE_FMT,
  DONE_WITH_PROPEL_REASON_OPTIONS,
  HIATUS_LENGTH_OPTIONS,
  HIATUS_REASON_OPTIONS,
} from 'config';
import Page from 'containers/page';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useForm from 'hooks/useForm';
import { FeedbackSubmitSuccess, GenericServerError } from 'lang/en/Snackbars';
import { AvailabilityFeedbackMeta as Meta } from 'metadata';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { APP_ROUTES } from 'utils/routing';

enum AvailabilityFeedbackType {
  Hiatus = 'hiatus',
  Done = 'done',
}

const AvailabilityFeedback = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { form, uid } = useParams<any>();
  if (
    form !== AvailabilityFeedbackType.Hiatus &&
    form !== AvailabilityFeedbackType.Done
  ) {
    return <Redirect to={APP_ROUTES.NOT_FOUND} />;
  }
  const dispatch = useAppDispatch();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const history = useHistory();
  const initialData = {
    type: form.capitalize(),
    reason: '',
    unsubscribed: false,
    end: '',
    additional: '',
  };
  const onSubmit = async (inputs) => {
    setSubmitDisabled(true);
    const payload = { ...inputs };
    if (typeof payload.end === 'number') {
      payload.end = moment().add(payload.end, 'days').format(DATE_FMT.DATE_KEY);
    } else {
      payload.end = null; // server won't accept empty string for date field
    }
    try {
      await api.availabilityFeedback.update(uid, payload);
      enqueueSnackbar(FeedbackSubmitSuccess);
      history.push(APP_ROUTES.HOME);
    } catch {
      enqueueSnackbar(GenericServerError);
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    initialData,
    onSubmit
  );
  const subtitle = {
    [AvailabilityFeedbackType.Hiatus]:
      'Would you be willing to tell us more about it?',
    [AvailabilityFeedbackType.Done]: 'Would you be willing to tell us why?',
  };
  return (
    <Page {...Meta}>
      <div className="flex justify-center px-4 py-12 bg-background min-h-90vh sm:px-6 lg:px-8">
        <div className="w-full max-w-xl">
          <div className="px-6 pt-8 pb-16 mb-4 space-y-8 bg-white rounded-lg shadow-lg sm:px-10">
            <div>
              <h2 className="mt-6 text-xl font-bold text-center text-gray-800">
                Thanks for letting us know!
              </h2>
              <h3 className="text-md font-normal text-center text-gray-700">
                {subtitle[form]}
              </h3>
            </div>
            <form className="mt-8" onSubmit={handleSubmit}>
              <div className="grid grid-cols-6 gap-4">
                {form === AvailabilityFeedbackType.Hiatus ? (
                  <Hiatus
                    inputs={inputs}
                    submitDisabled={submitDisabled}
                    handleInputChange={handleInputChange}
                  />
                ) : form === AvailabilityFeedbackType.Done ? (
                  <Done
                    inputs={inputs}
                    submitDisabled={submitDisabled}
                    handleInputChange={handleInputChange}
                  />
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Page>
  );
};

const Hiatus = ({ inputs, submitDisabled, handleInputChange }): JSX.Element => {
  return (
    <React.Fragment>
      <div className="col-span-6">
        <label htmlFor="reason">Reason you're taking a break</label>
        {HIATUS_REASON_OPTIONS.map((option) => {
          return (
            <ButtonLarge
              key={option.value}
              className="max-w-xl"
              variant="radio"
              title={option.label}
              selected={inputs.reason === option.value}
              onClick={() => {
                if (inputs.additional) {
                  handleInputChange({
                    target: { name: 'additional', value: '' },
                  });
                }
                handleInputChange({
                  target: { name: 'reason', value: option.value },
                });
              }}
            />
          );
        })}
      </div>
      <div
        className={`col-span-6 ${inputs.reason !== 'Other' ? 'hidden' : ''}`}
      >
        <label htmlFor="additional">Other reason (optional)</label>
        <input
          id="additional"
          name="additional"
          type="text"
          value={inputs.additional}
          onChange={handleInputChange}
          required={false}
          className="mt-2"
          maxLength={50}
        />
      </div>
      <div className="col-span-6 text-sm">
        <label htmlFor="end">Pause availability reminders</label>
        {HIATUS_LENGTH_OPTIONS.map((option) => {
          return (
            <ButtonLarge
              key={option.value}
              className="max-w-xl"
              variant="radio"
              title={option.label}
              selected={inputs.end === option.value}
              onClick={() => {
                handleInputChange({
                  target: { name: 'end', value: option.value },
                });
              }}
            />
          );
        })}
      </div>
      <br />
      <div className="col-span-6 text-sm">
        <Button
          disabled={!inputs.reason || inputs.end === '' || submitDisabled}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={true}
        >
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
};

const Done = ({ inputs, submitDisabled, handleInputChange }): JSX.Element => {
  return (
    <React.Fragment>
      <div className="col-span-6">
        <label htmlFor="reason">Reason for leaving</label>
        {DONE_WITH_PROPEL_REASON_OPTIONS.map((option) => {
          return (
            <ButtonLarge
              key={option.value}
              className="max-w-xl"
              variant="radio"
              title={option.label}
              selected={inputs.reason === option.value}
              onClick={() => {
                handleInputChange({
                  target: { name: 'reason', value: option.value },
                });
              }}
            />
          );
        })}
      </div>
      <div className="col-span-6">
        <label htmlFor="additional">Tell us more (optional)</label>
        <textarea
          name="additional"
          value={inputs.additional}
          className="mt-2"
          onChange={handleInputChange}
          rows={5}
          maxLength={280}
        />
      </div>
      <div className="col-span-6">
        <Checkbox
          label="Unsubscribe from all future Propel emails"
          checked={inputs.unsubscribed}
          onChange={() => {
            handleInputChange({
              target: { name: 'unsubscribed', value: !inputs.unsubscribed },
            });
          }}
          color="primary"
        />
      </div>
      <br />
      <div className="col-span-6 text-sm">
        <Button
          disabled={!inputs.reason || submitDisabled}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={true}
        >
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AvailabilityFeedback;
