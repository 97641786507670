import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/RefreshOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import { LatLng } from 'api/Serializers/Facilities';
import Loading from 'components/loading';
import { GOOGLE_MAPS_API_KEY } from 'config';
import GoogleMap from 'google-map-react';
import React, { useEffect, useState } from 'react';

const AnyReactComponent = ({ children, highlight = false, ...rest }) => (
  <div
    className={`absolute group border-0 cursor-pointer hover:z-50 w-10 h-10 -translate-x-1/2 -translate-y-full ${
      highlight ? 'z-50' : 'z-0'
    }`}
  >
    <div>{children}</div>
  </div>
);

export interface Pin {
  id: number;
  slug: string;
  latlng: LatLng;
  label?: string;
  detailUrl: string;
}

interface Props {
  defaultCenter: LatLng;
  pins: Pin[];
  pinFocus?: Pin;
  pinHighlight?: Pin;
  onReset?(): void;
  onPinClick?(...args): void;
  onMapClick?(...args): void;
}

const Map = ({
  pins,
  pinFocus,
  pinHighlight,
  defaultCenter,
  onMapClick,
  onPinClick,
  onReset,
}: Props) => {
  const POINT_ZOOM_LEVEL = 11;
  const [googleInit, setGoogleInit] = useState(typeof google !== 'undefined');
  const [loaded, setIsLoaded] = useState(false);
  const [mapObj, setMapObj] = useState<google.maps.Map>();
  const handleApiLoaded = (map, maps) => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
    setMapObj(map);
  };

  // Google loaded
  useEffect(() => {
    if (typeof google !== 'undefined') {
      setGoogleInit(true);
    }
  }, [typeof google]);

  // Map Loaded
  useEffect(() => {
    if (loaded && mapObj) {
      setBounds();
    }
  }, [pins, loaded, mapObj]);

  const setBounds = () => {
    if (!mapObj) {
      return;
    } else if (pins.length === 1) {
      const pin = pins[0];
      mapObj.setCenter(pin.latlng);
      mapObj.setZoom(POINT_ZOOM_LEVEL);
    } else if (pins.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      pins.map((pin) => {
        bounds.extend(pin.latlng);
        return pin;
      });
      mapObj.fitBounds(bounds);
    }
  };
  if (!googleInit) {
    return <Loading />;
  }
  return (
    <>
      <div className="absolute z-20 top-3 right-3">
        <div className="flex flex-col space-y-2">
          <button
            className="bg-gray-100 flex-center btn-icon"
            onClick={() => mapObj.setZoom(mapObj.getZoom() + 1)}
          >
            <AddIcon />
          </button>
          <button
            className="bg-gray-100 flex-center btn-icon"
            onClick={() => mapObj.setZoom(mapObj.getZoom() - 1)}
          >
            <RemoveIcon />
          </button>
          <button
            className="bg-gray-100 flex-center btn-icon"
            onClick={onReset}
          >
            <HomeIcon />
          </button>
        </div>
      </div>
      <GoogleMap
        className="bg-red-200"
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        defaultZoom={14}
        defaultCenter={defaultCenter}
        disableDefaultUI={true}
        options={(maps) => ({
          disableDefaultUI: true,
          clickableIcons: false,
          gestureHandling: 'greedy',
          scrollwheel: true,
        })}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChildClick={onPinClick}
        onClick={onMapClick}
      >
        {pins &&
          pins.map((pin) => {
            const highlight =
              pinFocus?.id === pin.id || pinHighlight?.id === pin.id;
            return (
              <AnyReactComponent
                key={pin.id}
                lat={pin.latlng.lat}
                lng={pin.latlng.lng}
                highlight={highlight}
                detailUrl={pin.detailUrl}
                label={pin.label}
              >
                <div className="flex justify-center -bottom-1">
                  <span
                    className={`absolute z-50 pointer-events-none ${
                      highlight ? 'block' : 'hidden'
                    } p-1 -translate-x-1/2 bg-white border border-gray-300 rounded-full shadow-md left-1/2 -top-4 w-max group-hover:block`}
                  >
                    {pin.label}
                  </span>
                  <svg
                    width={highlight ? 40 : 30}
                    height="40"
                    viewBox="0 0 43 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.10493 36.2381C10.9091 39.9764 21.0929 50 21.0929 50L35.3246 36.2381C39.3054 31.9389 42.129 26.9111 42.129 20.7612C42.129 9.29509 32.7108 0 21.0929 0C9.47499 0 0.0568008 9.29509 0.0568008 20.7612C0.0568008 26.5104 3.27914 32.4786 7.10493 36.2381Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.98429 35.3907C11.5493 38.9281 21.0929 48.413 21.0929 48.413C21.0929 48.413 23.6547 45.8348 26.6279 42.8956C24.9638 41.2456 14.6117 30.981 12.6173 28.9965C10.443 26.8328 9.09778 23.8419 9.09778 20.5378C9.09778 13.9361 14.4682 8.58429 21.0929 8.58429C27.7176 8.58429 33.0879 13.9361 33.0879 20.5378C33.0879 21.756 32.6395 24.877 30.8937 26.9332C28.7659 29.4391 25.6218 31.1141 25.6218 31.1141L32.1794 37.4843C33.0603 36.6446 33.837 35.9193 34.4299 35.3907C38.4643 31.7935 40.8065 26.565 40.8065 20.7456C40.8065 9.8958 31.9804 1.10028 21.0929 1.10028C10.2054 1.10028 1.37926 9.8958 1.37926 20.7456C1.37926 26.1859 4.39903 31.8333 7.98429 35.3907ZM25.2651 20.5376C25.2651 22.8339 23.3972 24.6953 21.0929 24.6953C18.7887 24.6953 16.9207 22.8339 16.9207 20.5376C16.9207 18.2413 18.7887 16.3798 21.0929 16.3798C23.3972 16.3798 25.2651 18.2413 25.2651 20.5376Z"
                      fill={highlight ? '#00AEFF' : '#097ed1'}
                    />
                  </svg>
                </div>
              </AnyReactComponent>
            );
          })}
      </GoogleMap>
    </>
  );
};

export default Map;
