import Card from 'components/card';
import Link from 'components/link';
import { FETCH_STATE } from 'config';
import Page from 'containers/page';
import PasswordResetForm from 'features/password-reset-form';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { PasswordResetRequestMeta as Meta } from 'metadata';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAuthenticationFetchState } from 'state/selectors';
import { resetAuthState } from 'state/slice/authentication';
import { APP_ROUTES } from 'utils/routing';

const ForgotPassword = () => {
  const fetchState = useSelector(getAuthenticationFetchState);
  const dispatch = useAppDispatch();

  const handleReset = () => {
    dispatch(resetAuthState());
  };

  return (
    <Page {...Meta}>
      <div className="flex flex-col items-center px-4 py-12 bg-background min-h-90vh sm:px-6 lg:px-8">
        <Card minWidth="md">
          <div className="px-2">
            {fetchState !== FETCH_STATE.FULFILLED ? (
              <div>
                <div>
                  <h2 className="mt-6 text-xl font-bold text-left text-gray-800">
                    Reset your password
                  </h2>
                  <p className="text-sm text-gray-700">
                    Enter your email address, and we will send you a link to
                    reset your password
                  </p>
                </div>
                <PasswordResetForm />
              </div>
            ) : (
              <div>
                <div>
                  <h2 className="mt-6 text-xl font-bold text-left text-gray-800">
                    Thanks, check your email for instructions to reset your
                    password
                  </h2>
                </div>
                <p>
                  Didn't get the email? Wait 1-2 minutes, check your spam
                  folder, or{' '}
                  <span
                    className="font-medium text-blue-600 cursor-pointer hover:text-blue-500"
                    onClick={handleReset}
                  >
                    try again
                  </span>
                  .
                </p>
              </div>
            )}
          </div>
        </Card>
        <p className="mt-8 text-sm text-center text-gray-600">
          <Link
            to={APP_ROUTES.LOGIN}
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Return to sign in
          </Link>
        </p>
      </div>
    </Page>
  );
};

export default ForgotPassword;
