import React from 'react';

type Size = 'default' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
interface Props {
  progress: number;
  size?: Size;
  color?: Color;
  stroke?: 1 | 2 | 3 | 4 | 5 | 6 | 8;
}

const radii = {
  default: 30,
  sm: 20,
  md: 40,
  lg: 50,
  xl: 60,
  '2xl': 70,
};
const fontSizes = {
  default: 'text-base font-bold',
  sm: 'text-sm font-medium',
  md: 'text-base font-bold',
  lg: 'text-lg font-extrabold',
  xl: 'text-xl font-extrabold',
  '2xl': 'text-lg font-extrabold',
};

const hexes = {
  default: '#38bdf8',
  blue: '#38bdf8',
  green: '#22c55e',
  orange: '#f97316',
  red: '#ef4444',
  yellow: '#fbbf24',
};

const CircleProgress = ({
  progress = 0,
  size = 'default',
  color = 'default',
  stroke = 3,
}: Props) => {
  const progressColor = hexes[color];
  const radius = radii[size];
  const text = fontSizes[size];
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference - ((progress % 100) / 100) * circumference;
  return (
    <div className="relative">
      <div className={`absolute ${text} text-gray-800 center-xy`}>
        {Math.round(progress)}%
      </div>
      <svg
        height={radius * 2}
        width={radius * 2}
        style={{ transform: 'rotate(90deg)' }}
      >
        {/* Gray circle */}
        <circle
          stroke="#e2e8f0"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        {/* 100% filled circle */}
        {progress > 100 && (
          <circle
            stroke="#7dd3fc"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={
              radius * 2 * 2 * Math.PI + ' ' + radius * 2 * 2 * Math.PI
            }
            r={radius - 12}
            cx={radius}
            cy={radius}
          />
        )}
        {/* Progress */}
        <circle
          stroke={progressColor}
          fill="transparent"
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
};

export default CircleProgress;
