import React from 'react';

type Size = 'default' | 'large';

const getWidth = (size: Size) => (size === 'large' ? '53' : '44');
const getHeight = (size: Size) => (size === 'large' ? '60' : '50');

const PinCheckmark = ({ color, size }: { color: string; size: Size }) => (
  <svg
    width={getWidth(size)}
    height={getHeight(size)}
    viewBox="0 0 44 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="transition-all duration-150 ease-in-out"
  >
    <g clipPath="url(#clip0_2315_2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.715 50C21.715 50 11.2418 39.9764 7.30108 36.2381C3.33799 32.4786 0 26.5104 0 20.7612C0 9.29509 9.75621 0 21.7911 0C24.6547 0 27.3892 0.526242 29.8941 1.48276C37.9164 4.54624 43.5 11.8376 43.5 20.5754C43.5 26.7254 40.9931 32.4365 36.5335 36.2381C32.6786 39.5242 21.715 50 21.715 50ZM22.0349 46.4974C22.0349 46.4974 12.7454 37.6771 9.2752 34.3876C5.78536 31.0794 2.84597 25.8278 2.84597 20.7687C2.84597 10.6792 11.4372 2.5 22.0349 2.5C32.6327 2.5 41.2239 10.6792 41.2239 20.7687C41.2239 26.1804 38.944 31.0424 35.0169 34.3876C34.4399 34.8792 33.6838 35.5536 32.8264 36.3344L26.4433 30.4106C26.4433 30.4106 29.5038 28.853 31.5749 26.5227C33.2742 24.6106 33.7108 21.7082 33.7108 20.5754C33.7108 14.4363 28.4833 9.45953 22.0349 9.45953C15.5866 9.45953 10.3591 14.4363 10.3591 20.5754C10.3591 23.6479 11.6685 26.4293 13.785 28.4414C15.7263 30.2868 25.8028 39.8321 27.4227 41.3665C24.5286 44.0998 22.0349 46.4974 22.0349 46.4974ZM22.0349 24.4418C24.2778 24.4418 26.0961 22.7108 26.0961 20.5754C26.0961 18.4401 24.2778 16.709 22.0349 16.709C19.792 16.709 17.9738 18.4401 17.9738 20.5754C17.9738 22.7108 19.792 24.4418 22.0349 24.4418Z"
        fill="white"
      />
      <path
        d="M9.2752 34.3876C12.7454 37.6771 22.0349 46.4974 22.0349 46.4974C22.0349 46.4974 24.5286 44.0998 27.4227 41.3665C25.8028 39.8321 15.7263 30.2868 13.785 28.4414C11.6685 26.4293 10.3591 23.6479 10.3591 20.5754C10.3591 14.4363 15.5866 9.45953 22.0349 9.45953C28.4833 9.45953 33.7108 14.4363 33.7108 20.5754C33.7108 21.7082 33.2742 24.6106 31.5749 26.5227C29.5038 28.853 26.4433 30.4106 26.4433 30.4106L32.8264 36.3344C33.6838 35.5536 34.4399 34.8792 35.0169 34.3876C38.944 31.0424 41.2239 26.1804 41.2239 20.7687C41.2239 10.6792 32.6327 2.5 22.0349 2.5C11.4372 2.5 2.84597 10.6792 2.84597 20.7687C2.84597 25.8278 5.78536 31.0794 9.2752 34.3876Z"
        fill={color}
      />
      <path
        d="M26.0961 20.5754C26.0961 22.7108 24.2778 24.4418 22.0349 24.4418C19.792 24.4418 17.9738 22.7108 17.9738 20.5754C17.9738 18.4401 19.792 16.709 22.0349 16.709C24.2778 16.709 26.0961 18.4401 26.0961 20.5754Z"
        fill={color}
      />
      <path
        d="M42.2769 14.1355C42.7543 12.9829 43 11.7476 43 10.5C43 7.98044 41.9991 5.56408 40.2175 3.78249C38.4359 2.00089 36.0196 1 33.5 1C30.9804 1 28.5641 2.00089 26.7825 3.78249C25.0009 5.56408 24 7.98044 24 10.5C24 11.7476 24.2457 12.9829 24.7231 14.1355C25.2006 15.2881 25.9003 16.3354 26.7825 17.2175C27.6646 18.0997 28.7119 18.7994 29.8645 19.2769C31.0171 19.7543 32.2524 20 33.5 20C34.7476 20 35.9829 19.7543 37.1355 19.2769C38.2881 18.7994 39.3354 18.0997 40.2175 17.2175C41.0997 16.3354 41.7994 15.2881 42.2769 14.1355Z"
        fill="#CBFBDC"
      />
      <path
        d="M30.3333 11.2917L32.7083 13.6667L36.6667 8.125M43 10.5C43 11.7476 42.7543 12.9829 42.2769 14.1355C41.7994 15.2881 41.0997 16.3354 40.2175 17.2175C39.3354 18.0997 38.2881 18.7994 37.1355 19.2769C35.9829 19.7543 34.7476 20 33.5 20C32.2524 20 31.0171 19.7543 29.8645 19.2769C28.7119 18.7994 27.6646 18.0997 26.7825 17.2175C25.9003 16.3354 25.2006 15.2881 24.7231 14.1355C24.2457 12.9829 24 11.7476 24 10.5C24 7.98044 25.0009 5.56408 26.7825 3.78249C28.5641 2.00089 30.9804 1 33.5 1C36.0196 1 38.4359 2.00089 40.2175 3.78249C41.9991 5.56408 43 7.98044 43 10.5Z"
        stroke="#389463"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2315_2">
        <rect width={getWidth(size)} height={getHeight(size)} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PinDefault = ({ color, size }: { color: string; size: Size }) => (
  <svg
    width={getWidth(size)}
    height={getHeight(size)}
    viewBox="0 0 44 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="transition-all duration-150 ease-in-out"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.715 50C21.715 50 11.2418 39.9764 7.30108 36.2381C3.33799 32.4786 0 26.5104 0 20.7612C0 9.29509 9.75621 0 21.7911 0C24.6547 0 27.3892 0.526242 29.8941 1.48276C37.9164 4.54624 43.5 11.8376 43.5 20.5754C43.5 26.7254 40.9931 32.4365 36.5335 36.2381C32.6786 39.5242 21.715 50 21.715 50ZM22.0349 46.4974C22.0349 46.4974 12.7454 37.6771 9.2752 34.3876C5.78536 31.0794 2.84597 25.8278 2.84597 20.7687C2.84597 10.6792 11.4372 2.5 22.0349 2.5C32.6327 2.5 41.2239 10.6792 41.2239 20.7687C41.2239 26.1804 38.944 31.0424 35.0169 34.3876C34.4399 34.8792 33.6838 35.5536 32.8264 36.3344L26.4433 30.4106C26.4433 30.4106 29.5038 28.853 31.5749 26.5227C33.2742 24.6106 33.7108 21.7082 33.7108 20.5754C33.7108 14.4363 28.4833 9.45953 22.0349 9.45953C15.5866 9.45953 10.3591 14.4363 10.3591 20.5754C10.3591 23.6479 11.6685 26.4293 13.785 28.4414C15.7263 30.2868 25.8028 39.8321 27.4227 41.3665C24.5286 44.0998 22.0349 46.4974 22.0349 46.4974ZM22.0349 24.4418C24.2778 24.4418 26.0961 22.7108 26.0961 20.5754C26.0961 18.4401 24.2778 16.709 22.0349 16.709C19.792 16.709 17.9738 18.4401 17.9738 20.5754C17.9738 22.7108 19.792 24.4418 22.0349 24.4418Z"
      fill="white"
    />
    <path
      d="M9.2752 34.3876C12.7454 37.6771 22.0349 46.4974 22.0349 46.4974C22.0349 46.4974 24.5286 44.0998 27.4227 41.3665C25.8028 39.8321 15.7263 30.2868 13.785 28.4414C11.6685 26.4293 10.3591 23.6479 10.3591 20.5754C10.3591 14.4363 15.5866 9.45953 22.0349 9.45953C28.4833 9.45953 33.7108 14.4363 33.7108 20.5754C33.7108 21.7082 33.2742 24.6106 31.5749 26.5227C29.5038 28.853 26.4433 30.4106 26.4433 30.4106L32.8264 36.3344C33.6838 35.5536 34.4399 34.8792 35.0169 34.3876C38.944 31.0424 41.2239 26.1804 41.2239 20.7687C41.2239 10.6792 32.6327 2.5 22.0349 2.5C11.4372 2.5 2.84597 10.6792 2.84597 20.7687C2.84597 25.8278 5.78536 31.0794 9.2752 34.3876Z"
      fill={color}
    />
    <path
      d="M26.0961 20.5754C26.0961 22.7108 24.2778 24.4418 22.0349 24.4418C19.792 24.4418 17.9738 22.7108 17.9738 20.5754C17.9738 18.4401 19.792 16.709 22.0349 16.709C24.2778 16.709 26.0961 18.4401 26.0961 20.5754Z"
      fill={color}
    />
  </svg>
);

type Color = 'default' | 'emphasis' | 'deemphasis';

interface Props {
  checked: boolean;
  color?: Color;
  size: Size;
}

const getColor = (color: Color) =>
  color === 'default'
    ? '#049FEC'
    : color === 'emphasis'
    ? '#00AEFF'
    : '#6BC1FA';

const MapPin = ({
  checked = false,
  color = 'default',
  size = 'default',
}: Props) => {
  return checked ? (
    <PinCheckmark color={getColor(color)} size={size} />
  ) : (
    <PinDefault color={getColor(color)} size={size} />
  );
};

export default MapPin;
