import { DATE_FMT, FETCH_STATE } from 'config';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { getScheduleOpeningsFetchState } from 'state/selectors';

const InstructorBaseTimeSlot = ({
  onClick = null,
  datetime,
  timezone,
  description,
  classes,
}) => {
  const fetchState = useSelector(getScheduleOpeningsFetchState);
  const isLoading =
    fetchState === FETCH_STATE.GET || fetchState === FETCH_STATE.PUT;
  return (
    <div
      className={`transition-colors duration-150 px-6 ${
        isLoading ? 'opacity-80 curs' : onClick ? 'cursor-pointer' : ''
      } ${classes}`}
      onClick={!isLoading ? onClick : null}
    >
      <div className="py-3 -mx-4 sm:mx-0 sm:my-0">
        <div className="relative flex items-center w-full text-normal ">
          <div className={`w-14 text-inherit text-xs text-center leading-none`}>
            {moment(datetime).tz(timezone).format(DATE_FMT.TIME)}
          </div>
          <div className="flex-1 px-2">
            <div className="text-inherit">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorBaseTimeSlot;
