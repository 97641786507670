import Button from 'components/button';
import CommonQuestions from 'components/common-questions';
import Hero from 'components/hero';
import React from 'react';
import { APP_ROUTES, SHOW_HELP } from 'utils/routing';

const SwimmerLearnMore = () => (
  <div className="landing-page">
    <div style={{ position: 'relative' }}>
      <Hero
        background="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvY2xpZW50cy1oZXJvLWxnLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0="
        title="The choice is yours"
        subtitle="Book swimming lessons where you choose your instructor, your time, and your price"
        backgroundPosition="35% 100%"
        fadeOpacity={30}
      >
        <div className="flex flex-wrap my-4">
          <Button
            to={APP_ROUTES.BROWSE_CITIES}
            variant="contained"
            shape="rounded-full"
            size="large"
            color="primary"
            className="mt-4 mr-4"
          >
            Find nearby locations
          </Button>
          <Button
            variant="contained"
            shape="rounded-full"
            size="large"
            className="mt-4 text-gray-100 bg-opacity-20 hover:text-gray-900"
            to={APP_ROUTES.LEARN_MORE.INSTRUCTORS}
          >
            Teaching with Propel
          </Button>
        </div>
      </Hero>
    </div>

    <section>
      <div>
        <h2 className="title">How it works</h2>
        <h3 className="subtitle">
          Book your swim lesson online in just a few steps
        </h3>
      </div>
      <div className="flex flex-col items-center max-w-5xl gap-8 md:flex-row">
        <div className="flex-1">
          <img
            style={{ width: 662 }}
            id="how-it-works--locations"
            alt="Drawing of a list of pools"
            src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvaG93LWl0LXdvcmtzLS1sb2NhdGlvbnMtbGcucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ=="
          />
        </div>
        <div className="flex-1">
          <h3>Select a nearby location</h3>
          <p>
            Choose from amazing pools in local hotels and gyms. Each location
            comes loaded with amenities like free wifi, towel service, and
            lounge chair seating.
          </p>
        </div>
      </div>
      <hr />
      <div className="flex flex-col items-center max-w-5xl gap-8 md:flex-row-reverse">
        <div className="flex-1">
          <img
            style={{ width: 692 }}
            id="how-it-works--instructor"
            alt="Drawing of a list of instructors"
            src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvaG93LWl0LXdvcmtzLS1pbnN0cnVjdG9yLWxnLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0="
          />
        </div>
        <div className="flex-1">
          <h3>Book with an instructor</h3>
          <p>
            Select your instructor based on their price, schedule, and
            experience to find the one best suited to help you hit your swim
            goals. Then just pick a time from their schedule.
          </p>
          <h4 className="italic">
            <em>See you at the pool!</em>
          </h4>
        </div>
      </div>
    </section>
    <section className="bg-background">
      <h2 className="title">At your lesson</h2>
      <div className="flex flex-col items-center max-w-5xl gap-8 py-8 md:flex-row card">
        <div className="flex-1">
          <img
            style={{ width: 450 }}
            id="how-it-works--meet-at-pool"
            src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvY2xpZW50cy1tZWV0LWF0LXBvb2wtbGcucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ=="
          />
        </div>
        <div className="flex-1">
          <div>
            <h3>Meet your instructor and set goals</h3>
            <p>
              At the start of your lesson, take a few minutes to discuss your
              needs and goals with your instructor. Focus on the areas and
              skills you want to work on most.
            </p>
          </div>
        </div>
      </div>
      <div
        className="hidden h-24 mx-auto w-112 -scale-x-100 md:block"
        style={{
          backgroundImage:
            "url('https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvY2xpZW50cy1sZWFybi1kaXZpZGVyLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=')",
        }}
      />
      <div className="flex flex-col items-center max-w-5xl gap-8 py-8 card md:flex-row-reverse">
        <div className="flex-1">
          <img
            style={{ width: 450 }}
            id="how-it-works--learn-to-swim"
            src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvY2xpZW50cy1sZWFybi10by1zd2ltLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0="
          />
        </div>
        <div className="flex-1">
          <div>
            <h3>Learn to swim</h3>
            <p>
              Build fundamental swimming and water safety skills quickly. Learn
              faster with individual attention and constant feedback.
            </p>
          </div>
        </div>
      </div>
      <div
        className="hidden h-24 mx-auto w-112 md:block"
        style={{
          backgroundImage:
            "url('https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvY2xpZW50cy1sZWFybi1kaXZpZGVyLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=')",
        }}
      />
      <div className="flex flex-col items-center max-w-5xl gap-8 py-8 md:flex-row card">
        <div className="flex-1">
          <img
            style={{ width: 340 }}
            id="how-it-works--review-progress"
            src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvY2xpZW50cy1yZXZpZXctcHJvZ3Jlc3MtbGcucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ=="
          />
        </div>
        <div className="flex-1">
          <div>
            <h3>Review your progress</h3>
            <p>
              Spend the last few minutes of your lesson going over what you
              learned and what to work on next. Celebrate your progress—you’re
              one step closer to reaching your goals!
            </p>
          </div>
        </div>
      </div>
      <div>
        <Button
          to={APP_ROUTES.BROWSE_CITIES}
          size="large"
          color="primary"
          variant="contained"
        >
          Yes, it's just that easy!
        </Button>
      </div>
    </section>

    <section>
      <div>
        <h2 className="title">Take control of your learning</h2>
        <h3 className="subtitle">Quickly manage your lesson schedule online</h3>
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="overflow-hidden bg-white shadow-md w-80 rounded-xl">
          <div className="overflow-hidden w-80 h-w-80">
            <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvZmxleGlibGUtcmVzY2hlZHVsZS1sZy5qcGciLCJidWNrZXQiOiJvbGVrc2lhayJ9" />
          </div>
          <div className="p-6">
            <h4>Cancel or reschedule</h4>
            <p>
              Cancel or reschedule any lesson up to 48 hours before it starts
              and you won't be charged, no questions asked.
            </p>
            <div className="text-right">
              <Button
                onClick={SHOW_HELP.CANCELLATION_POLICY}
                color="primary"
                fullWidth={true}
                variant="outlined"
              >
                Read the full policy
              </Button>
            </div>
          </div>
        </div>
        <div className="overflow-hidden bg-white shadow-md w-80 rounded-xl">
          <div className="overflow-hidden w-80 h-w-80">
            <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2xlYXJuLW1vcmUvZmxleGlibGUtcmVwb3J0LWNhcmRzLWxnLmpwZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=" />
          </div>
          <div className="p-6">
            <h4>Make positive progress</h4>
            <p>
              Progress through levels faster with direct and personalized
              feedback throughout every lesson.
            </p>
            <div className="text-right">
              <Button
                onClick={SHOW_HELP.REPORT_CARDS}
                color="primary"
                fullWidth={true}
                variant="outlined"
              >
                Tracking your progress
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-background">
      <div>
        <h2 className="title">Unlock new experiences</h2>
        <h3 className="subtitle">
          Thousands of people across Canada have used Propel to discover new
          aquatic adventures.
        </h3>
      </div>
      <div className="flex w-full max-w-full p-4 mx-auto overflow-x-auto">
        <div className="grid grid-flow-col gap-6 mx-auto">
          <div className="overflow-hidden bg-white shadow-md w-60 sm:w-72 rounded-xl ">
            <div className="overflow-hidden w-60 h-72 sm:w-72 sm:h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvZXhwZXJpZW5jZS1wcmVwLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=" />
            </div>
            <div className="p-6">
              <h4>Public pool prep ️✌️</h4>
              <p>
                “I learned a lot and had a lot of fun. Yesterday was literally
                my first time in a pool and today I feel confident enough to go
                to a public pool by myself.”
              </p>
              <p>—Willbert W.</p>
            </div>
          </div>
          <div className="overflow-hidden bg-white shadow-md w-60 sm:w-72 rounded-xl">
            <div className="overflow-hidden w-60 h-72 sm:w-72 sm:h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvZXhwZXJpZW5jZS1zdXJmaW5nLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=" />
            </div>
            <div className="p-6">
              <h4>Surfs up 🏄‍</h4>
              <p>
                “Seeing our kids surf for the first time was the highlight of
                our summer. Thanks Propel!”
              </p>
              <p>—Liv H.</p>
            </div>
          </div>
          <div className="overflow-hidden bg-white shadow-md w-60 sm:w-72 rounded-xl">
            <div className="overflow-hidden w-60 h-72 sm:w-72 sm:h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvZXhwZXJpZW5jZS1tZXhpY28ucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ==" />
            </div>
            <div className="p-6">
              <h4>Moment in Mexico 🌴</h4>
              <p>
                “This moment is extra special to me cuz for YEARS my daughter
                feared water around her face and would never go under.”
              </p>
              <p>—L'nielle P.</p>
            </div>
          </div>
          <div className="overflow-hidden bg-white shadow-md w-60 sm:w-72 rounded-xl">
            <div className="overflow-hidden w-60 h-72 sm:w-72 sm:h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvZXhwZXJpZW5jZS1nb2xkLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=" />
            </div>
            <div className="p-6">
              <h4>Going For Gold 🏅</h4>
              <p>
                “Our goal was to improve my daughter's swim so that she could
                join a swim club. After our lessons, my daughter successfully
                tried out and got accepted!”
              </p>
              <p>—Christina C.</p>
            </div>
          </div>
          <div className="overflow-hidden bg-white shadow-md w-60 sm:w-72 rounded-xl">
            <div className="overflow-hidden w-60 h-72 sm:w-72 sm:h-72">
              <img src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvZXhwZXJpZW5jZS10cmlhdGhhbG9ucy5wbmciLCJidWNrZXQiOiJvbGVrc2lhayJ9" />
            </div>
            <div className="p-6">
              <h4>Triathlete Triumph 🏊‍</h4>
              <p>“I already knew how to swim but now I know how to compete.”</p>
              <p>—Charlie M.</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Button
          size="large"
          to={APP_ROUTES.BROWSE_CITIES}
          variant="contained"
          color="primary"
        >
          Book your first lesson today
        </Button>
      </div>
    </section>
    <CommonQuestions type="client" />
  </div>
);

export default SwimmerLearnMore;
