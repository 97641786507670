import { Tense } from 'api/Serializers/Schedules';
import { DATE_FMT } from 'config';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getScheduleRenderDate } from 'state/selectors';

export interface DayComponentProps {
  date: string;
  tense: Tense;
  onClick?(date: string): void;
}
interface CalendarProps {
  DayComponent: (props: DayComponentProps) => JSX.Element;
  dayBorder?: boolean;
}

const CalendarView = ({ DayComponent, dayBorder = false }: CalendarProps) => {
  const renderDate = useSelector(getScheduleRenderDate);
  const [days, setDays] = useState<DayComponentProps[]>([]);

  useEffect(() => {
    const rDate = renderDate ? renderDate : moment().format(DATE_FMT.DATE_KEY);
    const the1st = moment(rDate, DATE_FMT.DATE_KEY).startOf('month');
    const lastMonth = moment(the1st).subtract(1, 'month').endOf('month');
    const nextMonth = moment(the1st).add(1, 'month').startOf('month');
    const numDaysInMonth = the1st.daysInMonth();
    const isoWeekday = the1st.isoWeekday();
    const startWeekday = isoWeekday === 7 ? 0 : isoWeekday;
    const numWeeks = Math.ceil((startWeekday + numDaysInMonth) / 7);

    // Set days to [... 2021-12-30, 2021-12-31, 2022-01-01, 2022-01-02 ... 2022-01-31, 2022-02-01...]
    setDays(
      [...Array(numWeeks * 7).keys()].map((i) => {
        let d: Moment;
        let tense: Tense;
        if (i > numDaysInMonth + startWeekday - 1) {
          d = moment(nextMonth).add(i - startWeekday - numDaysInMonth, 'days');
          tense = Tense.NextMonth;
        } else if (i >= startWeekday) {
          d = moment(the1st).add(i - startWeekday, 'days');
          tense = d.isSame(moment(), 'date')
            ? Tense.Today
            : d.isAfter(moment())
            ? Tense.Future
            : Tense.Past;
        } else {
          d = moment(lastMonth).add(i - startWeekday + 1, 'days');
          tense = Tense.LastMonth;
        }
        return {
          date: d.format(DATE_FMT.DATE_KEY),
          tense,
        };
      })
    );
  }, [renderDate]);

  return (
    <div className="">
      <div className="grid grid-cols-7">
        <div className="h-8 text-xs leading-8 text-center uppercase border-0 font-medium text-slate-500">
          Sun
        </div>
        <div className="h-8 text-xs leading-8 text-center uppercase border-0 font-medium text-slate-500">
          Mon
        </div>
        <div className="h-8 text-xs leading-8 text-center uppercase border-0 font-medium text-slate-500">
          Tue
        </div>
        <div className="h-8 text-xs leading-8 text-center uppercase border-0 font-medium text-slate-500">
          Wed
        </div>
        <div className="h-8 text-xs leading-8 text-center uppercase border-0 font-medium text-slate-500">
          Thu
        </div>
        <div className="h-8 text-xs leading-8 text-center uppercase border-0 font-medium text-slate-500">
          Fri
        </div>
        <div className="h-8 text-xs leading-8 text-center uppercase border-0 font-medium text-slate-500">
          Sat
        </div>
      </div>
      <div
        className={`grid w-full grid-cols-7 bg-white${
          dayBorder
            ? ' rounded-lg divide-x divide-y border-r border-b divide-gray-300 border-gray-300'
            : ''
        }`}
      >
        {days.map((day, i) => {
          let classes: string = '';
          if (dayBorder) {
            if (i === 0) {
              classes = 'border-gray-300 border-l border-t rounded-tl-lg';
            } else if (i === 6) {
              classes = 'border-gray-300 rounded-tr-lg';
            } else if (i === 35) {
              classes = 'border-gray-300 rounded-bl-lg';
            } else if (i === 41) {
              classes = 'border-gray-300 rounded-br-lg';
            }
          }
          return (
            <div
              key={`${i}-${day.date}`}
              style={{ verticalAlign: 'top' }}
              className={`relative p-0 ${classes}`}
            >
              <DayComponent {...day} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

CalendarView.defaultProps = {
  scheduleDates: [],
};

export default CalendarView;
