import { InvoiceLineItem, YearlySummary } from 'api/Serializers/Payouts';
import React from 'react';
import EarningsHeader from '../header';

interface Props {
  details: YearlySummary;
}

const SummaryRow = ({
  title,
  subtitle,
  total,
  children,
}: {
  title: string;
  subtitle: string;
  total: number;
  children: any;
}) => {
  return (
    <div className="flex flex-col justify-between gap-2 sm:flex-row">
      <div className="flex-1 my-4">
        <h5 className="my-0 font-semibold leading-normal text-gray-900">
          {title}
        </h5>
        <p className="text-sm font-light text-gray-700">{subtitle}</p>
      </div>
      <div className="flex-1 p-6 bg-gray-200 rounded-xl">
        {children}
        <hr />
        <div className="flex justify-between">
          <span className="font-semibold text-gray-900">
            Total {title.toLowerCase()}
          </span>
          <span className="font-semibold text-gray-900">
            {total.toCurrency()}
          </span>
        </div>
      </div>
    </div>
  );
};

const LineItem = ({
  description,
  total,
  quantity,
}: {
  description: string;
  total: string | number;
  quantity?: number;
}) => {
  return (
    <div className="flex justify-between">
      <span className="font-medium text-gray-800">
        {quantity > 0 && `${quantity} `}
        {description}
      </span>
      <span className="font-medium text-gray-800">{total?.toCurrency()}</span>
    </div>
  );
};

const YearSummary = ({ details }: Props) => {
  if (!details) {
    return null;
  }
  const { lineItems } = details;
  const earnings = lineItems.filter((item) => item.category === 'EARNINGS');
  const deductions = lineItems.filter((item) => item.category === 'DEDUCTIONS');
  const otherPayments = lineItems.filter(
    (item) => item.category === 'OTHER_PAYMENTS'
  );
  const taxCollected = lineItems.filter(
    (item) => item.category === 'TAX_COLLECTED'
  );
  const getTotal = (agg: number, item: InvoiceLineItem, k: number) =>
    agg + Number(item.total);

  return (
    <div>
      <EarningsHeader />
      <div>
        <div className="my-12">
          <h3 className="font-extrabold text-center text-gray-800 font-body">
            {details.fullName}
          </h3>
        </div>
        <div className="my-8 space-y-8">
          <SummaryRow
            title="Earnings"
            subtitle="Total revenue earned from all paid appointments"
            total={earnings.reduce(getTotal, 0)}
          >
            {earnings.map((item) =>
              item.total != '0.00' ? <LineItem {...item} /> : null
            )}
          </SummaryRow>
          <SummaryRow
            title="Deductions"
            subtitle="Deductions from admission, booking fees, and taxes paid"
            total={deductions.reduce(getTotal, 0)}
          >
            {deductions.map((item) =>
              item.total != '0.00' ? (
                <LineItem description={item.description} total={item.total} />
              ) : null
            )}
          </SummaryRow>
          {otherPayments.length > 0 && (
            <SummaryRow
              title="Other"
              subtitle="Adjustments, bonuses, and promotions from Propel, or reimbursements given to clients"
              total={otherPayments.reduce(getTotal, 0)}
            >
              {otherPayments.map((item) =>
                item.total != '0.00' ? <LineItem {...item} /> : null
              )}
            </SummaryRow>
          )}
          {taxCollected.length > 0 && (
            <SummaryRow
              title="Tax Collected"
              subtitle="Amount of tax collected from earnings"
              total={taxCollected.reduce(getTotal, 0)}
            >
              {taxCollected.map((item) =>
                item.total != '0.00' ? <LineItem {...item} /> : null
              )}
            </SummaryRow>
          )}
          <div className="flex flex-col justify-between gap-2 sm:flex-row">
            <div className="flex-1 my-4">
              <h5 className="my-0 font-semibold leading-normal text-gray-900">
                Net Income
              </h5>
              <p className="text-sm font-light text-gray-700">
                Net income after deducted fees and taxes paid.
              </p>
            </div>
            <div className="flex-1 p-6 bg-blue-600 rounded-xl">
              <div className="flex items-center justify-between h-full">
                <span className="text-lg font-semibold text-white">
                  Net Income
                </span>
                <span className="text-lg font-semibold text-white">
                  {lineItems.reduce(getTotal, 0).toCurrency()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearSummary;
