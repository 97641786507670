import React from 'react';

interface Props {
  children: any;
}

const CardActions: React.FC<Props> = ({ children }) => {
  return <div className="flex justify-end space-x-3">{children}</div>;
};

export default CardActions;
