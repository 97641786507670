import InstructorAccount, {
  InstructorOnboardingSerializer,
} from 'api/Serializers/Accounts/Instructor';
import Button from 'components/button';
import Controls from 'components/controls';
import Fleeq, { FleeqModule } from 'components/fleeq';
import Modal from 'components/modal';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail, getInstructorOnboarding } from 'state/selectors';
import { modifyOnboarding } from 'state/slice/instructor';

interface Props {
  module: FleeqModule;
  show: boolean;
  /** Provide the attribute key which should be set true once the module is watched */
  onboardingAttributeKey?: keyof InstructorOnboardingSerializer;
  /** Function to perform on closing, usually resetting a 'show' state value */
  onClose?(): void;
}

const LearningModule: React.FC<Props> = ({
  module,
  show,
  onClose,
  onboardingAttributeKey,
}) => {
  const [isModalOpen, setModalOpen] = useState(show);
  const [isFleeqLoaded, setFleeqLoaded] = useState(false);
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const onboarding = useSelector(getInstructorOnboarding);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setModalOpen(show);
  }, [show]);
  const closeModal = () => {
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
    if (
      onboardingAttributeKey &&
      onboarding &&
      onboarding[onboardingAttributeKey] !== true
    ) {
      dispatch(
        modifyOnboarding(account.username, { [onboardingAttributeKey]: true })
      );
    }
  };
  return (
    <Modal
      name="Instructor — Learning Module"
      open={isModalOpen}
      onClose={closeModal}
    >
      <Fleeq module={module} onLoad={() => setFleeqLoaded(true)} />
      <Controls>
        <Button
          variant="contained"
          color="primary"
          disabled={!isFleeqLoaded}
          onClick={closeModal}
        >
          Dismiss
        </Button>
      </Controls>
    </Modal>
  );
};

export default LearningModule;
