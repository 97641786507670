import InfoIcon from '@mui/icons-material/Info';
import { TimeFrame } from 'api/Serializers/Appointments';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { AssistanceWizardProps } from 'models/Assistance';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SHARED_ROUTES } from 'utils/routing';

const ClientCancelPolicyEnforcementView = (props: AssistanceWizardProps) => {
  const { appointment, trackAction, addReportData } = props;
  const history = useHistory();
  if (appointment.timeFrame === TimeFrame.Outside48) {
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'CANCELLATION',
    });
    history.replace(SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary'));
    return null;
  }

  const handlePolicyIsEnforced = () => {
    addReportData({
      type: 'SET_POLICY_ENFORCEMENT',
      key: 'isPolicyWaived',
      value: false,
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'CLIENT_NO_SHOW',
    });
  };

  const handlePolicyIsWaived = () => {
    addReportData({
      type: 'SET_POLICY_ENFORCEMENT',
      key: 'isPolicyWaived',
      value: true,
      title: 'Is the cancellation policy waived?',
      description: "Waive cancellation policy and return the client's payment",
    });
    trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value: 'CANCELLATION',
    });
  };
  return (
    <>
      <h2>Cancellation Assistance</h2>
      <h4>
        {appointment.timeFrame >= TimeFrame.HasEnded
          ? 'The client did not attend'
          : 'The client cannot attend'}
      </h4>
      <Callout
        className="my-8"
        type="question"
        title="Will the instructor waive or enforce the 48-hour cancellation policy?"
      />
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="Enforce policy, no refund"
          subtitle="Instructor is paid, client is not refunded"
          icon={<InfoIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
          onClick={handlePolicyIsEnforced}
        />
        <ButtonLarge
          title="Waive policy, provide refund"
          subtitle="Refund the client, instructor is not paid"
          icon={<InfoIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'summary')}
          onClick={handlePolicyIsWaived}
        />
      </div>
      <Controls variant="block">
        <BackButton>Go back</BackButton>
      </Controls>
    </>
  );
};

export default ClientCancelPolicyEnforcementView;
