import { PersonOff } from '@mui/icons-material';
import ClientIcon from '@mui/icons-material/Face';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import { AssistanceWizardProps } from 'models/Assistance';
import React from 'react';
import { SHARED_ROUTES } from 'utils/routing';
import { getReportTypeDescription } from '../../utils';

const FacilityFoulingView = (props: AssistanceWizardProps) => {
  const handleSetPoolFoulingCausedBy = (causedBy: 'CLIENT' | 'OTHER') => {
    props.addReportData({
      type: 'SET_DATA',
      key: 'foulingCausedBy',
      value: causedBy,
      title: 'Who caused the pool fouling?',
      description: causedBy === 'CLIENT' ? 'My client' : 'Someone else',
    });
    props.trackAction({
      type: 'SET_RESOLUTION',
      key: 'resolution',
      value: 'FACILITY_WILL_NOT_OPEN',
    });
    props.trackAction({
      type: 'SET_RESULT',
      key: 'result',
      value:
        causedBy === 'CLIENT'
          ? 'CLIENT_CAUSED_FOULING'
          : 'FACILITY_NOT_AVAILABLE',
    });
  };
  return (
    <>
      <h2>Facility Assistance</h2>
      <h4>{getReportTypeDescription(props.state.type)}</h4>
      <Callout className="my-8" type="info">
        Let us know who caused the pool fouling.
      </Callout>
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="The client caused the fouling"
          subtitle="The client caused the fouling during the lesson"
          icon={<ClientIcon className="text-gray-600" color="inherit" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(
            props.appointment.id,
            'facility/cancel'
          )}
          onClick={() => handleSetPoolFoulingCausedBy('CLIENT')}
        />
        <ButtonLarge
          title="Someone else caused the fouling"
          subtitle="The pool fouling was caused by another pool user"
          icon={<PersonOff className="text-gray-600" />}
          to={SHARED_ROUTES.ASSISTANCE.nav(
            props.appointment.id,
            'facility/cancel'
          )}
          onClick={() => handleSetPoolFoulingCausedBy('OTHER')}
        />
      </div>
      <Controls variant="block">
        <BackButton />
      </Controls>
    </>
  );
};

export default FacilityFoulingView;
