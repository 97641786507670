import api from 'api';
import { Participant } from 'api/Serializers/Clients';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import Controls from 'components/controls';
import { FETCH_STATE, MIN_SWIMMER_AGE } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useForm from 'hooks/useForm';
import {
  GenericServerError,
  InvalidNumberError,
  MinimumAgeError,
} from 'lang/en/Snackbars';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail } from 'state/selectors';
import { createAccountParticipant } from 'state/slice/account';
import { SHOW_HELP } from 'utils/routing';

const MinimumAgeAction = () => (
  <button className="text-white link hover:text-white" onClick={SHOW_HELP.AGES}>
    Learn more
  </button>
);

const CreateNewParticipant = ({
  onCreated,
  username,
  onClose = undefined,
  setParticipantState = undefined,
}: {
  onCreated(p: Participant): void;
  username: string;
  onClose?(): void;
  setParticipantState?: React.Dispatch<React.SetStateAction<Participant[]>>;
}) => {
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail);
  const [fetchState, setFetchState] = useState<FETCH_STATE>(FETCH_STATE.IDLE);
  const { enqueueSnackbar } = useSnackbar();
  const nameRef = useRef<HTMLInputElement>();
  const onSubmit = async (
    data: Pick<Participant, 'name' | 'age' | 'hasDisability' | 'notes'>
  ) => {
    if (isNaN(data.age)) {
      enqueueSnackbar(InvalidNumberError(data.age));
    } else if (Number(data.age) < MIN_SWIMMER_AGE) {
      enqueueSnackbar({ ...MinimumAgeError, action: MinimumAgeAction });
    } else {
      setFetchState(FETCH_STATE.POST);
      let participant = null;
      if (username === account.username) {
        participant = await dispatch(createAccountParticipant(data));
      } else {
        try {
          const response = await api.clients.participants(username, data);
          participant = response.data;
          setParticipantState((state) => [...state, participant]);
        } catch (err) {
          participant = null;
        }
      }
      setFetchState(FETCH_STATE.IDLE);
      if (!participant) {
        enqueueSnackbar(GenericServerError);
      } else {
        onCreated(participant);
      }
    }
  };
  useEffect(() => {
    if (nameRef?.current) {
      nameRef.current.focus();
    }
  }, []);
  const { inputs, handleInputChange, handleSubmit } = useForm(
    { name: '', age: '' as any, hasDisability: false, notes: '' },
    onSubmit
  );
  return (
    <div className="w-full">
      <form onSubmit={handleSubmit}>
        <div className="w-full space-y-4">
          <div>
            <label htmlFor="name">Name</label>
            <input
              name="name"
              required
              onChange={handleInputChange}
              value={inputs.name}
              type="text"
              maxLength={150}
              // className="w-80"
              placeholder="Sally Swimmer"
              ref={nameRef}
            />
          </div>
          <div>
            <label htmlFor="name">Age (years)</label>
            <input
              name="age"
              required
              onChange={handleInputChange}
              value={inputs.age}
              type="number"
              max="115"
              min="0"
              maxLength={3}
              // className="w-20"
            />
          </div>
          <Checkbox
            value={inputs.hasDisability}
            label="Has a disability (please elaborate below)"
            disableTypography
            onChange={(e) =>
              handleInputChange({
                target: { name: 'hasDisability', value: e.target.checked },
              })
            }
          />
          <div>
            <label htmlFor="notes">Swimmer notes (optional)</label>
            <textarea
              name="notes"
              placeholder="Current experience level, skills to work on, or any special needs"
              maxLength={1200}
              onChange={handleInputChange}
              rows={3}
              // className="w-80"
            />
          </div>
          <Controls>
            {onClose && (
              <Button
                variant="contained"
                onClick={onClose}
                disabled={fetchState !== FETCH_STATE.IDLE}
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !inputs.name || !inputs.age || fetchState !== FETCH_STATE.IDLE
              }
            >
              Save
            </Button>
          </Controls>
        </div>
      </form>
    </div>
  );
};

export default CreateNewParticipant;
