import * as React from 'react';
import style from './style.module.scss';

interface Props {
  className?: string;
  children?: any;
}

const Snuggle: React.FunctionComponent<Props> = (props) => (
  <div
    className={`${style.snuggle}${
      props.className ? ` ${props.className}` : ''
    }`}
  >
    {props.children}
  </div>
);

export default Snuggle;
