import { HelpIcon } from 'icons';
import React from 'react';
import { SHOW_HELP } from 'utils/routing';

const ProposalListHeader = () => {
  return (
    <div className="hidden py-1 text-xs text-blue-800 uppercase border-b border-gray-300 px-card-tight md:flex">
      <div className="flex-2">Description</div>
      <div className="flex items-center justify-center flex-1 space-x-2">
        <span>Status</span>
        <span
          onClick={SHOW_HELP.TEACHING.PROPOSALS.WHAT_DOES_STATUS_MEAN('')}
          className="text-gray-600 cursor-pointer leading-0 hover:text-blue-500 text-md"
        >
          <HelpIcon color="inherit" fontSize="inherit" />
        </span>
      </div>
      <div className="flex-1 text-center">Action</div>
    </div>
  );
};

export default ProposalListHeader;
