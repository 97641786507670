import Link from 'components/link';
import { QueryParams } from 'config';
import SignInForm from 'containers/authentication/login';
import Page from 'containers/page';
import TaskCard from 'containers/task-card';
import useQuery from 'hooks/useQuery';
import { LoginMeta } from 'metadata';
import React from 'react';
import { APP_ROUTES } from 'utils/routing';

const Login = () => {
  const query = useQuery();
  const nextParam = query.get(QueryParams.Next);
  return (
    <Page {...LoginMeta}>
      <div className="min-h-screen bg-background">
        <TaskCard>
          <h2 className="mt-6 text-xl font-bold text-left text-gray-800">
            Sign in to your account
          </h2>
          <SignInForm flow="SignInPage" />
          <p className="mt-8 text-sm text-center text-gray-600">
            Not registered yet?{' '}
            <Link
              to={
                APP_ROUTES.SIGN_UP +
                `${nextParam ? `?${query.toString()}` : ''}`
              }
              className="link"
              tabIndex={5}
            >
              Create an account
            </Link>
          </p>
        </TaskCard>
      </div>
    </Page>
  );
};

export default Login;
